<template>
  <div class="module-tabs">
    <div :class="typeClass" v-show="!tabDisabled">
      <ul class="list_tab">
        <li v-for="tabs in tabmenu" :key="tabs.index" :class="{ on: tabs.isActive }">
          <a :href="tabs.href" class="btn_tab" @click="selectTab(tabs)" ref="subjectTab">{{ tabs.name }}</a>
        </li>
      </ul>
    </div>
    <div class="tab_panel">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    typeClass: {
      type: String,
      required: true
    },
    tabName: {
      type: String,
      default: ''
    },
    tabDisabled: {
      default: false
    }
  },
  data() {
    return {
      tabmenu: []
    };
  },
  created() {
    this.tabmenu = this.$children;
  },
  methods: {
    selectTab(selectedTab) {
      this.tabmenu.forEach((tabs) => {
        tabs.isActive = tabs.name === selectedTab.name;
        if (tabs.isActive) {
          this.$emit('changeTable', tabs.unityExamDivCd);
          this.$emit('getQuestionInfo', tabs.byyySubjtSeq);
          if (this.tabName === 'highSubjectTab') {
            this.$emit('changeByyySubjtSeq', tabs.byyySubjtSeq);
          }
          if (this.tabName === 'subSubjectTab') {
            this.$emit('changeByyySubjtSeq', tabs.byyySubjtSeq);
          }
        }
      });
    }
  }
};
</script>
