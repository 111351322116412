<template>
  <div class="module-pagination">
    <div class="paging type2 pc_only" aria-label="Page navigation">
      <button type="button" class="btn_prev" :disabled="isInFirstPage" aria-label="Previous" @click="onClickFirstPage">
        <span class="blind">이전</span>
      </button>
      <div class="num_page">
        <a
          v-for="page in pages"
          :key="page.index"
          href="javascript:;"
          @click="onClickPage(page.name)"
          class="link_page"
          :disabled="page.isDisabled"
          :class="{ active: isPageActive(page.name) }"
          :aria-label="`page number ${page.name}`"
        >
          {{ page.name }}
        </a>
      </div>
      <button type="button" class="btn_next" :disabled="isInLastPage" aria-label="Next" @click="onClickLastPage">
        <span class="blind">다음</span>
      </button>
    </div>
    <div class="mo_only wrap_more">
      <button v-if="!isInLastPage" type="button" class="btn_more" :disabled="isInLastPage" @click="onClickLastPage">
        더보기 +
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 5
    },
    totalPages: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    }
  },
  computed: {
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.totalPages === 0 || this.currentPage === this.totalPages;
    },
    startPage() {
      if (this.currentPage === 1) {
        return 1;
      }
      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons + 1;
      }
      return this.currentPage - 1;
    },
    endPage() {
      return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
    },
    pages() {
      const range = [];
      for (let i = this.startPage; i <= this.endPage; i += 1) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage
        });
      }
      return range;
    }
  },
  methods: {
    onClickFirstPage() {
      this.$emit('pagechanged', 1);
    },
    onClickPreviousPage() {
      this.$emit('pagechanged', this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit('pagechanged', page);
    },
    onClickNextPage() {
      this.$emit('pagechanged', this.currentPage + 1);
    },
    onClickLastPage() {
      this.$emit('change-page-per-cnt');
    },
    isPageActive(page) {
      return this.currentPage === page;
    }
  }
};
</script>

<style></style>
