<template>
  <div id="unityexam" class="pay_content">
    <div class="mypage_comm subject_anls" :class="{ mobile: isMobile }">
      <h4 class="pc_only"><span>과목별분석</span></h4>
      <div class="mo_only tab_select">
        <ul class="list_select">
          <li v-for="(obj, idx) in subjectOrder" :class="currentSubjectMap[obj].byyySubjtSeq === highByyySubjtSeq ? 'on' : ''" :key="idx">
            <a href="javascript:void(0)" class="link_cont" @click="changeHighByyySubjtSeq(currentSubjectMap[obj].byyySubjtSeq)">{{ currentSubjectMap[obj].subjtNm }}</a>
          </li>
        </ul>
      </div>
      <div class="select_exam_system pc_only">
        <div class="inner">
          <div class="wrap_select_year">
            <select class="select_cont" v-model="unityExamYear">
              <option
                :key="idx"
                :value="key"
                v-for="(key, idx) in Object.keys(unityExamTests)
                  .sort()
                  .reverse()"
              >{{ key }}년</option
              >
            </select>
          </div>
        </div>
      </div>
      <tab-menu typeClass="tab_comm pc_only" ref="tabMenu" tab-name="highSubjectTab" @changeByyySubjtSeq="changeHighByyySubjtSeq">
        <tab-pannel :byyy-subjt-seq="currentSubjectMap[obj].byyySubjtSeq" :selected="idx === 0" :noDataFlag="isNodata" :name="currentSubjectMap[obj].subjtNm" v-for="(obj, idx) in subjectOrder" :key="idx">
          <template #GraphGrid>
            <tab-graph-box
              :radioBoxType="2"
              :graphTitle="'정규모의고사 [' + currentSubjectMap[obj].subjtNm + '] 성적추이 그래프'"
              :chart-options="chartOptions"
              :chart-target-field="chartTargetField"
              :chart-data="chartData || []"
              @open-Modal="showModalSetGraphLayer = true"
              @set-chart-field="setChartFieldName"
            />
          </template>
        </tab-pannel>
      </tab-menu>
      <tab-menu
        tab-name="subSubjectTab"
        typeClass="tab_comm2"
        :tab-disabled="(currentSubjectMap['T'] || {}).byyySubjtSeq !== highByyySubjtSeq && (currentSubjectMap['L'] || {}).byyySubjtSeq !== highByyySubjtSeq"
        @changeByyySubjtSeq="changeByyySubjtSeq"
      >
        <tab-pannel :byyy-subjt-seq="obj.byyySubjtSeq" :name="obj.subjtNm" v-for="(obj, idx) in subSubjectTab" :key="idx" :selected="idx === 0">
          <template #TabelGrid>
            <tab-table-box
              tableStyle="statsByQuestionTypeTable"
              tableTitle="문항분류별 응시결과 통계"
              :subject-name="getSubjtNm"
              :current-page="currentPage"
              :page-per-cnt="pagePerCnt"
              @open-WindowPopup="openUnityExamViewQustnByTypePopup('unityExamViewQustnByTypePopup', $event)"
            />
            <pagination
              :total-pages="Math.ceil(answerByQuestionTypes.length / pagePerCnt)"
              :total="answerByQuestionTypes.length"
              :current-page="currentPage"
              @pagechanged="onPageChange"
              @change-page-per-cnt="pagePerCnt = pagePerCnt + 5"
              :maxVisibleButtons="Math.ceil(answerByQuestionTypes.length / pagePerCnt) > 5 ? 5 : Math.ceil(answerByQuestionTypes.length / pagePerCnt)"
            />
          </template>
        </tab-pannel>
      </tab-menu>
      <div class="grid_column" v-show="!isNodata">
        <div class="col_inner">
          <div class="col_half" v-if="wransrChartData.labels.length !== 0">
            <tab-table-box tableStyle="TypeHalf" tableTitle="오답원인" :chart-data="wransrChartData" :chart-options="pieChartOption" :cause-data="wransrCauseData" />
          </div>
          <div class="col_half" v-if="coransrChartData.labels.length !== 0">
            <tab-table-box tableStyle="TypeHalf" tableTitle="정답원인" :chart-data="coransrChartData" :chart-options="pieChartOption" :cause-data="coransrCauseData" />
          </div>
        </div>
      </div>
      <div :style="isMobile ? { marginTop: '20px', marginLeft: '20px' } : {}" v-show="isNodata">분석할 {{ getHighSubjtNm }} 데이터가 없습니다.</div>
      <!-- 그래프 설정 레이어 -->
      <Modal v-if="showModalSetGraphLayer" modalTitle="성적추이 그래프" modalType="layer_graph_setting pc_only" @close-modal="closeModalSetGraphLayer">
        <div slot="head">
          <h4>성적추이 그래프</h4>
        </div>
        <div slot="body">
          <div class="data_setting">
            <dl>
              <dt>시험</dt>
              <dd>
                <div class="type_check">
                  <label :for="'modalUnityDtlDivCd_' + item.data" class="lab_check" v-for="(item, idx) in unityExamDtlDivCdList" :key="idx">
                    <input type="checkbox" :id="'modalUnityDtlDivCd_' + item.data" class="inp_check" name="unityDtlDivCds" checked="" :disabled="item.data === '1'" :value="item.data" v-model="modalChartFilter.unityExamDtlDivCds" />
                    <span class="ico_check"></span>
                    <span>{{ item.label }}</span>
                  </label>
                </div>
              </dd>
            </dl>
            <dl>
              <dt>그래프</dt>
              <dd>
                <div class="type_check">
                  <label for="typeGraphRadio1" class="lab_radio">
                    <input type="radio" id="typeGraphRadio1" class="inp_radio" name="typeGraphRadio" v-model="modalChartTargetField" value="pctrk" />
                    <span class="ico_radio"></span>백분위
                  </label>
                  <label for="typeGraphRadio2" class="lab_radio">
                    <input type="radio" id="typeGraphRadio2" class="inp_radio" name="typeGraphRadio" value="orgScr" v-model="modalChartTargetField" />
                    <span class="ico_radio"></span>원점수
                  </label>
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <div slot="foot">
          <button type="button" class="btn_save" @click="applyGraphFilter">적용</button>
          <button type="button" class="btn_cancel" @click="closeModalSetGraphLayer">취소</button>
        </div>
      </Modal>
      <!-- //그래프 설정 레이어 -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { windowOpenPopup } from '@/mixins/windowOpenPopup';
import TabMenu from './common/TabMenu';
import TabPannel from './common/TabPannel';
import TabTableBox from './common/TabTableBox';
import TabGraphBox from './common/TabGraphBox';
import Pagination from './common/Pagination';
import Modal from './common/Modal';
import moment from 'moment';
import { PAGE_TITLE } from '../../const/const';

export default {
  name: 'subjtAnalyze',
  props: ['radioBoxType'],
  mixins: [windowOpenPopup],
  components: {
    TabMenu,
    TabPannel,
    TabGraphBox,
    TabTableBox,
    Pagination,
    Modal
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      userInfo: (state) => state.common.userInfo,
      codeList: (state) => state.common.codeList,
      unityExamDtlDivCdList: (state) => state.unityexam.wransrnote.unityDtlDivCds,
      unityExamTests: (state) => state.unityexam.wransrnote.unityExamTests,
      currentSubjectMap: (state) => state.unityexam.wransrnote.subjectMap,
      currentYearSubjtMap: (state) => state.unityexam.wransrnote.currentYearSubjtMap,
      lastYearSubjtMap: (state) => state.unityexam.wransrnote.lastYearSubjtMap,
      analyzeAvailableMap: (state) => state.unityexam.wransrnote.analyzeAvailableMap,
      answerByQuestionTypes: (state) => state.unityexam.wransrnote.answerByQuestionTypes,
      wransrCoransrCauseListStats: (state) => state.unityexam.wransrnote.wransrCoransrCauseListStats,
      getChartData: (state) => state.unityexam.wransrnote.chartData
    }),
    getHighSubjtNm() {
      let result = '';
      for (const key in this.currentSubjectMap) {
        const value = this.currentSubjectMap[key] || {};
        if (value.byyySubjtSeq === this.highByyySubjtSeq) {
          result = value.subjtNm;
        }
      }
      return result;
    },
    getSubjtNm() {
      let result = '';
      for (const key in this.currentSubjectMap) {
        const value = this.currentSubjectMap[key] || {};
        if (key === 'T') {
          for (const obj of value.child) {
            if (obj.byyySubjtSeq === this.byyySubjtSeq) {
              result = obj.subjtNm;
              break;
            }
          }
        } else {
          if (value.byyySubjtSeq === this.highByyySubjtSeq) {
            result = value.subjtNm;
          }
        }
      }
      return result;
    }
  },
  data() {
    return {
      popup: undefined,
      showModalSetGraphLayer: false,
      currentPage: 1,
      pagePerCnt: 5,
      unityExamYear: '',
      subjectOrder: [],
      isNodata: false,
      byyySubjtSeq: 0,
      highByyySubjtSeq: 0,
      subSubjectTab: [],
      chartTargetField: 'pctrk',
      modalChartTargetField: 'pctrk',
      chartFilter: {
        unityExamDtlDivCds: ['1', '2', '3']
      },
      modalChartFilter: {
        unityExamDtlDivCds: ['1', '2', '3']
      },
      chartData: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'right',
          align: 'start',
          labels: {
            usePointStyle: true,
            boxWidth: 2,
            padding: 20,
            fontColor: '#222',
            filter: (label) => {
              return label.text !== '';
            }
          }
        },
        layout: {
          padding: {
            top: 50,
            bottom: 20,
            right: 40,
            left: 40
          }
        },
        scales: {
          xAxes: [
            {
              show: true,
              offset: true,
              min: -1,
              ticks: {
                maxRotation: 0,
                autoSkip: false,
                callback(label, index, labels) {
                  if (/\s/.test(label)) {
                    return label.split(' ');
                  } else {
                    return label;
                  }
                },
                fontColor: '#222'
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                reverse: false,
                min: 0,
                max: 100,
                stepSize: 20,
                beginAtZero: true,
                fontColor: '#222'
              }
            }
          ]
        },
        plugins: {
          datalabels: {
            align: 'right',
            offset: 10,
            color: '#222',
            formatter(value, context) {
              let result = value;
              if (context.dataset._meta.isSat) {
                result = context.dataset._meta.subjtNm + ' ' + result;
              }
              return result;
            }
          }
        }
      },
      coransrCauseData: [],
      wransrCauseData: [],
      coransrChartData: {
        labels: [],
        datasets: [{ data: [], backgroundColor: [], borderWidth: 0 }]
      },
      wransrChartData: {
        labels: [],
        datasets: [{ data: [], backgroundColor: [], borderWidth: 0 }]
      },
      pieChartOption: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        legend: {
          position: 'bottom',
          labels: {
            fontColor: '#222'
          }
        },
        plugins: {
          datalabels: {
            backgroundColor: null,
            borderColor: '#222',
            borderRadius: 25,
            borderWidth: 0,
            color: '#222',
            font: {
              size: 13
            },
            padding: 6,
            formatter(value) {
              return value + '%';
            }
          }
        }
      }
    };
  },
  watch: {
    getChartData: {
      deep: true,
      handler() {
        this.changeChartData();
      }
    },
    unityExamYear(year) {
      if (year === '') {
        return;
      }
      const subjectOrderFix = ['K', 'M', 'E', 'T', 'H', 'L'];
      this.subjectOrder = [];
      this.setSubjectMapByYear(year);
      this.getSubjectMapByYear(year).then(() => {
        subjectOrderFix.forEach((subject) => {
          if (Object.keys(this.currentSubjectMap).indexOf(subject) !== -1) {
            const child = this.currentSubjectMap[subject].child || [];
            const expose = child.some((childSubject) => {
              return childSubject.questionByPartYn === 'Y';
            });
            if (expose) {
              this.subjectOrder.push(subject);
            }
          }
        });
        const studentCd = this.userInfo.studentCd;
        if (this.subjectOrder.length > 0) {
          this.getScoreByStudent({ year, studentCd }).then(() => {
            this.highByyySubjtSeq = this.currentSubjectMap[this.subjectOrder[0]].byyySubjtSeq;
          });
        }
        this.getRegularTestScoreOverYear({ year, studentCd });
      });
    },
    byyySubjtSeq: {
      immediate: true,
      handler(seq) {
        if (seq === 0) {
          return;
        }
        const byyySubjtSeqs = [];
        byyySubjtSeqs.push(seq);
        const studentCd = this.userInfo.studentCd;
        const param = {
          studentCd,
          byyySubjtSeqs
        };

        this.getAnswerByQuestionType(param);
        this.getAnalysisOfAnswerCause(param).then(() => {
          this.drawPieChart();
          this.createCoransrWransrTable();
        });
      }
    },
    highByyySubjtSeq(seq) {
      if (seq === 0) {
        return;
      }
      const arr = this.analyzeAvailableMap[seq] || [];
      if (arr.length > 0) {
        this.isNodata = false;
        // drawChart();
      } else {
        this.isNodata = true;
      }
      this.subSubjectTab = [];
      const byyySubjtSeqs = [];
      byyySubjtSeqs.push(seq);
      const childArr = {};
      const checkByyySubjtSeqs = {};
      Object.values(this.currentSubjectMap || {}).forEach((value) => {
        if (value.byyySubjtSeq === seq) {
          (value.child || []).forEach((childSubjt) => {
            if (childSubjt.questionByPartYn === 'Y') {
              childArr[childSubjt.byyySubjtSeq] = childSubjt;
            }
          });
        }
      });
      arr.forEach((row) => {
        const currentByyySubjtSeq = row.byyySubjtSeq;
        if (Object.prototype.hasOwnProperty.call(checkByyySubjtSeqs, currentByyySubjtSeq)) {
          return;
        }
        checkByyySubjtSeqs[currentByyySubjtSeq] = true;
        if (byyySubjtSeqs.indexOf(currentByyySubjtSeq) === -1) {
          const child = childArr[currentByyySubjtSeq];
          if (child.questionByPartYn === 'Y') {
            byyySubjtSeqs.push(currentByyySubjtSeq);
            this.subSubjectTab.push(row);
          }
        }
      });
      this.changeChartData();
      if ((this.currentSubjectMap['T'] || {}).byyySubjtSeq === this.highByyySubjtSeq || (this.currentSubjectMap['L'] || {}).byyySubjtSeq === this.highByyySubjtSeq) {
        if (this.subSubjectTab.length > 0) {
          this.byyySubjtSeq = this.subSubjectTab[0].byyySubjtSeq;
        }
        return;
      } else {
        this.byyySubjtSeq = 0;
      }

      const studentCd = this.userInfo.studentCd;
      const param = {
        studentCd,
        byyySubjtSeqs
      };

      this.getAnswerByQuestionType(param);
      this.getAnalysisOfAnswerCause(param).then(() => {
        this.drawPieChart();
        this.createCoransrWransrTable();
      });
    },
    chartTargetField() {
      this.changeChartData();
      this.modalChartTargetField = this.chartTargetField;
    },
    isMobile: {
      immediate: true,
      handler(value) {
        this.pagePerCnt = value ? 5 : 10;
      }
    }
  },
  methods: {
    ...mapActions(['getUser', 'getAllTestList', 'getSubjectMapByYear', 'setSubjectMapByYear', 'getScoreByStudent', 'getAnswerByQuestionType', 'getAnalysisOfAnswerCause', 'getRegularTestScoreOverYear', 'setHeaderInfo', 'getMrCodeList']),
    changeTitle() {
      let title = '';
      if (this.userInfo.branchCd !== '') title = PAGE_TITLE[this.userInfo.branchCd];
      document.title = 'mypage_성적관리 - 과목별분석 | ' + title;
    },
    openUnityExamViewQustnByTypePopup(PopName, seqs) {
      const route = this.$router.resolve({
        name: PopName,
        query: { viewType: '2', seqs: seqs.join(',') }
      });
      this.openWindowPopup(route.href, this.$route.name, PopName, this.locationReload, 'width=990, height=800, scrollbars=1');
    },
    closePopup() {
      this.popup.close();
      window.opener.child.close();
    },
    locationReload() {
      location.reload();
    },
    onPageChange(page) {
      this.currentPage = page;
    },
    setChartFieldName(field) {
      this.chartTargetField = field;
    },
    changeHighByyySubjtSeq(seq) {
      this.highByyySubjtSeq = seq;
      this.currentPage = 1;
      this.pagePerCnt = this.isMobile ? 5 : 10;
    },
    changeByyySubjtSeq(seq) {
      this.byyySubjtSeq = seq;
    },
    drawPieChart() {
      this.wransrChartData = {
        labels: [],
        datasets: [{ data: [], backgroundColor: [], borderWidth: 0 }]
      };
      this.coransrChartData = {
        labels: [],
        datasets: [{ data: [], backgroundColor: [], borderWidth: 0 }]
      };
      const corAnsrColorTable = ['#9fd442', '#75c855', '#43d1a1', '#41d5e2', '#3fa8e2', '#4284dd', '#5371a9', '#4f596d', '#474a52'],
        wraAnsrColorTable = ['#eee365', '#ffcd20', '#ff9f20', '#ed7939', '#e75144', '#dd346d', '#c84ee2', '#9a4ed4', '#6d51ca'];
      let idxX = 0,
        idxY = 0;
      this.wransrCoransrCauseListStats.forEach((obj) => {
        if (obj.frontType.indexOf('X') !== -1) {
          if (obj.mySelectRate === 0) {
            return;
          }
          this.wransrChartData.labels.push(obj.description);
          this.wransrChartData.datasets[0].data.push(parseFloat(obj.mySelectRate).toFixed(0));
          this.wransrChartData.datasets[0].backgroundColor.push(wraAnsrColorTable[idxX++]);
        }
        if (obj.frontType.indexOf('O') !== -1) {
          if (obj.mySelectRate === 0) {
            return;
          }
          this.coransrChartData.labels.push(obj.description);
          this.coransrChartData.datasets[0].data.push(parseFloat(obj.mySelectRate).toFixed(0));
          this.coransrChartData.datasets[0].backgroundColor.push(corAnsrColorTable[idxY++]);
        }
      });
    },
    createCoransrWransrTable() {
      this.wransrCauseData = [];
      this.coransrCauseData = [];
      this.wransrCoransrCauseListStats.forEach((obj) => {
        if (obj.frontType.indexOf('X') !== -1) {
          this.wransrCauseData.push(obj);
        }
        if (obj.frontType.indexOf('O') !== -1) {
          this.coransrCauseData.push(obj);
        }
      });
      this.wransrCauseData.sort((obj1, obj2) => {
        return obj1.frontType - obj2.frontType;
      });
      this.coransrCauseData.sort((obj1, obj2) => {
        return obj1.frontType - obj2.frontType;
      });
    },
    applyGraphFilter() {
      this.chartFilter = JSON.parse(JSON.stringify(this.modalChartFilter));
      this.chartTargetField = this.modalChartTargetField;
      this.showModalSetGraphLayer = false;
    },
    closeModalSetGraphLayer() {
      this.showModalSetGraphLayer = false;
      this.modalChartFilter = JSON.parse(JSON.stringify(this.chartFilter));
      this.modalChartTargetField = this.chartTargetField;
    },
    changeChartData() {
      const checkKey = [],
        label = {},
        color = {};
      let targetSubjtCd = '';
      const result = {};
      Object.keys(this.currentSubjectMap).forEach((key) => {
        const value = this.currentSubjectMap[key];
        if (value.byyySubjtSeq !== this.highByyySubjtSeq) {
          return;
        }
        targetSubjtCd = value.unitySubjtCd;
        value.child.forEach((child) => {
          checkKey.push(child.byyySubjtSeq);
          label[child.byyySubjtSeq] = child.subjtNm;
          color[child.byyySubjtSeq] = value.subjtColrCd || this.getRandomColor();
        });
      });
      const field = this.chartTargetField;
      const filteredData = this.getChartData.filter((item) => {
        return this.chartFilter.unityExamDtlDivCds.indexOf(item.unityExamDtlDivCd) !== -1;
      });

      const lastSatByyySubjtSeqs = [];
      for (const data of filteredData) {
        if (data.isLastSat) {
          Object.keys(data.scoreMap).forEach((key) => {
            if (this.lastYearSubjtMap[-key].highSubjtCd === targetSubjtCd) {
              console.log(key);
              lastSatByyySubjtSeqs.push(key);
            }
          });
          break;
        }
      }
      checkKey.forEach((byyySubjtSeq) => {
        result[byyySubjtSeq] = [];
      });
      lastSatByyySubjtSeqs.forEach((byyySubjtSeq) => {
        result[byyySubjtSeq] = [];
      });

      for (const item of filteredData) {
        lastSatByyySubjtSeqs.forEach((byyySubjSeq) => {
          const scoreMapElement = item.isLastSat ? item.scoreMap[byyySubjSeq] : {};
          const scr = scoreMapElement[field] || null;
          result[byyySubjSeq].push(scr);
        });
        checkKey.forEach((byyySubjtSeq) => {
          const scoreMapElement = item.scoreMap[byyySubjtSeq];
          if (scoreMapElement === undefined || scoreMapElement[field] === undefined) {
            result[byyySubjtSeq].push(null);
          } else {
            result[byyySubjtSeq].push(scoreMapElement[field]);
          }
        });
      }
      const resultData = [];
      Object.keys(result).forEach((key) => {
        const value = result[key];
        let isEmpty = true;
        value.forEach((el) => {
          if (el !== null) {
            isEmpty = false;
          }
        });
        if (isEmpty) {
          return;
        }
        let colorElement = color[key],
          isSat = false,
          subjtNm = '';
        let labelElement = label[key];
        if (Number(key) < 0) {
          labelElement = '';
          subjtNm = this.lastYearSubjtMap[-key].subjtNm;
          colorElement = '#555';
          isSat = true;
        }
        const param = {
          label: labelElement,
          borderColor: colorElement,
          pointBorderWidth: 6,
          pointBackgroundColor: colorElement,
          data: value,
          fill: false,
          tension: 0,
          _meta: { isSat, subjtNm }
        };
        resultData.push(param);
      });

      this.chartData = {
        labels: this.chartXRowSet(),
        datasets: resultData
      };
    },
    chartXRowSet() {
      const result = [];
      this.getChartData.forEach((item) => {
        if (this.chartFilter.unityExamDtlDivCds.indexOf(item.unityExamDtlDivCd) === -1) {
          return;
        }
        result.push(item.unityExamNm);
      });
      return result;
    },
    getRandomColor() {
      let color = Math.floor(Math.random() * 16777215).toString(16);
      while (color.length < 6) {
        color = '0' + color;
      }
      return '#' + color;
    },
    init() {
      const studentCd = this.userInfo.studentCd;
      const branchCd = this.userInfo.branchCd;
      const param = {
        studentCd,
        branchCd
      };
      this.getMrCodeList('RMD').then(() => {
        this.$store.commit('SET_UNITY_DTL_DIV_LIST', JSON.parse(JSON.stringify(this.codeList)));
        const list = this.unityExamDtlDivCdList.map((value) => {
          return value.data;
        });
        this.chartFilter.unityExamDtlDivCds = list;
        this.modalChartFilter.unityExamDtlDivCds = list;
        this.$store.commit('SET_CODE', []);
      });
      this.getAllTestList(param).then(() => {
        const currentYear = moment().format('YYYY');
        if (Object.prototype.hasOwnProperty.call(this.unityExamTests, currentYear)) {
          this.unityExamYear = currentYear;
        } else {
          const arr = Object.keys(this.unityExamYear)
            .sort()
            .reverse();
          if (arr.length > 0) {
            this.unityExamYear = arr[0];
          }
        }
      });
    }
  },
  created() {
    this.setHeaderInfo({ menuName: '과목별분석', isPrev: false });
    if (!this.userInfo.studentCd) {
      this.getUser().then(() => {
        this.init();
      });
    } else {
      this.init();
    }
    this.changeTitle();
  }
};
</script>
