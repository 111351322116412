import InfinitePassMutationTypes from './modules/infinitePass/MutationTypes';

export default {
  /*******************************************************
   *** ERROR
   *******************************************************/
  ERROR: 'ERROR',
  SET_IS_MOBILE: 'SET_IS_MOBILE',
  SET_LOADING: 'SET_LOADING',
  SET_IS_API_CALLED: 'SET_IS_API_CALLED',

  SET_SAMPLE: 'SET_SAMPLE',
  SET_TEST_LIST: 'SET_TEST_LIST',
  SET_STUDENT_TESTPPR_LIST: 'SET_STUDENT_TESTPPR_LIST',
  WRANSR_NOTE_REASON_LIST: 'WRANSR_NOTE_REASON_LIST',
  SET_ANALYSIS_RESULT_LIST: 'SET_ANALYSIS_RESULT_LIST',
  SET_TAKEXAM_EXAM_QST_LIST: 'SET_TAKEXAM_EXAM_QST_LIST',
  SET_TAKEXAM_QST_WRANSR_NOTE_YN: 'SET_TAKEXAM_QST_WRANSR_NOTE_YN',
  WeakNo: 'WeakNo',
  USER_INFO: 'USER_INFO',
  SET_WRANSR_NOTE_LIST: 'SET_WRANSR_NOTE_LIST',
  SET_WRANSR_NOTE_PREVIEW_LIST: 'SET_WRANSR_NOTE_PREVIEW_LIST',
  SET_WRANSR_NOTE_EXAMPPR_LIST: 'SET_WRANSR_NOTE_EXAMPPR_LIST',
  SET_ANALYZE_TEST: 'SET_ANALYZE_TEST',
  DELETE_WRANSR_LIST: 'DELETE_WRANSR_LIST',
  SET_CHILD_SUBJECT_LIST: 'SET_CHILD_SUBJECT_LIST',

  /* COMMON */
  SET_HEADER_NAME: 'SET_HEADER_NAME',
  SET_HEADER_PREV: 'SET_HEADER_PREV',
  SET_MENU_HTML: 'SET_MENU_HTML',
  SET_RECRUIT_JSON: 'SET_RECRUIT_JSON',
  SET_CODE: 'SET_CODE',
  SET_MENU_LIST: 'SET_MENU_LIST',
  SET_RECRUIT: 'SET_RECRUIT',
  SET_TIME_TABLE: 'SET_TIME_TABLE',
  SET_MEM_NO: 'SET_MEM_NO',
  /* 무한 패스 */
  ...InfinitePassMutationTypes
};
