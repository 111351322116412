import MutationTypes from '@/store/MutationTypes';
import ReplayLectureApi from '@/api/ReplayLectureApi';

export default {
  getReplayLecture: async ({ commit }, params) => {
    try {
      const result = await ReplayLectureApi.getReplayLecture(params);
      commit('SET_REPLAY_LECTURES', result.data);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  getSearchItems: async ({ commit }, params) => {
    try {
      const result = await ReplayLectureApi.getSearchItems(params);
      commit('SET_SEARCH_ITEMS', result.data);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  }
};
