<template>
  <footer>
    <div class="link">
      <a href="tel:02-565-2586">전화상담</a>
      <a href="https://m.etoos.com/member/pop_yakkwan_new.asp" target="_blank">이용약관</a>
      <a href="https://m.etoos.com/member/pop_individual.asp" target="_blank" class="policy">개인정보처리방침</a>
    </div>
    <p v-if="userInfo.branchCd === '0130'" class="copyright">
      서울시 양천구 오목로 325(목1동 406-21) 4~5층, 7층 | 사업자등록번호 148-85-02118, 622-85-08978 | Tel : 02-2646-2586 | Fax : 02-2608-9002 <br />
      <a href="/info/contact.do">목동이투스앤써학원</a> 학원등록번호 : 제4918호 교습과정 : 종합-보습·논술, 진학상담·지도 | 목동이투스앤써컨설팅학원 학원등록번호 : 제5780호 교습과정 : 진학상담·지도<br />
      <a href="/info/contact.do">목동이투스앤써1독서실</a> 학원등록번호 : 제5781호 교습과정 : 독서실 | 목동이투스앤써2독서실 학원등록번호 : 제5758호 교습과정 : 독서실 |<br />
      <strong>이투스에듀 주식회사</strong> (06733) 서울시 서초구 남부순환로 2547 (서초동 1354-3) | Tel: 02-552-1230 | Fax: 02-552-1325 | 사업자등록번호 : 505-87-02372 | 통신판매신고번호 : 강남-03036호
      <a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=5058702372" class="fc_000" target="_blank"> 정보조회</a><br />
      대표이사: 정선욱 | 개인정보보호책임자 : 정미상<br />
      <span class="c">Copyright &copy; ETOOS EDUCATION Co.,Ltd. All rights Reserved.</span>
    </p>
    <!--<div class="network">
        <a href="#"><span>이투스교육 학원 네트워크</span></a>
      </div>-->
  </footer>
</template>

<script>
export default {
  name: 'Footera',
  props: ['userInfo']
};
</script>
