<template>
  <div class="panel-contents" v-if="isActive && !noDataFlag">
    <slot name="TabelGrid"></slot>
    <slot name="GraphGrid"></slot>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    selected: {
      default: false
    },
    unityExamDivCd: {
      type: String,
      default: ''
    },
    byyySubjtSeq: {
      type: Number,
      default: 0
    },
    noDataFlag: {
      default: false
    }
  },
  data() {
    return {
      isActive: false
    };
  },
  computed: {
    href() {
      // return '#' + this.name.toLowerCase().replace(/ /g, '-');
      return 'javascript:;';
    }
  },
  mounted() {
    this.isActive = this.selected;
  }
};
</script>

<style></style>
