import schedule from './schedule';
import study from './study';
import lecture from './lecture';

export default {
  state: {
    schedule: schedule.state,
    study: study.state,
    lecture: lecture.state
  },
  actions: { ...schedule.actions, ...study.actions, ...lecture.actions },
  mutations: { ...schedule.mutations, ...study.mutations, ...lecture.mutations },
  getters: { ...schedule.getters, ...study.getters, ...lecture.getters }
};
