<template>
  <div>
    <div class="box_subjectlist">
      <div class="expander">
        <em class="info_sum">
          총 <span class="num">{{ lectureDataDan.length }}</span>
          개
        </em>
      </div>
      <div class="wrap_list">
        <ul class="list_subject">
          <li v-for="(lecture, idx) in lectureAllData" :key="idx">
            <p class="txt_teacher">
              <em class="ico_notify type1" v-if="lecture.waitingOrder > 0">대기 {{ lecture.waitingOrder }}번</em>
              [{{ lecture.subjtNm }}] {{ lecture.teacherName }}
            </p>
            <strong class="tit_subject" v-html="lecture.groupName"></strong>
            <div class="info_subject">
              <em class="txt_info">{{ setTimeLine(lecture.schedules) }}</em>
              <template v-if="lecture.groupTime > 0 && lecture.groupTime != '' && lecture.groupTime != null">
                <em class="txt_info">{{ lecture.groupTime + 'T' }}</em>
              </template>
              <em class="txt_info">{{ setGroupDate(lecture.groupSdate, lecture.groupEdate) }}</em>
              <em class="txt_info" v-if="lecture.groupRoom !== ''">{{ lecture.groupRoom }}</em>
            </div>
            <div class="button">
              <template v-if="lecture.groupEdate >= toDay">
                <!-- 신청기간 -->
                <template v-if="lecture.regstateCd == '1' || lecture.regstateCd == '6'">
                  <button type="button" class="btn_status type3" disabled="disabled">
                    <span class="inner">-</span>
                  </button>
                </template>
                <template v-else>
                  <button type="button" class="btn_status type3" disabled="disabled" v-if="lecture.regstateCd == '7'">
                    <span class="inner">입금대기중</span>
                  </button>
                  <button type="button" class="btn_status" disabled="disabled" v-else-if="lecture.groupEdate < toDay">
                    <span class="inner">종료</span>
                  </button>
                  <button
                    type="button"
                    class="btn_status type2"
                    @click="waiting(lecture.grpIdx, lecture.groupName, false)"
                    v-else-if="lecture.waitYn === 'Y' && lecture.courseCount >= lecture.groupLimit"
                  >
                    <span class="inner">대기</span>
                  </button>
                  <button
                    type="button"
                    class="btn_status"
                    disabled="disabled"
                    v-else-if="lecture.waitYn === 'N' && lecture.courseCount >= lecture.groupLimit"
                  >
                    <span class="inner">마감</span>
                  </button>
                  <button
                    type="button"
                    class="btn_status type1"
                    @click="register(lecture.grpIdx, lecture.groupName, false)"
                    v-else
                  >
                    <span class="inner">신청</span>
                  </button>
                </template>
                <button
                  type="button"
                  class="btn_detail"
                  @click="detailPopup(lecture)"
                  v-show="lecture.lectureIntroFileUrl !== '' ? true : false"
                >
                  <span class="blind">상세보기</span>
                </button>
              </template>
              <template v-else>
                <!-- 신청기간이 아님 -->
                <button
                  type="button"
                  class="btn_status type3"
                  disabled="disabled"
                  v-if="lecture.regstateCd == '1' || lecture.regstateCd == '6' || lecture.regstateCd == '7'"
                >
                  <span class="inner">-</span>
                </button>
                <span v-else>
                  <button type="button" class="btn_status" disabled="disabled" v-if="lecture.groupEdate < toDay">
                    <span class="inner">종료</span>
                  </button>
                  <button
                    type="button"
                    class="btn_status type2"
                    disabled="disabled"
                    v-else-if="lecture.waitYn === 'Y' && lecture.courseCount >= lecture.groupLimit"
                  >
                    <span class="inner">대기</span>
                  </button>
                  <button
                    type="button"
                    class="btn_status"
                    disabled="disabled"
                    v-else-if="lecture.waitYn === 'N' && lecture.courseCount >= lecture.groupLimit"
                  >
                    <span class="inner">마감</span>
                  </button>
                  <button type="button" class="btn_status type1" disabled="disabled" v-else>
                    <span class="inner">신청</span>
                  </button>
                </span>
                <button
                  type="button"
                  class="btn_detail"
                  @click="detailPopup(lecture)"
                  v-show="lecture.lectureIntroFileUrl !== '' ? true : false"
                >
                  <span class="blind">상세보기</span>
                </button>
              </template>
            </div>
          </li>
        </ul>
        <template v-if="pageNum !== (pageCount === 0 ? pageCount : pageCount - 1)">
          <button class="btn_moreview" :disabled="pageNum >= pageCount - 1" @click="nextPage">
            <span class="inner">강좌 더보기</span>
          </button>
        </template>
      </div>
    </div>
    <lecture-check v-if="isMobile && isCheckPopup" :checkParam="checkParam" @close="isCheckPopup = false" />
    <lecture-detail-m v-if="isMobile && isCheckPopupD" :checkParam="checkParamD" @close="isCheckPopupD = false" />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import lectureCheck from '@/components/classroom/lecture/LectureCheckM';
import lectureDetailM from '@/components/classroom/lecture/LectureDetailM';

export default {
  data() {
    return {
      pageNum: 0,
      pageSize: this.pageLen,
      lectureAllData: [],
      toDay: '',
      studyRegStartDt: '',
      studyRegEndDt: '',
      checkParam: {},
      checkParamD: {},
      isCheckPopup: false,
      isCheckPopupD: false
    };
  },
  created() {
    this.toDay = moment().format('YYYYMMDD');
    this.seasonInfo();
  },
  props: ['lectureDataDan', 'pageLen', 'seasonList', 'branchCd', 'studentCd', 'groupYear', 'seasonCd', 'seasonPerdMap'],
  components: {
    lectureCheck,
    lectureDetailM
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      lectureRegisterData: (state) => state.classroom.lecture.lectureRegisterData,
      lectureCancelData: (state) => state.classroom.lecture.lectureCancelData
    }),
    pageCount() {
      const listLen = this.lectureDataDan.length,
        listSize = this.pageSize;
      let page = Math.floor(listLen / listSize);
      if (listLen % listSize > 0) page += 1;

      return page;
    }
  },
  methods: {
    ...mapActions(['lectureRegister', 'lectureCancel']),
    lecturePagingData() {
      const start = this.pageNum * this.pageSize,
        end = start + this.pageSize;

      this.lectureAllData.push(...this.lectureDataDan.slice(start, end));
    },
    setTimeLine(value) {
      const weeks = { mon: '월', tue: '화', wed: '수', thu: '목', fri: '금', sat: '토', sun: '일' };
      const seasonPerdMap = this.seasonPerdMap;
      const seasonYear = this.groupYear;
      const seasonCd = this.seasonCd;
      const timeLine = _.chain(value)
        .filter(function(e) {
          return e && e.perdCd.indexOf('r') < 0;
        })
        .groupBy('week')
        .map(function(v, k) {
          const time = _.chain(v)
            .map(function(ve) {
              const seasonCdMap = seasonPerdMap[seasonYear] || {};
              const perdMap = seasonCdMap[seasonCd] || {};
              return perdMap[ve.perdCd] || `${ve.perdCd}교시`;
            })
            .sortBy()
            .value();
          return weeks[k] + ' ' + time;
        })
        .sortBy(function(e) {
          return _.values(weeks).indexOf(e.substr(0, 1));
        })
        .value()
        .join(' ');

      return timeLine;
    },
    setGroupDate(v1, v2) {
      return moment(v1, 'YYYYMMDD').format('M/D(ddd)') + '-' + moment(v2, 'YYYYMMDD').format('M/D(ddd)');
    },
    nextPage() {
      this.pageNum += 1;
      this.lecturePagingData();
    },
    seasonInfo() {
      //현재시즌의 수강신청 기간 셋
      this.seasonList.forEach((el) => {
        if (el.seasonCd === this.seasonCd) {
          this.studyRegStartDt = el.studyRegStartDt;
          this.studyRegEndDt = el.studyRegEndDt;
        }
      });

      this.lecturePagingData();
    },
    courseCheckPopup(idx) {
      const grpIdx = [];
      grpIdx.push(idx);
      grpIdx.push(idx);

      this.checkParam.grpIdx = grpIdx;
      this.checkParam.studentCd = this.studentCd;
      this.checkParam.branchCd = this.branchCd;
      this.isCheckPopup = true;
    },
    register(grp_idx, groupName, isLesson) {
      const params = {};
      params.studentCd = this.studentCd;
      params.branchCd = this.branchCd;
      params.grpIdx = grp_idx;

      let confirmMessage = groupName + ' 신청하시겠습니까?';
      if (isLesson) {
        confirmMessage += ' 단과 신청 시 실제 수강료가 청구되오니 행정실에 말씀해 주세요.';
      }

      const con = confirm(confirmMessage);
      if (!con) {
        return false;
      }

      this.lectureRegister(params).finally(() => {
        const result = this.lectureRegisterData;

        if (result.code !== '00000') {
          alert('학원에 문의해 주세요.');
          //alert('수강신청 실패하였습니다.');
        } else if (result.data && result.data.success) {
          alert('수강신청 완료하였습니다.');
          this.$emit('reload', 'tab2');
        } else if (result.data && result.data.duplicate) {
          alert('중복인 강좌가 있습니다.');
        } else if (result.data && result.data.limitOver) {
          alert('수강제한을 초과하였습니다.');
        } else if (result.data && result.data.msg) {
          //환불된 강좌입니다. 다시 수강을 원하시면 학원에 문의해 주세요.
          alert(result.data.msg);
        } else {
          alert('학원에 문의해 주세요.');
          //alert('수강신청 실패하였습니다.');
        }
      });
    },
    cancel(grp_Idx, grpasn_Idx, groupName) {
      const params = {};
      params['studentCd'] = this.studentCd;
      params['branchCd'] = this.branchCd;
      params.groups = [{ grpIdx: grp_Idx, grpasnIdx: grpasn_Idx }];

      const con = confirm(groupName + ' 취소하시겠습니까?');
      if (!con) {
        return false;
      }

      this.lectureCancel(params).finally(() => {
        const result = this.lectureCancelData;
        if (result.code === '00000') {
          alert('수강신청 취소 완료하였습니다.');
          this.$emit('reload', 'tab2');
        } else {
          alert('학원에 문의해 주세요.');
          //alert('수강신청 취소 실패하였습니다.');
        }
      });
    },
    waiting(grp_idx, groupName, isLesson) {
      const confirmMessage = groupName + ' 대기신청하시겠습니까?';
      const con = confirm(confirmMessage);
      if (!con) {
        return false;
      } else {
        this.register(grp_idx, groupName, isLesson);
      }
    },
    detailPopup(data) {
      const params = {};
      params.groupName = data.groupName;
      params.teacherName = data.teacherName;
      params.grpIdx = data.grpIdx;
      params.branchCd = this.branchCd;
      params.subjt = data.subjtNm;
      params.groupYear = this.groupYear;

      //this.isCheckPopupD = true
      //this.checkParamD = params

      this.$emit('detailPop', params);
    }
  }
};
</script>
