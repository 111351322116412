<template>
  <div class="popup_cont popup_ansr" :class="{ mobile: isMobile }">
    <div class="popup_head">
      <h1 class="tit_popup">오답노트</h1>
      <div class="switch_cont">
        <button
          type="button"
          id="switch1"
          class="btn_type"
          :class="{ on: contentView === 'type1' }"
          @click="contentView = 'type1'"
        >
          문항만
        </button>
        <button
          type="button"
          id="switch2"
          class="btn_type"
          :class="{ on: contentView === 'type2' }"
          @click="contentView = 'type2'"
        >
          문항+해설
        </button>
      </div>
      <div class="expander">
        <div class="exam_subject">
          <span class="txt_subject" v-if="StringUtils.isNotEmpty(questionDetailByPart.purbNm)">{{
            questionDetailByPart.purbNm
          }}</span>
          <span class="txt_subject" v-if="StringUtils.isNotEmpty(questionDetailByPart.luntNm)">{{
            questionDetailByPart.luntNm
          }}</span>
          <span class="txt_subject" v-if="StringUtils.isNotEmpty(questionDetailByPart.sbptNm)">{{
            questionDetailByPart.sbptNm
          }}</span>
          <span class="txt_subject" v-if="StringUtils.isNotEmpty(questionDetailByPart.smunitNm)">{{
            questionDetailByPart.smunitNm
          }}</span>
        </div>
      </div>
    </div>
    <div class="popup_body">
      <div v-if="seqs.length > 0" class="wrap_exam_paper type2">
        <div class="paper_info" :class="{ dimmed: isOpenQuestionNumber, active: isActive }">
          <!--//class active추가하면 문항보기 영역 보임 -->
          <h2 class="tit_paper mo_only">문항보기</h2>
          <button type="button" class="btn_popup_close" @click="windowCloseSelf()">닫기</button>
          <table class="tbl_comm">
            <caption>
              문항 정보
            </caption>
            <colgroup>
              <col style="width:90px" />
              <col style="width:auto" />
              <col style="width:90px" />
              <col style="width:80px" />
              <col style="width:90px" />
              <col style="width:80px" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">시험정보</th>
                <td class="data_cont">
                  {{ examNm }}
                </td>
                <th scope="row">오답률</th>
                <td>{{ wransrrt === 0 ? '-' : wransrrt.toFixed(1) + '%' }}</td>
                <th scope="row">해설영상</th>
                <td>
                  <button
                    type="button"
                    v-if="StringUtils.isNotEmpty(currentQuestion.explCurriVodPathwy)"
                    @click="showExplVod(currentQuestion.explCurriVodPathwy, currentQuestion.qustnInpMethodCd)"
                    class="btn_vod"
                  >
                    보기
                  </button>
                  <a v-else>-</a>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  {{ currentQuestion.coransrYn === 'Y' ? '정답원인' : '오답원인' }}
                </th>
                <td colspan="5" class="data_cont">
                  <div class="type_check">
                    <label
                      v-for="(item, idx) in currentQuestion.coransrModels"
                      @change="saveCause(item.realType)"
                      :key="idx"
                      class="lab_radio"
                      :for="'typeRadio' + idx"
                    >
                      <input
                        type="radio"
                        :id="'typeRadio' + idx"
                        :checked="currentQuestion.wransrCauseCd === item.realType"
                        class="inp_radio"
                        name="typeRadio"
                        :disabled="isParent"
                        @click="item.description === '기타' ? (isShowInputReasonLayer = true) : () => {}"
                      />
                      <span class="ico_radio"></span>
                      {{ item.description }}
                      <span
                        v-if="item.description === '기타' && StringUtils.isNotEmpty(currentQuestion.etcReason)"
                        class="txt_reason"
                      >({{ currentQuestion.etcReason }})</span
                      >
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="tab_exam">
            <ul class="list_tab">
              <li :class="{ on: contentView == 'type1' }">
                <button type="button" class="btn_tab" @click="contentView = 'type1'">
                  문항
                </button>
              </li>
              <li :class="{ on: contentView == 'type2' }">
                <button type="button" class="btn_tab" @click="contentView = 'type2'">
                  해설
                </button>
              </li>
            </ul>
          </div>
          <div class="exam_cont">
            <div class="box_question" :class="{ on: contentView === 'type1' }">
              <strong class="blind">지문/문항</strong>
              <div v-if="StringUtils.isNotEmpty((currentQuestion || {}).textImgPathwy)">
                <img :src="currentTextImgPathwy" alt="" />
              </div>
              <div v-if="StringUtils.isNotEmpty((currentQuestion || {}).qustnImgPathwy)">
                <img :src="currentQustnImgPathwy" alt="" />
              </div>
              <div
                v-if="
                    StringUtils.isEmpty((currentQuestion || {}).qustnImgPathwy)
                "
              >
                <p class="blank">문항이미지가 없습니다.</p>
              </div>
            </div>
            <div class="box_explain" :class="{ on: contentView === 'type2' }">
              <strong class="blind">해설</strong>
              <div v-if="StringUtils.isNotEmpty((currentQuestion || {}).explImgPathwy)">
                <img :src="currentExplImgPathwy" alt="" />
              </div>
              <div v-else>
                <p class="blank">해설이미지가 없습니다.</p>
              </div>
            </div>
            <button type="button" v-if="!isFirstPage" @click="qustnPrev" class="btn_prev">이전</button>
            <button type="button" v-if="!isLastPage" @click="qustnNext" class="btn_next">다음</button>
            <button
              v-show="currentQuestion.explCurriVodPathwy && contentView === 'type2'"
              @click="showExplVod(currentQuestion.explCurriVodPathwy, currentQuestion.qustnInpMethodCd)"
              type="button"
              class="btn_vod_play"
            >
              해설영상<br />보기
            </button>
          </div>
        </div>
        <!-- 문항보기-과목별 -->
        <div class="exam_sheet">
          <div class="single">
            <aside-list
              :active="true"
              :active-index="activeIndex"
              :questionViewType="questionViewType"
              :Limits="Object.values(questionDetailByPart.answerResponseMap || {})"
              :is-parent="isParent"
              @isShowQuestionInfo="isShowInputReasonLayer = true"
              @qustnIndex="qustnIndex"
              @changeRegiWransrNote="changeRegiWransrNote"
            />
          </div>
        </div>
        <!-- //문항보기-과목별 -->
      </div>
    </div>
    <!-- 오답원인 입력 레이어 -->
    <Modal
      v-if="isShowInputReasonLayer"
      :modalTitle="currentQuestion.coransrYn === 'Y' ? '정답원인 입력' : '오답원인 입력'"
      modalType="layer_wransr_write"
      @close-modal="isShowInputReasonLayer = false"
    >
      <div slot="head">
        <h4>{{ currentQuestion.coransrYn === 'Y' ? '정답원인 입력' : '오답원인 입력' }}</h4>
      </div>
      <div slot="body">
        <textarea v-model="reasonText" @keydown="onKeyDownReason($event)" maxlength="20" placeholder="(최대 20자)" cols="30" rows="5" class="tf_write"></textarea>
      </div>
      <div slot="foot">
        <button @click="saveCauseReasonText" type="button" class="btn_save">확인</button>
      </div>
    </Modal>
    <!-- //오답원인 입력 레이어 -->
    <expl-curri-vod
      expl-type="corWransr"
      v-if="!isMobile && showVodModalLayer"
      :explCurriVodUrl="explCurriVodUrl"
      @showExplVod="showVodModalLayer = false"
    /><!-- 해설 강의 팝업 -->
    <expl-curri-vod-mobile
      expl-type="corWransr"
      v-if="isMobile && showVodModalLayer"
      :explCurriVodUrl="explCurriVodUrl"
      @showExplVod="showVodModalLayer = false"
    /><!-- 해설 강의 팝업 -->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AsideList from '@/components/unityexam/common/popup/AsideList';
import Modal from '@/components/unityexam/common/Modal';
import ExplCurriVod from '@/components/dailytest/taketest/ExplCurriVod';
import ExplCurriVodMobile from '@/components/dailytest/taketest/ExplCurriVodMobile';
import { AWS_S3_RESOURCE_URL } from '@build/build.config';
import { USER_AUTHORITY } from '@/const/const';
import { tmsImage } from '@/mixins/dailytest/tmsImage';
import StringUtils from '@/utils/StringUtils';

export default {
  name: 'unityExamViewQustnByTypePopup',
  mixins: [tmsImage],
  components: {
    AsideList,
    Modal,
    ExplCurriVod,
    ExplCurriVodMobile
  },
  data() {
    return {
      questionViewType: parseInt(this.$route.query.viewType) || 2, // 학생별: '1', 과목별: '2'
      seqs: (this.$route.query.seqs || '').split(','),
      contentView: 'type1',
      isShowQuestionInfo: false,
      isOpenQuestionNumber: false,
      reasonWrongAnswer: '',
      isShowInputReasonLayer: false,
      showVodModalLayer: false,
      isActive: false,
      isActiveOn: 0,
      items: 20,
      activeIndex: -1,
      currentQuestion: {},
      examNm: '',
      wransrrt: 0,
      explCurriVodPathwy: '',
      qustnList: [{}],
      explCurriVodUrl: '',
      awsResourceUrl: AWS_S3_RESOURCE_URL,
      reasonText: '',
      USER_AUTHORITY,
      StringUtils
    };
  },
  watch: {
    questionDetailByPart: {
      deep: true,
      handler(part) {
        if (Object.keys(part.answerResponseMap || {}).length > 0) {
          if (this.activeIndex === -1) {
            this.activeIndex = 0;
          }
        } else {
          this.activeIndex = -1;
        }
      }
    },
    activeIndex: {
      handler() {
        if (this.activeIndex === -1) {
          return;
        }
        const arr = Object.values(this.questionDetailByPart.answerResponseMap);
        this.currentQuestion = arr[this.activeIndex];
        const info = this.currentQuestion;
        const result = [];
        if (StringUtils.isNotEmpty(info.unityExamDivNm)) {
          result.push(info.unityExamDivNm);
        }
        if (StringUtils.isNotEmpty(info.unityExamNm)) {
          result.push(info.unityExamNm);
        }
        if (StringUtils.isNotEmpty(info.unityDtlExamNm)) {
          result.push(info.unityDtlExamNm);
        }
        if (info.unityDtlExamTurnOrd > 0) {
          result.push(info.unityDtlExamTurnOrd + '회');
        }

        this.examNm = result.join(' > ');
        this.wransrrt = (info.wransrrt || {}).rate || 0;
      }
    },
    isShowInputReasonLayer() {
      if (this.isShowInputReasonLayer) {
        this.reasonText = this.currentQuestion.etcReason;
      }
    }
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      userInfo: (state) => state.common.userInfo,
      questionDetailByPart: (state) => state.unityexam.wransrnote.questionDetailByPart
    }),
    isFirstPage() {
      return this.activeIndex === 0;
    },
    isLastPage() {
      return this.activeIndex === this.seqs.length - 1;
    },
    isParent() {
      return (this.userInfo || {}).userAuthority === this.USER_AUTHORITY.PARENT;
    },
    currentExplImgPathwy() {
      let url = '';
      const qustnInpMethodCd = (this.currentQuestion || {}).qustnInpMethodCd;
      if (qustnInpMethodCd === '1') {
        url = this.awsResourceUrl + '/unity-exam/' + this.currentQuestion.explImgPathwy;
      } else if (qustnInpMethodCd === '2') {
        url = this.getTmsImg(this.currentQuestion.explImgPathwy);
      }
      return url;
    },
    currentTextImgPathwy() {
      let url = '';
      const qustnInpMethodCd = (this.currentQuestion || {}).qustnInpMethodCd;
      if (qustnInpMethodCd === '1') {
        url = this.awsResourceUrl + '/unity-exam/' + this.currentQuestion.textImgPathwy;
      } else if (qustnInpMethodCd === '2') {
        url = this.getTmsImg(this.currentQuestion.textImgPathwy);
      }
      return url;
    },
    currentQustnImgPathwy() {
      let url = '';
      const qustnInpMethodCd = (this.currentQuestion || {}).qustnInpMethodCd;
      if (qustnInpMethodCd === '1') {
        url = this.awsResourceUrl + '/unity-exam/' + this.currentQuestion.qustnImgPathwy;
      } else if (qustnInpMethodCd === '2') {
        url = this.getTmsImg(this.currentQuestion.qustnImgPathwy);
      }
      return url;
    }
  },
  mounted() {
    if (this.isMobile) {
      this.isActive = true;
    }
  },
  updated() {
    if (this.contentView === 'type1'){
      document.querySelectorAll('.sel_ansr').forEach((element) => {
        element.classList.add('blur');
      });
    } else {
      document.querySelectorAll('.sel_ansr').forEach((element) => {
        element.classList.remove('blur');
      });
    }
  },
  created() {
    if (window.NodeList && !NodeList.prototype.forEach) {
      NodeList.prototype.forEach = Array.prototype.forEach;
    }
    if (!this.userInfo.studentCd) {
      this.getUser().then(() => {
        this.init();
      });
    } else {
      this.init();
    }
  },
  methods: {
    ...mapActions(['getUser', 'postChangeRegiWransrNote', 'postSaveCause', 'getQuestionInfoByExamQustnSeqs']),
    windowCloseSelf() {
      var customWindow = window.open('', '_self', '');
      customWindow.close();
    },
    showInputReasonLayer() {
      if (this.reasonWrongAnswer !== '') {
        if (this.reasonWrongAnswer.substring(5, 8) === '009') this.isShowInputReasonLayer = true;
        this.saveCause(this.reasonWrongAnswer);
      }
    },
    closeViews() {
      const isViews = document.querySelector('.paper_info');
      isViews.classList.remove('active');
      this.isActive = false;
    },
    showExplVod(explCurriVodPathwy, qustnInpMethodCd) {
      this.explCurriVodUrl = '/unity-exam/' + explCurriVodPathwy;
      this.showVodModalLayer = true;
    },
    qustnPrev() {
      if (this.activeIndex > 0) {
        this.activeIndex--;
        this.isActiveOn = this.activeIndex;
      }
    },
    qustnNext() {
      if (this.activeIndex < this.seqs.length - 1) {
        this.activeIndex++;
        this.isActiveOn = this.activeIndex;
      }
    },
    qustnIndex(index) {
      this.activeIndex = index;
      this.isActiveOn = this.activeIndex;
    },
    changeRegiWransrNote(unityExamQustnSeq, bystdntUnityExamQustnInpStusSeq, e) {
      const params = {};
      params.coransrNotesYn = e.target.checked === true ? 'Y' : 'N';
      params.unityExamQustnSeq = unityExamQustnSeq;
      params.bystdntUnityExamQustnInpStusSeq = bystdntUnityExamQustnInpStusSeq;

      this.postChangeRegiWransrNote(params);
    },
    saveCause(reasonWrongAnswer) {
      const params = {};
      this.reasonWrongAnswer = reasonWrongAnswer;
      if (reasonWrongAnswer.indexOf('W') >= 0) {
        params.wransrCauseCd = reasonWrongAnswer;
        this.currentQuestion.wransrCauseCd = this.reasonWrongAnswer;
      }

      params.unityExamQustnSeq = this.currentQuestion.unityExamQustnSeq;
      params.bystdntUnityExamQustnInpStusSeq = this.currentQuestion.bystdntUnityExamQustnInpStusSeq;
      this.postSaveCause(params);
    },
    saveCauseReasonText() {
      const params = {};
      params.coransrWransrNotesEtcOpninCont = this.reasonText;
      params.unityExamQustnSeq = this.currentQuestion.unityExamQustnSeq;
      params.bystdntUnityExamQustnInpStusSeq = this.currentQuestion.bystdntUnityExamQustnInpStusSeq;
      this.postSaveCause(params);
      this.currentQuestion.etcReason = this.reasonText;
      this.isShowInputReasonLayer = false;
    },
    init() {
      const param = {
        studentCd: this.userInfo.studentCd,
        unityExamQustnSeqs: this.seqs
      };
      this.getQuestionInfoByExamQustnSeqs(param);
    },
    onKeyDownReason(evt) {
      if(this.reasonText.length === 20 ){
        evt.target.blur();
        evt.target.focus();
      }
    }
  }
};
</script>
