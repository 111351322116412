import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = {
  takexamExamSeq: 0,
  takexamQstSeq: [0],
  exampprList: [
    {
      answerCount: 0,
      appliedDate: 0,
      createId: '',
      delYn: 'N',
      displayExplTmssCnt: 0,
      examDate: 0,
      exampprSeq: 0,
      exampprUseYn: 'Y',
      explTmssCnt: 0,
      memId: '',
      qstBankExampprCd: '',
      qstCnt: 0,
      questionCount: 0,
      testSeq: 0,
      turnOrdNo: 0,
      updateId: '',
      wkSeqNo: 0,
      questionList: [
        {
          qstSeq: 0,
          qstNo: 0,
          exampprSeq: 0,
          qstBankSubjtCd: 0,
          takexamQstSeq: 0,
          qstBankSubjtNm: '',
          qstBankLclasfCd: '',
          qstBankLclasfNm: '',
          qstBankMdclasCd: '',
          qstBankMdclasNm: '',
          qstBankSmclasfCd: '',
          qstBankSmclasfNm: '',
          qstImgUrl: '',
          qstExplUrl: '',
          moblQstImgUrl: '',
          moblQstExplUrl: '',
          qstCoransrNo: null,
          smlQstCd: null,
          explCurriVodId: null
        }
      ]
    }
  ],
  testList: [
    {
      testSeq: 0,
      testNm: '',
      takexamPosblDate: 0
    }
  ],
  testId: 0,
  newcoransr: [],
  explCurriVodUrl: '',
  analysisTest: {
    examDt: '',
    corAnswerCnt: 0,
    inpAnswerCnt: 0,
    qustnSolvSec: 0
  },
  childSubjectList: []
};

export default {
  state,
  getters,
  actions,
  mutations
};
