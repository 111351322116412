<template>
  <!-- 문제 출력 -->
  <div id="app" class="popup_cont popup_ansr print" :class="{ mobile: isMobile }">
    <div class="popup_head">
      <h1 class="tit_popup">{{ getMenuName() }}</h1>
      <div class="expander">
        <button type="button" class="btn_print" onclick="window.print();"><span class="blind">인쇄하기</span></button>
      </div>
    </div>
    <div class="popup_body">
      <div v-if="contentView != 'type3'">
        <div v-for="(itemSubject, activeLiIndex) in categories">
          <div v-for="(itemQustn, idx) in qustnList[activeLiIndex]" class="wrap_exam_paper">
            <h1 v-if="idx === 0" class="tit_popup_print_only">{{ getMenuName() }}</h1>
            <div class="paper_info">
              <table class="tbl_comm">
                <caption>
                  문항 정보
                </caption>
                <colgroup>
                  <col style="width:90px" />
                  <col style="width:auto" />
                  <col style="width:90px" />
                  <col style="width:80px " />
                  <col style="width:90px" />
                  <col style="width:80px" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">시험정보</th>
                    <td class="data_cont">{{ examYear }}년 {{ itemSubject.unityExamNm }} {{ itemSubject.unityDtlExamNm }} {{ itemSubject.unityDtlExamTurnOrd }}회 {{ itemSubject.subjtNm }}({{ itemSubject.dtlSubjtNm }})</td>
                    <th scope="row">문항번호</th>
                    <td>{{ itemQustn.no }}</td>
                    <th scope="row">학생명</th>
                    <td>{{ userInfo.userName }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="exam_cont">
                <div class="box_question">
                  <strong class="blind">지문/문항</strong>
                  <div v-if="StringUtils.isNotEmpty((itemQustn || {}).textImgPathwy) && itemQustn.qustnInpMethodCd === '1'">
                    <img :src="awsResourceUrl + '/unity-exam/' + itemQustn.textImgPathwy" alt="" />
                  </div>
                  <div v-if="StringUtils.isNotEmpty((itemQustn || {}).textImgPathwy) && itemQustn.qustnInpMethodCd === '2'">
                    <img :src="getTmsImg(itemQustn.textImgPathwy)" alt="" />
                  </div>
                  <div v-if="StringUtils.isNotEmpty((itemQustn || {}).qustnImgPathwy)">
                    <img v-if="itemQustn.qustnInpMethodCd === '1'" :src="awsResourceUrl + '/unity-exam/' + itemQustn.qustnImgPathwy" alt="" />
                    <img v-if="itemQustn.qustnInpMethodCd === '2'" :src="getTmsImg(itemQustn.qustnImgPathwy)" alt="" />
                  </div>
                  <div v-else>
                    <p class="blank">문항이미지가 없습니다.</p>
                  </div>
                </div>
                <div class="box_explain on">
                  <div v-if="contentView === 'type2'">
                    <strong class="blind">해설</strong>
                    <div v-if="StringUtils.isNotEmpty((itemQustn || {}).explImgPathwy)">
                      <img v-if="itemQustn.qustnInpMethodCd === '1'" :src="awsResourceUrl + '/unity-exam/' + itemQustn.explImgPathwy" alt="" />
                      <img v-if="itemQustn.qustnInpMethodCd === '2'" :src="getTmsImg(itemQustn.explImgPathwy)" alt="" />
                    </div>
                    <div v-else>
                      <p class="blank">해설이미지가 없습니다.</p>
                    </div>
                  </div>
                  <div class="txt_absolute_bottom_right">수학DM 콘텐츠에 대한 저작권은 이투스에듀에 있으므로 무단으로 전재하거나 복제, 배포할 수 없습니다.</div>
                </div>
              </div>
              <div class="txt_date">{{ todayFormat() }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="contentView == 'type3'">
        <div v-for="(itemSubject, activeLiIndex) in categories">
          <div v-for="(itemQustn, idx) in qustnList[activeLiIndex]">
            <div v-if="contentView === 'type3' && idx % 2 == 0">
              <div class="wrap_exam_paper">
                <h1 v-if="idx === 0" class="tit_popup_print_only">{{ getMenuName() }}</h1>
                <div class="paper_info">
                  <table class="tbl_comm">
                    <caption>
                      문항 정보
                    </caption>
                    <colgroup>
                      <col style="width:90px" />
                      <col style="width:auto" />
                      <col style="width:90px" />
                      <col style="width:80px " />
                      <col style="width:90px" />
                      <col style="width:80px" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th scope="row">시험정보</th>
                        <td class="data_cont">{{ examYear }}년 {{ itemSubject.unityExamNm }} {{ itemSubject.unityDtlExamNm }} {{ itemSubject.unityDtlExamTurnOrd }}회 {{ itemSubject.subjtNm }}({{ itemSubject.dtlSubjtNm }})</td>
                        <th scope="row">문항번호</th>
                        <td>{{ qustnList[activeLiIndex][idx].no }}{{ idx + 1 < qustnList[activeLiIndex].length ? ', ' + qustnList[activeLiIndex][idx + 1].no : '' }}</td>
                        <th scope="row">학생명</th>
                        <td>{{ userInfo.userName }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="exam_cont">
                    <div class="box_question">
                      <strong class="blind">해설</strong>
                      <div v-if="StringUtils.isNotEmpty((qustnList[activeLiIndex][idx] || {}).explImgPathwy)">
                        <img v-if="qustnList[activeLiIndex][idx].qustnInpMethodCd === '1'" :src="awsResourceUrl + '/unity-exam/' + qustnList[activeLiIndex][idx].explImgPathwy" alt="" />
                        <img v-if="qustnList[activeLiIndex][idx].qustnInpMethodCd === '2'" :src="getTmsImg(qustnList[activeLiIndex][idx].explImgPathwy)" alt="" />
                      </div>
                      <div v-else>
                        <p class="blank">해설이미지가 없습니다.</p>
                      </div>
                    </div>
                    <div class="box_explain on">
                      <div v-if="idx + 1 < qustnList[activeLiIndex].length">
                        <strong class="blind">해설</strong>
                        <div v-if="StringUtils.isNotEmpty((qustnList[activeLiIndex][idx + 1] || {}).explImgPathwy)">
                          <img v-if="qustnList[activeLiIndex][idx + 1].qustnInpMethodCd === '1'" :src="awsResourceUrl + '/unity-exam/' + qustnList[activeLiIndex][idx + 1].explImgPathwy" alt="" />
                          <img v-if="qustnList[activeLiIndex][idx + 1].qustnInpMethodCd === '2'" :src="getTmsImg(qustnList[activeLiIndex][idx + 1].explImgPathwy)" alt="" />
                        </div>
                        <div v-else>
                          <p class="blank">해설이미지가 없습니다.</p>
                        </div>
                      </div>
                      <div class="txt_absolute_bottom_right">수학DM 콘텐츠에 대한 저작권은 이투스에듀에 있으므로 무단으로 전재하거나 복제, 배포할 수 없습니다.</div>
                    </div>
                  </div>
                  <div class="txt_date">{{ todayFormat() }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- //오답노트 출력 -->
</template>
<style type="text/css">
  .txt_absolute_bottom_right {
    position: absolute;
    bottom: 6px;
    width: 36%;
    right: 3px;
    text-align: right;
  }
  .tit_popup_print_only {
    display: none;
  }
@media print {
  .wrap_exam_paper {
    margin-top: 15px;
  }
  .popup_cont .wrap_exam_paper {
    page-break-before: always;
  }
  .popup_body {
    width: 95% !important;
  }
  .tit_popup_print_only {
    display: inline-block;
    font-family: 'notokr-bold';
    font-size: 22px;
    line-height: 38px;
    color: #333;
    display: block;
  }
}
</style>
<script>
import { mapActions, mapState } from 'vuex';
import Modal from '@/components/unityexam/common/Modal';
import { AWS_S3_RESOURCE_URL } from '@build/build.config';
import { tmsImage } from '@/mixins/dailytest/tmsImage';
import StringUtils from '@/utils/StringUtils';
import DateUtil from '@/utils/DateUtil';
export default {
  name: 'unityExamViewQustnPrintPopup',
  mixins: [tmsImage],
  components: {
    Modal
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      userInfo: (state) => state.common.userInfo,
      subjtQuestionAnswerPrint: (state) => state.unityexam.wransrnote.subjtQuestionAnswerPrint
    })
  },
  created() {
    if (window.NodeList && !NodeList.prototype.forEach) {
      NodeList.prototype.forEach = Array.prototype.forEach;
    }
    this.examYear = this.$route.query['examYy'];
    this.contentView = this.$route.query['contentView'] || 'type1';
    if (!this.userInfo.studentCd) {
      this.getUser().then(() => {
        const params = {};
        params.examYy = this.examYear;
        params.unityExamSeq = this.$route.query['unityExamSeq'];
        params.unityDtlExamSeq = this.$route.query['unityDtlExamSeq'];
        params.unityDtlExamTurnOrdSeq = this.$route.query['unityDtlExamTurnOrdSeq'];
        params.dtlSubjtSeq = this.$route.query['dtlSubjtSeq'];
        this.initSelectExam(params);
      });
    } else {
      const params = {};
      params.examYy = this.examYear;
      params.unityExamSeq = this.$route.query['unityExamSeq'];
      params.unityDtlExamSeq = this.$route.query['unityDtlExamSeq'];
      params.unityDtlExamTurnOrdSeq = this.$route.query['unityDtlExamTurnOrdSeq'];
      params.dtlSubjtSeq = this.$route.query['dtlSubjtSeq'];
      this.initSelectExam(params);
    }
  },
  methods: {
    ...mapActions(['getUser', 'getSubjtQuestionAnswerPrint', 'getEnterAnswerExamQustn', 'getWransrnoteQustn']),
    initSelectExam(params) {
      this.getSubjtQuestionAnswerPrint(params).then(() => {
        this.categories = [];
        for (const subjt of this.subjtQuestionAnswerPrint) {
          this.categories.push(subjt);
          this.qustnList.push(subjt.includeSmlQustnResponse.qustnList);
        }
      });
    },
    getMenuName() {
      const subjt = this.categories === undefined ? null : this.categories[0];
      const title = StringUtils.isEmpty((subjt || {}).untiyExamDivNm) ? '' : subjt.untiyExamDivNm + ' ' + subjt.dtlSubjtNm + ' ' + subjt.unityDtlExamTurnOrd + '회';
      switch (this.contentView) {
        case 'type1':
          return StringUtils.isEmpty(title) ? '' : title + '(문제)';
        case 'type2':
          return StringUtils.isEmpty(title) ? '' : title + '(문제와 해설)';
        default:
          return StringUtils.isEmpty(title) ? '' : title + '(해설)';
      }
    },
    todayFormat() {
      return DateUtil.dateFormatter('{yyyy}.{mm}.{dd} {hh}:{mi}', new Date());
    }
  },
  data() {
    return {
      contentView: 'type1',
      categories: [{ title: '사회문화' }, { title: '경제' }, { title: '테스트1' }, { title: '테스트2' }],
      qustnList: [],
      awsResourceUrl: AWS_S3_RESOURCE_URL,
      StringUtils,
      examYear: ''
    };
  }
};
</script>
