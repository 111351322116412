// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import VueScrollTo from 'vue-scrollto';
import App from './App';
import router from './router';
import store from './store';
import SlimGrid from 'vue-slimgrid';
import moment from 'moment';
import underscore from 'vue-underscore';
import Buefy from 'buefy';
import VueGtag from 'vue-gtag';

import { MY_GOOGLE_ANALYTICS_ID } from './const/const';
import { getBrandType } from '@/utils/util';
import { APPLICATION_PHASE } from '../build/build.config';

if (APPLICATION_PHASE === 'REAL') {
/*  Vue.use(
    VueGtag,
    {
      config: {
        id: MY_GOOGLE_ANALYTICS_ID[getBrandType(location.href)],
        params: {
          send_page_view: false
        }
      },
      includes: [
        {
          id: 'GTM-5FGHQ98',
          params: {
            send_page_view: false
          }
        }
      ]
    },
    router
  );*/
  Vue.config.productionTip = false;
} else if (APPLICATION_PHASE === 'QA') {
  /*Vue.use(
    VueGtag,
    {
      config: {
        id: MY_GOOGLE_ANALYTICS_ID[getBrandType(location.href)],
        params: {
          send_page_view: false
        }
      },
      includes: [
        {
          id: 'GTM-WDGZTMV',
          params: {
            send_page_view: false
          }
        }
      ]
    },
    router
  );*/
  Vue.config.devtools = true;
} else {
  /* Disable on real env */
  Vue.config.devtools = true;
}

Vue.use(Buefy);

sync(store, router);
Vue.use(VueScrollTo, { offset: -40 });
Vue.use(SlimGrid);
Vue.use(moment);
Vue.use(underscore);
moment.locale('ko');

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>'
});
