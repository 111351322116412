import getters from './getters';
import actions from './actions';
import mutations from './mutations';
const state = {
  lectureSearchDataJae: [],
  lectureSearchDataDan: [],
  teacherData: [],
  lectureCheckList: [],
  resultData: [],
  lectureRegisterData: [],
  lectureCancelData: [],
  imageData: [],
  lectureTypeMap: {}
};

export default {
  state,
  getters,
  actions,
  mutations
};
