<template>
  <transition name="modal">
    <div class="wrap_layer" @mousedown="$emit('close-modal')">
      <div class="layer_cont" :class="modalType" style="height: auto;" @mousedown.stop>
        <button type="button" class="btn_close" @click="closeModal">
          x
        </button>
        <div v-show="!disableHead" class="layer_head">
          <slot name="header">
            <h4>{{ modalTitle }}</h4>
          </slot>
        </div>

        <div class="layer_body">
          <slot name="body"> </slot>
        </div>

        <div class="layer_foot">
          <slot name="foot"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    disableHead: {
      type: Boolean,
      dafault: false,
      required: false,
    },
    modalTitle: {
      type: String,
      default: null,
      required: true
    },
    modalType: {
      type: String,
      default: null,
      required: true
    }
  },
  methods: {
    closeModal() {
      document.body.classList.remove('noscroll');
      this.$emit('close-modal');
    }
  }
};
</script>
