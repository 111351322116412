const lecture = require('../store/modules/classroom/lecture');
const LECTURE_TYPE_CD = {
  GENERAL: 1,
  DANGWA: 2,
  SPECIAL: 3,
  isGeneral(lectureTypeCd) {
    if (!lectureTypeCd) {
      return false;
    }
    return Number(lectureTypeCd) === LECTURE_TYPE_CD.GENERAL;
  }
};

const REGSTATE_CD = {
  ASSIGN: 1,
  STANDBY: 6,
  TEMP_ASSIGN: 7,
  isAssign(regstateCd) {
    if (!regstateCd) {
      return false;
    }
    return Number(regstateCd) === REGSTATE_CD.ASSIGN;
  },
  isStandBy(regstateCd) {
    if (!regstateCd) {
      return false;
    }
    return Number(regstateCd) === REGSTATE_CD.STANDBY;
  },
  isTempAssign(regstateCd) {
    if (!regstateCd) {
      return false;
    }
    return Number(regstateCd) === REGSTATE_CD.TEMP_ASSIGN;
  }
};

module.exports = {
  LECTURE_TYPE_CD,
  REGSTATE_CD
};
