<template>
  <div class="popup_cont" style="width:930px;">
    <div class="popup_head borderless">
      <strong class="tit_popup">강좌 확인하기</strong>
    </div>
    <div class="popup_body">
      <div class="box_table type2">
        <table class="tbl_comm">
          <caption class="blind"></caption>
          <colgroup>
            <col style="width:80px;" />
            <col style="width:120px;" />
            <col style="width:auto;" />
            <col style="width:100px;" />
            <col style="width:90px;" />
            <col style="width:80px;" />
            <col style="width:180px;" />
            <col style="width:70px;" />
          </colgroup>
          <thead>
            <tr>
              <th>강좌유형</th>
              <th>과목/강사</th>
              <th>강좌</th>
              <th>수강기간</th>
              <th>요일/교시</th>
              <th>수강상태</th>
              <th>수강상태 변경</th>
              <th>시간표 노출</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in mainList" :key="idx">
              <td>{{ item.lectureTypeCd == '1' ? '재종반' : lectureFilter(item.groupForm) }}</td>
              <td class="ta_l">[{{ item.subject }}] {{ item.teacherName }}</td>
              <td class="ta_l" v-html="item.groupName"></td>
              <td class="ta_l">
                <span :class="{ fc_red: duplicateCheck(item.grpIdx, item.groupSdate, item.groupEdate) }"> {{ momentFormat(item.groupSdate) }}~<br />{{ momentFormat(item.groupEdate) }} </span>
              </td>
              <td style="white-space: pre">{{ item.weekData }}</td>
              <td :class="{ 'bg_color type5': item.waitingOrder > 0 }">
                {{ regstateFilter(item.regStateCd, item.waitingOrder) }}
              </td>
              <td>
                <span class="wrap_check">
                  <label class="lbl_check">
                    <input type="checkbox" class="inp_check" :value="idx + '-' + item.grpIdx + '-' + item.grpasnIdx" v-model="cancel" :disabled="item.lectureTypeCd === '2' || isCancel === 'N'" />
                    <span class="ico_check"></span>취소
                  </label>
                </span>
                <span class="wrap_select">
                  <select class="selbox_comm" @change="cancelReason($event)" :disabled="item.regStateCd === '8' || item.lectureTypeCd === '2' || isCancel === 'N'">
                    <option v-for="(opt, i) in options" :key="i" :value="item.grpIdx + ',' + opt.cd" :selected="opt.cd === 0">
                      {{ opt.nm }}
                    </option>
                  </select>
                </span>
              </td>
              <td>
                <span class="wrap_radio">
                  <label class="lbl_radio">
                    <input type="radio" class="inp_radio" v-model="priority" :value="item.grpIdx" />
                    <span class="ico_radio"></span>선택
                  </label>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="appendix">
          <ul class="list_info">
            <li>* 운영기간이 빨간색으로 표시되고 있으면, 중복 강좌 입니다. 중복되지 않도록 취소해 주세요.</li>
            <li>* 시간표 노출 선택 시, 시간표에 선택한 강좌가 보여집니다.</li>
          </ul>
        </div>
      </div>

      <div class="box_button">
        <a href="javascript:;" @click="save()" class="btn_comm type1"><span class="inner">저장</span></a>
        <a href="javascript:self.close();" class="btn_comm type2"><span class="inner">닫기</span></a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import { PAGE_TITLE } from '../../../const/const';

export default {
  data() {
    return {
      mainList: [],
      cancel: [],
      priority: '',
      reason: new Map(),
      duplicate: new Map(),
      dateList: [],
      options: [
        { cd: 0, nm: '취소사유선택' },
        { cd: 1, nm: '다른 강좌 수강 희망' },
        { cd: 2, nm: '강의 수준 맞지 않음' },
        { cd: 3, nm: '강의 내용 불만족' },
        { cd: 4, nm: '선생님 불만족' }
      ],
      mngArray: [],
      groupNameArray: [],
      branchCd: '',
      studentCd: '',
      studyRegStartDt: '',
      studyRegEndDt: '',
      isCancel: 'N',
      seasonYear: '',
      seasonCd: ''
    };
  },
  props: ['close'],
  components: {},
  created() {
    const curDt = moment().format('YYYYMMDD');
    this.studyRegStartDt = this.$route.query.studyRegStartDt;
    this.studyRegEndDt = this.$route.query.studyRegEndDt;
    if (this.studyRegStartDt <= curDt && this.studyRegEndDt >= curDt && this.studyRegEndDt !== '') {
      this.isCancel = 'Y';
    }

    this.seasonYear = this.$route.query.seasonYear;
    this.seasonCd = this.$route.query.seasonCd;

    if (!this.userInfo.studentCd) {
      this.getUser().then(() => {
        this.requestScheduleList();
      });
    } else {
      this.requestScheduleList();
    }

    this.changeTitle();
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.common.userInfo,
      lectureCheckList: (state) => state.classroom.lecture.lectureCheckList,
      lectureCancelData: (state) => state.classroom.lecture.lectureCancelData,
      scheduleList: (state) => state.classroom.schedule.scheduleList,
      seasonPerdMap: (state) => state.classroom.schedule.seasonPerdMap
    })
  },
  methods: {
    ...mapActions(['getLectureCheck', 'postBaisSort', 'getUser', 'lectureCancel', 'getScheduleList']),
    changeTitle() {
      let title = '';
      if (this.userInfo.branchCd !== '') title = PAGE_TITLE[this.userInfo.branchCd];
      document.title = 'mypage_수강신청 | ' + title;
    },
    requestScheduleList() {
      const param = {
        seasonYear: this.seasonYear,
        seasonCd: this.seasonCd,
        branchCd: this.$route.query.branchCd
      };
      this.getScheduleList(param).then(() => {
        this.LectureCheck();
      });
    },
    LectureCheck() {
      const params = {};
      params.grpIdx = this.$route.query.grpIdx;
      params.branchCd = this.$route.query.branchCd;
      params.studentCd = this.$route.query.studentCd;

      const days = ['monYn', 'tueYn', 'wedYn', 'thuYn', 'friYn', 'satYn', 'sunYn'];
      this.getLectureCheck(params).finally(() => {
        if (this.lectureCheckList.length > 0) {
          this.lectureCheckList.forEach((e, i) => {
            let List = [];
            const array = [];
            const weekChk = [];
            e.forEach((ee) => {
              days.forEach((d) => {
                if (ee[d] === 'Y') {
                  //월 1교시 월 2교시
                  let week = '';
                  const val = d;
                  if (val === 'monYn') {
                    week = '월';
                  } else if (val === 'tueYn') {
                    week = '화';
                  } else if (val === 'wedYn') {
                    week = '수';
                  } else if (val === 'thuYn') {
                    week = '목';
                  } else if (val === 'friYn') {
                    week = '금';
                  } else if (val === 'satYn') {
                    week = '토';
                  } else if (val === 'sunYn') {
                    week = '일';
                  }
                  //let chk = this.weekArray[week].filter((item, idx) => {item.indexOf(ee.perdCd) > -1})
                  if (weekChk.indexOf(week) === -1) {
                    //중복아님
                    if (week === '') {
                      return;
                    }
                    const row = {};
                    const yearSeasonMap = this.seasonPerdMap[this.seasonYear] || {};
                    const perdMap = yearSeasonMap[this.seasonCd] || {};
                    row.perdCd = perdMap[ee.perdCd] || ee.perdCd + '교시';
                    row.week = week;
                    weekChk.push(week);
                    array.push(row);
                  } else {
                    //중복 교시만 합쳐서 출력
                    array.filter((item) => {
                      if (item.week === week) {
                        const yearSeasonMap = this.seasonPerdMap[this.seasonYear] || {};
                        const perdMap = yearSeasonMap[this.seasonCd] || {};
                        const name = perdMap[ee.perdCd] || ee.perdCd + '교시';
                        item.perdCd = item.perdCd + ',' + name;
                      }
                    });
                  }
                }
              });
            });

            e[0].weekData = '';
            array.forEach((item) => {
              // 다수의 요일 일때
              e[0].weekData += item.week + ' ' + item.perdCd + '\n';
            });

            List = e[0];
            if (List.baisSort === '0') {
              this.baisSort = List.grpIdx;
              this.priority = List.grpIdx;
            }
            //this.mngArray[i] = [List.groupSdate, List.groupEdate];
            this.dateList.push({ grpIdx: List.grpIdx, groupSdate: List.groupSdate, groupEdate: List.groupEdate });
            this.duplicate.get(List.groupSdate) === undefined ? this.duplicate.set(List.groupSdate, '0') : this.duplicate.set(List.groupSdate, '1');
            this.groupNameArray[i] = List.groupName;
            this.mainList.push(List);
          });
          if (this.baisSort == 0) {
            this.baisSort = this.mainList[0].grpIdx;
          }
        }
      });
    },
    lectureFilter(groupForm) {
      if (groupForm === '003') {
        return '특강';
      } else {
        return '단과';
      }
    },
    regstateFilter(regstateCd, waitingOrder) {
      let regState = '';

      if (regstateCd === '1') {
        regState = '수강';
      } else if (regstateCd === '2') {
        regState = '휴강';
      } else if (regstateCd === '3') {
        regState = '탈퇴';
      } else if (regstateCd === '4') {
        regState = '수료';
      } else if (regstateCd === '5') {
        regState = '전반';
      } else if (regstateCd === '6') {
        regState = '대기(' + (waitingOrder || '') + '번)';
      } else if (regstateCd === '7') {
        regState = '가배정';
      } else if (regstateCd === '8') {
        regState = '취소';
      }

      return regState;
    },
    momentFormat(groupDate) {
      return moment(groupDate).format('YYYY.MM.DD');
    },
    duplicateClass(groupDate) {
      return this.duplicate.get(groupDate) === '1' ? 'Y' : 'N';
    },
    duplicateCheck(idx, sdate, edate) {
      let cnt = 0;
      this.dateList.forEach((e) => {
        if (e.grpIdx !== idx) {
          if ((sdate <= e.groupSdate && edate >= e.groupEdate) || (sdate <= e.groupEdate && edate >= e.groupSdate)) {
            ++cnt;
          }
        }
      });
      return cnt > 0;
    },
    cancelReason(e) {
      this.reason.set(e.target.value.split(',')[0], e.target.value.split(',')[1]);
    },
    save() {
      if (confirm('해당 내용을  변경하시겠습니까?')) {
        this.saveExposureLecture();
        //this.cancelLecture();
        setTimeout(() => {
          self.close();
        }, 1000);
      }
    },
    saveExposureLecture() {
      if (this.priority === '') {
        return;
      }

      const params = {};
      params.branchCd = this.$route.query.branchCd;
      params.seasonCd = this.$route.query.seasonCd;
      params.seasonYear = this.$route.query.seasonYear;
      params.studentCd = this.$route.query.studentCd;
      //params.seasonCd = '3';
      //params.seasonYear = '2020';

      const dataList = [];
      let i = 0;
      this.mainList.forEach((e) => {
        const data = {};
        if (this.priority == e.grpIdx) {
          data['studentCd'] = e.studentCd;
          data['grpIdx'] = e.grpIdx;
          data['baisSort'] = '0';
        } else {
          data['studentCd'] = e.studentCd;
          data['grpIdx'] = e.grpIdx;
          data['baisSort'] = '1';
        }
        dataList[i] = data;
        i++;
      });
      params.requestList = dataList;
      this.postBaisSort(params).finally(() => {
        this.cancelLecture();
      });
    },
    cancelLecture() {
      const result = [];
      let params = {};

      this.cancel.forEach((item) => {
        params = {};

        params['branchCd'] = this.$route.query.branchCd;
        params['studentCd'] = this.$route.query.studentCd;
        //params["branchCd"] =  "0130";
        //params["studentCd"] = "316107";

        if (this.reason.get(item.split('-')[1]) !== '') {
          params['cancelReasonCd'] = this.reason.get(item.split('-')[1]);
        }
        //console.log(params['cancelReasonCd']);
        if (params.cancelReasonCd === '' || params.cancelReasonCd === undefined) params['cancelReason'] = '0';

        params['groups'] = [{ grpIdx: item.split('-')[1], grpasnIdx: item.split('-')[2] }];

        this.lectureCancel(params).finally(() => {
          const res = this.lectureCancelData;
          if (res.code !== '00000') {
            result.push(this.groupNameArray[item.split('-')[0]]);
          }
        });
      });

      if (result.length === 0 && this.cancel.length > 0) {
        alert('수강취소를 완료했습니다.');
        this.$emit('refresh');
        return;
      }

      if (this.cancel.length > 0) {
        let failGroupName = '';
        for (var j = 0; j < result.length; j++) {
          if (j === 0) {
            failGroupName += result[j];
          } else {
            failGroupName += ', ' + result[j];
          }
        }
        alert(failGroupName + '항목에 수강취소를 실패했습니다.');
      }
    }
  }
};
</script>
