<template>
  <div v-bind:id="id" v-bind:class="className">
    <layout>
      <router-view />
    </layout>
  </div>
</template>

<script>
import Layout from '@/components/layout/Layout.vue';
import { ACADEMY_CLASS_NAME, META_SUBDOMAIN } from './const/const';
import { mapState, mapActions } from 'vuex';
import { AWS_S3_RESOURCE_URL } from '@build/build.config';
import TmsCookieUtil from './utils/TmsCookieUtil';
import { getBrandType } from './utils/util';
import MobileDetect from 'mobile-detect';
export default {
  name: 'App',
  components: { Layout },
  data() {
    return {
      //
      id: '',
      className: ACADEMY_CLASS_NAME[getBrandType(location.href)]
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile
    })
  },
  beforeCreate() {
    const S3_PC_CSS_URL = AWS_S3_RESOURCE_URL + '/my/pc/v2/css';
    const versionDate = '20230427';
    const md = new MobileDetect(window.navigator.userAgent);
    if (md.phone()) {
      if (location.href.indexOf('cheongsol') > -1 || location.href.indexOf('gisuk') > -1) {
        window.loadcssfile(AWS_S3_RESOURCE_URL + '/cs/mobile/branch/css/common.css');
        window.loadcssfile(AWS_S3_RESOURCE_URL + `/my/mobile/v2/css/cs/contents.css?ver=${versionDate}`);
        window.loadcssfile(S3_PC_CSS_URL + `/pay.css?ver=${versionDate}`);
      } else if (location.href.indexOf('hyper') > -1) {
        window.loadcssfile(AWS_S3_RESOURCE_URL + '/hp/mobile/css/common.css');
        window.loadcssfile(AWS_S3_RESOURCE_URL + `/my/mobile/v2/css/hp/contents.css?ver=${versionDate}`);
        window.loadcssfile(S3_PC_CSS_URL + `/pay.css?ver=${versionDate}`);
        window.loadcssfile('https://img.cheongsol.co.kr/mobile2018/hp/branch/css/Ncontents.css');
      } else if (location.href.indexOf('answer') > -1) {
        window.loadcssfile(AWS_S3_RESOURCE_URL + `/answer/mobile/css/common.css?ver=${versionDate}`);
        window.loadcssfile(AWS_S3_RESOURCE_URL + `/my/mobile/v2/css/answer/contents.css?ver=${versionDate}`);
      } else if (location.href.indexOf('neoneo') > -1) {
        const metaSubDomain = TmsCookieUtil.getCookie('metaSubDomain') || '';
        if (metaSubDomain !== '') {
          if (metaSubDomain === 'n1') {
            window.loadcssfile(AWS_S3_RESOURCE_URL + `/neo/mobile/css/common.css?ver=${versionDate}`);
            window.loadcssfile(`https://img.cheongsol.co.kr/mobile2018/neo/branch/css/contents.css?ver=${versionDate}`);
          } else if (META_SUBDOMAIN.cs.includes(metaSubDomain)) {
            window.loadcssfile(AWS_S3_RESOURCE_URL + '/cs/mobile/branch/css/common.css');
            window.loadcssfile(AWS_S3_RESOURCE_URL + `/my/mobile/v2/css/cs/contents.css?ver=${versionDate}`);
            window.loadcssfile(S3_PC_CSS_URL + `/pay.css?ver=${versionDate}`);
          } else if (META_SUBDOMAIN.as.includes(metaSubDomain)) {
            window.loadcssfile(AWS_S3_RESOURCE_URL + `/answer/mobile/css/common.css?ver=${versionDate}`);
            window.loadcssfile(AWS_S3_RESOURCE_URL + `/my/mobile/v2/css/answer/contents.css?ver=${versionDate}`);
          }
        } else {
          window.loadcssfile(AWS_S3_RESOURCE_URL + `/neo/mobile/css/common.css?ver=${versionDate}`);
          window.loadcssfile(`https://img.cheongsol.co.kr/mobile2018/neo/branch/css/contents.css?ver=${versionDate}`);
        }
      }
    } else {
      window.loadcssfile(AWS_S3_RESOURCE_URL + `/classroom/css/common.css?ver=${versionDate}`);
      window.loadcssfile('https://img.cheongsol.co.kr/web2018/cs/branch/css/contents.css');
      window.loadcssfile(S3_PC_CSS_URL + `/common.css?ver=${versionDate}`);
      window.loadcssfile(AWS_S3_RESOURCE_URL + `/cs/pc/branch/css/pay.css?ver=${versionDate}`);
    }
    window.loadcssfile(S3_PC_CSS_URL + '/swiper-bundle.min.css?ver=202010200903');
    window.loadcssfile(S3_PC_CSS_URL + '/mypage_comm.css');
    window.loadcssfile(S3_PC_CSS_URL + '/buefy_datepicker.css?ver=20211125');
  },
  created() {
    this.setIsMobile();
    this.getUser();
    TmsCookieUtil.setTmsToken();
  },
  beforeUpdate() {
    if (this.isMobile) {
      if (this.$route.meta.id === 'container') {
        this.id = 'wrapper';
      } else if (this.$route.meta.id === 'unityexam') {
        this.id = 'unityexam';
      } else {
        this.id = 'mathpop';
      }
      if (this.className === 'answer') {
        this.className = 'y2019 answer';
      }
      if (this.className === 'neo') {
        this.className = 'mypage neo';
      }
    } else if (this.$route.meta.id === 'unityexam') {
      this.id = 'unityexam';
    } else {
      this.id = this.$route.meta.id || 'mathpop';
    }
  },
  methods: {
    ...mapActions(['setIsMobile', 'getUser'])
  }
};
</script>
