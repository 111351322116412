<template>
  <div v-show="isCssLoaded">
    <component :is="headerName" :userInfo="userInfo" @toggleAcaNetwork="toggleAcaNetwork" />
    <div id="section">
      <!-- Content -->
      <div class="content">
        <!-- 컨텐츠 영역 -->
        <div class="inner">
          <div class="pay_area">
            <right-navi :userInfo="userInfo" />
            <!-- router view -->
            <slot></slot>
            <!-- // router view -->
          </div>
        </div>
      </div>
    </div>
    <component :is="footerName" :userInfo="userInfo" />
    <aca-network v-show="showNetwork" @toggleAcaNetwork="toggleAcaNetwork" />
  </div>
</template>

<script>
import MyPageHyperHeader from '../layout/MyPage/hyper/Header';
import MyPageHyperFooter from '../layout/MyPage/hyper/Footer';
import MyPageCheongsolHeader from '../layout/MyPage/cheongsol/Header';
import MyPageCheongsolFooter from '../layout/MyPage/cheongsol/Footer';
import MyPageNeoneoHeader from '../layout/MyPage/neoneo/Header';
import MyPageNeoneoFooter from '../layout/MyPage/neoneo/Footer';
import MyPageAnswerHeader from '../layout/MyPage/answer/Header';
import MyPageAnswerFooter from '../layout/MyPage/answer/Footer';
import RightNavi from '../layout/MyPage/RightNavi';

import AcaNetwork from './MyPage/AcaNetwork';

import { MYPAGE_HEADER_NAME, MYPAGE_FOOTER_NAME } from '@/const/const';

import { mapState } from 'vuex';
import { getBrandType } from '@/utils/util';
import crypto from 'crypto';

export default {
  name: 'MyPageLayout',
  components: {
    MyPageHyperHeader,
    MyPageCheongsolHeader,
    RightNavi,
    MyPageHyperFooter,
    MyPageCheongsolFooter,
    MyPageNeoneoHeader,
    MyPageNeoneoFooter,
    MyPageAnswerHeader,
    MyPageAnswerFooter,
    AcaNetwork
  },
  data() {
    return {
      headerName: MYPAGE_HEADER_NAME[getBrandType(location.href)],
      footerName: MYPAGE_FOOTER_NAME[getBrandType(location.href)],
      showNetwork: false,
      isCssLoaded: false,
      cssFileName: 'buefy_datepicker.css', //MyPageLayout.vue, MobileLayout.vue 제일 마지막 호출, 제일 큰 파일
      checkInterval: 100 // 100ms 간격으로 확인
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.common.userInfo
    })
  },
  created() {
    this.checkCssFileLoaded();
  },
  methods: {
    toggleAcaNetwork(flag) {
      this.showNetwork = flag;
    },
    isCssFileLoaded() {
      const styleSheets = document.styleSheets;

      for (let i = 0; i < styleSheets.length; i++) {
        const styleSheet = styleSheets[i];

        if (styleSheet.href && styleSheet.href.includes(this.cssFileName)) {
          return true;
        }
      }

      return false;
    },
    checkCssFileLoaded() {
      const check = () => {
        if (this.isCssFileLoaded()) {
          this.isCssLoaded = true;
        } else {
          if (!this.isCssLoaded) {
            setTimeout(check, this.checkInterval);
          }
        }
      };
      check();
    }
  }
  /*mounted() {
    const user_id = crypto
      .createHash('sha256')
      .update(this.userInfo.personVo.unityMemNo)
      .digest('base64');
    console.log(user_id);
    this.$gtag.config({ user_id: 'qa - ' + user_id });
  }*/
};
</script>
