<template>
  <!-- 오답노트 -->
  <div class="popup_cont popup_ansr dailymath_pop_v2" :class="{ mobile: isMobile }">
    <div class="popup_head">
      <h1 class="tit_popup">오답노트</h1>
      <div class="type_check">
        <label class="lab_check" for="typeCheck1">
          <input type="checkbox" id="typeCheck1" @change="includeSmlQustn($event)" checked="checked" class="inp_check" name="typeCheck" />
          <span class="ico_check"></span>
          유사문항 포함
        </label>
      </div>
      <div class="switch_cont">
        <button
          type="button"
          id="switch1"
          class="btn_type"
          :class="{ on: contentView == 'type1' }"
          @click="contentView = 'type1'"
        >
          문항만
        </button>
        <button
          type="button"
          id="switch2"
          class="btn_type"
          :class="{ on: contentView == 'type2' }"
          @click="contentView = 'type2'"
        >
          문항+해설
        </button>
      </div>
      <div class="expander">
        <div class="exam_subject">
          <span class="txt_subject">{{ (this.wransrnoteTableExamInfo[activeLiIndex] || {}).unityExamNm }}</span>
          <span class="txt_subject">{{ (this.wransrnoteTableExamInfo[activeLiIndex] || {}).unityDtlExamNm }}</span>
        </div>
        <div class="wrap_select">
          <select class="select_cont" v-model="selectTurnOrdSeq">
            <option v-for="(key, idx) in sortedTurnOrdsKey" :key="key" :value="key">{{ turnOrds[key] }}회</option>
          </select>
        </div>
        <button type="button" class="btn_print" @click="isShowPrinteSettingLayer = true">
          <span class="blind">인쇄하기</span>
        </button>
      </div>
    </div>
    <div class="popup_body">
      <div v-if="qustnList.length > 0" class="wrap_exam_paper type2">
        <div class="paper_info" :class="{ dimmed: isOpenQuestionNumber, active: isActive }">
          <!--//class active추가하면 문항보기 영역 보임 -->
          <h2 class="tit_paper mo_only">오답노트</h2>
          <button type="button" class="btn_popup_close" @click="closeViews()">닫기</button>
          <table class="tbl_comm">
            <caption>
              문항 정보
            </caption>
            <colgroup>
              <col style="width:90px" />
              <col style="width:auto" />
              <col style="width:90px" />
              <col style="width:80px" />
              <col style="width:90px" />
              <col style="width:80px" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">문항분류</th>
                <td v-if="qustnList[activeLiIndex][activeIndex].qustnPubrNm === null" class="data_cont">
                  -
                </td>
                <td v-else class="data_cont">
                  {{ qustnList[activeLiIndex][activeIndex].qustnPubrNm }} >
                  {{ qustnList[activeLiIndex][activeIndex].qustnLuntNm }} >
                  {{ qustnList[activeLiIndex][activeIndex].qustnSbptNm }} >
                  {{ qustnList[activeLiIndex][activeIndex].qustnSmunitNm }}
                </td>
                <th scope="row">오답률</th>
                <td>
                  {{
                    qustnList[activeLiIndex][activeIndex].coransrRt === null
                      ? '-'
                      : Number(100 - qustnList[activeLiIndex][activeIndex].coransrRt) + '%'
                  }}
                </td>
                <th scope="row">해설영상</th>
                <td>
                  <button
                      type="button"
                      v-show="qustnList[activeLiIndex][activeIndex].explCurriVodPathwy"
                      @click="showExplVod(qustnList[activeLiIndex][activeIndex].explCurriVodPathwy, qustnList[activeLiIndex][activeIndex].qustnInpMethodCd)"
                      class="btn_vod"
                  >
                    보기
                  </button>
                  <a v-if="!qustnList[activeLiIndex][activeIndex].explCurriVodPathwy">-</a>
                </td>
              </tr>
              <tr>
                <th scope="row">{{ qustnList[activeLiIndex][activeIndex].coransrYn === 'Y' ? '정답원인' : '오답원인' }}</th>
                <td colspan="5" class="data_cont">
                  <div class="type_check">
                    <label
                        v-for="(item, idx) in qustnList[activeLiIndex][activeIndex].coransrYn === 'Y'
                        ? answerCause
                        : wransrCause"
                        @change="saveCause(item.causeData)"
                        :key="idx"
                        class="lab_radio"
                        :for="'typeRadio' + idx"
                        v-show="qustnList[activeLiIndex][activeIndex].no.indexOf('-') == -1"
                    >
                      <input
                          v-if="item.causeLabel !== '기타'"
                          type="radio"
                          :id="'typeRadio' + idx"
                          :checked="
                          item.causeData === qustnList[activeLiIndex][activeIndex].wransrCauseCd ||
                            item.causeData === qustnList[activeLiIndex][activeIndex].coransrCauseCd
                        "
                          class="inp_radio"
                          name="typeRadio"
                          :disabled="USER_AUTHORITY.PARENT === userInfo.userAuthority"
                      />
                      <input
                          v-if="item.causeLabel === '기타'"
                          @click="isShowInputReasonLayer = true"
                          type="radio"
                          :id="'typeRadio' + idx"
                          :checked="
                          item.causeData === qustnList[activeLiIndex][activeIndex].wransrCauseCd ||
                            item.causeData === qustnList[activeLiIndex][activeIndex].coransrCauseCd
                        "
                          class="inp_radio"
                          name="typeRadio"
                          :disabled="USER_AUTHORITY.PARENT === userInfo.userAuthority"
                      />
                      <span class="ico_radio"></span>
                      {{ item.causeLabel }}
                      <span
                          v-if="
                          item.causeLabel === '기타' &&
                            qustnList[activeLiIndex][activeIndex].coransrWransrNotesEtcOpninCont !== null
                        "
                          class="txt_reason"
                      >({{ qustnList[activeLiIndex][activeIndex].coransrWransrNotesEtcOpninCont }})</span
                      >
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="nav_num mo_only" :class="{ open: isOpenQuestionNumber }">
            <!-- open -->
            <button type="button" class="btn_open" @click="isOpenQuestionNumber = !isOpenQuestionNumber" :disabled="qustnList[activeLiIndex].length < 6">
              열기/닫기
            </button>
            <ul class="list_number">
              <li
                v-for="(item, index) in qustnList[activeLiIndex]"
                v-show="isOpenQuestionNumber || isDisplayIndex(index)"
                :key="index"
                :class="{ on: isActiveOn === index }"
              >
                <a
                  href="javascript:;"
                  class="num_order"
                  :class="{ incorrect: item.coransrYn !== 'Y', correct: item.coransrYn === 'Y' }"
                  @click="toggleActive(index)"
                >
                  {{ item.no }}
                </a>
              </li>
            </ul>
          </div>
          <div class="tab_exam">
            <ul class="list_tab">
              <li :class="{ on: contentView == 'type1' }">
                <button type="button" class="btn_tab" @click="contentView = 'type1'">
                  문항
                </button>
              </li>
              <li :class="{ on: contentView == 'type2' }">
                <button type="button" class="btn_tab" @click="contentView = 'type2'">
                  해설
                </button>
              </li>
            </ul>
          </div>
          <div class="exam_cont type2">
            <div class="box_question" :class="{ on: contentView == 'type1' }">
              <strong class="blind">지문/문항</strong>
              <div v-if="StringUtils.isNotEmpty((qustnList[activeLiIndex][activeIndex] || {}).textImgPathwy) && qustnList[activeLiIndex][activeIndex].qustnInpMethodCd === '1'">
                <img :src="awsResourceUrl + '/unity-exam/' + qustnList[activeLiIndex][activeIndex].textImgPathwy" alt="" />
              </div>
              <div v-if="StringUtils.isNotEmpty((qustnList[activeLiIndex][activeIndex] || {}).textImgPathwy) && qustnList[activeLiIndex][activeIndex].qustnInpMethodCd === '2'">
                <img :src="getTmsImg(qustnList[activeLiIndex][activeIndex].textImgPathwy)" alt="" />
              </div>
              <div v-if="StringUtils.isNotEmpty((qustnList[activeLiIndex][activeIndex] || {}).qustnImgPathwy)">
                <img
                    v-if="qustnList[activeLiIndex][activeIndex].qustnInpMethodCd === '1'"
                    :src="awsResourceUrl + '/unity-exam/' + qustnList[activeLiIndex][activeIndex].qustnImgPathwy"
                    alt=""
                />
                <img
                    v-if="qustnList[activeLiIndex][activeIndex].qustnInpMethodCd === '2'"
                    :src="getTmsImg(qustnList[activeLiIndex][activeIndex].qustnImgPathwy)"
                    alt=""
                />
              </div>
              <div v-else>
                <p class="blank">문항이미지가 없습니다.</p>
              </div>
            </div>
            <div class="box_explain" :class="{ on: contentView == 'type2' }">
              <strong class="blind">해설</strong>
              <div v-if="StringUtils.isNotEmpty((qustnList[activeLiIndex][activeIndex] || {}).explImgPathwy)">
                <img
                    v-if="qustnList[activeLiIndex][activeIndex].qustnInpMethodCd === '1'"
                    :src="awsResourceUrl + '/unity-exam/' + qustnList[activeLiIndex][activeIndex].explImgPathwy"
                    alt=""
                />
                <img
                    v-if="qustnList[activeLiIndex][activeIndex].qustnInpMethodCd === '2'"
                    :src="getTmsImg(qustnList[activeLiIndex][activeIndex].explImgPathwy)"
                    alt=""
                />
              </div>
              <div v-else>
                <p class="blank">해설이미지가 없습니다.</p>
              </div>
            </div>
            <button v-show="this.activeIndex !== 0" type="button" @click="qustnPrev" class="btn_prev">이전</button>
            <button v-show="this.activeIndex + 1 !== this.qustnList[this.activeLiIndex].length" type="button" @click="qustnNext" class="btn_next">다음</button>
            <div class="bar_bottom_tool mo_only">
              <select v-show="qustnList[activeLiIndex][activeIndex].no.indexOf('-') == -1" class="select_ansr_type" @change="showInputReasonLayer" v-model="reasonWrongAnswer" :disabled="USER_AUTHORITY.PARENT === userInfo.userAuthority">
                <option v-if="qustnList[activeLiIndex][activeIndex].coransrYn === 'Y'" value="">정답원인선택</option>
                <option v-if="qustnList[activeLiIndex][activeIndex].coransrYn === 'N'" value="">오답원인선택</option>
                <option
                    v-for="(item, idx) in qustnList[activeLiIndex][activeIndex].coransrYn === 'Y'
                    ? answerCause
                    : wransrCause"
                    :key="idx"
                    :value="item.causeData"
                >{{ item.causeLabel }} {{ item.causeLabel === '기타' &&
                qustnList[activeLiIndex][activeIndex].coransrWransrNotesEtcOpninCont !== null
                    ? '(' + qustnList[activeLiIndex][activeIndex].coransrWransrNotesEtcOpninCont + ')'
                    : ''
                  }}</option
                >
              </select>
              <div class="check_review">
                <label class="lab_check" for="checkReview">
                  <input
                    type="checkbox"
                    id="checkReview"
                    class="inp_check"
                    name="checkReview"
                    @change="changeRegiReviEd(qustnList[activeLiIndex][activeIndex], $event)"
                    :checked="qustnList[activeLiIndex][activeIndex].reviEdYn === 'Y'"
                  />
                  <span class="ico_check"></span>
                    복습
                </label>
              </div>
            </div>
            <button
                v-show="StringUtils.isNotEmpty((qustnList[activeLiIndex][activeIndex] || {}).explCurriVodPathwy) && contentView === 'type2'"
                @click="showExplVod(qustnList[activeLiIndex][activeIndex].explCurriVodPathwy, qustnList[activeLiIndex][activeIndex].qustnInpMethodCd)"
                type="button"
                class="btn_vod_play"
            >
              해설영상<br />보기
            </button>
          </div>
        </div>
        <div class="exam_sheet">
          <aside-list
            ref="aside_list_wransr_qustn_view"
            v-for="(item, index) in categories"
            :title="item.dtlSubjtNm"
            :subjtNm="item.subjtNm"
            :active="index === activeLiIndex"
            :index="index"
            :turn-ord="item.unityDtlExamTurnOrd"
            :add-turn-ord="showAll"
            :key="item.dtlSubjtNm"
            :questionViewType="3"
            @newactive="activeLiIndex = $event"
            :Limits="qustnList[index]"
            @questionViews="isActive = true"
            @qustnPrev="qustnPrev()"
            @qustnNext="qustnNext()"
            @qustnIndex="qustnIndex"
            @changeRegiWransrNote="changeRegiWransrNote"
            @changeRegiReviEd="changeRegiReviEd"
          />
        </div>
      </div>
    </div>
    <!-- 오답원인 입력 레이어 -->
    <Modal
      v-if="isShowInputReasonLayer"
      :modalTitle="qustnList[activeLiIndex][activeIndex].coransrYn === 'Y' ? '정답원인 입력' : '오답원인 입력'"
      modalType="layer_wransr_write"
      @close-modal="isShowInputReasonLayer = false"
    >
      <div slot="head">
        <h4>{{ qustnList[activeLiIndex][activeIndex].coransrYn === 'Y' ? '정답원인 입력' : '오답원인 입력' }}</h4>
      </div>
      <div slot="body">
        <textarea v-model="reasonText" @keydown="onKeyDownReason($event)" maxlength="20" placeholder="(최대 20자)" cols="30" rows="5" class="tf_write"></textarea>
      </div>
      <div slot="foot">
        <button @click="saveCauseReasonText" type="button" class="btn_save">확인</button>
      </div>
    </Modal>
    <!-- //오답원인 입력 레이어 -->
    <expl-curri-vod
      expl-type="corWransr"
      v-if="!isMobile && showVodModalLayer"
      :explCurriVodUrl="explCurriVodUrl"
      @showExplVod="showVodModalLayer = false"
    /><!-- 해설 강의 팝업 -->
    <expl-curri-vod-mobile
      expl-type="corWransr"
      v-if="isMobile && showVodModalLayer"
      :explCurriVodUrl="explCurriVodUrl"
      @showExplVod="showVodModalLayer = false"
    /><!-- 해설 강의 팝업 -->
    <!-- 오답노트 인쇄 설정 레이어 -->
    <Modal
      v-if="isShowPrinteSettingLayer"
      modalTitle="오답노트 인쇄 설정"
      modalType="layer_wransr_setting"
      @close-modal="isShowPrinteSettingLayer = false"
    >
      <div slot="head">
        <h4>오답노트 인쇄 설정</h4>
      </div>
      <div slot="body" class="data_setting">
        <dl>
          <dt>
            <div class="type_check">
              <label for="subjectAllRadio" class="lab_check">
                <input type="checkbox" id="subjectAllRadio" class="inp_check" name="subjectAllRadio" @change="subjectPrintAll($event)"/>
                <span class="ico_check"></span>
                <span>과목</span>
              </label>
            </div>
          </dt>
          <dd>
            문항번호
          </dd>
        </dl>
        <dl v-for="(item, index) in categories">
          <dt>
            <div class="type_check">
              <label :for="`subjectRadio${index}`" class="lab_check">
                <input type="checkbox" :id="`subjectRadio${index}`" class="inp_check" :name="`subjectRadio${index}`" :value="item.unityExamSubjtSeq" :checked="activeLiIndex === index"/>
                <span class="ico_check"></span>
                <span>{{ item.subjtNm }}({{ item.dtlSubjtNm }})</span>
              </label>
            </div>
          </dt>
          <dd>
            {{ getQustnNoPrint(index) }}
          </dd>
        </dl>
      </div>
      <div slot="foot">
        <button type="button" class="btn_print" @click="openPopup('unityExamWranNotePrintPopup')">확인</button>
      </div>
    </Modal>
    <!-- //오답노트 인쇄 설정 레이어 -->
  </div>
  <!-- //오답노트 -->
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AsideList from '@/components/unityexam/common/popup/AsideList';
import Modal from '@/components/unityexam/common/Modal';
import { windowOpenPopup } from '@/mixins/windowOpenPopup';
import ExplCurriVod from '@/components/dailytest/taketest/ExplCurriVod';
import ExplCurriVodMobile from '@/components/dailytest/taketest/ExplCurriVodMobile';
import { AWS_S3_RESOURCE_URL } from '@build/build.config';
import { USER_AUTHORITY } from '@/const/const';
import { tmsImage } from '@/mixins/dailytest/tmsImage';
import StringUtils from '@/utils/StringUtils';
export default {
  name: 'unityExamWranNotePopup',
  mixins: [tmsImage, windowOpenPopup],
  components: {
    AsideList,
    Modal,
    ExplCurriVod,
    ExplCurriVodMobile
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      userInfo: (state) => state.common.userInfo,
      wransrnoteTableExamInfo: (state) => state.unityexam.wransrnote.wransrnoteTableExamInfo,
      wransrnoteQustnInfo: (state) => state.unityexam.wransrnote.wransrnoteQustnInfo,
      answerCauseList: (state) => state.unityexam.enteranswer.answerCauseList,
      wransrCauseList: (state) => state.unityexam.enteranswer.wransrCauseList
    })
  },
  mounted() {
    if (this.isMobile) {
      this.isActive = false;
    }
  },
  updated() {
    if (this.contentView === 'type1'){
      document.querySelectorAll('.sel_ansr').forEach((element) => {
        element.classList.add('blur');
      });
    } else {
      document.querySelectorAll('.sel_ansr').forEach((element) => {
        element.classList.remove('blur');
      });
    }
    const vues = this.$refs.aside_list_wransr_qustn_view;
    if (vues) {
      const viewElement = vues[this.activeLiIndex];
      viewElement.isActive = this.activeIndex;
      viewElement.index = this.activeIndex;
    }
  },
  data() {
    return {
      questionViewType: 3,
      contentView: 'type1',
      isShowQuestionInfo: false,
      isOpenQuestionNumber: false,
      reasonWrongAnswer: '',
      isShowInputReasonLayer: false,
      isShowPrinteSettingLayer: false,
      showVodModalLayer: false,
      isActive: false,
      isActiveOn: 0,
      items: 20,
      activeLiIndex: 0,
      categories: [{ title: '사회문화' }, { title: '경제' }, { title: '테스트1' }, { title: '테스트2' }],
      activeIndex: 0,
      qustnList: [],
      notIncludeSmlQustnList: [],
      includeSmlQustnList: [],
      explCurriVodUrl: '',
      awsResourceUrl: AWS_S3_RESOURCE_URL,
      answerCause: [],
      wransrCause: [],
      reasonText: '',
      USER_AUTHORITY,
      StringUtils,
      typeCheck: true,
      turnOrds: {},
      sortedTurnOrdsKey: [],
      selectTurnOrdSeq: '',
      currentTurnOrdExamInfo: {},
      initSubjtSeq: this.$route.query.subjtSeq,
      showAll: this.$route.query.showAll
    };
  },
  watch: {
    activeLiIndex: {
      immediate: true,
      handler() {
        this.activeIndex = 0;
        const vues = this.$refs.aside_list_wransr_qustn_view || [];
        const vue = vues[this.activeLiIndex] || {};
        vue.isActive = this.activeIndex;
        vue.index = this.activeIndex;
        if (this.qustnList[this.activeLiIndex].length > 0) {
          this.initQustnCauseList();
          this.intiReasonWrongAnswer();
        }
      }
    },
    isShowInputReasonLayer() {
      if (this.isShowInputReasonLayer) {
        this.reasonText = this.qustnList[this.activeLiIndex][this.activeIndex].coransrWransrNotesEtcOpninCont;
      }
    },
    isActive() {
      if (this.isActive) {
        document.querySelector('header').style.display = "none";
      } else {
        document.querySelector('header').style.display = 'block';
      }
    },
    selectTurnOrdSeq: {
      immediate: true,
      handler(seq) {
        this.categories = [];
        this.qustnList = [];
        if (!seq) {
          return;
        }
        for (const obj of this.wransrnoteTableExamInfo) {
          if ((this.showAll && this.isMobile) || Number(obj.unityDtlExamTurnOrdSeq) === Number(seq)) {
            this.categories.push(obj);
            if (this.initSubjtSeq && Number(obj.unityExamSubjtSeq) === Number(this.initSubjtSeq)) {
              this.activeLiIndex = this.categories.indexOf(obj);
              this.initSubjtSeq = 0;
            }
            this.qustnList.push(obj.includeSmlQustnResponse.includeSmlQustnList);
            this.notIncludeSmlQustnList.push(obj.includeSmlQustnResponse.qustnList);
            this.includeSmlQustnList.push(obj.includeSmlQustnResponse.includeSmlQustnList);
          }
        }
        if (this.categories.length === 0) {
          alert('등록된 오답노트가 없습니다.');
        } else {
          this.activeIndex = 0;
          this.initQustnCauseList();
          this.intiReasonWrongAnswer();
        }
      }
    }
  },
  destroyed() {
    document.querySelector('header').style.display = 'block';
  },
  created() {
    this.setHeaderInfo({ menuName: '오답노트', isPrev: false });
    if (this.isMobile) {
      this.contentView = 'type2';
    }
    if (window.NodeList && !NodeList.prototype.forEach) {
      NodeList.prototype.forEach = Array.prototype.forEach;
    }
    if (!this.userInfo.studentCd) {
      this.getUser().then(() => {
        this.getAnswerCauseData();
        this.getWransrCauseData();
        const params = {};
        params.examYy = this.$route.query['examYy'];
        params.unityExamSeq = this.$route.query['unityExamSeq'];
        params.unityDtlExamSeq = this.$route.query['unityDtlExamSeq'];
        params.unityDtlExamTurnOrdSeq = this.$route.query['unityDtlExamTurnOrdSeq'];
        this.initSelectExam(params);
      });
    } else {
      this.getAnswerCauseData();
      this.getWransrCauseData();
      const params = {};
      params.examYy = this.$route.query['examYy'];
      params.unityExamSeq = this.$route.query['unityExamSeq'];
      params.unityDtlExamSeq = this.$route.query['unityDtlExamSeq'];
      params.unityDtlExamTurnOrdSeq = this.$route.query['unityDtlExamTurnOrdSeq'];
      this.initSelectExam(params);
    }
  },
  methods: {
    ...mapActions([
      'getUser',
      'getWransrnoteTableExam',
      'getEnterAnswerExamQustn',
      'getWransrnoteQustn',
      'getAnswerCauseData',
      'getWransrCauseData',
      'postChangeRegiWransrNote',
      'postSaveCause',
      'setHeaderInfo'
    ]),
    showInputReasonLayer() {
      if (this.reasonWrongAnswer !== '') {
        if (this.reasonWrongAnswer.substring(5, 8) === '009') this.isShowInputReasonLayer = true;
        this.saveCause(this.reasonWrongAnswer);
      }
    },
    toggleActive(index) {
      this.isActiveOn = index;
      this.activeIndex = index;
      this.initQustnCauseList();
      this.intiReasonWrongAnswer();
    },
    closeViews() {
      const isViews = document.querySelector('.paper_info');
      isViews.classList.remove('active');
      this.isActive = false;
    },
    showExplVod(explCurriVodPathwy, qustnInpMethodCd) {
      this.explCurriVodUrl = '/unity-exam/' + explCurriVodPathwy;
      this.showVodModalLayer = true;
    },
    qustnCauseList(causeDivCd) {
      this.answerCause = [];
      this.wransrCause = [];
      this.answerCauseList?.forEach((item, idx) => {
        if (item.subjtData === causeDivCd) {
          this.answerCause.push(item);
        }
      });
      this.answerCause?.sort(function(a, b) {
        return Number(a.causeData.substring(5, 8)) - Number(b.causeData.substring(5, 8));
      });
      this.wransrCauseList?.forEach((item, idx) => {
        if (item.subjtData === causeDivCd) {
          this.wransrCause.push(item);
        }
      });
      this.wransrCause?.sort(function(a, b) {
        return Number(a.causeData.substring(5, 8)) - Number(b.causeData.substring(5, 8));
      });
    },
    qustnPrev() {
      if (this.activeIndex > 0) {
        this.activeIndex--;
        this.isActiveOn = this.activeIndex;
        this.$refs.aside_list_wransr_qustn_view[this.activeLiIndex].isActive = this.activeIndex;
        this.$refs.aside_list_wransr_qustn_view[this.activeLiIndex].index = this.activeIndex;
        this.initQustnCauseList();
        this.intiReasonWrongAnswer();
      }
    },
    qustnNext() {
      if (this.activeIndex < this.qustnList[this.activeLiIndex].length - 1) {
        this.activeIndex++;
        this.isActiveOn = this.activeIndex;
        this.$refs.aside_list_wransr_qustn_view[this.activeLiIndex].isActive = this.activeIndex;
        this.$refs.aside_list_wransr_qustn_view[this.activeLiIndex].index = this.activeIndex;
        this.initQustnCauseList();
        this.intiReasonWrongAnswer();
      }
    },
    qustnIndex(index) {
      this.activeIndex = index;
      this.isActiveOn = this.activeIndex;
      this.initQustnCauseList();
      this.intiReasonWrongAnswer();
    },
    changeRegiWransrNote(unityExamQustnSeq, bystdntUnityExamQustnInpStusSeq) {
      const params = {};
      params.coransrNotesYn = 'N';
      params.unityExamQustnSeq = unityExamQustnSeq;
      params.bystdntUnityExamQustnInpStusSeq = bystdntUnityExamQustnInpStusSeq;
      this.postChangeRegiWransrNote(params);
    },
    changeRegiReviEd(qustn, e) {
      const reviEdYn = e.target.checked === true ? 'Y' : 'N';
      qustn.reviEdYn = reviEdYn;

      const params = {};
      params.reviEdYn = reviEdYn;
      params.unityExamQustnSeq = qustn.unityExamQustnSeq;
      params.bystdntUnityExamQustnInpStusSeq = qustn.bystdntUnityExamQustnInpStusSeq;
      this.postChangeRegiWransrNote(params);
    },
    saveCause(reasonWrongAnswer) {
      const params = {};
      this.reasonWrongAnswer = reasonWrongAnswer;
      if (reasonWrongAnswer.indexOf('W') >= 0) {
        params.wransrCauseCd = reasonWrongAnswer;
        this.qustnList[this.activeLiIndex][this.activeIndex].wransrCauseCd = this.reasonWrongAnswer;
        this.qustnList[this.activeLiIndex][this.activeIndex].coransrCauseCd = null;
      }
      if (reasonWrongAnswer.indexOf('C') >= 0) {
        params.coransrCauseCd = reasonWrongAnswer;
        this.qustnList[this.activeLiIndex][this.activeIndex].coransrCauseCd = this.reasonWrongAnswer;
        this.qustnList[this.activeLiIndex][this.activeIndex].wransrCauseCd = null;
      }
      params.unityExamQustnSeq = this.qustnList[this.activeLiIndex][this.activeIndex].unityExamQustnSeq;
      params.bystdntUnityExamQustnInpStusSeq = this.qustnList[this.activeLiIndex][
          this.activeIndex
          ].bystdntUnityExamQustnInpStusSeq;
      this.postSaveCause(params);
    },
    saveCauseReasonText() {
      const params = {};
      params.coransrWransrNotesEtcOpninCont = this.reasonText;
      params.unityExamQustnSeq = this.qustnList[this.activeLiIndex][this.activeIndex].unityExamQustnSeq;
      params.bystdntUnityExamQustnInpStusSeq = this.qustnList[this.activeLiIndex][
          this.activeIndex
          ].bystdntUnityExamQustnInpStusSeq;
      this.postSaveCause(params);
      this.qustnList[this.activeLiIndex][this.activeIndex].coransrWransrNotesEtcOpninCont = this.reasonText;
      this.isShowInputReasonLayer = false;
    },
    openPopup(PopName) {
      const subjectAllRadio = document.querySelectorAll('input[name^="subjectRadio"]');
      let dtlSubjt = '';
      for (let i = 0; i < subjectAllRadio.length; i++) {
        if(subjectAllRadio[i].checked === true){
          if(dtlSubjt !== '') dtlSubjt += ','
          dtlSubjt += subjectAllRadio[i].value;
        }
      }
      if(dtlSubjt === ''){
        alert('출력할 과목을 선택해주세요.');
        return false;
      }
      const route = this.$router.resolve({
        name: PopName,
        query: {
          examYy: this.$route.query['examYy'],
          unityExamSeq: this.$route.query['unityExamSeq'],
          unityDtlExamSeq: this.$route.query['unityDtlExamSeq'],
          unityDtlExamTurnOrdSeq: this.$route.query['unityDtlExamTurnOrdSeq'],
          subjtSeq: this.$route.query['subjtSeq'],
          dtlSubjtSeq: dtlSubjt,
          typeCheck: this.typeCheck,
          contentView: this.contentView
        }
      });
      this.openWindowPopup(route.href, this.$route.name, PopName, this.locationReload, 'width=990, height=800, scrollbars=1');
    },
    includeSmlQustn(e) {
      if (e.target.checked){
        this.typeCheck = true;
        this.qustnList = this.includeSmlQustnList;
      } else{
        this.typeCheck = false;
        this.qustnList = this.notIncludeSmlQustnList;
      }
    },
    initSelectExam(params) {
      this.getWransrnoteTableExam(params).then(() => {
        this.sortedTurnOrdsKey = [];
        this.turnOrds = {};
        (this.wransrnoteTableExamInfo || []).forEach((test) => {
          this.turnOrds[test.unityDtlExamTurnOrdSeq] = test.unityDtlExamTurnOrd;
          if (this.sortedTurnOrdsKey.indexOf(test.unityDtlExamTurnOrdSeq) === -1) {
            this.sortedTurnOrdsKey.push(test.unityDtlExamTurnOrdSeq);
          }
        });
        this.sortedTurnOrdsKey.sort((a, b) => {
          return this.turnOrds[a] - this.turnOrds[b];
        });
        this.selectTurnOrdSeq = this.$route.query['unityDtlExamTurnOrdSeq'];
      });
    },
    initQustnCauseList() {
      this.qustnCauseList(this.qustnList[this.activeLiIndex][this.activeIndex].coransrCauseDivCd);
      if (this.qustnList[this.activeLiIndex][this.activeIndex].coransrYn === 'Y') {
        this.reasonWrongAnswer = this.qustnList[this.activeLiIndex][this.activeIndex]?.coransrCauseCd || '';
      } else {
        this.reasonWrongAnswer = this.qustnList[this.activeLiIndex][this.activeIndex]?.wransrCauseCd || '';
      }
    },
    intiReasonWrongAnswer(){
      let initCnt = 0;
      this.answerCause.forEach((item) => {
        if(item.causeData === this.reasonWrongAnswer){
          initCnt++;
        }
      });
      this.wransrCause.forEach((item) => {
        if(item.causeData === this.reasonWrongAnswer){
          initCnt++;
        }
      });
      if(initCnt === 0){
        this.reasonWrongAnswer = '';
      }
      this.isOpenQuestionNumber = false;
    },
    isDisplayIndex(index) {
      const partition = Math.floor(this.activeIndex / 5);
      return partition * 5 <= index && index < (partition + 1) * 5;
    },
    onKeyDownReason(evt) {
      if(this.reasonText.length === 20 ){
        evt.target.blur();
        evt.target.focus();
      }
    },
    getQustnNoPrint(index){
      let qustnNo = '';
      this.qustnList[index].forEach((item, idx) => {
        qustnNo += item.no;
        if(this.qustnList[index].length-1 !== idx){
          qustnNo += ', ';
        }
      });
      return qustnNo;
    },
    subjectPrintAll(e){
      const subjectAllRadio = document.querySelectorAll('input[name^="subjectRadio"]');
      if (e.target.checked){
        for (let i = 0; i < subjectAllRadio.length; i++) {
          subjectAllRadio[i].checked = true;
        }
      } else{
        for (let i = 0; i < subjectAllRadio.length; i++) {
          subjectAllRadio[i].checked = false;
        }
      }
    }
  }
};
</script>
