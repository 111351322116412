<template>
  <component :is="layout"><slot></slot></component>
</template>
<script>
import DefaultLayout from './DefaultLayout';
import MyPageLayout from './MyPageLayout';
import MobileLayout from './MobileLayout';
import { mapState } from 'vuex';

export default {
  name: 'Layout',
  components: { DefaultLayout, MyPageLayout, MobileLayout },
  data() {
    return {
      layout: 'DefaultLayout'
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile
    })
  },
  watch: {
    '$route'(to, from) {
      if (this.isMobile) {
        this.layout = to.meta.mobileLayout || 'DefaultLayout';
      } else {
        this.layout = to.meta.layout || 'DefaultLayout';
      }
    }
  },
  created() {
    if (this.isMobile) {
      this.layout = this.$route.meta.mobileLayout || 'DefaultLayout';
    } else {
      this.layout = this.$route.meta.layout || 'DefaultLayout';
    }
  }
};
</script>
