import MutationTypesUnityExam from '@/store/MutationTypesUnityExam';
import { UNITY_SUBJCT_CD } from '@/const/const.js';
import UnityExamApi from '@/api/UnityExamApi';
import moment from 'moment';
import CommonApi from '@/api/CommonApi';
import { deepCopy } from '@/utils/util';

function aggregateSubjectDataBySubjtCd(result) {
  const map = {};
  result.data.forEach((item) => {
    Object.keys(UNITY_SUBJCT_CD).forEach((key) => {
      if (item.unitySubjtCd === UNITY_SUBJCT_CD[key]) {
        map[key] = item;
      }
    });
  });
  return map;
}
export default {
  getSubjectYears: async ({ commit }) => {
    try {
      const result = await UnityExamApi.getSubjectYears();
      commit(MutationTypesUnityExam.SET_SUBJECT_YEARS, result.data);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getSubjectMap: async ({ commit }, turnOrdSeq) => {
    try {
      const result = await UnityExamApi.getSubjectMap(turnOrdSeq);
      const map = aggregateSubjectDataBySubjtCd(result);
      commit(MutationTypesUnityExam.SET_UNITY_SUBJECT_MAP, map);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getSubjectMapByYear: async ({ commit }, year) => {
    try {
      const result = await UnityExamApi.getSubjectMapByYear(year);
      const map = aggregateSubjectDataBySubjtCd(result);
      commit(MutationTypesUnityExam.SET_UNITY_SUBJECT_MAP_BY_YEAR, map);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  setSubjectMapByYear: async ({ commit }, year) => {
    try {
      const currentYearMap = {};
      let result = await UnityExamApi.getSubjectMapByYear(year);
      result.data.forEach((obj) => {
        obj.child.forEach((child) => {
          currentYearMap[child.byyySubjtSeq] = {
            highByyySubjtSeq: obj.byyySubjtSeq,
            subjtNm: child.subjtNm,
            highSubjtCd: obj.unitySubjtCd
          };
        });
      });
      const lastYearSubjtMap = {};
      result = await UnityExamApi.getSubjectMapByYear(Number(year) - 1);
      result.data.forEach((obj) => {
        obj.child.forEach((child) => {
          lastYearSubjtMap[child.byyySubjtSeq] = {
            highByyySubjtSeq: obj.byyySubjtSeq,
            subjtNm: child.subjtNm,
            highSubjtCd: obj.unitySubjtCd
          };
        });
      });
      commit(MutationTypesUnityExam.SET_CURRENT_YEAR_SUBJT_MAP, currentYearMap);
      commit(MutationTypesUnityExam.SET_LAST_YEAR_SUBJT_MAP, lastYearSubjtMap);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getStdSelSubjectByYear: async ({ commit }, { year, studentCd }) => {
    try {
      const result = await UnityExamApi.getStdSelSubjectByYear(year, studentCd);
      commit(MutationTypesUnityExam.SET_MY_SUBJECT_BY_YEAR, result.data);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  updateStdSelSubjectByYear: async ({ commit }, { year, studentCd, seqs }) => {
    try {
      const result = await UnityExamApi.updateStdSelSubjectByYear(year, studentCd, seqs);
      commit(MutationTypesUnityExam.SET_MY_SUBJECT_BY_YEAR, result.data);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getAllTestList: async ({ commit }, { studentCd, branchCd }) => {
    try {
      const result = await UnityExamApi.getAllTestList(studentCd, branchCd);
      const testMap = {};
      const testList = result.data || [];
      testList.forEach((value) => {
        const unityExamYy = value.unityExamYy,
          unityExamDivCd = value.unityExamDivCd,
          unityExamSeq = value.unityExamSeq,
          unityDtlExamSeq = value.unityDtlExamSeq,
          unityDtlExamTurnOrdSeq = value.unityDtlExamTurnOrdSeq;
        if (testMap[unityExamYy] === undefined) {
          testMap[unityExamYy] = { name: unityExamYy };
        }

        if (testMap[unityExamYy][unityExamDivCd] === undefined) {
          testMap[unityExamYy][unityExamDivCd] = { name: value.examDivNm };
        }
        if (testMap[unityExamYy][unityExamDivCd][unityExamSeq] === undefined) {
          testMap[unityExamYy][unityExamDivCd][unityExamSeq] = { name: value.unityExamNm };
        }
        if (testMap[unityExamYy][unityExamDivCd][unityExamSeq][unityDtlExamSeq] === undefined) {
          testMap[unityExamYy][unityExamDivCd][unityExamSeq][unityDtlExamSeq] = { name: value.unityDtlExamNm };
        }
        if (testMap[unityExamYy][unityExamDivCd][unityExamSeq][unityDtlExamSeq][unityDtlExamTurnOrdSeq] === undefined) {
          testMap[unityExamYy][unityExamDivCd][unityExamSeq][unityDtlExamSeq][unityDtlExamTurnOrdSeq] = {
            unityDtlExamTurnOrd: value.unityDtlExamTurnOrd,
            unityDtlExamSeq,
            unityDtlExamTurnOrdSeq,
            data: {}
          };
        }
        testMap[unityExamYy][unityExamDivCd][unityExamSeq][unityDtlExamSeq][unityDtlExamTurnOrdSeq].data[
          value.byyySubjtSeq
        ] = value;
      });

      commit(MutationTypesUnityExam.SET_TEST_MAP, testMap);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getRegularTestScoreOverYear: async ({ commit }, { year, studentCd }) => {
    try {
      const result = await UnityExamApi.getRegularTestScoreOverYear(year, studentCd);
      commit(MutationTypesUnityExam.CHART_DATA, result.data);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getRegularTestList: async ({ commit }, { studentCd, branchCd }) => {
    try {
      const rmdCodeList = await CommonApi.getMrCodeList('RMD');
      const codeList = rmdCodeList.data || [];
      commit(MutationTypesUnityExam.SET_UNITY_DTL_DIV_LIST, codeList);
      const unityExamDtlDivCds = {};
      codeList.forEach((element) => {
        unityExamDtlDivCds[element.data] = {};
      });
      const result = await UnityExamApi.getRegularTestList(studentCd, branchCd);
      const testMap = {};
      const testList = result.data || [];
      const today = moment().format('YYYYMMDD');
      let lastTest = {};
      testList.forEach((value) => {
        const unityExamYy = value.unityExamYy,
          unityExamDtlDivCd = value.unityExamDtlDivCd,
          unityExamSeq = value.unityExamSeq;
        if (testMap[unityExamYy] === undefined) {
          testMap[unityExamYy] = deepCopy(unityExamDtlDivCds);
        }
        if (!Object.prototype.hasOwnProperty.call(testMap[unityExamYy][unityExamDtlDivCd], unityExamSeq)) {
          testMap[unityExamYy][unityExamDtlDivCd][unityExamSeq] = { name: value.unityExamNm, data: [] };
        }
        testMap[unityExamYy][unityExamDtlDivCd][unityExamSeq].data.push(value);
        const examDate = value.unityExamDt || value.basExamDt || value.unityExamStDt,
          lastExamDate =
            Object.keys(lastTest).length > 0 ? lastTest.unityExamDt || lastTest.basExamDt || lastTest.unityExamStDt : null;
        if (today < examDate) {
          return;
        }
        if (
          Object.keys(lastTest).length === 0 ||
          lastExamDate < examDate ||
          (lastExamDate === examDate && lastTest.unityExamSeq >= value.unityExamSeq)
        ) {
          lastTest = value;
        }
      });
      commit(MutationTypesUnityExam.SET_LAST_TEST, lastTest);
      commit(MutationTypesUnityExam.SET_TEST_MAP, testMap);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getRegularTestExamScore: async ({ commit }, { unityDtlExamSeq, studentCd }) => {
    try {
      const result = await UnityExamApi.getRegularTestExamScore(unityDtlExamSeq, studentCd);
      const response = result.data.list[0].data || {};
      const personalScoreMap = response.scoreMap || {};
      commit(MutationTypesUnityExam.UNIV_GROUP, response.univGroup);
      commit(MutationTypesUnityExam.SET_TEST_EXAM_SCORE, personalScoreMap);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getAcaContentsScoreOverDtlExamSeq: async ({ commit }, { unityExamDivCd, dtlExamSeq, studentCd }) => {
    try {
      const result = await UnityExamApi.getAcaContentsScoreOverDtlExamSeq(unityExamDivCd, dtlExamSeq, studentCd);
      commit(MutationTypesUnityExam.CHART_DATA, result.data);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getAcaContentsList: async ({ commit }, { studentCd, branchCd }) => {
    try {
      const result = await UnityExamApi.getAcaContentsList(studentCd, branchCd);
      const testMap = {};
      const testList = result.data || [];
      const today = moment().format('YYYYMMDD');
      let lastTest = {};
      testList.forEach((value) => {
        const unityExamYy = value.unityExamYy,
          unityExamDivCd = value.unityExamDivCd,
          unityExamSeq = value.unityExamSeq,
          unityDtlExamSeq = value.unityDtlExamSeq,
          unityDtlExamTurnOrdSeq = value.unityDtlExamTurnOrdSeq;
        if (testMap[unityExamYy] === undefined) {
          testMap[unityExamYy] = { name: unityExamYy };
        }

        if (testMap[unityExamYy][unityExamDivCd] === undefined) {
          testMap[unityExamYy][unityExamDivCd] = { name: value.examDivNm };
        }
        if (testMap[unityExamYy][unityExamDivCd][unityExamSeq] === undefined) {
          testMap[unityExamYy][unityExamDivCd][unityExamSeq] = { name: value.unityExamNm };
        }
        if (testMap[unityExamYy][unityExamDivCd][unityExamSeq][unityDtlExamSeq] === undefined) {
          testMap[unityExamYy][unityExamDivCd][unityExamSeq][unityDtlExamSeq] = { name: value.unityDtlExamNm };
        }
        if (testMap[unityExamYy][unityExamDivCd][unityExamSeq][unityDtlExamSeq][unityDtlExamTurnOrdSeq] === undefined) {
          testMap[unityExamYy][unityExamDivCd][unityExamSeq][unityDtlExamSeq][unityDtlExamTurnOrdSeq] = {
            unityDtlExamTurnOrd: value.unityDtlExamTurnOrd,
            unityDtlExamSeq,
            unityDtlExamTurnOrdSeq,
            data: {}
          };
        }
        testMap[unityExamYy][unityExamDivCd][unityExamSeq][unityDtlExamSeq][unityDtlExamTurnOrdSeq].data[
          value.byyySubjtSeq
        ] = value;
        const examDate = value.unityExamDt || value.basExamDt || value.unityExamStDt,
          lastExamDate =
            Object.keys(lastTest).length !== 0
              ? lastTest.unityExamDt || lastTest.basExamDt || value.unityExamStDt
              : null;
        if (today < examDate) {
          return;
        }
        if (
          Object.keys(lastTest).length === 0 ||
          lastExamDate < examDate ||
          (lastExamDate === examDate && lastTest.unityExamSeq >= value.unityExamSeq)
        ) {
          lastTest = value;
        }
      });
      commit(MutationTypesUnityExam.SET_LAST_TEST, lastTest);
      commit(MutationTypesUnityExam.SET_TEST_MAP, testMap);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getAcaContentsScore: async ({ commit }, { unityDtlExamSeq, unityTurnOrdSeq, studentCd }) => {
    try {
      const result = await UnityExamApi.getAcaContentsScore(unityDtlExamSeq, unityTurnOrdSeq, studentCd);
      const response = result.data.list[0].data || {};
      const personalScoreMap = response.scoreMap || {};
      commit(MutationTypesUnityExam.SET_TEST_EXAM_SCORE, personalScoreMap);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getScoreByStudent: async ({ commit }, { year, studentCd }) => {
    try {
      const result = await UnityExamApi.getScoreByStudent(year, studentCd);
      commit(MutationTypesUnityExam.SET_ANALYZE_AVAILABLE_MAP, result.data.resultMap);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getQuestionInfo: async ({ commit }, { turnOrdSeq, byyySubjtSeq, studentCd }) => {
    try {
      const result = await UnityExamApi.getQuestionInfo(turnOrdSeq, byyySubjtSeq, studentCd);
      commit(MutationTypesUnityExam.SET_QUESTION_INFO, result.data);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getQuestionInfoByExamQustnSeqs: async ({ commit }, { studentCd, unityExamQustnSeqs }) => {
    try {
      if (unityExamQustnSeqs.length === 0) {
        return;
      }
      const seqs = unityExamQustnSeqs.join(',');
      const result = await UnityExamApi.getQuestionInfoByExamQustnSeqs(studentCd, seqs);
      commit(MutationTypesUnityExam.SET_QUESTION_DETAIL_BY_PART, result.data);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getQuestionByStudentExam: async ({ commit }, { studentCd, turnOrdSeq }) => {
    try {
      const result = await UnityExamApi.getQuestionByStudentExam(studentCd, turnOrdSeq);
      commit(MutationTypesUnityExam.SET_QUESTION_INFO_BY_EXAM, result.data);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getAnswerByQuestionType: async ({ commit }, { studentCd, byyySubjtSeqs }) => {
    try {
      if (byyySubjtSeqs.length === 0) {
        return;
      }
      const subjtSeqs = byyySubjtSeqs.join(',');
      const result = await UnityExamApi.getAnswerByQuestionType(studentCd, subjtSeqs);
      const data = result.data;
      data.sort((obj1, obj2) => {
        const rate1 = obj1.myWrongAnswerRate || 0,
          rate2 = obj2.myWrongAnswerRate || 0;
        let compare = rate2 - rate1;
        if (compare === 0) {
          compare = (obj2.wrongAnswerQustnSeqs.length || 0) - (obj1.wrongAnswerQustnSeqs.length || 0);
        }
        return compare;
      });
      commit(MutationTypesUnityExam.SET_ANSWER_BY_QUESTION_TYPES, data);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getAnalysisOfAnswerCause: async ({ commit }, { studentCd, byyySubjtSeqs }) => {
    try {
      if (byyySubjtSeqs.length === 0) {
        return;
      }
      const subjtSeqs = byyySubjtSeqs.join(',');
      const result = await UnityExamApi.getAnalysisOfAnswerCause(studentCd, subjtSeqs);
      commit(MutationTypesUnityExam.WRANSR_CORANSR_CAUSE_LIST_STATS, result.data);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  saveTestAnswer: async ({ commit }, param) => {
    try {
      await UnityExamApi.saveTestAnswer(param);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  updateChangeCoransNoteYn: ({ commit }, param) => {
    try {
      UnityExamApi.updateChangeCoransNoteYn(param);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  saveSubjtPrint: ({ commit }, param) => {
    try {
      UnityExamApi.saveSubjtPrint(param);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getWransrnoteYear: async ({ commit }) => {
    try {
      const result = await UnityExamApi.getWransrnoteYearInfo();
      commit(MutationTypesUnityExam.WRANSR_NOTE_YEAR_INFO, result);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getWransrnoteExamTab: async ({ commit }, params) => {
    try {
      const result = await UnityExamApi.getWransrnoteExamTabInfo(params);
      commit(MutationTypesUnityExam.WRANSR_NOTE_EXAM_TAB_INFO, result);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getWransrnoteExam: async ({ commit }, params) => {
    try {
      if (params.unityExamDivCd === '0') {
        commit(MutationTypesUnityExam.WRANSR_NOTE_EXAM_INFO, []);
        return;
      }
      const result = await UnityExamApi.getWransrnoteMobileExamInfo(params);
      commit(MutationTypesUnityExam.WRANSR_NOTE_EXAM_INFO, result);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getWransrnoteQustn: async ({ commit }, params) => {
    try {
      const result = await UnityExamApi.getWransrNoteQustnInfo(params);
      commit(MutationTypesUnityExam.WRANSR_NOTE_QUSTN_INFO, result);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getWransrnoteDtlExam: async ({ commit }, params) => {
    try {
      if (params.unityExamDivCd === '0') {
        commit(MutationTypesUnityExam.WRANSR_NOTE_DTL_EXAM_INFO, []);
        return;
      }
      const result = await UnityExamApi.getWransrnoteMobileDtlExamInfo(params);
      commit(MutationTypesUnityExam.WRANSR_NOTE_DTL_EXAM_INFO, result);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getWransrnoteTableExam: async ({ commit }, params) => {
    try {
      const result = await UnityExamApi.getWransrnoteExamInfo(params);
      commit(MutationTypesUnityExam.WRANSR_NOTE_TABLE_EXAM_INFO, result);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getSubjtQuestionAnswerPrint: async ({ commit }, params) => {
    try {
      const result = await UnityExamApi.getSubjtQuestionAnswerPrint(params);
      commit(MutationTypesUnityExam.SUBJT_QUESTION_ANSWER_PRINT, result);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  }
};
