import test from './test';

export default {
  state: {
    test: test.state
  },
  actions: { ...test.actions },
  mutations: { ...test.mutations },
  getters: { ...test.getters }
};
