<template>
  <footer>
    <!-- 본원 -->
    <template v-if="userInfo.branchCd === '0021'">
      <div class="link">
        <a href="tel:02)573-9001">전화상담</a>
        <a href="https://m.etoos.com/member/pop_individual.asp">개인정보처리방침</a>
        <a href="https://m.etoos.com/member/pop_yakkwan_new.asp">이용약관</a>
      </div>
      <p class="copyright">
        (06134) 서울시 강남구 강남대로 92길 35 (역삼동 649-6) | 사업자등록번호 : 101-85-48906 | Tel : 02-573-9001 | Fax : 02-554-2030 <br />
        강남하이퍼학원 학원등록번호 : 제973호 교습과정 : 종합-보습·논술, 진학상담·지도 | 강남하이퍼3학원 학원등록번호 : 제13391호 교습과정 : 종합-보습·논술, 진학상담·지도<br />
        강남하이퍼5학원 학원등록번호 : 제13383호 교습과정 : 보습·논술 | 강남하이퍼7학원 학원등록번호 : 제13384호 교습과정 : 보습·논술<br />
        (06134) 서울시 강남구 강남대로 92길 33 (역삼동 649-7) | 사업자등록번호 : 101-85-48906 | Tel : 02-573-9001 | Fax : 02-554-2030 <br />
        강남하이퍼2학원 학원등록번호 : 제12744호 교습과정 : 종합-보습·논술, 진학상담·지도 | 강남하이퍼4학원 학원등록번호 : 제13398호 교습과정 : 종합-보습·논술, 진학상담·지도<br />
        강남하이퍼6학원 학원등록번호 : 제13399호 교습과정 : 보습·논술 | 강남하이퍼독서실 학원등록번호 : 제12789호 교습과정 : 독서실<br />
        <strong>이투스에듀 주식회사</strong> (06733) 서울시 서초구 남부순환로 2547 (서초동 1354-3) | Tel: 02-552-1230 | Fax: 02-552-1325 | 사업자등록번호 : 505-87-02372 |
        <a href="javascript:void(0);" @click="openBizInfo('5058702372')" class="link_txt"> 통신판매신고번호:강남-03036호 </a><br />
        대표이사: 정선욱 | 개인정보보호책임자 : 정미상<br />
        <span class="c">copyright &copy; etoos education co.,ltd. all right reserved.</span>
      </p>
      <!-- <div class="network">
        <a href="#"><span>이투스교육 학원 네트워크</span></a>
      </div> -->
    </template>
    <!-- 기숙의대관 -->
    <template v-else-if="userInfo.branchCd === '0110'">
      <div class="link">
        <a href="tel:031)874-9001">전화연결</a>
        <a href="https://m.etoos.com/member/pop_yakkwan_new.asp" target="_blank">이용약관</a>
        <a href="https://m.etoos.com/member/pop_individual.asp" target="_blank">개인정보처리방침</a>
      </div>
      <p class="copyright">
        (17162) 경기도 용인시 처인구 양지면 중부대로 2374-8(제일리 608-2) | 사업자등록번호 : 291-85-02106 | Tel : 031-874-9001 | Fax : 031-873-9080 <br />
        강남하이퍼학원 학원등록번호 : 제1838-26호 교습과정 : 입시종합<br />
        <strong>이투스에듀 주식회사</strong> (06733) 서울시 서초구 남부순환로 2547 (서초동 1354-3) | Tel: 02-552-1230 | Fax: 02-552-1325 | 사업자등록번호 : 505-87-02372 |
        <a href="javascript:void(0);" @click="openBizInfo('5058702372')" class="link_txt"> 통신판매신고번호:강남-03036호 </a><br />
        대표이사: 정선욱 | 개인정보보호책임자 : 정미상<br />
        <span class="c">copyright &copy; etoos education co.,ltd. all right reserved.</span>
      </p>
      <!-- <div v-show="false" class="network">
        <a href="#"><span>이투스교육 학원 네트워크</span></a>
      </div> -->
    </template>
    <!-- 의대관 -->
    <template v-else-if="userInfo.branchCd === '0310'">
      <div class="link">
        <a href="tel:02)875-9001">전화연결</a>
        <a href="https://m.etoos.com/member/pop_yakkwan_new.asp" target="_blank">이용약관</a>
        <a href="https://m.etoos.com/member/pop_individual.asp" target="_blank">개인정보처리방침</a>
      </div>
      <p class="copyright">
        (06232) 서울시 강남구 강남대로 84길 13 (역삼동 825-26) 7층~14층 | 사업자등록번호 : 156-85-01065 | Tel : 02-875-9001 | Fax : 02-564-2586 <br />
        강남하이퍼의대관1학원 학원등록번호 : 제12773호 교습과정 : 보습·논술, 진학상담·지도 | 강남하이퍼의대관2학원 학원등록번호 : 제13410호 교습과정 : 보습·논술 <br />
        강남하이퍼의대관1독서실 학원등록번호 : 제13383호 교습과정 : 독서실 | 강남하이퍼의대관2독서실 학원등록번호 : 제13384호 교습과정 : 학원<br />
        강남하이퍼의대관컨설팅 학원등록번호 : 제12744호 교습과정 : 진학상담·지도<br />
        <strong>이투스에듀 주식회사</strong> (06733) 서울시 서초구 남부순환로 2547 (서초동 1354-3) | Tel: 02-552-1230 | Fax: 02-552-1325 | 사업자등록번호 : 505-87-02372 |
        <a href="javascript:void(0);" @click="openBizInfo('5058702372')" class="link_txt"> 통신판매신고번호:강남-03036호 </a><br />
        상호명 : 강남하이퍼학원 의대관 | 대표이사 : 정선욱 | 개인정보보호책임자 : 정미상<br />
        <span class="c">copyright &copy; etoos education co.,ltd. all right reserved.</span>
      </p>
      <!-- <div class="network">
        <a href="#"><span>이투스교육 학원 네트워크</span></a>
      </div> -->
    </template>
    <template v-else-if="userInfo.branchCd === '0130'">
      <div class="link">
        <a href="tel:02-565-2586">전화상담</a>
        <a href="https://m.etoos.com/member/pop_yakkwan_new.asp" target="_blank">이용약관</a>
        <a href="https://m.etoos.com/member/pop_individual.asp" target="_blank" class="policy">개인정보처리방침</a>
      </div>
      <p class="copyright">
        서울시 양천구 오목로 325(목1동 406-21) 4~5층, 7층 | 사업자등록번호 148-85-02118, 622-85-08978 | Tel : 02-2646-2586 | Fax : 02-2608-9002 <br />
        강남하이퍼목동관학원 학원등록번호 : 제4918호 교습과정 : 종합-보습·논술, 진학상담·지도 | 강남하이퍼목동관컨설팅학원 학원등록번호 : 제5780호 교습과정 : 진학상담·지도<br />
        강남하이퍼목동관 1독서실 학원등록번호 : 제5781호 교습과정 : 독서실 | 강남하이퍼목동관 2독서실 학원등록번호 : 제5758호 교습과정 : 독서실 |<br />
        (06134) 서울시 강남구 강남대로 92길 33 (역삼동 649-7) | 사업자등록번호 : 101-85-48906 | Tel : 02-573-9001 | Fax : 02-554-2030 <br />
        이투스에듀 주식회사 (06733) 서울시 서초구 남부순환로 2547 (서초동 1354-3) | Tel: 02-552-1230 | Fax: 02-552-1325 | 사업자등록번호 : 505-87-02372 |
        <a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=5058702372">통신판매신고번호:강남-03036호</a><br />
        대표이사: 정선욱 | 개인정보보호책임자 : 정미상<br />
        <span class="c">copyright © etoos education co.,ltd. all right reserved.</span>
      </p>
      <!-- <div class="network">
        <a href="#"><span>이투스교육 학원 네트워크</span></a>
      </div> -->
    </template>
    <!-- 기숙 -->
    <template v-else-if="userInfo.branchCd === '0120'">
      <div class="link">
        <a href="https://hyper-gisuk.etoos.com/index.do?site_preference=mobile">PC버전</a>
        <a href="tel:031)768-9001">전화상담</a>
        <a href="https://m.etoos.com/member/pop_individual.asp" target="_blank">개인정보처리방침</a>
        <a href="https://m.etoos.com/member/pop_yakkwan_new.asp" target="_blank">이용약관</a>
      </div>
      <p class="copyright">
        (17385) 경기도 이천시 마장면 이장로 115-10(이치리 160-5) | 사업자등록번호 : 348-85-01913 | Tel : 031-768-9001 | Fax : 031-767-0093 <br />
        강남하이퍼기숙학원 학원등록번호 : 제970호 교습과정 : 기숙<br />
        <strong>이투스에듀 주식회사</strong> (06733) 서울시 서초구 남부순환로 2547 (서초동 1354-3) | Tel: 02-552-1230 | Fax: 02-552-1325 | 사업자등록번호 : 505-87-02372 |
        <a href="javascript:void(0);" @click="openBizInfo('5058702372')" class="link_txt"> 통신판매신고번호:강남-03036호 </a><br />
        대표이사: 정선욱 | 개인정보보호책임자 : 정미상<br />
        <span class="c">copyright &copy; etoos education co.,ltd. all right reserved.</span>
      </p>
      <!-- <div class="network">
        <a href="#"><span>이투스교육 학원 네트워크</span></a>
      </div> -->
    </template>
  </footer>
</template>

<script>
export default {
  name: 'Footera',
  props: ['userInfo']
};
</script>
