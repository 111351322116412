import { httpClient } from '../httpclient/HttpClientFactory';

export default {
  getLetureSearchList: (request) => httpClient.get(`/api/lecture/search`, { params: request }).then((res) => res.data),
  getTeacherList: (request) => httpClient.get(`/api/lecture/teacher`, { params: request }).then((res) => res.data),
  getLectureCheck: (request) => httpClient.get(`/api/schedule/check`, { params: request }).then((res) => res.data),
  postBaisSort: (request) => httpClient.post(`/api/schedule/bais`, request).then((res) => res.data),
  cancelGroup: (request) => httpClient.post(`/api/study/cancel`, { params: request }).then((res) => res.data),
  lectureRegister: (request) => httpClient.post(`/api/study/register`, request).then((res) => res.data),
  lectureCancel: (request) => httpClient.post(`/api/study/cancel`, request).then((res) => res.data),
  getImage: (request) => httpClient.get(`/api/lecture/imageUrl`, { params: request }).then((res) => res.data)
};
