<template>
  <!-- contents -->
  <div class="contents">
    <!-- 마이페이지 -->
    <div class="my_area lecture">
      <div class="top_tit">
        <em>{{ detailLecture.teacherName }} | {{ detailLecture.subjtNm }}</em>
        <h4>{{ detailLecture.groupName }}</h4>
      </div>
      <div class="lecture_detail">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>강의주제</th>
              <th>강의영상</th>
            </tr>
          </thead>
          <tbody>
            <colgroup>
              <col width="20%" />
              <col width="*" />
              <col width="25%" />
            </colgroup>
            <tr v-for="item in detailLecture.turnOrdResponses" :key="item.turnOrd">
              <td>{{ item.turnordNo }}</td>
              <td>{{ item.lectureTopic }}</td>
              <td>
                <a
                  :href="item.lectureVideoUrl"
                  v-if="item.lectureVideoUrl !== null && item.lectureVideoUrl !== ''"
                  target="_blank"
                >
                  <img src="https://acaimg.etoos.com/neo/pc/images/cnt/center/icon_show.png" />
                </a>
                <a href="javascript:void(0);" v-else disabled>준비중</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- //마이페이지 -->
  </div>
  <!-- //contents -->
</template>

<script>
export default {
  name: 'ReplayTurnOrdLecturePopupM',
  data() {
    return {
      detailLecture: {}
    };
  },
  created() {
    this.detailLecture = this.$route.params?.detailLecture;
  }
};
</script>
