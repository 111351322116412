import getters from './getters';
import actions from './actions';
import mutations from './mutations';
const state = {
  studyTimeInfo: {}
};

export default {
  state,
  getters,
  actions,
  mutations
};
