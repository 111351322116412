import { httpClient } from '../httpclient/HttpClientFactory';

export default {
  getScheduleList: (params) => httpClient.get(`/api/schedule/fix`, { params }).then((res) => res.data),
  getSeason: (params) => httpClient.get(`/api/schedule/season`, { params }).then((res) => res.data),
  getStudentGroup: (params) => httpClient.get(`/api/schedule/group`, { params }).then((res) => res.data),
  getStudentSchedule: (params) => httpClient.get(`/api/schedule/schedule`, { params }).then((res) => res.data),
  getStudentHistory: (params) => httpClient.get(`/api/schedule/history`, { params }).then((res) => res.data),
  setStatusConfirm: (params) => httpClient.get(`/api/schedule/confirm`, { params }).then((res) => res.data),
  getStudentFirstGroup: (params) => httpClient.get(`/api/v1/group/first_group`, { params }).then((res) => res.data)
};
