<template>
  <!-- contents -->
  <div class="contents">
    <!-- 마이페이지 -->
    <div class="my_area lecture">
      <div class="top_bd" style="border-top: none;">
        <div class="frmfield mgt20">
          <div class="cell">
            <span class="select">
              <select class="w100p" v-model="searchByyySubjtSeq" @change="changeSelectBox((searchSubjectList.find((item) => item.byyySubjtSeq === searchByyySubjtSeq) || {}).subjtName || '', 'main')">
                <option value="" selected>과목</option>
                <option v-for="item in searchSubjectList" :value="item.byyySubjtSeq" :key="item.byyySubjtSeq">
                  {{ item.subjtName }}
                </option>
              </select>
            </span>
          </div>
          <div class="cell">
            <span class="select">
              <select class="w100p" v-model="searchSubCode" @change="changeSelectBox((subSubjectList.find((item) => item.code === searchSubCode) || {}).name || '', 'sub')">
                <option value="" selected>전체</option>
                <option v-for="item in subSubjectList" :value="item.code" :key="item.code">{{ item.name }}</option>
              </select>
            </span>
          </div>
          <div class="cell">
            <span class="select">
              <select class="w100p" v-model="searchTeacherCd" @change="changeSelectBox((teacherList.find((item) => item.teacherCd === searchTeacherCd) || {}).teacherName || '', 'teacher')">
                <option value="" selected>강사</option>
                <option v-for="(item, index) in teacherList" :value="item.teacherCd" :key="index">
                  {{ item.teacherName }}
                </option>
              </select>
            </span>
          </div>
        </div>
        <span class="search_txt" v-for="(item, idx) in searchRequest" :key="idx"> {{ item.name }}<a href="javascript:void(0);" @click="deleteRequest(idx)" class="search_close"></a> </span>
      </div>
      <div class="leaturelist mgt10">
        <ul>
          <li v-for="(lecture, idx) in searchLectureList.slice((currentPage - 1) * pagePerCnt, currentPage * pagePerCnt)" :key="idx">
            <a href="javascript:void(0)" @click="showTurnOrdLecture(lecture)">
              <strong class="tit">{{ lecture.teacherName }} ㅣ {{ lecture.subjtNm }} </strong>
              <strong class="subti" v-html="lecture.groupName"></strong>
              <span class="subti2">다시보기 ></span>
            </a>
          </li>
        </ul>
        <button type="button" @click="showMoreLecture()" v-if="isMoreBtn">더보기</button>
      </div>
    </div>
    <!-- //마이페이지 -->
  </div>
  <!-- //contents -->
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ReplayLecture',
  data() {
    return {
      searchRequest: [],
      searchSubjectList: [],
      searchByyySubjtSeq: '',
      searchSubCode: '',
      searchTeacherCd: '',
      searchLectureName: '',
      searchName: '',
      subSubjectList: [],
      isPartCode: false,
      teacherList: [],
      searchLectureList: [],
      currentPage: 1,
      pagePerCnt: 10,
      isMoreBtn: true
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      userInfo: (state) => state.common.userInfo,
      searchItems: (state) => state.replaylecture.searchItems,
      lectures: (state) => state.replaylecture.lectures
    })
  },
  watch: {
    searchByyySubjtSeq(seq) {
      this.makeSubSubjectList(seq);
      this.makeTeacherList(seq);
    },
    searchLectureList() {
      this.isMoreBtnCheck();
    }
  },
  methods: {
    ...mapActions(['getUser', 'getReplayLecture', 'getSearchItems']),
    changeTitle() {
      let title = '';
      if (this.userInfo.branchCd !== '') title = PAGE_TITLE[this.userInfo.branchCd];
      document.title = 'mypage_강의다시보기 | ' + title;
    },
    init() {
      this.getSearchItems().then(() => {
        for (const [key, item] of Object.entries(this.searchItems.subjects)) {
          this.searchSubjectList.push(item);
        }
        this.searchSubjectList.sort((a, b) => {
          return a.subjtCd - b.subjtCd;
        });
        this.makeTeacherList('');
      });
      this.getReplayLecture().then(() => {
        this.searchLectureList = this.lectures;
      });
    },
    makeSubSubjectList(seq) {
      const subject = this.searchItems.subjects[seq];
      this.subSubjectList = [];
      this.searchSubCode = '';
      if (Object.keys(subject?.partCodeMap || {}).length > 0) {
        this.isPartCode = true;
        for (const [key, item] of Object.entries(subject.partCodeMap)) {
          const obj = { code: key, name: item };
          this.subSubjectList.push(obj);
        }
      }
      if ((subject?.subSubjects || []).length > 0) {
        this.isPartCode = false;
        for (const item of subject.subSubjects) {
          const obj = { code: item.subjtCd, name: item.subjtNm };
          this.subSubjectList.push(obj);
        }
      }
      this.subSubjectList.sort((a, b) => {
        return (a.code || '').localeCompare(b.code);
      });
    },
    makeTeacherList(seq) {
      const teachers = this.searchItems.searchTeachers;
      this.searchTeacherCd = '';
      this.teacherList = [];
      if (seq === '') {
        for (const [key, item] of Object.entries(teachers)) {
          item?.forEach((teacher) => {
            this.teacherList.push(teacher);
          });
        }
      } else {
        teachers[seq]?.forEach((teacher) => {
          this.teacherList.push(teacher);
        });
      }
      this.teacherList.sort((a, b) => {
        return (a.teacherName || '').localeCompare(b.teacherName);
      });
    },
    getSchedule(dayScheduleMap) {
      const result = {};
      for (const prop in dayScheduleMap) {
        const timeList = [];
        dayScheduleMap[prop].forEach(function(obj, idx) {
          if (idx === 0) {
            result[prop] = obj.day;
          }
          timeList.push(obj.lectureStartTime + ' ~ ' + obj.lectureEndTime);
        });
        result[prop] += ' ' + timeList.join(', ');
      }
      return Object.values(result).join('<br>');
    },
    showTurnOrdLecture(lecture) {
      this.$router.push({ name: 'replayLectureDetail', params: { detailLecture: lecture } });
    },
    deleteRequest(idx) {
      this.searchRequest.splice(idx, 1);
      this.getReplayLecture(this.searchRequest).then(() => {
        this.initLecturesSize();
        this.searchLectureList = this.lectures;
      });
    },
    changeSelectBox(name, type) {
      if (name === '' || type === 'main') {
        this.searchLectureList = this.lectures;
        return;
      }
      const params = this.searchRequest;
      const param = {
        byyySubjtSeq: null,
        subjtPartCd: null,
        subSubjtCd: null,
        teacherCd: null,
        name: null
      };
      param['byyySubjtSeq'] = this.searchByyySubjtSeq;
      if (type === 'sub') {
        if (this.isPartCode) {
          param.subjtPartCd = this.searchSubCode;
        } else if (this.searchSubCode !== '') {
          param.subSubjtCd = this.searchSubCode;
        }
      } else {
        param['teacherCd'] = this.searchTeacherCd;
      }
      param['name'] = name;
      if (Object.values(param).filter((value) => value !== null).length === 0) {
        return;
      }
      params.push(param);
      this.getReplayLecture(params).then(() => {
        this.initLecturesSize();
        this.searchByyySubjtSeq = '';
        this.searchTeacherCd = '';
        this.searchLectureList = this.lectures;
        this.searchRequests = params;
      });
    },
    showMoreLecture() {
      this.pagePerCnt += 10;
      this.isMoreBtnCheck();
    },
    isMoreBtnCheck() {
      const size = (this.searchLectureList || []).length;
      if (size <= this.pagePerCnt) {
        this.pagePerCnt = size;
        this.isMoreBtn = false;
      }
    },
    initLecturesSize() {
      this.pagePerCnt = 10;
      this.isMoreBtn = true;
    }
  },
  created() {
    if (!this.userInfo.studentCd) {
      this.getUser().then(() => {
        this.init();
      });
    } else {
      this.init();
    }
    this.changeTitle();
  }
};
</script>

<style scoped></style>
