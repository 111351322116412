'use strict';

import TmsApi from '../api/TmsApi';

class TmsCookieUtil {
  async getTmsToken() {
    let token = this.getCookie('tms-request-token');
    if (!token.length > 0) {
      try {
        const tokenResult = await TmsApi.getToken(this.getCookie('etsssoMemNo') || 'yunji4091');
        this.setCookie(
          'tms-request-token',
          tokenResult.data.accessToken.token,
          tokenResult.data.accessToken.expiredAtDate
        );
        token = tokenResult.data.accessToken.token;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    }
    return token;
  }

  async setTmsToken() {
    try {
      const tokenResult = await TmsApi.getToken(this.getCookie('etsssoMemNo') || 'yunji4091');
      this.setCookie(
        'tms-request-token',
        tokenResult.data.data.accessToken.token,
        tokenResult.data.data.accessToken.expiredAtDate
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }

  getCookie(cname) {
    var name = cname + '=';
    var decodedCookie = unescape(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  setCookie(cname, cvalue, cday) {
    document.cookie = cname + '=' + cvalue + ';expires=' + new Date(cday).toUTCString();
  }
}

const tmsCookieUtil = new TmsCookieUtil();
export default tmsCookieUtil;
