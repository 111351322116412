import StudyTimeManagementApi from '@/api/StudyTimeManagementApi';
import MutationTypes from '@/store/MutationTypesStudyTimeManagement';

export default {
  getStudyTimeInfo: async ({ commit }, params) => {
    try {
      const result = await StudyTimeManagementApi.getStudyTimeInfo(params);
      commit(MutationTypes.SET_STUDY_TIME_INFO, result.data);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  setStudyTimeInfo: async ({ commit }, params) => {
    try {
      await StudyTimeManagementApi.setStudyTimeInfo(params);
      return 'success';
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  }
};
