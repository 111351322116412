<template>
  <div id="footer_hp">
    <div v-if="userInfo.branchCd === '0021'" class="inner">
      <!-- 본원 -->
      <p class="logo">
        <img src="https://img.cheongsol.co.kr/web2018/hp/branch/images/common/logo_footer_h1.png" alt="" />
      </p>
      <ul class="foot_link">
        <li><a href="https://recruit.etoos.com" target="_blank">인재채용</a></li>
        <li><a href="https://www.etoos.com/member/yakkwan_new.asp" target="_blank">이용약관</a></li>
        <li class="policy">
          <a href="https://www.etoos.com/member/individual_new.asp" target="_blank">개인정보처리방침</a>
        </li>
      </ul>
      <div class="company_info">
        <p class="info">
          (06134) 서울시 강남구 강남대로 92길 35 (역삼동 649-6) | 사업자등록번호 : 101-85-48906 | Tel : 02-573-9001 | Fax : 02-554-2030 <br />
          강남하이퍼학원 학원등록번호 : 제973호 교습과정 : 종합-보습·논술, 진학상담·지도 | 강남하이퍼3학원 학원등록번호 : 제13391호 교습과정 : 종합-보습·논술, 진학상담·지도<br />
          강남하이퍼5학원 학원등록번호 : 제13383호 교습과정 : 보습·논술 | 강남하이퍼7학원 학원등록번호 : 제13384호 교습과정 : 보습·논술<br />
          (06134) 서울시 강남구 강남대로 92길 33 (역삼동 649-7) | 사업자등록번호 : 101-85-48906 | Tel : 02-573-9001 | Fax : 02-554-2030 <br />
          강남하이퍼2학원 학원등록번호 : 제12744호 교습과정 : 종합-보습·논술, 진학상담·지도 | 강남하이퍼4학원 학원등록번호 : 제13398호 교습과정 : 종합-보습·논술, 진학상담·지도<br />
          강남하이퍼6학원 학원등록번호 : 제13399호 교습과정 : 보습·논술 | 강남하이퍼독서실 학원등록번호 : 제12789호 교습과정 : 독서실<br />
          <strong>이투스에듀 주식회사</strong> (06733) 서울시 서초구 남부순환로 2547 (서초동 1354-3) | Tel: 02-552-1230 | Fax: 02-552-1325 | 사업자등록번호 : 505-87-02372 | 통신판매신고번호 : 강남-03036호
          <a href="javascript:void(0);" @click="openBizInfo('5058702372')">정보조회</a><br />
          대표이사: 정선욱 | 개인정보보호책임자 : 정미상<br />
          <span class="copy">
            Copyright © ETOOS EDUCATION Co.,Ltd. All rights Reserved.
            <a href="mailto:hyper_web@etoos.com">hyper_web@etoos.com</a>
          </span>
        </p>
      </div>
    </div>
    <div v-else-if="userInfo.branchCd === '0110'">
      <!-- 기숙의대관 -->
      <div class="inner">
        <p class="logo">
          <img src="https://img.cheongsol.co.kr/web2018/hp/branch/images/common/logo_footer_h3.png" alt="" />
        </p>
        <ul class="foot_link">
          <li><a href="https://recruit.etoos.com" target="_blank">인재채용</a></li>

          <li><a href="https://www.etoos.com/member/yakkwan_new.asp" target="_blank">이용약관</a></li>
          <li class="policy">
            <a href="https://www.etoos.com/member/individual_new.asp" target="_blank">개인정보처리방침</a>
          </li>
        </ul>
        <div class="company_info">
          <p class="info">
            (17162) 경기도 용인시 처인구 양지면 중부대로 2374-8(제일리 608-2) | 사업자등록번호 : 291-85-02106 | Tel : 031-874-9001 | Fax : 031-873-9080 <br />
            강남하이퍼학원 학원등록번호 : 제1838-26호 교습과정 : 입시종합<br />
            <strong>이투스에듀 주식회사</strong> (06733) 서울시 서초구 남부순환로 2547 (서초동 1354-3) | Tel: 02-552-1230 | Fax: 02-552-1325 | 사업자등록번호 : 505-87-02372 | 통신판매신고번호 : 강남-03036호<br />
            <a href="javascript:void(0);" @click="openBizInfo('5058702372')">정보조회</a><br />
            대표이사: 정선욱 | 개인정보보호책임자 : 정미상<br />
            <span class="copy">
              Copyright &copy; ETOOS EDUCATION Co.,Ltd. All rights Reserved.
              <a href="mailto:hyper_web@etoos.com">hyper_web@etoos.com</a>
            </span>
          </p>
        </div>
      </div>
    </div>
    <div v-else-if="userInfo.branchCd === '0310'">
      <!-- 의대관 -->
      <div class="inner">
        <p class="logo">
          <img src="https://img.cheongsol.co.kr/web2018/hp/branch/images/common/logo_footer_h4.png" alt="" />
        </p>
        <ul class="foot_link">
          <li><a href="https://recruit.etoos.com" target="_blank">인재채용</a></li>

          <li><a href="https://www.etoos.com/member/yakkwan_new.asp" target="_blank">이용약관</a></li>
          <li class="policy">
            <a href="https://www.etoos.com/member/individual_new.asp" target="_blank">개인정보처리방침</a>
          </li>
        </ul>
        <div class="company_info">
          <p class="info">
            (06232) 서울시 강남구 강남대로 84길 13 (역삼동 825-26) 7층~14층 | 사업자등록번호 : 156-85-01065 | Tel : 02-875-9001 | Fax : 02-564-2586 <br />
            강남하이퍼의대관1학원 학원등록번호 : 제12773호 교습과정 : 보습·논술, 진학상담·지도 | 강남하이퍼의대관2학원 학원등록번호 : 제13410호 교습과정 : 보습·논술 <br />
            강남하이퍼의대관1독서실 학원등록번호 : 제13383호 교습과정 : 독서실 | 강남하이퍼의대관2독서실 학원등록번호 : 제13384호 교습과정 : 학원<br />
            강남하이퍼의대관컨설팅 학원등록번호 : 제12744호 교습과정 : 진학상담·지도<br />
            이투스에듀 주식회사 (06733) 서울시 서초구 남부순환로 2547 (서초동 1354-3) | Tel: 02-552-1230 | Fax: 02-552-1325 | 사업자등록번호 : 505-87-02372 | 통신판매신고번호 : 강남-03036호
            <a href="javascript:void(0);" @click="openBizInfo('5058702372')">정보조회</a><br />
            상호명 : 강남하이퍼 의대관 | 대표이사 : 정선욱 | 개인정보보호책임자 : 정미상<br />
            <span class="copy">
              Copyright © ETOOS EDUCATION Co.,Ltd. All rights Reserved.
              <a href="mailto:hyper_web@etoos.com">hyper_web@etoos.com</a>
            </span>
          </p>
        </div>
      </div>
    </div>
    <div v-else-if="userInfo.branchCd === '0130'">
      <!-- 목동관 -->
      <div class="inner">
        <p class="logo">
          <img src="https://acaimg.etoos.com/hp/pc/images/common/logo_footer.png" alt="" />
        </p>
        <ul class="foot_link">
          <li><a href="https://recruit.etoos.com" target="_blank">인재채용</a></li>

          <li><a href="https://www.etoos.com/member/yakkwan_new.asp" target="_blank">이용약관</a></li>
          <li class="policy">
            <a href="https://www.etoos.com/member/individual_new.asp" target="_blank">개인정보처리방침</a>
          </li>
        </ul>
        <div class="company_info">
          <p class="info">
            서울시 양천구 오목로 325(목1동 406-21) 4~5층, 7층 | 사업자등록번호 148-85-02118, 622-85-08978 | Tel : 02-2646-2586 | Fax : 02-2608-9002 <br />
            <a href="/info/contact.do">목동이투스앤써학원</a> 학원등록번호 : 제4918호 교습과정 : 종합-보습·논술, 진학상담·지도 | 목동이투스앤써컨설팅학원 학원등록번호 : 제5780호 교습과정 : 진학상담·지도<br />
            <a href="/info/contact.do">목동이투스앤써1독서실</a> 학원등록번호 : 제5781호 교습과정 : 독서실 | 목동이투스앤써2독서실 학원등록번호 : 제5758호 교습과정 : 독서실 |<br />
            <strong>이투스에듀 주식회사</strong> (06733) 서울시 서초구 남부순환로 2547 (서초동 1354-3) | Tel: 02-552-1230 | Fax: 02-552-1325 | 사업자등록번호 : 505-87-02372 | 통신판매신고번호 : 강남-03036호
            <a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=5058702372" class="fc_000" target="_blank"> 정보조회</a><br />
            대표이사: 정선욱 | 개인정보보호책임자 : 정미상<br />
            Copyright © ETOOS EDUCATION Co.,Ltd. All rights Reserved.
            <a href="mailto:answer_web@etoos.com">answer_web@etoos.com</a>
          </p>
        </div>
      </div>
    </div>
    <div v-else>
      <!-- 기숙 -->
      <div class="inner">
        <p class="logo">
          <img src="https://img.cheongsol.co.kr/web2018/hp/branch/images/common/logo_footer_h2.png" alt="" />
        </p>
        <!-- 2018-09-05 -->

        <ul class="foot_link">
          <li><a href="https://recruit.etoos.com" target="_blank">인재채용</a></li>

          <li><a href="https://www.etoos.com/member/yakkwan_new.asp" target="_blank">이용약관</a></li>
          <li class="policy">
            <a href="https://www.etoos.com/member/individual_new.asp" target="_blank">개인정보처리방침</a>
          </li>
        </ul>
        <div class="company_info">
          <p class="info">
            (17385) 경기도 이천시 마장면 이장로 115-10(이치리 160-5) | 사업자등록번호 : 348-85-01913 | Tel : 031-768-9001 | Fax : 031-767-0093 <br />
            강남하이퍼기숙학원 학원등록번호 : 제970호 교습과정 : 입시종합<br />
            <strong>이투스에듀 주식회사</strong> (06733) 서울시 서초구 남부순환로 2547 (서초동 1354-3) | Tel: 02-552-1230 | Fax: 02-552-1325 | 사업자등록번호 : 505-87-02372 | 통신판매신고번호 : 강남-03036호
            <a href="javascript:void(0);" @click="openBizInfo('5058702372')">정보조회</a><br />
            대표이사: 정선욱 | 개인정보보호책임자 : 정미상<br />
            <span class="copy">
              Copyright © ETOOS EDUCATION Co.,Ltd. All rights Reserved.
              <a href="mailto:hyper_web@etoos.com">hyper_web@etoos.com</a>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from '../cheongsol/Footer';

export default {
  name: 'Footera',
  props: ['userInfo'],
  mixins: [Footer]
};
</script>
