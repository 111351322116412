<template>
  <div :class="!isMobile ? 'pay_content' : ''">
    <div class="mypage_comm">
      <div class="box_tab" v-bind:style="[activeTab === tabs[1].id ? { overflow: 'visible' } : {}]">
        <div class="tab_navi size2">
          <ul class="list_tab">
            <li v-for="tab in tabs" :class="{ on: tab.id === activeTab }" :key="tab.id">
              <a href="#" @click.prevent="tabToggle(tab)" class="btn_tab">
                <span class="inner">{{ tab.name }}</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="tab_cont">
          <div id="tab1" class="panel_tab" :class="{ on: activeTab === tabs[0].id }" v-if="activeTab === tabs[0].id">
            <cur-study-list :subjects="setSubjectData()" />
            <!-- 현재수강중인내역 -->
          </div>
          <div id="tab2" class="panel_tab" :class="{ on: activeTab === tabs[1].id }" v-else>
            <prev-study-list :subjects="setSubjectData()" />
            <!-- 지난수강내역 -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import PrevStudyList from './PrevStudyList';
import CurStudyList from './CurStudyList';
import { PAGE_TITLE } from '../../../const/const';

const lectury_types = [
  { cd: 'all', name: '전체' },
  { cd: '1', name: '재종반 강좌' },
  { cd: '2', name: '단과' },
  { cd: '3', name: '특강' }
];
export default {
  data() {
    return {
      activeTab: 'tab1',
      tabs: [
        { id: 'tab1', name: '현재 수강중인 내역' },
        { id: 'tab2', name: '지난 수강내역' }
      ]
    };
  },
  methods: {
    ...mapActions(['getSelectSubject', 'getUser', 'setHeaderInfo']),
    changeTitle() {
      let title = '';
      if (this.userInfo.branchCd !== '') title = PAGE_TITLE[this.userInfo.branchCd];
      document.title = 'mypage_수강현황 | ' + title;
    },
    tabToggle(tab) {
      this.activeTab = tab.id;
    },
    setSubjectData() {
      let subjectData = [];
      const subject = [{ subjtCd: '', subjtName: '전체' }];
      subjectData = [...subject, ...this.selectSubject];

      return subjectData;
    },
    setLectureTypeData() {
      this.lectureTypeData = lectury_types;
    }
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      userInfo: (state) => state.common.userInfo,
      selectSubject: (state) => state.classroom.study.selectSubject
    })
  },
  components: {
    PrevStudyList,
    CurStudyList
  },
  created() {
    this.setHeaderInfo({ menuName: '수강현황', isPrev: false });
    this.activeTab = 'tab1';
    if (!this.userInfo.etoosMemNo) {
      this.getUser().then(() => {
        this.getSelectSubject();
        this.setSubjectData();
      });
    } else {
      this.getSelectSubject();
      this.setSubjectData();
    }
    this.changeTitle();
  }
};
</script>
