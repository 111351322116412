import $ from 'jquery';
import Swiper from 'swiper/bundle';
import MobileDetect from 'mobile-detect';

export default {
  mypageUI: {
    searchClick() {
      $('.seaall').on('click', function() {
        if ($(this).hasClass('on')) {
          $(this)
            .removeClass('on')
            .next('.slide')
            .stop()
            .slideUp();
        } else {
          $(this)
            .addClass('on')
            .next('.slide')
            .stop()
            .slideDown();
        }
        return false;
      });
    },
    timeTable() {
      const md = new MobileDetect(window.navigator.userAgent);
      let tblSwiper;

      const breakpointChecker = function() {
        if (md.phone()) {
          if ($('.swiper-container').length) {
            return enableSwiper();
          }
        } else {
          if (tblSwiper !== undefined) {
            tblSwiper.destroy(true, true);
            $('.selectday .btn_day').off('click');
          }
        }
      };
      const enableSwiper = function() {
        var today = new Date();
        const week = new Array('6', '0', '1', '2', '3', '4', '5');

        tblSwiper = new Swiper('.swiper-container', {
          loop: true,
          touchReleaseOnEdges: true,
          initialSlide: Number(week[today.getDay()]),
          on: {
            init() {
              $('.selectday .btn_day').on('click', function() {
                const idx =
                  $(this)
                    .parents('li')
                    .index() + 1;

                tblSwiper.slideTo(idx);
              });
            },
            slideChange(swiper) {
              const idx = swiper.realIndex;

              $('.selectday .list_day')
                .find('li')
                .removeClass('on');
              $('.selectday .list_day')
                .find('li')
                .eq(idx)
                .addClass('on');
            }
          }
        });
      };
      breakpointChecker();
    },
    panelUI() {
      const $itemFilter = $('.item_filter');
      const $button = $itemFilter.find('.btn_toggle');

      $button.on('click', function() {
        $(this)
          .parents('.item_filter')
          .toggleClass('on');
      });
    },
    filterLayerUI() {
      const $filter = $('.box_filter');
      const $button = $filter.find('.btn_toggle');

      $button.on('click', function() {
        $(this)
          .parents('.box_filter')
          .toggleClass('open');
      });
    },
    init() {
      this.timeTable();
      //this.datepicker();
      this.searchClick();
      this.panelUI();
      this.filterLayerUI();
    }
  },

  mypageLayer: {
    open(layerID) {
      const $layer = $(layerID);

      $('body').addClass('open_layer');
      $('#section').append('div');
      $layer.addClass('open');
      $layer
        .find('.dimmed_layer')
        .off('click')
        .on('click', function() {
          this.mypageLayer.close();
        });
    },
    close(layerID) {
      const $layer = layerID ? $(layerID) : $(this).parents('.layer_side, .layer_sidepage');

      $('body').removeClass('open_layer');
      $layer.removeClass('open');
    }
  }
};
