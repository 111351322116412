<template>
  <div class="lnb_box">
    <div class="tit"><a href="javascript:;" @click="goMyPage('')">마이페이지</a></div>
    <ul class="left_menu">
      <li v-if="isStudent" :class="{ on: currentMenuGroup === 'enrolment' }">
        <a @click="goSceduleMain()">수강신청</a>
      </li>
      <li :class="{ on: currentMenuGroup === 'studySchedule' }">
        <a @click="goStudyMain()">수강현황</a>
      </li>
      <li v-if="isStudent">
        <a href="javascript:;" @click="goMyPage('900')">나만의 추천 콘텐츠</a>
      </li>
      <li v-if="isStudent" :class="{ on: currentMenuGroup === 'infinitePass' }">
        <span>현장강의 무한패스</span>
        <ul>
          <li :class="{ on: $route.name === 'InfinitePassMain' }">
            <router-link :to="{ path: '/infinite-pass' }">나의 강의실</router-link>
          </li>
          <li :class="{ on: $route.name === 'allLectureView' }">
            <router-link :to="{ path: '/infinite-pass/all' }">전체 강좌</router-link>
          </li>
          <li :class="{ on: $route.name === 'TodayLectureView' }">
            <router-link :to="{ path: '/infinite-pass/today' }">오늘 업데이트된 강의</router-link>
          </li>
          <li :class="{ on: $route.name === 'OngoingLectureView' }">
            <router-link :to="{ path: '/infinite-pass/ongoing' }">수강 중인 강의</router-link>
          </li>
          <!-- ACAPJ-3319 2023-04-11 진도율 숨김
          <li :class="{ on: $route.name === 'CompleteLectureView' }">
            <router-link :to="{ path: '/infinite-pass/complete' }">수강 완료 강의</router-link>
          </li>
          // ACAPJ-3319 2023-04-11 진도율 숨김 -->
          <li :class="{ on: $route.name === 'interestedLectureView' }">
            <router-link :to="{ path: '/infinite-pass/interested' }">관심 강좌</router-link>
          </li>
        </ul>
      </li>
      <!--<li v-if="isStudent" :class="{ on: currentMenuGroup === 'mathDaily' }">
        <span>학습서비스</span>
        <ul>
          <li :class="{ on: $route.name === 'DailyTestMain' }">
            <router-link :to="{ path: '/main' }">수학 DM</router-link>
          </li>
        </ul>
      </li>-->
      <li v-if="isStudent" :class="{ on: currentMenuGroup === 'studyManagement' }">
        <span>학습·생활 관리</span>
        <ul>
          <li :class="{ on: $route.name === 'studyTimeManagement' }">
            <router-link :to="{ path: '/study/timeManagement' }">학습시간 관리</router-link>
          </li>
          <li>
            <a v-if="userInfo.branchCd !== '0120'" href="javascript:;" @click="goMyPage('800')">생활관리</a>
          </li>
        </ul>
      </li>
      <li :class="{ on: currentMenuGroup === 'examManagement' }">
        <span>성적관리</span>
        <ul>
          <li :class="{ on: $route.name === 'regularTest' }">
            <router-link :to="{ path: '/unityexam/regulartest' }">정규모의고사</router-link>
          </li>
          <li :class="{ on: $route.name === 'acaContents' }">
            <router-link :to="{ path: '/unityexam/acacontents' }">학원콘텐츠</router-link>
          </li>
          <li :class="{ on: $route.name === 'subjtAnalyze' }">
            <router-link :to="{ path: '/unityexam/subjtanalyze' }">과목별분석</router-link>
          </li>
          <li v-if="isStudent" :class="{ on: $route.name === 'unityExamWransNote' }">
            <router-link :to="{ path: '/unityexam/wransnote' }">오답노트</router-link>
          </li>
          <li v-if="isStudent" :class="{ on: $route.name === 'unityExamEnterAnswer' }">
            <router-link :to="{ path: '/unityexam/enteranswer' }">답안/정오답원인 입력</router-link>
          </li>
        </ul>
      </li>
      <li>
        <span>결제정보</span>
        <ul>
          <li><a href="javascript:;" @click="goMyPage('200')">결제하실 내역</a></li>
          <li><a href="javascript:;" @click="goMyPage('300')">결제내역</a></li>
          <li><a href="javascript:;" @click="goMyPage('400')">장바구니</a></li>
        </ul>
      </li>
      <li>
        <span>개인정보</span>
        <ul>
          <li>
            <a v-if="userInfo.branchCd === '0130'" @click="memInfoEdit(ETOOS_MEMBER_EDIT_URL + '?clientId=' + CLIENT_ID + '&accessToken=')">정보수정</a>
            <a v-else-if="userInfo.branchCd === '0030'" @click="memInfoEdit(ETOOS_MEMBER_EDIT_URL + '?clientId=' + CLIENT_ID + '&accessToken=')">정보수정</a>
            <a v-else-if="userInfo.branchCd === '0050'" @click="memInfoEdit(ETOOS_MEMBER_EDIT_URL + '?clientId=' + CLIENT_ID + '&accessToken=')">정보수정</a>
            <a v-else-if="userInfo.branchCd === '0090'" @click="memInfoEdit(ETOOS_MEMBER_EDIT_URL + '?clientId=' + CLIENT_ID + '&accessToken=')">정보수정</a>
            <a v-else-if="userInfo.branchCd === '0080'" @click="memInfoEdit(ETOOS_MEMBER_EDIT_URL + '?clientId=' + CLIENT_ID + '&accessToken=')">정보수정</a>
            <a v-else-if="userInfo.branchCd === '0040'" @click="memInfoEdit(ETOOS_MEMBER_EDIT_URL + '?clientId=' + CLIENT_ID + '&accessToken=')">정보수정</a>
            <a v-else-if="userInfo.branchCd === '0060'" @click="memInfoEdit(ETOOS_MEMBER_EDIT_URL + '?clientId=' + CLIENT_ID + '&accessToken=')">정보수정</a>
            <a v-else-if="userInfo.branchCd === '0021'" @click="memInfoEdit(ETOOS_MEMBER_EDIT_URL + '?clientId=' + CLIENT_ID + '&accessToken=')">정보수정</a>
            <a v-else-if="userInfo.branchCd === '0120'" @click="memInfoEdit(ETOOS_MEMBER_EDIT_URL + '?clientId=' + CLIENT_ID + '&accessToken=')">정보수정</a>
            <a v-else-if="userInfo.branchCd === '0110'" @click="memInfoEdit(ETOOS_MEMBER_EDIT_URL + '?clientId=' + CLIENT_ID + '&accessToken=')">정보수정</a>
            <a v-else-if="userInfo.branchCd === '0310'" @click="memInfoEdit(ETOOS_MEMBER_EDIT_URL + '?clientId=' + CLIENT_ID + '&accessToken=')">정보수정</a>
            <a v-else-if="userInfo.branchCd === '0020'" @click="memInfoEdit(ETOOS_MEMBER_EDIT_URL + '?clientId=' + CLIENT_ID + '&accessToken=')">정보수정</a>
            <a v-else-if="userInfo.branchCd === '0500'" @click="memInfoEdit(ETOOS_MEMBER_EDIT_URL + '?clientId=' + CLIENT_ID + '&accessToken=')">정보수정</a>
            <a v-else-if="userInfo.branchCd === '0600'" @click="memInfoEdit(ETOOS_MEMBER_EDIT_URL + '?clientId=' + CLIENT_ID + '&accessToken=')">정보수정</a>
            <a v-else>정보수정</a>
          </li>
          <li>
            <router-link :to="{ name: 'regularTest', query: { showModal: true } }" @click.native="goReload()">
              선택과목수정
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
    <div class="link">
      <a href="javascript:;" @click="goMyPage('1000')" class="link5">온라인결제<br /><strong>이용가이드</strong></a>
    </div>
  </div>
</template>

<script>
import { OLD_MY_PAGE_URL, LMS_PAGE_URL, ETOOS_MEMBER_EDIT_URL, CLIENT_ID, HOMEPAGE_URL } from '@build/build.config';
import { USER_AUTHORITY, FAVICON, PAGE_TITLE, BRANCH_SUBDOMAIN } from '@/const/const';
import {mapActions, mapState} from 'vuex';
import { AWS_S3_RESOURCE_URL } from '@build/build.config';
import { getBrandType } from '../../../utils/util';

function getCookieValue(cookieName) {
  const cookies = document.cookie.split('; ');
  for (let cookie of cookies) {
    const [name, value] = cookie.split('=');
    if (name === cookieName) {
      return decodeURIComponent(value); // URL 디코딩
    }
  }
  return null; // 쿠키가 없을 경우 null 반환
}

export default {
  name: 'RightNavi',
  data() {
    return {
      OLD_MY_PAGE_URL: OLD_MY_PAGE_URL[getBrandType(location.href)],
      HOMEPAGE_URL,
      LMS_PAGE_URL: LMS_PAGE_URL[getBrandType(location.href)],
      USER_AUTHORITY,
      lectureServiceMenuFlag: false,
      scheduleMenuFlag: false,
      dailyTestFlag: false,
      wrasnrMenuFlag: false,
      infinitePassFlag: false,
      studyManagementMenuFlag: false,
      FAVICON,
      PAGE_TITLE,
      BRANCH_SUBDOMAIN,
      ETOOS_MEMBER_EDIT_URL,
      CLIENT_ID: CLIENT_ID[getBrandType(location.href)]
    };
  },
  props: ['userInfo'],
  watch: {
    userInfo() {
      this.authorityCheck();
      //this.changeTitle();
      this.$forceUpdate();
    }
  },
  computed: {
    isStudent() {
      return ((this.userInfo || {}).personVo || {}).userAuthority === USER_AUTHORITY.STUDENT;
    },
    currentMenuGroup() {
      return (this.$route.meta || {}).menuGroup || '';
    },
    ...mapState({
      encMemNo: (state) => state.common.memNo
    })
  },
  methods: {
    ...mapActions(['getUser', 'getMemNo']),
    goSceduleMain() {
      this.$router.push({ name: 'schedulemain' });
    },
    goStudyMain() {
      this.$router.push({ name: 'studyMain' });
    },
    // 권한체크
    authorityCheck() {
      // 메뉴체크
      this.checkScheduleMenu(this.userInfo.personVo);
      this.checkDailyTestMenu(this.userInfo.personVo);
      this.checkLectureServiceMenu(this.userInfo.personVo);
      this.checkWransrMenu(this.userInfo.personVo);
      this.checkStudyManagementMenu(this.userInfo.personVo);
      this.checkInfinitePassMenu(this.userInfo.personVo);
      this.checkSchedulePage();
    },
    // 수강신청 메뉴체크
    checkScheduleMenu(personVo) {
      if (personVo.userAuthority === USER_AUTHORITY.STUDENT) {
        this.scheduleMenuFlag = !(!personVo.classRoomInfoList.length > 0);
      }
    },
    checkLectureServiceMenu(personVo) {
      if (personVo.userAuthority === USER_AUTHORITY.STUDENT) {
        this.lectureServiceMenuFlag = personVo.classRoomInfoList.some((classRoomInfo) => {
          if (classRoomInfo.firstgroupYn === 'Y') {
            return true;
          }
        });
      }
    },
    // 데일리 테스트 메뉴 체크
    checkDailyTestMenu(personVo) {
      if (personVo.userAuthority === USER_AUTHORITY.STUDENT) {
        this.dailyTestFlag = personVo.newDailyTestService;
      }
    },
    checkWransrMenu(personVo) {
      if (personVo.userAuthority === USER_AUTHORITY.STUDENT) {
        this.wrasnrMenuFlag = personVo.classRoomInfoList.some((classRoomInfo) => {
          if (classRoomInfo.firstgroupYn === 'Y') {
            return true;
          }
        });
      } else if (personVo.userAuthority === USER_AUTHORITY.PARENT) {
        this.wrasnrMenuFlag = personVo.childList.some((child) => {
          return child.classRoomInfoList.some((classRoomInfo) => {
            return classRoomInfo.firstgroupYn === 'Y';
          });
        });
      }
    },
    checkSchedulePage() {
      if ((this.$route.name === 'schedulemain' && !this.scheduleMenuFlag) || (this.$route.name === 'Main' && !this.dailyTestFlag)) {
        this.$router.replace('/');
      }
    },
    // 학습관리 메뉴 체크
    checkStudyManagementMenu(personVo) {
      if (personVo.userAuthority === USER_AUTHORITY.STUDENT) {
        this.studyManagementMenuFlag = personVo.classRoomInfoList.some((classRoomInfo) => {
          if (classRoomInfo.firstgroupYn === 'Y') {
            return true;
          }
        });
      } else if (personVo.userAuthority === USER_AUTHORITY.PARENT) {
        this.studyManagementMenuFlag = personVo.childList.some((child) => {
          return child.classRoomInfoList.some((classRoomInfo) => {
            return classRoomInfo.firstgroupYn === 'Y';
          });
        });
      }
    },
    // 현강무패 메뉴 체크
    checkInfinitePassMenu(personVo) {
      if (personVo.userAuthority === USER_AUTHORITY.STUDENT) {
        this.infinitePassFlag = personVo.stdTypeCd === '1';
      }
    },
    changeTitle() {
      const favicon = document.getElementById('favicon');
      let faviconUrl = '';
      let title = '';
      if (this.userInfo.branchCd === '') {
        faviconUrl = FAVICON['0020'];
        title = PAGE_TITLE['0020'];
      } else if (this.userInfo.branchCd === '0600' && this.userInfo.metaSubDomain === 'n1') {
        faviconUrl = FAVICON['0020'];
        title = '이투스네오';
      } else {
        faviconUrl = FAVICON[this.userInfo.branchCd];
        title = PAGE_TITLE[this.userInfo.branchCd];
      }

      favicon.href = AWS_S3_RESOURCE_URL + '/my' + faviconUrl;
      document.title = title;
    },
    goReload() {
      this.$router.go(this.$route.currentRoute);
    },
    memInfoEdit(editUrl) {
      const csAt = getCookieValue('cs_at');
      location.href = editUrl + csAt + '&callbackUrl=' + window.location.href;
    },
    created() {
      if (this.userInfo.branchCd === '') {
        this.getUser().finally(() => {
          this.authorityCheck();
          //this.changeTitle();
        });
      } else {
        this.authorityCheck();
        //this.changeTitle();
      }
    },
    goMyPage(menuCd) {
      const initDomain = {
        '0030': 'gangbuk',
        '0040': 'bucheon',
        '0050': 'bundang',
        '0080': 'ilsan',
        '0090': 'pyeongchon',
        '0130': 'a2',
        '0021': 'h1',
        '0110': 'h3',
        '0120': 'h2',
        '0060': 'etoos-gisuk',
        '0310': 'h4',
        '0500': 'n2',
        '0010': 'gangbuk'
      };
      const requestData = { etoosMemNo: this.userInfo.etoosMemNo };
      this.getMemNo(requestData).then(() => {
        window.location.href =
          this.OLD_MY_PAGE_URL +
          '/login/memNo.do?memNo=' +
          encodeURIComponent(this.encMemNo.mem_no) +
          '&returnUrl=' +
          encodeURIComponent(this.HOMEPAGE_URL[this.userInfo.branchCd]) +
          '&initDomain=' +
          initDomain[this.userInfo.branchCd] +
          '&menuCd=' +
          menuCd;
      });
    }
  }
};
</script>
