<template>
  <div class="item_filter on">
    <button type="button" class="btn_toggle">
      <strong class="tit_cate">강좌유형</strong>
      <em class="selected">{{ lectureNames }}</em>
    </button>
    <div class="panel">
      <ul class="list_item">
        <li>
          <label class="lbl_item">
            <strong class="tit_item">전체</strong>
            <input
              type="checkbox"
              class="inp_check"
              id="lec1"
              value=""
              @change="chkChange($event)"
              v-model="lectureType"
            />
            <i class="ico_comm ico_check"></i>
          </label>
        </li>
        <li v-for="[key, value] of Object.entries(lectureTypeMap)" v-bind:key="key">
          <label class="lbl_item">
            <strong class="tit_item">{{ value }}</strong>
            <input
              type="checkbox"
              class="inp_check"
              :id="`lec${key}`"
              :value="key"
              @change="chkChange($event)"
              v-model="lectureType"
            />
            <i class="ico_comm ico_check"></i>
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lectureType: [''],
      lectureNames: '전체',
      lectureList: [
        { lecCd: '', lecNm: '전체' },
        { lecCd: '1', lecNm: '재종반' },
        { lecCd: '2', lecNm: '단과' },
        { lecCd: '3', lecNm: '특강' }
      ]
    };
  },
  props: ['lectureDt', 'lectureTypeMap'],
  methods: {
    sendLecture() {
      this.$emit('lecture', this.lectureType);
    },
    chkChange(e) {
      if (e.target.value !== '') {
        if (e.target.id.indexOf('lec') >= 0) {
          const index = this.lectureType.indexOf('');
          if (index > -1) {
            this.lectureType.splice(index, 1);
          }
        } else {
          const index = this.subject.indexOf('');
          if (index > -1) {
            this.subject.splice(index, 1);
          }
        }
      } else {
        if (e.target.id.indexOf('lec') >= 0) {
          this.lectureType = [''];
        }
      }
    }
  },
  watch: {
    lectureType(args1) {
      if (this.lectureType.length === 0) this.lectureType = [''];
      this.sendLecture();

      this.lectureNames = '';
      const lec = [];
      args1.forEach((el) => {
        if (el !== '') {
          this.lectureList.forEach((l) => {
            if (el === l.lecCd) lec.push(l.lecNm);
          });
        } else {
          lec.push('전체');
        }
      });
      this.lectureNames = lec.join(',');
    },
    lectureDt(arg1) {
      if (arg1[0] === '') {
        this.lectureType = [''];
        this.sendLecture();
      }
    }
  }
};
</script>
