<template>
  <div id="filterLayer" class="layer_sidepage layer_sidepage_pass filter">
    <div class="layer_body">
      <div class="layer_head">
        <h4 class="tit_layer">필터</h4>
        <div class="expander">
          <button type="button" class="btn_refresh" @click="initSelectedOptionss"><span class="blind">새로고침</span></button>
        </div>
        <a href="javascript:;" class="layer_close" @click="close('#filterLayer')"><span class="blind">레이어 닫기</span></a>
      </div>
      <div class="layer_cont">
        <div class="item_filter" :class="{ on: lectureTypeCdSelect }">
          <button type="button" class="btn_toggle" @click="lectureTypeCdSelect = !lectureTypeCdSelect">
            <strong class="tit_cate">강좌유형</strong>
            <em class="selected">전체</em>
          </button>
          <div class="panel">
            <ul class="list_item">
              <li>
                <label class="lbl_item">
                  <strong class="tit_item">전체</strong>
                  <input
                    type="checkbox"
                    class="inp_check"
                    v-model="isSelectAlllectureTypeCds"
                    :disabled="!selectedOptions.lectureTypeCds.length"
                  />
                  <i class="ico_comm ico_check"></i>
                </label>
              </li>
              <li v-for="lectureTypeCd in lectureTypeCdList" :key="lectureTypeCd.cd">
                <label class="lbl_item">
                  <strong class="tit_item">{{ lectureTypeCd.nm }}</strong>
                  <input
                    type="checkbox"
                    class="inp_check"
                    v-model="selectedOptions.lectureTypeCds"
                    :value="lectureTypeCd"
                    @click="clickSelect($event)"
                  />
                  <i class="ico_comm ico_check"></i>
                </label>
              </li>
            </ul>
          </div>
        </div>
        <div class="item_filter" :class="{ on: subjectSelector }">
          <button
            type="button"
            class="btn_toggle"
            @click="subjectSelector = !subjectSelector"
          >
            <strong class="tit_cate">과목</strong>
            <em class="selected">전체</em>
          </button>
          <div class="panel">
            <ul class="list_item">
              <li>
                <label class="lbl_item">
                  <strong class="tit_item">전체</strong>
                  <input
                    type="checkbox"
                    class="inp_check"
                    v-model="isSelectAllSubjects"
                    :disabled="!selectedOptions.subjects.length"
                  />
                  <i class="ico_comm ico_check"></i>
                </label>
              </li>
              <li v-for="subject in subjectList" :key="subject.unitySubjtCd">
                <label class="lbl_item">
                  <strong class="tit_item">{{ subject.subjtNm }}</strong>
                  <input
                    type="checkbox"
                    class="inp_check"
                    v-model="selectedOptions.subjects"
                    :value="subject"
                    @click="clickSelect($event)"
                  />
                  <i class="ico_comm ico_check"></i>
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="dimmed_layer"></div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'search-table-m',
  components: {},
  props: {
    value: {
      type: Object
    },
    /**
     * 요청할 검색 api path
     */
    apiPath: {
      type: String,
      default: ''
    },
    /**
     * 페이지당 데이터 숫자
     */
    numPerPage: {
      type: Number,
      default: 10
    }
  },
  data: () => ({
    lectureTypeCdSelect: false,
    subjectSelector: false,
    isSelectAllSubjects: true,
    isSelectAlllectureTypeCds: true,
    selectedOptions: {
      lectureTypeCds: [],
      subjects: []
    },
    /*
     * 001(대입종합), 002(단과), 003(특강), 004(고등종합)
     */
    lectureTypeCdList: [
      { cd: '1', nm: '정규수업' },
      { cd: '21', nm: '수학전문관' },
      { cd: '2', nm: '단과' },
      { cd: '3', nm: '특강' }
    ]
  }),
  computed: {
    ...mapState({
      studentCd: (state) => state.common.userInfo.studentCd,
      subjectList: (state) => state.infinitePass.subjectList
    }),
    selectedFilterList: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  watch: {
    isSelectAllSubjects(val) {
      if (val) {
        this.selectedOptions.subjects = [];
      }
    },
    isSelectAlllectureTypeCds(val) {
      if (val) {
        this.selectedOptions.lectureTypeCds = [];
      }
    },
    'selectedOptions.subjects'() {
      if (this.selectedOptions.subjects.length === this.subjectList.length) {
        this.isSelectAllSubjects = true;
      } else {
        this.isSelectAllSubjects = !this.selectedOptions.subjects.length;
      }
      this.selectedFilterList = this.selectedOptions;
      if (this.numPerPage > 10) {
        this.$emit('setNumPerPage', 1);
        return;
      }
      this.search();
    },
    'selectedOptions.lectureTypeCds'() {
      if (this.selectedOptions.lectureTypeCds.length === this.lectureTypeCdList.length) {
        this.isSelectAlllectureTypeCds = true;
      } else {
        this.isSelectAlllectureTypeCds = !this.selectedOptions.lectureTypeCds.length;
      }
      this.selectedFilterList = this.selectedOptions;
      if (this.numPerPage > 10) {
        this.$emit('setNumPerPage', 1);
        return;
      }
      this.search();
    },
    numPerPage() {
      this.search();
    }
  },
  async mounted() {
    if (!this.studentCd) {
      await this.getUser();
    }
    if (!this.subjectList.length) {
      this.getInfiniteSubjects({ studentCd: this.studentCd });
    }
    this.searchAll();
  },
  methods: {
    ...mapActions(['getInfiniteSubjects', 'getSearchData', 'getUser']),
    async search() {
      const request = {
        studentCd: this.studentCd,
        unitySubjtCds: this.selectedOptions.subjects?.map((subject) => subject.unitySubjtCd).join(','),
        lectureTypeCds: this.selectedOptions.lectureTypeCds?.map((lectureTypeCd) => lectureTypeCd.cd).join(','),
        pageSize: this.numPerPage,
        pageNo: this.currPage
      };
      this.$emit('loadingStart');
      const result = await this.getSearchData({ apiPath: this.apiPath, params: request });
      this.$emit('search', result);
    },
    async searchAll() {
      const request = {
        studentCd: this.studentCd,
        pageSize: this.numPerPage,
        pageNo: this.currPage
      };
      this.$emit('loadingStart');
      const result = await this.getSearchData({ apiPath: this.apiPath, params: request });
      this.$emit('search', result);
    },
    initSelectedOptionss() {
      this.selectedOptions = {
        lectureTypeCds: [],
        subjects: []
      };
    },
    close(target) {
      const popup = document.querySelector(`${target}`);
      popup.classList.remove('open');
    },
    clickSelect(e) {
      if (e.target.checked) {
        if (this.subjectList.indexOf(e.target._value) !== -1
          && this.selectedOptions.subjects.length + 1 === this.subjectList.length) {
          e.target.checked = false;
          this.isSelectAllSubjects = true;
        } else if (this.lectureTypeCdList.indexOf(e.target._value) !== -1
                && this.selectedOptions.lectureTypeCds.length + 1 === this.lectureTypeCdList.length) {
          e.target.checked = false;
          this.isSelectAlllectureTypeCds = true;
        }
      }
    }
  }
};
</script>
