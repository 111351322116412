import MutationTypes from '../../MutationTypesReplayLecture';

export default {
  [MutationTypes.SET_REPLAY_LECTURES]: (state, payload) => {
    state.lectures = payload;
  },
  [MutationTypes.SET_SEARCH_ITEMS]: (state, payload) => {
    state.searchItems = payload;
  }
};
