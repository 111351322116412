<template>
<div>
    <div>etssso</div>
    <br />
    <div>ew0KICAgICJyZXN1bHRfY2QiOiIwMDAwIiwNCiAgICAibWVtX25vIjoiMjYxMTQ2MCIsDQogICAgIm1lbV9pZCI6Inp6ZWU3MjEiLA0KICAgICJtZW1fbm0iOiIlRUMlOUQlQjQlRUMlQTclODAlRUQlOTclOEMiLA0KICAgICJzZXNzaW9uX2lkIjoiMGRlMjkxZGYxZmQ0NDY2Y2EyY2M5OTBiODM3NWY3N2I5ZGZmYmFlYjc2Zjk0MzYyOGUiLA0KICAgICJsb2dpbl9kdCI6IjIwMjAtMTItMTAgMTA6NDU6NDAuOTEwIiwNCiAgICAiYXV0aF9rZXkiOiJjNDAzNjNmYTVjYzA1ZGY3NjE3NWJhY2I3YWNkNzgzN2YyYTQ3YmM5NWQ3Y2QwZjU2ZjRiZGNkOTM1OTk3NWMyIg0KfQ%3D%3D</div>
</div>
</template>
<script>
import { APPLICATION_PHASE } from '@build/build.config';
export default {
    created() {
        if(APPLICATION_PHASE !== 'LOCAL'){
            this.$router.push('/studymain');
        } 
    }   
}
</script>