import TmsCookieUtil from '@/utils/TmsCookieUtil';
import { TMS_API_URL } from '@build/build.config';

export const tmsImage = {
  data() {
    return {
      token: ''
    };
  },
  methods: {
    getTmsImg(path) {
      const fullpath = TMS_API_URL + '/file/get-file/FileData_ZOCBOv4 BANK_' + path + '?Authorization=';
      if (!this.token) {
        TmsCookieUtil.getTmsToken().then((value) => {
          this.token = value;
        });
      }
      return fullpath + this.token;
    }
  }
};
