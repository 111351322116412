<template>
  <!-- 오답노트 출력 -->
  <div id="app" class="popup_cont popup_ansr print" :class="{ mobile: isMobile }">
    <div class="popup_head">
      <h1 class="tit_popup">오답노트 출력</h1>
      <div class="expander">
        <button type="button" class="btn_print" onclick="window.print();"><span class="blind">인쇄하기</span></button>
      </div>
    </div>
    <div class="popup_body">
      <div v-for="(itemSubject, activeLiIndex) in categories">
        <div v-for="(itemQustn, idx) in qustnList[activeLiIndex]" class="wrap_exam_paper">
          <div class="paper_info">
            <table class="tbl_comm">
              <caption>
                문항 정보
              </caption>
              <colgroup>
                <col style="width:90px" />
                <col style="width:auto" />
                <col style="width:90px" />
                <col style="width:80px " />
                <col style="width:90px" />
                <col style="width:80px" />
              </colgroup>
              <tbody>
              <tr>
                <th scope="row">시험정보</th>
                <td class="data_cont">{{ examYear }}년 {{ itemSubject.unityExamNm }} {{ itemSubject.unityDtlExamNm }} {{ itemSubject.unityDtlExamTurnOrd }}회
                  {{ itemSubject.subjtNm }}({{ itemSubject.dtlSubjtNm }})</td>
                <th scope="row">문항번호</th>
                <td>{{ itemQustn.no }}</td>
                <th scope="row">학생명</th>
                <td>{{ userInfo.userName }}</td>
              </tr>
              <tr>
                <th scope="row">문항분류</th>
                <td v-if="itemQustn.qustnPubrNm === null" class="data_cont">
                  -
                </td>
                <td v-else class="data_cont">
                  {{ itemQustn.qustnPubrNm }} >
                  {{ itemQustn.qustnLuntNm }} >
                  {{ itemQustn.qustnSbptNm }} >
                  {{ itemQustn.qustnSmunitNm }}
                </td>
                <th scope="row">{{ itemQustn.coransrYn === 'Y' ? '정답원인' : '오답원인' }}</th>
                <td colspan="3">{{ getQustnCause(itemQustn) }}</td>
              </tr>
              </tbody>
            </table>
            <div class="exam_cont">
              <div class="box_question" :class="{ on: contentView == 'type1' }">
                <strong class="blind">지문/문항</strong>
                <div v-if="StringUtils.isNotEmpty((itemQustn || {}).textImgPathwy) && itemQustn.qustnInpMethodCd === '1'">
                  <img :src="awsResourceUrl + '/unity-exam/' + itemQustn.textImgPathwy" alt="" />
                </div>
                <div v-if="StringUtils.isNotEmpty((itemQustn || {}).textImgPathwy) && itemQustn.qustnInpMethodCd === '2'">
                  <img :src="getTmsImg(itemQustn.textImgPathwy)" alt="" />
                </div>
                <div v-if="StringUtils.isNotEmpty((itemQustn || {}).qustnImgPathwy)">
                  <img
                      v-if="itemQustn.qustnInpMethodCd === '1'"
                      :src="awsResourceUrl + '/unity-exam/' + itemQustn.qustnImgPathwy"
                      alt=""
                  />
                  <img
                      v-if="itemQustn.qustnInpMethodCd === '2'"
                      :src="getTmsImg(itemQustn.qustnImgPathwy)"
                      alt=""
                  />
                </div>
                <div v-else>
                  <p class="blank">문항이미지가 없습니다.</p>
                </div>
              </div>
              <div class="box_explain" :class="{ on: contentView == 'type2' }">
                <strong class="blind">해설</strong>
                <div v-if="StringUtils.isNotEmpty((itemQustn || {}).explImgPathwy)">
                  <img
                      v-if="itemQustn.qustnInpMethodCd === '1'"
                      :src="awsResourceUrl + '/unity-exam/' + itemQustn.explImgPathwy"
                      alt=""
                  />
                  <img
                      v-if="itemQustn.qustnInpMethodCd === '2'"
                      :src="getTmsImg(itemQustn.explImgPathwy)"
                      alt=""
                  />
                </div>
                <div v-else>
                  <p class="blank">해설이미지가 없습니다.</p>
                </div>
              </div>
            </div>
            <div class="txt_date">{{ todayFormat() }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- //오답노트 출력 -->
</template>
<style type="text/css">
@media print {
  .wrap_exam_paper {
    margin-top: 15px;
  }
  .popup_cont .wrap_exam_paper {
    page-break-before: always;
  }
  .popup_body {
    width:95% !important; ;
  }
}
</style>
<script>
import { mapActions, mapState } from 'vuex';
import Modal from '@/components/unityexam/common/Modal';
import { AWS_S3_RESOURCE_URL } from '@build/build.config';
import { tmsImage } from '@/mixins/dailytest/tmsImage';
import StringUtils from '@/utils/StringUtils';
import DateUtil from '@/utils/DateUtil';
export default {
  name: 'unityExamWranNotePrintPopup',
  mixins: [tmsImage],
  components: {
    Modal
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      userInfo: (state) => state.common.userInfo,
      wransrnoteTableExamInfo: (state) => state.unityexam.wransrnote.wransrnoteTableExamInfo,
      wransrnoteQustnInfo: (state) => state.unityexam.wransrnote.wransrnoteQustnInfo,
    })
  },
  created() {
    if (window.NodeList && !NodeList.prototype.forEach) {
      NodeList.prototype.forEach = Array.prototype.forEach;
    }
    //document.body.classList.add('print_g');
    this.examYear = this.$route.query['examYy'];
    this.contentView = this.$route.query['contentView'] || 'type1';
    if (!this.userInfo.studentCd) {
      this.getUser().then(() => {
        let params = {};
        params.examYy = this.examYear;
        params.unityExamSeq = this.$route.query['unityExamSeq'];
        params.unityDtlExamSeq = this.$route.query['unityDtlExamSeq'];
        params.unityDtlExamTurnOrdSeq = this.$route.query['unityDtlExamTurnOrdSeq'];
        params.dtlSubjtSeq = this.$route.query['dtlSubjtSeq'];
        this.initSelectExam(params);

      });
    } else {
      let params = {};
      params.examYy = this.examYear;
      params.unityExamSeq = this.$route.query['unityExamSeq'];
      params.unityDtlExamSeq = this.$route.query['unityDtlExamSeq'];
      params.unityDtlExamTurnOrdSeq = this.$route.query['unityDtlExamTurnOrdSeq'];
      params.dtlSubjtSeq = this.$route.query['dtlSubjtSeq'];
      this.initSelectExam(params);
    }
  },
  methods: {
    ...mapActions([
      'getUser',
      'getWransrnoteTableExam',
      'getEnterAnswerExamQustn',
      'getWransrnoteQustn',
    ]),
    initSelectExam(params) {
      this.getWransrnoteTableExam(params).then(() => {
        this.categories = [];
        for (const subjt of this.wransrnoteTableExamInfo) {
          this.categories.push(subjt);
          this.qustnList.push(subjt.includeSmlQustnResponse.includeSmlQustnList);
          this.notIncludeSmlQustnList.push(subjt.includeSmlQustnResponse.qustnList);
          this.includeSmlQustnList.push(subjt.includeSmlQustnResponse.includeSmlQustnList);
        }
        if(this.$route.query['typeCheck']==='true'){
          this.qustnList = this.includeSmlQustnList;
        }else{
          this.qustnList = this.notIncludeSmlQustnList;
        }
      });
    },
    getQustnCause(qustn){
      let cause = '';
      if(qustn.coransrYn === 'Y'){
        cause = qustn.coransrCauseNm;
        if(cause === '기타' && qustn.coransrWransrNotesEtcOpninCont !== null){
          cause += '(' + qustn.coransrWransrNotesEtcOpninCont + ')';
        }
      }else{
        cause = qustn.wransrCauseNm;
        if(cause === '기타' && qustn.coransrWransrNotesEtcOpninCont !== null){
          cause += '(' + qustn.coransrWransrNotesEtcOpninCont + ')';
        }
      }
      return cause;
    },
    todayFormat() {
      return DateUtil.dateFormatter('{yyyy}.{mm}.{dd} {hh}:{mi}', new Date());
    },
  },
  data() {
    return {
      contentView: 'type1',
      categories: [{ title: '사회문화' }, { title: '경제' }, { title: '테스트1' }, { title: '테스트2' }],
      qustnList: [],
      notIncludeSmlQustnList: [],
      includeSmlQustnList: [],
      awsResourceUrl: AWS_S3_RESOURCE_URL,
      StringUtils,
      examYear: ''
    };
  }
};
</script>
