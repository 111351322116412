import MutationTypes from './MutationTypes';

export default {
  [MutationTypes.SET_MYCLASS_INFO_DATA]: (state, payload) => {
    state.myClassInfoData = payload;
  },
  [MutationTypes.SET_INFINITE_SUBJECT_LIST](state, payload) {
    state.subjectList = payload;
  },
  [MutationTypes.SET_INFINITE_TEACHER_LIST](state, payload) {
    state.teacherList = payload;
  },
  [MutationTypes.SET_INFINITE_TURN_ORD_LECTURE](state, payload) {
    state.turnOrdLectures = payload;
  }
};
