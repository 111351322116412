import getters from './getters';
import actions from './actions';
import mutations from './mutations';
const state = {
  seasonList: {},
  seasonData: {},
  studentScheduleList: {},
  scheduleList: {},
  studentHistoryList: [],
  studentGroup: {},
  statusResult: {},
  seasonPerdMap: {},
  listByYear: [],
  defaultSeason: {
    defaultYear: '',
    defaultGrpIdx: 0,
    defaultSeasonCd: ''
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
