<template>
  <div>
    <!-- router view -->
    <slot></slot>
    <!-- // router view -->
  </div>
</template>
<script>
export default {
  name: 'DefaultLayout'
};
</script>
