import MutationTypes from '@/store/MutationTypes';
import InfinitePassApi from '@/api/InfinitePassApi';

const VIEW_TYPE = {
  LECTURE: 'LECTURE',
  CURRI: 'CURRI'
};

export default {
  getMyClassInfo: async ({ commit }, params) => {
    try {
      const result = await InfinitePassApi.getMyClassInfo(params);
      commit(MutationTypes.SET_MYCLASS_INFO_DATA, result);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  /**
   * 학생이 수강 가능한 강좌의 과목 목록을 조회
   *
   * @param params API에 동반할 파라미터들
   * @returns {Promise<*|undefined>} api 응답으로 받은 데이터 반환
   */
  getInfiniteSubjects: async ({ commit }, params) => {
    try {
      const result = await InfinitePassApi.getInfiniteSubjects(params);
      commit(MutationTypes.SET_INFINITE_SUBJECT_LIST, result.data);
      return result;
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  /**
   * 학생이 수강 가능한 강좌의 선생님 목록을 조회
   *
   * @param params API에 동반할 파라미터들
   * @returns {Promise<*|undefined>} api 응답으로 받은 데이터 반환
   */
  getInfiniteTeachers: async ({ commit }, params) => {
    try {
      const result = await InfinitePassApi.getInfiniteTeachers(params);
      commit(MutationTypes.SET_INFINITE_TEACHER_LIST, result.data);
      return result;
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  /**
   * 원하는 API path에 get 요청을 하는 함수
   *
   * @param apiPath 검색(GET) 요청을 보내고자 하는 API path
   * @param params 해당 API에 동반할 파라미터들
   * @returns {Promise<*|undefined>} api 응답으로 받은 데이터 반환
   */
  getSearchData: async ({ commit }, { apiPath, params }) => {
    try {
      return await InfinitePassApi.getSearchData(apiPath, params);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  /**
   * 수강 중인 강의 삭제(다건)
   *
   * @param params 수강 중인 강의 삭제 API 요청 파라미터
   * @returns {Promise<*>} API 반환 데이터
   */
  deleteCurriInClass: async ({ commit }, params) => {
    try {
      return await InfinitePassApi.deleteCurriInClass(params);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  getTurnOrdLectures: async ({ commit }, params) => {
    try {
      const result = await InfinitePassApi.getTurnOrdLectures(params);
      commit(MutationTypes.SET_INFINITE_TURN_ORD_LECTURE, result.data);
      return result;
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  addInterestedLecture: async ({ commit }, params) => {
    try {
      return await InfinitePassApi.addInterestedLecture(params);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  removeInterestedLectures: async ({ commit }, params) => {
    try {
      return await InfinitePassApi.removeInterestedLectures(params);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  }
};
