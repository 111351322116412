'use Strict';

class StringUtils {
  isEmpty(str) {
    return str === undefined || str === null || str.trim() === '';
  }
  isNotEmpty(str) {
    return !this.isEmpty(str);
  }
}
const stringUtils = new StringUtils();
export default stringUtils;
