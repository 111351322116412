<template>
  <div class="item_filter">
    <button type="button" class="btn_toggle">
      <strong class="tit_cate">과목</strong>
      <em class="selected">{{ subjectNames }}</em>
    </button>
    <div class="panel">
      <ul class="list_item">
        <span v-for="(sub, idx) in subjectData" :key="idx">
          <span v-if="sub.subjtName !== ''">
            <li>
              <label class="lbl_item">
                <strong class="tit_item">{{ sub.subjtName }}</strong>
                <input
                  type="checkbox"
                  class="inp_check"
                  :id="'sub'"
                  :value="sub.subjtCd"
                  v-model="subject"
                  @change="chkChange($event)"
                />
                <i class="ico_comm ico_check"></i>
              </label>
            </li>
          </span>
        </span>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      subject: [''],
      subjectNames: '전체'
    };
  },
  watch: {
    subject(args1) {
      if (this.subject.length === 0) this.subject = [''];
      this.sendSubject();

      this.subjectNames = '';
      const subjt = [];
      args1.forEach((el) => {
        if (el !== '') {
          this.subjectData.forEach((sub) => {
            if (el === sub.subjtCd) subjt.push(sub.subjtName);
          });
        } else {
          subjt.push('전체');
        }
      });
      this.subjectNames = subjt.join(',');
    },
    subjectDt(arg1) {
      if (arg1[0] === '') {
        this.subject = [''];
        this.sendSubject();
      }
    }
  },
  props: ['subjectData', 'subjectDt'],
  methods: {
    sendSubject() {
      this.$emit('subject', this.subject);
    },
    chkChange(e) {
      if (e.target.value !== '') {
        const index = this.subject.indexOf('');
        if (index > -1) {
          this.subject.splice(index, 1);
        }
      } else {
        this.subject = [''];
      }
    }
  }
};
</script>
