<template>
  <div class="module-table">
    <template v-if="tableStyle === 'regularTestScore'">
      <div class="wrap_title">
        <h5 class="tit_cont">
          <span>{{ tableTitle }}</span>
        </h5>
      </div>
      <div class="cont_data_info">
        <table class="tbl_comm">
          <colgroup>
            <col style="width:16.6666%" />
            <col style="width:16.6666%" />
            <col style="width:16.6666%" />
            <col style="width:16.6666%" />
            <col style="width:16.6666%" />
            <col style="width:16.6666%" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">과목</th>
              <th scope="col">원점수</th>
              <th scope="col">표준점수</th>
              <th scope="col">백분위</th>
              <th scope="col">등급</th>
              <th scope="col">상위누적</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="sortedPersonalScoreList.length === 0">
              <td colspan="6">입력한 성적이 없습니다</td>
            </tr>
            <tr v-for="(obj, idx) in sortedPersonalScoreList" :key="idx">
              <td>{{ obj.subjtNm }}</td>
              <td>{{ typeof obj.orgScr === 'number' ? obj.orgScr : '-' }}</td>
              <td>{{ typeof obj.stndScr === 'number' ? obj.stndScr : '-' }}</td>
              <td>{{ typeof obj.pctrk === 'number' ? obj.pctrk : '-' }}</td>
              <td>{{ typeof obj.grd === 'number' ? obj.grd : '-' }}</td>
              <td>{{ typeof obj.highAcumlt === 'number' ? obj.highAcumlt : '-' }}</td>
            </tr>
          </tbody>
        </table>
        <p class="tbl_txt" v-if="(univGroup || '') !== ''">※ 지원가능대학 : {{ univGroup }}</p>
      </div>
    </template>
    <template v-else-if="tableStyle === 'acaContentsScore'">
      <div class="wrap_title">
        <h5 class="tit_cont">
          <span>{{ tableTitle }}</span>
        </h5>
      </div>
      <div class="cont_data_info">
        <table class="tbl_comm">
          <colgroup>
            <col style="width:30%" />
            <col style="width:35%" />
            <col style="width:35%" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">과목</th>
              <th scope="col">평균 점수</th>
              <th scope="col">내 점수</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="sortedPersonalScoreList.length === 0">
              <td colspan="3">입력한 성적이 없습니다</td>
            </tr>
            <tr v-for="(obj, idx) in sortedPersonalScoreList" :key="idx">
              <td>{{ obj.subjtNm }}</td>
              <td>{{ typeof obj.avgOrgScr === 'number' ? parseFloat(obj.avgOrgScr).toFixed(1) : '-' }}</td>
              <td>{{ typeof obj.orgScr === 'number' ? parseFloat(obj.orgScr).toFixed(1) : '-' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-else-if="tableStyle === 'regularTestQuestionInfo'">
      <div class="cont_data_info">
        <table class="tbl_comm">
          <colgroup>
            <col style="width: 45px" />
            <col class="pc_only" style="width: 300px" />
            <col class="pc_only" style="width: 50px" />
            <col style="width: 60px" />
            <col style="width: 50px" />
            <col style="width: 50px" />
            <col class="pc_only" style="width: auto" />
            <col style="width: 60px" />
            <col class="pc_only" style="width: 60px" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">NO</th>
              <th scope="col" class="pc_only">문항분류</th>
              <th scope="col" class="pc_only">난이도</th>
              <th scope="col">오답률</th>
              <th scope="col">정답</th>
              <th scope="col">내답안</th>
              <th scope="col" class="pc_only">정오답원인</th>
              <th scope="col">오답노트</th>
              <th scope="col" class="pc_only">해설영상</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="filteredQuestionInfoList.length === 0">
              <td :colspan="isMobile ? 5 : 9">문항정보를 제공하지 않습니다.</td>
            </tr>
            <tr
              :class="obj.myAnsr !== obj.coransr ? 'wrong_ansr' : ''"
              v-for="(obj, key) in filteredQuestionInfoList || []"
              :key="key"
            >
              <td>
                <a
                  href="javascript:;"
                  @click="openQuestionViewPopup(obj.bystdntUnityExamQustnInpStusSeq)"
                  class="check"
                >{{ obj.qustnNo }}</a
                >
              </td>
              <td class="pc_only" :style="getQuestionType(obj) ? { textAlign: 'left' } : { textAlign: 'center' }">
                {{ getQuestionType(obj) || '-' }}
              </td>
              <td class="pc_only">{{ obj.questionLevel || '-' }}</td>
              <td
                :class="
                  getWransrRate(obj) !== '-' && highlightQuestions.indexOf(obj.bystdntUnityExamQustnInpStusSeq) !== -1
                    ? 'highlight'
                    : ''
                "
              >
                {{ getWransrRate(obj) }}
              </td>
              <td>{{ obj.coransr }}</td>
              <td class="txt_ansr">{{ obj.myAnsr }}</td>
              <td class="pc_only txt_ansr">{{ getCoransrDescription(obj) }}</td>
              <td>
                <label class="lab_check">
                  <input
                    type="checkbox"
                    class="inp_check"
                    :checked="obj.coransrNotesYn === 'Y'"
                    :disabled="userInfo.userAuthority !== USER_AUTHORITY.STUDENT"
                    @change="changeCoransrNoteYn(obj.bystdntUnityExamQustnInpStusSeq)"
                  />
                  <span class="ico_check"></span>
                </label>
              </td>
              <td class="pc_only">
                <a
                  v-if="obj.explCurriVodPathwy !== ''"
                  href="javascript:;"
                  @click="openVodPopup(obj.explCurriVodPathwy, obj.qustnInpMethodCd)"
                  class="link_more"
                ><span class="txt">보기</span></a
                >
                <template v-else>-</template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-else-if="tableStyle === 'acaContentsQuestionInfo'">
      <div class="cont_data_info">
        <table class="tbl_comm">
          <colgroup>
            <col style="width: 45px" />
            <col class="pc_only" style="width: 300px" />
            <col class="pc_only" style="width: 50px" />
            <col style="width: 60px" />
            <col style="width: 50px" />
            <col style="width: 50px" />
            <col class="pc_only" style="width: auto" />
            <col style="width: 60px" />
            <col class="pc_only" style="width: 60px" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">NO</th>
              <th scope="col" class="pc_only">문항분류</th>
              <th scope="col" class="pc_only">난이도</th>
              <th scope="col">오답률</th>
              <th scope="col">정답</th>
              <th scope="col">내답안</th>
              <th scope="col" class="pc_only">정오답원인</th>
              <th scope="col">오답노트</th>
              <th scope="col" class="pc_only">해설영상</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="filteredQuestionInfoList.length === 0">
              <td :colspan="isMobile ? 5 : 9">문항정보를 제공하지 않습니다.</td>
            </tr>
            <tr
              :class="obj.myAnsr !== obj.coransr ? 'wrong_ansr' : ''"
              v-for="(obj, key) in filteredQuestionInfoList || []"
              :key="key"
            >
              <td>
                <a
                  href="javascript:;"
                  @click="openQuestionViewPopup(obj.bystdntUnityExamQustnInpStusSeq)"
                  class="check"
                >{{ obj.qustnNo }}</a
                >
              </td>
              <td class="pc_only" :style="getQuestionType(obj) ? { textAlign: 'left' } : { textAlign: 'center' }">
                {{ getQuestionType(obj) || '-' }}
              </td>
              <td class="pc_only">{{ obj.questionLevel || '-' }}</td>
              <td
                :class="
                  getWransrRate(obj) !== '-' && highlightQuestions.indexOf(obj.bystdntUnityExamQustnInpStusSeq) !== -1
                    ? 'highlight'
                    : ''
                "
              >
                {{ getWransrRate(obj) }}
              </td>
              <td>{{ obj.coransr }}</td>
              <td class="txt_ansr">{{ obj.myAnsr }}</td>
              <td class="txt_cmt pc_only txt_ansr">{{ getCoransrDescription(obj) }}</td>
              <td>
                <label class="lab_check">
                  <input
                    type="checkbox"
                    class="inp_check"
                    :checked="obj.coransrNotesYn === 'Y'"
                    :disabled="userInfo.userAuthority !== USER_AUTHORITY.STUDENT"
                    @change="changeCoransrNoteYn(obj.bystdntUnityExamQustnInpStusSeq)"
                  />
                  <span class="ico_check"></span>
                </label>
              </td>
              <td class="pc_only">
                <a
                  v-if="obj.explCurriVodPathwy !== ''"
                  href="javascript:;"
                  @click="openVodPopup(obj.explCurriVodPathwy, obj.qustnInpMethodCd)"
                  class="link_more"
                ><span class="txt">보기</span></a
                >
                <template v-else>-</template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-else-if="tableStyle === 'statsByQuestionTypeTable'">
      <div class="wrap_title">
        <h5 class="tit_cont">
          <span>{{ tableTitle }}</span>
        </h5>
      </div>
      <div class="cont_data_info">
        <table class="tbl_comm tbl_result">
          <colgroup>
            <col style="width:60px" />
            <col style="width:auto" />
            <col style="width:120px" />
            <col class="pc_only" style="width:120px" />
            <col class="pc_only" style="width:100px" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">NO</th>
              <th scope="col">문항분류</th>
              <th scope="col">내 오답률</th>
              <th scope="col" class="pc_only">평균 오답률</th>
              <th scope="col" class="pc_only">오답노트</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="filteredAnswerByQuestionTypes.length === 0">
              <td :colspan="isMobile ? 3 : 5">{{ subjectName }}에 틀린 문항이 없습니다.</td>
            </tr>
            <tr v-for="(obj, idx) in filteredAnswerByQuestionTypes" :key="idx">
              <td>{{ obj.pageIdx + 1 }}</td>
              <td>{{ getQuestionType(obj) }}</td>
              <td>{{ parseFloat(obj.myWrongAnswerRate).toFixed(1) }}%</td>
              <td :class="(highlightQuestionTypes.indexOf(obj.pageIdx) !== -1 ? 'highlight' : '') + ' pc_only'">
                {{
                  typeof obj.allWrongAnswerRate === 'number' ? parseFloat(obj.allWrongAnswerRate).toFixed(1) + '%' : '-'
                }}
              </td>
              <td class="pc_only">
                <button type="button" class="link_wransr" @click="openQustnByTypePopup(obj.wrongAnswerQustnSeqs)">
                  {{ obj.wrongAnswerQustnSeqs.length }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-else-if="tableStyle === 'TypeD'">
      <div class="cont_data_info">
        <table class="tbl_comm tbl_wransr">
          <colgroup>
            <col class="pc_only" style="width: 40px" />
            <col class="pc_only" style="width: 136px" />
            <col style="width: auto" />
            <col class="pc_only" style="width: 136px" />
            <col class="pc_only" style="width: 60px" />
            <col class="pc_only" style="width: 135px" />
            <col class="pc_only" style="width: 135px" />
            <col class="pc_only" style="width: 60px" />
            <col style="width: 90px" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col" class="pc_only">NO</th>
              <th scope="col" class="pc_only">시험구분</th>
              <th scope="col">시험명</th>
              <th scope="col" class="pc_only">세부시험명</th>
              <th scope="col" class="pc_only">회차</th>
              <th scope="col" class="pc_only">과목</th>
              <th scope="col" class="pc_only">선택과목</th>
              <th scope="col" class="pc_only">복습현황</th>
              <th scope="col">복습</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, idx) in wransrnoteTableExamInfo.slice((currentPage - 1) * pagePerCnt, currentPage * pagePerCnt)"
              :key="idx"
            >
              <td class="pc_only">{{ idx + 1}}</td>
              <td class="pc_only">{{ item.untiyExamDivNm }}</td>
              <td class="txt_name">
                {{ item.unityExamNm }}<span class="mo_only">{{ item.unityDtlExamTurnOrd }}회 {{ item.subjtNm }} ({{ item.dtlSubjtNm }})</span>
              </td>
              <td class="pc_only">{{ item.unityDtlExamNm }}</td>
              <td class="pc_only">{{ item.unityDtlExamTurnOrd }}회</td>
              <td class="pc_only">{{ item.subjtNm }}</td>
              <td class="pc_only">{{ item.dtlSubjtNm }}</td>
              <td :class="item.reviEdCnt + item.reviEdDtlCnt + item.reviEdSmiCnt ===  item.wransrCnt + item.wransrDtlCnt + item.wransrSmiCnt  ? ' pc_only' : 'txt_not_yet pc_only'">
                {{ item.reviEdCnt + item.reviEdDtlCnt + item.reviEdSmiCnt }} / {{ item.wransrCnt + item.wransrDtlCnt + item.wransrSmiCnt  }}
              </td>
              <td>
                <button
                  v-if="item.reviEdCnt + item.reviEdDtlCnt + item.reviEdSmiCnt !==  item.wransrCnt + item.wransrDtlCnt + item.wransrSmiCnt"
                  type="button"
                  class="btn_wransr_note start"
                  @click="openWransnotePopup(item.unityExamSeq, item.unityDtlExamSeq, item.unityDtlExamTurnOrdSeq, item.unityExamSubjtSeq)"
                >
                  복습시작
                </button>
                <button
                  v-if="item.reviEdCnt + item.reviEdDtlCnt + item.reviEdSmiCnt ===  item.wransrCnt + item.wransrDtlCnt + item.wransrSmiCnt"
                  type="button"
                  class="btn_wransr_note"
                  @click="openWransnotePopup(item.unityExamSeq, item.unityDtlExamSeq, item.unityDtlExamTurnOrdSeq, item.unityExamSubjtSeq)"
                >
                  복습완료
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <p class="tbl_txt ta_r">{{ tableNotice }}</p>
      </div>
    </template>
    <template v-else-if="tableStyle === 'TypeEnterAnswer'">
      <div class="cont_data_info">
        <table class="tbl_comm tbl_wransr">
          <colgroup>
            <col class="pc_only" style="width: 40px" />
            <col class="pc_only" style="width: 100px" />
            <col style="width: auto" />
            <col class="pc_only" style="width: 158px" />
            <col class="pc_only" style="width: 60px" />
            <col class="pc_only" style="width: 120px" />
            <col style="width: 100px" />
            <col style="width: 100px" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col" class="pc_only">NO</th>
              <th scope="col" class="pc_only">시험구분</th>
              <th scope="col">시험명</th>
              <th scope="col" class="pc_only">세부시험명</th>
              <th scope="col" class="pc_only">회차</th>
              <th scope="col" class="pc_only">과목</th>
              <th scope="col">답안 <span class="pc_only">입력</span></th>
              <th scope="col">정오답원인 <span class="pc_only">선택</span></th>
            </tr>
          </thead>
          <tbody v-if="!isMobile">
            <tr
              v-for="(item, idx) in enterAnswerPossibleExamInfo.slice(
                (currentPage - 1) * pagePerCnt,
                currentPage * pagePerCnt
              )"
              :key="idx"
            >
              <td class="pc_only">{{ idx + 1 }}</td>
              <td class="pc_only">{{ item.untiyExamDivNm }}</td>
              <td class="txt_name">
                {{ item.unityExamNm }}<span class="mo_only">{{ item.unityDtlExamTurnOrd }}회 {{ item.subjtNm }}</span>
              </td>
              <td class="pc_only">{{ item.unityDtlExamNm }}</td>
              <td class="pc_only">{{ item.unityDtlExamTurnOrd }}회</td>
              <td class="pc_only">{{ item.subjtNm }}</td>
              <td>
                <button
                  v-if="item.enterAnswer === 'Y'"
                  type="button"
                  class="btn_wransr_note"
                  :disabled="item.answerInpPosbleYn === 'N'"
                  @click="openTypeEnterAnswerPopup(item.unityExamSeq, item.unityDtlExamSeq, item.unityDtlExamTurnOrdSeq, item.subjtSeq)"
                >
                  완료
                </button>
                <button
                  v-if="item.enterAnswer === 'N'"
                  type="button"
                  class="btn_wransr_note start"
                  :disabled="item.answerInpPosbleYn === 'N'"
                  @click="openTypeEnterAnswerPopup(item.unityExamSeq, item.unityDtlExamSeq, item.unityDtlExamTurnOrdSeq ,item.subjtSeq)"
                >
                  미완료
                </button>
              </td>
              <td>
                <button
                  v-if="item.enterAnswer === 'Y' && item.enterWransr === 'Y'"
                  type="button"
                  class="btn_wransr_note"
                  @click="openTypeViewQustnPopup(item.unityExamSeq, item.unityDtlExamSeq, item.unityDtlExamTurnOrdSeq, item.subjtSeq)"
                >
                  {{ item.coransrWransrCauseInpPosbleYn === 'Y' ? '완료 ' : '-' }}
                </button>
                <button
                  v-if="(item.enterAnswer === 'N' || item.enterWransr === 'N') && item.coransrWransrCauseInpPosbleYn === 'Y'"
                  type="button"
                  class="btn_wransr_note start"
                  :disabled="item.enterAnswer === 'N'"
                  @click="openTypeViewQustnPopup(item.unityExamSeq, item.unityDtlExamSeq, item.unityDtlExamTurnOrdSeq, item.subjtSeq)"
                >
                  미완료
                </button>
                {{ (item.enterAnswer === 'N' || item.enterWransr === 'N') && item.coransrWransrCauseInpPosbleYn === 'N' ? '-' : '' }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="(item, idx) in enterAnswerPossibleExamInfo.slice(0, currentPage * pagePerCnt)" :key="idx">
              <td class="pc_only">{{ idx }}</td>
              <td class="pc_only">{{ item.untiyExamDivNm }}</td>
              <td class="txt_name">
                {{ item.unityExamNm }}<span class="mo_only">{{ item.unityDtlExamTurnOrd }}회 {{ item.subjtNm }}</span>
              </td>
              <td class="pc_only">{{ item.unityDtlExamNm }}</td>
              <td class="pc_only">{{ item.unityDtlExamTurnOrd }}회</td>
              <td class="pc_only">{{ item.subjtNm }}</td>
              <td>
                <button
                  v-if="item.enterAnswer === 'Y'"
                  type="button"
                  class="btn_wransr_note"
                  :disabled="item.answerInpPosbleYn === 'N'"
                  @click="openTypeEnterAnswerPopup(item.unityExamSeq, item.unityDtlExamSeq, item.unityDtlExamTurnOrdSeq, item.subjtSeq)"
                >
                  완료
                </button>
                <button
                  v-if="item.enterAnswer === 'N'"
                  type="button"
                  class="btn_wransr_note start"
                  :disabled="item.answerInpPosbleYn === 'N'"
                  @click="openTypeEnterAnswerPopup(item.unityExamSeq, item.unityDtlExamSeq, item.unityDtlExamTurnOrdSeq, item.subjtSeq)"
                >
                  미완료
                </button>
              </td>
              <td>
                <button
                  v-if="item.enterAnswer === 'Y' && item.enterWransr === 'Y'"
                  type="button"
                  class="btn_wransr_note"
                  @click="openTypeViewQustnPopup(item.unityExamSeq, item.unityDtlExamSeq, item.unityDtlExamTurnOrdSeq, item.subjtSeq)"
                >
                  {{ item.coransrWransrCauseInpPosbleYn === 'Y' ? '완료 ' : '-' }}
                </button>
                <button
                    v-if="(item.enterAnswer === 'N' || item.enterWransr === 'N') && item.coransrWransrCauseInpPosbleYn === 'Y'"
                    type="button"
                    class="btn_wransr_note start"
                    :disabled="item.enterAnswer === 'N'"
                    @click="openTypeViewQustnPopup(item.unityExamSeq, item.unityDtlExamSeq, item.unityDtlExamTurnOrdSeq, item.subjtSeq)"
                >
                  미완료
                </button>
                {{ (item.enterAnswer === 'N' || item.enterWransr === 'N') && item.coransrWransrCauseInpPosbleYn === 'N' ? '-' : '' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-else-if="tableStyle === 'TypeHalf'">
      <div class="wrap_title">
        <h5 class="tit_cont">
          <span>{{ tableTitle }} 분석</span>
        </h5>
      </div>
      <div class="cont_graph_result">
        <div class="box_graph_cont" style="padding-top: 15px; padding-left: 65px; padding-right: 80px">
          <pie-chart :chart-data="chartData" :options="chartOptions" />
        </div>
      </div>
      <div class="cont_data_info pc_only">
        <table class="tbl_comm">
          <colgroup>
            <col style="width:40px" />
            <col style="width:auto" />
            <col style="width:100px" />
            <col style="width:100px" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">NO</th>
              <th scope="col">{{ tableTitle }}</th>
              <th scope="col">내 선택률</th>
              <th scope="col">평균 선택률</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(obj, idx) in causeData" :key="idx">
              <td>{{ idx + 1 }}</td>
              <td>{{ obj.description }}</td>
              <td :class="idx === causeHighlightMyRateIdx ? 'highlight' : ''">
                {{ obj.mySelectRate === 0 ? '-' : parseFloat(obj.mySelectRate).toFixed(1) + '%' }}
              </td>
              <td :class="idx === causeHighlightAllRateIdx ? 'highlight' : ''">
                {{ typeof obj.allSelectRate === 'number' ? parseFloat(obj.allSelectRate).toFixed(1) + '%' : '-' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PieChart from './PieChart';
import StringUtils from '@/utils/StringUtils';
import { USER_AUTHORITY } from '@/const/const';
export default {
  components: { PieChart },
  props: {
    tableTitle: {
      type: String,
      default: null
    },
    tableNotice: {
      type: [Number, String],
      default: null
    },
    tableStyle: {
      type: [Number, String],
      default: null
    },
    typeSize: {
      type: String,
      default: null
    },
    enterAnswerPossibleExamInfo: {},
    wransrnoteTableExamInfo: {},
    currentPage: {
      type: Number,
      default: 1
    },
    pagePerCnt: {
      type: Number,
      default: 10
    },
    univGroup: {
      type: String,
      default: ''
    },
    chartData: {
      default() {
        return {};
      }
    },
    chartOptions: { default: null },
    causeData: {
      type: Array,
      default() {
        return [];
      }
    },
    subjectName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isMobile: false,
      sortedPersonalScoreList: [],
      filteredQuestionInfoList: [],
      filteredAnswerByQuestionTypes: [],
      highlightQuestions: [],
      highlightQuestionTypes: [],
      causeHighlightMyRateIdx: -1,
      causeHighlightAllRateIdx: -1,
      StringUtils,
      USER_AUTHORITY
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.common.userInfo,
      selectUnityExamSeq: (state) => state.unityexam.enteranswer.selectUnityExamSeq,
      selectUnityDtlExamSeq: (state) => state.unityexam.enteranswer.selectUnityDtlExamSeq,
      selectUnityDtlExamTurnOrdSeq: (state) => state.unityexam.enteranswer.selectUnityDtlExamTurnOrdSeq,
      selectUnityExamHighSubjtSeq: (state) => state.unityexam.enteranswer.selectUnityExamHighSubjtSeq,
      currentSubjectMap: (state) => state.unityexam.wransrnote.currentSubjectMap,
      personalScoreMap: (state) => state.unityexam.wransrnote.personalScoreMap,
      questionInfoList: (state) => state.unityexam.wransrnote.questionInfoList,
      answerByQuestionTypes: (state) => state.unityexam.wransrnote.answerByQuestionTypes
    })
  },
  watch: {
    personalScoreMap() {
      const filterOrder = ['K', 'M', 'E', 'T', 'H', 'L', 'KM', 'MT', 'KMT'];
      const result = [];
      filterOrder.forEach((column) => {
        const checkSeqs = [];
        if (column.length === 1) {
          const subject = this.currentSubjectMap[column] || {};
          const highSubjtSeq = subject.byyySubjtSeq || 0;
          if (highSubjtSeq === 0) {
            return;
          }
          checkSeqs.push(highSubjtSeq);
          subject.child.forEach((childSubject) => {
            checkSeqs.push(childSubject.byyySubjtSeq);
          });
        } else {
          checkSeqs.push(column);
        }

        checkSeqs.forEach((byyySubjtSeq) => {
          if (Object.keys(this.personalScoreMap[byyySubjtSeq] || {}).length !== 0) {
            result.push(this.personalScoreMap[byyySubjtSeq]);
          }
        });
      });
      this.sortedPersonalScoreList = result;
    },
    questionInfoList() {
      this.filteredQuestionInfoList = this.questionInfoList.filter((el) => {
        return Number.isInteger(el.bystdntUnityExamQustnInpStusSeq);
      });

      const highLightCnt = Math.ceil(this.filteredQuestionInfoList.length / 10);
      const result = [];
      this.filteredQuestionInfoList.forEach((value) => {
        result.push(value);
        result.sort((o1, o2) => {
          const wransrrtArray1 = o1.wransrrt || [],
            wransrrt1 = wransrrtArray1[0] || {},
            rate1 = wransrrt1['rate'] || 0;
          const wransrrtArray2 = o2.wransrrt || [],
            wransrrt2 = wransrrtArray2[0] || {},
            rate2 = wransrrt2['rate'] || 0;
          return rate2 - rate1;
        });
        if (result.length > highLightCnt) {
          result.pop();
        }
      });
      this.highlightQuestions = [];
      result.forEach((obj) => {
        this.highlightQuestions.push(obj.bystdntUnityExamQustnInpStusSeq);
      });
    },
    answerByQuestionTypes: {
      deep: true,
      handler() {
        if (this.tableStyle === 'statsByQuestionTypeTable') {
          this.changeFilteredAnswerByQuestionTypes('answerByQuestionTypes');
        }
      }
    },
    currentPage: {
      immediate: true,
      handler() {
        if (this.tableStyle === 'statsByQuestionTypeTable') {
          this.changeFilteredAnswerByQuestionTypes('currentPage');
        }
      }
    },
    pagePerCnt: {
      immediate: true,
      handler() {
        if (this.tableStyle === 'statsByQuestionTypeTable') {
          this.changeFilteredAnswerByQuestionTypes('currentPage');
        }
      }
    },
    causeData: {
      deep: true,
      immediate: true,
      handler() {
        if (this.causeData.length === 0) {
          return;
        }
        this.causeHighlightMyRateIdx = -1;
        this.causeHighlightAllRateIdx = -1;
        for (let i = 0; i < this.causeData.length; i++) {
          const data = this.causeData[i];
          if (data.mySelectRate > 0) {
            if (
              this.causeHighlightMyRateIdx === -1 ||
              this.causeData[this.causeHighlightMyRateIdx].mySelectRate < data.mySelectRate
            ) {
              this.causeHighlightMyRateIdx = i;
            }
          }
          if ((data.allSelectRate || 0) > 0) {
            if (
              this.causeHighlightAllRateIdx === -1 ||
              this.causeData[this.causeHighlightAllRateIdx].allSelectRate < data.allSelectRate
            ) {
              this.causeHighlightAllRateIdx = i;
            }
          }
        }
      }
    }
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true });
    }
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize, { passive: true });
  },
  methods: {
    ...mapActions(['setSelectExam', 'setWransrnoteExam', 'getQuestionInfoByExamQustnSeqs']),
    getQuestionType(obj) {
      const questionType = [];
      if (StringUtils.isNotEmpty(obj.purbNm)) {
        questionType.push(obj.purbNm);
      }
      if (StringUtils.isNotEmpty(obj.luntNm)) {
        questionType.push(obj.luntNm);
      }
      if (StringUtils.isNotEmpty(obj.sbptNm)) {
        questionType.push(obj.sbptNm);
      }
      if (StringUtils.isNotEmpty(obj.smunitNm)) {
        questionType.push(obj.smunitNm);
      }
      return questionType.join(' > ');
    },
    getWransrRate(obj) {
      const wransrrtArray = obj.wransrrt || [],
        wransrrt = wransrrtArray[0] || {},
        rate = wransrrt['rate'];
      return typeof rate === 'number' ? parseFloat(rate).toFixed(1) + '%' : '-';
    },
    getCoransrDescription(obj) {
      const coransrModel = obj.coransrModel || {};
      if (coransrModel.description === '기타') {
        const reason = StringUtils.isNotEmpty((coransrModel || {}).etcReason) ? '(' + coransrModel.etcReason + ')' : '';
        return coransrModel.description + reason;
      } else {
        return coransrModel.description || '-';
      }
    },
    changeCoransrNoteYn(bystdntUnityExamQustnInpStusSeq) {
      this.$emit('change-coransr-note-yn', bystdntUnityExamQustnInpStusSeq);
    },
    changeFilteredAnswerByQuestionTypes(watchData) {
      this.filteredAnswerByQuestionTypes = [];
      const start = (this.currentPage - 1) * this.pagePerCnt;
      let end = this.currentPage * this.pagePerCnt - 1;
      if (end > this.answerByQuestionTypes.length - 1) {
        end = this.answerByQuestionTypes.length - 1;
      }
      for (let i = start; i <= end; i++) {
        this.filteredAnswerByQuestionTypes.push(this.answerByQuestionTypes[i]);
        this.answerByQuestionTypes[i].pageIdx = i;
      }

      if (watchData === 'answerByQuestionTypes') {
        const highLightCnt = Math.ceil(this.answerByQuestionTypes.length / 10);
        const result = [];
        for (let i = 0; i < this.answerByQuestionTypes.length; i++) {
          this.answerByQuestionTypes[i].pageIdx = i;
          const value = this.answerByQuestionTypes[i];
          result.push(value);
          result.sort((o1, o2) => {
            const rate1 = o1.allWrongAnswerRate || 0;
            const rate2 = o2.allWrongAnswerRate || 0;
            return rate2 - rate1;
          });
          if (result.length > highLightCnt) {
            result.pop();
          }
        }
        this.highlightQuestionTypes = [];
        result.forEach((obj) => {
          this.highlightQuestionTypes.push(obj.pageIdx);
        });
      }
    },
    onResize() {
      this.isMobile = window.innerWidth <= 768;
    },
    openPopup() {
      this.$emit('open-WindowPopup');
    },
    openQuestionViewPopup(bystdntUnityExamQustnInpStusSeq) {
      this.$emit('open-WindowPopup', bystdntUnityExamQustnInpStusSeq);
    },
    openVodPopup(explCurriVodPathwy, qustnInpMethodCd) {
      this.$emit('show-expl-vod', '/unity-exam/' + explCurriVodPathwy);
    },
    openTypeEnterAnswerPopup(unityExamSeq, unityDtlExamSeq, unityDtlExamTurnOrdSeq, subjtSeq) {
      this.setSelectExam({ unityExamSeq, unityDtlExamSeq, unityDtlExamTurnOrdSeq, subjtSeq });
      this.$emit('open-WindowPopup');
    },
    openWransnotePopup(unityExamSeq, unityDtlExamSeq, unityDtlExamTurnOrdSeq, subjtSeq) {
      console.log(subjtSeq);
      this.setWransrnoteExam({ unityExamSeq, unityDtlExamSeq, unityDtlExamTurnOrdSeq, subjtSeq });
      this.$emit('open-WindowPopup');
    },
    openPopupType() {
      this.$emit('open-WindowPopupType');
    },
    openTypeViewQustnPopup(unityExamSeq, unityDtlExamSeq, unityDtlExamTurnOrdSeq, subjtSeq) {
      this.setSelectExam({ unityExamSeq, unityDtlExamSeq, unityDtlExamTurnOrdSeq, subjtSeq });
      this.$emit('open-WindowPopupType');
    },
    openQustnByTypePopup(seqs) {
      this.$emit('open-WindowPopup', seqs);
    }
  }
};
</script>

<style></style>
