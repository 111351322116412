<template>
  <div class="box_table">
    <div class="expander">
      <span class="num_sum"
      ><em>{{ (groupList || []).length }}</em
        >건</span
      >
    </div>
    <table class="tbl_comm">
      <caption class="blind"></caption>
      <colgroup>
        <col style="width:50px;" />
        <col :style="{ width: isMobile ? 'auto' : '500px' }" />
        <col style="width:80px" />
        <col style="width:130px" />
      </colgroup>
      <thead>
        <tr>
          <th>No</th>
          <th>반명</th>
          <th>담임</th>
          <th>수강기간</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(studyGrpStatusData, idx) in groupList" :key="idx">
          <td>{{ idx + 1 }}</td>

          <td>
            <div style="width: 100%;text-align: left" v-html="studyGrpStatusData.groupName"></div>
          </td>
          <td>{{ studyGrpStatusData.teacherName }}</td>
          <td>
            {{ formatGroupDate(studyGrpStatusData.groupSdate) }} ~<br v-if="isMobile" />{{
              formatGroupDate(studyGrpStatusData.groupEdate)
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';

export default {
  name: 'GroupTable',
  props: ['groupList'],
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile
    })
  },
  methods: {
    formatGroupDate(value) {
      return moment(value).format('YYYY.MM.DD');
    }
  }
};
</script>
