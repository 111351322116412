import getters from './getters';
import actions from './actions';
import mutations from './mutations';
const state = {
  studyStatusDataAs: [],
  studyStatusDataTo: [],
  studyGrpStatusDataAs: [],
  studyGrpStatusDataTo: [],
  selectSubject: []
};

export default {
  state,
  getters,
  actions,
  mutations
};
