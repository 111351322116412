<template>
  <div id="unityexam" class="pay_content">
    <div class="mypage_comm wransr_note" :class="{ mobile: isMobile }">
      <h4 class="pc_only"><span>답안/정오답원인 입력</span></h4>
      <div class="tab_select mo_only">
        <ul class="list_select">
          <li v-for="(item, idx) in enterAnswerExamTabInfo" :key="idx" :class="{ on: activeTab === item.unityExamDivCd }">
            <a class="link_cont" @click="changeTable(item.unityExamDivCd)">{{ item.unityExamDivNm }}</a>
          </li>
        </ul>
      </div>
      <div class="select_exam_system" :class="{ type2: !isMobile }">
        <div class="inner">
          <div class="wrap_select_year">
            <select class="select_cont" v-model="examYear" @change="changeTab($event)">
              <option v-for="(item, idx) in enterAnswerYearInfo" :key="idx" :value="item.examYy"> {{ item.examYy }}년 </option>
              <option v-if="enterAnswerYearInfo === null" :value="examYear"> {{ examYear }}년 </option>
            </select>
          </div>
          <div class="wrap_select_exam mo_only">
            <select class="select_cont" v-model="examSeq" @change="changeMobileSelExam($event)">
              <option value="0" :selected="true">전체</option>
              <option v-for="(item, idx) in enterAnswerExamInfo" :key="idx" :value="item.unityExamSeq">{{ item.unityExamNm }}</option>
            </select>
          </div>
          <div class="wrap_select_exam mo_only">
            <select class="select_cont" v-model="dtlExamSeq" @change="changeMobileSelDtlExamTable($event)">
              <option value="0" :selected="true">전체</option>
              <option v-for="(item, idx) in enterAnswerDtlExamInfo" :key="idx" :value="item.unityDtlExamSeq">{{ item.unityDtlExamNm }}</option>
            </select>
          </div>
        </div>
      </div>
      <tab-menu @changeTable="changeTable" typeClass="tab_comm pc_only">
        <tab-pannel name="전체" :selected="true">
          <template #TabelGrid>
            <tab-table-box
              tableStyle="TypeEnterAnswer"
              @open-WindowPopup="openPopup('unityExamEnterAnswerPopup')"
              @open-WindowPopupType="openPopup('unityExamViewQustnPopup')"
              :enterAnswerPossibleExamInfo="enterAnswerPossibleExamInfo"
              :currentPage="currentPage"
              :pagePerCnt="pagePerCnt"
            />
            <pagination
              :total-pages="Math.ceil(enterAnswerPossibleExamInfo.length / pagePerCnt)"
              :total="enterAnswerPossibleExamInfo.length"
              :current-page="currentPage"
              @pagechanged="onPageChange"
              @change-page-per-cnt="pagePerCnt = pagePerCnt + 10"
              :maxVisibleButtons="Math.ceil(enterAnswerPossibleExamInfo.length / pagePerCnt) > 5 ? 5 : Math.ceil(enterAnswerPossibleExamInfo.length / pagePerCnt)"
            />
          </template>
        </tab-pannel>
        <tab-pannel v-for="(item, idx) in enterAnswerExamTabInfo" :key="idx" :unityExamDivCd="item.unityExamDivCd" :name="item.unityExamDivNm">
          <template #TabelGrid>
            <tab-table-box
              tableStyle="TypeEnterAnswer"
              @open-WindowPopup="openPopup('unityExamEnterAnswerPopup')"
              @open-WindowPopupType="openPopup('unityExamViewQustnPopup')"
              :enterAnswerPossibleExamInfo="enterAnswerPossibleExamInfo"
              :currentPage="currentPage"
              :pagePerCnt="pagePerCnt"
            />
            <pagination
              :total-pages="Math.ceil(enterAnswerPossibleExamInfo.length / pagePerCnt)"
              :total="enterAnswerPossibleExamInfo.length"
              :current-page="currentPage"
              @pagechanged="onPageChange"
              @change-page-per-cnt="pagePerCnt = pagePerCnt + 10"
              :maxVisibleButtons="Math.ceil(enterAnswerPossibleExamInfo.length / pagePerCnt) > 5 ? 5 : Math.ceil(enterAnswerPossibleExamInfo.length / pagePerCnt)"
            />
          </template>
        </tab-pannel>
      </tab-menu>
      <!-- 선택과목 설정 레이어 -->
      <Modal v-if="showModalOpeningLayer" disableHead="true" modalTitle="선택과목 설정" modalType="layer_setting" @close-modal="showModalOpeningLayer = false">
        <div slot="body">
          <div class="info_select_subject">
            <p class="txt_info">선택과목을 선택해 주세요.</p>
          </div>
          <div class="data_setting">
            <dl>
              <dt>
                {{ enterAnswerSelectExamInfo[0].subjtNm }}
              </dt>
              <dd>
                <div class="type_check">
                  <label
                    v-for="(item, idx) in enterAnswerSelectExamInfo"
                    :key="idx"
                    :for="'typeSubjectRadio' + idx"
                    class="lab_radio"
                    @click="dtlSubjtClick($event)"
                    v-show="selectPopName === 'unityExamEnterAnswerPopup' || (selectPopName === 'unityExamViewQustnPopup' && item.qustnInpCnt > 0)"
                  >
                    <input
                      :type="selectPopName === 'unityExamEnterAnswerPopup' && item.subTamYn === 'Y' ? 'checkbox' : 'radio'"
                      :id="'typeSubjectRadio' + idx"
                      class="inp_radio"
                      :value="item.unityExamSubjtSeq"
                      name="typeSubjectRadio"
                      :checked="selectPopName === 'unityExamEnterAnswerPopup' && item.subTamYn === 'Y' ? item.qustnInpCnt > 0 : checkSubject(idx)"
                    />
                    <span class="ico_radio">
                      <span class="mo_only">{{ item.dtlSubjtNm }}</span>
                    </span>
                    {{ item.dtlSubjtNm }}
                  </label>
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <div slot="foot">
          <button type="button" @click="subjtSave(enterAnswerSelectExamInfo[0].subjtNm)" class="btn_save">선택</button>
        </div>
      </Modal>
      <!-- //선택과목 설정 레이어 -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TabMenu from './common/TabMenu';
import TabPannel from './common/TabPannel';
import TabTableBox from './common/TabTableBox';
import Pagination from './common/Pagination';
import Modal from './common/Modal';
import { windowOpenPopup } from '@/mixins/windowOpenPopup';
import { PAGE_TITLE } from '../../const/const';

export default {
  name: 'unityExamEnterAnswer',
  mixins: [windowOpenPopup],
  components: {
    TabMenu,
    TabPannel,
    TabTableBox,
    Pagination,
    Modal
  },
  data() {
    return {
      popup: undefined,
      currentPage: 1,
      showModalOpeningLayer: false,
      examYear: new Date().getFullYear(),
      activeTab: '',
      examSeq: 0,
      dtlExamSeq: 0,
      dtlSubjtSeq01: 0,
      dtlSubjtSeq02: 0,
      selectPopName: '',
      pagePerCnt: 10
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      userInfo: (state) => state.common.userInfo,
      enterAnswerYearInfo: (state) => state.unityexam.enteranswer.enterAnswerYearInfo,
      enterAnswerExamTabInfo: (state) => state.unityexam.enteranswer.enterAnswerExamTabInfo,
      enterAnswerExamInfo: (state) => state.unityexam.enteranswer.enterAnswerExamInfo,
      enterAnswerDtlExamInfo: (state) => state.unityexam.enteranswer.enterAnswerDtlExamInfo,
      enterAnswerPossibleExamInfo: (state) => state.unityexam.enteranswer.enterAnswerPossibleExamInfo,
      selectUnityExamSeq: (state) => state.unityexam.enteranswer.selectUnityExamSeq,
      selectUnityDtlExamSeq: (state) => state.unityexam.enteranswer.selectUnityDtlExamSeq,
      selectUnityDtlExamTurnOrdSeq: (state) => state.unityexam.enteranswer.selectUnityDtlExamTurnOrdSeq,
      selectUnityExamHighSubjtSeq: (state) => state.unityexam.enteranswer.selectUnityExamHighSubjtSeq,
      enterAnswerSelectExamInfo: (state) => state.unityexam.enteranswer.enterAnswerSelectExamInfo
    })
  },
  created() {
    this.setHeaderInfo({ menuName: '답안/정오답원인 입력', isPrev: false });
    if (!this.userInfo.studentCd) {
      this.getUser().then(() => {
        this.getEnterAnswerYear();
        const params = {};
        params.examYy = this.examYear;
        this.getEnterAnswerExamTab(params).then(() => {
          if (this.isMobile) {
            params.unityExamDivCd = this.enterAnswerExamTabInfo[0].unityExamDivCd;
            this.activeTab = params.unityExamDivCd;
          }
          params.unityExamDivCd = this.activeTab;
          this.getEnterAnswerPossibleExam(params);

          this.getEnterAnswerExam(params);
          params.unityExamSeq = this.examSeq;
          this.getEnterAnswerDtlExam(params);
        });
      });
    } else {
      this.getEnterAnswerYear();
      const params = {};
      params.examYy = this.examYear;
      this.getEnterAnswerExamTab(params).then(() => {
        if (this.isMobile) {
          params.unityExamDivCd = this.enterAnswerExamTabInfo[0].unityExamDivCd;
          this.activeTab = params.unityExamDivCd;
        }
        params.unityExamDivCd = this.activeTab;
        this.getEnterAnswerPossibleExam(params);

        this.getEnterAnswerExam(params);
        params.unityExamSeq = this.examSeq;
        this.getEnterAnswerDtlExam(params);
      });
    }
    this.changeTitle();
  },
  methods: {
    ...mapActions(['getUser', 'getEnterAnswerYear', 'getEnterAnswerExamTab', 'getEnterAnswerPossibleExam', 'getEnterAnswerExam', 'getEnterAnswerDtlExam', 'getEnterAnswerSelectExam', 'setHeaderInfo']),
    changeTitle() {
      let title = '';
      if (this.userInfo.branchCd !== '') title = PAGE_TITLE[this.userInfo.branchCd];
      document.title = 'mypage_성적관리 - 답안/정오답원인 입력 | ' + title;
    },
    openPopup(PopName) {
      this.selectPopName = PopName;
      if (this.isMobile) {
        const params = {};
        params.examYy = this.examYear;
        params.unityExamSeq = this.selectUnityExamSeq;
        params.unityDtlExamSeq = this.selectUnityDtlExamSeq;
        params.unityDtlExamTurnOrdSeq = this.selectUnityDtlExamTurnOrdSeq;
        params.highSubjtSeq = this.selectUnityExamHighSubjtSeq;
        this.getEnterAnswerSelectExam(params).then(() => {
          let cnt = 0;
          for (const item of this.enterAnswerSelectExamInfo) {
            if (item.qustnInpCnt > 0) {
              cnt++;
            }
          }
          if (cnt > 1 || this.enterAnswerSelectExamInfo.length > 1) {
            this.showModalOpeningLayer = true;
          } else {
            this.dtlSubjtSeq01 = this.enterAnswerSelectExamInfo[0].unityExamSubjtSeq;
            this.goWindowPopup(PopName);
          }
        });
      } else {
        this.goWindowPopup(PopName);
      }
    },
    dtlSubjtClick(e) {
      const chkList = document.querySelectorAll('input[name="typeSubjectRadio"]:checked');
      if (this.selectPopName === 'unityExamEnterAnswerPopup' && this.enterAnswerSelectExamInfo[0].subTamYn === 'Y') {
        if (chkList.length > 2) {
          alert('탐구는 2개까지 선택 가능합니다.');
          e.target.checked = false;
        }
      }
      const existSubject = [];
      let chkCnt = 0;
      for (const item of this.enterAnswerSelectExamInfo) {
        if (item.qustnInpCnt > 0) {
          existSubject.push(item.unityExamSubjtSeq);
        }
      }
      for (const chk of chkList) {
        for (const seq of existSubject) {
          if (seq == chk.value) {
            chkCnt++;
          }
        }
      }
      if (this.selectPopName === 'unityExamViewQustnPopup' && existSubject.length < 2 && chkList.length > 1) {
        e.target.checked = false;
      }
      if (this.selectPopName === 'unityExamEnterAnswerPopup' && existSubject.length > 0 && chkCnt < existSubject.length) {
        alert('선택과목을 변경하면 답안이 초기화됩니다.');
      }
    },
    subjtSave(subjtNm) {
      var chkList = document.querySelectorAll('input[name="typeSubjectRadio"]:checked');

      if (chkList.length === 0) {
        alert(subjtNm + '선택과목을 선택해 주세요.');
        return;
      }

      if (this.selectPopName === 'unityExamEnterAnswerPopup' && this.enterAnswerSelectExamInfo[0].subTamYn === 'Y') {
        if (chkList.length === 1) {
          alert('탐구 선택과목을 하나 더 선택해 주세요.');
          return;
        }
        if (chkList.length === 0) {
          alert(subjtNm + '선택과목을 선택해 주세요.');
          return;
        }
      }

      if (chkList.length === 2) {
        this.dtlSubjtSeq01 = chkList[0].value;
        this.dtlSubjtSeq02 = chkList[1].value;
      } else {
        this.dtlSubjtSeq01 = chkList[0].value;
        this.dtlSubjtSeq02 = null;
      }

      this.showModalOpeningLayer = false;
      this.goWindowPopup(this.selectPopName);
    },
    goWindowPopup(PopName) {
      const route = this.$router.resolve({
        name: PopName,
        query: {
          examYy: this.examYear,
          unityExamSeq: this.selectUnityExamSeq,
          unityDtlExamSeq: this.selectUnityDtlExamSeq,
          unityDtlExamTurnOrdSeq: this.selectUnityDtlExamTurnOrdSeq,
          highSubjtSeq: this.selectUnityExamHighSubjtSeq,
          dtlSubjtSeq01: this.dtlSubjtSeq01,
          dtlSubjtSeq02: this.dtlSubjtSeq02
        }
      });
      this.openWindowPopup(route.href, this.$route.name, PopName, this.locationReload, 'width=990, height=800, scrollbars=1');
    },
    closePopup() {
      this.popup.close();
      window.opener.child.close();
    },
    locationReload() {
      location.reload();
    },
    onPageChange(page) {
      this.currentPage = page;
    },
    changeTab(e) {
      this.currentPage = 1;
      this.pagePerCnt = 10;
      const params = {};
      params.examYy = this.examYear;
      this.getEnterAnswerExam(params);
      this.changeTable(this.activeTab);
    },
    changeTable(divCd) {
      this.currentPage = 1;
      this.pagePerCnt = 10;
      this.activeTab = divCd;
      const params = {};
      this.examSeq = 0;
      this.dtlExamSeq = 0;
      params.examYy = this.examYear;
      params.unityExamDivCd = divCd;
      this.getEnterAnswerPossibleExam(params);
      if (this.isMobile) {
        this.getEnterAnswerExam(params);
      }
    },
    changeMobileSelExam(e) {
      const params = {};
      params.unityExamSeq = this.examSeq;
      this.getEnterAnswerDtlExam(params);
      this.dtlExamSeq = 0;
      params.examYy = this.examYear;
      params.unityDtlExamSeq = this.dtlExamSeq;
      this.getEnterAnswerPossibleExam(params);
    },
    changeMobileSelDtlExamTable(e) {
      const params = {};
      params.examYy = this.examYear;
      params.unityExamSeq = this.examSeq;
      params.unityDtlExamSeq = this.dtlExamSeq;
      this.getEnterAnswerPossibleExam(params);
    },
    checkSubject(index) {
      let temp = -1;
      for (let idx = 0; idx < this.enterAnswerSelectExamInfo.length; idx++) {
        if (this.enterAnswerSelectExamInfo[idx].qustnInpCnt > 0) {
          if (temp === -1) {
            temp = idx;
          }
        }
      }
      if (index === temp) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
