const BRANCH_NAME = {
  '0020': '이투스네오강남',
  '0060': '강남청솔기숙',
  '0021': '강남하이퍼',
  '0120': '강남하이퍼기숙',
  '0110': '강남하이퍼기숙의대관',
  '0030': '강북',
  '0032': '강북독서실',
  '0100': '노량진',
  '0010': '본사',
  '0040': '부천',
  '0050': '분당',
  '0052': '분당독서실',
  '0310': '강남하이퍼의대관',
  '0130': '앤써목동',
  '0070': '양평',
  '0410': '이투스247라이브',
  '0500': '이투스네오일산',
  '0080': '일산',
  '0031': '중계수학관',
  '0011': '본사-지점',
  '0090': '평촌',
  '0092': '평촌독서실',
  '0600': '이투스네오원격'
};

const BRANCH_SUBDOMAIN = {
  '0020': 'n1',
  '0060': 'gisuk',
  '0021': 'h1',
  '0120': 'h2',
  '0110': 'h3',
  '0310': 'h4',
  '0030': 'gangbuk',
  '0040': 'bucheon',
  '0050': 'bundang',
  '0130': 'a2',
  '0500': 'n2',
  '0080': 'ilsan',
  '0090': 'pyeongchon',
  '0600': 'meta',
  '0011': 'o2o'
};

const META_SUBDOMAIN = {
  cs: ['gangbuk', 'bundang', 'pyeongchon', 'ilsan', 'bucheon'],
  as: ['a2']
};

const ACADEMY_CLASS_NAME = {
  cheongsol: 'cs',
  hyper: 'hp',
  neoneo: 'neo',
  answer: 'answer',
  gisuk: 'cs'
};

const MYPAGE_HEADER_NAME = {
  cheongsol: 'my-page-cheongsol-header',
  hyper: 'my-page-hyper-header',
  neoneo: 'my-page-neoneo-header',
  answer: 'my-page-answer-header',
  gisuk: 'my-page-cheongsol-header'
};

const MYPAGE_FOOTER_NAME = {
  cheongsol: 'my-page-cheongsol-footer',
  hyper: 'my-page-hyper-footer',
  neoneo: 'my-page-neoneo-footer',
  answer: 'my-page-answer-footer',
  gisuk: 'my-page-cheongsol-footer'
};

const OLD_MY_PAGE_URL = {
  cheongsol: 'https://cheongsol-mypage.etoos.com/main.do',
  hyper: 'https://hyper-mypage.etoos.com/main.do',
  neoneo: 'https://neoneo-mypage.etoos.com/main.do',
  answer: 'https://answer-mypage.etoos.com/main.do',
  gisuk: 'https://gisuk-mypage.etoos.com/main.do'
};

const HOMEPAGE_DOMAIN = {
  cheongsol: 'https://cheongsol.etoos.com',
  hyper: 'https://hyperacademy.etoos.com',
  neoneo: 'https://neoneo.etoos.com',
  answer: 'https://a2-etoosanswer.etoos.com',
  gisuk: 'https://gisuk.etoos.com'
};

const BRANCH_EDU_COUNSEL_PHONE_NO = {
  '0020': '02)3288-9001',
  '0060': '031)843-9001',
  '0021': '02)573-9001',
  '0120': '031)768-9001',
  '0110': '031)874-9001',
  '0030': '02)934-9001',
  '0032': '02)934-9001',
  '0100': '노량진',
  '0010': '02)552-1230',
  '0040': '032)611-9001',
  '0050': '031)708-9001',
  '0052': '031)708-9001',
  '0310': '02)875-9001',
  '0130': '02)2646-2586',
  '0410': '이투스247라이브',
  '0500': '031)818-4545',
  '0080': '031)903-9001',
  '0031': '중계수학관',
  '0011': '02)552-1230',
  '0090': '031)383-9001',
  '0092': '031)383-9001'
};

const MY_GOOGLE_ANALYTICS_ID = {
  cheongsol: 'G-TKR57Z7BE3',
  hyper: 'G-JMPGRE4TXY',
  neoneo: 'G-TKR57Z7BE3',
  answer: 'G-JMPGRE4TXY',
  gisuk: 'G-TKR57Z7BE3'
};

const USER_AUTHORITY = {
  MANAGER: 'MANAGER',
  TEACHER: 'TEACHER',
  STUDENT: 'STUDENT',
  PARENT: 'PARENT'
};

const FAVICON = {
  '0060': '/pc/v2/images/favicon/favicon_cs.ico',
  '0021': '/pc/v2/images/favicon/favicon_hp.ico',
  '0120': '/pc/v2/images/favicon/favicon_hp.ico',
  '0110': '/pc/v2/images/favicon/favicon_hp.ico',
  '0310': '/pc/v2/images/favicon/favicon_hp.ico',
  '0030': '/pc/v2/images/favicon/favicon_cs.ico',
  '0032': '/pc/v2/images/favicon/favicon_cs.ico',
  '0100': '/pc/v2/images/favicon/favicon_cs.ico',
  '0010': '/pc/v2/images/favicon/favicon_cs.ico',
  '0040': '/pc/v2/images/favicon/favicon_cs.ico',
  '0050': '/pc/v2/images/favicon/favicon_cs.ico',
  '0052': '/pc/v2/images/favicon/favicon_cs.ico',
  '0070': '/pc/v2/images/favicon/favicon_cs.ico',
  '0410': '/pc/v2/images/favicon/favicon_cs.ico',
  '0080': '/pc/v2/images/favicon/favicon_cs.ico',
  '0031': '/pc/v2/images/favicon/favicon_cs.ico',
  '0011': '/pc/v2/images/favicon/favicon_cs.ico',
  '0090': '/pc/v2/images/favicon/favicon_cs.ico',
  '0092': '/pc/v2/images/favicon/favicon_cs.ico',
  '0130': '/pc/v2/images/favicon/favicon_hp.ico',
  '0020': '/pc/v2/images/favicon/favicon_neo.ico',
  '0500': '/pc/v2/images/favicon/favicon_cs.ico',
  '0600': '/pc/v2/images/favicon/favicon_neo.ico'
};

const PAGE_TITLE = {
  '0020': '이투스네오 강남',
  '0060': '이투스기숙학원',
  '0021': '강남하이퍼학원 본원',
  '0120': '강남하이퍼기숙학원',
  '0110': '강남하이퍼기숙학원 의대관',
  '0030': '강북청솔학원',
  '0032': '강북독서실',
  '0100': '노량진',
  '0010': '본사',
  '0040': '부천청솔학원',
  '0050': '분당청솔학원',
  '0052': '분당독서실',
  '0310': '강남하이퍼학원 의대관',
  '0130': '강남하이퍼학원 목동관',
  '0070': '양평',
  '0410': '이투스247라이브',
  '0500': '일산청솔학원 네오관',
  '0080': '일산청솔학원',
  '0031': '중계수학관',
  '0011': '테스트',
  '0090': '평촌청솔학원',
  '0092': '평촌독서실',
  '0600': '메타버스 학습관'
};

const API_CALL_RESULT = {
  SUCCESS: '00000'
};

const UNITY_SUBJCT_CD = {
  K: '0100',
  M: '0200',
  E: '0300',
  H: '0400',
  T: '0500',
  L: '0900'
};

module.exports = {
  BRANCH_NAME,
  ACADEMY_CLASS_NAME,
  MYPAGE_HEADER_NAME,
  MYPAGE_FOOTER_NAME,
  OLD_MY_PAGE_URL,
  BRANCH_EDU_COUNSEL_PHONE_NO,
  MY_GOOGLE_ANALYTICS_ID,
  USER_AUTHORITY,
  FAVICON,
  PAGE_TITLE,
  BRANCH_SUBDOMAIN,
  API_CALL_RESULT,
  UNITY_SUBJCT_CD,
  HOMEPAGE_DOMAIN,
  META_SUBDOMAIN
};
