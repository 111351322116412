<template>
  <div class="tab_cont">
    <!--전체강좌-->
    <div id="tab1" class="panel_tab on" :class="{ ing: showSelect }">
      <div class="box_filter">
        <div class="list_selected">
          <template v-if="selectedFilterList.lectureTypeCds.length > 0">
            <em class="txt_item" v-for="lectureTypeCd in selectedFilterList.lectureTypeCds">{{ lectureTypeCd.nm }}</em>
          </template>
          <template v-else>
            <em class="txt_item">전체</em>
          </template>
          <template v-if="selectedFilterList.subjects.length > 0">
            <em class="txt_item" v-for="subject in selectedFilterList.subjects">{{ subject.subjtNm }}</em>
          </template>
          <template v-else>
            <em class="txt_item">전체</em>
          </template>
        </div>
        <button type="button" class="btn_filter" @click="$emit('openPopup', '#filterLayer')"><span class="blind">필터 열기</span></button>
      </div>
      <div class="box_subjectlist">
        <div class="expander">
          <em class="info_sum">
            총 <span class="num">{{ lectureInfo.totalCnt }}</span
            >건
          </em>
        </div>
        <div v-if="isLoading" class="loading_bar">
          <div class="loader"></div>
        </div>
        <div v-else-if="lectureInfo.totalCnt <= 0" class="no_data" :class="{ ask: showSelect }">
          <i class="ico_pass" :class="{ ico_nodata: !showSelect, ico_askbox: showSelect }"></i>
          <div class="tit" v-if="!showSelect">{{ title }}가 없습니다!</div>
          <div class="tit" v-else>{{ userInfo.userName }}님, 강의 안 들으세요?</div>
        </div>
        <div class="wrap_list" v-else>
          <ul class="list_subject">
            <li v-for="(item, idx) in lectureInfo.items" :key="idx" :class="{ disable: rowDisable(item) }">
              <div class="box_item" @click="!showSelect ? $emit('openPopup', '#vodListLayer', item) : null">
                <div class="tit_teacher">
                  <label class="lbl_item" v-if="showSelect">
                    <input type="checkbox" class="inp_check" :id="idx" :value="item" v-model="selectedItems" />
                    <i class="ico_comm ico_check"></i>
                  </label>
                  <em class="ico_class" :class="[getLectureType(item)]">{{ getLectureName(item) }}</em>
                  <em v-if="getLectureName(item) !== '수학전문관'" class="ico_notify" :class="[icoNotifyType[item.unitySubjtCd]]">
                    {{ item.subjtNm }}
                  </em>
                  <span class="info_subject">
                    <em class="txt_info">{{ item.teacherName }}</em>
                    <em class="txt_info" v-if="!showSelect">{{ item.groupRoom }}</em>
                  </span>
                  <span class="info_class" v-if="!showSelect">
                    <em class="txt_info">{{ getSchedule(item.schedules, ', ') }}</em>
                  </span>
                  <!-- [D] 시작 : tool_group  -->
                  <div class="tool_group" :id="`tooltip_${item.curriVodNo}`" @click="tooltipEvent" v-if="item.userNotiCont">
                    <i class="ico_pass ico_notice"></i>
                    <div class="noti_pop" v-html="item.userNotiCont"></div>
                  </div>
                  <!-- [D] 끝 : tooltip -->
                </div>
                <div class="tit_subject" v-html="item.groupName"></div>
                <div class="wrap_status" v-if="showSelect">
                  <div class="status">
                    <div class="num">
                      <span class="txt">{{ item.turnOrd }}회차</span>
                      <!-- <span class="txt">{{ item.prgRt }}</span> ACAPJ-3319 2023-04-11 진도율 숨김 -->
                    </div>
                    <div class="time_info">
                      <span class="txt">복습시간 : {{ item.playTmmm }}</span>
                      <span class="txt">최종 학습일 : {{ item.lastStdDt }}</span>
                    </div>
                    <!-- <div class="chart"> ACAPJ-3319 2023-04-11 진도율 숨김 -->
                    <!--   <div class="chart_bar" :style="{ width: item.prgRt }"></div> -->
                    <!-- </div> -->
                  </div>
                  <a class="btn_vod" @click="onViewClick(item.mediaContsKey, item.curriVodNo, item.viewYn)"><i class="ico_pass ico_vod"></i></a>
                </div>
              </div>
            </li>
          </ul>
          <button
            type="button"
            class="btn_moreview"
            v-if="lectureInfo.totalCnt > 10 && lectureInfo.totalCnt > this.numPerPage"
            @click="$emit('setNumPerPage', nextPage())"
          >
            <span class="inner">더보기</span>
          </button>
          <button v-if="showSelect" type="button" class="btn_delete" @click="openDeletePopup">
            <span class="inner">삭제</span>
          </button>
          <confirm-popup v-model="deleteConfirmPopup" :text="`${this.selectedItems ? this.selectedItems.length : 0}개의 강의를 삭제하시겠습니까?`" @confirm="$emit('confirmDelete')" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ConfirmPopup from './ConfirmPopup.vue';
import InfinitePassApi from '@/api/InfinitePassApi';
import { mapState } from 'vuex';
import { getSchedule } from '../../../utils/util';

export default {
  name: 'infinite-pass-table-m',
  components: { ConfirmPopup },
  props: {
    /**
     * 선택 아이템 List
     **/
    value: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: '강좌'
    },
    numPerPage: {
      type: Number,
      default: 10
    },
    lectureInfo: {
      type: Object,
      default: () => ({
        currPage: 1,
        totalCnt: 0,
        items: []
      })
    },
    /**
     * 삭제시 row item을 파라미터로 받고 true, false를 반환해주는 function를 넣어주면 됨.
     * type: Function
     * default: false, disable 비활성
     */
    rowDisable: {
      type: Function,
      // eslint-disable-next-line no-unused-vars
      default: (item) => false
    },
    /**
     * 데이터들 1열에 select box 노출 여부
     * default: false
     */
    showSelect: {
      type: Boolean,
      default: false
    },
    selectedFilterList: {
      type: Object
    },
    isLoading: {
      typr: Boolean,
      default: true
    }
  },
  data: () => ({
    icoNotifyType: {
      '0100': 'type1',
      '0200': 'type2',
      '0300': 'type3',
      '0400': 'type4',
      '0500': 'type5',
      '0900': 'type6',
      '1000': 'type7'
    },
    currPage: 1,
    deleteConfirmPopup: false
  }),
  computed: {
    ...mapState({
      userInfo: (state) => state.common.userInfo
    }),
    selectedItems: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  watch: {
    selectedItems(val) {
      this.selectAll = val?.length === this.lectureInfo.items?.length;
    }
  },
  methods: {
    getSchedule,
    nextPage() {
      return (this.currPage += 1);
    },
    openDeletePopup() {
      if (this.selectedItems?.length) {
        this.deleteConfirmPopup = true;
      } else {
        alert(`선택된 강의가 없습니다!`);
      }
    },
    tooltipEvent(e) {
      const classList = e.target.offsetParent.classList;
      if (classList.contains('on')) {
        classList.remove('on');
      } else {
        classList.add('on');
      }
    },
    onViewClick(mediaContsKey, curriVodNo, viewYn) {
      InfinitePassApi.playCurri(mediaContsKey, curriVodNo, viewYn);
    },
    getLectureType({ lectureTypeCd, clsMiddle }) {
      switch (lectureTypeCd) {
        case '1':
          return 'type1';
        case '3':
          return 'type4';
        case '2':
          if (clsMiddle === '수학전문관') return 'type3';
          return 'type2';
        default:
          return 'type1';
      }
    },
    getLectureName({ lectureTypeCd, clsMiddle }) {
      switch (lectureTypeCd) {
        case '1':
          return '정규수업';
        case '3':
          return '특강';
        case '2':
          if (clsMiddle === '수학전문관') return '수학전문관';
          return '단과';
        default:
          return '정규수업';
      }
    }
  }
};
</script>
