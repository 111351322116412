<template>
  <div class="pay_content pass">
    <h4 class="mgb50">
      <span>{{ userInfo.userName }}</span> 님 강의실
    </h4>

    <h5>나의 이용현황<i class="ico_question" @click="LayerPopupOpen()"></i></h5>
    <div class="my_count">
      <div class="count_cont">
        <span class="count_tit">지금까지<br>총 수강시간</span>
        <span class="total_wrap">
          <i class="ico_run"></i>
          <span class="total">
            <strong>{{ totalHours }}</strong>시간 {{ totalMinutes }}분
          </span>
        </span>
      </div>
      <div class="count_cont">
        <span class="count_tit">Today Update<em class="date">{{ today }}</em></span>
        <router-link to="/infinite-pass/today" class="num" :class="{ new: updateCnt ? updateCnt > 0 : false }">
          {{ updateCnt }}
        </router-link>
      </div>
      <div class="count_cont">
        <span class="count_tit">관심 강좌</span>
        <router-link to="/infinite-pass/interested" class="num">
          {{ intrstLctCnt }}
        </router-link>
      </div>
      <div class="count_cont">
        <span class="count_tit">수강 중인 강의</span>
        <router-link to="/infinite-pass/ongoing" class="num">
          {{ inClassCnt }}
        </router-link>
      </div>
    </div>

    <h5 class="mgt50">
      최근 수강 중인 강의 <em class="small"><router-link to="/infinite-pass/ongoing">+ 전체보기</router-link></em>
    </h5>

    <div class="tbl_data my">
      <table>
        <caption>
          최근 수강 중인 강의
        </caption>
        <colgroup>
          <col />
          <col style="width:17%" />
        </colgroup>
        <tbody>
          <template v-if="curriRecentInClass === null">
            <tr>
              <td colspan="6">
                <div class="loading_bar">
                  <div class="loader"></div>
                </div>
              </td>
            </tr>
          </template>
          <template v-else-if="curriRecentInClass.length">
            <tr v-for="(item, idx) in curriRecentInClass" :key="idx">
              <td>
                <badge-box
                  :unity-subjt-cd="item.unitySubjtCd"
                  :subjt-nm="item.subjtNm"
                  :lecture-type-cd="item.lectureTypeCd"
                  :cls-middle="item.clsMiddle"
                />
                <template v-if="item.groupName.length > 40">
                  <span class="my_tit">
                    <span v-html="item.groupName"></span>
                    <span>{{ item.turnOrd }}회차</span>
                  </span>
                </template>
                <template v-else>
                  <span class="my_tit" v-html="item.groupName + '-' + item.turnOrd + '회차<em>(' + item.createDt + ')</em>'"> </span>
                </template>
                <span class="my_date" v-if="item.groupName.length > 40">({{ item.createDt }})</span>
                <span class="small">
                  <span class="time">복습시간 : {{ item.playTmmm }}</span>
                  |
                  <span class="lastdate">최종 학습일 : {{ item.lastStdDt }}</span>
                </span>
              </td>
              <td>
                <a href="javascript:void(0);" @click="onViewClick(item.mediaContsKey, item.curriVodNo, item.viewYn)" :class="{ vod_btn: true, disable: item.viewYn === 'N' }">수강하기</a>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="6">
                <div class="nolecture">
                  <i class="ico_nolecture_my"></i>
                  <span>{{ userInfo.userName }} 님, 강의 안 들으세요?</span>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div class="ly_pop intro_pop" :class="{ on: isActiveIntroPop }">
      <a class="close" @click="LayerPopupClose">close</a>
      <div class="my_count">
        <div class="count_cont">
          <span class="total_wrap">
            <span class="total"> <strong>00</strong>시간 25분 </span>
          </span>
        </div>
        <div class="count_cont">
          <a href="#" class="num">7</a>
        </div>
        <div class="count_cont">
          <a href="#" class="num">15</a>
        </div>
        <div class="count_cont">
          <a href="#" class="num">29</a>
        </div>
      </div>
      <!-- <img src="http://acaimg.etoos.com/my/pc/v2/images/mypage/intropop_txt.png" alt="팝업텍스트"> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DateUtil from '@/utils/DateUtil';
import InfinitePassApi from '@/api/InfinitePassApi';
import BadgeBox from './components/BadgeBox.vue';
import { PAGE_TITLE } from '../../const/const';

export default {
  name: 'InfinitePassMain',
  components: {
    BadgeBox
  },
  data() {
    return {
      isActiveIntroPop: false,
      totalPlayTime: 0,
      updateCnt: 0,
      intrstLctCnt: 0,
      inClassCnt: 0,
      classCompleteCnt: 0,
      curriRecentInClass: null
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      userInfo: (state) => state.common.userInfo,
      myClassInfoData: (state) => state.infinitePass.myClassInfoData
    }),
    today: {
      get() {
        const date = new Date();
        date.setDate(date.getDate() - 1);
        return DateUtil.dateFormatter('{yyyy}.{mm}.{dd}', date);
      }
    },
    totalHours: {
      get() {
        return `${Math.floor(this.totalPlayTime / (60 * 60))}`.padStart(2, '0');
      }
    },
    totalMinutes: {
      get() {
        const seconds = this.totalPlayTime % 60;
        return `${Math.floor((this.totalPlayTime % (60 * 60)) / 60) + (seconds > 30 ? 1 : 0)}`.padStart(2, '0');
      }
    }
  },
  created() {
    this.setHeaderInfo({ menuName: '나의 강의실', isPrev: false });

    if (!this.userInfo.studentCd) {
      this.getUser().then(() => {
        this.getMyClassInfoData();
      });
    } else {
      this.getMyClassInfoData();
    }
    this.changeTitle();
  },
  methods: {
    ...mapActions(['getUser', 'setHeaderInfo', 'getMyClassInfo']),
    changeTitle() {
      let title = '';
      if (this.userInfo.branchCd !== '') title = PAGE_TITLE[this.userInfo.branchCd];
      document.title = 'mypage_현장강의 무한패스 - 나의 강의실 | ' + title;
    },
    getMyClassInfoData() {
      this.getMyClassInfo().then(() => {
        this.totalPlayTime = this.myClassInfoData.data.totalPlayTmmm;
        this.updateCnt = this.myClassInfoData.data.updateCnt;
        this.intrstLctCnt = this.myClassInfoData.data.intrstLctCnt;
        this.inClassCnt = this.myClassInfoData.data.inClassCnt;
        this.classCompleteCnt = this.myClassInfoData.data.classCompleteCnt;
        this.curriRecentInClass = this.myClassInfoData.data.curriRecentInClass;
      });
    },
    LayerPopupOpen() {
      this.isActiveIntroPop = true;
    },
    LayerPopupClose() {
      this.isActiveIntroPop = false;
    },
    onViewClick(mediaContsKey, curriVodNo, viewYn) {
      InfinitePassApi.playCurri(mediaContsKey, curriVodNo, viewYn);
    }
  }
};
</script>

<style scoped></style>
