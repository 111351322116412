<template>
  <div>
    <div class="mg_cont">
      <h5 class="tit_section">반 수강내역</h5>
      <group-table :group-list="studyGrpStatusDataAs" />
      <h5 class="tit_section">
        강좌 수강내역<button class="btn_filter" @click="filterLayers('show')">
          <span class="blind">검색필터 열기</span>
        </button>
      </h5>
      <div class="pc_only">
        <div class="box_filter" style="text-align: left">
          <div class="wrap_cate">
            <ul class="list_item">
              <li>
                <input
                  type="checkbox"
                  class="inp_check"
                  id="lec1"
                  value=""
                  v-model="lectureType"
                  @change="chkChange($event)"
                />
                <label for="lec1" class="lbl_item"><span class="inner">전체</span></label>
              </li>
              <li>
                <input
                  type="checkbox"
                  class="inp_check"
                  id="lec2"
                  value="1"
                  v-model="lectureType"
                  @change="chkChange($event)"
                />
                <label for="lec2" class="lbl_item">
                  <span class="inner">{{ this.lectureTypeMap['1'] }}</span>
                </label>
              </li>
              <li>
                <input
                  type="checkbox"
                  class="inp_check"
                  id="lec3"
                  value="2"
                  v-model="lectureType"
                  @change="chkChange($event)"
                />
                <label for="lec3" class="lbl_item">
                  <span class="inner">{{ this.lectureTypeMap['2'] }}</span>
                </label>
              </li>
              <li>
                <input
                  type="checkbox"
                  class="inp_check"
                  id="lec4"
                  value="3"
                  v-model="lectureType"
                  @change="chkChange($event)"
                />
                <label for="lec4" class="lbl_item">
                  <span class="inner">{{ this.lectureTypeMap['3'] }}</span>
                </label>
              </li>
            </ul>
          </div>
          <div class="wrap_cate">
            <ul class="list_item">
              <li v-for="(sub, idx) in getSubjects()" :key="idx">
                <span v-if="sub.subjtName !== ''">
                  <input
                    type="checkbox"
                    class="inp_check"
                    :id="'sub'"
                    :value="sub.subjtCd"
                    v-model="subject"
                    @change="chkChange($event)"
                  />
                  <label class="lbl_item" :for="'sub'">
                    <span class="inner">{{ sub.subjtName }}</span>
                  </label>
                </span>
              </li>
            </ul>
          </div>
          <div class="wrap_cate">
            <div>
              <div class="wrap_input">
                <b-datepicker v-model="dates" size="is-small" range :date-formatter="defaultDateFormatter" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <lecture-table
        :lecture-list="lectureList"
        :page-size="lectureTablePageSize"
        :page-num="lectureTablePageNum"
        :is-curr="false"
      />
      <div class="pc_only">
        <classroom-table-page
          :page-list="lecturePageList"
          :total-size="(lectureList || []).length"
          :page-num="lectureTablePageNum"
          :page-size="lectureTablePageSize"
          @set-page="setPage"
          @set-page-list="setPageList"
        />
      </div>
    </div>
    <!-- 필터2 -->
    <div id="filterLayer2" class="layer_sidepage filter" :class="{ open: filterView === true }">
      <div class="layer_body">
        <div class="layer_head">
          <h4 class="tit_layer">필터</h4>
          <div class="expander">
            <button type="button" class="btn_refresh" @click="filterRefresh()">
              <span class="blind">새로고침</span>
            </button>
          </div>
          <a href="javascript:;" class="layer_close" @click="filterLayers('hide')">
            <span class="blind">레이어 닫기</span>
          </a>
        </div>
        <div class="layer_cont">
          <!--  강좌유형 -->
          <lecture-component @lecture="getChildLecture" :lectureDt="lectureDt" :lecture-type-map="lectureTypeMap" />

          <!-- 과목 -->
          <subject-component :subjectData="getSubjects()" @subject="getChildSubject" :subjectDt="subjectDt" />

          <div class="item_filter on">
            <button type="button" class="btn_toggle">
              <strong class="tit_cate">수강기간</strong>
              <em class="selected">{{ coursesSdate }} ~ {{ coursesEdate }}</em>
            </button>
            <div class="panel">
              <div class="datepicker_div">
                <div class="wrap_input">
                  <b-datepicker v-model="fromDate" size="is-small" range />
                </div>
                <div class="wrap_input">
                  <b-datepicker v-model="toDate" size="is-small" range />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dimmed_layer" @click="filterLayers('hide')"></div>
    </div>
    <!-- //필터2 -->
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { Plugins } from 'slickgrid-es6';
import moment from 'moment';
import Uimypage from '@/utils/ui_mypage';
import LectureComponent from './LectureComponent';
import SubjectComponent from './SubjectComponent';
import GroupTable from './GroupTable';
import LectureTable from './LectureTable';
import ClassroomTablePage from './ClassroomTablePage';

export default {
  data() {
    return {
      filterView: false,
      columnOptions1: {},
      selectionModel: new Plugins.RowSelectionModel({ selectActiveRow: false }),
      lectureType: [''],
      subject: [''],
      dates: [],
      fromDate: [],
      toDate: [],
      coursesSdate: '',
      coursesEdate: '',
      datePerd: 365, // 조회 날짜 기간
      groupOpe: '003', //강좌상태 : 종강
      lectureDt: [''], // 모바일 강좌유형 초기화용
      subjectDt: [''], // 모바일 과목 초기화용
      subjectList: [], //과목 내역
      lectureTablePageNum: 1,
      lecturePageList: [1]
    };
  },
  props: ['subjects'],
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      userInfo: (state) => state.common.userInfo,
      studyGrpStatusDataAs: (state) => state.classroom.study.studyGrpStatusDataAs,
      studyStatusDataAs: (state) => state.classroom.study.studyStatusDataAs,
      lectureTypeMap: (state) => state.classroom.lecture.lectureTypeMap
    }),
    lectureList() {
      const studyData = this.setStudyDataFilter(this.studyStatusDataAs);

      const filData = [];
      _.chain(studyData)
        .sortBy('groupSdate')
        .sortBy('subjectCd')
        .each(function(item, idx) {
          //번호를 재설정
          item.no = idx + 1;
          filData.push(item);
        })
        .value();

      return filData;
    },
    lectureTablePageSize() {
      return this.isMobile ? 500 : 20;
    }
  },
  watch: {
    lectureType() {
      if (this.lectureType.length === 0) this.lectureType = [''];
    },
    subject() {
      if (this.subject.length === 0) this.subject = [''];
    },
    fromDate(arg1, arg2) {
      this.coursesSdate = moment(arg1).format('YYYY.MM.DD');
      if (arg1.toString() !== '' && arg2.toString() !== '') this.getStudyData();
    },
    toDate(arg1, arg2) {
      this.coursesEdate = moment(arg1).format('YYYY.MM.DD');
      if (arg1.toString() !== '' && arg2.toString() !== '') this.getStudyData();
    },
    dates(arg1) {
      this.coursesSdate = moment(arg1[0]).format('YYYY.MM.DD');
      this.coursesEdate = moment(arg1[1]).format('YYYY.MM.DD');
      this.getStudyData();
    }
  },
  components: {
    ClassroomTablePage,
    LectureTable,
    GroupTable,
    LectureComponent,
    SubjectComponent
  },
  created() {
    const fromDt = new Date();
    fromDt.setDate(fromDt.getDate() - this.datePerd);
    this.fromDate = fromDt;
    this.dates.push(fromDt);
    const toDt = new Date();
    this.toDate = toDt;
    this.dates.push(toDt);

    if (!this.userInfo.studentCd) {
      this.getUser().then(() => {
        this.init();
      });
    } else {
      this.init();
    }
  },
  methods: {
    ...mapActions(['getStudyGrpStatusAs', 'getStudyStatusAs', 'getUser', 'getLectureType']),
    init() {
      this.getLectureType().then(() => {
        this.$forceUpdate();
      });
      this.getStudyGrpData();
      this.getStudyData();
    },
    getChildLecture(e) {
      this.lectureType = e;
    },
    getChildSubject(e) {
      this.subject = e;
    },
    defaultDateFormatter(date, vm) {
      const targetDates = Array.isArray(date) ? date : [date];
      const dates = targetDates.map((date) => {
        const d = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12);
        const time = {
          date: ' ' + ('' + d.getDate()).slice(-2),
          month: ' ' + ('' + (d.getMonth() + 1)).slice(-2),
          year: d.getFullYear()
        };
        return `${time.year}.${time.month}.${time.date}`;
      });
      return dates.join(' - ');
    },
    filterLayers(view) {
      if (view === 'show') {
        this.filterView = true;
      } else {
        this.filterView = false;
      }
    },
    //강좌수강내역 필터링
    setStudyDataFilter(statusData) {
      const filterData = [];
      const lec = [];
      const sub = [];

      this.lectureType.forEach((item) => {
        if (item !== '') lec.push(item);
      });
      this.subject.forEach((item) => {
        if (item !== '') sub.push(item);
      });

      if (lec.length > 0) {
        //강좌유형
        lec.forEach((item1) => {
          if (sub.length === 0) {
            filterData.push(...statusData.filter((d) => d.lectureTypeCd === item1));
          }
          sub.forEach((item2) => {
            filterData.push(...statusData.filter((d) => d.lectureTypeCd === item1 && d.subjectCd === item2));
          });
        });
      } else if (sub.length > 0) {
        //과목
        sub.forEach((item2) => {
          filterData.push(...statusData.filter((d) => d.subjectCd === item2));
        });
      } else {
        //전체
        filterData.push(...statusData);
      }

      return filterData;
    },
    //반수강내역 데이터 요청
    getStudyGrpData() {
      const params = {};
      params.branchCd = this.userInfo.branchCd;
      params.studentCd = this.userInfo.studentCd;
      params.groupTypeCd = '1'; //강좌
      params.groupOpe = this.groupOpe; // 강좌상태 -> 수강: 001, 종강: 003
      params.subjtCds = '';
      params.coursesSdate = moment().format('YYYYMMDD');
      params.coursesEdate = moment().format('YYYYMMDD');

      this.getStudyGrpStatusAs(params);
    },
    //강좌수강내역 데이터 요청
    getStudyData() {
      const params = {};
      params.branchCd = this.userInfo.branchCd;
      params.studentCd = this.userInfo.studentCd;
      params.groupTypeCd = '2'; //강좌
      params.groupOpe = this.groupOpe; // 강좌상태 -> 수강: 001, 종강: 003

      if (this.coursesSdate !== '') {
        params.coursesSdate = this.coursesSdate.replace(/\./gi, '');
      } else {
        params.coursesSdate = moment()
          .subtract(this.datePerd, 'd')
          .format('YYYYMMDD');
      }
      if (this.coursesEdate !== '') {
        params.coursesEdate = this.coursesEdate.replace(/\./gi, '');
      } else {
        params.coursesEdate = moment().format('YYYYMMDD');
      }

      this.getStudyStatusAs(params).finally(() => {
        this.subjectList = this.getStudySubjectList();
      });
    },
    //검색항목 이벤트
    chkChange(e) {
      if (e.target.value !== '') {
        if (e.target.id.indexOf('lec') >= 0) {
          const index = this.lectureType.indexOf('');
          if (index > -1) {
            this.lectureType.splice(index, 1);
          }
        } else {
          const index = this.subject.indexOf('');
          if (index > -1) {
            this.subject.splice(index, 1);
          }
        }
      } else {
        if (e.target.id.indexOf('lec') >= 0) {
          this.lectureType = [''];
        } else {
          this.subject = [''];
        }
      }
    },
    //전체 과목정보
    getStudySubjectList() {
      const resultData = this.studyStatusDataAs;
      resultData.sort((a, b) => (a['subjectCd'] < b['subjectCd'] ? -1 : 0));

      return resultData;
    },
    //과목정보 추출
    getSubjects() {
      const data = this.subjectList;
      const arrDt = [];
      let arr = { subjtCd: '', subjtName: '전체' };
      arrDt.push(arr);
      data.forEach((item) => {
        arr = {};
        arr.subjtCd = item.subjectCd;
        arr.subjtName = item.subjectName;
        arrDt.push(arr);
      });
      const uniqueArray = arrDt.filter((thing, index) => {
        return (
          index ===
          arrDt.findIndex((obj) => {
            return JSON.stringify(obj) === JSON.stringify(thing);
          })
        );
      });

      return uniqueArray.sort((a, b) => (a['subjtCd'] < b['subjtCd'] ? -1 : 0));
    },
    //검색항목 초기화
    filterRefresh() {
      this.lectureType = [''];
      this.lectureDt = [''];
      this.subject = [''];
      this.subjectDt = [''];
      const fromDt = new Date();
      fromDt.setDate(fromDt.getDate() - this.datePerd);
      this.fromDate = fromDt;
      this.toDate = new Date();
      this.coursesSdate = '';
      this.coursesEdate = '';

      this.getStudyData();
    },
    setPage(page) {
      this.lectureTablePageNum = page;
    },
    setPageList(list) {
      this.lecturePageList = list;
    }
  },
  mounted() {
    Uimypage.mypageUI.init();
  },
  destroyed() {}
};
</script>
