import { mapActions, mapState } from 'vuex';
import { getBrandType } from '@/utils/util';
import { ETOOS_MEMBER_EDIT_URL, CLIENT_ID, ETOOS_MEMBER_LOGOUT_URL, HOMEPAGE_URL } from '@build/build.config';

const APPLICATION_PHASE = process.env.APPLICATION_PHASE || 'LOCAL';
const ALL_MENU = 'allMenu';
const ONLY_MEMBER = 'onlyMember';
const EDITMY = 'editMy';
const LOGOUTMY = 'logoutMy';
const domain = getBrandType(location.href);
const clientId = CLIENT_ID[domain];
const editUrl = ETOOS_MEMBER_EDIT_URL;
const logoutUrl = ETOOS_MEMBER_LOGOUT_URL;

function clickMobileTabMenu(type) {
  if (type === ALL_MENU) {
    document.getElementsByClassName('menulist1')[0].classList.add('on');
    document.getElementsByClassName('menulist2')[0].classList.remove('on');
    document
      .getElementById(ALL_MENU)
      .closest('li')
      .classList.add('on');
    document
      .getElementById(ONLY_MEMBER)
      .closest('li')
      .classList.remove('on');
  } else if (type === ONLY_MEMBER) {
    document.getElementsByClassName('menulist1')[0].classList.remove('on');
    document.getElementsByClassName('menulist2')[0].classList.add('on');
    document
      .getElementById(ALL_MENU)
      .closest('li')
      .classList.remove('on');
    document
      .getElementById(ONLY_MEMBER)
      .closest('li')
      .classList.add('on');
  }
}

function getCookieValue(cookieName) {
  const cookies = document.cookie.split('; ');
  for (let cookie of cookies) {
    const [name, value] = cookie.split('=');
    if (name === cookieName) {
      return decodeURIComponent(value); // URL 디코딩
    }
  }
  return null; // 쿠키가 없을 경우 null 반환
}

function clickEditMy() {
  const csAt = getCookieValue('cs_at');
  location.href = editUrl + '?clientId=' + clientId + '&accessToken=' + csAt + '&callbackUrl=' + window.location.href;
}

function clickLogoutMy(hompageUrl) {
  if (hompageUrl === 'meta') {
    if (APPLICATION_PHASE === 'QA') {
      if (domain === 'cheongsol') {
        location.href = logoutUrl + '?callbackUrl=https://qa-cheongsol.etoos.com/logout.do';
      } else if (domain === 'answer') {
        location.href = logoutUrl + '?callbackUrl=https://qa-a2-etoosanswer.etoos.com/logout.do';
      } else {
        location.href = logoutUrl + '?callbackUrl=https://qa-n1-neoneo.etoos.com/logout.do';
      }
    } else {
      if (domain === 'cheongsol') {
        location.href = logoutUrl + '?callbackUrl=https://cheongsol.etoos.com/logout.do';
      } else if (domain === 'answer') {
        location.href = logoutUrl + '?callbackUrl=https://a2-etoosanswer.etoos.com/logout.do';
      } else {
        location.href = logoutUrl + '?callbackUrl=https://n1-neoneo.etoos.com/logout.do';
      }
    }
  } else {
    location.href = logoutUrl + '?callbackUrl=' + hompageUrl + '/logout.do';
  }
}

export const commonEvent = {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      mobileGnb: (state) => state.common.mobileGnb,
      isMobile: (state) => state.common.isMobile
    })
  },
  methods: {
    ...mapActions(['getMobileGnbMenu']),
    openMobileLeftMenu() {
      if (!document.getElementById('nav_newcomm')) {
        this.getMobileGnbMenu(this.userInfo['branchCd'])
          .then(() => this.createMobileLeftMenu())
          .then(() => {
            document.getElementById('wrapper').classList.add('nav_on');
          })
          .then(() => {
            this.bindClickEvent();
            document.getElementById('wrapper').classList.add('nav_on');
            document.getElementById('wrapper').classList.add('sideopen');
          })
          .then(() => {
            if (!this.userInfo['branchCd'] === '0060') {
              clickMobileTabMenu(ONLY_MEMBER);
            }
          });

        return;
      }
      document.getElementById('wrapper').classList.add('nav_on');
      document.getElementById('wrapper').classList.add('sideopen');
      if (!this.userInfo['branchCd'] === '0060') {
        clickMobileTabMenu(ONLY_MEMBER);
      }
    },
    createMobileLeftMenu() {
      if (this.userInfo['branchCd'] === '0060') {
        const div = document.createElement('div');
        div.classList.add('etsgisuk');
        div.innerHTML = this.mobileGnb['menuHtml'].trim();
        document.getElementsByClassName('boxshadow')[0].append(div);
      } else {
        const div = document.createElement('div');
        div.innerHTML = this.mobileGnb['menuHtml'].trim();
        document.getElementById('wrapper').append(div);
      }
    },
    bindClickEvent() {
      const closelength = document.getElementsByClassName('close').length;
      const branchCd = this.userInfo['branchCd'];

      if (this.userInfo['branchCd'] === '0060') {
        document.getElementsByClassName('gnb_layer_close_btn')[0].addEventListener('click', function() {
          document.getElementById('wrapper').classList.remove('nav_on');
          document.getElementById('wrapper').classList.remove('sideopen');
        });
        const depth1Length = document.getElementsByClassName('depth1').length;
        for (let i = 0; i < depth1Length; i++) {
          document.getElementsByClassName('depth1')[i].addEventListener('click', function() {
            for (let i = 0; i < depth1Length; i++) {
              this.parentElement.children[i].classList.remove('on');
            }
            this.classList.add('on');
          });
        }
      } else {
        for (let i = 0; i < closelength; i++) {
          document.getElementsByClassName('close')[i].addEventListener('click', function() {
            document.getElementById('wrapper').classList.remove('nav_on');
            document.getElementById('wrapper').classList.remove('sideopen');
          });
        }

        document.getElementsByClassName('close')[0].addEventListener('click', function() {
          document.getElementById('wrapper').classList.remove('nav_on');
          document.getElementById('wrapper').classList.remove('sideopen');
        });

        document.getElementById(ALL_MENU).addEventListener('click', function() {
          clickMobileTabMenu(ALL_MENU);
        });
        document.getElementById(ONLY_MEMBER).addEventListener('click', function() {
          clickMobileTabMenu(ONLY_MEMBER);
        });
      }
      document.getElementById(EDITMY).addEventListener('click', function() {
        clickEditMy();
      });
      document.getElementById(LOGOUTMY).addEventListener('click', function() {
        clickLogoutMy(HOMEPAGE_URL[branchCd]);
      });
    }
  }
};
