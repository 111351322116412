import studyTimeManagement from './studyTimeManagement';

export default {
  state: {
    studyTimeManagement: studyTimeManagement.state
  },
  actions: { ...studyTimeManagement.actions },
  mutations: { ...studyTimeManagement.mutations },
  getters: { ...studyTimeManagement.getters }
};
