import MutationTypesUnityExam from '@/store/MutationTypesUnityExam';
import UnityExamApi from '@/api/UnityExamApi';
export default {
  getEnterAnswerYear: async ({ commit }) => {
    try {
      const result = await UnityExamApi.getEnterAnswerYearInfo();
      commit(MutationTypesUnityExam.ENTER_ANSWER_YEAR_INFO, result);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getEnterAnswerExamTab: async ({ commit }, params) => {
    try {
      const result = await UnityExamApi.getEnterAnswerExamTabInfo(params);
      commit(MutationTypesUnityExam.ENTER_ANSWER_EXAM_TAB_INFO, result);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getEnterAnswerExam: async ({ commit }, params) => {
    try {
      const result = await UnityExamApi.getEnterAnswerExamInfo(params);
      commit(MutationTypesUnityExam.ENTER_ANSWER_EXAM_INFO, result);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getEnterAnswerDtlExam: async ({ commit }, params) => {
    try {
      const result = await UnityExamApi.getEnterAnswerDtlExamInfo(params);
      commit(MutationTypesUnityExam.ENTER_ANSWER_DTL_EXAM_INFO, result);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getEnterAnswerPossibleExam: async ({ commit }, params) => {
    try {
      const result = await UnityExamApi.getEnterAnswerPossibleExamInfo(params);
      commit(MutationTypesUnityExam.ENTER_ANSWER_POSSIBLE_EXAM_INFO, result);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getEnterAnswerSelectExam: async ({ commit }, params) => {
    try {
      const result = await UnityExamApi.getEnterAnswerSelectExamInfo(params);
      commit(MutationTypesUnityExam.ENTER_ANSWER_SELECT_EXAM_INFO, result);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  setSelectExam: async ({ commit }, { unityExamSeq, unityDtlExamSeq, unityDtlExamTurnOrdSeq, subjtSeq }) => {
    commit(MutationTypesUnityExam.SET_SELECT_EXAM_SEQ, unityExamSeq);
    commit(MutationTypesUnityExam.SET_SELECT_DTL_EXAM_SEQ, unityDtlExamSeq);
    commit(MutationTypesUnityExam.SET_SELECT_DTL_EXAM_TURN_ORD_SEQ, unityDtlExamTurnOrdSeq);
    commit(MutationTypesUnityExam.SET_SELECT_EXAM_HIGH_SUBJT_SEQ, subjtSeq);
  },
  setWransrnoteExam: async ({ commit }, { unityExamSeq, unityDtlExamSeq, unityDtlExamTurnOrdSeq, subjtSeq }) => {
    commit(MutationTypesUnityExam.SET_SELECT_EXAM_SEQ, unityExamSeq);
    commit(MutationTypesUnityExam.SET_SELECT_DTL_EXAM_SEQ, unityDtlExamSeq);
    commit(MutationTypesUnityExam.SET_SELECT_DTL_EXAM_TURN_ORD_SEQ, unityDtlExamTurnOrdSeq);
    commit(MutationTypesUnityExam.SET_SELECT_EXAM_SUBJT_SEQ, subjtSeq);
  },
  getEnterAnswerExamQustn: async ({ commit }, params) => {
    try {
      const result = await UnityExamApi.getEnterAnswerExamQustnInfo(params);
      commit(MutationTypesUnityExam.ENTER_ANSWER_EXAM_QUSTN_INFO, result);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getEnterAnswerExamQustnTam: async ({ commit }, params) => {
    try {
      const result = await UnityExamApi.getEnterAnswerExamQustnTamInfo(params);
      commit(MutationTypesUnityExam.ENTER_ANSWER_EXAM_QUSTN_TAM_INFO, result);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  postInpStdntQustn: async ({ commit }, params) => {
    try {
      await UnityExamApi.postInpStdntQustn(params);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  postInitStdntQustn: async ({ commit }, params) => {
    try {
      await UnityExamApi.postInitStdntQustn(params);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getAnswerCauseData: async ({ commit }) => {
    try {
      const result = await UnityExamApi.getCauseInfo('CAR', 'C');
      commit(MutationTypesUnityExam.ANSWER_CAUSE_LIST, result);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  getWransrCauseData: async ({ commit }) => {
    try {
      const result = await UnityExamApi.getCauseInfo('WAR', 'W');
      commit(MutationTypesUnityExam.WRANSR_CAUSE_LIST, result);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  postChangeRegiWransrNote: async ({ commit }, params) => {
    try {
      await UnityExamApi.postChangeRegiWransrNote(params);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  },
  postSaveCause: async ({ commit }, params) => {
    try {
      await UnityExamApi.postSaveCause(params);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  }
};
