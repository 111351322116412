import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = {
  isMobile: false,
  isLoading: false,
  isApiCalled: false,
  userInfo: {
    authorities: [],
    metaSubDomain: '',
    branchCd: '',
    branches: [],
    grpIdx: [],
    loginId: '',
    studentCd: undefined,
    takexamYy: '2020',
    testGroupAssignSeq: 0,
    testSeq: 0,
    userAuthority: '',
    userCd: null,
    userName: '',
    personVo: {
      userAuthority: '',
      stdTypeCd: '',
      etoosMemNo: '',
      userName: '',
      unityMemNo: '',
      /* When StudentVo */
      classRoomInfoList: [
        {
          grpIdx: 0,
          groupName: ''
        }
      ],
      infinitePassInfos: [
        {
          infipasNo: 0,
          branchCd: '',
          seasonYear: '',
          seasonCd: ''
        }
      ],
      lectureGrpIdxs: 0
    }
  },
  menuName: '마이페이지',
  isPrev: false,
  mobileGnb: {
    menuHtml: '',
    recruitJson: undefined
  },
  codeList: [],
  menuList: [],
  recruitList: [],
  timeTableList: [],
  memNo: ''
};

export default {
  state,
  getters,
  actions,
  mutations
};
