<template>
  <ol class="list_ansr">
    <li v-for="(checkQuestion, index) in checkQuestions" :key="index">
      <label :for="`examAnsr${name}_${index}_${subjectSeq}`">
        <input
          type="radio"
          :id="`examAnsr${name}_${index}_${subjectSeq}`"
          :name="`examAnsr${name}_${subjectSeq}`"
          :value="checkQuestions[index].answer"
          :title="title"
          class="inp_rd_num"
          :checked="checkQuestions[index].answer === ansr"
          @change="changeAnswer(checkQuestions[index].answer)"
        />
        <span class="txt_num">{{ checkQuestion.answer }}</span>
      </label>
    </li>
  </ol>
</template>

<script>
export default {
  props: ['name', 'ansr', 'title', 'subjectSeq'],
  data() {
    return {
      checkQuestions: [{ answer: '1' }, { answer: '2' }, { answer: '3' }, { answer: '4' }, { answer: '5' }]
    };
  },
  methods: {
    changeAnswer(value) {
      this.$emit('change-answer', value);
    }
  }
};
</script>

<style></style>
