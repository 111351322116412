import actions from './actions';
import mutations from './mutations';

const state = {
  myClassInfoData: {
    updateCnt: 0,
    inClassCnt: 0,
    intrstLctCnt: 0,
    classCompleteCnt: 0,
    curriRecentInClass: []
  },
  /**
   * 학생과 연관관계가 있는 무한패스 강좌에 대한 과목 리스트
   */
  subjectList: [],
  /**
   * 학생과 연관관계가 있는 무한패스 강좌에 대한 선생님 리스트
   */
  teacherList: [],
  turnOrdLectures: [
    {
      curriVodNo: 0,
      mediaContsKey: '',
      curriTurnOrd: 0,
      curriDt: '0000-00-00(월)',
      prgRt: '',
      lastStdDt: '',
      viewYn: '',
      userNotiCont: '',
      vodIdxInfo: ''
    }
  ]
};

export default {
  state,
  actions,
  mutations
};
