<template>
  <div class="schedule" id="schedule" ref="layoutSchedule">
    <div class="wrap_time">
      <div class="colgroup w02">
        <div class="cell h01 tit_column">
          <div class="inner_cell"><strong class="txt_info">교시</strong></div>
        </div>
        <div
          v-for="(item, idx) in scheduleList"
          :key="idx"
          class="cell"
          :ref="'cellIndex' + idx"
          :id="'cellIndex' + item.perdCd"
          :class="perdSet(item ? item.perdCd : '', false, false, item ? item.fixData == 'Y' : false)"
        >
          <div v-if="item" class="inner_cell">
            <strong v-if="perdSet(item ? item.perdCd : '') == 'h02'" class="txt_info">{{ item.exposureNm }}</strong>
            <p class="txt_time">{{ item.startTimeHhmm + '~' + item.endTimeHhmm }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="wrap_lecture swiper-container">
      <div class="swiper-wrapper">
        <!-- 월요일 -->
        <div v-for="(schedule, idx) in schedule" :key="idx" class="colgroup w01 swiper-slide">
          <div class="cell h01 tit_column">
            <div class="inner_cell">
              <strong class="txt_info">{{ idx | weekFilter() }}</strong>
            </div>
          </div>
          <div
            v-for="(item, cnt) in scheduleList"
            :key="cnt"
            :class="perdSet(item.perdCd, 'rowspan', item[idx], item.fixData == 'Y', item[idx + 'ItemUseYn'])"
            class="cell"
            @click.self="courseSearchPopUp(item, idx)"
          >
            <!--subjtCd != 'all' && subjtCd == item[idx].subjtCd /@click="courseSearchPopUp(item.perdCd, idx)"-->
            <div
              v-if="item[idx] && item[idx].baisSort == '0'"
              v-show="subjtCd == 'all' || subjtCd == item[idx].subjtCd"
              class="inner_cell"
              @click.self="courseSearchPopUp(item, idx)"
            >
              <label v-if="!popupCall" class="lbl_subject">
                <input
                  type="checkbox"
                  class="inp_check"
                  v-model="deleteChk"
                  :disabled="item[idx].lectureTypeCd === String(LECTURE_TYPE_CD.DANGWA) || item[idx].lectureTypeCd === String(LECTURE_TYPE_CD.SPECIAL)"
                  :value="{ grpIdx: item[idx].grpIdx, grpasnIdx: item[idx].grpasnIdx }"
                />
                <i class="ico_comm ico_check"></i>
              </label>
              <div @click="courseSearchPopUp(item, idx)">
                <p class="txt_teacher">{{ '[' + item[idx].subjtNm + ']' + item[idx].teacherName }}</p>
                <template v-if="item[idx].newfix != ''">
                  <p class="txt_subject" v-html="newITag + item[idx].groupName"></p>
                </template>
                <template v-else>
                  <p class="txt_subject" v-html="item[idx].groupName"></p>
                </template>
                <p class="txt_time">
                  {{ item[idx].startTime + '~' + item[idx].endTime + '(' + item[idx].groupRoom + ')' }}
                </p>
                <a href="javascript:;" class="btn_find"><span class="blind"></span></a>
              </div>
              <button
                v-if="item[idx].count > 0 || item[idx].waitingOrder > 0"
                :class="btnCheck(item[idx])"
                class="btn_check"
                @click="checkPopup(item[idx])"
              >
                <span v-if="item[idx].count > 0" class="inner">{{ item[idx].count }}건</span>
                <span v-else-if="item[idx].waitingOrder > 0" class="inner">
                  대기<br />{{ item[idx].waitingOrder }}번
                </span>
              </button>
              <button
                v-if="item[idx].lectureIntroFileUrl != ''"
                type="button"
                class="btn_detail"
                @click="detailPop(item[idx])"
              >
                <span class="blind">상세보기</span>
              </button>
            </div>
            <div v-else class="inner_cell">
              <p v-if="item[idx + 'ItemUseYn'] == 'Y'" class="txt_cont">
                {{ item[idx + 'FixItemNm'] }}
              </p>
              <strong v-if="item.perdCd == 'r1' || item.perdCd == 'r2'" class="txt_cont">
                {{ item.exposureNm }}&nbsp;
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import DateUtil from '@/utils/DateUtil';
import TmsCookieUtil from '@/utils/TmsCookieUtil';
import lectureCheck from '@/components/classroom/lecture/LectureCheckM';
import lectureSearch from '@/components/classroom/lecture/LectureSearchMainM';
import UiMypage from '@/utils/ui_mypage';
import { LECTURE_TYPE_CD } from '@/const/classroom_const';

export default {
  name: 'schedulemain',
  components: { DateUtil, lectureCheck, lectureSearch },
  data() {
    return {
      newITag: '<i class="ico_comm ico_new">신규</i>',
      guidePopup: true,
      seasonCd: '',
      seasonNm: '',
      studentCd: '',
      date: new Date(),
      subjtArray: [],
      schedule: {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: []
      },
      duplicateIdx: [],
      duplicateWeek: [],
      guidePopupChk: false,
      branchCd: '',
      deleteChk: [],
      checkParam: {},
      isCheckPopup: false,
      isSearchPopup: false,
      isPrintPopup: false,
      Array: [],
      danArray: [],
      popupCnt: 0,
      seasonYear: '',
      seasonStatus: 'N',
      sortArray: [],
      scheduleFlag: 0,
      LECTURE_TYPE_CD
    };
  },
  filters: {
    tmFilter(val) {
      return TmsCookieUtil.getCookie('lecTM') === 'M' ? val * 60 + '분' : val + 'T';
    },
    weekFilter(val) {
      switch (val) {
        case 'mon':
          return '월요일';
        case 'tue':
          return '화요일';
        case 'wed':
          return '수요일';
        case 'thu':
          return '목요일';
        case 'fri':
          return '금요일';
        case 'sat':
          return '토요일';
        case 'sun':
          return '일요일';
        default:
          '';
          break;
      }
    }
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      userInfo: (state) => state.common.userInfo,
      studentScheduleList: (state) => state.classroom.schedule.studentScheduleList,
      scheduleList: (state) => state.classroom.schedule.scheduleList
    })
  },
  watch: {
    cancel(e) {
      if (e) {
        this.cancelCheck();
      }
    }
  },
  props: ['scheduleParam', 'popupCall', 'cancel', 'subjtCd'],
  created() {
    if (TmsCookieUtil.getCookie('etsscheduleguide') === 'Y') {
      this.guidePopup = false;
    }
    this.branchCd = this.scheduleParam.branchCd;
    this.seasonCd = this.scheduleParam.seasonCd;
    this.studentCd = this.scheduleParam.studentCd;
    this.seasonYear = this.scheduleParam.seasonYear;
    this.seasonStatus = this.scheduleParam.seasonStatus;
    this.duplicateIdx = [];
    this.duplicateWeek = [];
    this.danArray = [];
    this.schedule = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: []
    };
    if (!this.userInfo.studentCd) {
      this.getUser().then(() => {
        this.getSchedule();
      });
    } else {
      this.getSchedule();
    }
  },
  mounted() {
    UiMypage.mypageUI.init();
  },
  destroyed() {},
  methods: {
    ...mapActions([
      'getScheduleList',
      'StudentSchedule',
      'getUser',
      'getStudentGroup',
      'postBaisSort',
      'setStatusConfirm'
    ]),
    getSchedule() {
      const params = {};
      params.branchCd = this.branchCd;
      params.seasonCd = this.seasonCd;
      params.seasonYear = this.seasonYear;
      this.getScheduleList(params).finally(() => {
        this.scheduleList.forEach((e) => {
          if (
            e.perdCd.slice(0, 1) !== 'r' &&
            e.monItemUseYn === 'Y' &&
            e.tueItemUseYn === 'Y' &&
            e.wedItemUseYn === 'Y' &&
            e.thuItemUseYn === 'Y' &&
            e.friItemUseYn === 'Y' &&
            e.satItemUseYn === 'Y' &&
            e.sunItemUseYn === 'Y'
          ) {
            e.fixData = 'Y';
          } else {
            e.fixData = 'N';
          }
        });
        this.getStudentSchedule();
      });
    },
    getGroup() {
      const params = {};
      params.studentCd = this.studentCd;

      this.getStudentGroup(params);
    },
    disableChk(item) {
      if (
        !(
          item.studyRegStartDt <= DateUtil.dateFormatter('{yyyy}{mm}{dd}', this.date) &&
          item.studyRegEndDt >= DateUtil.dateFormatter('{yyyy}{mm}{dd}', this.date)
        ) &&
        item.seasonStartDt > DateUtil.dateFormatter('{yyyy}{mm}{dd}', this.date)
      ) {
        return true;
      }
    },
    //학생 스케쥴
    getStudentSchedule() {
      const params = {};
      params.branchCd = this.branchCd;
      params.seasonCd = this.seasonCd;
      params.seasonYear = this.seasonYear;
      params.studentCd = this.studentCd;
      this.duplicateIdx = [];
      this.subjtArray = [];
      if (this.scheduleFlag != 0) {
        return;
      }
      this.StudentSchedule(params).then(() => {
        let t = 0;
        const array = [];
        let arrayT = [];
        this.sortArray = [];
        this.scheduleFlag = 1;
        this.studentScheduleList.forEach((e) => {
          if (
            !e.monHhmm &&
            !e.tueHhmm &&
            !e.wedHhmm &&
            !e.thuHhmm &&
            !e.friHhmm &&
            !e.satHhmm &&
            !e.sunHhmm
          ) {
            return;
          }
          const check = _.find(this.scheduleList, { perdCd: e.perdCd });
          if (!check) {
            return;
          }
          if (array.indexOf(e.grpIdx) == -1) {
            const x = _.findIndex(arrayT, { value: e.subjtCd });
            if (x == -1) {
              const row = {};
              row.value = e.subjtCd;
              row.name = e.subjtNm;
              row.cnt = e.groupTime;
              t += e.groupTime;
              arrayT.push(row);
              array.push(e.grpIdx);
            } else {
              arrayT[x].cnt = arrayT[x].cnt + e.groupTime;
              t += e.groupTime;
              array.push(e.grpIdx);
            }
          }
          if (this.seasonStatus == 'Y' && e.waitingOrder > 0) {
            //시즌확정 해제
            this.setConfrimCancel();
          }
          //시간표
          if (e.monHhmm != null) {
            this.perdCheck(e, 'mon');
          }
          if (e.tueHhmm != null) {
            this.perdCheck(e, 'tue');
          }
          if (e.wedHhmm != null) {
            this.perdCheck(e, 'wed');
          }
          if (e.thuHhmm != null) {
            this.perdCheck(e, 'thu');
          }
          if (e.friHhmm != null) {
            this.perdCheck(e, 'fri');
          }
          if (e.satHhmm != null) {
            this.perdCheck(e, 'sat');
          }
          if (e.sunHhmm != null) {
            this.perdCheck(e, 'sun');
          }
        });
        //default
        this.dupCheck();
        arrayT = _.sortBy(arrayT, 'value');
        this.subjtArray = arrayT;
        this.subjtArray.unshift({ value: 'all', name: '전체', cnt: t });
        this.danArray = this.danArray.filter((item, idx) => this.danArray.indexOf(item) === idx);
        this.$emit('subjtArray', this.subjtArray);
        this.$nextTick(() => {
          this.$forceUpdate();
        });
      });
      window.dispatchEvent(new Event('resize'));
    },
    //교시 셋팅
    perdSet(perdCd, span, isSubjt, fixPerd, fixData) {
      if (perdCd.slice(0, 1) !== 'r') {
        if (fixPerd && !isSubjt) {
          return 'h01 item_fixed';
        } else if (fixPerd && isSubjt) {
          const fixDataChk = _.find(this.scheduleList, { perdCd });
          if (fixDataChk.fixData === 'Y') {
            fixDataChk.fixData = 'N';
            //교시컬럼 rerender필요
            this.$forceUpdate();
          }
        }
        if (isSubjt && (this.subjtCd === 'all' || this.subjtCd == isSubjt.subjtCd)) {
          if (isSubjt.baisSort === '1' && fixData === 'N') {
            return 'h02';
          } else if (isSubjt.baisSort == '1' && fixData === 'Y') {
            return 'h02 item_fixed';
          }
          if (isSubjt.subjtCd == '100') {
            return 'h02 subject type1';
          } else if (isSubjt.subjtCd == '200') {
            return 'h02 subject type3';
          } else if (isSubjt.subjtCd == '300') {
            return 'h02 subject type2';
          } else if (isSubjt.subjtCd == '400') {
            return 'h02 subject type7';
          } else if (isSubjt.subjtCd == '500') {
            return 'h02 subject type4';
          } else if (isSubjt.subjtCd == '600') {
            return 'h02 subject type5';
          } else if (isSubjt.subjtCd == '700') {
            return 'h02 subject type8';
          } else if (isSubjt.subjtCd == '301') {
            return 'h02 subject type6';
          } else if (isSubjt.subjtCd == '800') {
            return 'h02 subject type9';
          } else if (isSubjt.subjtCd == '900') {
            return 'h02 subject type10';
          } else if (isSubjt.subjtCd == '999') {
            return 'h02 subject type11';
          }
          return 'h02 subject type';
        } else if (fixData === 'Y') {
          return 'h02 item_fixed';
        } else {
          return 'h02';
        }
      } else {
        if (!span) {
          span = '';
        }
        return 'h01 item_fixed ' + span;
      }
    },
    btnCheck(item) {
      if (item.count > 0 && item.overYn === 'Y') {
        return 'type3';
      } else if ((item.waitingOrder > 0 && item.regstateCd === '6') || item.watitng === 'Y') {
        return 'type2';
      } else if (item.count > 0 && item.overYn === 'N' && item.watitng === 'N') {
        return 'type1';
      }
    },
    dupCheck() {
      let refreshFlag = 0;
      Object.keys(this.schedule).forEach((e) => {
        //해당요일 찾기
        const weekDup = _.filter(this.sortArray, { week: e });
        if (weekDup.length > 0) {
          Object.keys(_.groupBy(weekDup, 'perdCd')).forEach((k) => {
            //교시별 루프
            const tempList = _.filter(weekDup, { perdCd: k });
            const data = [];
            let displayTrue = 0;
            let displayfalse = 0;
            const dupChk = [];
            let assignFirstGrpIdx = 0; //신청일시 가장빠른것
            let updateLastGrpIdx = 0; //업데이트일시 가장 빠른것
            let assignTempDate = '';
            let updateTempDate = '';
            tempList.forEach((item) => {
              //업데이트 비교
              item.studentCd = this.studentCd;
              if (updateLastGrpIdx === 0 || item.updateDate > updateTempDate) {
                updateLastGrpIdx = item.grpIdx;
                updateTempDate = item.updateDate;
              }
              if (item.baisSort === '0') {
                if (dupChk.indexOf(item.grpIdx) === -1) {
                  dupChk.push(item.grpIdx);
                  displayTrue++;
                }
              } else {
                displayfalse++;
              }
              const grpFind = _.find(data, { grpIdx: item.grpIdx });
              if (!grpFind) {
                data.push(item);
              }
            });
            tempList.forEach((item) => {
              //신청일시 비교
              if (
                item.grpIdx !== updateLastGrpIdx &&
                (assignFirstGrpIdx === 0 || assignTempDate > item.assignDetailDate)
              ) {
                assignTempDate = item.assignDetailDate;
                assignFirstGrpIdx = item.grpIdx;
              }
            });
            if (displayTrue === 1) {
              //데이터 정상
              const cellPerd = _.find(this.scheduleList, { perdCd: data[0].perdCd });
              cellPerd[data[0].week].count = data.length;
              if (data.length > 0) {
                //운영기간 중복 체크
                let sdate,
                  edate = '';
                let over = 0;
                let watitng = 0;
                data.forEach((e) => {
                  if (sdate !== '' && edate !== '') {
                    if (e.coursesSdate <= edate && sdate <= e.coursesEdate) {
                      over++;
                    }
                  }
                  sdate = e.coursesSdate;
                  edate = e.coursesEdate;
                  if (e.waitingOrder > 0) {
                    watitng++;
                  }
                });
                if (watitng > 0) {
                  cellPerd[data[0].week].watitng = 'Y';
                } else {
                  cellPerd[data[0].week].watitng = 'N';
                }
                if (over > 0) {
                  cellPerd[data[0].week].overYn = 'Y';
                  if (this.seasonStatus === 'Y') {
                    //중복값존재시 확정 해제
                    this.setConfrimCancel();
                  }
                } else {
                  cellPerd[data[0].week].overYn = 'N';
                }
              }
              return;
            }
            //해당 요일 교시 기간순 찾음
            if (displayTrue > 1) {
              //00중복
              data.forEach((v) => {
                v.baisSort = v.grpIdx === updateLastGrpIdx ? '0' : '1';
              });
            } /*else {
              //11중복
              let currentIdx = '';
              data.forEach((v) => {
                currentIdx = v.grpIdx;
                if (currentIdx === updateLastGrpIdx) {
                  v.baisSort = '1';
                } else if (currentIdx === assignFirstGrpIdx) {
                  v.baisSort = '0';
                } else {
                  v.baisSort = '1';
                }
              });
            }*/
            refreshFlag = this.saveBaisSort(data) ? refreshFlag + 1 : refreshFlag;
          });
        }
      });
      /*      this.$nextTick(() => {
        if (refreshFlag > 0) {
          this.$emit('scheduleRefresh');
        }
      });*/
    },
    perdCheck(e, week) {
      const cellPerd = _.find(this.scheduleList, { perdCd: e.perdCd });
      if (Number(e.lectureTypeCd) === 2) {
        //단과 grpidx 모으기
        this.Array.push(e.grpIdx);
      }
      if (!cellPerd) {
        return;
      }
      //같은교시 걸러내기 위해 새 object생성
      const data = {};
      data.assignDetailDate = e.assignDetailDate;
      data.updateDate = e.updateDate;
      data.baisSort = e.baisSort;
      data.grpIdx = e.grpIdx;
      data.perdCd = e.perdCd;
      data.coursesEdate = e.coursesEdate;
      data.coursesSdate = e.coursesSdate;
      data.groupForm = e.groupForm;
      data.groupLimit = e.groupLimit;
      data.groupMark = e.groupMark;
      data.courseTimeMinuteCnt = e.courseTimeMinuteCnt;
      data.groupCd = e.groupCd;
      data.groupName = e.groupName;
      data.groupRoom = e.groupRoom;
      data.groupTime = e.groupTime;
      data.grpasnIdx = e.grpasnIdx;
      data.lectureIntroFileUrl = e.lectureIntroFileUrl;
      data.lectureTypeCd = e.lectureTypeCd;
      data.newfix = e.newfix;
      data.perdSeq = e.perdSeq;
      data.regstateCd = e.regstateCd;
      data.subjtCd = e.subjtCd;
      data.subjtNm = e.subjtNm;
      data.teacherCd = e.teacherCd;
      data.teacherName = e.teacherName;
      data.waitingOrder = e.waitingOrder;
      data.startTime = e[week + 'Hhmm'];
      data.endTime = e[week + 'HhmmEnd'];

      if (cellPerd[week]) {
        let row = {};
        row.grpIdx = cellPerd[week].grpIdx;
        row.perdCd = cellPerd[week].perdCd;
        if (!_.find(this.schedule[week], { grpIdx: row.grpIdx })) {
          this.schedule[week].push(row);
        }
        row = {};
        row.grpIdx = data.grpIdx;
        row.perdCd = data.perdCd;
        if (!_.find(this.schedule[week], { grpIdx: row.grpIdx })) {
          this.schedule[week].push(row);
        }
        //중복 강좌 모으기
        cellPerd[week].week = week;
        data.week = week;

        this.sortArray.push(cellPerd[week]);
        this.sortArray.push(data);
      } else {
        data.week = week;
        data.count = 0;
        cellPerd[week] = data;
      }
    },
    checkPopup(val) {
      const result = this.schedule[val.week]
        .filter((e) => {
          return e.perdCd === val.perdCd;
        })
        .map(function(v) {
          return v.grpIdx;
        });
      if (result.length === 0 && val.waitingOrder > 0) {
        result.push(val.grpIdx);
      }
      this.$emit('checkPopup', result);
    },
    detailPop(val) {
      this.$emit('detailPop', val);
    },
    courseSearchPopUp(item, week) {
      if (item.perdCd.substring(0, 1).indexOf('r') === -1 && item[week + 'ItemUseYn'] === 'N') {
        const params = {};
        params.perdCd = item.perdCd;
        params.week = week;
        if (item[week]) {
          params.lectureTypeCd = item[week].lectureTypeCd;
        } else {
          params.lectureTypeCd = '1';
        }
        this.$emit('scheduleSearch', params);
      }
    },
    cancelCheck() {
      this.$emit('cancelCheck', this.deleteChk);
    },
    saveBaisSort(dataList) {
      const params = {};
      params.branchCd = this.branchCd;
      params.seasonCd = this.seasonCd;
      params.seasonYear = this.seasonYear;
      params.studentCd = this.studentCd;
      params.requestList = dataList;
      this.postBaisSort(params);
      return true;
    },
    setConfrimCancel() {
      const params = {};
      params.regYn = 'N';
      params.branchCd = this.branchCd;
      params.seasonCd = this.seasonCd;
      params.seasonYear = this.seasonYear;
      params.studentCd = this.studentCd;
      this.setStatusConfirm(params).finally(() => {
        this.$emit('seasonStatusChange');
      });
      this.seasonStatus = 'N';
    }
  }
};
</script>
