<template>
  <div>
    <div class="mg_cont">
      <h5 class="tit_section">반 수강내역</h5>
      <group-table :group-list="studyGrpStatusDataTo" />
      <h5 class="tit_section">
        강좌 수강내역<button class="btn_filter" @click="filterLayers('show')">
          <span class="blind">검색필터 열기</span>
        </button>
      </h5>
      <div class="pc_only">
        <div class="box_filter" style="text-align: left">
          <div class="wrap_cate">
            <ul class="list_item">
              <li>
                <input
                  type="checkbox"
                  class="inp_check"
                  id="lec1"
                  value=""
                  v-model="lectureType"
                  @change="chkChange($event)"
                />
                <label for="lec1" class="lbl_item"><span class="inner">전체</span></label>
              </li>
              <li>
                <input
                  type="checkbox"
                  class="inp_check"
                  id="lec2"
                  value="1"
                  v-model="lectureType"
                  @change="chkChange($event)"
                />
                <label for="lec2" class="lbl_item">
                  <span class="inner">{{ this.lectureTypeMap['1'] }}</span>
                </label>
              </li>
              <li>
                <input
                  type="checkbox"
                  class="inp_check"
                  id="lec3"
                  value="2"
                  v-model="lectureType"
                  @change="chkChange($event)"
                />
                <label for="lec3" class="lbl_item">
                  <span class="inner">{{ this.lectureTypeMap['2'] }}</span>
                </label>
              </li>
              <li>
                <input
                  type="checkbox"
                  class="inp_check"
                  id="lec4"
                  value="3"
                  v-model="lectureType"
                  @change="chkChange($event)"
                />
                <label for="lec4" class="lbl_item">
                  <span class="inner">{{ this.lectureTypeMap['3'] }}</span>
                </label>
              </li>
            </ul>
          </div>
          <div class="wrap_cate">
            <ul class="list_item">
              <li>
                <input
                  type="checkbox"
                  class="inp_check"
                  id="grp"
                  value=""
                  v-model="studyStatus"
                  @change="chkChange($event)"
                />
                <label class="lbl_item" for="grp"><span class="inner">전체</span></label>
              </li>
              <li>
                <input
                  type="checkbox"
                  class="inp_check"
                  id="grp"
                  value="1"
                  v-model="studyStatus"
                  @change="chkChange($event)"
                />
                <label class="lbl_item" for="grp"><span class="inner">수강예정</span></label>
              </li>
              <li>
                <input
                  type="checkbox"
                  class="inp_check"
                  id="grp"
                  value="2"
                  v-model="studyStatus"
                  @change="chkChange($event)"
                />
                <label class="lbl_item" for="grp"><span class="inner">수강중</span></label>
              </li>
            </ul>
          </div>
          <div class="wrap_cate">
            <ul class="list_item">
              <li v-for="(sub, idx) in getSubjects()" :key="idx">
                <span v-if="sub.subjtName !== ''">
                  <input
                    type="checkbox"
                    class="inp_check"
                    :id="'sub'"
                    :value="sub.subjtCd"
                    v-model="subject"
                    @change="chkChange($event)"
                  />
                  <label class="lbl_item" :for="'sub'">
                    <span class="inner">{{ sub.subjtName }}</span>
                  </label>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <lecture-table
        :lecture-list="lectureList"
        :page-size="lectureTablePageSize"
        :page-num="lectureTablePageNum"
        :is-curr="true"
      />
      <div class="pc_only">
        <classroom-table-page
          :page-list="lecturePageList"
          :total-size="(lectureList || []).length"
          :page-num="lectureTablePageNum"
          :page-size="lectureTablePageSize"
          @set-page="setPage"
          @set-page-list="setPageList"
        />
      </div>
      <!-- Layer -->
      <!-- 필터1 -->
      <div id="filterLayer1" class="layer_sidepage filter" :class="{ open: filterView === true }">
        <div class="layer_body">
          <div class="layer_head">
            <h4 class="tit_layer">필터</h4>
            <div class="expander">
              <button type="button" class="btn_refresh" @click="filterRefresh()">
                <span class="blind">새로고침</span>
              </button>
            </div>
            <a href="javascript:;" class="layer_close" @click="filterLayers('hide')">
              <span class="blind">레이어 닫기</span>
            </a>
          </div>
          <div class="layer_cont">
            <!--  강좌유형 -->
            <lecture-component @lecture="getChildLecture" :lectureDt="lectureDt" :lecture-type-map="lectureTypeMap" />

            <!-- 수강상태 -->
            <grp-state-component @state="getChildState" :studyDt="studyDt" />

            <!-- 과목 -->
            <subject-component :subjectData="getSubjects()" @subject="getChildSubject" :subjectDt="subjectDt" />
          </div>
        </div>
        <div class="dimmed_layer" @click="filterLayers('hide')"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import Uimypage from '@/utils/ui_mypage';
import LectureComponent from './LectureComponent';
import GrpStateComponent from './GrpStateComponent';
import SubjectComponent from './SubjectComponent';
import GroupTable from './GroupTable';
import LectureTable from './LectureTable';
import ClassroomTablePage from './ClassroomTablePage';

export default {
  data() {
    return {
      filterView: false,
      lectureType: [''],
      studyStatus: [''],
      subject: [''],
      studentCd: '',
      groupOpe: '001', //강좌상태 : 개강
      studyDt: [''], // 모바일 수강상태 초기화용
      lectureDt: [''], //모바일 강좌유형 초기화용
      subjectDt: [''], //모바일 과목 초기화용
      subjectList: [], //과목 내역
      lectureTablePageNum: 1,
      lecturePageList: [1]
    };
  },
  props: ['subjects'],

  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      userInfo: (state) => state.common.userInfo,
      studyGrpStatusDataTo: (state) => state.classroom.study.studyGrpStatusDataTo,
      studyStatusDataTo: (state) => state.classroom.study.studyStatusDataTo,
      lectureTypeMap: (state) => state.classroom.lecture.lectureTypeMap,
      seasonPerdMap: (state) => state.classroom.schedule.seasonPerdMap
    }),
    lectureList() {
      const curDt = moment().format('YYYYMMDD');
      const studyData = this.setStudyDataFilter(this.studyStatusDataTo);

      studyData.forEach((item) => {
        if (item.groupSdate > curDt && item.groupEdate > curDt) {
          item.order = '1';
        } else if (item.groupSdate <= curDt && item.groupEdate >= curDt) {
          item.order = '2';
        } else {
          item.order = '3';
        }
      });

      const filData = [];
      _.chain(studyData)
        .sortBy('groupSdate')
        .sortBy('subjectCd')
        .sortBy('order')
        .each(function(item, idx) {
          //번호를 재설정
          item.no = idx + 1;
          filData.push(item);
        })
        .value();
      return filData;
    },
    lectureTablePageSize() {
      return this.isMobile ? 500 : 20;
    }
  },
  watch: {
    lectureType() {
      if (this.lectureType.length === 0) this.lectureType = [''];
    },
    subject() {
      if (this.subject.length === 0) this.subject = [''];
    },
    studyStatus() {
      if (this.studyStatus.length === 0) this.studyStatus = [''];
    }
  },
  components: {
    ClassroomTablePage,
    LectureTable,
    GroupTable,
    LectureComponent,
    GrpStateComponent,
    SubjectComponent
  },
  created() {
    if (!this.userInfo.studentCd) {
      this.getUser().then(() => {
        this.getLectureType().then(() => {
          this.$forceUpdate();
        });
        this.getStudyGrpData();
        this.getStudyData();
      });
    } else {
      this.getLectureType().then(() => {
        this.$forceUpdate();
      });
      this.getStudyGrpData();
      this.getStudyData();
    }
  },
  mounted() {
    Uimypage.mypageUI.init();
  },
  methods: {
    ...mapActions(['getStudyGrpStatusTo', 'getStudyStatusTo', 'getUser', 'getLectureType']),
    getChildLecture(e) {
      this.lectureType = e;
    },
    getChildState(e) {
      this.studyStatus = e;
    },
    getChildSubject(e) {
      this.subject = e;
    },
    filterLayers(view) {
      if (view === 'show') {
        this.filterView = true;
      } else {
        this.filterView = false;
      }
    },
    //검색데이터 필터링
    setStudyDataFilter(statusData) {
      const filterData = [];
      const lec = [];
      const sub = [];
      const ststa = [];
      const curDt = moment().format('YYYYMMDD');

      this.lectureType.forEach((item) => {
        if (item !== '') lec.push(item);
      });
      this.subject.forEach((item) => {
        if (item !== '') sub.push(item);
      });
      this.studyStatus.forEach((item) => {
        if (item !== '') ststa.push(item);
      });
      if (lec.length > 0) {
        //강좌유형
        lec.forEach((item1) => {
          //console.log("ststa==>"+ststa.length);
          if (ststa.length === 0) {
            if (sub.length === 0) {
              filterData.push(...statusData.filter((d) => d.lectureTypeCd === item1));
            } else {
              sub.forEach((item3) => {
                filterData.push(...statusData.filter((d) => d.lectureTypeCd === item1 && d.subjectCd === item3));
              });
            }
          } else {
            const sta = [];
            ststa.forEach((st) => {
              statusData.forEach((item) => {
                if (st === '1') {
                  if (item.groupSdate > curDt) sta.push(item);
                } else {
                  if (item.groupSdate <= curDt && item.groupEdate >= curDt) sta.push(item);
                }
              });
            });
            sta.forEach((item2) => {
              if (sub.length === 0)
                filterData.push(...statusData.filter((d) => d.lectureTypeCd === item1 && d.id === item2.id));
              sub.forEach((item3) => {
                filterData.push(
                  ...statusData.filter((d) => d.lectureTypeCd === item1 && d.id === item2.id && d.subjectCd === item3)
                );
              });
            });
          }
        });
      } else if (ststa.length > 0) {
        const sta = [];
        ststa.forEach((st) => {
          statusData.forEach((item) => {
            if (st === '1') {
              if (item.groupSdate > curDt) sta.push(item);
            } else {
              if (item.groupSdate <= curDt && item.groupEdate >= curDt) sta.push(item);
            }
          });
        });
        sta.forEach((item2) => {
          if (sub.length === 0) filterData.push(...statusData.filter((d) => d.id === item2.id));
          sub.forEach((item3) => {
            filterData.push(...statusData.filter((d) => d.id === item2.id && d.subjectCd === item3));
          });
        });
      } else if (sub.length > 0) {
        //과목
        sub.forEach((item3) => {
          filterData.push(...statusData.filter((d) => d.subjectCd === item3));
        });
      } else {
        //전체
        filterData.push(...statusData);
      }

      return filterData;
    },
    //반수강내역 데이터 처리
    getStudyGrpData() {
      const params = {};
      params.branchCd = this.userInfo.branchCd;
      params.studentCd = this.userInfo.studentCd;
      params.lectureTypeCds = ''; //재종반: 1, 단과: 2, 특강: 3
      params.groupForm = ''; // 특강 3
      params.groupTypeCd = '1'; //강좌
      params.groupOpe = this.groupOpe; // 강좌상태 -> 수강: 001, 종강: 003
      params.subjtCds = '';
      params.coursesSdate = moment().format('YYYYMMDD');
      params.coursesEdate = moment().format('YYYYMMDD');
      this.getStudyGrpStatusTo(params);
    },
    //강좌수강내역 데이터 처리
    getStudyData() {
      const params = {};
      params.branchCd = this.userInfo.branchCd;
      params.studentCd = this.userInfo.studentCd;
      params.groupForm = ''; // 특강 3
      params.groupTypeCd = '2'; //강좌
      params.groupOpe = this.groupOpe; // 강좌상태 -> 수강: 001, 종강: 003*/
      this.getStudyStatusTo(params).finally(() => {
        this.subjectList = this.getStudySubjectList();
      });
    },
    //검색항목 이벤트 처리
    chkChange(e) {
      if (e.target.value !== '') {
        if (e.target.id.indexOf('lec') >= 0) {
          const index = this.lectureType.indexOf('');
          if (index > -1) {
            this.lectureType.splice(index, 1);
          }
        } else if (e.target.id.indexOf('sub') >= 0) {
          const index = this.subject.indexOf('');
          if (index > -1) {
            this.subject.splice(index, 1);
          }
        } else {
          const index = this.studyStatus.indexOf('');
          if (index > -1) {
            this.studyStatus.splice(index, 1);
          }
        }
      } else {
        if (e.target.id.indexOf('lec') >= 0) {
          this.lectureType = [''];
        } else if (e.target.id.indexOf('sub') >= 0) {
          this.subject = [''];
        } else {
          this.studyStatus = [''];
        }
      }
    },
    //전체 과목정보
    getStudySubjectList() {
      const resultData = this.studyStatusDataTo;
      resultData.sort((a, b) => (a['subjectCd'] < b['subjectCd'] ? -1 : 0));

      return resultData;
    },
    //과목정보 추출
    getSubjects() {
      const data = this.subjectList;
      const arrDt = [];
      let arr = { subjtCd: '', subjtName: '전체' };
      arrDt.push(arr);
      data.forEach((item) => {
        arr = {};
        arr.subjtCd = item.subjectCd;
        arr.subjtName = item.subjectName;
        arrDt.push(arr);
      });
      const uniqueArray = arrDt.filter((thing, index) => {
        return (
          index ===
          arrDt.findIndex((obj) => {
            return JSON.stringify(obj) === JSON.stringify(thing);
          })
        );
      });

      return uniqueArray.sort((a, b) => (a['subjtCd'] < b['subjtCd'] ? -1 : 0));
    },
    //초기화
    filterRefresh() {
      this.studyStatus = '';
      this.studyDt = [''];
      this.lectureType = [''];
      this.lectureDt = [''];
      this.subject = [''];
      this.subjectDt = [, ''];
    },
    setPage(page) {
      this.lectureTablePageNum = page;
    },
    setPageList(list) {
      this.lecturePageList = list;
    }
  },
  destroyed() {}
};
</script>
