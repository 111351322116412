<template>
  <div v-if="userInfo.branchCd === '0500'" id="footer_neo">
    <div class="ly_inner">
      <div class="link">
        <a href="https://recruit.etoos.com" target="_blank">인재채용</a>
        <a href="https://www.etoos.com/member/yakkwan_new.asp" target="_blank">이용약관</a>
        <a href="https://www.etoos.com/member/individual_new.asp" target="_blank"><strong>개인정보처리방침</strong></a>
      </div>
      <p class="company">(10416) 경기도 고양시 일산동구 일산로 197(마두동 736-1) 4층 <span class="line">|</span> 사업자등록번호 : 217-85-25116 <span class="line">|</span> Tel : 031-818-4545<span class="line">|</span> Fax : 031-696-9945</p>
      <p>
        이투스네오일산학원 학원등록번호 : 제5837호 교습과정 : 종합-보습, 입시, 진학상담·지도 | 이투스네오일산독서실 학원등록번호 : 제5836호 교습과정 : 독서실
      </p>
      <p class="company">
        (10416) 경기도 고양시 일산동구 일산로 219(마두동 738) 5층 <span class="line">|</span> 사업자등록번호 : 711-85-01877<span class="line">|</span> Tel : 031-907-2345<span class="line">|</span> Fax : 031-696-9945
        <br />
      </p>
      <p>이투스네오일산1학원 학원등록번호 : 제3786호 교습과정 : 입시</p>
      <p class="company">
        <strong>이투스에듀 주식회사</strong> (06733) 서울시 서초구 남부순환로 2547 (서초동 1354-3) <span class="line">|</span> Tel: 02-552-1230 <span class="line">|</span> Fax: 02-552-1325 <span class="line">|</span> 사업자등록번호 : 505-87-02372
        <span class="line">|</span> 통신판매신고번호 : 강남-03036호
        <a href="javascript:void(0);" @click="openBizInfo('5058702372')">정보조회</a>
      </p>
      <p>대표이사: 정선욱<span class="line">|</span>개인정보보호책임자 : 정미상</p>
      <p class="copyright">Copyright (C) 2019 ETOOSNEO. All Right Reserved</p>
      <a href="#" class="logo"><span class="blind">이투스 neo</span></a>
    </div>
  </div>
  <div v-else id="footer_neo">
    <div class="ly_inner">
      <div class="link">
        <a href="https://recruit.etoos.com" target="_blank">인재채용</a>
        <a href="https://www.etoos.com/member/yakkwan_new.asp" target="_blank">이용약관</a>
        <a href="https://www.etoos.com/member/individual_new.asp" target="_blank"><strong>개인정보처리방침</strong></a>
      </div>
      <p class="company">(06134) 서울시 강남구 강남대로 94길 30 (역삼동 649-1) <span class="line">|</span> 사업자등록번호 : 505-87-02372 <span class="line">|</span> Tel : 02-3288-9001<span class="line">|</span> Fax : 02-553-0512</p>
      <p>이투스네오1학원 학원등록번호 : 제8570호 교습과정 : 종합-보습·논술, 외국어, 진학상담·지도 | 이투스네오1독서실 학원등록번호 : 제13106호 교습과정 : 독서실<br /></p>
      <p class="company">
        (06134) 서울시 강남구 강남대로 94길 36 (역삼동 649-3)
        <span class="line">|</span> 사업자등록번호 : 264-85-01838, 891-85-02100 <span class="line">|</span> Tel : 02-3288-9001<span class="line">|</span> Fax : 02-553-0512<br />
      </p>
      <p>이투스네오2학원 학원등록번호 : 제12939호 교습과정 : 보습·논술 <span class="line">|</span> 이투스네오2독서실 학원등록번호 : 제12940호 교습과정 : 독서실<br /></p>
      <strong>이투스에듀 주식회사</strong> (06733) 서울시 서초구 남부순환로 2547 (서초동 1354-3) | Tel: 02-552-1230 | Fax: 02-552-1325 | 사업자등록번호 : 505-87-02372 | 통신판매신고번호 : 강남-03036호
      <a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=5058702372" class="fc_000" target="_blank"> 정보조회</a><br />
      대표이사: 정선욱 | 개인정보보호책임자 : 정미상<br />
      Copyright © ETOOS EDUCATION Co.,Ltd. All rights Reserved.
    </div>
  </div>
</template>

<script>
import Footer from '../cheongsol/Footer';
export default {
  name: 'Footera',
  mixins: [Footer],
  props: ['userInfo']
};
</script>
