<template>
  <div id="footer_as">
    <div v-if="userInfo.branchCd === '0130'" class="inner">
      <div class="company_info">
        <ul class="foot_link">
          <li><a href="https://recruit.etoos.com/main/main.do" target="_blank">인재채용</a></li>
          <li>
            <a href="https://www.etoos.com/member/default.asp?returnurl=https://a2-etoosanswer.etoos.com">회원가입</a>
          </li>
          <li><a href="https://www.etoos.com/member/yakkwan_new.asp" target="_blank">이용약관</a></li>
          <li class="policy">
            <a href="https://www.etoos.com/member/individual_new.asp" target="_blank">개인정보처리방침</a>
          </li>
        </ul>
        <p class="info">
          서울시 양천구 오목로 325(목1동 406-21) 4~5층, 7층 | 사업자등록번호 148-85-02118, 622-85-08978 | Tel : 02-2646-2586 | Fax : 02-2608-9002 <br />
          <a href="/info/contact.do">목동이투스앤써학원</a> 학원등록번호 : 제4918호 교습과정 : 종합-보습·논술, 진학상담·지도 | 목동이투스앤써컨설팅학원 학원등록번호 : 제5780호 교습과정 : 진학상담·지도<br />
          <a href="/info/contact.do">목동이투스앤써1독서실</a> 학원등록번호 : 제5781호 교습과정 : 독서실 | 목동이투스앤써2독서실 학원등록번호 : 제5758호 교습과정 : 독서실 |<br />
          <strong>이투스에듀 주식회사</strong> (06733) 서울시 서초구 남부순환로 2547 (서초동 1354-3) | Tel: 02-552-1230 | Fax: 02-552-1325 | 사업자등록번호 : 505-87-02372 | 통신판매신고번호 : 강남-03036호
          <a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=5058702372" class="fc_000" target="_blank"> 정보조회</a><br />
          대표이사: 정선욱 | 개인정보보호책임자 : 정미상<br />
          Copyright © ETOOS EDUCATION Co.,Ltd. All rights Reserved.
          <a href="mailto:answer_web@etoos.com">answer_web@etoos.com</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footera',
  props: ['userInfo']
};
</script>
