<template>
  <div id="historyLayer" :class="isMobile ? 'layer_fullpage' : 'layer_side'" class="history open">
    <div class="layer_head">
      <h4 class="tit_layer">시간표 편성 히스토리</h4>
      <a href="javascript:;" class="layer_close" @click="$emit('close')"><span class="blind">레이어 닫기</span></a>
    </div>
    <div v-if="!isMobile" class="filter">
      <span class="wrap_select register">
        <select class="selbox_comm" v-model="creator">
          <option selected="selected" value="all">등록자</option>
          <option v-for="(selItem, idx) in createId" :key="idx" :value="selItem.createId">
            {{ selItem.userName }}
          </option>
        </select>
      </span>
      <span class="wrap_select category">
        <select class="selbox_comm" v-model="historyData">
          <option value="all">히스토리</option>
          <option v-for="(hisItem, idx) in history" :key="idx" :value="historyVal(hisItem)">
            {{ historySubjt(hisItem) }}
          </option>
        </select>
      </span>
    </div>
    <div class="layer_cont">
      <div class="box_table">
        <table class="tbl_comm" ref="historyGrid">
          <caption class="blind"></caption>
          <colgroup>
            <col style="width:70px;" />
            <col style="width:auto;" />
            <col style="width:74px;" />
          </colgroup>
          <thead>
            <tr>
              <th>등록자</th>
              <th>히스토리</th>
              <th>등록일</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(data, idx) in historyList.slice((pageNum - 1) * pageSize, pageNum * pageSize)"
              :class="{ complete: data.setYn === 'Y', incomplete: data.setYn === 'N' }"
            >
              <td>
                <span class="text name"
                >{{ data.userType === 'T' ? 'Ⓣ' : data.userType === 'A' ? 'Ⓐ' : 'Ⓢ' }}{{ data.userName }}</span
                >
              </td>
              <td>
                <template v-if="data.setYn === ''">
                  <span
                    class="text status"
                    :class="{
                      type1: isAssignStandByTempAssign(data.regstateCd),
                      type2: !isAssignStandByTempAssign(data.regstateCd)
                    }"
                  >{{ getNoneSetText(data) }}</span
                  >
                  <strong class="text teacher">{{ data.teacherName }}</strong>
                  <strong class="text subject" v-html="data.groupName"></strong>
                </template>
                <strong v-else class="stat">{{ data.setYn === 'Y' ? '시간표 완료' : '시간표 미완료' }}</strong>
              </td>
              <td>
                <span class="text date"
                >{{ data.createDate.split(' ')[0] }}<br />{{ data.createDate.split(' ')[1] }}</span
                >
              </td>
            </tr>
          </tbody>
        </table>
        <button v-if="isMobile && btnVisible" type="button" class="btn_moreview" @click="moreData()">
          <span class="inner">더보기</span>
        </button>
      </div>
      <classroom-table-page
        ref="historyPager"
        v-show="!isMobile"
        :total-size="(historyList || []).length"
        :page-num="pageNum"
        :page-list="pageList"
        :page-size="pageSize"
        @set-page="setPage"
        @set-page-list="setPageList"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ClassroomTablePage from '../study/ClassroomTablePage';
import { REGSTATE_CD } from '../../../const/classroom_const';

export default {
  name: 'scheduleHistory',
  components: { ClassroomTablePage },
  data() {
    return {
      data: [],
      createId: [],
      perPage: '10',
      creator: 'all',
      historyData: 'all',
      historyList: [],
      history: [],
      pageNum: 1,
      pageSize: 10,
      pageList: [1],
      branchCd: '',
      seasonCd: '',
      seasonYear: '',
      studentCd: 0,
      moPageNum: 1,
      btnVisible: false,
      moTotalPage: 0,
      pageHeight: 0
    };
  },
  computed: {
    ...mapState({
      studentHistoryList: (state) => state.classroom.schedule.studentHistoryList,
      isMobile: (state) => state.common.isMobile
    })
  },
  watch: {
    creator() {
      this.searchList();
    },
    historyData() {
      this.searchList();
    }
  },
  props: ['historyParam', 'close'],
  created() {
    this.branchCd = this.historyParam.branchCd;
    this.seasonCd = this.historyParam.seasonCd;
    this.seasonYear = this.historyParam.seasonYear;
    this.studentCd = this.historyParam.studentCd;
    if (this.isMobile) {
      this.pageSize = 30;
    }
    this.studentHistory();
  },
  updated() {
    this.setBtnVisible();
  },
  destroyed() {},
  methods: {
    ...mapActions(['getStudentHistory']),
    studentHistory() {
      const params = {};
      params.branchCd = this.branchCd;
      params.seasonCd = this.seasonCd;
      params.seasonYear = this.seasonYear;
      params.studentCd = this.studentCd;

      this.getStudentHistory(params).then(() => {
        const createId = _.chain(this.studentHistoryList)
          .groupBy('userName')
          .map(function(e) {
            return _.first(e);
          })
          .value();
        this.createId = createId;
        const historyRow = [];
        _.chain(this.studentHistoryList)
          .groupBy('regstateCd')
          .map(function(v, k) {
            if (k !== 'null') {
              historyRow.push(_.first(v));
            } else {
              _.chain(v)
                .groupBy('setYn')
                .map(function(vv, kk) {
                  if (kk) {
                    historyRow.push(_.first(vv));
                  }
                })
                .value();
            }
          })
          .value();
        this.history = historyRow;
        this.historyList = this.studentHistoryList;
        this.initPageList();
      });
    },
    historyVal(item) {
      if (item.setYn === '') {
        return item.regstateCd;
      } else {
        return item.setYn;
      }
    },
    historySubjt(item) {
      if (item.setYn === '') {
        if (item.regstateCd === '1') {
          return '신청';
        } else if (item.regstateCd === '2') {
          return '휴강';
        } else if (item.regstateCd === '3') {
          return '탈퇴';
        } else if (item.regstateCd === '4') {
          return '수료';
        } else if (item.regstateCd === '5') {
          return '전반';
        } else if (item.regstateCd === '6') {
          return '대기';
        } else if (item.regstateCd === '7') {
          return '가배정';
        } else {
          return '취소';
        }
      } else {
        if (item.setYn === 'Y') {
          return '시간표 완료';
        } else {
          return '시간표 미완료';
        }
      }
    },
    searchList() {
      let list = [];
      const historyState = this.historyData;
      if (this.creator !== 'all') {
        list = _.groupBy(this.studentHistoryList, 'createId');
        list = list[this.creator];
        if (historyState !== 'all') {
          if (historyState === 'Y' || historyState === 'N') {
            list = _.groupBy(list, 'setYn');
            list = list[historyState];
          } else {
            list = _.groupBy(list, 'regstateCd');
            list = list[historyState];
          }
        }
      } else {
        if (historyState !== 'all') {
          if (historyState === 'Y' || historyState === 'N') {
            list = _.groupBy(this.studentHistoryList, 'setYn');
            list = list[historyState];
          } else {
            list = _.groupBy(this.studentHistoryList, 'regstateCd');
            list = list[historyState];
          }
        } else {
          list = this.studentHistoryList;
        }
      }
      this.historyList = list;
      this.$nextTick(() => {
        this.pageNum = 1;
        this.pageHeight = this.$refs.historyGrid.height - 50;
      });
    },
    moreData() {
      this.moPageNum += 1;
      if (this.moPageNum >= this.$refs.historyPager.totalPages) this.btnVisible = false;

      this.pageSize += 30;
      if (this.$refs.historyPager.totalSize <= this.pageSize) {
        this.pageSize = this.$refs.historyPager.totalSize;
        this.btnVisible = false;
      }
      this.$nextTick(() => {
        this.scrollTop();
      });
    },
    scrollTop() {
      document
        .querySelector('.layer_fullpage')
        .scrollTo({ top: this.pageHeight * (this.moPageNum - 1), behavior: 'auto' });
    },
    setPage(page) {
      this.pageNum = page;
    },
    setPageList(list) {
      this.pageList = list;
    },
    totalPages() {
      let number = Math.floor(((this.historyList || []).length + this.pageSize - 1) / this.pageSize);
      if (number < 1) {
        number = 1;
      }
      return number;
    },
    initPageList() {
      const minPage = Math.floor((this.pageNum - 1) / 5) * 5 + 1,
        tempList = [];
      let maxPage = Math.floor((this.pageNum - 1) / 5) * 5 + 5;
      if (maxPage > this.totalPages()) {
        maxPage = this.totalPages();
      }
      for (let i = minPage; i <= maxPage; i++) {
        tempList.push(i);
      }
      this.pageList = tempList;
    },
    isAssign: REGSTATE_CD.isAssign,
    isStandBy: REGSTATE_CD.isStandBy,
    isTempAssign: REGSTATE_CD.isTempAssign,
    isAssignStandByTempAssign(regstateCd) {
      return this.isAssign(regstateCd) || this.isStandBy(regstateCd) || this.isTempAssign(regstateCd);
    },
    getNoneSetText(data) {
      let text = '';
      if (this.isAssign(data.regstateCd)) {
        text = '신청';
      } else if (this.isStandBy(data.regstateCd)) {
        text = `${data.regstateCdNm}[${data.waitingOrder === null ? '' : data.waitingOrder}번]`;
      } else if (this.isTempAssign(data.regstateCd)) {
        text = '입금대기중';
      } else {
        if (data.regstateCdNm) {
          text = data.regstateCdNm;
        } else {
          text = '취소';
        }
        if (data.cancelReasonCd !== '') {
          text += `[${data.cancelReasonCd}]`;
        }
      }
      return text;
    },
    setBtnVisible() {
      this.btnVisible =
        this.isMobile && this.studentHistoryList.length > 0 && this.moPageNum < this.$refs.historyPager.totalPages;
    }
  }
};
</script>
