import MutationTypes from '@/store/MutationTypes';
import ScheduleApi from '@/api/ScheduleApi';
import { API_CALL_RESULT } from '@/const/const.js';

export default {
  getScheduleList: async ({ commit }, params) => {
    try {
      const result = await ScheduleApi.getScheduleList(params);
      const mapData = {
        seasonYear: params.seasonYear,
        seasonCd: params.seasonCd
      };
      if (result.data && Array.isArray(result.data)) {
        const perdCdMap = {};
        result.data.forEach((item) => {
          if (item.perdCd.indexOf('r') >= 0) {
            return;
          }
          perdCdMap[item.perdCd] = item.exposureNm;
        });
        mapData['data'] = perdCdMap;
        commit('SET_SEASON_PERD_MAP', mapData);
      }
      commit('SET_SCHEDULE_LIST', result.data);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  getSeason: async ({ commit }, params) => {
    try {
      const result = await ScheduleApi.getSeason(params);
      commit('SET_SEASON_LIST', result.data);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  getStudentGroup: async ({ commit }, params) => {
    try {
      const result = await ScheduleApi.getStudentGroup(params);
      commit('SET_GROUP_DATA', result.data);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  StudentSchedule: async ({ commit }, params) => {
    try {
      const result = await ScheduleApi.getStudentSchedule(params);
      commit('SET_STUDENT_SCHEDULE', result.data);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  getStudentHistory: async ({ commit }, params) => {
    try {
      const result = await ScheduleApi.getStudentHistory(params);
      commit('SET_STUDENT_HISTORY', result.data);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  setStatusConfirm: async ({ commit }, params) => {
    try {
      const result = await ScheduleApi.setStatusConfirm(params);
      commit('SET_STATUS_RESULT', result);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  getStudentFirstGroup: async ({ commit }, params) => {
    try {
      const result = await ScheduleApi.getStudentFirstGroup(params);
      if (result.code !== API_CALL_RESULT.SUCCESS) {
        commit(MutationTypes.ERROR, result.data);
        return;
      }
      commit('SET_ASSIGN_GROUP_DATA', result.data.list);
      commit('SET_DEFAULT_SEASON', result.data.default);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  }
};
