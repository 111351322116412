<template >
  <div class="ly_pop delete_pop" :class="{ on: isActive }" v-if="!isMobile">
    <a class="close" @click="close">close</a>
    {{ text }}
    <div class="popbtn_box mgt25">
      <input type="button" value="확인" class="mbtn dark" @click="confirm" />
      <input type="button" value="취소" class="mbtn" @click="close" />
    </div>
  </div>
  <div id="deleteLayer" class="layer_delete" :class="{ open: isActive }" v-else>
    <div class="layer_body">
      <div class="layer_head">
        <a class="layer_close" @click="close"><span class="blind">레이어 닫기</span></a>
      </div>
      <div class="layer_cont">
        <div class="txt">{{ text }}</div>
      </div>
      <div class="layer_foot">
        <button type="button" class="btn_save" @click="confirm">확인</button>
        <button type="button" class="btn_cancel" @click="close">취소</button>
      </div>
    </div>
    <div class="dimmed_layer"></div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

/**
 * 무한패스 확인, 취소 팝업창 컴포넌트
 * @since 2023.03.20
 * @author 유경호 ykh6242@etoos.com
 */
export default {
  name: 'confirm-popup',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: '3개의 강의를 삭제하시겠습니까?'
    }
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile
    }),
    isActive: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  data: () => ({}),
  methods: {
    confirm() {
      this.$emit('confirm');
      this.close();
    },
    close() {
      this.isActive = false;
    }
  }
};
</script>
