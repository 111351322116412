import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = {
  enterAnswerYearInfo: [
    {
      examYy: '2999'
    }
  ],
  enterAnswerExamTabInfo: [
    {
      unityExamDivCd: '',
      unityExamDivNm: ''
    }
  ],
  enterAnswerExamInfo: [
    {
      unityExamSeq: '',
      unityExamNm: ''
    }
  ],
  enterAnswerDtlExamInfo: [
    {
      unityDtlExamSeq: '',
      unityDtlExamNm: ''
    }
  ],
  enterAnswerPossibleExamInfo: [
    {
      unityExamSeq: 0,
      untiyExamDivNm: '',
      unityExamNm: '',
      unityDtlExamSeq: 0,
      unityDtlExamNm: '',
      unityDtlExamTurnOrd: 0,
      unityDtlExamTurnOrdSeq: 0,
      subjtNm: '',
      subjtSeq: 0,
      enterAnswer: '',
      enterWransr: '',
      coransrWransrCauseInpPosbleYn: '',
      answerInpPosbleYn: null
    }
  ],
  selectUnityExamSeq: 0,
  selectUnityDtlExamSeq: 0,
  selectUnityDtlExamTurnOrd: 0,
  selectUnityDtlExamTurnOrdSeq: 0,
  selectUnityExamHighSubjtSeq: 0,
  selectUnityExamSubjtSeq: 0,
  enterAnswerSelectExamInfo: [
    {
      dtlSubjtNm: '',
      subTamYn: '',
      subjtNm: '',
      unityDtlExamNm: '',
      unityDtlExamSeq: 0,
      unityDtlExamTurnOrd: 0,
      unityDtlExamTurnOrdSeq: 0,
      unityExamNm: '',
      unityExamSeq: 0,
      unityExamSubjtSeq: 0
    }
  ],
  enterAnswerExamQustnInfo: [],
  enterAnswerExamQustnTamInfo: []
};

export default {
  state,
  getters,
  actions,
  mutations
};
