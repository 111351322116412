export default {
  SET_SEASON_LIST: (state, payload) => {
    state.schedule.seasonList = payload;
  },
  SET_SEASON_DATA: (state, payload) => {
    state.schedule.seasonData = payload;
  },
  SET_STATUS_RESULT: (state, payload) => {
    state.schedule.statusResult = payload;
  },
  SET_STUDENT_SCHEDULE: (state, payload) => {
    state.schedule.studentScheduleList = payload;
  },
  SET_SCHEDULE_LIST: (state, payload) => {
    state.schedule.scheduleList = payload;
  },
  SET_STUDENT_HISTORY: (state, payload) => {
    state.schedule.studentHistoryList = payload;
  },
  SET_GROUP_DATA: (state, payload) => {
    state.schedule.studentGroup = payload;
  },
  SET_SEASON_PERD_MAP: (state, payload) => {
    const seasonYear = payload.seasonYear,
      seasonCd = payload.seasonCd,
      map = payload.data;
    let mSeasonYear = state.schedule.seasonPerdMap[seasonYear];
    if (!mSeasonYear) {
      mSeasonYear = {};
    }
    mSeasonYear[seasonCd] = map;
    state.schedule.seasonPerdMap[seasonYear] = mSeasonYear;
  },
  SET_ASSIGN_GROUP_DATA: (state, payload) => {
    state.schedule.listByYear = payload;
  },
  SET_DEFAULT_SEASON: (state, payload) => {
    state.schedule.defaultSeason = payload;
  }
};
