import Vue from 'vue';
import VueRouter from 'vue-router';
import DailyTestMain from '@/components/dailytest/main/DailyTestMain';
import AnalysisResult from '@/components/dailytest/analysisResult/AnalysisResult';
import ScheduleMain from '@/components/classroom/schedule/ScheduleMain';
import StudyMain from '@/components/classroom/study/StudyMain';
import LectureSearchMainM from '@/components/classroom/lecture/LectureSearchMainM';
import schedulePrintPopup from '@/components/classroom/schedule/SchedulePrintPopup';
import lectureCheckP from '@/components/classroom/lecture/LectureCheckP';
import lectureCheckM from '@/components/classroom/lecture/LectureCheckM';
import lectureSearchP from '@/components/classroom/lecture/LectureSearchP';
import lectureDetailP from '@/components/classroom/lecture/LectureDetailP';
import lectureDetailM from '@/components/classroom/lecture/LectureDetailM';
import replayLecture from '@/components/replaylecture/ReplayLecture';
import replayLectureDetail from '@/components/replaylecture/ReplayTurnOrdLecturePopupM';
import Common from '@/components/classroom/staticPage';
import MathContView from '@/components/dailytest/main/MathContView';
/* 오답원인분석 */
import regularTest from '@/components/unityexam/regulartest';
import acaContents from '@/components/unityexam/acacontents';
import subjtAnalyze from '@/components/unityexam/subjtanalyze';
import unityExamWransNote from '@/components/unityexam/wransnote';
import unityExamEnterAnswer from '@/components/unityexam/enteranswer';
import unityExamEnterAnswerPopup from '@/components/unityexam/common/popup/enteranswerpopup';
import unityExamViewQustnPopup from '@/components/unityexam/common/popup/viewqustnpopup';
import unityExamWranNotePopup from '@/components/unityexam/common/popup/wrannotepopup';
import unityExamViewQustnByExamPopup from '@/components/unityexam/common/popup/viewqustnByExamPopup.vue';
import unityExamWranNotePrintPopup from '@/components/unityexam/common/popup/wrannoteprintpopup';
import unityExamViewQustnByTypePopup from '@/components/unityexam/common/popup/viewqustnByTypePopup';
import unityExamViewQustnPrintPopup from '@/components/unityexam/common/popup/viewqustnprintpopup';

/* 현장강의 무한패스 */
import InfinitePassMain from '@/components/infinitePass/InfinitePassMain';
import allLectureView from '@/components/infinitePass/AllLectureView';
import TodayLectureView from '@/components/infinitePass/TodayLectureView';
import OngoingLectureView from '@/components/infinitePass/OngoingLectureView.vue';
import PassContView from '@/components/infinitePass/PassContView.vue';
// import CompleteLectureView from '@/components/infinitePass/CompleteLectureView.vue'; // ACAPJ-3319 2023-04-11 진도율 숨김 -->
import interestedLectureView from '@/components/infinitePass/InterestedLectureView';

import { APPLICATION_PHASE, MY_PAGE_URL } from '@build/build.config';

/* 학습관리 */
import studyTimeManagement from '@/components/studyManagement/studyTimeManagement/StudyTimeManagement';

// import store from '../store';

import CommonApi from '@/api/CommonApi';
import queueingView from '@/components/queueing/QueueingView.vue';

Vue.use(VueRouter);

//학생 본인 지점 도메인으로 리다이렉트 처리
async function redirectToMyBranchURL(next, toUrl) {
  let personVo = {};
  let branchType = 'cs';

  try {
    const localStorageStore = JSON.parse(localStorage.vuex);
    personVo = localStorageStore.common.userInfo.personVo;
  } catch (e) {
    const response = await CommonApi.getUserInfo();
    personVo = response.data.personVo;
  }

  const myUrl = MY_PAGE_URL[personVo.branchCd || '0010'];

  if (myUrl.includes('hyper')) {
    branchType = 'hp';
  } else if (myUrl.includes('gisuk')) {
    branchType = 'eg';
  }

  if (
    (window.location.hostname.includes('hyper') && branchType === 'hp') ||
    (window.location.hostname.includes('cheongsol') && branchType === 'cs') ||
    (!window.location.hostname.includes('hyper-gisuk') && window.location.hostname.includes('gisuk') && branchType === 'eg')
  ) {
    next();
  } else if (
    (!window.location.hostname.includes('hyper') && branchType === 'hp') ||
    (!window.location.hostname.includes('cheongsol') && branchType === 'cs') ||
    (!window.location.hostname.includes('hyper-gisuk') && !window.location.hostname.includes('gisuk') && branchType === 'eg')
  ) {
    window.location.href = myUrl + toUrl;
  } else {
    next();
  }
}

async function checkMathDMPermission(next) {
  let isAuthenticated = false;
  let personVo = {};
  try {
    const localStorageStore = JSON.parse(localStorage.vuex);
    personVo = localStorageStore.common.userInfo.personVo;
    if (personVo.userAuthority === 'STUDENT') {
      isAuthenticated = personVo.newDailyTestService;
    }
    if (isAuthenticated) {
      next();
    } else {
      next('/math-cont');
    }
  } catch (e) {
    const response = await CommonApi.getUserInfo();
    personVo = response.data.personVo;
    if (personVo.userAuthority === 'STUDENT') {
      isAuthenticated = personVo.newDailyTestService;
    }
    if (isAuthenticated) {
      next();
    } else {
      next('/math-cont');
    }
  }
}

async function checkInfinitepassPermission(next) {
  let isAuthenticated = false;
  let personVo = {};
  try {
    const localStorageStore = JSON.parse(localStorage.vuex);
    personVo = localStorageStore.common.userInfo.personVo;
    if (personVo.userAuthority === 'STUDENT') {
      isAuthenticated = personVo.stdTypeCd === '1';
    }
    if (isAuthenticated) {
      next();
    } else {
      next('/infinite-pass/pass-cont');
    }
  } catch (e) {
    const response = await CommonApi.getUserInfo();
    personVo = response.data.personVo;
    if (personVo.userAuthority === 'STUDENT') {
      isAuthenticated = personVo.stdTypeCd === '1';
    }
    if (isAuthenticated) {
      next();
    } else {
      next('/infinite-pass/pass-cont');
    }
  }
}

const routes = [
  {
    path: '/',
    redirect: { name: 'studyMain' }
  },
  {
    path: '/main',
    name: 'DailyTestMain',
    component: DailyTestMain,
    meta: {
      layout: 'MyPageLayout',
      mobileLayout: 'MobileLayout',
      id: 'container',
      menuGroup: 'mathDaily',
      evictClassroomCss: true
    },
    beforeEnter: (to, from, next) => {
      checkMathDMPermission(next);
    }
  },
  {
    path: '/math-daily/test',
    name: 'mathDailyTest',
    component: unityExamEnterAnswerPopup,
    meta: { id: 'mathpop', evictClassroomCss: true, mobileLayout: 'DefaultLayout' }
  },
  {
    path: '/math-daily/analysis',
    name: 'AnalysisResult',
    component: AnalysisResult,
    meth: { id: 'methpop', evictClassroomCss: true }
  },
  {
    path: '/math-daily/result',
    name: 'mathDailyTestResult',
    component: unityExamViewQustnByExamPopup,
    meta: { id: 'methpop' }
  },
  //classroom
  {
    path: '/schedulemain',
    name: 'schedulemain',
    component: ScheduleMain,
    meta: { layout: 'MyPageLayout', mobileLayout: 'MobileLayout', id: 'container', menuGroup: 'enrolment' },
    beforeEnter: (to, from, next) => {
      redirectToMyBranchURL(next, '/schedulemain');
    }
  },
  {
    path: '/studyMain',
    name: 'studyMain',
    component: StudyMain,
    meta: { layout: 'MyPageLayout', mobileLayout: 'MobileLayout', id: 'container', menuGroup: 'studySchedule' }
  },
  {
    path: '/lectureSearchMainM',
    name: 'lectureSearchMainM',
    component: LectureSearchMainM,
    meta: { layout: 'MyPageLayout', mobileLayout: 'MobileLayout', id: 'container' }
  },
  {
    path: '/classroom/printPopup',
    name: 'schedulePrintPopup',
    component: schedulePrintPopup
  },
  {
    path: '/lectureCheckP',
    name: 'lectureCheckP',
    component: lectureCheckP,
    meta: { id: 'wrapper' }
  },
  {
    path: '/lectureSearchP',
    name: 'lectureSearchP',
    component: lectureSearchP,
    meta: { id: 'wrapper' }
  },
  {
    path: '/lectureCheckM',
    name: 'lectureCheckM',
    component: lectureCheckM,
    meta: { id: 'wrapper' }
  },
  {
    path: '/lectureDetailP',
    name: 'lectureDetailP',
    component: lectureDetailP
  },
  {
    path: '/lectureDetailM',
    name: 'lectureDetailM',
    component: lectureDetailM
  },
  /* 오답원인분석 */
  {
    path: '/unityexam/regulartest',
    name: 'regularTest',
    component: regularTest,
    meta: {
      layout: 'MyPageLayout',
      mobileLayout: 'MobileLayout',
      id: 'container',
      menuGroup: 'examManagement',
      evictClassroomCss: true
    }
  },
  {
    path: '/unityexam/acacontents',
    name: 'acaContents',
    component: acaContents,
    meta: {
      layout: 'MyPageLayout',
      mobileLayout: 'MobileLayout',
      id: 'container',
      menuGroup: 'examManagement',
      evictClassroomCss: true
    }
  },
  {
    path: '/unityexam/subjtanalyze',
    name: 'subjtAnalyze',
    component: subjtAnalyze,
    meta: { layout: 'MyPageLayout', mobileLayout: 'MobileLayout', id: 'container', menuGroup: 'examManagement' }
  },
  {
    path: '/unityexam/wransnote',
    name: 'unityExamWransNote',
    component: unityExamWransNote,
    meta: {
      layout: 'MyPageLayout',
      mobileLayout: 'MobileLayout',
      id: 'container',
      menuGroup: 'examManagement',
      evictClassroomCss: true
    }
  },
  {
    path: '/unityexam/enteranswer',
    name: 'unityExamEnterAnswer',
    component: unityExamEnterAnswer,
    meta: {
      layout: 'MyPageLayout',
      mobileLayout: 'MobileLayout',
      id: 'container',
      menuGroup: 'examManagement',
      evictClassroomCss: true
    }
  },
  {
    path: '/unityexam/common/enteranswerpopup',
    name: 'unityExamEnterAnswerPopup',
    component: unityExamEnterAnswerPopup,
    meta: { id: 'unityexam' }
  },
  {
    path: '/unityexam/common/viewqustnpopup',
    name: 'unityExamViewQustnPopup',
    component: unityExamViewQustnPopup,
    meta: { id: 'unityexam' }
  },
  {
    path: '/unityexam/common/viewqustnprintpopup',
    name: 'unityExamViewQustnPrintPopup',
    component: unityExamViewQustnPrintPopup,
    meta: { id: 'unityexam' }
  },
  {
    path: '/unityexam/common/wrannotepopup',
    name: 'unityExamWranNotePopup',
    component: unityExamWranNotePopup,
    meta: { mobileLayout: 'DefaultLayout', id: 'mathpop', evictClassroomCss: true }
  },
  {
    path: '/unityexam/common/wrannoteprintpopup',
    name: 'unityExamWranNotePrintPopup',
    component: unityExamWranNotePrintPopup,
    meta: { id: 'unityexam' }
  },
  {
    path: '/unityexam/common/viewqustntype',
    name: 'unityExamViewQustnByTypePopup',
    component: unityExamViewQustnByTypePopup,
    meta: { id: 'unityexam' }
  },
  {
    path: '/unityexam/common/viewqustnexam',
    name: 'unityExamViewQustnByExamPopup',
    component: unityExamViewQustnByExamPopup,
    meta: { id: 'unityexam', evictClassroomCss: true }
  },
  /* 학습 시간 관리 */
  {
    path: '/study/timeManagement',
    name: 'studyTimeManagement',
    component: studyTimeManagement,
    meta: { layout: 'MyPageLayout', mobileLayout: 'MobileLayout', id: 'container', menuGroup: 'studyManagement' }
  },
  {
    path: '/replaylecture',
    name: 'replayLecture',
    component: replayLecture,
    meta: { layout: 'MyPageLayout', mobileLayout: 'MobileLayout', id: 'container', menuGroup: 'replayLecture' }
  },
  {
    path: '/replaylecture/detail',
    name: 'replayLectureDetail',
    component: replayLectureDetail,
    meta: { layout: 'MyPageLayout', mobileLayout: 'MobileLayout', id: 'container', menuGroup: 'replayLecture' }
  },
  {
    path: '/infinite-pass',
    name: 'InfinitePassMain',
    component: InfinitePassMain,
    meta: { layout: 'MyPageLayout', mobileLayout: 'MobileLayout', id: 'container', menuGroup: 'infinitePass' },
    beforeEnter: (to, from, next) => {
      checkInfinitepassPermission(next);
    }
  },
  {
    path: '/infinite-pass/all',
    name: 'allLectureView',
    component: allLectureView,
    meta: { layout: 'MyPageLayout', mobileLayout: 'MobileLayout', id: 'container', menuGroup: 'infinitePass' },
    beforeEnter: (to, from, next) => {
      checkInfinitepassPermission(next);
    }
  },
  {
    path: '/infinite-pass/today',
    name: 'TodayLectureView',
    component: TodayLectureView,
    meta: { layout: 'MyPageLayout', mobileLayout: 'MobileLayout', id: 'container', menuGroup: 'infinitePass' },
    beforeEnter: (to, from, next) => {
      checkInfinitepassPermission(next);
    }
  },
  {
    path: '/infinite-pass/ongoing',
    name: 'OngoingLectureView',
    component: OngoingLectureView,
    meta: { layout: 'MyPageLayout', mobileLayout: 'MobileLayout', id: 'container', menuGroup: 'infinitePass' },
    beforeEnter: (to, from, next) => {
      checkInfinitepassPermission(next);
    }
  },
  {
    path: '/infinite-pass/pass-cont',
    name: 'PassContView',
    component: PassContView,
    meta: { layout: 'MyPageLayout', mobileLayout: 'MobileLayout', id: 'container', menuGroup: 'infinitePass' }
  },
  {
    path: '/math-cont',
    name: 'MathContView',
    component: MathContView,
    meta: {
      layout: 'MyPageLayout',
      mobileLayout: 'MobileLayout',
      id: 'container',
      menuGroup: 'mathDaily',
      evictClassroomCss: true
    }
  },
  // { // ACAPJ-3319 2023-04-11 진도율 숨김 -->
  //   path: '/infinite-pass/complete',
  //   name: 'CompleteLectureView',
  //   component: CompleteLectureView,
  //   meta: { layout: 'MyPageLayout', mobileLayout: 'MobileLayout', id: 'container', menuGroup: 'infinitePass' }
  // },
  {
    path: '/infinite-pass/interested',
    name: 'interestedLectureView',
    component: interestedLectureView,
    meta: { layout: 'MyPageLayout', mobileLayout: 'MobileLayout', id: 'container', menuGroup: 'infinitePass' },
    beforeEnter: (to, from, next) => {
      checkInfinitepassPermission(next);
    }
  },
  {
    path: '/queueing',
    name: 'queueingView',
    component: queueingView,
    meta: { layout: 'MyPageLayout', mobileLayout: 'MobileLayout', id: 'container' }
  }
];

if (APPLICATION_PHASE !== 'REAL') {
  routes.push({
    path: '/static/common',
    name: 'static/common',
    component: Common
  });
}

const router = new VueRouter({
  mode: 'history',
  routes
});

// function convertCookieToObject(cookies) {
//   const cookieItems = cookies.split(';');
//
//   const obj = {};
//   cookieItems.forEach((item) => {
//     // '='으로 분리
//     const elem = item.split('=');
//     // 키 가져오기
//     const key = elem[0].trim();
//     // 값 가져오기
//     const val = decodeURIComponent(elem[1]);
//     // 저장
//     obj[key] = val;
//   });
//   return obj;
// }

// async function getEtoosMemNo() {
//   const obj = convertCookieToObject(document.cookie);
//   const etoosMemNo = decodeURIComponent(atob(obj.etssso))
//     .replaceAll('"', '')
//     .split(',')[1]
//     .split(':')[1];
//   return etoosMemNo;
// }

// router.beforeEach(async (to, from, next) => {
//   const params = {
//     etoosMemNo: await getEtoosMemNo()
//   };
//   try {
//     const response = await CommonApi.getQueueing(params);
//     const data = response.data;
//
//     if (data.pass == false && to.path != '/queueing') {
//       next('/queueing?etoosMemNo=' + data.etoosMemNo + '&time=' + data.time + '&path=' + to.path);
//     } else {
//       next(); // 페이지 이동 계속 진행
//     }
//   } catch (error) {
//     await CommonApi.getUserInfo();
//     const response = await CommonApi.getQueueing(params);
//     const data = response.data;
//
//     if (data.pass == false && to.path != '/queueing') {
//       next('/queueing?etoosMemNo=' + data.etoosMemNo + '&time=' + data.time + '&path=' + to.path);
//     } else {
//       next(); // 페이지 이동 계속 진행
//     }
//   }
// });
export default router;
