<template>
  <div v-show="isCssLoaded" id="wrap">
    <component :is="headerName" :userInfo="userInfo" :menuName="menuName" :isPrev="isPrev" />
    <div id="container" class="mobile_view">
      <slot></slot>
    </div>
    <a href="#" class="btn_top">
      <span><em class="blind">TOP</em></span>
    </a>
    <component :is="footerName" :userInfo="userInfo" />
  </div>
</template>

<script>
import MyPageHyperHeader from '../layout/MyPage/hyper/mobile/Header';
import MyPageHyperFooter from '../layout/MyPage/hyper/mobile/Footer';
import MyPageAnswerHeader from '../layout/MyPage/answer/mobile/Header';
import MyPageAnswerFooter from '../layout/MyPage/answer/mobile/Footer';
import MyPageCheongsolHeader from '../layout/MyPage/cheongsol/mobile/Header';
import MyPageCheongsolFooter from '../layout/MyPage/cheongsol/mobile/Footer';
import MyPageNeoneoHeader from '../layout/MyPage/neoneo/mobile/Header';
import MyPageNeoneoFooter from '../layout/MyPage/neoneo/mobile/Footer';
import RightNavi from '../layout/MyPage/RightNavi';

import { MYPAGE_HEADER_NAME, MYPAGE_FOOTER_NAME } from '@/const/const';
import { FAVICON, PAGE_TITLE } from '@/const/const';
import { AWS_S3_RESOURCE_URL } from '@build/build.config';
import { mapState } from 'vuex';
import { getBrandType } from '../../utils/util';
import crypto from 'crypto';

export default {
  name: 'MobileLayout',
  components: {
    MyPageAnswerHeader,
    MyPageAnswerFooter,
    MyPageHyperHeader,
    MyPageCheongsolHeader,
    RightNavi,
    MyPageHyperFooter,
    MyPageCheongsolFooter,
    MyPageNeoneoHeader,
    MyPageNeoneoFooter
  },
  data() {
    return {
      headerName: MYPAGE_HEADER_NAME[getBrandType(location.href)],
      footerName: MYPAGE_FOOTER_NAME[getBrandType(location.href)],
      PAGE_TITLE,
      isCssLoaded: false,
      cssFileName: 'buefy_datepicker.css', //MyPageLayout.vue, MobileLayout.vue 제일 마지막 호출, 제일 큰 파일
      checkInterval: 100 // 100ms 간격으로 확인
    };
  },
  watch: {
    userInfo() {
      //this.changeTitle();
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.common.userInfo,
      menuName: (state) => state.common.menuName,
      isPrev: (state) => state.common.isPrev
    })
  },
  methods: {
    changeTitle() {
      const favicon = document.getElementById('favicon');
      let faviconUrl = '';
      let title = '';
      if (this.userInfo.branchCd === '') {
        faviconUrl = FAVICON['0020'];
        title = PAGE_TITLE['0020'];
      } else if (this.userInfo.branchCd === '0600' && this.userInfo.metaSubDomain === 'n1') {
        faviconUrl = FAVICON['0020'];
        title = '이투스네오';
      } else {
        faviconUrl = FAVICON[this.userInfo.branchCd];
        title = PAGE_TITLE[this.userInfo.branchCd];
      }

      favicon.href = AWS_S3_RESOURCE_URL + '/my' + faviconUrl;
      document.title = title;
    },
    isCssFileLoaded() {
      const styleSheets = document.styleSheets;

      for (let i = 0; i < styleSheets.length; i++) {
        const styleSheet = styleSheets[i];

        if (styleSheet.href && styleSheet.href.includes(this.cssFileName)) {
          return true;
        }
      }

      return false;
    },
    checkCssFileLoaded() {
      const check = () => {
        if (this.isCssFileLoaded()) {
          this.isCssLoaded = true;
        } else {
          if (!this.isCssLoaded) {
            setTimeout(check, this.checkInterval);
          }
        }
      };
      check();
    }
  },
  created() {
    //this.changeTitle();
    this.checkCssFileLoaded();
  }
  /*mounted() {
    const user_id = crypto
      .createHash('sha256')
      .update(this.userInfo.personVo.unityMemNo)
      .digest('base64');
    console.log(user_id);
    this.$gtag.config({ user_id: 'qa - ' + user_id });
  }*/
};
</script>
