<template>
  <div class="popup_cont popup_ansr" :class="{ mobile: isMobile }">
    <div class="popup_head">
      <h1 class="tit_popup">문항보기</h1>
      <div class="switch_cont">
        <button
          type="button"
          id="switch1"
          class="btn_type"
          :class="{ on: contentView == 'type1' }"
          @click="contentView = 'type1'"
        >
          문항만
        </button>
        <button
          type="button"
          id="switch2"
          class="btn_type"
          :class="{ on: contentView == 'type2' }"
          @click="contentView = 'type2'"
        >
          문항+해설
        </button>
      </div>
      <div class="expander">
        <div class="exam_subject">
          <span class="txt_subject">{{ this.enterAnswerSelectExamInfo[0].unityExamNm }}</span>
          <span class="txt_subject">{{ this.enterAnswerSelectExamInfo[0].unityDtlExamNm }}</span>
          <span class="txt_subject">{{ this.enterAnswerSelectExamInfo[0].unityDtlExamTurnOrd }}회</span>
        </div>
      </div>
      <!-- <button type="button" class="btn_popup_close mo_only">닫기</button> -->
    </div>
    <div class="popup_body">
      <div v-if="qustnList.length > 0" class="wrap_exam_paper type2">
        <div class="paper_info" :class="{ dimmed: isOpenQuestionNumber, active: isActive }">
          <!--//class active추가하면 문항보기 영역 보임 -->
          <h2 class="tit_paper mo_only">문항보기</h2>
          <button type="button" class="btn_popup_close" @click="windowCloseSelf()">닫기</button>
          <table class="tbl_comm">
            <caption>
              문항 정보
            </caption>
            <colgroup>
              <col style="width:90px" />
              <col style="width:auto" />
              <col style="width:90px" />
              <col style="width:80px" />
              <col style="width:90px" />
              <col style="width:80px" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">문항분류</th>
                <td v-if="qustnList[activeLiIndex][activeIndex].qustnPubrNm === null" class="data_cont">
                  -
                </td>
                <td v-else class="data_cont">
                  {{ qustnList[activeLiIndex][activeIndex].qustnPubrNm }} >
                  {{ qustnList[activeLiIndex][activeIndex].qustnLuntNm }} >
                  {{ qustnList[activeLiIndex][activeIndex].qustnSbptNm }} >
                  {{ qustnList[activeLiIndex][activeIndex].qustnSmunitNm }}
                </td>
                <th scope="row">오답률</th>
                <td>
                  {{
                    qustnList[activeLiIndex][activeIndex].coransrRt === null
                        ? '-'
                        : Number(100 - qustnList[activeLiIndex][activeIndex].coransrRt).toFixed(1) + '%'
                  }}
                </td>
                <th scope="row">해설영상</th>
                <td>
                  <button
                    type="button"
                    v-show="qustnList[activeLiIndex][activeIndex].explCurriVodPathwy"
                    @click="showExplVod(qustnList[activeLiIndex][activeIndex].explCurriVodPathwy, qustnList[activeLiIndex][activeIndex].qustnInpMethodCd)"
                    class="btn_vod"
                  >
                    보기
                  </button>
                  <a v-if="!qustnList[activeLiIndex][activeIndex].explCurriVodPathwy">-</a>
                </td>
              </tr>
              <tr>
                <th scope="row">{{ qustnList[activeLiIndex][activeIndex].coransrYn === 'Y' ? '정답원인' : '오답원인' }}</th>
                <td colspan="5" class="data_cont">
                  <div class="type_check">
                    <label
                      v-for="(item, idx) in qustnList[activeLiIndex][activeIndex].coransrYn === 'Y'
                        ? answerCause
                        : wransrCause"
                      @change="saveCause(item.causeData)"
                      :key="idx"
                      class="lab_radio"
                      :for="'typeRadio' + idx"
                    >
                      <input
                        v-if="item.causeLabel !== '기타'"
                        type="radio"
                        :id="'typeRadio' + idx"
                        :checked="
                          item.causeData === qustnList[activeLiIndex][activeIndex].wransrCauseCd ||
                            item.causeData === qustnList[activeLiIndex][activeIndex].coransrCauseCd
                        "
                        class="inp_radio"
                        name="typeRadio"
                        :disabled="USER_AUTHORITY.PARENT === userInfo.userAuthority"
                      />
                      <input
                        v-if="item.causeLabel === '기타'"
                        @click="isShowInputReasonLayer = true"
                        type="radio"
                        :id="'typeRadio' + idx"
                        :checked="
                          item.causeData === qustnList[activeLiIndex][activeIndex].wransrCauseCd ||
                            item.causeData === qustnList[activeLiIndex][activeIndex].coransrCauseCd
                        "
                        class="inp_radio"
                        name="typeRadio"
                        :disabled="USER_AUTHORITY.PARENT === userInfo.userAuthority"
                      />
                      <span class="ico_radio"></span>
                      {{ item.causeLabel }}
                      <span
                        v-if="
                          item.causeLabel === '기타' &&
                            qustnList[activeLiIndex][activeIndex].coransrWransrNotesEtcOpninCont !== null
                        "
                        class="txt_reason"
                        >({{ qustnList[activeLiIndex][activeIndex].coransrWransrNotesEtcOpninCont }})</span
                      >
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="nav_num mo_only" :class="{ open: isOpenQuestionNumber }">
            <!-- open -->
            <button type="button" class="btn_open" @click="isOpenQuestionNumber = !isOpenQuestionNumber" :disabled="qustnList[activeLiIndex].length < 6 && categories.length < 2">
              열기/닫기
            </button>
            <ul v-if="categories.length < 2" class="list_number">
              <li
                v-for="(item, index) in qustnList[activeLiIndex]"
                v-show="isOpenQuestionNumber || isDisplayIndex(index)"
                :key="index"
                :class="{ on: isActiveOn === index }"
              >
                <a
                  href="javascript:;"
                  class="num_order"
                  :class="{ wransr: item.coransrYn !== 'Y' }"
                  @click="toggleActive(0, index)"
                >
                  {{ index + 1 }}
                </a>
              </li>
            </ul>
            <ul v-else class="list_number">
              <li
                v-for="(item, index) in qustnList[0]"
                :key="index"
                v-show="isOpenQuestionNumber || isDisplayIndex(index)"
                :class="{ on: isActiveOn === index }"
              >
                <a
                  href="javascript:;"
                  class="num_order"
                  :class="{ wransr: item.coransrYn !== 'Y' }"
                  @click="toggleActive(0, index)"
                >
                  {{ index + 1 }}
                </a>
              </li>
              <li
                v-for="(item, index) in qustnList[1]"
                :key="index"
                v-show="isOpenQuestionNumber || isDisplayIndex(index)"
                :class="{ on: isActiveTamOn === index }"
              >
                <a
                  href="javascript:;"
                  class="num_order"
                  :class="{ wransr: item.coransrYn !== 'Y' }"
                  @click="toggleActive(1, index)"
                >
                  {{ index + 1 }}
                </a>
              </li>
            </ul>
          </div>
          <div class="tab_exam">
            <ul class="list_tab">
              <li :class="{ on: contentView == 'type1' }">
                <button type="button" class="btn_tab" @click="contentView = 'type1'">
                  문항
                </button>
              </li>
              <li :class="{ on: contentView == 'type2' }">
                <button type="button" class="btn_tab" @click="contentView = 'type2'">
                  해설
                </button>
              </li>
            </ul>
          </div>
          <div class="exam_cont">
            <div class="box_question" :class="{ on: contentView == 'type1' }">
              <strong class="blind">지문/문항</strong>
              <div v-if="StringUtils.isNotEmpty((qustnList[activeLiIndex][activeIndex] || {}).textImgPathwy) && qustnList[activeLiIndex][activeIndex].qustnInpMethodCd === '1'">
                <img :src="awsResourceUrl + '/unity-exam/' + qustnList[activeLiIndex][activeIndex].textImgPathwy" alt="" />
              </div>
              <div v-if="StringUtils.isNotEmpty((qustnList[activeLiIndex][activeIndex] || {}).textImgPathwy) && qustnList[activeLiIndex][activeIndex].qustnInpMethodCd === '2'">
                <img :src="getTmsImg(qustnList[activeLiIndex][activeIndex].textImgPathwy)" alt="" />
              </div>
              <div v-if="StringUtils.isNotEmpty((qustnList[activeLiIndex][activeIndex] || {}).qustnImgPathwy)">
                <img
                  v-if="qustnList[activeLiIndex][activeIndex].qustnInpMethodCd === '1'"
                  :src="awsResourceUrl + '/unity-exam/' + qustnList[activeLiIndex][activeIndex].qustnImgPathwy"
                  alt=""
                />
                <img
                  v-if="qustnList[activeLiIndex][activeIndex].qustnInpMethodCd === '2'"
                  :src="getTmsImg(qustnList[activeLiIndex][activeIndex].qustnImgPathwy)"
                  alt=""
                />
              </div>
              <div v-else>
                <p class="blank">문항이미지가 없습니다.</p>
              </div>
            </div>
            <div class="box_explain" :class="{ on: contentView == 'type2' }">
              <strong class="blind">해설</strong>
              <div v-if="StringUtils.isNotEmpty((qustnList[activeLiIndex][activeIndex] || {}).explImgPathwy)">
                <img
                  v-if="qustnList[activeLiIndex][activeIndex].qustnInpMethodCd === '1'"
                  :src="awsResourceUrl + '/unity-exam/' + qustnList[activeLiIndex][activeIndex].explImgPathwy"
                  alt=""
                />
                <img
                  v-if="qustnList[activeLiIndex][activeIndex].qustnInpMethodCd === '2'"
                  :src="getTmsImg(qustnList[activeLiIndex][activeIndex].explImgPathwy)"
                  alt=""
                />
              </div>
              <div v-else>
                <p class="blank">해설이미지가 없습니다.</p>
              </div>
            </div>
            <button
              v-show="this.activeIndex !== 0"
              type="button"
              @click="qustnPrev"
              class="btn_prev"
            >
              이전
            </button>
            <button
              v-show="this.activeIndex + 1 !== this.qustnList[this.activeLiIndex].length"
              type="button"
              @click="qustnNext"
              class="btn_next"
            >
              다음
            </button>
            <div class="bar_bottom_tool mo_only">
              <select class="select_ansr_type" @change="showInputReasonLayer" v-model="reasonWrongAnswer" :disabled="USER_AUTHORITY.PARENT === userInfo.userAuthority">
                <option v-if="qustnList[activeLiIndex][activeIndex].coransrYn === 'Y'" value="">정답원인선택</option>
                <option v-if="qustnList[activeLiIndex][activeIndex].coransrYn === 'N'" value="">오답원인선택</option>
                <option
                  v-for="(item, idx) in qustnList[activeLiIndex][activeIndex].coransrYn === 'Y'
                    ? answerCause
                    : wransrCause"
                  :key="idx"
                  :value="item.causeData"
                  >{{ item.causeLabel }} {{ item.causeLabel === '기타' &&
                    qustnList[activeLiIndex][activeIndex].coransrWransrNotesEtcOpninCont !== null
                      ? '(' + qustnList[activeLiIndex][activeIndex].coransrWransrNotesEtcOpninCont + ')'
                      : ''
                  }}</option
                >
              </select>
              <div class="check_review">
                <label class="lab_check" for="checkReview">
                  <input
                    type="checkbox"
                    id="checkReview"
                    class="inp_check"
                    name="checkReview"
                    @change="
                      changeRegiWransrNote(
                        qustnList[activeLiIndex][activeIndex].unityExamQustnSeq,
                        qustnList[activeLiIndex][activeIndex].bystdntUnityExamQustnInpStusSeq,
                        $event
                      )
                    "
                    :checked="qustnList[activeLiIndex][activeIndex].coransrNotesYn === 'Y'"
                  />
                  <span class="ico_check"></span>
                  오답노트
                </label>
              </div>
            </div>
            <button
              v-show="StringUtils.isNotEmpty((qustnList[activeLiIndex][activeIndex] || {}).explCurriVodPathwy) && contentView === 'type2'"
              @click="showExplVod(qustnList[activeLiIndex][activeIndex].explCurriVodPathwy, qustnList[activeLiIndex][activeIndex].qustnInpMethodCd)"
              type="button"
              class="btn_vod_play"
            >
              해설영상<br />보기
            </button>
          </div>
        </div>
        <template v-if="questionViewType == 1">
          <!-- 문항보기-학생 -->
          <div class="exam_sheet">
            <aside-list
              ref="aside_list_qustn_view"
              v-for="(item, index) in categories"
              :title="item.dtlSubjtNm"
              :subjtNm="item.subjtNm"
              :active="index === activeLiIndex"
              :index="index"
              :key="item.dtlSubjtNm"
              :questionViewType="questionViewType"
              @newactive="activeLiIndex = $event"
              :Limits="qustnList[index]"
              :orgScr="item.orgScr"
              @isShowQuestionInfo="isShowInputReasonLayer = true"
              @questionViews="isActive = true"
              @qustnPrev="qustnPrev()"
              @qustnNext="qustnNext()"
              @qustnIndex="qustnIndex"
              @changeRegiWransrNote="changeRegiWransrNote"
            />
          </div>
          <!-- //문항보기-학생 -->
        </template>
        <template v-if="questionViewType == 2">
          <!-- 문항보기-과목별 -->
          <div class="exam_sheet">
            <div class="single">
              <aside-list
                :active="0 === activeLiIndex"
                :questionViewType="questionViewType"
                :Limits="qustnList[index]"
                @newactive="activeLiIndex = $event"
                @isShowQuestionInfo="isShowInputReasonLayer = true"
                @qustnIndex="qustnIndex"
              />
            </div>
          </div>
          <!-- //문항보기-과목별 -->
        </template>
      </div>
    </div>
    <!-- 오답원인 입력 레이어 -->
    <Modal
      v-if="isShowInputReasonLayer"
      :modalTitle="qustnList[activeLiIndex][activeIndex].coransrYn === 'Y' ? '정답원인 입력' : '오답원인 입력'"
      modalType="layer_wransr_write"
      @close-modal="isShowInputReasonLayer = false"
    >
      <div slot="head">
        <h4>{{ qustnList[activeLiIndex][activeIndex].coransrYn === 'Y' ? '정답원인 입력' : '오답원인 입력' }}</h4>
      </div>
      <div slot="body">
        <textarea v-model="reasonText" @keydown="onKeyDownReason($event)" maxlength="20" placeholder="(최대 20자)" cols="30" rows="5" class="tf_write"></textarea>
      </div>
      <div slot="foot">
        <button @click="saveCauseReasonText" type="button" class="btn_save">확인</button>
      </div>
    </Modal>
    <!-- //오답원인 입력 레이어 -->
    <expl-curri-vod
      expl-type="corWransr"
      v-if="!isMobile && showVodModalLayer"
      :explCurriVodUrl="explCurriVodUrl"
      @showExplVod="showVodModalLayer = false"
    /><!-- 해설 강의 팝업 -->
    <expl-curri-vod-mobile
      expl-type="corWransr"
      v-if="isMobile && showVodModalLayer"
      :explCurriVodUrl="explCurriVodUrl"
      @showExplVod="showVodModalLayer = false"
    /><!-- 해설 강의 팝업 -->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AsideList from '@/components/unityexam/common/popup/AsideList';
import Modal from '@/components/unityexam/common/Modal';
import ExplCurriVod from '@/components/dailytest/taketest/ExplCurriVod';
import ExplCurriVodMobile from '@/components/dailytest/taketest/ExplCurriVodMobile';
import { AWS_S3_RESOURCE_URL } from '@build/build.config';
import { USER_AUTHORITY } from '@/const/const';
import { tmsImage } from '@/mixins/dailytest/tmsImage';
import StringUtils from '@/utils/StringUtils';
export default {
  name: 'unityExamViewQustnPopup',
  mixins: [tmsImage],
  components: {
    AsideList,
    Modal,
    ExplCurriVod,
    ExplCurriVodMobile
  },
  data() {
    return {
      questionViewType: parseInt(this.$route.query.viewType) || 1, // 학생별: '1', 과목별: '2'
      contentView: 'type1',
      isShowQuestionInfo: false,
      isOpenQuestionNumber: false,
      reasonWrongAnswer: '',
      isShowInputReasonLayer: false,
      showVodModalLayer: false,
      isActive: false,
      isActiveOn: 0,
      isActiveTamOn: '',
      activeLiIndex: 0,
      items: 20,
      categories: [{ dtlSubjtNm: '사회문화', title: '사회문화' }, { dtlSubjtNm: '경제', title: '경제' }],
      activeIndex: 0,
      qustnList: [],
      explCurriVodUrl: '',
      awsResourceUrl: AWS_S3_RESOURCE_URL,
      answerCause: [],
      wransrCause: [],
      reasonText: '',
      USER_AUTHORITY,
      agent: '',
      StringUtils
    };
  },
  watch: {
    activeLiIndex() {
      this.activeIndex = 0;
      this.$refs.aside_list_qustn_view[this.activeLiIndex].isActive = this.activeIndex;
      this.$refs.aside_list_qustn_view[this.activeLiIndex].index = this.activeIndex;
      if (this.qustnList[this.activeLiIndex].length > 0) {
        this.intiQustnCauseList();
      }
    },
    isShowInputReasonLayer() {
      if (this.isShowInputReasonLayer) {
        this.reasonText = this.qustnList[this.activeLiIndex][this.activeIndex].coransrWransrNotesEtcOpninCont;
      }
    }
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      userInfo: (state) => state.common.userInfo,
      enterAnswerSelectExamInfo: (state) => state.unityexam.enteranswer.enterAnswerSelectExamInfo,
      enterAnswerExamQustnInfo: (state) => state.unityexam.enteranswer.enterAnswerExamQustnInfo,
      answerCauseList: (state) => state.unityexam.enteranswer.answerCauseList,
      wransrCauseList: (state) => state.unityexam.enteranswer.wransrCauseList
    })
  },
  mounted() {
    if (this.isMobile) {
      this.isActive = true;
    }
  },
  updated() {
    if (this.contentView === 'type1'){
      document.querySelectorAll('.sel_ansr').forEach((element) => {
        element.classList.add('blur');
      });
    } else {
      document.querySelectorAll('.sel_ansr').forEach((element) => {
        element.classList.remove('blur');
      });
    }
    if(typeof this.$refs.aside_list_qustn_view[this.activeLiIndex] !== 'undefined'){
      this.$refs.aside_list_qustn_view[this.activeLiIndex].isActive = this.activeIndex;
      this.$refs.aside_list_qustn_view[this.activeLiIndex].index = this.activeIndex;
    }
  },
  created() {
    if (window.NodeList && !NodeList.prototype.forEach) {
      NodeList.prototype.forEach = Array.prototype.forEach;
    }
    if (!this.userInfo.studentCd) {
      this.getUser().then(() => {
        this.getAnswerCauseData();
        this.getWransrCauseData();
        let params = {};
        params.examYy = this.$route.query['examYy'];
        params.unityExamSeq = this.$route.query['unityExamSeq'];
        params.unityDtlExamSeq = this.$route.query['unityDtlExamSeq'];
        params.unityDtlExamTurnOrdSeq = this.$route.query['unityDtlExamTurnOrdSeq'];

        if (this.isMobile) {
          params.highSubjtSeq = this.$route.query['highSubjtSeq'];
          params.unityExamSubjtSeq = this.$route.query['dtlSubjtSeq01'];
          params.unityExamSubjtSeqTam = this.$route.query['dtlSubjtSeq02'];
        }
        this.initSelectExam(params);
      });
    } else {
      this.getAnswerCauseData();
      this.getWransrCauseData();
      let params = {};
      params.examYy = this.$route.query['examYy'];
      params.unityExamSeq = this.$route.query['unityExamSeq'];
      params.unityDtlExamSeq = this.$route.query['unityDtlExamSeq'];
      params.unityDtlExamTurnOrdSeq = this.$route.query['unityDtlExamTurnOrdSeq'];

      if (this.isMobile) {
        params.highSubjtSeq = this.$route.query['highSubjtSeq'];
        params.unityExamSubjtSeq = this.$route.query['dtlSubjtSeq01'];
        params.unityExamSubjtSeqTam = this.$route.query['dtlSubjtSeq02'];
      }
      this.initSelectExam(params);
    }
    this.agent = navigator.userAgent.toLowerCase();
  },
  methods: {
    ...mapActions([
      'getUser',
      'getEnterAnswerSelectExam',
      'getEnterAnswerExamQustn',
      'getAnswerCauseData',
      'getWransrCauseData',
      'postChangeRegiWransrNote',
      'postSaveCause'
    ]),
    windowCloseSelf() {
      var customWindow = window.open('', '_self', '');
      customWindow.close();
    },
    toggleActive(idx, index) {
      if(idx === 0){
        this.isActiveOn = index;
        this.isActiveTamOn = '';
        this.activeLiIndex = 0;
      }else{
        this.isActiveOn = '';
        this.isActiveTamOn = index;
        this.activeLiIndex = 1;
      }

      this.activeIndex = index;
      this.intiQustnCauseList();
      this.intiReasonWrongAnswer();
    },
    showInputReasonLayer() {
      if (this.reasonWrongAnswer !== '') {
        if (this.reasonWrongAnswer.substring(5, 8) === '009') this.isShowInputReasonLayer = true;
        this.saveCause(this.reasonWrongAnswer);
      }
    },
    closeViews() {
      const isViews = document.querySelector('.paper_info');
      isViews.classList.remove('active');
      this.isActive = false;
    },
    showExplVod(explCurriVodPathwy, qustnInpMethodCd) {
      this.explCurriVodUrl = '/unity-exam/' + explCurriVodPathwy;
      this.showVodModalLayer = true;
    },
    qustnCauseList(causeDivCd) {
      this.answerCause = [];
      this.wransrCause = [];
      this.answerCauseList.forEach((item, idx) => {
        if (item.subjtData === causeDivCd) {
          this.answerCause.push(item);
        }
      });
      this.answerCause.sort(function(a, b) {
        return Number(a.causeData.substring(5, 8)) - Number(b.causeData.substring(5, 8));
      });
      this.wransrCauseList.forEach((item, idx) => {
        if (item.subjtData === causeDivCd) {
          this.wransrCause.push(item);
        }
      });
      this.wransrCause.sort(function(a, b) {
        return Number(a.causeData.substring(5, 8)) - Number(b.causeData.substring(5, 8));
      });
    },
    qustnPrev() {
      if (this.activeIndex > 0) {
        this.activeIndex--;
        this.isActiveOn = this.activeIndex;
        this.$refs.aside_list_qustn_view[this.activeLiIndex].isActive = this.activeIndex;
        this.$refs.aside_list_qustn_view[this.activeLiIndex].index = this.activeIndex;
        this.intiQustnCauseList();
        this.intiReasonWrongAnswer();
      }
    },
    qustnNext() {
      if (this.activeIndex < this.qustnList[this.activeLiIndex].length - 1) {
        this.activeIndex++;
        this.isActiveOn = this.activeIndex;
        this.$refs.aside_list_qustn_view[this.activeLiIndex].isActive = this.activeIndex;
        this.$refs.aside_list_qustn_view[this.activeLiIndex].index = this.activeIndex;
        this.intiQustnCauseList();
        this.intiReasonWrongAnswer();
      }
    },
    qustnIndex(index) {
      this.activeIndex = index;
      this.isActiveOn = this.activeIndex;
      this.intiQustnCauseList();
      this.intiReasonWrongAnswer();
    },
    changeRegiWransrNote(unityExamQustnSeq, bystdntUnityExamQustnInpStusSeq, e) {
      const params = {};
      params.coransrNotesYn = e.target.checked === true ? 'Y' : 'N';
      params.unityExamQustnSeq = unityExamQustnSeq;
      params.bystdntUnityExamQustnInpStusSeq = bystdntUnityExamQustnInpStusSeq;
      this.postChangeRegiWransrNote(params);
    },
    saveCause(reasonWrongAnswer) {
      const params = {};
      this.reasonWrongAnswer = reasonWrongAnswer;
      if (reasonWrongAnswer.indexOf('W') >= 0) {
        params.wransrCauseCd = reasonWrongAnswer;
        this.qustnList[this.activeLiIndex][this.activeIndex].wransrCauseCd = this.reasonWrongAnswer;
        this.qustnList[this.activeLiIndex][this.activeIndex].coransrCauseCd = null;
      }
      if (reasonWrongAnswer.indexOf('C') >= 0) {
        params.coransrCauseCd = reasonWrongAnswer;
        this.qustnList[this.activeLiIndex][this.activeIndex].coransrCauseCd = this.reasonWrongAnswer;
        this.qustnList[this.activeLiIndex][this.activeIndex].wransrCauseCd = null;
      }

      params.unityExamQustnSeq = this.qustnList[this.activeLiIndex][this.activeIndex].unityExamQustnSeq;
      params.bystdntUnityExamQustnInpStusSeq = this.qustnList[this.activeLiIndex][
        this.activeIndex
      ].bystdntUnityExamQustnInpStusSeq;
      this.postSaveCause(params);
    },
    saveCauseReasonText() {
      const params = {};
      params.coransrWransrNotesEtcOpninCont = this.reasonText;
      params.unityExamQustnSeq = this.qustnList[this.activeLiIndex][this.activeIndex].unityExamQustnSeq;
      params.bystdntUnityExamQustnInpStusSeq = this.qustnList[this.activeLiIndex][
        this.activeIndex
      ].bystdntUnityExamQustnInpStusSeq;
      this.postSaveCause(params);
      this.qustnList[this.activeLiIndex][this.activeIndex].coransrWransrNotesEtcOpninCont = this.reasonText;
      this.isShowInputReasonLayer = false;
    },
    initSelectExam(params) {
      params.examSubjtInpCompleteYn = 'Y';
      this.getEnterAnswerSelectExam(params).then(() => {
        this.categories = [];
        let selectinx = this.activeLiIndex;
        this.enterAnswerSelectExamInfo.forEach((item) => {
          this.categories.push(item);
          this.qustnList.push(item.examQustnResponseList);
          let idx = this.categories.indexOf(item);
          if(item.highSubjtSeq == this.$route.query['highSubjtSeq']){
            if(this.$route.query['dtlSubjtSeq01'] === '0'){
              selectinx = idx;
              this.activeLiIndex = selectinx;
              this.intiQustnCauseList();
              this.intiReasonWrongAnswer();
            }
            if(this.$route.query['subjtSeq'] !== '0'){
              selectinx = idx;
              this.activeLiIndex = selectinx;
              this.intiQustnCauseList();
              this.intiReasonWrongAnswer();
            }
          }
          if(item.unityExamSubjtSeq == this.$route.query['dtlSubjtSeq01']){
            selectinx = idx;
            this.activeLiIndex = selectinx;
            this.intiQustnCauseList();
            this.intiReasonWrongAnswer();
          }
        });
      });
    },
    intiQustnCauseList(){
      this.qustnCauseList(this.qustnList[this.activeLiIndex][this.activeIndex].coransrCauseDivCd);
      if (this.qustnList[this.activeLiIndex][this.activeIndex].coransrYn === 'Y') {
        this.reasonWrongAnswer =
          this.qustnList[this.activeLiIndex][this.activeIndex].coransrCauseCd === null
            ? ''
            : this.qustnList[this.activeLiIndex][this.activeIndex].coransrCauseCd;
      } else {
        this.reasonWrongAnswer =
          this.qustnList[this.activeLiIndex][this.activeIndex].wransrCauseCd === null
            ? ''
            : this.qustnList[this.activeLiIndex][this.activeIndex].wransrCauseCd;
      }
    },
    intiReasonWrongAnswer(){
      let initCnt = 0;
      this.answerCause.forEach((item) => {
        if(item.causeData === this.reasonWrongAnswer){
          initCnt++;
        }
      });
      this.wransrCause.forEach((item) => {
        if(item.causeData === this.reasonWrongAnswer){
          initCnt++;
        }
      });
      if(initCnt === 0){
        this.reasonWrongAnswer = '';
      }
      this.isOpenQuestionNumber = false;
    },
    isDisplayIndex(index) {
      const partition = Math.floor(this.activeIndex / 5);
      return partition * 5 <= index && index < (partition + 1) * 5;
    },
    onKeyDownReason(evt) {
      if(this.reasonText.length === 20 ){
        evt.target.blur();
        evt.target.focus();
      }
    }
  }
};
</script>
