<template>
  <div id="unityexam" class="pay_content">
    <div class="mypage_comm wransr_note" :class="{ mobile: isMobile }">
      <h4 class="pc_only"><span>오답노트</span></h4>
      <div class="tab_select mo_only">
        <ul class="list_select">
          <li v-for="(item, idx) in wransrnoteExamTabInfo" :key="idx" :class="{ on: activeTab === item.unityExamDivCd }">
            <a class="link_cont" @click="changeTable(item.unityExamDivCd)">{{ item.unityExamDivNm }}</a>
          </li>
        </ul>
      </div>
      <div class="select_exam_system">
        <div class="inner">
          <div class="wrap_select_year">
            <select class="select_cont" v-model="examYear" @change="changeTab($event)">
              <option v-for="(item, idx) in wransrnoteYearInfo" :key="idx" :value="item.examYy"> {{ item.examYy }}년 </option>
              <option v-if="wransrnoteYearInfo === null" :value="examYear"> {{ examYear }}년 </option>
            </select>
          </div>
          <div class="wrap_select_exam mo_only">
            <select class="select_cont" v-model="examSeq" @change="changeMobileSelExam($event)">
              <option value="0" :selected="true">전체</option>
              <option v-for="(item, idx) in wransrnoteExamInfo" :key="idx" :value="item.unityExamSeq">{{ item.unityExamNm }}</option>
            </select>
          </div>
          <div class="wrap_select_exam mo_only">
            <select class="select_cont" v-model="dtlExamSeq" @change="changeMobileSelDtlExamTable($event)">
              <option value="0" :selected="true">전체</option>
              <option v-for="(item, idx) in wransrnoteDtlExamInfo" :key="idx" :value="item.unityDtlExamSeq">{{ item.unityDtlExamNm }}</option>
            </select>
          </div>
        </div>
      </div>
      <tab-menu @changeTable="changeTable" typeClass="tab_comm pc_only">
        <tab-pannel name="전체" :selected="true">
          <template #TabelGrid>
            <tab-table-box
              tableStyle="TypeD"
              tableNotice="* 유사문항이 제공되면, 유사문항까지 복습해야 복습완료 상태가 됩니다."
              :wransrnoteTableExamInfo="wransrnoteTableExamInfo"
              @open-WindowPopup="openPopup('unityExamWranNotePopup')"
              :currentPage="currentPage"
              :pagePerCnt="pagePerCnt"
            />
            <pagination
              :total-pages="Math.ceil(wransrnoteTableExamInfo.length / pagePerCnt)"
              :total="wransrnoteTableExamInfo.length"
              :current-page="currentPage"
              @pagechanged="onPageChange"
              @change-page-per-cnt="pagePerCnt = pagePerCnt + 10"
              :maxVisibleButtons="Math.ceil(wransrnoteTableExamInfo.length / pagePerCnt) > 5 ? 5 : Math.ceil(wransrnoteTableExamInfo.length / pagePerCnt)"
            />
          </template>
        </tab-pannel>
        <tab-pannel v-for="(item, idx) in wransrnoteExamTabInfo" :key="idx" :unityExamDivCd="item.unityExamDivCd" :name="item.unityExamDivNm">
          <template #TabelGrid>
            <tab-table-box
              tableStyle="TypeD"
              tableNotice="* 유사문항이 제공되면, 유사문항까지 복습해야 복습완료 상태가 됩니다."
              :wransrnoteTableExamInfo="wransrnoteTableExamInfo"
              @open-WindowPopup="openPopup('unityExamWranNotePopup')"
              :currentPage="currentPage"
              :pagePerCnt="pagePerCnt"
            />
            <pagination
              :total-pages="Math.ceil(wransrnoteTableExamInfo.length / pagePerCnt)"
              :total="wransrnoteTableExamInfo.length"
              :current-page="currentPage"
              @pagechanged="onPageChange"
              @change-page-per-cnt="pagePerCnt = pagePerCnt + 10"
              :maxVisibleButtons="Math.ceil(wransrnoteTableExamInfo.length / pagePerCnt) > 5 ? 5 : Math.ceil(wransrnoteTableExamInfo.length / pagePerCnt)"
            />
          </template>
        </tab-pannel>
      </tab-menu>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TabMenu from './common/TabMenu';
import TabPannel from './common/TabPannel';
import TabTableBox from './common/TabTableBox';
import Pagination from './common/Pagination';
import { windowOpenPopup } from '@/mixins/windowOpenPopup';
import { PAGE_TITLE } from '../../const/const';
export default {
  name: 'unityExamWransNote',
  mixins: [windowOpenPopup],
  components: {
    TabMenu,
    TabPannel,
    TabTableBox,
    Pagination
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      userInfo: (state) => state.common.userInfo,
      wransrnoteYearInfo: (state) => state.unityexam.wransrnote.wransrnoteYearInfo,
      wransrnoteExamTabInfo: (state) => state.unityexam.wransrnote.wransrnoteExamTabInfo,
      wransrnoteExamInfo: (state) => state.unityexam.wransrnote.wransrnoteExamInfo,
      wransrnoteDtlExamInfo: (state) => state.unityexam.wransrnote.wransrnoteDtlExamInfo,
      wransrnoteTableExamInfo: (state) => state.unityexam.wransrnote.wransrnoteTableExamInfo,
      selectUnityExamSeq: (state) => state.unityexam.enteranswer.selectUnityExamSeq,
      selectUnityDtlExamSeq: (state) => state.unityexam.enteranswer.selectUnityDtlExamSeq,
      selectUnityDtlExamTurnOrdSeq: (state) => state.unityexam.enteranswer.selectUnityDtlExamTurnOrdSeq,
      selectUnityExamSubjtSeq: (state) => state.unityexam.enteranswer.selectUnityExamSubjtSeq,
      wransrnoteQustnInfo: (state) => state.unityexam.wransrnote.wransrnoteQustnInfo
    })
  },
  watch: {
    examYear(year) {
      const params = {};
      params.examYy = year;
      this.getWransrnoteExamTab(params).then(() => {
        if (this.isMobile) {
          params.unityExamDivCd = '0';
          if (this.wransrnoteExamTabInfo.length !== 0) {
            params.unityExamDivCd = this.wransrnoteExamTabInfo[0].unityExamDivCd;
            this.activeTab = params.unityExamDivCd;
          }
          this.getWransrnoteExam(params);
          this.getWransrnoteDtlExam(params);
        }
        this.getWransrnoteTableExam(params);
      });
    }
  },
  updated() {
    if (document.querySelector('header').style.display == 'none') {
      document.querySelector('header').style.display = 'block';
    }
  },
  data() {
    return {
      popup: undefined,
      currentPage: 1,
      examYear: '',
      activeTab: '',
      examTabDib: '0',
      pagePerCnt: 10,
      examSeq: 0,
      dtlExamSeq: 0
    };
  },
  created() {
    this.setHeaderInfo({ menuName: '오답노트', isPrev: false });
    if (!this.userInfo.studentCd) {
      this.getUser().then(() => {
        this.init();
      });
    } else {
      this.init();
    }
    this.changeTitle();
  },
  methods: {
    ...mapActions(['getUser', 'getWransrnoteYear', 'getWransrnoteExamTab', 'getWransrnoteExam', 'getWransrnoteDtlExam', 'getWransrnoteTableExam', 'getWransrnoteQustn', 'setHeaderInfo']),
    changeTitle() {
      let title = '';
      if (this.userInfo.branchCd !== '') title = PAGE_TITLE[this.userInfo.branchCd];
      document.title = 'mypage_성적관리 - 오답노트 | ' + title;
    },
    openPopup(PopName) {
      const route = this.$router.resolve({
        name: PopName,
        query: {
          examYy: this.examYear,
          unityExamSeq: this.selectUnityExamSeq,
          unityDtlExamSeq: this.selectUnityDtlExamSeq,
          unityDtlExamTurnOrdSeq: this.selectUnityDtlExamTurnOrdSeq,
          subjtSeq: this.selectUnityExamSubjtSeq
        }
      });
      if (this.isMobile && PopName === 'unityExamWranNotePopup') {
        location.href = route.href;
      } else {
        this.openWindowPopup(route.href, this.$route.name, PopName, this.locationReload, 'width=990, height=800, scrollbars=1');
      }
    },
    closePopup() {
      this.popup.close();
      window.opener.child.close();
    },
    locationReload() {
      location.reload();
    },
    onPageChange(page) {
      this.currentPage = page;
    },
    changeTab(e) {
      this.currentPage = 1;
      this.pagePerCnt = 10;
      const params = {};
      params.examYy = this.examYear;
      this.activeTab = e.target.value;
      params.unityExamDivCd = this.activeTab;
      this.getWransrnoteExam(params);
      this.changeTable(this.activeTab);
    },
    changeTable(divCd) {
      this.currentPage = 1;
      this.pagePerCnt = 10;
      this.activeTab = divCd;
      const params = {};
      this.examSeq = 0;
      this.dtlExamSeq = 0;
      params.examYy = this.examYear;
      params.unityExamDivCd = divCd;
      this.getTable(params);
      if (this.isMobile) {
        this.getWransrnoteExam(params);
      }
    },
    changeMobileSelExam(e) {
      const params = {};
      params.examYy = this.examYear;
      params.unityExamDivCd = this.activeTab;
      params.unityExamSeq = this.examSeq;
      this.getWransrnoteDtlExam(params);
      this.dtlExamSeq = 0;
      params.unityDtlExamSeq = this.dtlExamSeq;
      this.getTable(params);
    },
    changeMobileSelDtlExamTable(e) {
      const params = {};
      params.examYy = this.examYear;
      params.unityExamDivCd = this.activeTab;
      params.unityExamSeq = this.examSeq;
      params.unityDtlExamSeq = this.dtlExamSeq;
      this.getTable(params);
    },
    getTable(params) {
      this.getWransrnoteTableExam(params);
    },
    init() {
      this.getWransrnoteYear().then(() => {
        const currentYear = new Date().getFullYear();
        if ((this.wransrnoteYearInfo || []).length === 0) {
          return;
        }
        for (const obj of this.wransrnoteYearInfo) {
          if (obj.examYy === currentYear) {
            this.examYear = currentYear;
          }
        }
        if ((this.examYear || '') === '') {
          this.examYear = this.wransrnoteYearInfo[0].examYy;
        }
      });
    }
  }
};
</script>
