import MutationTypesUnityExam from '@/store/MutationTypesUnityExam';

export default {
  [MutationTypesUnityExam.SET_SUBJECT_YEARS]: (state, payload) => {
    state.wransrnote.subjectYears = payload || [];
  },
  [MutationTypesUnityExam.SET_UNITY_SUBJECT_MAP]: (state, payload) => {
    state.wransrnote.currentSubjectMap = payload;
  },
  [MutationTypesUnityExam.SET_UNITY_SUBJECT_MAP_BY_YEAR]: (state, payload) => {
    state.wransrnote.subjectMap = payload;
  },
  [MutationTypesUnityExam.SET_CURRENT_YEAR_SUBJT_MAP]: (state, payload) => {
    state.wransrnote.currentYearSubjtMap = payload;
  },
  [MutationTypesUnityExam.SET_LAST_YEAR_SUBJT_MAP]: (state, payload) => {
    state.wransrnote.lastYearSubjtMap = payload;
  },
  [MutationTypesUnityExam.SET_MY_SUBJECT_BY_YEAR]: (state, payload) => {
    state.wransrnote.mySubjectList = payload;
  },
  [MutationTypesUnityExam.CHART_DATA]: (state, payload) => {
    state.wransrnote.chartData = payload;
  },
  [MutationTypesUnityExam.SET_TEST_MAP]: (state, payload) => {
    state.wransrnote.unityExamTests = payload;
  },
  [MutationTypesUnityExam.SET_LAST_TEST]: (state, payload) => {
    state.wransrnote.lastTest = payload;
  },
  [MutationTypesUnityExam.SET_TEST_EXAM_SCORE]: (state, payload) => {
    state.wransrnote.personalScoreMap = payload;
  },
  [MutationTypesUnityExam.SET_UNITY_DTL_DIV_LIST]: (state, list) => {
    state.wransrnote.unityDtlDivCds = list;
  },
  [MutationTypesUnityExam.UNIV_GROUP]: (state, payload) => {
    state.wransrnote.univGroup = payload;
  },
  [MutationTypesUnityExam.SET_QUESTION_INFO]: (state, payload) => {
    state.wransrnote.questionInfoList = payload;
  },
  [MutationTypesUnityExam.SET_QUESTION_INFO_BY_EXAM]: (state, payload) => {
    state.wransrnote.questionInfoByExam = payload;
  },
  [MutationTypesUnityExam.SET_QUESTION_DETAIL_BY_PART]: (state, payload) => {
    state.wransrnote.questionDetailByPart = payload;
  },
  [MutationTypesUnityExam.SET_ANSWER_BY_QUESTION_TYPES]: (state, payload) => {
    state.wransrnote.answerByQuestionTypes = payload;
  },
  [MutationTypesUnityExam.WRANSR_CORANSR_CAUSE_LIST_STATS]: (state, payload) => {
    state.wransrnote.wransrCoransrCauseListStats = payload;
  },
  [MutationTypesUnityExam.SET_ANALYZE_AVAILABLE_MAP]: (state, payload) => {
    state.wransrnote.analyzeAvailableMap = payload;
  },
  [MutationTypesUnityExam.WRANSR_NOTE_YEAR_INFO]: (state, payload) => {
    state.wransrnote.wransrnoteYearInfo = payload.data;
  },
  [MutationTypesUnityExam.WRANSR_NOTE_EXAM_TAB_INFO]: (state, payload) => {
    state.wransrnote.wransrnoteExamTabInfo = payload.data;
  },
  [MutationTypesUnityExam.WRANSR_NOTE_EXAM_INFO]: (state, payload) => {
    state.wransrnote.wransrnoteExamInfo = payload.data;
  },
  [MutationTypesUnityExam.WRANSR_NOTE_DTL_EXAM_INFO]: (state, payload) => {
    state.wransrnote.wransrnoteDtlExamInfo = payload.data;
  },
  [MutationTypesUnityExam.WRANSR_NOTE_TABLE_EXAM_INFO]: (state, payload) => {
    state.wransrnote.wransrnoteTableExamInfo = payload.data;
  },
  [MutationTypesUnityExam.WRANSR_NOTE_QUSTN_INFO]: (state, payload) => {
    state.wransrnote.wransrnoteQustnInfo = payload.data;
  },
  [MutationTypesUnityExam.SUBJT_QUESTION_ANSWER_PRINT]: (state, payload) => {
    state.wransrnote.subjtQuestionAnswerPrint = payload.data;
  }
};
