<template>
  <replay-lecture-p v-if="!isMobile" />
  <replay-lecture-m v-else />
</template>

<script>
import { mapState } from 'vuex';
import ReplayLectureP from '@/components/replaylecture/ReplayLectureP';
import ReplayLectureM from '@/components/replaylecture/ReplayLectureM';

export default {
  name: 'ReplayLecture',
  components: { ReplayLectureP, ReplayLectureM },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
    })
  }
};
</script>
<style scoped></style>
