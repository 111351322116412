import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = {
  subjectYears: [],
  currentSubjectMap: {},
  subjectMap: {},
  currentYearSubjtMap: {},
  lastYearSubjtMap: {},
  mySubjectList: [],
  chartData: [],
  unityExamTests: {},
  lastTest: {},
  personalScoreMap: {},
  univGroup: '',
  questionInfoList: [],
  questionInfoByExam: {},
  questionDetailByPart: {},
  answerByQuestionTypes: [],
  wransrCoransrCauseListStats: [],
  analyzeAvailableMap: {},
  wransrnoteYearInfo: [
    {
      examYy: '2999'
    }
  ],
  wransrnoteExamTabInfo: [
    {
      unityExamDivCd: '0',
      unityExamDivNm: ''
    }
  ],
  wransrnoteExamInfo: [
    {
      unityExamSeq: 0,
      untiyExamDivNm: '',
      unityExamNm: '',
      unityDtlExamSeq: 0,
      unityDtlExamNm: '',
      unityDtlExamTurnOrd: 0,
      unityDtlExamTurnOrdSeq: 0,
      subjtNm: '',
      dtlSubjtNm: '',
      unityExamSubjtSeq: 0,
      reviEdCnt: 0,
      wransrCnt: 0
    }
  ],
  wransrnoteDtlExamInfo: [
    {
      unityExamSeq: 0,
      untiyExamDivNm: '',
      unityExamNm: '',
      unityDtlExamSeq: 0,
      unityDtlExamNm: '',
      unityDtlExamTurnOrd: 0,
      unityDtlExamTurnOrdSeq: 0,
      subjtNm: '',
      dtlSubjtNm: '',
      unityExamSubjtSeq: 0,
      reviEdCnt: 0,
      wransrCnt: 0
    }
  ],
  wransrnoteTableExamInfo: [
    {
      unityExamSeq: 0,
      untiyExamDivNm: '',
      unityExamNm: '',
      unityDtlExamSeq: 0,
      unityDtlExamNm: '',
      unityDtlExamTurnOrd: 0,
      unityDtlExamTurnOrdSeq: 0,
      subjtNm: '',
      dtlSubjtNm: '',
      unityExamSubjtSeq: 0,
      reviEdCnt: 0,
      wransrCnt: 0
    }
  ],
  wransrnoteQustnInfo: {
    includeSmlQustnList: [],
    qustnList: []
  },
  unityDtlDivCds: []
};

export default {
  state,
  getters,
  actions,
  mutations
};
