import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import common from './modules/common';
import replaylecture from './modules/replaylecture';
import dailytest from './modules/dailytest/dailytestIndex';
import classroom from './modules/classroom/classroomIndex';
import unityexam from './modules/unityexam/unityexamIndex';
import studyTimeManagement from './modules/studyManagement/studytimemanagementIndex';
import infinitePass from './modules/infinitePass';

Vue.use(Vuex);
export default new Vuex.Store({
  plugins: [
    //주목! : 여기에 쓴 모듈만 저장됩니다.
    createPersistedState({
      paths: ['common']
    })
  ],
  modules: {
    common,
    dailytest,
    replaylecture,
    classroom,
    unityexam,
    studyTimeManagement,
    infinitePass
  }
});
