<template>
  <div>
    <div class="tbl_data">
      <table>
        <caption>
          무한패스
          {{
            title
          }}
          테이블
        </caption>
        <colgroup>
          <col v-if="showSelect" style="width: 5%" />
          <col v-for="header in headers" :key="header.value" :style="{ width: header.width }" />
        </colgroup>
        <thead>
          <tr>
            <th v-if="showSelect">
              <input type="checkbox" id="selectAll" v-model="selectAll" :disabled="items !== null && !items.length" />
              <label for="selectAll"></label>
            </th>
            <th v-for="header in headers" :key="header.value" v-html="header.text"><i v-if="header.sortable" class="ico_filter"></i></th>
          </tr>
        </thead>
        <tbody>
          <template v-if="isLoading">
            <tr>
              <td v-bind:colspan="headers.length + (showSelect ? 1 : 0)">
                <div class="loading_bar">
                  <div class="loader"></div>
                </div>
              </td>
            </tr>
          </template>
          <template v-else-if="items.length">
            <tr v-for="(item, index) in items" :key="index" :class="{ disable: rowDisable(item) }">
              <td v-if="showSelect">
                <input type="checkbox" :id="index" :value="item" v-model="selectedItems" />
                <label :for="index"></label>
              </td>
              <td
                v-for="{ value, txtLeft } in headers"
                :key="value"
                :class="[
                  txtLeft ? 'txt_left' : '',
                  value === 'groupName' ? 'ellip' : '',
                  value === 'curriTurnOrd' && item['userNotiCont'] ? 'tooltip' : ''
                ]"
              >
                <slot :name="`item.${value}`" v-bind:item="item">{{ item[value] }}</slot>
                <div v-if="value === 'curriTurnOrd' && item['userNotiCont']" class="tool_group">
                  <i class="ico_notice"></i>
                  <div class="noti_pop" v-html="item['userNotiCont']"></div>
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td :colspan="headers.length + (showSelect ? 1 : 0)">
                <div class="nolecture">
                  <i class="ico_nolecture"></i><span>{{ menuTitle }}가 없습니다!</span>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <!-- 삭제버튼 영역 -->
    <div v-if="showSelect" class="txt_r mgt20">
      <input type="button" value="삭제" class="mbtn dark" @click="openDeletePopup" />
    </div>
    <!-- paging 영역 -->
    <infinite-table-page />
    <confirm-popup v-model="deleteConfirmPopup" :text="`${this.selectedItems ? this.selectedItems.length : 0}개의 ${this.title}를 삭제하시겠습니까?`" @confirm="onDeletePopupConfirm" />
  </div>
</template>

<script>
import ConfirmPopup from './ConfirmPopup.vue';
import InfiniteTablePage from './InfiniteTablePage';

/**
 * 무한패스 강좌/강의 테이블 컴포넌트
 * @since 2023.03.23
 * @author 유경호 ykh6242@etoos.com
 */
export default {
  name: 'infinite-pass-table-component',
  components: {
    ConfirmPopup,
    InfiniteTablePage
  },
  props: {
    /**
     * 선택 아이템 List
     **/
    value: {
      type: Array,
      default: () => null
    },
    /**
     * 테이블 제목
     */
    title: {
      type: String,
      default: '강의'
    },
    /**
     * 헤더 데이터 List
     */
    headers: {
      type: Array,
      default: () => []
    },
    /**
     * 헤더 데이터 List
     */
    items: {
      type: Array,
      default: () => []
    },
    /**
     * 삭제시 row item을 파라미터로 받고 true, false를 반환해주는 function를 넣어주면 됨.
     * type: Function
     * default: false, disable 비활성
     */
    rowDisable: {
      type: Function,
      // eslint-disable-next-line no-unused-vars
      default: (item) => false
    },
    /**
     * 데이터들 1열에 select box 노출 여부
     * default: false
     */
    showSelect: {
      type: Boolean,
      default: false
    },
    menuTitle: {
      type: String,
      default: '등록된 강좌'
    },
    isLoading: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    deleteConfirmPopup: false,
    selectAll: false
  }),
  computed: {
    selectedItems: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  watch: {
    selectedItems(val) {
      this.selectAll = val?.length === this.items?.length;
    },
    selectAll(val) {
      if (val) {
        this.selectedItems = this.items;
      } else if (this.selectedItems?.length === this.items?.length) {
        this.selectedItems = [];
      }
    }
  },
  mounted() {},
  methods: {
    openDeletePopup() {
      if (this.selectedItems?.length) {
        this.deleteConfirmPopup = true;
      } else {
        alert(`선택된 ${this.title}가 없습니다!`);
      }
    },
    onDeletePopupConfirm() {
      // TODO 선택된 강의/강좌들 삭제 하는 action
      this.$emit('confirmDelete');
    }
  }
};
</script>
