export default {
  ERROR: 'ERROR',
  SET_STUDY_STATUS_LIST_AS: 'SET_STUDY_STATUS_LIST_AS',
  SET_STUDY_STATUS_LIST_TO: 'SET_STUDY_STATUS_LIST_TO',
  SET_STUDY_GRPSTATUS_LIST_AS: 'SET_STUDY_GRPSTATUS_LIST_AS',
  SET_STUDY_GRPSTATUS_LIST_TO: 'SET_STUDY_GRPSTATUS_LIST_TO',
  SET_SELECT_SUBJECT: 'SET_SELECT_SUBJECT',
  SET_LECTURE_SEARCH_LIST_JAE: 'SET_LECTURE_SEARCH_LIST_JAE',
  SET_LECTURE_SEARCH_LIST_DAN: 'SET_LECTURE_SEARCH_LIST_DAN',
  SET_TEACHER_LIST: 'SET_TEACHER_LIST',
  SET_LECTURE_CHECK_LIST: 'SET_LECTURE_CHECK_LIST',
  SET_RESULT_DATA: 'SET_RESULT_DATA',
  SET_LECTURE_REGISTER_DATA: 'SET_LECTURE_REGISTER_DATA',
  SET_LECTURE_CANCEL_DATA: 'SET_LECTURE_CANCEL_DATA',
  SET_IMAGE_DATA: 'SET_IMAGE_DATA',
  SET_LECTURE_TYPE_MAP: 'SET_LECTURE_TYPE_MAP'
};
