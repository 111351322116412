<template>
  <div>
    <header class="boxshadow">
      <div class="inner">
        <a class="menu" @click="toggleNav()">
          <span><em class="blind">메뉴열기</em></span>
        </a>
        <a :href="HOMEPAGE_URL[userInfo.branchCd]" class="home" v-if="!isPrev">
          <span class="homeBtnLogo"><em class="blind">홈</em></span>
        </a>
        <a href="javascript:void(0);" class="item_prev" @click="goPrev()" v-if="isPrev">이전</a>
        <h1 class="title">
          <span>{{ menuName }}</span>
        </h1>
        <!-- 스크립트로 타이틀 복사해옴 -->
        <a @click="goMyPage('')" class="hmy" v-if="!isPrev"><span>My</span></a>
      </div>
    </header>
    <div id="wrapper" :class="{ nav_on: isNavOpen, sideopen: isNavOpen }" style="z-index: 100;">
      <!-- nav -->
      <nav :class="{ is_show: isNavOpen }" class="nav_wrap hp" @click.stop>
        <div class="inner">
          <!-- 로그인후 -->
          <div class="nav_myarea">
            <div class="my_func">
              <div class="name">
                <a href="#" @click="goMyPage('')">
                  <span class="name">
                    <strong>{{ userInfo.userName }}</strong
                    >님 마이페이지 >
                  </span>
                </a>
              </div>
            </div>
            <div class="nav_func">
              <a :href="HOMEPAGE_URL[userInfo.branchCd]" class="home"><em class="blind">메인으로 이동</em></a>
              <a href="javascript:;" class="close" @click="closeNav"><em class="blind">메뉴닫기</em></a>
            </div>
          </div>
          <!-- //로그인후 -->
          <div class="nav_menu">
            <ul class="menu_tab">
              <li :class="{ on: activeTab === 'menulist1' }">
                <a href="javascript:;" class="tab" ref="" @click="navTab('menulist1', this)">전체메뉴</a>
              </li>
              <li :class="{ on: activeTab === 'menulist2' }">
                <a href="javascript:;" class="tab" ref="menulist2" @click="navTab('menulist2', this)">회원전용</a>
              </li>
            </ul>
            <div :class="['menu_list', 'menulist1', { on: activeTab === 'menulist1' }]">
              <ul class="depth1" v-for="(depth1, index) in depth1List" :key="index">
                <li>
                  <div class="title">{{ depth1.menu_nm }}</div>
                  <!-- depth2 -->
                  <ul v-if="depth2List.filter((menu) => menu.upper_menu_cd === depth1.menu_cd)" class="depth2">
                    <li v-for="(depth2, index) in depth2List.filter((menu) => menu.upper_menu_cd === depth1.menu_cd)" :key="index" :class="depth2.has_children ? 'has_depth3' : ''" :id="depth2.expanded_yn === 'Y' ? 'expanded' : ''">
                      <a v-if="depth2.url && depth2.link_cnnt_type !== 'NON-CLICKABLE' && depth2.link_cnnt_type !== 'WEBPLAYER'" :href="HOMEPAGE_URL[userInfo.branchCd] + depth2.url" :target="getTargetAttribute(depth2.link_cnnt_type)">{{
                        depth2.menu_nm
                      }}</a>
                      <a v-else-if="depth2.url && depth2.link_cnnt_type === 'WEBPLAYER'" @click.prevent="videoOn(depth2.link_cnnt_type, HOMEPAGE_URL[userInfo.branchCd] + depth2.url)">{{ depth2.menu_nm }}</a>
                      <a v-else href="javascript:;">{{ depth2.menu_nm }}</a>
                      <!-- depth3 -->
                      <ul v-if="depth3List.filter((menu) => menu.upper_menu_cd === depth2.menu_cd)" class="depth3">
                        <li v-for="(depth3, index) in depth3List.filter((menu) => menu.upper_menu_cd === depth2.menu_cd)" :key="index">
                          <a v-if="depth3.url && depth3.link_cnnt_type !== 'NON-CLICKABLE' && depth3.link_cnnt_type !== 'WEBPLAYER'" :href="HOMEPAGE_URL[userInfo.branchCd] + depth3.url" :target="getTargetAttribute(depth3.link_cnnt_type)">{{
                            depth3.menu_nm
                          }}</a>
                          <a v-else-if="depth3.url && depth3.link_cnnt_type === 'WEBPLAYER'" @click.prevent="videoOn(depth3.link_cnnt_type, HOMEPAGE_URL[userInfo.branchCd] + depth3.url)">{{ depth3.menu_nm }}</a>
                          <a v-else href="javascript:;">{{ depth3.menu_nm }}</a>
                        </li>
                      </ul>
                      <!-- /depth3 -->
                    </li>
                  </ul>
                  <!-- /depth2 -->
                </li>
              </ul>
            </div>
            <div :class="['menu_list', 'menulist2', { on: activeTab === 'menulist2' }]">
              <ul class="depth1">
                <li>
                  <ul class="depth2">
                    <li>
                      <a href="/schedulemain">수강신청</a>
                    </li>
                    <li>
                      <a href="/studyMain">수강현황</a>
                    </li>
                  </ul>
                </li>
                <li v-if="isStudent">
                  <div class="title">현장강의 무한패스</div>
                  <ul class="depth2">
                    <li>
                      <a href="/infinite-pass/all"><span>전체 강좌</span></a>
                    </li>
                    <li>
                      <a href="/infinite-pass/ongoing"><span>수강 중인 강의</span></a>
                    </li>
                  </ul>
                </li>
                <li v-if="isStudent">
                  <div class="title">학습·생활 관리</div>
                  <ul class="depth2">
                    <li>
                      <a href="/study/timeManagement"><span>학습시간 관리</span></a>
                    </li>
                    <li>
                      <a href="javascript:;" @click="goMyPage('800')"><span>생활관리</span></a>
                    </li>
                  </ul>
                </li>
                <li v-if="isStudent">
                  <div class="title">성적관리</div>
                  <ul class="depth2">
                    <li>
                      <a href="/unityexam/regulartest"><span>정규모의고사</span></a>
                    </li>
                    <li>
                      <a href="/unityexam/acacontents"><span>학원콘텐츠</span></a>
                    </li>
                    <li>
                      <a href="/unityexam/subjtanalyze"><span>과목별분석</span></a>
                    </li>
                    <li>
                      <a href="/unityexam/wransnote"><span>오답노트</span></a>
                    </li>
                    <li>
                      <a href="/unityexam/enteranswer'"><span>답안/정오답원인 입력</span></a>
                    </li>
                  </ul>
                </li>
                <li v-else>
                  <div class="title">성적관리</div>
                  <ul class="depth2">
                    <li>
                      <a href="/unityexam/regulartest"><span>정규모의고사</span></a>
                    </li>
                    <li>
                      <a href="/unityexam/acacontents"><span>학원콘텐츠</span></a>
                    </li>
                    <li>
                      <a href="/unityexam/subjtanalyze"><span>과목별분석</span></a>
                    </li>
                  </ul>
                </li>
                <li>
                  <div class="title">결제정보</div>
                  <ul class="depth2">
                    <li>
                      <a href="javascript:;" @click="goMyPage('200')">결제하실 내역</a>
                    </li>
                    <li>
                      <a href="javascript:;" @click="goMyPage('300')">결제내역</a>
                    </li>
                    <li>
                      <a href="javascript:;" @click="goMyPage('400')">장바구니</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <div class="title">개인정보</div>
                  <ul class="depth2">
                    <li>
                      <a @click="goEdit()">정보수정</a>
                    </li>
                    <li v-if="userInfo.userAuthority === 'PARENT'">
                      <a href="/unityexam/regulartest?showModal=true'">선택과목수정</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <a @click="goLogout()" class="logout">로그아웃</a>
        </div>
      </nav>
      <!-- //nav -->
    </div>
  </div>
</template>

<script>
import { OLD_MY_PAGE_URL, HOMEPAGE_URL, HP_CD_LIST, BTYPE_CD_LIST, ETOOS_MEMBER_EDIT_URL, ETOOS_MEMBER_LOGOUT_URL, CALLBACK_URL } from '@build/build.config';
import { USER_AUTHORITY } from '@/const/const';
import { commonEvent } from '@/mixins/commonEvent';
import { getBrandType } from '../../../../../utils/util';
import { mapActions, mapState } from 'vuex';
import { CLIENT_ID } from '../../../../../../build/build.config';

export default {
  name: 'Header',
  props: ['userInfo', 'menuName', 'isPrev'],
  mixins: [commonEvent],
  data() {
    return {
      OLD_MY_PAGE_URL: OLD_MY_PAGE_URL[getBrandType(location.href)],
      HOMEPAGE_URL,
      HP_CD_LIST,
      BTYPE_CD_LIST,
      CALLBACK_URL,
      BRAND_TYPE: getBrandType(location.href),
      branchCd: '',
      hpCd: '',
      btypeCd: '',
      depth1List: [],
      depth2List: [],
      depth3List: [],
      recruit: {
        recruits: []
      },
      timeTable: {
        course_homepage_menus: []
      },
      isNavOpen: false,
      activeTab: 'menulist1',
      ETOOS_MEMBER_EDIT_URL,
      ETOOS_MEMBER_LOGOUT_URL,
      CLIENT_ID: CLIENT_ID[getBrandType(location.href)]
    };
  },
  computed: {
    ...mapState({
      menuList: (state) => state.common.menuList,
      recruitList: (state) => state.common.recruitList,
      timeTableList: (state) => state.common.timeTableList,
      encMemNo: (state) => state.common.memNo,
      isStudent() {
        return ((this.userInfo || {}).personVo || {}).userAuthority === USER_AUTHORITY.STUDENT;
      }
    })
  },
  methods: {
    ...mapActions(['getMenuListData', 'getRecruitData', 'getTimeTableData', 'getMemNo', 'getUser']),
    getBrandType,
    goPrev() {
      this.$router.go(-1);
    },
    getMenuList() {
      this.branchCd = this.userInfo.branchCd;
      this.hpCd = HP_CD_LIST[this.branchCd];
      this.btypeCd = BTYPE_CD_LIST[this.branchCd];
      const requestData = { hpCd: this.hpCd };

      this.getMenuListData(requestData).then(() => {
        this.depth1List = this.menuList.main_menu_list.filter((menu) => menu.depth === 1);
        this.depth2List = this.menuList.main_menu_list.filter((menu) => menu.depth === 2);
        this.depth3List = this.menuList.main_menu_list.filter((menu) => menu.depth === 3);
      });

      this.getRecruitData(requestData).then(() => {
        this.recruit = this.recruitList;
      });

      this.getTimeTableData(requestData).then(() => {
        this.timeTable = this.timeTableList;
      });

      let nsuNum = '';
      let goNum = '';
      let neoNum = '';
      let timetableMenuCd = '';

      this.depth2List.find((item) => {
        if (item.menu_type === 'C780002') {
          item.has_children = true;
          nsuNum = item.menu_cd;
        } else if (item.menu_type === 'C780003') {
          item.has_children = true;
          goNum = item.menu_cd;
        } else if (item.menu_type === 'C780004') {
          item.has_children = true;
          neoNum = item.menu_cd;
        }
      });

      this.depth1List.find((item) => {
        if (item.menu_type === 'C780005') {
          item.has_children = false;
          timetableMenuCd = item.menu_cd;
        }
      });

      this.recruit.recruits.forEach((item) => {
        let upperCd;
        if (item.grade_div_cd === 'NSU') {
          upperCd = nsuNum;
        } else if (item.grade_div_cd === 'HIGH') {
          upperCd = goNum;
        } else if (item.grade_div_cd === 'NEO') {
          upperCd = neoNum;
        }
        this.depth3List.push({
          upper_menu_cd: upperCd,
          menu_nm: item.recruit_nm,
          url: `/${this.btypeCd}/recruit/information?recruit_seq=${item.recruit_seq}`,
          link_cnnt_type: 'CURRENT'
        });
      });

      this.timeTable.course_homepage_menus.forEach((item) => {
        this.depth2List.push({
          upper_menu_cd: timetableMenuCd,
          course_menu_seq: item.course_menu_seq,
          menu_nm: item.course_menu_nm,
          url: `/${this.btypeCd}/manage/courseList?course_menu_seq=${item.course_menu_seq}`,
          link_cnnt_type: 'CURRENT'
        });
      });
    },
    toggleNav() {
      this.isNavOpen = !this.isNavOpen;
    },
    closeNav() {
      this.isNavOpen = false;
    },
    navTab(tab) {
      this.activeTab = tab;
    },
    goEdit() {
      const cookies = document.cookie.split('; ');
      let etsssoCookie;
      // 각 쿠키를 반복하면서 원하는 이름을 찾기
      for (const cookie of cookies) {
        // 쿠키 이름과 값으로 나눔
        const [cookieName, cookieValue] = cookie.split('=');
        // 이름이 일치하면 값을 반환
        if (cookieName === 'cs_at') {
          etsssoCookie = cookieValue;
        }
      }
      location.href = this.ETOOS_MEMBER_EDIT_URL + '?clientId=' + this.CLIENT_ID + '&accessToken=' + etsssoCookie + '&callbackUrl=' + this.CALLBACK_URL[this.BRAND_TYPE];
    },
    goMyPage(menuCd) {
      const initDomain = {
        '0030': 'gangbuk',
        '0040': 'bucheon',
        '0050': 'bundang',
        '0080': 'ilsan',
        '0090': 'pyeongchon',
        '0130': 'a2',
        '0021': 'h1',
        '0110': 'h3',
        '0120': 'h2',
        '0060': 'etoos-gisuk',
        '0310': 'h4',
        '0500': 'n2',
        '0010': 'gangbuk'
      };
      const requestData = { etoosMemNo: this.userInfo.etoosMemNo };
      this.getMemNo(requestData).then(() => {
        window.location.href =
          this.OLD_MY_PAGE_URL +
          '/login/memNo.do?memNo=' +
          encodeURIComponent(this.encMemNo.mem_no) +
          '&returnUrl=' +
          encodeURIComponent(this.HOMEPAGE_URL[this.userInfo.branchCd]) +
          '&initDomain=' +
          initDomain[this.userInfo.branchCd] +
          '&menuCd=' +
          menuCd;
      });
    },
    goLogout() {
      window.location.href = this.ETOOS_MEMBER_LOGOUT_URL + '?callbackUrl=' + this.HOMEPAGE_URL(this.userInfo.branchCd);
    },
    authorityCheck() {
      // 메뉴체크
      this.checkScheduleMenu(this.userInfo.personVo);
      this.checkDailyTestMenu(this.userInfo.personVo);
      this.checkLectureServiceMenu(this.userInfo.personVo);
      this.checkWransrMenu(this.userInfo.personVo);
      this.checkStudyManagementMenu(this.userInfo.personVo);
      this.checkInfinitePassMenu(this.userInfo.personVo);
      this.checkSchedulePage();
    },
    // 수강신청 메뉴체크
    checkScheduleMenu(personVo) {
      if (personVo.userAuthority === USER_AUTHORITY.STUDENT) {
        this.scheduleMenuFlag = !(!personVo.classRoomInfoList.length > 0);
      }
    },
    checkLectureServiceMenu(personVo) {
      if (personVo.userAuthority === USER_AUTHORITY.STUDENT) {
        this.lectureServiceMenuFlag = personVo.classRoomInfoList.some((classRoomInfo) => {
          if (classRoomInfo.firstgroupYn === 'Y') {
            return true;
          }
        });
      }
    },
    // 데일리 테스트 메뉴 체크
    checkDailyTestMenu(personVo) {
      if (personVo.userAuthority === USER_AUTHORITY.STUDENT) {
        this.dailyTestFlag = personVo.newDailyTestService;
      }
    },
    checkWransrMenu(personVo) {
      if (personVo.userAuthority === USER_AUTHORITY.STUDENT) {
        this.wrasnrMenuFlag = personVo.classRoomInfoList.some((classRoomInfo) => {
          if (classRoomInfo.firstgroupYn === 'Y') {
            return true;
          }
        });
      } else if (personVo.userAuthority === USER_AUTHORITY.PARENT) {
        this.wrasnrMenuFlag = personVo.childList.some((child) => {
          return child.classRoomInfoList.some((classRoomInfo) => {
            return classRoomInfo.firstgroupYn === 'Y';
          });
        });
      }
    },
    checkSchedulePage() {
      if ((this.$route.name === 'schedulemain' && !this.scheduleMenuFlag) || (this.$route.name === 'Main' && !this.dailyTestFlag)) {
        this.$router.replace('/');
      }
    },
    // 학습관리 메뉴 체크
    checkStudyManagementMenu(personVo) {
      if (personVo.userAuthority === USER_AUTHORITY.STUDENT) {
        this.studyManagementMenuFlag = personVo.classRoomInfoList.some((classRoomInfo) => {
          if (classRoomInfo.firstgroupYn === 'Y') {
            return true;
          }
        });
      } else if (personVo.userAuthority === USER_AUTHORITY.PARENT) {
        this.studyManagementMenuFlag = personVo.childList.some((child) => {
          return child.classRoomInfoList.some((classRoomInfo) => {
            return classRoomInfo.firstgroupYn === 'Y';
          });
        });
      }
    },
    // 현강무패 메뉴 체크
    checkInfinitePassMenu(personVo) {
      if (personVo.userAuthority === USER_AUTHORITY.STUDENT) {
        this.infinitePassFlag = personVo.stdTypeCd === '1';
      }
    },
    getTargetAttribute(link_cnnt_type) {
      switch (link_cnnt_type) {
        case 'CURRENT':
          return '_self';
        case 'NEW':
          return '_blank';
        default:
          break;
      }
    },
    setExpandedElement() {
      const expandedElement = document.getElementById('expanded');
      if (expandedElement) {
        expandedElement.classList.add('is_show');
      } else {
        console.warn("ID가 'expanded'인 요소를 찾을 수 없습니다.");
      }
    },
    setClickEvent() {
      const hasDepth3Elements = document.querySelectorAll('.has_depth3');
      hasDepth3Elements.forEach((element) => {
        element.addEventListener('click', function() {
          const hasDepth3Elements = document.querySelectorAll('.has_depth3');
          hasDepth3Elements.forEach((element02) => {
            if (element !== element02 && element02.classList.contains('is_show')) {
              element02.classList.toggle('is_show');
              // You can add more logic here if needed
            }
          });
          // expanded ID를 가진 요소는 제외
          //if (element.id !== 'expanded') {
          element.classList.toggle('is_show');
          //}
        });
      });
      const depth2LiElements = document.querySelectorAll('ul.depth2 li'); //펼침시 현재 선택된 메뉴 색깔 없애기
      depth2LiElements.forEach((element) => {
        element.addEventListener('click', function() {
          const depth2LiElements = document.querySelectorAll('ul.depth2 li');
          depth2LiElements.forEach((element02) => {
            if (element !== element02 && element02.classList.contains('is_selected')) {
              element02.classList.toggle('is_selected');
              // You can add more logic here if needed
            }
          });
          // expanded ID를 가진 요소는 제외
          //if (element.id !== 'expanded') {
          element.classList.toggle('is_selected');
          //}
        });
      });
    }
  },
  created() {
    if (this.userInfo.branchCd === '') {
      this.getUser().finally(() => {
        this.authorityCheck();
        //this.changeTitle();
      });
    } else {
      this.authorityCheck();
      //this.changeTitle();
    }
    this.getMenuList();
  },
  watch: {
    depth3List() {
      this.$nextTick(() => {
        this.setExpandedElement();
        this.setClickEvent();
      });
    }
  }
};
</script>
