<template>
  <div class="pay_content pass" v-if="!isMobile">
    <div class="my_cont">
      <img src="http://acaimg.etoos.com/my/pc/v2/images/mypage/my_mathDM_cont.png" alt="꾸준한 수학 학습 루틴 만들기 수학 Daily Math" />
    </div>
  </div>
  <!--  MOBILE -->
  <div class="mypage_comm pass_mo" v-else>
    <div class="my_cont">
      <div class="cont_box">
        <div class="inner">
          <img src="http://acaimg.etoos.com/my/pc/v2/images/mypage/m_my_mathDM_cont.png" alt="꾸준한 수학 학습 루틴 만들기 수학 Daily Math" style="width: 320px;">
        </div>
      </div>
      <p class="desc">※ 수학 DM 관련 자세한 내용은 상담실에 문의해주세요 !</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { PAGE_TITLE } from '../../../const/const';

export default {
  name: 'math-cont-view',
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      userInfo: (state) => state.common.userInfo
    })
  },
  created() {
    this.setHeaderInfo({ menuName: '수학 DM', isPrev: false });
    this.changeTitle();
  },
  methods: {
    ...mapActions(['setHeaderInfo']),
    changeTitle() {
      let title = '';
      if (this.userInfo.branchCd !== '') title = PAGE_TITLE[this.userInfo.branchCd];
      document.title = '수학DM | ' + title;
    }
  }
};
</script>
