<template>
  <div class="pay_content pass" v-if="!isMobile">
    <div class="my_cont">
      <img src="http://acaimg.etoos.com/my/pc/v2/images/mypage/my_infinite_pass_cont.png" alt="나에게 꼭 필요한 강의만 골라 듣자 현장강의 무한패스" />
    </div>
  </div>
  <!--  MOBILE -->
  <div class="mypage_comm pass_mo" v-else>
    <div class="my_cont">
      <div class="cont_box">
        <div class="inner">
          <img src="http://acaimg.etoos.com/my/pc/v2/images/mypage/m_my_infinite_pass_cont.png" alt="나에게 꼭 필요한 강의만 골라 듣자 현장강의 무한패스" style="width: 320px;">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { PAGE_TITLE } from '../../const/const';

export default {
  name: 'pass-cont-view',
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      userInfo: (state) => state.common.userInfo
    })
  },
  methods: {
    ...mapActions(['setHeaderInfo']),
    changeTitle() {
      let title = '';
      if (this.userInfo.branchCd !== '') title = PAGE_TITLE[this.userInfo.branchCd];
      document.title = 'mypage_현장강의 무한패스 | ' + title;
    }
  },
  created() {
    this.setHeaderInfo({ menuName: '현장강의 무한패스', isPrev: false });
    this.changeTitle();
  }
};
</script>
