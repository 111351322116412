<template>
  <div class="pay_content">
    <div class="mypage_comm timeManage" :class="{ mobile: isMobile }">
      <!-- 모바일타입일때 mobile 클래스 추가 -->
      <h4 class="pc_only"><span>학습시간 관리</span></h4>
      <div class="select_exam_system">
        <div class="inner">
          <div class="wrap_select_year">
            <select class="select_cont" v-model="currentYear">
              <option v-for="(obj, idx) in yearList" :key="idx" :value="obj">{{ obj }}년</option>
            </select>
          </div>
          <div class="wrap_select_month">
            <select class="select_cont" v-model="currentMonth">
              <option v-for="(obj, idx) in monthList" :key="idx" :value="obj">{{ obj }}월</option>
            </select>
          </div>
          <div class="wrap_select_week">
            <select class="select_cont" v-model="currentWeek">
              <option v-for="week in weekNum" :key="week" :value="week">{{ week }}주</option>
            </select>
          </div>
        </div>
      </div>
      <div class="module-tabs">
        <div class="tab_comm tbl_score pc_only">
          <ul class="list_tab">
            <li :class="{ on: week === currentWeek }" v-for="week in weekNum" :key="week">
              <a class="btn_tab" href="javascript:;" @click="currentWeek = week">{{ week }}주차</a>
            </li>
          </ul>
        </div>
        <div class="timeManage__frame">
          <div class="clfix mgb10">
            <p class="txt">(단위 : 분)</p>
            <div class="btnwrap f_r">
              <button type="button" class="cancel" @click="getStudyTime()">
                <span>입력취소</span>
              </button>
              <button type="button" class="save" @click="setStudyTime()"><span>저장</span></button>
            </div>
          </div>

          <div class="tab_panel pc_only" v-if="!isMobile">
            <div style="display:block;">
              <div class="cont_data_info">
                <table class="tbl_comm daily">
                  <colgroup>
                    <col />
                    <col style="width:11%" span="8" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th rowspan="2">구분</th>
                      <th colspan="2">국어</th>
                      <th colspan="2">수학</th>
                      <th colspan="2">탐구</th>
                      <th colspan="2">영어</th>
                    </tr>
                    <tr>
                      <th>자습</th>
                      <th>인강</th>
                      <th>자습</th>
                      <th>인강</th>
                      <th>자습</th>
                      <th>인강</th>
                      <th>자습</th>
                      <th>인강</th>
                    </tr>
                    <tr>
                      <th>합계</th>
                      <th v-for="idx in 8">
                        {{ sumTimeInfo[idx - 1] === 0 ? '-' : sumTimeInfo[idx - 1] }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(studyTime, idx) in studyTimeInfo" :key="idx">
                      <td>
                        {{ Number(studyTime.studyDt.split('-')[1]) }}월 {{ Number(studyTime.studyDt.split('-')[2]) }}일 (<span :style="{ color: getColor(idx) }">{{ day[idx] }}</span
                        >)
                      </td>
                      <template v-for="info in studyTime.studyTimeInfoList">
                        <td>
                          <input type="text" @keyup="validateStudyTime($event, info)" v-model.number="info.studyTime" />
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="tab_panel mo_only" v-if="isMobile">
            <div class="cont_data_info">
              <table class="tbl_comm summary">
                <colgroup>
                  <col />
                  <col style="width:18%" span="4" />
                </colgroup>
                <thead>
                  <tr>
                    <th>구분</th>
                    <th>국어</th>
                    <th>수학</th>
                    <th>탐구</th>
                    <th>영어</th>
                  </tr>
                  <tr>
                    <th>자습 합계</th>
                    <th v-for="idx in 4">
                      {{ sumTimeInfo[(idx - 1) * 2] === 0 ? '-' : sumTimeInfo[(idx - 1) * 2] }}
                    </th>
                  </tr>
                  <tr>
                    <th>인강 합계</th>
                    <th v-for="idx in 4">
                      {{ sumTimeInfo[(idx - 1) * 2 + 1] === 0 ? '-' : sumTimeInfo[(idx - 1) * 2 + 1] }}
                    </th>
                  </tr>
                </thead>
              </table>
              <template v-for="(studyTime, idx) in studyTimeInfo">
                <div class="wrap_title">
                  <h5 class="tit_cont">
                    <span
                    >{{ Number(studyTime.studyDt.split('-')[1]) }}월 {{ Number(studyTime.studyDt.split('-')[2]) }}일 (<span :style="{ color: getColor(idx) }">{{ day[idx] }}</span
                    >)</span
                    >
                  </h5>
                </div>
                <table class="tbl_comm daily">
                  <colgroup>
                    <col />
                    <col style="width:39%" span="2" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>구분</th>
                      <th>자습</th>
                      <th>인강</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(title, idx) in subjtTitleNm">
                      <th>{{ title }}</th>
                      <td>
                        <input type="text" @keyup="validateStudyTime($event, studyTime.studyTimeInfoList[idx * 2])" v-model.number="studyTime.studyTimeInfoList[idx * 2].studyTime" />
                      </td>
                      <td>
                        <input type="text" @keyup="validateStudyTime($event, studyTime.studyTimeInfoList[idx * 2 + 1])" v-model.number="studyTime.studyTimeInfoList[idx * 2 + 1].studyTime" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DateUtil from '@/utils/DateUtil';
import { PAGE_TITLE } from '../../../const/const';

export default {
  name: 'StudyTimeManagement',
  data() {
    return {
      monthList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      weekList: [],
      currentYear: 0,
      currentMonth: 0,
      currentWeek: 0,
      sumTimeInfo: [0, 0, 0, 0, 0, 0, 0, 0],
      day: ['월', '화', '수', '목', '금', '토', '일'],
      subjtTitleNm: ['국어', '수학', '탐구', '영어'],
      weekMap: {}
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.common.userInfo,
      studyTimeInfo: (state) => state.studyTimeManagement.studyTimeManagement.studyTimeInfo,
      isMobile: (state) => state.common.isMobile
    }),
    weekNum() {
      return DateUtil.weekNumberByMonth(this.currentYear, this.currentMonth);
    },
    yearList() {
      const map = (this.userInfo.personVo.classRoomInfoList || []).filter((a) => a.firstgroupYn === 'Y').map((a) => a.groupYear);
      return map;
    },
    // 삭제하지 말것 watch 걸어둠
    dateRequestParam() {
      return this.currentYear + '-' + this.currentMonth + '-' + this.currentWeek;
    }
  },
  methods: {
    ...mapActions(['getUser', 'getStudyTimeInfo', 'setStudyTimeInfo', 'setLoading', 'setHeaderInfo']),
    changeTitle() {
      let title = '';
      if (this.userInfo.branchCd !== '') title = PAGE_TITLE[this.userInfo.branchCd];
      document.title = 'mypage_학습관리 - 학습시간관리 | ' + title;
    },
    getColor(idx) {
      if (idx === 5) {
        return 'blue';
      }
      if (idx === 6) {
        return 'red';
      }
      return '';
    },
    getStudyTime() {
      const params = {};
      params.year = this.currentYear;
      params.month = this.currentMonth;
      params.week = this.currentWeek;
      params.studentCd = this.userInfo.studentCd;
      params.branchCd = this.userInfo.branchCd;
      this.getStudyTimeInfo(params).then(() => {
        this.sumTimeInfo = [0, 0, 0, 0, 0, 0, 0, 0];
        this.studyTimeInfo.forEach((info) => {
          info.studyTimeInfoList.forEach((studyTime, idx) => {
            const time = studyTime.studyTime || 0;
            this.sumTimeInfo[idx] += time;
          });
        });
      });
    },
    setStudyTime() {
      if (!confirm('저장하시겠습니까?')) {
        return;
      }
      const params = {};
      params.year = this.currentYear;
      params.month = this.currentMonth;
      params.week = this.currentWeek;
      params.studyTimeInfoList = this.studyTimeInfo;
      params.studentCd = this.userInfo.studentCd;
      params.branchCd = this.userInfo.branchCd;
      this.setStudyTimeInfo(params).then((result) => {
        if (result === 'success') {
          alert('저장되었습니다.');
          this.getStudyTime();
          return;
        }
        alert('저장에 실패하였습니다.');
      });
    },
    validateStudyTime(event, info) {
      const isDigit = new RegExp('^\\d+$');
      const value = event.target.value;
      if (!isDigit.test(value || 0)) {
        alert('시간은 정수의 숫자로 입력해주세요.');
        info.studyTime = Number(value.replace(/\D/i, ''));
        return;
      }
      if (Number(value) > 1440) {
        alert('시간을 확인해주세요.');
        info.studyTime = 1440;
      }
    },
    init() {
      if (this.yearList.indexOf(new Date().getFullYear().toString()) > -1) {
        this.currentYear = new Date().getFullYear();
        return;
      }
      if (this.yearList.length > 0) {
        this.currentYear = this.yearList[0];
      }
    }
  },
  watch: {
    currentYear(year) {
      this.currentMonth = 0;
      if (year === new Date().getFullYear()) {
        this.currentMonth = new Date().getMonth() + 1;
      } else {
        this.currentMonth = 1;
      }
    },
    currentMonth(month) {
      this.currentWeek = 0;
      if (month === 0) {
        return;
      }
      this.currentWeek = 1;
    },
    dateRequestParam() {
      this.getStudyTime();
    }
  },
  created() {
    this.setHeaderInfo({ menuName: '학습시간 관리', isPrev: false });
    if (!this.userInfo.studentCd) {
      this.getUser().then(() => {
        this.init();
      });
    } else {
      this.init();
    }
    this.changeTitle();
  }
};
</script>
