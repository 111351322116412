const APPLICATION_PHASE = process.env.APPLICATION_PHASE || 'LOCAL';
const APPLICATION_VERSION = process.env.npm_package_version;

const PROFILE = {
  ALPHA: 'qa',
  BETA: '',
  RC: 'dev',
  REAL: 'live',
  QA: 'stage'
};

const STATIC_DOMAIN = {
  ALPHA: '',
  BETA: '',
  RC: '',
  REAL: '',
  QA: ''
};

const DOMAIN_ID = {
  LOCAL: '6076',
  ALPHA: '',
  BETA: '',
  RC: '',
  REAL: '',
  QA: ''
};

const API_URL = {
  LOCAL: 'http://localhost:8099',
  ALPHA: '',
  BETA: '',
  REAL: '',
  QA: ''
};

const O2O_API_URL = {
  LOCAL: 'http://localhost:8080',
  ALPHA: 'https://dev-o2o-api.etoos.com',
  BETA: '',
  REAL: 'https://o2o-api.etoos.com',
  QA: 'https://qa-o2o-api.etoos.com'
};

const TMS_API_URL = {
  LOCAL: 'https://tms-front.etoos.com',
  ALPHA: 'https://tms-front.etoos.com',
  BETA: 'https://beta-tms-front.etoos.com',
  REAL: 'https://tms-front.etoos.com',
  QA: 'https://tms-front.etoos.com'
};

const ETOOS_MEMBER_LOGIN_URL = {
  LOCAL: 'http://dev-member.etoos.com/login',
  ALPHA: 'https://qa-member.etoos.com/login',
  BETA: '',
  QA: 'https://stage-member.etoos.com/login',
  REAL: 'https://member.etoos.com/login'
};

const CLIENT_ID = {
  LOCAL: {
    cheongsol: '0f52714748e16d834fb1e93ebfa23c17',
    hyper: '61c2df69daab35ed94bd7a71c99b76e1',
    neoneo: '1108db3ef2b8f9da7f7612c88f79a36b',
    answer: 'c982e64223753a81eefa40034e80d576',
    gisuk: '46246408bcc55aef9cc25021d1d91d15'
  },
  ALPHA: {
    cheongsol: '0f52714748e16d834fb1e93ebfa23c17',
    hyper: '61c2df69daab35ed94bd7a71c99b76e1',
    neoneo: '1108db3ef2b8f9da7f7612c88f79a36b',
    answer: 'c982e64223753a81eefa40034e80d576',
    gisuk: '46246408bcc55aef9cc25021d1d91d15'
  },
  BETA: '',
  QA: {
    cheongsol: '0f52714748e16d834fb1e93ebfa23c17',
    hyper: '61c2df69daab35ed94bd7a71c99b76e1',
    neoneo: '1108db3ef2b8f9da7f7612c88f79a36b',
    answer: 'c982e64223753a81eefa40034e80d576',
    gisuk: '46246408bcc55aef9cc25021d1d91d15'
  },
  REAL: {
    cheongsol: '0f52714748e16d834fb1e93ebfa23c17',
    hyper: '61c2df69daab35ed94bd7a71c99b76e1',
    neoneo: '1108db3ef2b8f9da7f7612c88f79a36b',
    answer: 'c982e64223753a81eefa40034e80d576',
    gisuk: '08e8f5040afe266d70bbe9bdac9daaed'
  }
};

const CLIENT_SECRET = {
  LOCAL: {
    cheongsol: '41f10f2ffcd97d19c92c39cd29c9d51df7cb2febc9ecad6e81a6d2c8216ee7bc',
    hyper: '58bbd16ce083e3c9200407f3991a78e7a526fd996e5e12f8f310a5c1e2c7cb1d',
    neoneo: '42159e2e42ca677c08225bf700f9cbd9f209377616d6adf75146471c9fa1d1f7',
    answer: '51b79cb8c654fcf37f8449563380d90906dd96b0a5e886e9057b6d8bcfcd5444',
    gisuk: '41f10f2ffcd97d19c92c39cd29c9d51df7cb2febc9ecad6e81a6d2c8216ee7bc'
  },
  ALPHA: {
    cheongsol: '41f10f2ffcd97d19c92c39cd29c9d51df7cb2febc9ecad6e81a6d2c8216ee7bc',
    hyper: '58bbd16ce083e3c9200407f3991a78e7a526fd996e5e12f8f310a5c1e2c7cb1d',
    neoneo: '42159e2e42ca677c08225bf700f9cbd9f209377616d6adf75146471c9fa1d1f7',
    answer: '51b79cb8c654fcf37f8449563380d90906dd96b0a5e886e9057b6d8bcfcd5444',
    gisuk: '41f10f2ffcd97d19c92c39cd29c9d51df7cb2febc9ecad6e81a6d2c8216ee7bc'
  },
  BETA: '',
  QA: {
    cheongsol: '41f10f2ffcd97d19c92c39cd29c9d51df7cb2febc9ecad6e81a6d2c8216ee7bc',
    hyper: '58bbd16ce083e3c9200407f3991a78e7a526fd996e5e12f8f310a5c1e2c7cb1d',
    neoneo: '42159e2e42ca677c08225bf700f9cbd9f209377616d6adf75146471c9fa1d1f7',
    answer: '51b79cb8c654fcf37f8449563380d90906dd96b0a5e886e9057b6d8bcfcd5444',
    gisuk: '41f10f2ffcd97d19c92c39cd29c9d51df7cb2febc9ecad6e81a6d2c8216ee7bc'
  },
  REAL: {
    cheongsol: '41f10f2ffcd97d19c92c39cd29c9d51df7cb2febc9ecad6e81a6d2c8216ee7bc',
    hyper: '58bbd16ce083e3c9200407f3991a78e7a526fd996e5e12f8f310a5c1e2c7cb1d',
    neoneo: '42159e2e42ca677c08225bf700f9cbd9f209377616d6adf75146471c9fa1d1f7',
    answer: '51b79cb8c654fcf37f8449563380d90906dd96b0a5e886e9057b6d8bcfcd5444',
    gisuk: '41f10f2ffcd97d19c92c39cd29c9d51df7cb2febc9ecad6e81a6d2c8216ee7bc'
  }
};

const BRANCH_TYPE = {
  LOCAL: {
    cheongsol: 'cs',
    hyper: 'hp',
    neoneo: 'nk',
    answer: 'as',
    gisuk: 'cs'
  },
  ALPHA: {
    cheongsol: 'cs',
    hyper: 'hp',
    neoneo: 'nk',
    answer: 'as',
    gisuk: 'cs'
  },
  BETA: '',
  QA: {
    cheongsol: 'cs',
    hyper: 'hp',
    neoneo: 'nk',
    answer: 'as',
    gisuk: 'cs'
  },
  REAL: {
    cheongsol: 'cs',
    hyper: 'hp',
    neoneo: 'nk',
    answer: 'as',
    gisuk: 'cs'
  }
};

const CALLBACK_URL = {
  LOCAL: {
    cheongsol: 'http://local-cheongsol.etoos.com:8090/api/login',
    hyper: 'http://local-hyperacademy.etoos.com:8090/api/login',
    neoneo: 'http://local-neoneo.etoos.com:3001/api/login',
    answer: 'http://local-a2-etoosanswer.etoos.com:8090/api/login',
    gisuk: 'http://local-cheongsol.etoos.com:8090/api/login'
  },
  ALPHA: {
    cheongsol: 'https://dev-cheongsol.etoos.com/api/login',
    hyper: 'https://dev-hyperacademy.etoos.com/api/login',
    neoneo: 'https://dev-neoneo.etoos.com/api/login',
    answer: 'https://dev-a2-etoosanswer.etoos.com/api/login',
    gisuk: 'https://dev-cheongsol.etoos.com/api/login'
  },
  BETA: '',
  QA: {
    cheongsol: 'https://qa-cheongsol.etoos.com/api/login',
    hyper: 'https://qa-hyperacademy.etoos.com/api/login',
    neoneo: 'https://qa-neoneo.etoos.com/api/login',
    answer: 'https://qa-a2-etoosanswer.etoos.com/api/login',
    gisuk: 'https://qa-cheongsol.etoos.com/api/login'
  },
  REAL: {
    cheongsol: 'https://cheongsol.etoos.com/api/login',
    hyper: 'https://hyperacademy.etoos.com/api/login',
    neoneo: 'https://neoneo.etoos.com/api/login',
    answer: 'https://a2-etoosanswer.etoos.com/api/login',
    gisuk: 'https://cheongsol.etoos.com/api/login'
  }
};

const MCALLBACK_URL = {
  LOCAL: {
    cheongsol: 'http://local-m-cheongsol.etoos.com:8091/api/login',
    hyper: 'http://local-m-hyperacademy.etoos.com:8091/api/login',
    neoneo: 'http://local-neoneo.etoos.com:3001/api/login',
    answer: 'http://local-a2-etoosanswer.etoos.com:8090/api/login',
    gisuk: 'http://local-m-cheongsol.etoos.com:8091/api/login'
  },
  ALPHA: {
    cheongsol: 'https://dev-m-cheongsol.etoos.com/api/login',
    hyper: 'https://dev-m-hyperacademy.etoos.com/api/login',
    neoneo: 'https://dev-neoneo.etoos.com/api/login',
    answer: 'https://dev-a2-etoosanswer.etoos.com/api/login',
    gisuk: 'https://dev-m-cheongsol.etoos.com/api/login'
  },
  BETA: '',
  QA: {
    cheongsol: 'https://qa-m-cheongsol.etoos.com/api/login',
    hyper: 'https://qa-m-hyperacademy.etoos.com/api/login',
    neoneo: 'https://qa-neoneo.etoos.com/api/login',
    answer: 'https://qa-a2-etoosanswer.etoos.com/api/login',
    gisuk: 'https://qa-m-cheongsol.etoos.com/api/login'
  },
  REAL: {
    cheongsol: 'https://m-cheongsol.etoos.com/api/login',
    hyper: 'https://m-hyperacademy.etoos.com/api/login',
    neoneo: 'https://neoneo.etoos.com/api/login',
    answer: 'https://a2-etoosanswer.etoos.com/api/login',
    gisuk: 'https://m-cheongsol.etoos.com/api/login'
  }
};

const GATE_DOMAIN = {
  LOCAL: {
    cheongsol: 'http://local-cheongsol.etoos.com:8090',
    hyper: 'http://local-hyperacademy.etoos.com:8090',
    neoneo: 'http://local-neoneo.etoos.com:3001',
    answer: 'http://local-a2-etoosanswer.etoos.com:8090',
    gisuk: 'http://local-gisuk.etoos.com:8090'
  },
  ALPHA: {
    cheongsol: 'https://dev-cheongsol.etoos.com',
    hyper: 'https://dev-hyperacademy.etoos.com',
    neoneo: 'https://dev-neoneo.etoos.com',
    answer: 'https://dev-a2-etoosanswer.etoos.com',
    gisuk: 'https://dev-gisuk.etoos.com'
  },
  BETA: '',
  QA: {
    cheongsol: 'https://qa-cheongsol.etoos.com',
    hyper: 'https://qa-hyperacademy.etoos.com',
    neoneo: 'https://qa-neoneo.etoos.com',
    answer: 'https://qa-a2-etoosanswer.etoos.com',
    gisuk: 'https://qa-gisuk.etoos.com'
  },
  REAL: {
    cheongsol: 'https://cheongsol.etoos.com',
    hyper: 'https://hyperacademy.etoos.com',
    neoneo: 'https://neoneo.etoos.com',
    answer: 'https://a2-etoosanswer.etoos.com',
    gisuk: 'https://gisuk.etoos.com'
  }
};

const ETOOS_MEMBER_LOGOUT_URL = {
  LOCAL: 'https://dev-member.etoos.com/logout',
  ALPHA: 'https://qa-member.etoos.com/logout',

  BETA: '',
  QA: 'https://stage-member.etoos.com/logout',
  REAL: 'https://member.etoos.com/logout'
};

const METOOS_MEMBER_LOGOUT_URL = {
  LOCAL: 'https://dev-member.etoos.com/logout',
  ALPHA: 'https://qa-member.etoos.com/logout',
  BETA: '',
  QA: 'https://stage-member.etoos.com/logout',
  REAL: 'https://member.etoos.com/logout'
};

const OLD_MY_PAGE_URL = {
  LOCAL: {
    cheongsol: 'https://local-cheongsol-mypage.etoos.com',
    hyper: 'https://local-hyper-mypage.etoos.com',
    neoneo: 'https://local-neoneo-mypage.etoos.com',
    answer: 'https://local-answer-mypage.etoos.com',
    gisuk: 'https://local-gisuk-mypage.etoos.com'
  },
  ALPHA: {
    cheongsol: 'https://dev-cheongsol-mypage.etoos.com',
    hyper: 'https://dev-hyper-mypage.etoos.com',
    neoneo: 'https://dev-neoneo-mypage.etoos.com',
    answer: 'https://dev-answer-mypage.etoos.com',
    gisuk: 'https://dev-gisuk-mypage.etoos.com'
  },
  BETA: '',
  REAL: {
    cheongsol: 'https://cheongsol-mypage.etoos.com',
    hyper: 'https://hyper-mypage.etoos.com',
    neoneo: 'https://neoneo-mypage.etoos.com',
    answer: 'https://answer-mypage.etoos.com',
    gisuk: 'https://gisuk-mypage.etoos.com'
  },
  QA: {
    cheongsol: 'https://qa-cheongsol-mypage.etoos.com',
    hyper: 'https://qa-hyper-mypage.etoos.com',
    neoneo: 'https://qa-neoneo-mypage.etoos.com',
    answer: 'https://qa-answer-mypage.etoos.com',
    gisuk: 'https://qa-gisuk-mypage.etoos.com'
  }
};

const LMS_PAGE_URL = {
  LOCAL: {
    cheongsol: 'https://local-cheongsol-lms.etoos.com',
    hyper: 'https://local-hyper-lms.etoos.com',
    neoneo: 'https://local-neoneo-lms.etoos.com',
    answer: 'https://local-answer-lms.etoos.com',
    gisuk: 'https://local-cheongsol-lms.etoos.com'
  },
  ALPHA: {
    cheongsol: 'https://dev-cheongsol-lms.etoos.com',
    hyper: 'https://dev-hyper-lms.etoos.com',
    neoneo: 'https://dev-neoneo-lms.etoos.com',
    answer: 'https://dev-answer-lms.etoos.com',
    gisuk: 'https://dev-cheongsol-lms.etoos.com'
  },
  BETA: '',
  REAL: {
    cheongsol: 'https://cheongsol-lms.etoos.com',
    hyper: 'https://hyper-lms.etoos.com',
    neoneo: 'https://neoneo-lms.etoos.com',
    answer: 'https://answer-lms.etoos.com',
    gisuk: 'https://cheongsol-lms.etoos.com'
  },
  QA: {
    cheongsol: 'https://qa-cheongsol-lms.etoos.com',
    hyper: 'https://qa-hyper-lms.etoos.com',
    neoneo: 'https://qa-neoneo-lms.etoos.com',
    answer: 'https://qa-answer-lms.etoos.com',
    gisuk: 'https://qa-cheongsol-lms.etoos.com'
  }
};

const AWS_S3_BUCKET_URL = {
  LOCAL: 'https://dev-acaimg.etoos.com/movie/mathdaily',
  ALPHA: 'https://dev-acaimg.etoos.com/movie/mathdaily',
  BETA: 'https://dev-acaimg.etoos.com/movie/mathdaily',
  QA: 'https://qa-acaimg.etoos.com/movie/mathdaily',
  REAL: 'https://acaimg.etoos.com/movie/mathdaily'
};

const AWS_S3_RESOURCE_URL = {
  LOCAL: 'https://dev-acaimg.etoos.com',
  ALPHA: 'https://dev-acaimg.etoos.com',
  BETA: 'https://dev-acaimg.etoos.com',
  QA: 'https://qa-acaimg.etoos.com',
  REAL: 'https://acaimg.etoos.com'
};

const HOMEPAGE_URL = {
  LOCAL: {
    '0010': 'http://local-cheongsol-gangbuk.etoos.com:8081',
    '0020': 'http://local-n1-neoneo.etoos.com:8081',
    '0021': 'http://local-hyper-gangnam.etoos.com:8081',
    '0030': 'http://local-cheongsol-gangbuk.etoos.com:8081',
    '0040': 'http://local-cheongsol-bucheon.etoos.com:8081',
    '0050': 'http://local-cheongsol-bundang.etoos.com:8081',
    '0060': 'http://local-gisuk.etoos.com:8081',
    '0080': 'http://local-cheongsol-ilsan.etoos.com:8081',
    '0090': 'http://local-cheongsol-pyeongchon.etoos.com:8081',
    '0110': 'http://local-hyper-medicalgisuk.etoos.com:8081',
    '0120': 'http://local-hyper-gisuk.etoos.com:8081',
    '0130': 'http://local-hyper-mokdong.etoos.com:8081',
    '0310': 'http://local-hyper-medical.etoos.com:8081',
    '0500': 'http://local-cheongsol-ilsan-neo.etoos.com:8081',
    '0600': 'meta',
    '0011': 'http://local-cheongsol-o2o.etoos.com:8090'
  },
  ALPHA: {
    '0010': 'https://dev-cheongsol-gangbuk.etoos.com',
    '0020': 'https://dev-n1-neoneo.etoos.com',
    '0021': 'https://dev-hyper-gangnam.etoos.com',
    '0030': 'https://dev-cheongsol-gangbuk.etoos.com',
    '0040': 'https://dev-cheongsol-bucheon.etoos.com',
    '0050': 'https://dev-cheongsol-bundang.etoos.com',
    '0060': 'https://dev-gisuk.etoos.com',
    '0080': 'https://dev-cheongsol-ilsan.etoos.com',
    '0090': 'https://dev-cheongsol-pyeongchon.etoos.com',
    '0110': 'https://dev-hyper-medicalgisuk.etoos.com',
    '0120': 'https://dev-hyper-gisuk.etoos.com',
    '0130': 'https://dev-hyper-mokdong.etoos.com',
    '0310': 'https://dev-hyper-medical.etoos.com',
    '0500': 'https://dev-cheongsol-ilsan-neo.etoos.com',
    '0600': 'meta',
    '0011': 'https://dev-cheongsol-o2o.etoos.com'
  },
  BETA: {
    '0010': 'https://dev-cheongsol-gangbuk.etoos.com',
    '0020': 'https://dev-n1-neoneo.etoos.com',
    '0021': 'https://dev-hyper-gangnam.etoos.com',
    '0030': 'https://dev-cheongsol-gangbuk.etoos.com',
    '0040': 'https://dev-cheongsol-bucheon.etoos.com',
    '0050': 'https://dev-cheongsol-bundang.etoos.com',
    '0060': 'https://dev-gisuk.etoos.com',
    '0080': 'https://dev-cheongsol-ilsan.etoos.com',
    '0090': 'https://dev-cheongsol-pyeongchon.etoos.com',
    '0110': 'https://dev-hyper-medicalgisuk.etoos.com',
    '0120': 'https://dev-hyper-gisuk.etoos.com',
    '0130': 'https://dev-hyper-mokdong.etoos.com',
    '0310': 'https://dev-hyper-medical.etoos.com',
    '0500': 'https://dev-cheongsol-ilsan-neo.etoos.com',
    '0600': 'meta',
    '0011': 'https://dev-cheongsol-o2o.etoos.com'
  },
  REAL: {
    '0010': 'https://cheongsol-gangbuk.etoos.com',
    '0020': 'https://n1-neoneo.etoos.com',
    '0021': 'https://hyper-gangnam.etoos.com',
    '0030': 'https://cheongsol-gangbuk.etoos.com',
    '0040': 'https://cheongsol-bucheon.etoos.com',
    '0050': 'https://cheongsol-bundang.etoos.com',
    '0060': 'https://gisuk.etoos.com',
    '0080': 'https://cheongsol-ilsan.etoos.com',
    '0090': 'https://cheongsol-pyeongchon.etoos.com',
    '0110': 'https://hyper-medicalgisuk.etoos.com',
    '0120': 'https://hyper-gisuk.etoos.com',
    '0130': 'https://hyper-mokdong.etoos.com',
    '0310': 'https://hyper-medical.etoos.com',
    '0500': 'https://cheongsol-ilsan-neo.etoos.com',
    '0600': 'meta',
    '0011': 'https://cheongsol-o2o.etoos.com'
  },
  QA: {
    '0010': 'https://qa-cheongsol-gangbuk.etoos.com',
    '0020': 'https://qa-n1-neoneo.etoos.com',
    '0021': 'https://qa-hyper-gangnam.etoos.com',
    '0030': 'https://qa-cheongsol-gangbuk.etoos.com',
    '0040': 'https://qa-cheongsol-bucheon.etoos.com',
    '0050': 'https://qa-cheongsol-bundang.etoos.com',
    '0060': 'https://qa-gisuk.etoos.com',
    '0080': 'https://qa-cheongsol-ilsan.etoos.com',
    '0090': 'https://qa-cheongsol-pyeongchon.etoos.com',
    '0110': 'https://qa-hyper-medicalgisuk.etoos.com',
    '0120': 'https://qa-hyper-gisuk.etoos.com',
    '0130': 'https://qa-hyper-mokdong.etoos.com',
    '0310': 'https://qa-hyper-medical.etoos.com',
    '0500': 'https://qa-cheongsol-ilsan-neo.etoos.com',
    '0600': 'meta',
    '0011': 'https://qa-cheongsol-o2o.etoos.com'
  }
};
const MY_PAGE_URL = {
  LOCAL: {
    '0010': 'http://local-cheongsol-my.etoos.com:3000',
    '0020': 'http://local-cheongsol-my.etoos.com:3000',
    '0021': 'http://local-hyper-my.etoos.com:3000',
    '0030': 'http://local-cheongsol-my.etoos.com:3000',
    '0040': 'http://local-cheongsol-my.etoos.com:3000',
    '0050': 'http://local-cheongsol-my.etoos.com:3000',
    '0060': 'http://local-gisuk-my.etoos.com:3000',
    '0080': 'http://local-cheongsol-my.etoos.com:3000',
    '0090': 'http://local-cheongsol-my.etoos.com:3000',
    '0110': 'http://local-hyper-my.etoos.com:3000',
    '0120': 'http://local-hyper-my.etoos.com:3000',
    '0130': 'http://local-hyper-my.etoos.com:3000',
    '0310': 'http://local-hyper-my.etoos.com:3000',
    '0500': 'http://local-cheongsol-my.etoos.com:3000',
    '0600': 'meta',
    '0011': 'http://local-cheongsol-my.etoos.com:3000'
  },
  ALPHA: {
    '0010': 'https://dev-cheongsol-my.etoos.com',
    '0020': 'https://dev-cheongsol-my.etoos.com',
    '0021': 'https://dev-hyper-my.etoos.com',
    '0030': 'https://dev-cheongsol-my.etoos.com',
    '0040': 'https://dev-cheongsol-my.etoos.com',
    '0050': 'https://dev-cheongsol-my.etoos.com',
    '0060': 'https://dev-gisuk-my.etoos.com',
    '0080': 'https://dev-cheongsol-my.etoos.com',
    '0090': 'https://dev-cheongsol-my.etoos.com',
    '0110': 'https://dev-hyper-my.etoos.com',
    '0120': 'https://dev-hyper-my.etoos.com',
    '0130': 'https://dev-hyper-my.etoos.com',
    '0310': 'https://dev-hyper-my.etoos.com',
    '0500': 'https://dev-cheongsol-my.etoos.com',
    '0600': 'meta',
    '0011': 'https://dev-cheongsol-my.etoos.com'
  },
  BETA: {
    '0010': 'https://dev-cheongsol-my.etoos.com',
    '0020': 'https://dev-cheongsol-my.etoos.com',
    '0021': 'https://dev-hyper-my.etoos.com',
    '0030': 'https://dev-cheongsol-my.etoos.com',
    '0040': 'https://dev-cheongsol-my.etoos.com',
    '0050': 'https://dev-cheongsol-my.etoos.com',
    '0060': 'https://dev-gisuk-my.etoos.com',
    '0080': 'https://dev-cheongsol-my.etoos.com',
    '0090': 'https://dev-cheongsol-my.etoos.com',
    '0110': 'https://dev-hyper-my.etoos.com',
    '0120': 'https://dev-hyper-my.etoos.com',
    '0130': 'https://dev-hyper-my.etoos.com',
    '0310': 'https://dev-hyper-my.etoos.com',
    '0500': 'https://dev-cheongsol-my.etoos.com',
    '0600': 'meta',
    '0011': 'https://dev-cheongsol-my.etoos.com'
  },
  REAL: {
    '0010': 'https://cheongsol-my.etoos.com',
    '0020': 'https://cheongsol-my.etoos.com',
    '0021': 'https://hyper-my.etoos.com',
    '0030': 'https://cheongsol-my.etoos.com',
    '0040': 'https://cheongsol-my.etoos.com',
    '0050': 'https://cheongsol-my.etoos.com',
    '0060': 'https://gisuk-my.etoos.com',
    '0080': 'https://cheongsol-my.etoos.com',
    '0090': 'https://cheongsol-my.etoos.com',
    '0110': 'https://hyper-my.etoos.com',
    '0120': 'https://hyper-my.etoos.com',
    '0130': 'https://hyper-my.etoos.com',
    '0310': 'https://hyper-my.etoos.com',
    '0500': 'https://cheongsol-my.etoos.com',
    '0600': 'meta',
    '0011': 'https://cheongsol-my.etoos.com'
  },
  QA: {
    '0010': 'https://qa-cheongsol-my.etoos.com',
    '0020': 'https://qa-cheongsol-my.etoos.com',
    '0021': 'https://qa-hyper-my.etoos.com',
    '0030': 'https://qa-cheongsol-my.etoos.com',
    '0040': 'https://qa-cheongsol-my.etoos.com',
    '0050': 'https://qa-cheongsol-my.etoos.com',
    '0060': 'https://qa-gisuk-my.etoos.com',
    '0080': 'https://qa-cheongsol-my.etoos.com',
    '0090': 'https://qa-cheongsol-my.etoos.com',
    '0110': 'https://qa-hyper-my.etoos.com',
    '0120': 'https://qa-hyper-my.etoos.com',
    '0130': 'https://qa-hyper-my.etoos.com',
    '0310': 'https://qa-hyper-my.etoos.com',
    '0500': 'https://qa-cheongsol-my.etoos.com',
    '0600': 'meta',
    '0011': 'https://qa-cheongsol-my.etoos.com'
  }
};
const ETOOS_MEMBER_EDIT_URL = {
  LOCAL: 'https://dev-member.etoos.com/mypage/gate/home',
  ALPHA: 'https://qa-member.etoos.com/mypage/gate/home',
  BETA: 'https://stage-member.etoos.com/mypage/gate/home',
  REAL: 'https://member.etoos.com/mypage/gate/home',
  QA: 'https://stage-member.etoos.com/mypage/gate/home'
};

const WAITING_CLIENT = {
  LOCAL: {
    server_type: 'qa',
    client_id: '65d435e48dadaf0cf4b82a7f',
    client_url: 'https://qa-portal.etoos.com/waiting/65d435e48dadaf0cf4b82a7f'
  },
  ALPHA: {
    server_type: 'qa',
    client_id: '65d435e48dadaf0cf4b82a7f',
    client_url: 'https://qa-portal.etoos.com/waiting/65d435e48dadaf0cf4b82a7f'
  },
  BETA: '',
  QA: {
    server_type: 'qa',
    client_id: '65d435e48dadaf0cf4b82a7f',
    client_url: 'https://qa-portal.etoos.com/waiting/65d435e48dadaf0cf4b82a7f'
  },
  REAL: {
    server_type: 'live',
    client_id: '661736d5c99429385d1e12da',
    client_url: 'https://portal.etoos.com/waiting/661736d5c99429385d1e12da'
  }
};

const HP_CD_LIST = {
  '0010': 'C004954',
  '0030': 'C004954',
  '0040': 'C004955',
  '0050': 'C004956',
  '0080': 'C004959',
  '0090': 'C004960',
  '0130': 'C009417',
  '0021': 'C009418',
  '0110': 'C009419',
  '0120': 'C009420',
  '0060': 'C009426',
  '0310': 'C010000',
  '0500': 'C020000'
};

const BTYPE_CD_LIST = {
  '0010': 'nsu',
  '0030': 'nsu',
  '0040': 'nsu',
  '0050': 'nsu',
  '0080': 'normal',
  '0090': 'nsu',
  '0130': 'nsu',
  '0021': 'normal',
  '0110': 'normal',
  '0120': 'normal',
  '0060': 'normal',
  '0310': 'normal',
  '0500': 'normal'
};

module.exports = {
  TITLE: process.env.npm_package_title,
  PHASE: APPLICATION_PHASE,
  APPLICATION_PHASE,
  DOMAIN_ID: DOMAIN_ID[APPLICATION_PHASE],
  VERSION: APPLICATION_VERSION,
  STATIC_DOMAIN: STATIC_DOMAIN[APPLICATION_PHASE],
  TMS_API_URL: TMS_API_URL[APPLICATION_PHASE],
  API_URL: API_URL[APPLICATION_PHASE],
  O2O_API_URL: O2O_API_URL[APPLICATION_PHASE],
  ETOOS_MEMBER_LOGIN_URL: ETOOS_MEMBER_LOGIN_URL[APPLICATION_PHASE],
  OLD_MY_PAGE_URL: OLD_MY_PAGE_URL[APPLICATION_PHASE],
  AWS_S3_BUCKET_URL: AWS_S3_BUCKET_URL[APPLICATION_PHASE],
  ETOOS_MEMBER_LOGOUT_URL: ETOOS_MEMBER_LOGOUT_URL[APPLICATION_PHASE],
  LMS_PAGE_URL: LMS_PAGE_URL[APPLICATION_PHASE],
  AWS_S3_RESOURCE_URL: AWS_S3_RESOURCE_URL[APPLICATION_PHASE],
  HOMEPAGE_URL: HOMEPAGE_URL[APPLICATION_PHASE],
  MY_PAGE_URL: MY_PAGE_URL[APPLICATION_PHASE],
  CLIENT_ID: CLIENT_ID[APPLICATION_PHASE],
  CLIENT_SECRET: CLIENT_SECRET[APPLICATION_PHASE],
  CALLBACK_URL: CALLBACK_URL[APPLICATION_PHASE],
  MCALLBACK_URL: MCALLBACK_URL[APPLICATION_PHASE],
  METOOS_MEMBER_LOGOUT_URL: METOOS_MEMBER_LOGOUT_URL[APPLICATION_PHASE],
  BRANCH_TYPE: BRANCH_TYPE[APPLICATION_PHASE],
  PROFILE: PROFILE[APPLICATION_PHASE],
  ETOOS_MEMBER_EDIT_URL: ETOOS_MEMBER_EDIT_URL[APPLICATION_PHASE],
  GATE_DOMAIN: GATE_DOMAIN[APPLICATION_PHASE],
  WAITING_CLIENT: WAITING_CLIENT[APPLICATION_PHASE],
  HP_CD_LIST,
  BTYPE_CD_LIST
};
