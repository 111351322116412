import {httpClient, httpO2OClient} from '../httpclient/HttpClientFactory';

export default {
  getMrCodeList: (codeBtn) => httpClient.get(`/api/common/code/${codeBtn}`).then((res) => res.data),
  getUserInfo: () => httpClient.get(`/api/common/userInfo`).then((res) => res.data),
  getMobileGnbMenu: (branchCd) => httpClient.get(`/api/common/gnb/mobile?branchCd=${branchCd}`).then((res) => res.data),
  getQueueing: (request) => httpClient.get(`/api/common/queueing`, { params: request }).then((res) => res.data),
  getMenuListData: (request) => httpO2OClient.get(`/main-menu/list`, { params: request }).then((res) => res.data),
  getRecruitData: (request) => httpO2OClient.get(`/recruit-mng/home-recruit`, { params: request }).then((res) => res.data),
  getTimeTableData: (request) => httpO2OClient.get(`/aca-oprt/timetable-mng/timetable-menus`, { params: request }).then((res) => res.data),
  getMemNo: (request) => httpO2OClient.get(`/common/member/new-my-page`, { params: request }).then((res) => res.data)
};
