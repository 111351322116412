<template>
  <div id="poplectureleList" class="wrap_ly_pop" style="display: block">
    <div class="ly_pop">
      <div class="ly_header">
        <h5>{{ detailLecture.groupName }}</h5>
        <a href="javascript:void(0)" class="close" @click="$emit('close')">close</a>
      </div>
      <div class="ly_body">
        <div class="tbl_data">
          <table id="turnOrdTable">
            <colgroup>
              <col style="width:15%;" />
              <col />
              <col style="width:30%;" />
            </colgroup>
            <thead>
              <tr>
                <th></th>
                <th>강의주제</th>
                <th>강의영상</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in detailLecture.turnOrdResponses" :key="item.turnOrd">
                <td>{{ item.turnordNo }}</td>
                <td>{{ item.lectureTopic }}</td>
                <td v-if="item.lectureVideoUrl !== null && item.lectureVideoUrl !== ''">
                  <a :href="item.lectureVideoUrl" target="_blank">
                    <img src="https://acaimg.etoos.com/neo/pc/images/cnt/center/pop_btn.png" />
                  </a>
                </td>
                <td v-else class="disable">
                  <a href="javascript:void(0);" disabled>준비중</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReplayTurnOrdLecturePopupP',
  props: ['detailLecture'],
  data() {
    return {};
  }
};
</script>
