import { httpClient } from '../httpclient/HttpClientFactory';

export default {
  getMathDmTest: ({ studentCd, branchCd }) => {
    return httpClient
      .get(`/api/v1/math-dm/test?studentCds=${studentCd}&selectBranchCd=${branchCd}`)
      .then((res) => res.data);
  },
  getMyMathDmInfo: ({ studentCd, branchCd, unityDtlExamSeq }) => {
    return httpClient
      .get(`/api/v1/math-dm/info?studentCds=${studentCd}&selectBranchCd=${branchCd}&unityDtlExamSeq=${unityDtlExamSeq}`)
      .then((res) => res.data);
  },
  getMathDmTestSubjt: (turnOrdSeq) => {
    return httpClient.get(`/api/unity-exam/v1/exam/turn-ord-seq/${turnOrdSeq}`).then((res) => res.data);
  },
  getMathDmResolveTest: (branchCd, studentCd) => {
    return httpClient.get(`/api/v1/math-dm/resolve-test/${branchCd}/${studentCd}`).then((res) => res.data);
  },
  getMathDmTargetTestInfo: (studentCd, turnOrdSeq) => {
    return httpClient
      .get(`/api/v1/math-dm/analysis?studentCds=${studentCd}&turnOrdSeq=${turnOrdSeq}`)
      .then((res) => res.data);
  },
  getChildSubject: (turnOrdSeq) =>
    httpClient.get(`/api/v1/math-dm/subject?turnOrdSeq=${turnOrdSeq}`).then((res) => res.data)
};
