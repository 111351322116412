import actions from './actions';
import mutations from './mutations';

const state = {
  lectures: [],
  searchItems: {
    subjects: {},
    searchTeachers: {}
  }
};

export default {
  state,
  actions,
  mutations
};
