export const windowOpenPopup = {
  data() {
    return {};
  },
  methods: {
    openWindowPopup(href, windowName, targetWindowName, callBackFunction, size = 'width=1300,height=800', noneRefresh) {
      window.name = windowName;
      const new_window = window.open(href, targetWindowName, size);
      if (new_window == null) {
        alert('팝업 차단을 해제해주세요.');
      }
      if (noneRefresh) {
        return;
      }
      new_window.onbeforeunload = function() {
        location.reload();
      };
    }
  }
};
