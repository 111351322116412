<template>
  <div class="module-graph">
    <div class="wrap_title pc_only">
      <h5 class="tit_cont">
        <span>{{ graphTitle }}</span>
      </h5>
      <div v-if="radioBoxType === 2" class="graph_sort_area">
        <label class="lab_radio" for="sortRadio1">
          <input
            type="radio"
            id="sortRadio1"
            class="inp_radio"
            name="sortRadio"
            v-model="localChartTargetField"
            value="pctrk"
          />
          <span class="ico_radio"></span>백분위
        </label>
        <label class="lab_radio" for="sortRadio2">
          <input
            type="radio"
            id="sortRadio2"
            class="inp_radio"
            name="sortRadio"
            v-model="localChartTargetField"
            value="orgScr"
          />
          <span class="ico_radio"></span>원점수
        </label>
      </div>
      <div v-else-if="radioBoxType === 3" class="graph_sort_area">
        <label class="lab_radio" for="sortRadio1">
          <input
            type="radio"
            id="sortRadio1"
            class="inp_radio"
            name="sortRadio"
            v-model="localChartTargetField"
            value="pctrk"
          />
          <span class="ico_radio"></span>백분위
        </label>
        <label class="lab_radio" for="sortRadio2">
          <input
            type="radio"
            id="sortRadio2"
            class="inp_radio"
            name="sortRadio"
            v-model="localChartTargetField"
            value="orgScr"
          />
          <span class="ico_radio"></span>원점수
        </label>
        <label class="lab_radio" for="sortRadio3">
          <input
            type="radio"
            id="sortRadio3"
            class="inp_radio"
            name="sortRadio"
            v-model="localChartTargetField"
            value="highAcumlt"
          />
          <span class="ico_radio"></span>상위누적
        </label>
      </div>
    </div>
    <div class="cont_graph_result pc_only">
      <button type="button" class="btn_setting" @click="openModal">그래프 설정<span class="ico_arr">▼</span></button>
      <div class="box_graph_cont" style="height: 400px">
        <line-chart :chart-data="chartData" :options="chartOptions" />
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from './LineChart.js';

export default {
  components: {
    LineChart
  },
  watch: {
    localChartTargetField(field) {
      this.$emit('set-chart-field', field);
    },
    chartTargetField: {
      immediate: true,
      handler(field) {
        this.localChartTargetField = field;
      }
    }
  },
  data() {
    return {
      localChartTargetField: 'pctrk'
    };
  },
  props: {
    graphTitle: {
      type: String,
      required: true
    },
    radioBoxType: {
      type: Number,
      required: true
    },
    chartData: {
      default() {
        return {};
      }
    },
    chartOptions: { default: null },
    chartTargetField: { default: 'pctrk' }
  },
  methods: {
    openModal() {
      this.$emit('open-Modal');
      document.body.classList.add('noscroll');
    }
  }
};
</script>

<style></style>
