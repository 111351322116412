import axios from 'axios';
import store from '../store';
import { API_URL, TMS_API_URL, O2O_API_URL } from '@build/build.config';
import TmsCookieUtil from '../utils/TmsCookieUtil';

const DEFAULT_HEADER = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  Pragma: 'no-cache',
  etssso: getCookie('etssso')
};

const TMS_HEADER = {
  'Content-Type': 'application/json'
};
// const CancelToken = axios.CancelToken;
// const source = CancelToken.source();

export const httpClient = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  headers: DEFAULT_HEADER
});

export const httpO2OClient = axios.create({
  baseURL: O2O_API_URL,
  withCredentials: false,
  headers: DEFAULT_HEADER
});

export const tmsHttpClient = axios.create({
  baseURL: TMS_API_URL,
  withCredentials: false,
  headers: TMS_HEADER
});

export const tmsTokenHttpClient = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  headers: TMS_HEADER
});

export const tmsTest = axios.create({
  baseURL: TMS_API_URL,
  withCredentials: false,
  headers: TMS_HEADER
});

tmsTest.interceptors.request.use(function(config) {
  return config;
});

function getCookie(cname) {
  var name = cname + '=';
  var decodedCookie = unescape(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
}

tmsHttpClient.interceptors.request.use(function(config) {
  const tConfig = config;
  if (tConfig.url.indexOf('?') === -1) {
    tConfig.url += '?';
  }

  TmsCookieUtil.getTmsToken().then((token) => {
    tConfig.url += '&Authorization=' + token;
  });

  return tConfig;
});

httpClient.interceptors.request.use(
  function(config) {
    // 요청 성공 직전 호출됩니다.
    // axios 설정값을 넣습니다. (사용자 정의 설정도 추가 가능)
    //const userInfo = store.userInfo;
    const localStorageStore = JSON.parse(localStorage.vuex);
    const etoosMemNo = localStorageStore.common.userInfo.etoosMemNo;
    if (config.url !== '/api/common/userInfo' && !etoosMemNo) {
      throw new axios.Cancel(`request url:${config.url} ` + 'No etoosMemNo');
    }
    return config;
  },
  function(error) {
    // 요청 에러 직전 호출됩니다.
    return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(
  function(response) {
    /*
      http status가 200인 경우
      응답 성공 직전 호출됩니다. 
      .then() 으로 이어집니다.
  */
    return response;
  },

  function(error) {
    /*
      http status가 200이 아닌 경우
      응답 에러 직전 호출됩니다.
      .catch() 으로 이어집니다.    
  */
    return Promise.reject(error);
  }
);
