<template>
  <div class="pay_content pass" v-if="!isMobile">
    <h4>
      <span>수강 중인 강의</span>
    </h4>
    <!-- search box 영역 -->
    <search-table-p ref="searchTable" :num-per-page="numPerPage" :api-path="ongoingSearchApiPath" @search="onSearch" @loadingStart="isLoading = true" />
    <!-- table 영역 -->
    <infinite-pass-table-p
      v-model="selectedLecturesList"
      :show-select="true"
      :menu-title="'수강중인 강의'"
      :headers="tableHeaders"
      :items="vodStdList"
      :rowDisable="(item) => item.viewYn === 'N'"
      :is-loading="isLoading"
      @confirmDelete="onConfirmDelete"
      @openPopup="openPopupInMobile"
    >
      <template v-slot:[`item.groupName`]="{ item }">
        <badge-box
          :unity-subjt-cd="item.unitySubjtCd"
          :subjt-nm="item.subjtNm"
          :lecture-type-cd="item.lectureTypeCd"
          :cls-middle="item.clsMiddle"
        />
        <span v-html="item.groupName"></span>
      </template>
      <template v-slot:[`item.curriTurnOrd`]="{ item }">{{ item.turnOrd }}회차</template>
      <template v-slot:[`item.viewBtn`]="{ item }">
        <a @click="onViewClick(item)">보기&gt;</a>
      </template>
    </infinite-pass-table-p>
  </div>
  <!--  MOBILE -->
  <div class="mypage_comm pass_mo" v-else>
    <div class="box_tab">
      <div class="tab_navi size2">
        <ul class="list_tab">
          <li>
            <router-link to="/infinite-pass/all" class="btn_tab" data-href="tab1"><span class="inner">전체 강좌</span></router-link>
          </li>
          <li class="on">
            <router-link to="/infinite-pass/ongoing" class="btn_tab" data-href="tab2"><span class="inner">수강중인 강의</span></router-link>
          </li>
        </ul>
      </div>
      <infinite-pass-table-m
        v-model="selectedLecturesList"
        :showSelect="true"
        :lectureInfo="lectureInfo"
        :num-per-page="numPerPage"
        :selectedFilterList="selectedFilterList"
        :rowDisable="(item) => item.viewYn === 'N'"
        :is-loading="isLoading"
        @openPopup="openPopupInMobile"
        @setNumPerPage="setNumPerPage"
        @confirmDelete="onConfirmDelete"
      />
    </div>
    <search-table-m
      ref="searchTableM"
      v-model="selectedFilterList"
      :num-per-page="numPerPage"
      :api-path="ongoingSearchApiPath"
      @search="onSearchInMobile"
      @loadStart="isLoading"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SearchTableP from './components/SearchTableP.vue';
import SearchTableM from './components/SearchTableM.vue';
import InfinitePassTableP from './components/InfinitePassTableP.vue';
import InfinitePassTableM from './components/InfinitePassTableM.vue';
import InfinitePassApi from '@/api/InfinitePassApi';
import EventBus from '../../utils/eventBus';
import BadgeBox from './components/BadgeBox.vue';
import { PAGE_TITLE } from '../../const/const';

/**
 * 수강 중인 강의 화면
 * @since 2023.03.23
 * @author 유경호 ykh6242@etoos.com
 */
export default {
  name: 'ongoing-lecture-view',
  components: {
    BadgeBox,
    InfinitePassTableP,
    InfinitePassTableM,
    SearchTableP,
    SearchTableM
  },
  data: () => ({
    /*
     * 검색 테이블 관련 properties
     */
    ongoingSearchApiPath: '/api/infinitePass/curri/inclass/list',
    /*
     * 무한패스 테이블 컴포넌트 관련 properties
     */
    tableHeaders: [
      { text: '강좌', value: 'groupName', txtLeft: true, sortable: true },
      { text: '강사', value: 'teacherName', width: '8%', sortable: true },
      { text: '회차', value: 'curriTurnOrd', width: '10%' },
      { text: '복습 시간', value: 'playTmmm', width: '9%' },
      { text: '최종학습일', value: 'lastStdDt', width: '11%' },
      { text: '강의 보기', value: 'viewBtn', width: '8%' }
    ],
    selectedLecturesList: [],
    /*
     * 검색 테이블 컴포넌트 + 무한패스 테이블 컴포넌트 공통 properties
     */
    vodStdList: [],
    lectureInfo: {
      currPage: 1,
      totalCnt: 0,
      items: null
    },
    numPerPage: 10,
    selectedFilterList: {
      subjects: [],
      lectureTypeCds: []
    },
    isLoading: true
  }),
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      userInfo: (state) => state.common.userInfo
    })
  },
  methods: {
    ...mapActions(['deleteCurriInClass', 'setHeaderInfo']),
    changeTitle() {
      let title = '';
      if (this.userInfo.branchCd !== '') title = PAGE_TITLE[this.userInfo.branchCd];
      document.title = 'mypage_현장강의 무한패스 - 수강 중인 강의 | ' + title;
    },
    async onConfirmDelete() {
      const result = await this.deleteCurriInClass({
        vodStdNos: this.selectedLecturesList.map((el) => el.vodStdNo)
      });
      if (result.code !== '00000') {
        alert(`Error! ${result.message}`);
      }
      this.selectedLecturesList = [];
      if (this.isMobile) {
        await this.$refs.searchTableM.search();
      } else {
        await this.$refs.searchTable.search();
      }
    },
    onViewClick(item) {
      InfinitePassApi.playCurri(item.mediaContsKey, item.curriVodNo, item.viewYn);
    },
    onSearch(result) {
      this.isLoading = false;
      if (result.data.list) {
        this.vodStdList = result.data.list;
        EventBus.$emit('setPageInfo', result.data.pageInfo.currPage, result.data.pageInfo.numPerPage, result.data.pageInfo.totalRows);
      } else {
        EventBus.$emit('setPageInfo', 1, 10, 0);
      }
    },
    onSearchInMobile(result) {
      this.isLoading = false;
      if (result.code !== '00000') {
        return;
      }
      const data = result.data;
      this.lectureInfo.currPage = data.pageInfo.currPage;
      this.lectureInfo.totalCnt = data.pageInfo.totalRows;
      this.lectureInfo.items = data.list;
    },
    openPopupInMobile(target) {
      const popup = document.querySelector(`${target}`);
      popup.classList.add('open');
    },
    setNumPerPage(pageNum) {
      this.numPerPage = pageNum * 10;
    }
  },
  created() {
    this.setHeaderInfo({ menuName: '현장강의 무한패스', isPrev: false });
    this.changeTitle();
  }
};
</script>
