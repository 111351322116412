<template>
  <div class="box_table" :style="{ overflowX : isMobile ? 'auto' : '' }">
    <div class="expander">
      <span class="num_sum"
        ><em>{{ lectureList.length }}</em
        >건</span
      >
    </div>
    <table class="tbl_comm">
      <caption class="blind">
        강좌 수강내역 조회
      </caption>
      <thead>
        <tr style="width: 100%">
          <th :style="{ width: isMobile ? '30px' : '40px' }">No</th>
          <th :style="{ width: isMobile ? '45px' : '60px' }">강좌</th>
          <th style="width: 80px" :class="{ hide_mo: isMobile }">과목</th>
          <th style="width: 60px" :class="{ hide_mo: isMobile }">강사명</th>
          <th :style="{ width: isMobile ? '180px' : '260px' }">강좌명</th>
          <th style="width: 90px" :class="{ hide_mo: isMobile }">요일/교시</th>
          <th :style="{ width: isMobile ? '40px' : '50px' }">T수</th>
          <th :style="{ width: isMobile ? '85px' : '140px' }">수강기간</th>
          <th style="width: 60px" :class="{ hide_mo: isMobile }">강의실</th>
          <th style="width: 70px">수강</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(data, idx) in lectureList.slice((pageNum - 1) * pageSize, pageNum * pageSize)" :key="idx">
          <td>{{ data.no }}</td>
          <td>{{ lectureTypeMap[data.lectureTypeCd] }}</td>
          <td :class="{ hide_mo: isMobile }">{{ data.subjectName }}</td>
          <td :class="{ hide_mo: isMobile }">{{ data.teacherName }}</td>
          <td>
            <div style="width: 100%;text-align: left" v-html="data.groupName"></div>
          </td>
          <td :class="{ hide_mo: isMobile }" v-html="getSchedule(data.schedules)"></td>
          <td>{{ (data.groupTime || '0') === '0' ? '-' : data.groupTime + 'T' }}</td>
          <td>{{ formatGroupDate(data.groupSdate) }} ~<br v-if="isMobile" />{{ formatGroupDate(data.groupEdate) }}</td>
          <td :class="{ hide_mo: isMobile }">{{ data.groupRoom }}</td>
          <td>{{ regstateNm(data.groupSdate, data.groupEdate) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';

export default {
  name: 'LectureTable',
  props: ['lectureList', 'pageSize', 'pageNum', 'isCurr'],
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      lectureTypeMap: (state) => state.classroom.lecture.lectureTypeMap
    })
  },
  methods: {
    formatGroupDate(value) {
      return moment(value).format('YYYY.MM.DD');
    },
    regstateNm(sdate, edate) {
      const curDt = moment().format('YYYYMMDD');
      if (sdate <= curDt && edate >= curDt) {
        return '수강중';
      } else if (sdate > curDt && edate > curDt) {
        return '수강예정';
      } else {
        return this.isCurr ? '-' : this.isMobile ? '완료' : '수강완료';
      }
    },
    getSchedule(value) {
      const weeks = { mon: '월', tue: '화', wed: '수', thu: '목', fri: '금', sat: '토', sun: '일' };
      const timeLine = _.chain(value)
        .filter(function(e) {
          return e && e.perdCd.indexOf('r') < 0;
        })
        .groupBy('week')
        .map(function(v, k) {
          const time = _.chain(v)
            .map(function(ve) {
              return ve.perdName;
            })
            .sortBy()
            .value();
          return weeks[k] + ' ' + time;
        })
        .sortBy(function(e) {
          return _.values(weeks).indexOf(e.substr(0, 1));
        })
        .value()
        .join('<br />');

      return timeLine;
    }
  }
};
</script>
