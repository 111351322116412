<template>
  <div class="paging">
    <button type="button" class="btn_prev" @click="prevPage">
      <span class="blind">이전</span>
    </button>
    <div class="num_page">
      <a
        href="javascript:;"
        class="link_page"
        v-for="page in pageList"
        v-bind:key="page"
        :class="{ current: page === pageNum }"
        @click="setPage(page)"
      >
        {{ page }}
      </a>
    </div>
    <button type="button" class="btn_next" @click="nextPage">
      <span class="blind">다음</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ClassroomTablePage',
  props: ['pageSize', 'pageNum', 'totalSize', 'pageList'],
  computed: {
    totalPages() {
      let number = Math.floor((this.totalSize + this.pageSize - 1) / this.pageSize);
      if (number < 1) {
        number = 1;
      }
      return number;
    }
  },
  methods: {
    setPage(pageNum) {
      this.$emit('set-page', pageNum);
      this.setPageList();
    },
    setPageList() {
      const minPage = Math.floor((this.pageNum - 1) / 5) * 5 + 1,
        tempList = [];
      let maxPage = Math.floor((this.pageNum - 1) / 5) * 5 + 5;
      if (maxPage > this.totalPages) {
        maxPage = this.totalPages;
      }
      for (let i = minPage; i <= maxPage; i++) {
        tempList.push(i);
      }
      this.$emit('set-page-list', tempList);
    },
    prevPage() {
      this.setPage(this.pageNum - 1 === 0 ? 1 : this.pageNum - 1);
    },
    nextPage() {
      this.setPage(this.pageNum === this.totalPages ? this.totalPages : this.pageNum + 1);
    }
  },
  created() {
    this.setPageList();
  }
};
</script>
