import MutationTypes from '../../MutationTypes';
import MobileDetect from 'mobile-detect';
import {
  OLD_MY_PAGE_URL,
  ETOOS_MEMBER_LOGIN_URL,
  CLIENT_ID,
  CALLBACK_URL,
  MCALLBACK_URL,
  BRANCH_TYPE
} from '@build/build.config';
import { getBrandType } from '@/utils/util';
// 운영 = location.href.replace('local-','').split('-')[0].split('//')[1] 그 외 = location.href.replace('local-','').split('-')[0].split('//')[1]
export default {
  [MutationTypes.ERROR]: (state, payload) => {
    if (!payload.response) {
      //
    } else if (payload.response.status === 401) {
      const md = new MobileDetect(window.navigator.userAgent);
      let loginUrl = '';
      const currentUrl = location.href;
      const brandType = getBrandType(currentUrl);
      if (md.phone()) {
        loginUrl = ETOOS_MEMBER_LOGIN_URL + '?clientId=' + CLIENT_ID[brandType] + '&callbackUrl=' + MCALLBACK_URL[brandType] + '&branchType=' + BRANCH_TYPE[brandType];
        location.href = loginUrl + '&returnURL=' + currentUrl;
      } else {
        loginUrl = ETOOS_MEMBER_LOGIN_URL + '?clientId=' + CLIENT_ID[brandType] + '&callbackUrl=' + CALLBACK_URL[brandType] + '&branchType=' + BRANCH_TYPE[brandType];
        location.href = loginUrl + '&returnURL=' + currentUrl;
      }
    } else if (payload.response.status === 403) {
      window.setTimeout(() => {
        alert('본 서비스는 재원생 전용 서비스로 수학 DM 신청자만 이용 가능합니다.');
        location.href = OLD_MY_PAGE_URL[getBrandType(location.href)];
      }, 500);
    }
  },
  [MutationTypes.SET_IS_MOBILE]: (state, payload) => {
    if (payload.phone()) {
      payload = true;
    } else {
      payload = false;
    }
    state.isMobile = payload;
  },
  [MutationTypes.SET_LOADING]: (state, payload) => {
    state.isLoading = payload;
  },
  [MutationTypes.USER_INFO]: (state, payload) => {
    state.userInfo = payload.data;
  },
  [MutationTypes.SET_IS_API_CALLED]: (state, payload) => {
    state.isApiCalled = payload;
  },
  [MutationTypes.SET_HEADER_NAME]: (state, payload) => {
    state.menuName = payload || '마이페이지';
  },
  [MutationTypes.SET_HEADER_PREV]: (state, payload) => {
    state.isPrev = payload || false;
  },
  [MutationTypes.SET_MENU_HTML]: (state, payload) => {
    state.mobileGnb.menuHtml = payload;
  },
  [MutationTypes.SET_RECRUIT_JSON]: (state, payload) => {
    state.mobileGnb.recruitJson = payload;
  },
  [MutationTypes.SET_CODE]: (state, list) => {
    state.codeList = list;
  },
  [MutationTypes.SET_MENU_LIST]: (state, payload) => {
    state.menuList = payload;
  },
  [MutationTypes.SET_RECRUIT]: (state, payload) => {
    state.recruitList = payload;
  },
  [MutationTypes.SET_TIME_TABLE]: (state, payload) => {
    state.timeTableList = payload;
  },
  [MutationTypes.SET_MEM_NO]: (state, payload) => {
    state.memNo = payload;
  }
};
