import MutationTypesUnityExam from '@/store/MutationTypesUnityExam';

export default {
  [MutationTypesUnityExam.ENTER_ANSWER_YEAR_INFO]: (state, payload) => {
    state.enteranswer.enterAnswerYearInfo = payload.data;
  },
  [MutationTypesUnityExam.ENTER_ANSWER_EXAM_TAB_INFO]: (state, payload) => {
    state.enteranswer.enterAnswerExamTabInfo = payload.data;
  },
  [MutationTypesUnityExam.ENTER_ANSWER_EXAM_INFO]: (state, payload) => {
    state.enteranswer.enterAnswerExamInfo = payload.data;
  },
  [MutationTypesUnityExam.ENTER_ANSWER_DTL_EXAM_INFO]: (state, payload) => {
    state.enteranswer.enterAnswerDtlExamInfo = payload.data;
  },
  [MutationTypesUnityExam.ENTER_ANSWER_POSSIBLE_EXAM_INFO]: (state, payload) => {
    state.enteranswer.enterAnswerPossibleExamInfo = payload.data;
  },
  [MutationTypesUnityExam.ENTER_ANSWER_SELECT_EXAM_INFO]: (state, payload) => {
    state.enteranswer.enterAnswerSelectExamInfo = payload.data;
  },
  [MutationTypesUnityExam.SET_SELECT_EXAM_SEQ]: (state, payload) => {
    state.enteranswer.selectUnityExamSeq = payload || 0;
  },
  [MutationTypesUnityExam.SET_SELECT_DTL_EXAM_SEQ]: (state, payload) => {
    state.enteranswer.selectUnityDtlExamSeq = payload || 0;
  },
  [MutationTypesUnityExam.SET_SELECT_DTL_EXAM_TURN_ORD_SEQ]: (state, payload) => {
    state.enteranswer.selectUnityDtlExamTurnOrdSeq = payload || 0;
  },
  [MutationTypesUnityExam.SET_SELECT_EXAM_HIGH_SUBJT_SEQ]: (state, payload) => {
    state.enteranswer.selectUnityExamHighSubjtSeq = payload || 0;
  },
  [MutationTypesUnityExam.SET_SELECT_EXAM_SUBJT_SEQ]: (state, payload) => {
    state.enteranswer.selectUnityExamSubjtSeq = payload || 0;
  },
  [MutationTypesUnityExam.ENTER_ANSWER_EXAM_QUSTN_INFO]: (state, payload) => {
    state.enteranswer.enterAnswerExamQustnInfo = payload.data;
  },
  [MutationTypesUnityExam.ENTER_ANSWER_EXAM_QUSTN_TAM_INFO]: (state, payload) => {
    state.enteranswer.enterAnswerExamQustnTamInfo = payload.data;
  },
  [MutationTypesUnityExam.ANSWER_CAUSE_LIST]: (state, payload) => {
    state.enteranswer.answerCauseList = payload.data;
  },
  [MutationTypesUnityExam.WRANSR_CAUSE_LIST]: (state, payload) => {
    state.enteranswer.wransrCauseList = payload.data;
  }
};
