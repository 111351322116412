<style>
li {
  list-style-type: none;
}
</style>
<template>
  <div class="pop_layout answer popup_cont" style="width: 1199px">
    <div class="popti">강좌검색</div>
    <div class="popbody">
      <div class="seatab">
        <ul class="fclear">
          <li>
            <a id="#season" :class="{ on: tabNumber === 1 }" @click="tabChange('1')">{{ lectureTypeMap['1'] }}</a>
          </li>
          <li>
            <a id="#operation_dangwa" :class="{ on: tabNumber === 2 }" @click="tabChange('2')">
              {{ lectureTypeMap['2'] }}
            </a>
          </li>
          <li>
            <a id="#operation_special" :class="{ on: tabNumber === 3 }" @click="tabChange('3')">
              {{ lectureTypeMap['3'] }}
            </a>
          </li>
        </ul>
      </div>
      <div class="seacont">
        <div id="season" class="seaco seasonDiv">
          <div class="seahead">
            <div>
              <strong v-if="isSeason">{{ seasonData.seasonNm }}</strong>
              <strong v-if="isOper">{{ this.coursesSdate + ' ~ ' + this.coursesEdate }}</strong>
            </div>
            <div>
              <strong>{{ lectureText.join(',') }}</strong>
            </div>
            <div>
              <strong>
                {{ teacherText.join(',') }}
              </strong>
            </div>
            <div>
              <strong>{{ weekText.join(',') }}</strong>
            </div>
            <div>
              <strong>{{ perdText.join(',') }}</strong>
            </div>
          </div>
          <a class="seaall"><span>전체 열기</span></a>
          <div class="slide">
            <div class="seabody">
              <div v-if="isOper">
                <div class="seadata">
                  <b-datepicker v-model="fromDate" size="is-small" :min-date="seasonStartDate" :max-date="seasonEndDate" />
                  <b-datepicker v-model="toDate" size="is-small" :min-date="seasonStartDate" :max-date="seasonEndDate" />
                </div>
              </div>
              <div v-if="!isOper">
                <div class="scoll" style="height: 200px">
                  <ul>
                    <li>
                      <span class="btnrc">
                        <input type="radio" :id="'season'" :value="seasonData.seasonCd" v-model="seasonCd" />
                        <label :for="'season'">{{ seasonData.seasonNm }}</label>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <ul class="two fclear">
                  <li>
                    <span class="btnrc">
                      <input type="checkbox" :id="'lectureAll'" checked="" name="subjt" v-model="lectureChk" :value="''" @change="chkChange($event)" />
                      <label :for="'lectureAll'">전체</label>
                    </span>
                  </li>
                  <li v-for="(item, idx) in lectureList" :key="idx">
                    <span class="btnrc">
                      <input type="checkbox" :id="'lectrue' + item.id" :value="item.subjtCd" name="subjt" v-model="lectureChk" @change="chkChange($event)" />
                      <label :for="'lectrue' + item.id">{{ item.subjtNm }}</label>
                    </span>
                  </li>
                </ul>
              </div>

              <div>
                <div class="scoll">
                  <ul class="two fclear">
                    <li>
                      <span class="btnrc">
                        <input type="checkbox" id="teacherAll" name="teacher" :value="''" v-model="teacherCd" @change="chkChange($event)" />
                        <label for="teacherAll">전체</label>
                      </span>
                    </li>
                    <li v-for="(item, idx) in teachers" :key="idx">
                      <span class="btnrc">
                        <input type="checkbox" :id="'teacher' + idx" name="teacher" :value="item.teacherCd" v-model="teacherCd" @change="chkChange($event)" />
                        <label :for="'teacher' + idx">{{ item.teacherName }}</label>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <ul class="two fclear">
                  <li>
                    <span class="btnrc">
                      <input type="checkbox" id="r141" name="week" v-model="weekChk" :value="''" @change="chkChange($event)" />
                      <label for="r141">전체</label>
                    </span>
                  </li>
                  <li>
                    <span class="btnrc">
                      <input type="checkbox" id="r142" name="week" v-model="weekChk" :value="'mon'" @change="chkChange($event)" />
                      <label for="r142">월요일</label>
                    </span>
                  </li>
                  <li>
                    <span class="btnrc">
                      <input type="checkbox" id="r143" name="week" v-model="weekChk" :value="'tue'" @change="chkChange($event)" />
                      <label for="r143">화요일</label>
                    </span>
                  </li>
                  <li>
                    <span class="btnrc">
                      <input type="checkbox" id="r144" name="week" v-model="weekChk" :value="'wed'" @change="chkChange($event)" />
                      <label for="r144">수요일</label>
                    </span>
                  </li>
                  <li>
                    <span class="btnrc">
                      <input type="checkbox" id="r145" name="week" v-model="weekChk" :value="'thu'" @change="chkChange($event)" />
                      <label for="r145">목요일</label>
                    </span>
                  </li>
                  <li>
                    <span class="btnrc">
                      <input type="checkbox" id="r146" name="week" v-model="weekChk" :value="'fri'" @change="chkChange($event)" />
                      <label for="r146">금요일</label>
                    </span>
                  </li>
                  <li>
                    <span class="btnrc">
                      <input type="checkbox" id="r147" name="week" v-model="weekChk" :value="'sat'" @change="chkChange($event)" />
                      <label for="r147">토요일</label>
                    </span>
                  </li>
                  <li>
                    <span class="btnrc">
                      <input type="checkbox" id="r148" name="week" v-model="weekChk" :value="'sun'" @change="chkChange($event)" />
                      <label for="r148">일요일</label>
                    </span>
                  </li>
                </ul>
              </div>
              <div>
                <ul class="two fclear">
                  <li>
                    <span class="btnrc">
                      <input type="checkbox" id="perdAll" name="perd" :value="''" v-model="perdChk" @change="chkChange($event)" />
                      <label for="perdAll">전체</label>
                    </span>
                  </li>
                  <li v-for="(item, idx) in scheduleList" :key="idx">
                    <span v-if="item.perdCd.slice(0, 1) != 'r'" class="btnrc">
                      <input type="checkbox" :id="'perd' + idx" name="perd" :value="item.perdCd" v-model="perdChk" @change="chkChange($event)" />
                      <label :for="'perd' + idx">{{ item.exposureNm }}</label>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="seatext">
              <span class="input_text search" style="width: 240px"> <input type="text" placeholder="" v-model="groupName" /><a class="b" @click="getSearch()">검색</a> </span>
            </div>
          </div>
        </div>
      </div>
      <div class="lecfixed cnlay_btn mgt20">
        <div class="leccell fs12 ffam800 vab">총 {{ this.dataList.length }} 개</div>
      </div>
      <div class="lecfixed table mgt5">
        <div class="lecrow">
          <div class="leccell th bol" style="width:89px;"><a href="#" class="">과목명</a></div>
          <div class="leccell th bol" style="width:80px;"><a href="#" class="">강사명</a></div>
          <div class="leccell th bol"><a href="#" class="">강좌명</a></div>
          <div class="leccell th bol" style="width:128px;">요일 / 교시</div>
          <div class="leccell th bol" style="width:89px;">
            {{ getLecTm() === 'T' ? 'T수' : '분' }}
          </div>
          <div class="leccell th bol" style="width:191px;">운영기간</div>
          <div class="leccell th bol" style="width:65px;">강의실</div>
          <div class="leccell th bol borb" style="width:124px;">수강신청</div>
        </div>
        <div class="lecrow" v-for="(data, idx) in dataList.slice((pageNum - 1) * 10, pageNum * 10)" :key="idx" style="height: 53px">
          <div class="leccell td bolb">{{ data.subjtNm }}</div>
          <div class="leccell td bol">{{ data.teacherName }}</div>
          <div class="leccell td bol txt_l">
            <div class="pl">
              <template v-if="data.duplicate.length > 1 && (isAssign(data.regstateCd) || isStandBy(data.regstateCd))">
                <a href="#" class="one t_sty1" :class="dupCheckForClass(data)">{{ data.duplicate.length }}건</a>
              </template>
              <template v-else-if="isStandBy(data.regstateCd)">
                <a href="#" class="one bg3"> 대기<br v-if="data.waitingOrder > 0" />{{ data.waitingOrder > 0 ? data.waitingOrder + '번' : '' }} </a>
              </template>
              <span v-html="data.groupName"></span><span class="new" v-if="isAssign(data.regstateCd) && data.newfix === 'N'">N</span>
              <a href="#" class="more" v-if="data.lectureIntroFileUrl" @click="detailPop(data)">+</a>
            </div>
          </div>
          <div class="leccell td bol">
            <template v-for="(schedule, scheduleIdx) in getGroupTimeLine(data.schedules)"> {{ schedule }}<br v-if="scheduleIdx < getGroupTimeLine(data.schedules).length - 1" /> </template>
          </div>
          <div class="leccell td bol">
            {{ getLecTm() === 'T' ? (data.groupTime && data.groupTime > 0 ? data.groupTime + 'T' : '-') : (data.groupTime || 0) * 60 + '분' }}
          </div>
          <div class="leccell td bol">{{ getGroupDate(data) }}</div>
          <div class="leccell td bol">{{ data.groupRoom || '-' }}</div>
          <div class="leccell td bol borb">
            <template v-if="isGeneralLecture(data.lectureTypeCd)">
              <template v-if="isAssign(data.regstateCd) || isStandBy(data.regstateCd)">
                <template v-if="data.groupEdate < today">-</template>
                <template v-else>
                  <a
                    v-if="canEnrolment"
                    href="javascript:void(0);"
                    :class="{
                      wbtn: isAssign(data.regstateCd),
                      bbtn: !isAssign(data.regstateCd)
                    }"
                    class="combtn s dib"
                    style="width:58px"
                    @click="lectureCancelAssign(data)"
                  >{{ isAssign(data.regstateCd) ? '신청취소' : '대기취소' }}</a
                  >
                  <span class="combtn s dib disabled" style="width:58px" v-else>{{ isAssign(data.regstateCd) ? '신청취소' : '대기취소' }}</span>
                </template>
              </template>
              <span v-else-if="isTempAssign(data.regstateCd)" class="fc">입금대기중</span>
              <template v-else>
                <template v-if="data.groupEdate < today">
                  <span class="fcg">종료</span>
                </template>
                <template v-else-if="data.courseCount >= data.groupLimit">
                  <template v-if="data.waitYn === 'Y'">
                    <a v-if="canEnrolment" href="javascript:void(0);" class="combtn s dib bbtn" style="width:58px" @click="lectureAssign(data)">대기</a>
                    <span class="combtn s dib disabled" style="width:58px" v-else>대기</span>
                  </template>
                  <span v-else class="fc">마감</span>
                </template>
                <template v-else>
                  <a v-if="canEnrolment" href="javascript:void(0);" class="combtn s dib gbbtn" style="width:58px" @click="lectureAssign(data)">신청</a>
                  <span class="combtn s dib disabled" style="width:58px" v-else>신청</span>
                </template>
              </template>
            </template>
            <template v-else>
              <span v-if="isTempAssign(data.regstateCd)" class="fc">입금대기중</span>
              <template v-else-if="isAssign(data.regstateCd) || isTempAssign(data.regstateCd)">-</template>
              <template v-else>
                <template v-if="data.groupEdate >= today">
                  <template v-if="data.courseCount >= data.groupLimit">
                    <a v-if="data.waitYn === 'Y'" href="javascript:void(0);" class="combtn s dib bbtn" style="width:58px" @click="lectureAssign(data)">대기</a>
                    <span v-else class="fc">마감</span>
                  </template>
                  <a v-else href="javascript:void(0);" class="combtn s dib gbbtn" style="width:58px" @click="lectureAssign(data)">신청</a>
                </template>
                <span v-else class="fcg">종료</span>
              </template>
            </template>
          </div>
        </div>
      </div>
      <div v-if="dataList.length === 0 && printResult === true" class="txt_c noata" style="text-align: center; padding: 100px 0; border-bottom: 1px solid #dedede">
        검색결과가 없습니다
      </div>
      <div v-if="!isMobile" class="paging">
        <div class="num_page">
          <a class="prev" @click="setPage(pageNum - 1 === 0 ? 1 : pageNum - 1)">
            <img :src="awsResourceUrl + '/my/pc/v2/images/bt_prev.gif?ver=20201111'" class="vam" alt="이전" />
            <span class="blind">이전</span>
          </a>
          <a class="link_page" v-for="(page, idx) in pageList" :key="idx" @click="setPage(page)">
            <strong v-if="page === pageNum">{{ page }}</strong>
            <span v-else>{{ page }}</span>
          </a>
          <a class="next" @click="setPage(pageNum === totalPages ? totalPages : pageNum + 1)">
            <img :src="awsResourceUrl + '/my/pc/v2/images/bt_next.gif?ver=20201111'" class="vam" alt="다음" />
            <span class="blind">다음</span>
          </a>
        </div>
      </div>
      <div class="txt_r">
        <span class="checkbox mgl10 vab">
          <input type="checkbox" id="continu" v-model="continuChk" @change="continuLecture" />
          <label for="continu"><span class="box"></span> 계속 강좌신청하기</label>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { classRoomPopup } from '@/mixins/classRoomPopup';
import moment from 'moment';
import UiMypage from '@/utils/ui_mypage';
import TmsCookieUtil from '@/utils/TmsCookieUtil';
import DateUtil from '@/utils/DateUtil';
import { AWS_S3_RESOURCE_URL } from '@build/build.config';
import { REGSTATE_CD, LECTURE_TYPE_CD } from '@/const/classroom_const';
import { PAGE_TITLE } from '../../../const/const';

export default {
  name: 'lectureSearchP',
  data() {
    return {
      branchCd: '',
      seasonCd: '',
      seasonYear: '',
      studentCd: '',
      week: '',
      perdCd: '',
      seasonData: {},
      isSeason: false,
      isOper: false,
      tabNumber: 1,
      lectureList: [],
      lectureChk: [''],
      lectureTypeCd: '1',
      teacherCd: [''],
      lectureText: ['과목 전체'],
      weekChk: [''],
      teacherText: ['강사 전체'],
      weekText: ['요일 전체'],
      perdChk: [''],
      perdText: ['교시 전체'],
      coursesSdate: '',
      coursesEdate: '',
      dataList: [],
      groupName: '',
      pageNum: 1,
      pageSize: 10,
      pageList: [1],
      continuChk: true,
      fromDate: [],
      toDate: [],
      date: new Date(),
      awsResourceUrl: AWS_S3_RESOURCE_URL,
      teacherList: [],
      teachers: [],
      seasonStartDate: '',
      seasonEndDate: '',
      printResult: false,
      today: DateUtil.dateFormatter('{yyyy}{mm}{dd}', new Date()),
      PAGE_TITLE
    };
  },
  mixins: [classRoomPopup],
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      userInfo: (state) => state.common.userInfo,
      seasonList: (state) => state.classroom.schedule.seasonList,
      lectureSearchDataJae: (state) => state.classroom.lecture.lectureSearchDataJae,
      lectureSearchDataDan: (state) => state.classroom.lecture.lectureSearchDataDan,
      teacherData: (state) => state.classroom.lecture.teacherData,
      scheduleList: (state) => state.classroom.schedule.scheduleList,
      lectureCancelData: (state) => state.classroom.lecture.lectureCancelData,
      lectureRegisterData: (state) => state.classroom.lecture.lectureRegisterData,
      lectureTypeMap: (state) => state.classroom.lecture.lectureTypeMap,
      seasonPerdMap: (state) => state.classroom.schedule.seasonPerdMap
    }),
    canEnrolment() {
      console.log('isEnrolment: ', this.seasonData.isEnrolment);
      return this.seasonData.indvdlEnrolmentYn === 'Y' || this.seasonData.isEnrolment === 'Y';
    },
    totalPages() {
      return Math.floor(((this.dataList || []).length + this.pageSize - 1) / this.pageSize);
    }
  },
  filters: {},
  props: ['userInfo'],
  watch: {
    userInfo() {
      this.changeTitle();
    },
    seasonCd(e) {
      this.seasonData = _.find(this.seasonList, { seasonCd: e });
      this.getSchedule();
      this.getLectureSubject();
    },
    teacherCd(arg1) {
      this.teacherNames = '';
      arg1.forEach((cd) => {
        if (cd === '') {
          this.teacherText = ['강사 전체'];
        }
      });
    },
    lectureChk(arg1) {
      this.subjectNames = '';
      arg1.forEach((cd) => {
        if (cd === '') {
          this.lectureText = ['과목 전체'];
        }
      });
    },
    weekChk(arg1) {
      this.weekText = '';
      const day = [];
      arg1.forEach((cd) => {
        if (cd !== '') {
          this.weekChk.forEach((d) => {
            if (cd === d) day.push(this.weekFilter(d));
          });
        } else {
          day.push('요일 전체');
        }
      });
      this.weekText = day;
    },
    perdChk(arg1) {
      this.perdText = '';
      let perd = [];
      arg1.forEach((cd) => {
        if (cd !== '') {
          const seasonCdMap = this.seasonPerdMap[this.seasonYear] || {};
          const perdMap = seasonCdMap[this.seasonCd] || {};
          const name = perdMap[cd] || `${cd}교시`;
          perd.push(name);
        }
      });
      if (arg1.length === 1 && arg1.indexOf('') > -1) {
        perd = ['교시 전체'];
      }
      this.perdText = perd;
    },
    fromDate(arg1) {
      this.coursesSdate = moment(arg1).format('YYYY.MM.DD');
      if (this.fromDate > this.toDate) {
        alert('시작일보다 이전 날짜입니다. 다시 선택해 주세요');
        this.fromDate = new Date(moment(this.$route.query.seasonStartDt).format());
      }
    },
    toDate(arg1) {
      this.coursesEdate = moment(arg1).format('YYYY.MM.DD');
      if (this.fromDate > this.toDate) {
        alert('시작일보다 이전 날짜입니다. 다시 선택해 주세요');
        this.toDate = new Date(moment(this.$route.query.seasonEndDt).format());
      }
    }
  },
  created() {
    if (this.userInfo.branchCd === '') {
      this.getUser().finally(() => {
        this.changeTitle();
      });
    } else {
      this.changeTitle();
    }
    if (!this.userInfo.studentCd) {
      this.getUser().then(() => {
        this.getLectureType().then(() => {
          this.$forceUpdate();
        });
        if (Number(this.$route.query.lectureTypeCd) === 2 || Number(this.$route.query.lectureTypeCd) === 3) {
          this.isOper = true;
          this.setInitSearchPopup();
        } else {
          this.isSeason = true;
          this.setInitSearchPopup();
        }
      });
    } else {
      this.getLectureType().then(() => {
        this.$forceUpdate();
      });
      if (Number(this.$route.query.lectureTypeCd) === 2 || Number(this.$route.query.lectureTypeCd) === 3) {
        this.isOper = true;
        this.setInitSearchPopup();
      } else {
        this.isSeason = true;
        this.setInitSearchPopup();
      }
    }
  },
  mounted() {
    UiMypage.mypageUI.init();
  },
  destroyed() {},
  methods: {
    ...mapActions(['getSeason', 'getUser', 'getLectureSearchListJae', 'getTeacherList', 'getScheduleList', 'getLectureSearchListDan', 'lectureRegister', 'lectureCancel', 'getLectureType']),
    changeTitle() {
      let title = '';
      if (this.userInfo.branchCd !== '') title = PAGE_TITLE[this.userInfo.branchCd];
      document.title = 'mypage_수강신청 - 강좌검색 | ' + title;
    },
    setInitSearchPopup() {
      this.tabNumber = Number(this.$route.query.lectureTypeCd);
      this.branchCd = this.$route.query.branchCd;
      this.seasonYear = this.$route.query.seasonYear;
      this.studentCd = this.$route.query.studentCd;
      this.groupYear = this.$route.query.groupYear;
      this.fromDate = new Date(moment(this.$route.query.seasonStartDt).format());
      this.toDate = new Date(moment(this.$route.query.seasonEndDt).format());
      this.seasonStartDate = new Date(moment(this.$route.query.seasonStartDt).format());
      this.seasonEndDate = new Date(moment(this.$route.query.seasonEndDt).format());
      this.getSeasonList().finally(() => {
        const continuYn = TmsCookieUtil.getCookie('etscontinuChk');

        if (continuYn === 'N') {
          this.continuChk = false;
        }
        this.coursesEdate = this.$route.query.seasonEndDt;
        this.coursesSdate = this.$route.query.seasonStartDt;
        if (this.$route.query.week) {
          this.weekChk = [this.$route.query.week];
          this.weekText = [this.weekFilter(this.$route.query.week)];
          this.perdChk = [this.$route.query.perdCd];
          this.week = this.$route.query.week;
          this.perdCd = this.$route.query.perdCd;
        }
        this.getSchedule().finally(() => {
          this.perdChk = [this.$route.query.perdCd];
        });
        this.getLectureSubject();
      });
    },

    async getSeasonList() {
      const params = {};
      params.branchCd = this.branchCd;
      params.seasonYear = this.seasonYear;
      params.studentCd = this.studentCd;
      await this.getSeason(params).finally(() => {
        this.seasonCd = this.$route.query.seasonCd;
        this.seasonList.forEach((e) => {
          if (e.seasonEndDt <= DateUtil.dateFormatter('{yyyy}{mm}{dd}', this.date)) {
            e.confirm = 'Y';
          }
        });
        this.seasonData = _.find(this.seasonList, { seasonCd: this.seasonCd });
        this.getTeacher(true);
      });
    },
    getLectureSubject() {
      const params = {};
      params.branchCd = this.branchCd;
      params.studentCd = this.studentCd;
      params.groupYear = this.seasonYear;
      params.lectureTypeCd = this.tabNumber;
      params.seasonCd = this.seasonCd;

      if (this.isOper) {
        params.coursesSdate = this.coursesSdate.replace(/\./gi, '');
        params.coursesEdate = this.coursesEdate.replace(/\./gi, '');
        this.getLectureSearchListDan(params).then(() => {
          this.setSubjt();
        });
      } else {
        this.getLectureSearchListJae(params).then(() => {
          this.setSubjt();
        });
      }
    },
    getLectureSearchList() {
      const params = {};
      params.branchCd = this.branchCd;
      params.studentCd = this.studentCd;
      params.groupYear = this.seasonYear;
      params.lectureTypeCd = this.tabNumber;
      params.seasonCd = this.seasonCd;
      params.groupName = this.groupName;
      if (this.lectureChk.indexOf('') === -1) {
        params.subjtCds = this.lectureChk.join(',');
      }
      if (this.teacherCd.indexOf('') === -1) {
        params.teacherCds = this.teacherCd.join(',');
      }
      if (this.weekChk.indexOf('') === -1) {
        params.week = this.weekChk.join(',');
      }
      if (this.perdChk.indexOf('') === -1) {
        params.perdCds = this.perdChk.join(',');
      }
      let list = [];
      const resultData = [];
      this.dataList = [];
      const seData = this.seasonData;
      if (this.isSeason) {
        this.getLectureSearchListJae(params).then(() => {
          list = this.lectureSearchDataJae;
          _.chain(list)
            .sortBy('groupSdate')
            .sortBy('subjtCd')
            .sortBy(function(e) {
              return e.regstateCd == '1' || e.regstateCd == '6' || e.regstateCd == '7' ? 1 : 2;
            })
            .each(function(item) {
              item.studyRegStartDt = seData.studyRegStartDt;
              item.studyRegEndDt = seData.studyRegEndDt;
              resultData.push(item);
            })
            .value();
          this.dataList = resultData;
          this.printResult = true;
        });
      } else if (this.isOper) {
        params.coursesSdate = this.coursesSdate.replace(/\./gi, '');
        params.coursesEdate = this.coursesEdate.replace(/\./gi, '');
        this.getLectureSearchListDan(params).then(() => {
          list = this.lectureSearchDataDan;
          _.chain(list)
            .sortBy('groupSdate')
            .sortBy('subjtCd')
            .sortBy(function(e) {
              return e.regstateCd == '1' || e.regstateCd == '6' || e.regstateCd == '7' ? 1 : 2;
            })
            .each(function(item) {
              resultData.push(item);
            })
            .value();
          this.dataList = resultData;
          this.printResult = true;
        });
      }
    },
    getTeacher(full) {
      this.teacherCd = [''];
      let array = [];
      this.lectureChk.forEach((e) => {
        const array2 = _.chain(this.teacherList)
          .filter((v) => v.subjtCd == e)
          .value();
        array = [...array, ...array2];
      });
      if (full) {
        this.teachers = this.teacherList.filter((item, i) => {
          return (
            this.teacherList.findIndex((item2) => {
              return item.teacherCd === item2.teacherCd;
            }) === i
          );
        });
      } else {
        this.teachers = array.filter((item, i) => {
          return (
            array.findIndex((item2) => {
              return item.teacherCd === item2.teacherCd;
            }) === i
          );
        });
      }
    },
    chkChange(e) {
      if (e.target.value) {
        if (e.target.name === 'subjt') {
          if (this.lectureChk.indexOf('') > -1) {
            this.lectureChk.splice(this.lectureChk.indexOf(''), 1);
          }
          this.lectureText = [];
          this.lectureChk.forEach((cd) => {
            const subjt = _.find(this.lectureList, { subjtCd: cd });
            this.lectureText.push(subjt.subjtNm);
          });
          if (this.lectureChk.length === 0) {
            this.lectureText = ['과목 전체'];
            this.lectureChk = [''];
            this.getTeacher(true);
          } else {
            this.getTeacher();
          }
        } else if (e.target.name === 'teacher') {
          if (this.teacherCd.indexOf('') > -1) {
            this.teacherCd.splice(this.teacherCd.indexOf(''), 1);
          }
          this.teacherText = [];
          this.teacherCd.forEach((cd) => {
            const teacher = _.find(this.teacherList, { teacherCd: cd });
            this.teacherText.push(teacher.teacherName);
          });
          if (this.teacherCd.length === 0) {
            this.teacherText = ['과목 전체'];
            this.teacherCd = [''];
          }
        } else if (e.target.name === 'week') {
          if (this.weekChk.indexOf('') > -1) {
            this.weekChk.splice(this.weekChk.indexOf(''), 1);
          }
          this.weekText = [];
          const weeks = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
          this.weekChk.sort(function(a, b) {
            return weeks.indexOf(a) - weeks.indexOf(b);
          });
          this.weekChk.forEach((engWeek) => {
            const week = this.weekFilter(engWeek);
            this.weekText.push(week);
          });
          if (this.weekChk.length === 0) {
            this.weekText = ['요일 전체'];
            this.weekChk = [''];
          }
        } else if (e.target.name === 'perd') {
          if (this.perdChk.indexOf('') > -1) {
            this.perdChk.splice(this.perdChk.indexOf(''), 1);
          }
          this.perdText = [];
          this.perdChk.sort(function(a, b) {
            return a - b;
          });
          this.perdChk.forEach((t) => {
            const seasonCdMap = this.seasonPerdMap[this.seasonYear] || {};
            const perdMap = seasonCdMap[this.seasonCd] || {};
            const name = perdMap[t] || `${t}교시`;
            this.perdText.push(name);
          });
          if (this.perdChk.length === 0) {
            this.perdText = ['교시 전체'];
            this.perdChk = [''];
          }
        }
      } else {
        if (e.target.name == 'subjt') {
          this.lectureChk = [''];
          this.lectureText = ['과목 전체'];
          this.getTeacher(true);
        } else if (e.target.name == 'teacher') {
          this.teacherCd = [''];
          this.teacherText = ['강사 전체'];
        } else if (e.target.name == 'week') {
          this.weekText = ['요일 전체'];
          this.weekChk = [''];
        } else if (e.target.name == 'perd') {
          this.perdText = ['교시 전체'];
          this.perdChk = [''];
        }
      }
    },
    weekFilter(val) {
      switch (val) {
        case 'mon':
          return '월요일';
        case 'tue':
          return '화요일';
        case 'wed':
          return '수요일';
        case 'thu':
          return '목요일';
        case 'fri':
          return '금요일';
        case 'sat':
          return '토요일';
        case 'sun':
          return '일요일';
        default:
          '';
      }
    },
    async getSchedule() {
      const params = {};
      params.branchCd = this.$route.query.branchCd;
      params.seasonCd = this.seasonCd;
      params.seasonYear = this.seasonData.seasonYear;
      await this.getScheduleList(params);
    },
    tabChange(cd) {
      if (cd === '1') {
        this.isSeason = true;
        this.isOper = false;
        this.getSeasonList(1);
      } else {
        this.isSeason = false;
        this.isOper = true;
      }
      this.tabNumber = Number(cd);
      this.printResult = false;
      this.seachBoxInit();
      this.getSchedule();
      this.getLectureSubject();
      this.getTeacher(true);
      this.$nextTick(() => {
        this.setPage(1);
      });
    },
    setSubjt() {
      let list = [];
      if (this.isOper) {
        list = this.lectureSearchDataDan;
      } else {
        list = this.lectureSearchDataJae;
      }
      let array = [];
      const teacherArray = [];
      let i = 0;
      const data = this.seasonData;
      const season = this.isSeason;

      let resultData = [];
      _.chain(list)
        .sortBy('groupSdate')
        .sortBy('subjtCd')
        .sortBy(function(e) {
          return e.regstateCd == '1' || e.regstateCd == '6' || e.regstateCd == '7' ? 1 : 2;
        })
        .each(function(item) {
          if (season) {
            item.studyRegStartDt = data.studyRegStartDt;
            item.studyRegEndDt = data.studyRegEndDt;
          }
          if (_.findIndex(array, { subjtCd: item.subjtCd }) < 0) {
            const row = {};
            row.id = i;
            row.subjtCd = item.subjtCd;
            row.subjtNm = item.subjtNm;
            array.push(row);
          }
          if (_.findIndex(teacherArray, { teacherCd: item.teacherCd, subtjCd: item.subjtCd }) < 0) {
            const row = {};
            row.id = i;
            row.teacherCd = item.teacherCd;
            row.teacherName = item.teacherName;
            row.subjtCd = item.subjtCd;
            teacherArray.push(row);
          }
          i++;
          resultData.push(item);
        })
        .value();
      if (array.length > 1) {
        array = _.sortBy(array, 'subjtCd');
      }
      if (this.weekChk.indexOf('') === -1) {
        //요일 교시를 물고 들어올 시
        resultData = resultData.filter((e) => {
          return _.find(e.schedules, { perdCd: this.perdChk[0], week: this.weekChk[0] });
        });
        this.week = '';
        this.perdCd = '';
      }
      this.lectureList = array;
      this.teacherList = teacherArray;
      this.teachers = this.teacherList.filter((item, i) => {
        return (
          this.teacherList.findIndex((item2) => {
            return item.teacherCd === item2.teacherCd;
          }) === i
        );
      });
      this.dataList = resultData;
      this.printResult = true;

      this.setPage(1);
    },
    getSearch() {
      this.printResult = false;
      this.getLectureSearchList();
    },
    setPage(pageNum) {
      this.pageNum = pageNum;
      this.setPageList();
    },
    setPageList() {
      const minPage = Math.floor((this.pageNum - 1) / 5) * 5 + 1,
        tempList = [];
      let maxPage = Math.floor((this.pageNum - 1) / 5) * 5 + 5;
      if (maxPage > this.totalPages) {
        maxPage = this.totalPages;
      }
      for (let i = minPage; i <= maxPage; i++) {
        tempList.push(i);
      }
      this.pageList = tempList;
    },
    continuLecture() {
      const date = new Date();
      const yearLater = this.date.setFullYear(date.getFullYear() + 1);
      this.continuChk ? TmsCookieUtil.setCookie('etscontinuChk', 'Y', yearLater) : TmsCookieUtil.setCookie('etscontinuChk', 'N', yearLater);
    },
    detailPop(data) {
      const route = this.$router.resolve({
        name: 'lectureDetailP',
        query: {
          groupName: data.groupName,
          teacherName: data.teacherName,
          grpIdx: data.grpIdx,
          branchCd: this.branchCd,
          subjt: data.subjtNm,
          groupYear: this.seasonYear
        }
      });
      this.openClassRoomPopup(route.href, 'lectureSearchP', 'lectureDetailP', this.reload);
    },
    dupCheckForClass(data) {
      let i = 0,
        dupWait = 0;
      data.duplicate.forEach((e) => {
        if (data.grpIdx !== e.grpIdx) {
          if (e.groupSdate <= data.coursesEdate && data.coursesSdate <= e.groupEdate) {
            i++;
          }
        }
        if (e.regstateCd === 6) {
          //중복강좌중 대기 강좌체크
          dupWait++;
        }
      });
      if (i > 0) {
        return 'bg2';
      } else if (dupWait > 0) {
        return 'bg3';
      }
      return 'bg1';
    },
    getGroupTimeLine(value) {
      const weeks = { mon: '월', tue: '화', wed: '수', thu: '목', fri: '금', sat: '토', sun: '일' };
      const seasonPerdMap = this.seasonPerdMap;
      const seasonYear = this.seasonYear;
      const seasonCd = this.seasonCd;
      return _.chain(value)
        .filter(function(e) {
          return e && e.perdCd.indexOf('r') < 0;
        })
        .groupBy('week')
        .map(function(v, k) {
          const time = _.chain(v)
            .map(function(ve) {
              const seasonCdMap = seasonPerdMap[seasonYear] || {};
              const perdMap = seasonCdMap[seasonCd] || {};
              return perdMap[ve.perdCd] || `${ve.perdCd}교시`;
            })
            .sortBy()
            .value();
          return weeks[k] + ' ' + time;
        })
        .sortBy(function(e) {
          return _.values(weeks).indexOf(e.substr(0, 1));
        })
        .value();
    },
    getLecTm() {
      return TmsCookieUtil.getCookie('lecTM') || 'T';
    },
    getGroupDate(data) {
      return moment(data.groupSdate, 'YYYYMMDD').format('M/D(ddd)') + '-' + moment(data.groupEdate, 'YYYYMMDD').format('M/D(ddd)');
    },
    lectureAssign(data) {
      if (!confirm(data.groupName + ' 신청하시겠습니까?')) {
        return;
      }
      const params = {};
      params.grpIdx = data.grpIdx;
      params.branchCd = this.branchCd;
      params.studentCd = this.studentCd;
      this.lectureRegister(params).finally(() => {
        if (this.lectureRegisterData.code !== '00000') {
          alert('학원에 문의해 주세요.');
          return;
        }
        if (!this.lectureRegisterData.data) {
          alert('학원에 문의해 주세요.');
          return;
        }

        if (this.lectureRegisterData.data.success) {
          alert('수강신청 완료하였습니다.');
          if (!this.continuChk) {
            setTimeout(() => {
              opener.location.reload();
              self.close();
            }, 1000);
          } else {
            setTimeout(() => {
              this.getLectureSearchList();
              opener.location.reload();
            }, 1000);
          }
        } else if (this.lectureRegisterData.data.duplicate) {
          alert('중복인 강좌가 있습니다.');
        } else if (this.lectureRegisterData.data.limitOver) {
          alert('수강제한을 초과하였습니다');
        } else if (this.lectureRegisterData.data.msg) {
          alert(this.lectureRegisterData.data.msg);
        }
      });
    },
    lectureCancelAssign(data) {
      if (!this.canEnrolment) {
        return;
      }
      if (!confirm(data.groupName + '취소하시겠습니까?')) {
        return;
      }
      const params = {};
      params['studentCd'] = this.studentCd;
      params['branchCd'] = this.branchCd;
      params.groups = [{ grpIdx: data.grpIdx, grpasnIdx: data.grpasnIdx }];

      this.lectureCancel(params).finally(() => {
        if (this.lectureCancelData.code !== '00000') {
          alert('학원에 문의해 주세요.');
          return;
        }

        alert('수강신청 취소 완료하였습니다.');
        if (this.continuChk) {
          this.getSearch();
          opener.location.reload();
          return;
        }

        setTimeout(() => {
          self.close();
          opener.location.reload();
        }, 1000);
      });
    },
    seachBoxInit() {
      this.teacherCd = [''];
      this.lectureChk = [''];
    },
    reload() {},
    isAssign: REGSTATE_CD.isAssign,
    isStandBy: REGSTATE_CD.isStandBy,
    isTempAssign: REGSTATE_CD.isTempAssign,
    isGeneralLecture: LECTURE_TYPE_CD.isGeneral
  }
};
</script>
