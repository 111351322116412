<template>
  <div id="historyLayer" class="layer_fullpage history open">
    <div class="layer_head">
      <h4 class="tit_layer">강좌 상세정보</h4>
      <a href="javascript:;" class="layer_close" @click="$emit('close')"><span class="blind">레이어 닫기</span></a>
    </div>
    <div class="layer_cont">
      <div>
        <div class="fs14 ffam800 vab mgt20">
          [<span class="gw">{{ subjt }}</span
          >] <span class="teacher">{{ teacherName }} </span><br /><span class="lecture">{{ groupNm }}</span>
        </div>
        <div class="scll mgt5" id="img">
          <img :src="imgSrc" style="border-radius: 5px" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { PAGE_TITLE } from '../../../const/const';

export default {
  name: 'lectureDetailP',
  data() {
    return {
      subjt: '',
      teacherName: '',
      groupNm: '',
      imgSrc: ''
    };
  },
  props: ['close', 'checkParam'],
  components: {},
  computed: {
    ...mapState({
      userInfo: (state) => state.common.userInfo,
      imageData: (state) => state.classroom.lecture.imageData
    })
  },
  created() {
    if (!this.userInfo.studentCd) {
      this.getUser().then(() => {
        this.groupNm = this.checkParam.groupName;
        this.teacherName = this.checkParam.teacherName;
        this.subjt = this.checkParam.subjt;
        this.getImageData();
      });
    } else {
      this.groupNm = this.checkParam.groupName;
      this.teacherName = this.checkParam.teacherName;
      this.subjt = this.checkParam.subjt;
      this.getImageData();
    }
    this.changeTitle();
  },
  methods: {
    ...mapActions(['getUser', 'getImage']),
    changeTitle() {
      let title = '';
      if (this.userInfo.branchCd !== '') title = PAGE_TITLE[this.userInfo.branchCd];
      document.title = 'mypage_강좌상세정보 | ' + title;
    },
    getImageData() {
      const params = {};
      params['branchCd'] = this.checkParam.branchCd;
      params['groupYear'] = this.checkParam.groupYear;
      params['groupidx'] = this.checkParam.grpIdx;

      this.getImage(params).then(() => {
        if (this.imageData.data !== null) {
          this.imgSrc = '/api/lecture/view?fileName=' + this.imageData.data.imageurl;
        } else {
          alert('강좌 상세정보가 없습니다');
          this.$emit('close');
        }
      });
    }
  }
};
</script>
