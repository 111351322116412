<template>
  <div class="pay_content pass" v-if="!isMobile">
    <!--[D]: 현장강의 무한패스의 경우 pass 클래스 추가 -->
    <h4>
      <span>관심 강좌</span>
    </h4>
    <!-- search box 영역 -->
    <search-table ref="searchTable" :num-per-page="numPerPage" :api-path="interestedApi" @search="onSearchCallback" @loadingStart="isLoading = true" />
    <!-- table 영역 -->
    <infinite-pass-table-component
      v-model="selectedLecturesList"
      :show-select="true"
      :title="'강좌'"
      :menuTitle="'관심 강좌'"
      :headers="tableHeaders"
      :items="infiniteLectures"
      :is-loading="isLoading"
      @openPopup="openPopup"
      @confirmDelete="onDeleteInterestLectures"
    >
      <template v-slot:[`item.groupName`]="{ item }">
        <badge-box
          :unity-subjt-cd="item.unitySubjtCd"
          :subjt-nm="item.subjtNm"
          :lecture-type-cd="item.lectureTypeCd"
          :cls-middle="item.clsMiddle"
        />
        <span v-html="item.groupName"></span>
      </template>
      <template v-slot:[`item.perdName`]="{ item }">
        <div v-html="getSchedule(item.schedules)"></div>
      </template>
      <template v-slot:[`item.viewBtn`]="{ item }">
        <a @click="openPopup('.ly_pop.lecture', item)">보기&gt;</a>
      </template>
    </infinite-pass-table-component>
    <!-- LayerPopup : 보기 버튼 눌렀을 때 뜨는 팝업 -->
    <lecture-popup ref="lecturePop" @closePopup="closePopup" />
    <!-- LayerPopup : 삭제팝업 -->
  </div>
</template>

<script>
import SearchTable from './components/SearchTableP.vue';
import LecturePopup from './components/LecturePopup.vue';
import InfinitePassTableComponent from './components/InfinitePassTableP.vue';
import { mapActions, mapState } from 'vuex';
import EventBus from '../../utils/eventBus';
import { getSchedule } from '../../utils/util';
import BadgeBox from './components/BadgeBox.vue';
import { PAGE_TITLE } from '../../const/const';

export default {
  name: 'lectureView',
  components: {
    BadgeBox,
    InfinitePassTableComponent,
    SearchTable,
    LecturePopup
  },
  data: () => ({
    interestedApi: `/api/infinitePass/interest/list`,
    turnOrdLectures: [],
    infiniteLectures: null,
    numPerPage: 10,
    tableHeaders: [
      { text: '강좌', value: 'groupName', txtLeft: true, sortable: true },
      { text: '강사', value: 'teacherName', width: '9%', sortable: true },
      { text: '요일/교시', value: 'perdName', width: '11%' },
      { text: '강의실', value: 'groupRoom', width: '11%', sortable: true },
      { text: '강의 보기', value: 'viewBtn', width: '9%' }
    ],
    selectedLecturesList: [],
    selectedSubject: [],
    isLoading: true
  }),
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      userInfo: (state) => state.common.userInfo
    })
  },
  methods: {
    getSchedule,
    ...mapActions(['getTurnOrdLectures', 'removeInterestedLectures']),
    changeTitle() {
      let title = '';
      if (this.userInfo.branchCd !== '') title = PAGE_TITLE[this.userInfo.branchCd];
      document.title = 'mypage_현장강의 무한패스 - 관심 강좌 | ' + title;
    },
    onSearchCallback(result) {
      this.isLoading = false;
      if (result.code !== '00000') {
        EventBus.$emit('setPageInfo', 1, 10, 0);
        return;
      }
      const data = result.data;
      this.infiniteLectures = data.list;
      EventBus.$emit('setPageInfo', data.pageInfo.currPage, data.pageInfo.numPerPage, data.pageInfo.totalRows);
    },
    async openPopup(className, obj) {
      const result = await this.getTurnOrdLectures({ grpIdx: obj.grpIdx });
      if (Object.keys(result.data)?.length === 0) {
        alert('등록된 강의가 없습니다.');
        return;
      }
      this.$refs.lecturePop.title = obj.groupName;
      const popup = document.querySelector(`${className}`);
      popup.classList.add('on');
    },
    closePopup(className) {
      const popup = document.querySelector(`${className}`);
      popup.classList.remove('on');
    },
    onDeleteInterestLectures() {
      const infipasLctShareNos = [];
      const infipasSglctDetrmnNos = [];
      this.selectedLecturesList?.forEach(({ infipasLctShareNo, infipasSglctDetrmnNo }) => {
        if (infipasLctShareNo) infipasLctShareNos.push(infipasLctShareNo);
        if (infipasSglctDetrmnNo) infipasSglctDetrmnNos.push(infipasSglctDetrmnNo);
      });
      this.removeInterestedLectures({ infipasLctShareNos, infipasSglctDetrmnNos, studentCd: this.userInfo.studentCd })
        .then((result) => {
          if (result.code === '00000') {
            alert(`${infipasLctShareNos.length + infipasSglctDetrmnNos.length}건 삭제가 완료되었습니다`);
            this.$refs.searchTable.search();
          } else {
            alert('오류가 발생되었습니다. 잠시후 다시 시도해주세요.');
          }
          this.selectedLecturesList = [];
        })
        .catch(() => alert('오류가 발생되었습니다. 잠시후 다시 시도해주세요.'));
    },
    openPopupInMobile(target) {
      const popup = document.querySelector(`${target}`);
      popup.classList.add('open');
    }
  },
  created() {
    this.changeTitle();
  }
};
</script>
