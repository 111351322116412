<template v-if="userInfo.branchCd === '0500'">
  <footer>
    <div class="link">
      <a href="tel:031-818-4545">전화상담</a>
      <a href="https://m.etoos.com/member/pop_individual.asp" target="_blank">개인정보처리방침</a>
      <a href="https://m.etoos.com/member/pop_yakkwan_new.asp" target="_blank">이용약관</a>
    </div>
    <div class="copyright">
      <p>
        (10416) 경기도 고양시 일산동구 일산로 197(마두동 736-1) 4층 | 사업자등록번호 : 217-85-25116 | Tel : 031-818-4545 | Fax : 031-696-9945 <br />
        이투스네오일산학원 학원등록번호 : 제5837호 교습과정 : 종합-보습, 입시, 진학상담·지도 | 이투스네오일산독서실 학원등록번호 : 제5836호 교습과정 : 독서실
      </p>
      <p>
        (10416) 경기도 고양시 일산동구 일산로 219(마두동 738) 5층 | 사업자등록번호 : 711-85-01877 | Tel : 031-907-2345 | Fax : 031-696-9945 <br />
        이투스네오일산1학원 학원등록번호 : 제3786호 교습과정 : 입시
      </p>
      <p>
        <strong>이투스에듀 주식회사</strong> (06733) 서울시 서초구 남부순환로 2547 (서초동 1354-3) | Tel: 02-552-1230 | Fax: 02-552-1325 | 사업자등록번호 : 505-87-02372 | 통신판매신고번호 : 강남-03036호
        <a href="javascript:void(0);" @click="openBizInfo('5058702372')">정보조회</a><br />
        대표이사: 정선욱 | 개인정보보호책임자 : 정미상<br />
        Copyright © ETOOS EDUCATION Co.,Ltd. All rights Reserved.
      </p>
    </div>
  </footer>
</template>
<template v-else>
  <footer>
    <div class="link">
      <a href="tel:02-3288-9001">전화상담</a>
      <a href="https://m.etoos.com/member/pop_individual.asp" target="_blank">개인정보처리방침</a>
      <a href="https://m.etoos.com/member/pop_yakkwan_new.asp" target="_blank">이용약관</a>
    </div>
    <div class="copyright">
      <p>
        (06134) 서울시 강남구 강남대로 94길 30 (역삼동 649-1) | 사업자등록번호 : 505-87-02372 | Tel : 02-3288-9001 | Fax : 02-553-0512 <br />
        이투스네오1학원 학원등록번호 : 제8570호 교습과정 : 종합-보습·논술, 외국어, 진학상담·지도 | 이투스네오1독서실 학원등록번호 : 제13106호 교습과정 : 독서실
      </p>
      <p>(06134) 서울시 강남구 강남대로 94길 36 (역삼동 649-3) | 사업자등록번호 : 264-85-01838, 891-85-02100 | Tel : 02-3288-9001 | Fax : 02-553-0512 <br /></p>
      <p>이투스네오2학원 학원등록번호 : 제12939호 교습과정 : 보습·논술 | 이투스네오2독서실 학원등록번호 : 제12940호 교습과정 : 독서실<br /></p>
      <p>
        <strong>이투스에듀 주식회사</strong> (06733) 서울시 서초구 남부순환로 2547 (서초동 1354-3) | Tel: 02-552-1230 | Fax: 02-552-1325 | 사업자등록번호 : 505-87-02372 | 통신판매신고번호 : 강남-03036호
        <a href="javascript:void(0);" @click="openBizInfo('5058702372')">정보조회</a><br />
        대표이사: 정선욱 | 개인정보보호책임자 : 정미상<br />
        Copyright © ETOOS EDUCATION Co.,Ltd. All rights Reserved.
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footera',
  props: ['userInfo']
};
</script>
