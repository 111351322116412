import MutationTypes from '@/store/MutationTypesClass';
import LectureSearchApi from '@/api/LectureSearchApi';
import CommonApi from '@/api/CommonApi';

export default {
  getLectureSearchListJae: async ({ commit }, params) => {
    try {
      const result = await LectureSearchApi.getLetureSearchList(params);
      commit(MutationTypes.SET_LECTURE_SEARCH_LIST_JAE, result.data);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  getLectureSearchListDan: async ({ commit }, params) => {
    try {
      const result = await LectureSearchApi.getLetureSearchList(params);
      commit(MutationTypes.SET_LECTURE_SEARCH_LIST_DAN, result.data);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  getTeacherList: async ({ commit }, params) => {
    try {
      const result = await LectureSearchApi.getTeacherList(params);
      commit(MutationTypes.SET_TEACHER_LIST, result.data);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  getLectureCheck: async ({ commit }, params) => {
    try {
      const result = await LectureSearchApi.getLectureCheck(params);
      commit(MutationTypes.SET_LECTURE_CHECK_LIST, result.data);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  postBaisSort: async ({ commit }, params) => {
    try {
      await LectureSearchApi.postBaisSort(params);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  cancelGroup: async ({ commit }, params) => {
    try {
      await LectureSearchApi.cancelGroup(params);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  lectureRegister: async ({ commit }, params) => {
    try {
      const result = await LectureSearchApi.lectureRegister(params);
      commit(MutationTypes.SET_LECTURE_REGISTER_DATA, result);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  lectureCancel: async ({ commit }, params) => {
    try {
      const result = await LectureSearchApi.lectureCancel(params);
      commit(MutationTypes.SET_LECTURE_CANCEL_DATA, result);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  getImage: async ({ commit }, params) => {
    try {
      const result = await LectureSearchApi.getImage(params);
      commit(MutationTypes.SET_IMAGE_DATA, result);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  getLectureType: async ({ commit }) => {
    try {
      const result = await CommonApi.getMrCodeList('LCT');
      commit(MutationTypes.SET_LECTURE_TYPE_MAP, result.data);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  }
};
