<template>
  <div class="pay_content">
    <h4><span>강의 다시보기</span></h4>

    <div class="srch_hgroup">
      <div class="sel">
        <select style="width:130px;" v-model="searchByyySubjtSeq" @change="saveSearchName(searchSubjectList.find((item) => item.byyySubjtSeq === searchByyySubjtSeq).subjtName)">
          <option value="" selected>과목</option>
          <option v-for="item in searchSubjectList" :value="item.byyySubjtSeq" :key="item.byyySubjtSeq">{{ item.subjtName }}</option>
        </select>
        <select style="width:130px;" v-model="searchSubCode" @change="saveSearchName(subSubjectList.find((item) => item.code === searchSubCode).name)">
          <option value="" selected>전체</option>
          <option v-for="item in subSubjectList" :value="item.code" :key="item.code">{{ item.name }}</option>
        </select>
        <select style="width:130px;" v-model="searchTeacherCd" @change="saveSearchName(teacherList.find((item) => item.teacherCd === searchTeacherCd).teacherName)">
          <option value="" selected>강사</option>
          <option v-for="(item, index) in teacherList" :value="item.teacherCd" :key="index">{{ item.teacherName }}</option>
        </select>
      </div>
      <div class="text">
        <input type="text" placeholder="강좌명" style="width:330px;" v-model="searchLectureName" />
      </div>
      <a href="javascript:void(0)" class="mbtn dark btnSearch" @click="searchLectures()">조회하기</a>
      <div class="txt_box">
        <span v-for="(item, idx) in searchRequests" class="search_txt"> {{ item.name }}<em @click="deleteRequest(idx)">X</em> </span>
      </div>
    </div>
    <div class="tbl_data">
      <table>
        <colgroup>
          <col style="width:8%" />
          <col style="width:11%" />
          <col style="width:7%" />
          <col />
          <col style="width:22%" />
          <col style="width:12%" />
        </colgroup>
        <thead>
          <tr>
            <th>과목</th>
            <th>파트</th>
            <th>강사</th>
            <th>강좌</th>
            <th>수업시간</th>
            <th>강의 다시보기</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(lecture, idx) in lectures.slice((currentPage - 1) * pagePerCnt, currentPage * pagePerCnt)" :key="idx">
            <th>{{ lecture.subjtNm }}</th>
            <td v-if="lecture.subjtPartNm">{{ lecture.subjtPartNm }}</td>
            <td v-else>{{ lecture.subjtSubNm || '-' }}</td>
            <td>{{ lecture.teacherName }}</td>
            <td v-html="lecture.groupName"></td>
            <td v-html="getSchedule(lecture.dayScheduleMap)" style="text-align: right;padding-right: 56px;"></td>
            <td>
              <a href="javascript:void(0)" @click="showTurnOrdLecture(lecture)">보기></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- paging -->
    <div class="paging" id="divSearchPaging"><span class="num"></span></div>
    <div class="paging">
      <a href="javascript:void(0)" class="first" @click="movePage(1)"><span class="blind">처음</span></a>
      <a href="javascript:void(0)" class="prev"
@click="movePage(currentPage === 1 ? currentPage : currentPage - 1)"><span class="blind">이전</span></a>
      <span class="num">
        <template v-for="(item, idx) in totalPage">
          <strong v-if="currentPage === item" :key="idx">{{ item }}</strong>
          <a href="javascript:void(0)" v-else :key="idx" @click="movePage(item)">{{ item }}</a>
        </template>
      </span>
      <a href="javascript:void(0)" class="next" @click="movePage(currentPage === totalPage ? currentPage : currentPage + 1)"><span class="blind">다음</span></a>
      <a href="javascript:void(0)" class="last" @click="movePage(totalPage)"><span class="blind">마지막</span></a>
    </div>
    <!-- //paging -->

    <div class="tbl_txt mgt40">
      <ul>
        <li>결제하신 내역과 증빙서류 발급은 마이페이지 &gt; 결제내역 화면에서 확인하실 수 있습니다.</li>
        <li>결제한 강좌의 환불/취소는 해당 학원에서만 가능합니다.</li>
        <li>결제한 강좌는 전체 결제금액에 대한 전체 취소만 가능하며 부분 환불은 하실 수 없습니다.</li>
        <li>여러 강좌를 한번에 결제하신 경우, 전체 결제금액에 대한 환불/취소만 가능합니다.</li>
        <li>여러 강좌를 한번에 결제하신 경우, 결제내역에 묶음 처리되어 보여집니다.</li>
      </ul>
    </div>
    <replay-turn-ord-lecture-popup-p v-if="showTurnOrd" :detailLecture="detailLecture" @close="showTurnOrd = false" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ReplayTurnOrdLecturePopupP from '@/components/replaylecture/ReplayTurnOrdLecturePopupP';

export default {
  name: 'ReplayLecture',
  components: { ReplayTurnOrdLecturePopupP },
  data() {
    return {
      searchRequests: [],
      searchSubjectList: [],
      searchByyySubjtSeq: '',
      searchSubCode: '',
      searchTeacherCd: '',
      searchLectureName: '',
      searchName: '',
      subSubjectList: [],
      isPartCode: false,
      teacherList: [],
      detailLecture: {},
      currentPage: 1,
      totalPage: 0,
      pagePerCnt: 10,
      showTurnOrd: false
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      userInfo: (state) => state.common.userInfo,
      searchItems: (state) => state.replaylecture.searchItems,
      lectures: (state) => state.replaylecture.lectures
    })
  },
  watch: {
    searchByyySubjtSeq(seq) {
      this.makeSubSubjectList(seq);
      this.makeTeacherList(seq);
    }
  },
  methods: {
    ...mapActions(['getUser', 'getReplayLecture', 'getSearchItems']),
    changeTitle() {
      let title = '';
      if (this.userInfo.branchCd !== '') title = PAGE_TITLE[this.userInfo.branchCd];
      document.title = 'mypage_강의다시보기 | ' + title;
    },
    init() {
      this.getSearchItems().then(() => {
        for (const [key, item] of Object.entries(this.searchItems.subjects)) {
          this.searchSubjectList.push(item);
        }
        this.searchSubjectList.sort((a, b) => {
          return (a.subjtCd || '').localeCompare(b.subjtCd);
        });
        this.makeTeacherList('');
      });
      this.getReplayLecture().then(() => {
        this.setTotalPage();
      });
    },
    makeSubSubjectList(seq) {
      const subject = this.searchItems.subjects[seq];
      this.subSubjectList = [];
      this.searchSubCode = '';
      if (Object.keys(subject?.partCodeMap || {}).length > 0) {
        this.isPartCode = true;
        for (const [key, item] of Object.entries(subject.partCodeMap)) {
          const obj = { code: key, name: item };
          this.subSubjectList.push(obj);
        }
      }
      if ((subject?.subSubjects || []).length > 0) {
        this.isPartCode = false;
        for (const item of subject.subSubjects) {
          const obj = { code: item.subjtCd, name: item.subjtNm };
          this.subSubjectList.push(obj);
        }
      }
      this.subSubjectList.sort((a, b) => {
        return (a.code || '').localeCompare(b.code);
      });
    },
    makeTeacherList(seq) {
      const teachers = this.searchItems.searchTeachers;
      this.searchTeacherCd = '';
      this.teacherList = [];
      if (seq === '') {
        for (const [key, item] of Object.entries(teachers)) {
          item?.forEach((teacher) => {
            this.teacherList.push(teacher);
          });
        }
      } else {
        teachers[seq]?.forEach((teacher) => {
          this.teacherList.push(teacher);
        });
      }
      this.teacherList.sort((a, b) => {
        return (a.teacherName || '').localeCompare(b.teacherName);
      });
    },
    searchLectures() {
      let params = [];
      const param = {
        byyySubjtSeq: null,
        subjtPartCd: null,
        subSubjtCd: null,
        teacherCd: null,
        lectureName: null,
        name: this.searchName || null
      };
      if (this.searchByyySubjtSeq !== '') {
        param.byyySubjtSeq = this.searchByyySubjtSeq;
      }
      if (this.isPartCode) {
        param.subjtPartCd = this.searchSubCode;
      } else if (this.searchSubCode !== '') {
        param.subSubjtCd = this.searchSubCode;
      }
      if (this.searchTeacherCd !== '') {
        param.teacherCd = this.searchTeacherCd;
      }
      if (this.searchLectureName !== '') {
        param.lectureName = this.searchLectureName;
        param.name = this.searchLectureName;
      }
      const paramSize = Object.values(param).filter((value) => value !== null).length;
      if (paramSize === 0) {
        return;
      }

      params.push(param);
      if (this.searchRequests.length > 0) {
        params = this.searchRequests.concat(params);
      }
      this.getReplayLecture(params).then(() => {
        if (param.name !== null) {
          this.searchRequests = params;
        }
        this.setTotalPage();
        this.searchByyySubjtSeq = '';
        this.searchTeacherCd = '';
        this.searchLectureName = '';
        this.searchName = '';
      });
    },
    getSchedule(dayScheduleMap) {
      const result = {};
      for (const prop in dayScheduleMap) {
        const timeList = [];
        dayScheduleMap[prop].forEach(function(obj, idx) {
          if (idx === 0) {
            result[prop] = obj.day;
          }
          timeList.push(obj.lectureStartTime + ' ~ ' + obj.lectureEndTime);
        });
        result[prop] += ' ' + timeList.join('<br>');
      }
      return Object.values(result).join('<br>');
    },
    showTurnOrdLecture(lecture) {
      this.detailLecture = lecture;
      this.showTurnOrd = true;
    },
    deleteRequest(idx) {
      this.searchRequests.splice(idx, 1);
      this.getReplayLecture(this.searchRequests).then(() => {
        this.setTotalPage();
      });
    },
    movePage(pageNum) {
      this.currentPage = pageNum;
    },
    saveSearchName(name) {
      if (name === '') return;
      this.searchName = name;
    },
    setTotalPage() {
      const size = (this.lectures || []).length;
      const page = Math.floor(size / 10);
      const addPage = size % 10 === 0 ? 0 : 1;
      this.totalPage = page + addPage;
    }
  },
  created() {
    if (!this.userInfo.studentCd) {
      this.getUser().then(() => {
        this.init();
      });
    } else {
      this.init();
    }
    this.changeTitle();
  }
};
</script>

<style scoped></style>
