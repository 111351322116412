import MutationTypes from '@/store/MutationTypes';
import MutationTypesUnityExam from '@/store/MutationTypesUnityExam';
import TestApi from '@/api/TestApi';

export default {
  getMathDmTest: async ({ commit }, { studentCd, branchCd }) => {
    try {
      const result = await TestApi.getMathDmTest({ studentCd, branchCd });
      const testMap = {};
      const testList = result.data || [];
      let lastTest = {};
      testList.forEach((value) => {
        const unityExamSeq = value.unityExamSeq,
          unityDtlExamSeq = value.unityDtlExamSeq,
          unityDtlExamTurnOrdSeq = value.unityDtlExamTurnOrdSeq;

        if (testMap[unityExamSeq] === undefined) {
          testMap[unityExamSeq] = { name: value.unityExamNm };
        }
        if (testMap[unityExamSeq][unityDtlExamSeq] === undefined) {
          testMap[unityExamSeq][unityDtlExamSeq] = { name: value.unityDtlExamNm };
        }
        if (testMap[unityExamSeq][unityDtlExamSeq][unityDtlExamTurnOrdSeq] === undefined) {
          testMap[unityExamSeq][unityDtlExamSeq][unityDtlExamTurnOrdSeq] = {
            unityDtlExamTurnOrd: value.unityDtlExamTurnOrd,
            unityDtlExamSeq,
            unityDtlExamTurnOrdSeq,
            unityExamYy: value.unityExamYy,
            data: {}
          };
        }
        testMap[unityExamSeq][unityDtlExamSeq][unityDtlExamTurnOrdSeq].data[value.byyySubjtSeq] = value;

        if (
          Object.keys(lastTest).length === 0 ||
          lastTest.unityExamStDt > value.unityExamStDt ||
          (lastTest.unityExamStDt === value.unityExamStDt && lastTest.testRegDt < value.testRegDt)
        ) {
          lastTest = value;
        }
      });
      commit(MutationTypesUnityExam.SET_LAST_TEST, lastTest);
      commit(MutationTypesUnityExam.SET_TEST_MAP, testMap);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  getMyMathDmInfo: async ({ commit }, { studentCd, branchCd, unityDtlExamSeq }) => {
    try {
      const result = await TestApi.getMyMathDmInfo({ studentCd, branchCd, unityDtlExamSeq });
      commit(MutationTypes.SET_TEST_LIST, result.data);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  getMathDmTestSubjt: async ({ commit }, turnOrdSeq) => {
    try {
      const result = await TestApi.getMathDmTestSubjt(turnOrdSeq);
      commit(MutationTypesUnityExam.SET_LAST_TEST, result.data);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  getMathDmResolveTest: async ({ commit }, { branchCd, studentCd, selectTurnOrdSeq }) => {
    try {
      const result = await TestApi.getMathDmResolveTest(branchCd, studentCd);
      const testMap = {};
      const testList = result.data || [];
      let lastTest = null;
      testList.forEach((value) => {
        const unityExamSeq = value.unityExamSeq,
          unityDtlExamSeq = value.unityDtlExamSeq,
          unityDtlExamTurnOrd = value.unityDtlExamTurnOrd,
          unityDtlExamTurnOrdSeq = value.unityDtlExamTurnOrdSeq;

        if (testMap[unityExamSeq] === undefined) {
          testMap[unityExamSeq] = { name: value.unityExamNm };
        }
        if (testMap[unityExamSeq][unityDtlExamSeq] === undefined) {
          testMap[unityExamSeq][unityDtlExamSeq] = { name: value.unityDtlExamNm };
        }
        if (testMap[unityExamSeq][unityDtlExamSeq][unityDtlExamTurnOrd] === undefined) {
          testMap[unityExamSeq][unityDtlExamSeq][unityDtlExamTurnOrd] = {
            unityDtlExamTurnOrd,
            unityDtlExamSeq,
            unityDtlExamTurnOrdSeq,
            data: value
          };
        }
        if (unityDtlExamTurnOrdSeq === Number(selectTurnOrdSeq)) {
          lastTest = value;
        }
      });
      commit(MutationTypesUnityExam.SET_TEST_MAP, testMap);
      commit(MutationTypesUnityExam.SET_LAST_TEST, lastTest);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  getMathDmTargetTestInfo: async ({ commit }, { studentCd, turnOrdSeq }) => {
    try {
      const result = await TestApi.getMathDmTargetTestInfo(studentCd, turnOrdSeq);
      commit(MutationTypes.SET_ANALYZE_TEST, result.data);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  getChildSubject: async ({ commit }, turnOrdSeq) => {
    try {
      const result = await TestApi.getChildSubject(turnOrdSeq);
      commit(MutationTypes.SET_CHILD_SUBJECT_LIST, result.data);
    } catch (e) {
      commit(MutationTypesUnityExam.ERROR, e);
    }
  }
};
