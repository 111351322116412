<template>
  <div class="popup_cont popup_ansr" :class="{ mobile: isMobile, dailymath_pop_v2: isMathTestResult }">
    <div class="popup_head">
      <h1 class="tit_popup">{{ isMathTestResult ? '응시결과' : '문항보기' }}</h1>
      <div class="switch_cont" v-if="!isMathTestResult">
        <button type="button" id="switch1" class="btn_type" :class="{ on: contentView === 'type1' }" @click="contentView = 'type1'">
          문항만
        </button>
        <button type="button" id="switch2" class="btn_type" :class="{ on: contentView === 'type2' }" @click="contentView = 'type2'">
          문항+해설
        </button>
      </div>
      <div class="expander">
        <div class="exam_subject no_arr">
          <span class="txt_subject" v-if="StringUtils.isNotEmpty(firstTitle)">{{ firstTitle }}</span>
          <span class="txt_subject" v-if="StringUtils.isNotEmpty(secondTitle)">{{ secondTitle }}</span>
          <span class="txt_subject" v-if="StringUtils.isNotEmpty(thirdTitle)">{{ thirdTitle }}</span>
          <span class="txt_subject" v-if="StringUtils.isNotEmpty(turnOrdTitle)">{{ turnOrdTitle }}</span>
          <span class="txt_subject" v-if="isMathTestResult && StringUtils.isNotEmpty(highSubjtTitle)">{{ highSubjtTitle }}</span>
          <span class="txt_subject" v-if="isMathTestResult && StringUtils.isNotEmpty(subjtTitle)">{{ subjtTitle }}</span>
        </div>
      </div>
    </div>
    <div class="popup_body">
      <div class="exam_category mo_only">
        <p class="txt_subject">{{ totalTitle }}</p>
      </div>
      <div v-if="qustnList.length > 0" class="wrap_exam_paper type2">
        <div class="paper_info" :class="{ dimmed: isOpenQuestionNumber, active: isActive }">
          <!--//class active추가하면 문항보기 영역 보임 -->
          <h2 class="tit_paper mo_only">문항보기</h2>
          <button type="button" class="btn_popup_close" @click="windowCloseSelf()">닫기</button>
          <table class="tbl_comm">
            <caption>
              문항 정보
            </caption>
            <colgroup>
              <col style="width:90px" />
              <col style="width:auto" />
              <col style="width:90px" />
              <col style="width:80px" />
              <col style="width:90px" />
              <col style="width:80px" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">문항분류</th>
                <td class="data_cont">
                  {{ getQuestionType }}
                </td>
                <th scope="row">오답률</th>
                <td>{{ typeof wransrrt === 'number' ? wransrrt.toFixed(1) + '%' : '-' }}</td>
                <th scope="row">해설영상</th>
                <td>
                  <button type="button" v-show="currentQuestion.explCurriVodPathwy" @click="showExplVod(currentQuestion.explCurriVodPathwy, currentQuestion.qustnInpMethodCd)" class="btn_vod">
                    보기
                  </button>
                  <a v-if="!currentQuestion.explCurriVodPathwy">-</a>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  {{ currentQuestion.coransr === currentQuestion.myAnsr ? '정답원인' : '오답원인' }}
                </th>
                <td colspan="5" class="data_cont">
                  <div class="type_check">
                    <label v-for="(item, idx) in currentQuestion.coransr === currentQuestion.myAnsr ? answerCause : wransrCause" @change="saveCause(item.realType)" :key="idx" class="lab_radio" :for="'typeRadio' + idx">
                      <input
                        type="radio"
                        :id="'typeRadio' + idx"
                        :checked="item.realType === (currentQuestion.coransrModel || {}).realType"
                        class="inp_radio"
                        name="typeRadio"
                        @click="item.description === '기타' ? (isShowInputReasonLayer = true) : () => {}"
                        :disabled="isParent"
                      />
                      <span class="ico_radio"></span>
                      {{ item.description }}
                      <span v-if="item.description === '기타' && StringUtils.isNotEmpty((currentQuestion.coransrModel || {}).etcReason)" class="txt_reason">({{ currentQuestion.coransrModel.etcReason }})</span>
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="nav_num mo_only" :class="{ open: isOpenQuestionNumber }">
            <!-- open -->
            <button type="button" class="btn_open" @click="isOpenQuestionNumber = !isOpenQuestionNumber" :disabled="qustnList[activeLiIndex].length < 6">
              열기/닫기
            </button>
            <ul class="list_number">
              <li v-for="(item, index) in qustnList[activeLiIndex]" v-show="isOpenQuestionNumber || isDisplayIndex(index)" :key="index" :class="{ on: isActiveOn === index }">
                <a
                  href="javascript:;"
                  class="num_order"
                  :class="{
                    wransr: item.coransr !== item.myAnsr,
                    correct: isMathTestResult && item.coransr === item.myAnsr,
                    incorrect: isMathTestResult && item.coransr !== item.myAnsr
                  }"
                  @click="toggleActive(index)"
                >
                  {{ item.qustnNo }}
                </a>
              </li>
            </ul>
          </div>
          <div class="tab_exam">
            <ul class="list_tab">
              <li :class="{ on: contentView === 'type1' }">
                <button type="button" class="btn_tab" @click="contentView = 'type1'">
                  문항
                </button>
              </li>
              <li :class="{ on: contentView === 'type2' }">
                <button type="button" class="btn_tab" @click="contentView = 'type2'">
                  해설
                </button>
              </li>
            </ul>
          </div>
          <div class="exam_cont" :class="{ type2: isMathTestResult }">
            <div class="box_question" :class="{ on: contentView === 'type1' }">
              <strong class="blind">지문/문항</strong>
              <div v-if="StringUtils.isNotEmpty((currentQuestion || {}).textImgPathwy)">
                <img :src="currentTextImgPathwy" alt="" />
              </div>
              <div v-if="StringUtils.isNotEmpty((currentQuestion || {}).qustnImgPathwy)">
                <img :src="currentQustnImgPathwy" alt="" />
              </div>
              <div v-if="StringUtils.isEmpty((currentQuestion || {}).qustnImgPathwy)">
                <p class="blank">문항이미지가 없습니다.</p>
              </div>
            </div>
            <div class="box_explain" :class="{ on: contentView === 'type2' }">
              <strong class="blind">해설</strong>
              <div v-if="StringUtils.isNotEmpty((currentQuestion || {}).explImgPathwy)">
                <img :src="currentExplImgPathwy" alt="" />
              </div>
              <div v-else>
                <p class="blank">해설이미지가 없습니다.</p>
              </div>
            </div>
            <button type="button" v-if="!isFirstPage" @click="qustnPrev" class="btn_prev">이전</button>
            <button type="button" v-if="!isLastPage" @click="qustnNext" class="btn_next">다음</button>
            <div class="bar_bottom_tool mo_only">
              <select class="select_ansr_type" @change="showInputReasonLayer" v-model="reasonWrongAnswer" :disabled="isParent">
                <option v-if="currentQuestion.coransr === currentQuestion.myAnsr" value="">정답원인선택</option>
                <option v-if="currentQuestion.coransr !== currentQuestion.myAnsr" value="">오답원인선택</option>
                <option
v-for="(item, idx) in currentQuestion.coransr === currentQuestion.myAnsr ? answerCause : wransrCause"
:key="idx" :value="item.realType"
                >{{ item.description }} {{ item.description === '기타' && StringUtils.isNotEmpty((currentQuestion.coransrModel || {}).etcReason) ? '(' + currentQuestion.coransrModel.etcReason + ')' : '' }}</option
                >
              </select>
              <div class="check_review">
                <label class="lab_check" for="checkReview">
                  <input type="checkbox" id="checkReview" class="inp_check" name="checkReview" :disabled="isParent" @change="changeRegiWransrNoteYn(currentQuestion, $event)" :checked="currentQuestion.coransrNotesYn === 'Y'" />
                  <span class="ico_check"></span>오답노트
                </label>
              </div>
            </div>
            <button v-show="currentQuestion.explCurriVodPathwy && contentView === 'type2'" type="button" class="btn_vod_play" @click="showExplVod(currentQuestion.explCurriVodPathwy, currentQuestion.qustnInpMethodCd)">해설영상<br />보기</button>
          </div>
        </div>
        <!-- 문항보기-학생 -->
        <div class="exam_sheet">
          <aside-list
            ref="aside_list_qustn_view"
            v-for="(item, index) in subjects"
            :title="item.subjtNm"
            :subjtNm="subjectInfo[item.highByyySubjtSeq].subjtNm"
            :active="index === activeLiIndex"
            :index="index"
            :orgScr="item.originScore"
            :key="item.subjtNm"
            :is-parent="isParent"
            :is-math-test-result="isMathTestResult"
            questionViewType="4"
            @newactive="activeLiIndex = $event"
            :Limits="qustnList[index]"
            @isShowQuestionInfo="isShowInputReasonLayer = true"
            @questionViews="isActive = true"
            @qustnPrev="qustnPrev()"
            @qustnNext="qustnNext()"
            @qustnIndex="qustnIndex"
            @changeRegiWransrNote="changeRegiWransrNote"
            @go-test-analysis="goTestAnalysis()"
          />
        </div>
        <!-- //문항보기-학생 -->
      </div>
    </div>
    <div class="floating_bottom mo_only" v-if="isMathTestResult">
      <a class="button type1" @click="goTestAnalysis()"><span class="inner">성적분석</span></a>
    </div>
    <!-- 오답원인 입력 레이어 -->
    <Modal v-if="isShowInputReasonLayer" :modalTitle="currentQuestion.coransr === currentQuestion.myAnsr ? '정답원인 입력' : '오답원인 입력'" modalType="layer_wransr_write" @close-modal="isShowInputReasonLayer = false">
      <div slot="head">
        <h4>{{ currentQuestion.coransr === currentQuestion.myAnsr ? '정답원인 입력' : '오답원인 입력' }}</h4>
      </div>
      <div slot="body">
        <textarea v-model="reasonText" @keydown="onKeyDownReason($event)" maxlength="20" placeholder="(최대 20자)" cols="30" rows="5" class="tf_write"></textarea>
      </div>
      <div slot="foot">
        <button @click="saveCauseReasonText" type="button" class="btn_save">확인</button>
      </div>
    </Modal>
    <!-- //오답원인 입력 레이어 -->
    <expl-curri-vod expl-type="corWransr" v-if="!isMobile && showVodModalLayer" :explCurriVodUrl="explCurriVodUrl" @showExplVod="showVodModalLayer = false" /><!-- 해설 강의 팝업 -->
    <expl-curri-vod-mobile expl-type="corWransr" v-if="isMobile && showVodModalLayer" :explCurriVodUrl="explCurriVodUrl" @showExplVod="showVodModalLayer = false" /><!-- 해설 강의 팝업 -->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AsideList from '@/components/unityexam/common/popup/AsideList';
import Modal from '@/components/unityexam/common/Modal';
import ExplCurriVod from '@/components/dailytest/taketest/ExplCurriVod';
import ExplCurriVodMobile from '@/components/dailytest/taketest/ExplCurriVodMobile';
import { AWS_S3_RESOURCE_URL } from '@build/build.config';
import { USER_AUTHORITY } from '@/const/const';
import { tmsImage } from '@/mixins/dailytest/tmsImage';
import StringUtils from '@/utils/StringUtils';
import { PAGE_TITLE } from '../../../../const/const';
export default {
  name: 'unityExamViewQustnByExamPopup',
  mixins: [tmsImage],
  components: {
    AsideList,
    Modal,
    ExplCurriVod,
    ExplCurriVodMobile
  },
  data() {
    return {
      turnOrdSeq: this.$route.query.turnOrdSeq,
      bystdntUnityExamQustnInpStusSeq: this.$route.query.displaySeq,
      selectByyySubjtSeq: this.$route.params.selectByyySubjtSeq,
      isMathTestResult: this.$route.name === 'mathDailyTestResult',
      contentView: 'type1',
      isShowQuestionInfo: false,
      isOpenQuestionNumber: false,
      reasonWrongAnswer: '',
      isShowInputReasonLayer: false,
      showVodModalLayer: false,
      isActive: false,
      isActiveOn: 0,
      activeLiIndex: 0,
      items: 20,
      activeIndex: 0,
      qustnList: [],
      currentQuestion: {},
      explCurriVodUrl: '',
      awsResourceUrl: AWS_S3_RESOURCE_URL,
      causeCds: [],
      answerCause: [],
      wransrCause: [],
      reasonText: '',
      examInfo: {},
      subjects: [],
      subjectInfo: {},
      wransrrt: 0,
      isPrevBtn: false,
      USER_AUTHORITY,
      StringUtils
    };
  },
  watch: {
    activeLiIndex: {
      handler() {
        if (!this.isPrevBtn) {
          this.activeIndex = 0;
        } else {
          this.isPrevBtn = false;
        }
        this.currentQuestion = this.qustnList[this.activeLiIndex][this.activeIndex];
        this.answerCause = [];
        this.wransrCause = [];
        (this.causeCds[this.activeLiIndex] || []).forEach((cause) => {
          if (cause.frontType.indexOf('O') !== -1) {
            this.answerCause.push(cause);
          }
          if (cause.frontType.indexOf('X') !== -1) {
            this.wransrCause.push(cause);
          }
        });
      }
    },
    causeCds: {
      deep: true,
      handler() {
        this.answerCause = [];
        this.wransrCause = [];
        (this.causeCds[this.activeLiIndex] || []).forEach((cause) => {
          if (cause.frontType.indexOf('O') !== -1) {
            this.answerCause.push(cause);
          }
          if (cause.frontType.indexOf('X') !== -1) {
            this.wransrCause.push(cause);
          }
        });
      }
    },
    isShowInputReasonLayer() {
      if (this.isShowInputReasonLayer) {
        this.reasonText = (this.currentQuestion.coransrModel || {}).etcReason;
      }
    },
    activeIndex() {
      this.currentQuestion = this.qustnList[this.activeLiIndex][this.activeIndex];
      this.isActiveOn = this.activeIndex;
    },
    currentQuestion: {
      deep: true,
      handler() {
        const rt = this.currentQuestion.wransrrt || [];
        if (rt.length > 0) {
          this.wransrrt = rt[0].rate;
        } else {
          this.wransrrt = null;
        }
        let type = (this.currentQuestion.coransrModel || { realType: '' }).realType || '';
        if (
          !(this.causeCds[this.activeLiIndex] || []).some((value) => {
            return value.realType === type;
          })
        ) {
          type = '';
        }
        this.reasonWrongAnswer = (this.currentQuestion.coransrModel || { realType: '' }).realType || '';
      }
    }
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      userInfo: (state) => state.common.userInfo,
      questionInfo: (state) => state.unityexam.wransrnote.questionInfoByExam
    }),
    firstTitle() {
      return this.examInfo.unityExamDivNm;
    },
    secondTitle() {
      let result = '';
      if (this.examInfo.unityExamDivCd === '1') {
        result = this.examInfo.unityExamDtlDivCdNm;
      } else {
        result = this.examInfo.unityExamNm;
      }
      return result;
    },
    thirdTitle() {
      let result = '';
      if (this.examInfo.unityExamDivCd === '1') {
        result = this.examInfo.unityExamNm;
      } else {
        result = this.examInfo.unityDtlExamNm;
      }
      return result;
    },
    turnOrdTitle() {
      let result = '';
      if (this.examInfo.unityExamDivCd !== '1' && this.examInfo.unityDtlExamTurnOrd) {
        result = this.examInfo.unityDtlExamTurnOrd + '회';
      }
      return result;
    },
    highSubjtTitle() {
      const highByyySubjtSeq = ((this.questionInfo.subjectInfo || {})[this.selectByyySubjtSeq] || {}).highByyySubjtSeq || '';
      return ((this.questionInfo.subjectInfo || {})[highByyySubjtSeq] || {}).subjtNm || '';
    },
    subjtTitle() {
      return ((this.questionInfo.subjectInfo || {})[this.selectByyySubjtSeq] || {}).subjtNm || '';
    },
    totalTitle() {
      const titles = [];
      if (StringUtils.isNotEmpty(this.firstTitle)) {
        titles.push(this.firstTitle);
      }
      if (StringUtils.isNotEmpty(this.secondTitle)) {
        titles.push(this.secondTitle);
      }
      if (StringUtils.isNotEmpty(this.thirdTitle)) {
        titles.push(this.thirdTitle);
      }
      if (StringUtils.isNotEmpty(this.turnOrdTitle)) {
        titles.push(this.turnOrdTitle);
      }
      if (this.isMathTestResult && StringUtils.isNotEmpty(this.highSubjtTitle)) {
        titles.push(this.highSubjtTitle);
      }
      if (this.isMathTestResult && StringUtils.isNotEmpty(this.subjtTitle)) {
        titles.push(this.subjtTitle);
      }
      return titles.join(' > ');
    },
    getQuestionType() {
      const result = [];
      if (StringUtils.isNotEmpty(this.currentQuestion.purbNm)) {
        result.push(this.currentQuestion.purbNm);
      }
      if (StringUtils.isNotEmpty(this.currentQuestion.luntNm)) {
        result.push(this.currentQuestion.luntNm);
      }
      if (StringUtils.isNotEmpty(this.currentQuestion.sbptNm)) {
        result.push(this.currentQuestion.sbptNm);
      }
      if (StringUtils.isNotEmpty(this.currentQuestion.smunitNm)) {
        result.push(this.currentQuestion.smunitNm);
      }

      return result.join(' > ');
    },
    isFirstPage() {
      return (this.activeLiIndex === 0 || this.isMobile) && this.activeIndex === 0;
    },
    isLastPage() {
      return (this.activeLiIndex === this.qustnList.length - 1 || this.isMobile) && this.activeIndex === this.qustnList[this.activeLiIndex].length - 1;
    },
    isParent() {
      return (this.userInfo || {}).userAuthority === this.USER_AUTHORITY.PARENT;
    },
    currentExplImgPathwy() {
      let url = '';
      const qustnInpMethodCd = (this.currentQuestion || {}).qustnInpMethodCd;
      if (qustnInpMethodCd === '1') {
        url = this.awsResourceUrl + '/unity-exam/' + this.currentQuestion.explImgPathwy;
      } else if (qustnInpMethodCd === '2') {
        url = this.getTmsImg(this.currentQuestion.explImgPathwy);
      }
      return url;
    },
    currentTextImgPathwy() {
      let url = '';
      const qustnInpMethodCd = (this.currentQuestion || {}).qustnInpMethodCd;
      if (qustnInpMethodCd === '1') {
        url = this.awsResourceUrl + '/unity-exam/' + this.currentQuestion.textImgPathwy;
      } else if (qustnInpMethodCd === '2') {
        url = this.getTmsImg(this.currentQuestion.textImgPathwy);
      }
      return url;
    },
    currentQustnImgPathwy() {
      let url = '';
      const qustnInpMethodCd = (this.currentQuestion || {}).qustnInpMethodCd;
      if (qustnInpMethodCd === '1') {
        url = this.awsResourceUrl + '/unity-exam/' + this.currentQuestion.qustnImgPathwy;
      } else if (qustnInpMethodCd === '2') {
        url = this.getTmsImg(this.currentQuestion.qustnImgPathwy);
      }
      return url;
    }
  },
  updated() {
    if (this.contentView === 'type1') {
      document.querySelectorAll('.sel_ansr').forEach((element) => {
        element.classList.add('blur');
      });
    } else {
      document.querySelectorAll('.sel_ansr').forEach((element) => {
        element.classList.remove('blur');
      });
    }
    if (this.$refs.aside_list_qustn_view) {
      this.$refs.aside_list_qustn_view[this.activeLiIndex].isActive = this.activeIndex;
      this.$refs.aside_list_qustn_view[this.activeLiIndex].index = this.activeIndex;
    }
  },
  created() {
    if (window.NodeList && !NodeList.prototype.forEach) {
      NodeList.prototype.forEach = Array.prototype.forEach;
    }
    if (!this.userInfo.studentCd) {
      this.getUser().then(() => {
        this.init();
      });
    } else {
      this.init();
    }
    this.changeTitle();
  },
  mounted() {
    if (this.isMobile) {
      this.isActive = true;
      if (this.isMathTestResult) {
        this.isActive = false;
      }
    }
  },
  methods: {
    ...mapActions(['getUser', 'postChangeRegiWransrNote', 'postSaveCause', 'getQuestionByStudentExam', 'getMathDmTargetTestInfo']),
    changeTitle() {
      let menu = '';
      if (this.isMathTestResult) {
        menu = '응시결과';
      } else {
        menu = '문항보기';
      }
      let title = '';
      if (this.userInfo.branchCd !== '') title = PAGE_TITLE[this.userInfo.branchCd];
      document.title = 'mypage_수학DM - ' + menu + ' | ' + title;
    },
    windowCloseSelf() {
      if (this.isMathTestResult) {
        this.isActive = false;
        return;
      }
      var customWindow = window.open('', '_self', '');
      customWindow.close();
    },
    toggleActive(index) {
      this.isActiveOn = index;
      this.activeIndex = index;
      this.reasonWrongAnswer = (this.currentQuestion.coransrModel || {}).realType;
      this.isOpenQuestionNumber = false;
    },
    showInputReasonLayer() {
      if (this.reasonWrongAnswer !== '') {
        if (this.reasonWrongAnswer.substring(5, 8) === '009') this.isShowInputReasonLayer = true;
        this.saveCause(this.reasonWrongAnswer);
      }
    },
    closeViews() {
      const isViews = document.querySelector('.paper_info');
      isViews.classList.remove('active');
      this.isActive = false;
    },
    showExplVod(explCurriVodPathwy, qustnInpMethodCd) {
      this.explCurriVodUrl = '/unity-exam/' + explCurriVodPathwy;
      this.showVodModalLayer = true;
    },
    qustnPrev() {
      if (this.activeIndex > 0) {
        this.activeIndex--;
        this.isActiveOn = this.activeIndex;
        return;
      }
      if (this.activeLiIndex > 0 && this.activeIndex === 0) {
        this.activeLiIndex--;
        this.isActiveOn = this.activeIndex;
        this.isPrevBtn = true;
        this.activeIndex = this.qustnList[this.activeLiIndex].length - 1;
      }
    },
    qustnNext() {
      if (this.activeIndex < this.qustnList[this.activeLiIndex].length - 1) {
        this.activeIndex++;
        this.isActiveOn = this.activeIndex;
        return;
      }
      if (this.activeLiIndex < this.qustnList.length - 1 && this.activeIndex === this.qustnList[this.activeLiIndex].length - 1) {
        this.activeLiIndex++;
        this.activeIndex = 0;
        this.isActiveOn = this.activeIndex;
      }
    },
    qustnIndex(index) {
      this.activeIndex = index;
      this.isActiveOn = this.activeIndex;
    },
    changeRegiWransrNoteYn(qustn, e) {
      const checkedYn = e.target.checked === true ? 'Y' : 'N';
      qustn.coransrNotesYn = checkedYn;
      this.changeRegiWransrNote(qustn.unityExamQustnSeq, qustn.bystdntUnityExamQustnInpStusSeq, e);
    },
    changeRegiWransrNote(unityExamQustnSeq, bystdntUnityExamQustnInpStusSeq, e) {
      const params = {};
      params.coransrNotesYn = e.target.checked === true ? 'Y' : 'N';
      params.unityExamQustnSeq = unityExamQustnSeq;
      params.bystdntUnityExamQustnInpStusSeq = bystdntUnityExamQustnInpStusSeq;
      this.postChangeRegiWransrNote(params);
    },
    saveCause(reasonWrongAnswer) {
      const params = {};
      this.reasonWrongAnswer = reasonWrongAnswer;
      if (reasonWrongAnswer.indexOf('W') >= 0) {
        params.wransrCauseCd = reasonWrongAnswer;
      }
      if (reasonWrongAnswer.indexOf('C') >= 0) {
        params.coransrCauseCd = reasonWrongAnswer;
      }
      this.currentQuestion.coransrModel.realType = this.reasonWrongAnswer;

      params.unityExamQustnSeq = this.currentQuestion.unityExamQustnSeq;
      params.bystdntUnityExamQustnInpStusSeq = this.currentQuestion.bystdntUnityExamQustnInpStusSeq;
      this.postSaveCause(params);
    },
    saveCauseReasonText() {
      const params = {};
      params.coransrWransrNotesEtcOpninCont = this.reasonText;
      params.unityExamQustnSeq = this.currentQuestion.unityExamQustnSeq;
      params.bystdntUnityExamQustnInpStusSeq = this.currentQuestion.bystdntUnityExamQustnInpStusSeq;
      this.postSaveCause(params);
      this.currentQuestion.coransrModel.etcReason = this.reasonText;
      this.isShowInputReasonLayer = false;
    },
    isDisplayIndex(index) {
      const partition = Math.floor(this.activeIndex / 5);
      return partition * 5 <= index && index < (partition + 1) * 5;
    },
    goTestAnalysis() {
      this.$router.push({ name: 'AnalysisResult', query: { selectTurnOrdSeq: this.turnOrdSeq } });
    },
    init() {
      if (this.isMathTestResult) {
        this.contentView = 'type2';
      }
      const param = {
        studentCd: this.userInfo.studentCd,
        turnOrdSeq: this.turnOrdSeq
      };
      this.getMathDmTargetTestInfo(param);
      this.getQuestionByStudentExam(param).then(() => {
        this.examInfo = this.questionInfo.examInfo;
        const resultMap = this.questionInfo.resultMap || {};

        const result = [];
        this.subjects = [];
        this.causeCds = [];
        Object.values(this.questionInfo.subjectInfo || {}).forEach((subject) => {
          if (subject.highByyySubjtSeq === null) {
            return;
          }
          if (this.isMathTestResult && subject.byyySubjtSeq !== this.selectByyySubjtSeq) {
            return;
          }
          const currentSubjtResponses = (resultMap[subject.byyySubjtSeq] || {}).questionResponses || [];
          if (currentSubjtResponses.length === 0) {
            return;
          }
          let skip = true;
          currentSubjtResponses.forEach((response) => {
            if (skip && response.bystdntUnityExamQustnInpStusSeq) {
              skip = false;
            }
          });
          if (skip) {
            return;
          }
          this.causeCds.push((resultMap[subject.byyySubjtSeq] || {}).description || []);
          result.push(currentSubjtResponses);
          this.subjects.push(subject);
        });
        this.qustnList = result;
        this.subjectInfo = this.questionInfo.subjectInfo;
        if (!this.bystdntUnityExamQustnInpStusSeq) {
          if (this.isMathTestResult) {
            this.currentQuestion = (this.qustnList[0] || [])[0] || {};
          }
          return;
        }
        for (let liIdx = 0; liIdx < this.qustnList.length; liIdx++) {
          for (let idx = 0; idx < this.qustnList[liIdx].length; idx++) {
            if (this.qustnList[liIdx][idx].bystdntUnityExamQustnInpStusSeq === Number(this.bystdntUnityExamQustnInpStusSeq)) {
              this.currentQuestion = this.qustnList[liIdx][idx];
              this.activeLiIndex = liIdx;
              this.activeIndex = idx;
              this.isActiveOn = idx;
              this.isPrevBtn = true;
              return;
            }
          }
        }
      });
    },
    onKeyDownReason(evt) {
      if (this.reasonText.length === 20) {
        evt.target.blur();
        evt.target.focus();
      }
    }
  }
};
</script>
