import { Pie } from 'vue-chartjs';
import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels';

export default {
  extends: Pie,
  props: ['options', 'chartData'],
  watch: {
    chartData: {
      deep: true,
      handler() {
        this.renderChart(this.chartData, this.options);
      }
    },
    options: {
      deep: true,
      handler() {
        this.renderChart(this.chartData, this.options);
      }
    }
  },
  mounted() {
    this.addPlugin(ChartJSPluginDatalabels);
    this.renderChart(this.chartData, this.options);
  }
};
