<template>
  <div class="pay_content">
    <!-- ACARW-11167 2023-07-25 -->
    <div class="waiting_page">
      <h2 class="title">현재 접속자가 많아<br /><strong>페이지 접속 대기 중</strong>입니다.</h2>
      <p class="sub_title">잠시만 기다리시면 해당 페이지로 자동 접속됩니다.</p>

      <div class="waiting_time_wrap">
        <p class="progress_title">예상 대기시간</p>
        <span class="progress_time">{{ stringTime }}</span>
        <div class="progress_box">
          <div class="progress_bar" v-bind:aria-valuenow="progressValue" v-bind:style="styleObject"></div>
        </div>
      </div>

      <div class="bottom_txt_box">
        <p class="waiting_people">
          현재 대기 인원 <span>{{ total }}</span
          >명
        </p>
        <p class="desc">* 새로고침(F5)을 하거나 다시 접속하면 대기시간이 길어지니 주의하세요!</p>
      </div>
    </div>
    <!-- //ACARW-11167 2023-07-25 -->
  </div>
</template>

<script>
import CommonApi from '@/api/CommonApi';
export default {
  name: 'QueueingView',
  data() {
    return {
      data: [],
      path: this.$route.query.path,
      stringTime: '',
      time: 0,
      originTotal: 0,
      styleObject: {
        transitionDuration: '2.7s',
        width: '100%'
      },
      progressValue: 0,
      total: 0,
      params: {
        etoosMemNo: this.$route.query.etoosMemNo,
        time: this.$route.query.time
      }
    };
  },
  created() {
    this.fetchWaitingFirst(this.params); // 컴포넌트가 생성될 때 초기 회원 조회를 실행
    this.startFetching(); // 5초마다 회원 조회를 시작
  },
  methods: {
    async fetchWaitingFirst(params) {
      try {
        const response = await CommonApi.getQueueing(params);
        const firstTotal = response.data.total - 300;
        this.data = response.data;
        this.time = (firstTotal / 10).toFixed() * 5;
        const hour = Math.trunc(this.time / 3600)
          .toString()
          .padStart(2, '0');
        const minute = Math.trunc((this.time % 3600) / 60)
          .toString()
          .padStart(2, '0');
        const second = Math.trunc((this.time % 3600) % 60)
          .toString()
          .padStart(2, '0');
        this.stringTime = hour + ':' + minute + ':' + second;
        this.originTotal = firstTotal;
        this.total = firstTotal;

        // 회원 조회 요청 로직을 구현 (예: API 호출 등)
        // 데이터를 this.Waiting에 저장
      } catch (error) {
        console.error(error);
      }
    },

    async fetchWaiting(params) {
      try {
        const response = await CommonApi.getQueueing(params);
        this.data = response.data;
        this.time = this.time - 5;
        this.total = this.total - 10;
        if (this.time <= 0 && this.total <= 0) {
          clearInterval(this.fetchInterval);
          location.href = this.path;
        } else {
          const hour = Math.trunc(this.time / 3600)
            .toString()
            .padStart(2, '0');
          const minute = Math.trunc((this.time % 3600) / 60)
            .toString()
            .padStart(2, '0');
          const second = Math.trunc((this.time % 3600) % 60)
            .toString()
            .padStart(2, '0');
          this.stringTime = hour + ':' + minute + ':' + second;
          this.progressValue = Math.trunc((this.total / this.originTotal) * 100);
          this.styleObject.width = Math.trunc((this.total / this.originTotal) * 100) + '%';
        }
        // 회원 조회 요청 로직을 구현 (예: API 호출 등)
        // 데이터를 this.Waiting에 저장
      } catch (error) {
        console.error(error);
      }
    },
    startFetching() {
      this.fetchInterval = setInterval(() => {
        this.fetchWaiting(this.params); // 5초마다 조회
      }, 5000); // 5000 밀리초 = 5초
    },
    stopFetching() {
      clearInterval(this.fetchInterval); // 조회 멈추기
    }
  },
  beforeDestroy() {
    this.stopFetching(); // 컴포넌트가 소멸되기 전에 조회 중지
  }
};
</script>
