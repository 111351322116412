import MutationTypes from '@/store/MutationTypesClass';

export default {
  [MutationTypes.SET_STUDY_GRPSTATUS_LIST_AS]: (state, payload) => {
    state.study.studyGrpStatusDataAs = payload;
  },
  [MutationTypes.SET_STUDY_GRPSTATUS_LIST_TO]: (state, payload) => {
    state.study.studyGrpStatusDataTo = payload;
  },
  [MutationTypes.SET_STUDY_STATUS_LIST_AS]: (state, payload) => {
    state.study.studyStatusDataAs = payload;
  },
  [MutationTypes.SET_STUDY_STATUS_LIST_TO]: (state, payload) => {
    state.study.studyStatusDataTo = payload;
  },
  [MutationTypes.SET_SELECT_SUBJECT]: (state, payload) => {
    state.study.selectSubject = payload;
  }
};
