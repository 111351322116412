import MutationTypes from '@/store/MutationTypes';

export default {
  [MutationTypes.SET_TEST_LIST]: (state, payload) => {
    state.test.testList = payload;
  },
  [MutationTypes.SET_TAKEXAM_QST_WRANSR_NOTE_YN]: (state, payload) => {
    let exampprListIndex = -1;
    state.test.exampprList.some((a, index) => {
      if (a.takexamExamSeq === parseInt(payload.takexamExamSeq)) {
        exampprListIndex = index;
        return true;
      }
    });
    state.test.exampprList[exampprListIndex].questionList.some((q, index) => {
      if (q.takexamQstSeq === payload.takexamQstSeq) {
        state.test.exampprList[exampprListIndex].questionList[index].wransrNotesYn = payload.wransrNotesYn;
      }
    });
  },
  [MutationTypes.SET_ANALYZE_TEST]: (state, payload) => {
    state.test.analysisTest = payload;
  },
  [MutationTypes.SET_CHILD_SUBJECT_LIST]: (state, payload) => {
    state.test.childSubjectList = payload;
  }
};
