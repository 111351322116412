<template>
  <div class="item_filter">
    <button type="button" class="btn_toggle">
      <strong class="tit_cate">수강상태</strong>
      <em class="selected">{{ statusNames }}</em>
    </button>
    <div class="panel">
      <ul class="list_item">
        <li>
          <label class="lbl_item">
            <strong class="tit_item">전체</strong>
            <input
              type="checkbox"
              class="inp_check"
              name="grp"
              value=""
              v-model="studyStatus"
              @change="chkChange($event)"
            />
            <i class="ico_comm ico_check"></i>
          </label>
        </li>
        <li>
          <label class="lbl_item">
            <strong class="tit_item">수강예정</strong>
            <input
              type="checkbox"
              class="inp_check"
              name="grp"
              value="1"
              v-model="studyStatus"
              @change="chkChange($event)"
            />
            <i class="ico_comm ico_check"></i>
          </label>
        </li>
        <li>
          <label class="lbl_item">
            <strong class="tit_item">수강중</strong>
            <input
              type="checkbox"
              class="inp_check"
              name="grp"
              value="2"
              v-model="studyStatus"
              @change="chkChange($event)"
            />
            <i class="ico_comm ico_check"></i>
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      studyStatus: [''],
      statusNames: '전체'
    };
  },
  props: ['studyDt'],
  watch: {
    studyStatus(arg1) {
      if (this.studyStatus.length === 0) this.studyStatus = [''];
      this.sendStat();

      this.statusNames = '';
      const sta = [];
      arg1.forEach((el) => {
        if (el !== '') {
          if (el === '1') {
            sta.push('수강예정');
          } else if (el === '2') {
            sta.push('수강중');
          } else {
            sta.push('전체');
          }
        } else {
          sta.push('전체');
        }
      });
      this.statusNames = sta.join(',');
    },
    studyDt(arg1) {
      if (arg1[0] === '') {
        this.studyStatus = [''];
        this.sendStat();
      }
    }
  },
  methods: {
    sendStat() {
      this.$emit('state', this.studyStatus);
    },
    chkChange(e) {
      if (e.target.value !== '') {
        const index = this.studyStatus.indexOf('');
        if (index > -1) {
          this.studyStatus.splice(index, 1);
        }
      } else {
        this.studyStatus = [''];
      }
    }
  },
  destroyed() {}
};
</script>
