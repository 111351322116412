export default {
  /*******************************************************
   *** ERROR
   *******************************************************/
  ERROR: 'ERROR',
  SET_SUBJECT_YEARS: 'SET_SUBJECT_YEARS',
  SET_UNITY_SUBJECT_MAP: 'SET_UNITY_SUBJECT_MAP',
  SET_UNITY_SUBJECT_MAP_BY_YEAR: 'SET_UNITY_SUBJECT_MAP_BY_YEAR',
  SET_CURRENT_YEAR_SUBJT_MAP: 'SET_CURRENT_YEAR_SUBJT_MAP',
  SET_LAST_YEAR_SUBJT_MAP: 'SET_LAST_YEAR_SUBJT_MAP',
  SET_MY_SUBJECT_BY_YEAR: 'SET_MY_SUBJECT_BY_YEAR',
  CHART_DATA: 'CHART_DATA',
  SET_TEST_MAP: 'SET_TEST_MAP',
  SET_LAST_TEST: 'SET_LAST_TEST',
  SET_TEST_EXAM_SCORE: 'SET_TEST_EXAM_SCORE',
  SET_UNITY_DTL_DIV_LIST: 'SET_UNITY_DTL_DIV_LIST',
  UNIV_GROUP: 'UNIV_GROUP',
  SET_QUESTION_INFO: 'SET_QUESTION_INFO',
  SET_QUESTION_INFO_BY_EXAM: 'SET_QUESTION_INFO_BY_EXAM',
  SET_QUESTION_DETAIL_BY_PART: 'SET_QUESTION_DETAIL_BY_PART',
  SET_ANSWER_BY_QUESTION_TYPES: 'SET_ANSWER_BY_QUESTION_TYPES',
  WRANSR_CORANSR_CAUSE_LIST_STATS: 'WRANSR_CORANSR_CAUSE_LIST_STATS',
  SET_ANALYZE_AVAILABLE_MAP: 'SET_ANALYZE_AVAILABLE_MAP',
  WRANSR_NOTE_YEAR_INFO: 'WRANSR_NOTE_YEAR_INFO',
  WRANSR_NOTE_EXAM_TAB_INFO: 'WRANSR_NOTE_EXAM_TAB_INFO',
  WRANSR_NOTE_EXAM_INFO: 'WRANSR_NOTE_EXAM_INFO',
  WRANSR_NOTE_DTL_EXAM_INFO: 'WRANSR_NOTE_DTL_EXAM_INFO',
  WRANSR_NOTE_TABLE_EXAM_INFO: 'WRANSR_NOTE_TABLE_EXAM_INFO',
  WRANSR_NOTE_QUSTN_INFO: 'WRANSR_NOTE_QUSTN_INFO',
  ENTER_ANSWER_YEAR_INFO: 'ENTER_ANSWER_YEAR_INFO',
  ENTER_ANSWER_EXAM_TAB_INFO: 'ENTER_ANSWER_EXAM_TAB_INFO',
  ENTER_ANSWER_EXAM_INFO: 'ENTER_ANSWER_EXAM_INFO',
  ENTER_ANSWER_DTL_EXAM_INFO: 'ENTER_ANSWER_DTL_EXAM_INFO',
  ENTER_ANSWER_POSSIBLE_EXAM_INFO: 'ENTER_ANSWER_POSSIBLE_EXAM_INFO',
  ENTER_ANSWER_SELECT_EXAM_INFO: 'ENTER_ANSWER_SELECT_EXAM_INFO',
  SET_SELECT_EXAM_SEQ: 'SET_SELECT_EXAM_SEQ',
  SET_SELECT_DTL_EXAM_SEQ: 'SET_SELECT_DTL_EXAM_SEQ',
  SET_SELECT_DTL_EXAM_TURN_ORD_SEQ: 'SET_SELECT_DTL_EXAM_TURN_ORD_SEQ',
  SET_SELECT_EXAM_HIGH_SUBJT_SEQ: 'SET_SELECT_EXAM_HIGH_SUBJT_SEQ',
  SET_SELECT_EXAM_SUBJT_SEQ: 'SET_SELECT_EXAM_SUBJT_SEQ',
  ENTER_ANSWER_EXAM_QUSTN_INFO: 'ENTER_ANSWER_EXAM_QUSTN_INFO',
  ENTER_ANSWER_EXAM_QUSTN_TAM_INFO: 'ENTER_ANSWER_EXAM_QUSTN_TAM_INFO',
  ANSWER_CAUSE_LIST: 'ANSWER_CAUSE_LIST',
  WRANSR_CAUSE_LIST: 'WRANSR_CAUSE_LIST',
  SUBJT_QUESTION_ANSWER_PRINT: 'SUBJT_QUESTION_ANSWER_PRINT'
};
