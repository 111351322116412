import MutationTypes from '@/store/MutationTypesClass';
import StudyStatusApi from '@/api/StudyStatusApi';

export default {
  getStudyGrpStatusAs: async ({ commit }, params) => {
    try {
      const result = await StudyStatusApi.getStudyStatusList(params);
      commit(MutationTypes.SET_STUDY_GRPSTATUS_LIST_AS, result.data);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  getStudyGrpStatusTo: async ({ commit }, params) => {
    try {
      const result = await StudyStatusApi.getStudyStatusList(params);
      commit(MutationTypes.SET_STUDY_GRPSTATUS_LIST_TO, result.data);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  getStudyStatusAs: async ({ commit }, params) => {
    try {
      const result = await StudyStatusApi.getStudyStatusList(params);

      commit(MutationTypes.SET_STUDY_STATUS_LIST_AS, result.data);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  getStudyStatusTo: async ({ commit }, params) => {
    try {
      const result = await StudyStatusApi.getStudyStatusList(params);
      commit(MutationTypes.SET_STUDY_STATUS_LIST_TO, result.data);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  getSelectSubject: async ({ commit }, params) => {
    try {
      const result = await StudyStatusApi.getSelectSubject(params);

      commit(MutationTypes.SET_SELECT_SUBJECT, result.data);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  }
};
