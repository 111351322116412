export const classRoomPopup = {
  data() {
    return {};
  },
  methods: {
    openClassRoomPopup(href, windowName, targetWindowName, callBackFunction, size) {
      if (!size) {
        size = 'width=1300,height=800,scrollbars=1';
      } else {
        let width = size.width;
        let height = size.height;
        const popupX = size.popupX;
        const popupY = size.popupY;
        if (!width) {
          width = 1300;
        } else if (!height) {
          height = 800;
        }
        size = 'width=' + width + ',height=' + height + ',left=' + popupX + ',top=' + popupY + ',scrollbars=1';
      }
      window.name = windowName;
      const new_window = window.open(href, targetWindowName, size);
      new_window.onbeforeunload = function(e) {
        callBackFunction(e);
      };
    }
  }
};
