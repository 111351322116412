<template>
  <div id="unityexam" class="pay_content">
    <div class="mypage_comm acs_contents" :class="{ mobile: isMobile }">
      <h4 class="pc_only"><span>학원콘텐츠</span></h4>
      <div class="mo_only tab_select">
        <ul class="list_select">
          <li v-for="(obj, key) in unityExamDivCds" :class="unityExamDivCd === key ? 'on' : ''">
            <a href="javascript:;" class="link_cont" @click="changeUnityExamDivCd(key)">{{ obj.name }}</a>
          </li>
        </ul>
      </div>
      <div class="select_exam_system">
        <div class="inner">
          <div class="wrap_select_year">
            <select class="select_cont" v-model="unityExamYear">
              <option
                :key="idx"
                :value="key"
                v-for="(key, idx) in Object.keys(unityExamTests)
                  .sort()
                  .reverse()"
              >{{ key }}년</option
              >
            </select>
          </div>
          <div class="wrap_select_exam pc_only">
            <select class="select_cont" v-model="unityExamDivCd">
              <option v-for="(obj, key) in unityExamDivCds" :value="key" :key="key">{{ obj.name }}</option>
            </select>
          </div>
          <div class="wrap_select_exam">
            <select class="select_cont" v-model="unityExamSeq">
              <option v-for="(obj, key) in unityExams" :value="key" :key="key">{{ obj.name }}</option>
            </select>
          </div>
          <div class="wrap_select_exam">
            <select class="select_cont" v-model="unityDtlExamSeq">
              <option v-for="(obj, key) in unityDtlExams" :value="key" :key="key"> {{ obj.name }}</option>
            </select>
          </div>
          <div class="wrap_select_times">
            <select class="select_cont" v-model="unityTurnOrdSeq">
              <option v-for="(obj, idx) in unityTurnOrds" :value="obj.unityDtlExamTurnOrdSeq" :key="obj.unityDtlExamTurnOrdSeq"> {{ obj.unityDtlExamTurnOrd }}회</option>
            </select>
          </div>
        </div>
      </div>
      <div class="wrap_nolist" v-show="!hasTest">
        <p>응시설정된 시험이 없습니다.</p>
      </div>
      <tab-graph-box :radioBoxType="0" graphTitle="성적추이 그래프 (원점수)" @open-Modal="showModalSetGraphLayer = true" :chart-data="chartData || []" :chart-options="chartOptions" v-show="hasTest" />
      <tab-table-box tableStyle="acaContentsScore" tableTitle="성적정보 (원점수)" v-show="hasTest" />
      <div class="wrap_title" v-show="hasTest">
        <h5 class="tit_cont"><span>문항정보</span></h5>
        <a v-if="userInfo.userAuthority === USER_AUTHORITY.STUDENT" href="/unityexam/wransnote" class="link_note pc_only">오답노트 바로가기</a>
      </div>
      <tab-menu typeClass="tab_comm" @getQuestionInfo="getQuestionInfoList" ref="tabMenu" v-show="hasTest">
        <tab-pannel :name="obj.subjtNm" :selected="key === 0" v-for="(obj, key) in subjtList" :key="key" :byyy-subjt-seq="obj.byyySubjtSeq" ref="subject">
          <template #TabelGrid>
            <tab-table-box tableStyle="acaContentsQuestionInfo" @open-WindowPopup="openPopup" @change-coransr-note-yn="changeCoransrNoteYn" @show-expl-vod="showExplVod" />
          </template>
        </tab-pannel>
      </tab-menu>
      <!-- 그래프 설정 레이어 -->
      <Modal v-if="showModalSetGraphLayer" modalTitle="성적추이 그래프" modalType="layer_graph_setting pc_only" @close-modal="closeModalSetGraphLayer">
        <div slot="head">
          <h4>성적추이 그래프</h4>
        </div>
        <div slot="body">
          <div class="data_setting">
            <dl>
              <dt>과목/선택과목</dt>
              <dd>
                <div class="type_check">
                  <label for="all" class="lab_check">
                    <input type="checkbox" id="all" class="inp_check" name="subjectRadio" v-model="modalChartAll" @change="modalChartAllChange($event)" />
                    <span class="ico_check"></span>
                    <span>전체</span>
                  </label>
                </div>
              </dd>
            </dl>
            <dl class="sub_data" v-for="(obj, key) in currentSubjectMap" :key="key">
              <dt></dt>
              <dd>
                <dl>
                  <dt>
                    <div class="type_check">
                      <label :for="key" class="lab_check">
                        <input type="checkbox" :id="key" class="inp_check" name="subjectRadio" :value="key" v-model="modalChartFilter.highSubjt" />
                        <span class="ico_check"></span>
                        <span>{{ obj.subjtNm }}</span>
                      </label>
                    </div>
                  </dt>
                  <dd>
                    <div class="type_check" v-if="key !== 'T'">
                      <label :for="key + idx" class="lab_radio" v-for="(child, idx) in obj.child" :key="idx">
                        <input type="radio" :id="key + idx" class="inp_radio" :name="key" v-model="modalChartFilter.byyySubjtSeq[key]" :value="child.byyySubjtSeq" />
                        <span class="ico_radio"></span>{{ child.subjtNm }}
                      </label>
                    </div>
                    <div class="type_check" v-if="key === 'T'">
                      <label :for="key + idx" class="lab_check" v-for="(child, idx) in obj.child" :key="idx">
                        <input type="checkbox" :id="key + idx" class="inp_check" :key="idx" v-model="modalChartFilter.byyySubjtSeq[key]" :value="child.byyySubjtSeq" @click="lengthCheck(key, $event, child.byyySubjtSeq)" />
                        <span class="ico_check"></span>
                        <span>{{ child.subjtNm }}</span>
                      </label>
                    </div>
                  </dd>
                </dl>
              </dd>
            </dl>
          </div>
        </div>
        <div slot="foot">
          <button type="button" class="btn_save" @click="applyGraphFilter">적용</button>
          <button type="button" class="btn_cancel" @click="closeModalSetGraphLayer">취소</button>
        </div>
      </Modal>
      <!-- //그래프 설정 레이어 -->
      <ExplVurriVod expl-type="corWransr" :expl-curri-vod-url="explCurriVodUrl" v-if="showVodModalLayer" @showExplVod="showVodModalLayer = false" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TabMenu from './common/TabMenu';
import TabPannel from './common/TabPannel';
import TabTableBox from './common/TabTableBox';
import TabGraphBox from './common/TabGraphBox';
import Modal from './common/Modal';
import { USER_AUTHORITY } from '@/const/const';
import { windowOpenPopup } from '@/mixins/windowOpenPopup';
import moment from 'moment';
import ExplVurriVod from '../dailytest/taketest/ExplCurriVod';
import { deepCopy } from '@/utils/util';
import { PAGE_TITLE } from '../../const/const';

export default {
  name: 'acaContents',
  mixins: [windowOpenPopup],
  components: {
    TabMenu,
    TabPannel,
    TabTableBox,
    TabGraphBox,
    Modal,
    ExplVurriVod
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      userInfo: (state) => state.common.userInfo,
      unityExamTests: (state) => state.unityexam.wransrnote.unityExamTests,
      personalScoreMap: (state) => state.unityexam.wransrnote.personalScoreMap,
      currentSubjectMap: (state) => state.unityexam.wransrnote.currentSubjectMap,
      getChartData: (state) => state.unityexam.wransrnote.chartData,
      lastTest: (state) => state.unityexam.wransrnote.lastTest,
      mySubjectList: (state) => state.unityexam.wransrnote.mySubjectList
    })
  },
  watch: {
    unityExamTests: {
      deep: true,
      handler(tests) {
        this.hasTest = Object.keys(tests).length !== 0;
      }
    },
    personalScoreMap() {
      const filterOrder = ['K', 'M', 'E', 'T', 'H', 'L'];
      const seqTypeMap = {};
      const result = [];
      filterOrder.forEach((column) => {
        const checkSeqs = [];
        const subject = this.currentSubjectMap[column] || {};
        const highSubjtSeq = subject.byyySubjtSeq || 0;
        if (highSubjtSeq === 0) {
          return;
        }
        checkSeqs.push(highSubjtSeq);
        subject.child.forEach((childSubject) => {
          seqTypeMap[childSubject.byyySubjtSeq] = column;
          checkSeqs.push(childSubject.byyySubjtSeq);
        });
        checkSeqs.forEach((byyySubjtSeq) => {
          if (Object.keys(this.personalScoreMap[byyySubjtSeq] || {}).length !== 0) {
            const score = this.personalScoreMap[byyySubjtSeq];
            score.byyySubjtSeq = byyySubjtSeq;
            result.push(score);
          }
        });
      });
      this.subjtList = result;

      const tamguList = [],
        defaultCheckData = JSON.parse(JSON.stringify(this.personalScoreMap));
      if (Object.keys(defaultCheckData).length === 0) {
        (this.mySubjectList || []).forEach((selSubjt) => {
          defaultCheckData[selSubjt.byyySubjtSeq] = {};
        });
      }
      this.modalChartFilter.highSubjt = [];
      Object.keys(defaultCheckData).forEach((key) => {
        const subjtKey = seqTypeMap[key];
        if (subjtKey === 'T') {
          tamguList.push(Number(key));
          return;
        }
        if (subjtKey === undefined) {
          return;
        }

        this.modalChartFilter.byyySubjtSeq[subjtKey] = Number(key);
        if (this.modalChartFilter.highSubjt.indexOf(subjtKey) === -1) {
          this.modalChartFilter.highSubjt.push(subjtKey);
        }
      });
      if (tamguList.length !== 0) {
        this.modalChartFilter.byyySubjtSeq['T'] = tamguList;
        this.modalChartFilter.highSubjt.push('T');
      }
      this.modalChartAll = Object.keys(this.currentSubjectMap).length === this.modalChartFilter.highSubjt.length;
      this.chartFilter = JSON.parse(JSON.stringify(this.modalChartFilter));
    },
    unityExamYear(year) {
      if (year === '') {
        return;
      }
      this.unityExamDivCd = '';
      this.unityExamDivCds = {};
      Object.keys(this.unityExamTests[year]).forEach((key) => {
        if (key === 'name') {
          return;
        }
        this.unityExamDivCds[key] = deepCopy(this.unityExamTests[year][key]);
      });
      this.getStdSelSubjectByYear({ year, studentCd: this.userInfo.studentCd });
    },
    unityExamDivCds: {
      deep: true,
      handler() {
        if (Object.keys(this.lastTest).length !== 0) {
          this.unityExamDivCd = this.lastTest.unityExamDivCd;
        } else if (this.unityExamDivCd === '') {
          if (Object.keys(this.unityExamDivCds).length !== 0) {
            this.unityExamDivCd = Object.keys(this.unityExamDivCds)[0];
          }
        }
        this.unityExamDivCdWatchFn(this.unityExamDivCd);
      }
    },
    unityExamDivCd(unityExamDivCd) {
      if (unityExamDivCd === '') {
        return;
      }
      this.unityExamDivCdWatchFn(unityExamDivCd);
    },
    unityExamSeq(seq) {
      if (seq === 0) {
        return;
      }
      this.unityDtlExams = {};
      this.unityDtlExamSeq = 0;

      Object.keys(this.unityExams[seq]).forEach((key) => {
        if (key === 'name') {
          return;
        }
        if (Object.keys(this.lastTest).length !== 0) {
          this.unityDtlExamSeq = this.lastTest.unityDtlExamSeq;
        } else if (this.unityDtlExamSeq === 0) {
          this.unityDtlExamSeq = Number(key);
        }
        this.unityDtlExams[key] = this.unityExams[seq][key];
      });
    },
    unityDtlExamSeq(seq) {
      if (seq === 0) {
        return;
      }
      this.unityTurnOrds = [];
      this.unityTurnOrdSeq = 0;
      const result = [];
      Object.keys(this.unityDtlExams[seq]).forEach((key) => {
        if (key === 'name') {
          return;
        }
        result.push(this.unityDtlExams[seq][key]);
      });
      result.sort((obj1, obj2) => {
        return obj1.unityDtlExamTurnOrd - obj2.unityDtlExamTurnOrd;
      });
      if (Object.keys(this.lastTest).length !== 0) {
        this.unityTurnOrdSeq = this.lastTest.unityDtlExamTurnOrdSeq;
        this.$store.commit('SET_LAST_TEST', {});
      } else if (result.length > 0) {
        this.unityTurnOrdSeq = result[0].unityDtlExamTurnOrdSeq;
      }
      this.unityTurnOrds = result;
      const unityExamDivCd = this.unityExamDivCd;
      const dtlExamSeq = seq;
      const studentCd = this.userInfo.studentCd;
      const param = { unityExamDivCd, dtlExamSeq, studentCd };
      this.getAcaContentsScoreOverDtlExamSeq(param);
    },
    unityTurnOrdSeq(seq) {
      if (seq === 0) {
        return;
      }
      const unityDtlExamSeq = this.unityDtlExamSeq,
        unityTurnOrdSeq = seq,
        studentCd = this.userInfo.studentCd;
      const param = {
        unityDtlExamSeq,
        unityTurnOrdSeq,
        studentCd
      };
      this.getSubjectMap(seq);
      this.chartXRowSet();
      this.getAcaContentsScore(param).then(() => {
        const tab = this.$refs.tabMenu.$children;
        if (tab.length > 0) {
          this.getQuestionInfoList(tab[0].$props.byyySubjtSeq);
        }
      });
    },
    chartFilter: {
      deep: true,
      handler() {
        this.changeChartData();
      }
    },
    getChartData: {
      deep: true,
      handler() {
        this.changeChartData();
      }
    },
    modalChartFilter: {
      deep: true,
      handler(filter) {
        this.modalChartAll = filter.highSubjt.length === Object.keys(this.currentSubjectMap).length;
      }
    }
  },
  data() {
    return {
      USER_AUTHORITY,
      popup: undefined,
      hasTest: false,
      showModalSetGraphLayer: false,
      explCurriVodUrl: '',
      showVodModalLayer: false,
      subjtList: [],
      unityExamYear: '',
      unityExamDivCd: '',
      unityExamSeq: 0,
      unityDtlExamSeq: 0,
      unityTurnOrdSeq: 0,
      unityExamDivCds: {},
      unityExams: {},
      unityDtlExams: {},
      unityTurnOrds: [],
      chartFilter: {
        highSubjt: [],
        byyySubjtSeq: { K: '', M: '', E: '', T: [], H: '', L: '' }
      },
      modalChartFilter: {
        highSubjt: [],
        byyySubjtSeq: { K: '', M: '', E: '', T: [], H: '', L: '' }
      },
      chartAll: false,
      modalChartAll: false,
      chartData: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'right',
          align: 'start',
          labels: {
            usePointStyle: true,
            boxWidth: 2,
            padding: 20,
            fontColor: '#222'
          }
        },
        layout: {
          padding: {
            top: 50,
            bottom: 20,
            right: 40,
            left: 40
          }
        },
        scales: {
          xAxes: [
            {
              show: true,
              offset: true,
              min: -1,
              ticks: {
                maxRotation: 0,
                autoSkip: false,
                callback(label, index, labels) {
                  if (/\s/.test(label)) {
                    return label.split(' ');
                  } else {
                    return label;
                  }
                },
                fontColor: '#222'
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                min: 0,
                max: 100,
                stepSize: 20,
                beginAtZero: true,
                fontColor: '#222'
              }
            }
          ]
        },
        annotation: {
          annotations: [
            {
              type: 'box',
              drawTime: 'beforeDatasetsDraw',
              borderColor: '',
              id: 'a-box-1',
              yMin: 0,
              yMax: 100,
              xMin: 3,
              xMax: 4,
              xScaleID: 'x-axis-0',
              yScaleID: 'y-axis-0',
              backgroundColor: 'rgba(244, 244, 244, 0.6)',
              borderWidth: 0
            }
          ]
        },
        plugins: {
          datalabels: {
            align: 'right',
            offset: 10,
            color: '#222'
          }
        }
      }
    };
  },
  methods: {
    ...mapActions(['getUser', 'getAcaContentsList', 'getAcaContentsScore', 'getSubjectMap', 'getQuestionInfo', 'updateChangeCoransNoteYn', 'getAcaContentsScoreOverDtlExamSeq', 'getStdSelSubjectByYear', 'setHeaderInfo']),
    changeTitle() {
      let title = '';
      if (this.userInfo.branchCd !== '') title = PAGE_TITLE[this.userInfo.branchCd];
      document.title = 'mypage_성적관리 - 학원콘텐츠 | ' + title;
    },
    openPopup(bystdntUnityExamQustnInpStusSeq) {
      const route = this.$router.resolve({
        name: 'unityExamViewQustnByExamPopup',
        query: {
          turnOrdSeq: this.unityTurnOrdSeq,
          displaySeq: bystdntUnityExamQustnInpStusSeq
        }
      });
      this.openWindowPopup(route.href, this.$route.name, 'unityExamViewQustnByExamPopup', this.locationReload, 'width=990, height=800, scrollbars=1', this.isMobile);
    },
    closePopup() {
      this.popup.close();
      window.opener.child.close();
    },
    locationReload() {
      location.reload();
    },
    getQuestionInfoList(byyySubjtSeq) {
      const studentCd = this.userInfo.studentCd;
      const turnOrdSeq = this.unityTurnOrdSeq;
      const param = {
        turnOrdSeq,
        byyySubjtSeq,
        studentCd
      };
      this.getQuestionInfo(param);
    },
    changeCoransrNoteYn(bystdntUnityExamQustnInpStusSeq) {
      const studentCd = this.userInfo.studentCd;
      const param = {
        studentCd,
        bystdntUnityExamQustnInpStusSeq
      };
      this.updateChangeCoransNoteYn(param);
    },
    closeModalSetGraphLayer() {
      this.showModalSetGraphLayer = false;
      this.modalChartFilter = JSON.parse(JSON.stringify(this.chartFilter));
    },
    lengthCheck(key, event, byyySubjtSeq) {
      if (key !== 'T') {
        return;
      }
      if (this.modalChartFilter.byyySubjtSeq[key].length >= 2) {
        if (this.modalChartFilter.byyySubjtSeq[key].indexOf(byyySubjtSeq) === -1) {
          alert('탐구는 선택과목 2개까지 선택 가능합니다.');
          event.target.checked = false;
        }
      }
    },
    applyGraphFilter() {
      if (this.modalChartFilter.highSubjt.length === 0) {
        alert('과목을 선택해 주세요.');
        return;
      }
      for (const key of this.modalChartFilter.highSubjt) {
        if (this.modalChartFilter.byyySubjtSeq[key].length === 0) {
          alert('선택과목을 선택해 주세요.');
          return;
        }
      }

      Object.keys(this.modalChartFilter.byyySubjtSeq).forEach((subjtKey) => {
        if (this.modalChartFilter.highSubjt.indexOf(subjtKey) === -1) {
          this.modalChartFilter.byyySubjtSeq[subjtKey] = subjtKey === 'T' ? [] : '';
        }
      });

      this.chartFilter = JSON.parse(JSON.stringify(this.modalChartFilter));
      this.showModalSetGraphLayer = false;
    },
    changeChartData() {
      const checkKey = [],
        label = {},
        color = {};
      const result = {};
      Object.keys(this.currentSubjectMap).forEach((key) => {
        const value = this.currentSubjectMap[key];
        value.child.forEach((child) => {
          checkKey.push(child.byyySubjtSeq);
          label[child.byyySubjtSeq] = child.subjtNm;
          color[child.byyySubjtSeq] = value.subjtColrCd || this.getRandomColor();
        });
      });
      checkKey.forEach((byyySubjtSeq) => {
        result[byyySubjtSeq] = [];
      });
      for (const item of this.getChartData) {
        checkKey.forEach((byyySubjtSeq) => {
          const scoreMapElement = item.scoreMap[byyySubjtSeq];
          if (scoreMapElement === undefined || scoreMapElement['orgScr'] === undefined) {
            result[byyySubjtSeq].push(null);
          } else {
            result[byyySubjtSeq].push(scoreMapElement['orgScr']);
          }
        });
      }
      const resultData = [];
      Object.keys(result).forEach((key) => {
        const value = result[key];
        let isEmpty = true;
        value.forEach((el) => {
          if (el !== null) {
            isEmpty = false;
          }
        });
        if (isEmpty || !this.filterByChartFilter(key)) {
          return;
        }
        const param = {
          label: label[key],
          borderColor: color[key],
          pointBorderWidth: 6,
          pointBackgroundColor: color[key],
          data: value,
          fill: false,
          tension: 0
        };
        resultData.push(param);
      });

      this.chartData = {
        labels: this.chartXRowSet(),
        datasets: resultData
      };
    },
    filterByChartFilter(key) {
      for (const byyySubjtSeqKey in this.chartFilter.byyySubjtSeq) {
        if ((byyySubjtSeqKey === 'T' && this.chartFilter.byyySubjtSeq[byyySubjtSeqKey].indexOf(Number(key)) !== -1) || (byyySubjtSeqKey !== 'T' && Number(this.chartFilter.byyySubjtSeq[byyySubjtSeqKey]) === Number(key))) {
          return true;
        }
      }
      return false;
    },
    getRandomColor() {
      let color = Math.floor(Math.random() * 16777215).toString(16);
      while (color.length < 6) {
        color = '0' + color;
      }
      return '#' + color;
    },
    chartXRowSet() {
      const result = [];
      this.getChartData.forEach((item) => {
        result.push(item.unityDtlExamTurnOrd + '회');
      });

      const turnOrd = this.unityDtlExams[this.unityDtlExamSeq][this.unityTurnOrdSeq].unityDtlExamTurnOrd;
      const index = result.indexOf(turnOrd + '회');
      this.chartOptions.annotation.annotations[0].xMin = index - 0.5;
      this.chartOptions.annotation.annotations[0].xMax = index + 0.5;
      return result;
    },
    showExplVod(url) {
      this.explCurriVodUrl = url;
      this.showVodModalLayer = true;
    },
    changeUnityExamDivCd(cd) {
      this.unityExamDivCd = cd;
    },
    modalChartAllChange(e) {
      if (e.target.checked) {
        this.modalChartFilter.highSubjt = Object.keys(this.currentSubjectMap);
      } else {
        this.modalChartFilter.highSubjt = [];
      }
    },
    unityExamDivCdWatchFn(unityExamDivCd) {
      this.unityExams = {};
      this.unityExamSeq = 0;

      Object.keys(this.unityExamDivCds[unityExamDivCd]).forEach((key) => {
        if (key === 'name') {
          return;
        }
        if (Object.keys(this.lastTest).length !== 0) {
          this.unityExamSeq = this.lastTest.unityExamSeq;
        } else if (this.unityExamSeq === 0) {
          this.unityExamSeq = Number(key);
        }
        this.unityExams[key] = this.unityExamDivCds[unityExamDivCd][key];
      });
    },
    init() {
      const studentCd = this.userInfo.studentCd;
      const branchCd = this.userInfo.branchCd;
      const param = {
        studentCd,
        branchCd
      };
      this.getAcaContentsList(param).then(() => {
        if (Object.keys(this.lastTest).length === 0) {
          const arr = Object.keys(this.unityExamTests)
            .sort()
            .reverse();
          if (arr.length > 0) {
            this.unityExamYear = arr[0];
          }
        } else {
          this.unityExamYear = this.lastTest.unityExamYy;
        }
      });
    }
  },
  created() {
    this.setHeaderInfo({ menuName: '학원콘텐츠', isPrev: false });
    if (!this.userInfo.studentCd) {
      this.getUser().then(() => {
        this.init();
      });
    } else {
      this.init();
    }
    this.changeTitle();
  }
};
</script>
