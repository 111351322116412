import wransrnote from './wransrnote';
import enteranswer from './enteranswer';

export default {
  state: {
    wransrnote: wransrnote.state,
    enteranswer: enteranswer.state
  },
  actions: { ...wransrnote.actions, ...enteranswer.actions },
  mutations: { ...wransrnote.mutations, ...enteranswer.mutations },
  getters: { ...wransrnote.getters, ...enteranswer.getters }
};
