import { httpClient } from '../httpclient/HttpClientFactory';

export default {
  getMyClassInfo: (request) => httpClient.get(`/api/infinitePass/classRoom/info`, { params: request }).then((res) => res.data),
  getInfiniteSubjects: (request) => httpClient.get(`/api/infinitePass/subject/list`, { params: request }).then((res) => res.data),
  getInfiniteTeachers: (request) => httpClient.get(`/api/infinitePass/teacher/list`, { params: request }).then((res) => res.data),
  getTurnOrdLectures: (request) => httpClient.get(`/api/infinitePass/curri/list`, { params: request }).then((res) => res.data),
  deleteCurriInClass: (request) => httpClient.post(`/api/infinitePass/curri/inclass/delete`, { ...request }).then((res) => res.data),
  getSearchData: (apiPath, request) => httpClient.get(apiPath, { params: request }).then((res) => res.data),
  addInterestedLecture: (request) => httpClient.post(`/api/infinitePass/interest/lecture/add`, { ...request }).then((res) => res.data),
  removeInterestedLectures: (request) => httpClient.delete(`/api/infinitePass/interest/delete`, { data: request }).then((res) => res.data),
  playCurri: (mediaContsKey, curriVodNo, viewYn) => {
    if (viewYn === 'N') {
      alert('제공 가능한 강의가 없습니다. 학원에 문의해주세요.');
      return;
    }

    httpClient.get(`/api/curri-url`, { params: { mediaContentsKey: mediaContsKey, curriVodNo } }).then((res) => {
      const response = res.data;

      if (response.code !== '00000') {
        alert('영상 정보 재생에 실패했습니다. 관리자에 문의해주세요.');
        return;
      }

      setTimeout(function() {
        window.open(response.data, 'curriWindow', 'popup');
      }, 10);
    });
  }
};
