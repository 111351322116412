<style scoped>
.data_setting dd {
  height: auto !important;
}
</style>

<template>
  <div id="unityexam" class="pay_content">
    <div class="mypage_comm trial_exam" :class="{ mobile: isMobile }">
      <h4 class="pc_only"><span>정규모의고사</span></h4>
      <div class="select_exam_system">
        <div class="inner">
          <div class="wrap_select_year">
            <select class="select_cont" v-model="unityExamYear">
              <option
                v-for="(key, idx) in Object.keys(unityExamTests)
                  .sort()
                  .reverse()"
                :key="idx"
                :value="key"
                >{{ key }}년</option
              >
            </select>
          </div>
          <div class="wrap_select_system">
            <select class="select_cont" v-model="unityDtlDivCd">
              <option v-for="(obj, key) in unityDtlDivCds" :key="key" :value="key">{{ getUnityDtlDivNm(key) }}</option>
            </select>
          </div>
          <div class="wrap_select_month">
            <select class="select_cont" v-model="unityExamSeq">
              <option v-for="(obj, key) in unityDtlDivCds[unityDtlDivCd]" :key="key" :value="key">{{ obj.name }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="wrap_nolist" v-show="!hasTest">
        <p>응시설정된 시험이 없습니다.</p>
      </div>
      <tab-graph-box
        v-show="hasTest"
        :radioBoxType="3"
        graphTitle="성적추이 그래프"
        @open-Modal="showModalSetGraphLayer = true"
        :chart-target-field="chartTargetField"
        :chart-data="chartData || []"
        :chart-options="chartOptions"
        @set-chart-field="setChartFieldName"
      />
      <tab-table-box tableStyle="regularTestScore" tableTitle="성적정보" :univ-group="this.univGroup" v-show="hasTest" />
      <div class="wrap_title" v-show="hasTest">
        <h5 class="tit_cont"><span>문항정보</span></h5>
        <a v-if="userInfo.userAuthority === USER_AUTHORITY.STUDENT" href="/unityexam/wransnote" class="link_note pc_only">오답노트 바로가기</a>
      </div>
      <tab-menu typeClass="tab_comm" @getQuestionInfo="getQuestionInfoList" ref="tabMenu" v-show="hasTest">
        <tab-pannel :name="obj.subjtNm" :selected="key === 0" v-for="(obj, key) in subjtList" :key="key" :byyy-subjt-seq="obj.byyySubjtSeq" ref="subject">
          <template #TabelGrid>
            <tab-table-box tableStyle="regularTestQuestionInfo" @open-WindowPopup="openPopup" @change-coransr-note-yn="changeCoransrNoteYn" @show-expl-vod="showExplVod" />
          </template>
        </tab-pannel>
      </tab-menu>
      <!-- 그래프 설정 레이어 -->
      <Modal v-if="showModalSetGraphLayer" modalTitle="성적추이 그래프" modalType="layer_graph_setting pc_only" @close-modal="closeModalSetGraphLayer">
        <div slot="head">
          <h4>성적추이 그래프</h4>
        </div>
        <div slot="body">
          <div class="data_setting">
            <dl>
              <dt>시험</dt>
              <dd>
                <div class="type_check">
                  <label :for="'modalUnityDtlDivCd_' + item.data" class="lab_check" v-for="(item, idx) in unityExamDtlDivCdList" :key="idx">
                    <input type="checkbox" :id="'modalUnityDtlDivCd_' + item.data" class="inp_check" name="unityDtlDivCds" checked="" :disabled="item.data === '1'" v-model="modalChartFilter.unityExamDtlDivCds" :value="item.data" />
                    <span class="ico_check"></span>
                    <span>{{ item.label }}</span>
                  </label>
                </div>
              </dd>
            </dl>
            <dl>
              <dt>그래프</dt>
              <dd>
                <div class="type_check">
                  <label for="typeGraphRadio1" class="lab_radio">
                    <input type="radio" id="typeGraphRadio1" class="inp_radio" name="typeGraphRadio" v-model="modalChartTargetField" value="pctrk" />
                    <span class="ico_radio"></span>백분위
                  </label>
                  <label for="typeGraphRadio2" class="lab_radio">
                    <input type="radio" id="typeGraphRadio2" class="inp_radio" name="typeGraphRadio" v-model="modalChartTargetField" value="orgScr" />
                    <span class="ico_radio"></span>원점수
                  </label>
                  <label for="typeGraphRadio3" class="lab_radio">
                    <input type="radio" id="typeGraphRadio3" class="inp_radio" name="typeGraphRadio" v-model="modalChartTargetField" value="highAcumlt" />
                    <span class="ico_radio"></span>상위누적
                  </label>
                </div>
              </dd>
            </dl>
            <!-- 백분위 / 원점수 선택시 -->
            <dl v-if="modalChartTargetField !== 'highAcumlt'">
              <dt>과목/선택과목</dt>
              <dd>
                <div class="type_check">
                  <label for="all" class="lab_check">
                    <input type="checkbox" id="all" class="inp_check" name="subjectRadio" @change="modalChartAllChange($event)" v-model="modalChartAll" />
                    <span class="ico_check"></span>
                    <span>전체</span>
                  </label>
                </div>
              </dd>
            </dl>
            <template v-if="modalChartTargetField !== 'highAcumlt'">
              <dl class="sub_data" v-for="(obj, key) in currentSubjectMap" :key="key">
                <dt></dt>
                <dd>
                  <dl>
                    <dt>
                      <div class="type_check">
                        <label :for="key" class="lab_check">
                          <input type="checkbox" :id="key" class="inp_check" name="subjectRadio" :value="key" v-model="modalChartFilter.highSubjt" :disabled="modalChartTargetField === 'pctrk' && (key === 'H' || key === 'E')" />
                          <span class="ico_check"></span>
                          <span>{{ obj.subjtNm }}</span>
                        </label>
                      </div>
                    </dt>
                    <dd>
                      <div class="type_check" v-if="key !== 'T'">
                        <label :for="key + idx" class="lab_radio" v-for="(child, idx) in obj.child" :key="idx">
                          <input type="radio" :id="key + idx" class="inp_radio" :name="key" v-model="modalChartFilter.byyySubjtSeq[key]" :disabled="modalChartTargetField === 'pctrk' && (key === 'H' || key === 'E')" :value="child.byyySubjtSeq" />
                          <span class="ico_radio"></span>{{ child.subjtNm }}
                        </label>
                      </div>
                      <div class="type_check" v-if="key === 'T'">
                        <label :for="key + idx" class="lab_check" v-for="(child, idx) in obj.child" :key="idx">
                          <input type="checkbox" :id="key + idx" class="inp_check" :key="idx" v-model="modalChartFilter.byyySubjtSeq[key]" :value="child.byyySubjtSeq" @click="lengthCheck(key, $event, child.byyySubjtSeq, 'chartFilter')" />
                          <span class="ico_check"></span>
                          <span>{{ child.subjtNm }}</span>
                        </label>
                      </div>
                    </dd>
                  </dl>
                </dd>
              </dl>
            </template>
            <!-- //백분위 / 원점수 선택시 -->
            <!-- 상위누적 선택시 -->
            <dl v-if="modalChartTargetField === 'highAcumlt'">
              <dt>과목/선택과목</dt>
              <dd>
                <div class="type_check">
                  <label for="subjectRadio1" class="lab_check">
                    <input type="checkbox" id="subjectRadio1" class="inp_check" name="subjectRadio" value="ALL" v-model="modalHighAcumltFilterALL" @change="modalHighAcumltFilterALLChange($event)" />
                    <span class="ico_check"></span>
                    <span>전체</span>
                  </label>
                  <label for="subjectRadio2" class="lab_check">
                    <input type="checkbox" id="subjectRadio2" class="inp_check" name="subjectRadio" value="KMT" v-model="modalHighAcumltFilter" />
                    <span class="ico_check"></span>
                    <span>국수탐</span>
                  </label>
                  <label for="subjectRadio3" class="lab_check">
                    <input type="checkbox" id="subjectRadio3" class="inp_check" name="subjectRadio" value="KM,MT" v-model="modalHighAcumltFilter" />
                    <span class="ico_check"></span>
                    <span>국수 or 수탐</span>
                  </label>
                </div>
              </dd>
            </dl>
            <!-- //상위누적 선택시 -->
          </div>
        </div>
        <div slot="foot">
          <button type="button" class="btn_save" @click="applyGraphFilter">적용</button>
          <button type="button" class="btn_cancel" @click="closeModalSetGraphLayer">취소</button>
        </div>
      </Modal>
      <!-- //그래프 설정 레이어 -->
      <!-- 선택과목 설정 레이어 -->
      <Modal v-if="showModalOpeningLayer" modalTitle="선택과목 설정" modalType="layer_setting" @close-modal="showModalOpeningLayer = false">
        <div slot="head">
          <h4>선택과목 설정</h4>
        </div>
        <div slot="body">
          <div class="info_select_subject">
            <div class="select_year">
              <select class="select_cont" v-model="selSubjectYear">
                <option v-for="(key, idx) in subjectYears" :key="idx" :value="key">{{ key }}년</option>
              </select>
            </div>
            <p class="txt_info"><span class="txt_year">2022년</span>에 공부하는 과목을 설정해 주세요.</p>
          </div>
          <div class="data_setting">
            <dl v-for="(obj, idx) in subjectMapToList(currentYearSubjectMap)" :key="idx">
              <dt>{{ obj.subjtNm }}</dt>
              <dd>
                <div class="type_check" v-if="obj.subjectKey !== 'T'">
                  <label :for="'selSubject' + idx + '_' + i" class="lab_radio" v-for="(child, i) in obj.child" :key="i">
                    <input v-model="selSubjectByyySubjtSeq[obj.subjectKey]" type="radio" :id="'selSubject' + idx + '_' + i" class="inp_radio" :name="obj.byyySubjtSeq + obj.subjectKey" :value="child.byyySubjtSeq" />
                    <span class="ico_radio">
                      <span class="mo_only">{{ child.subjtNm }}</span>
                    </span>
                    {{ child.subjtNm }}
                  </label>
                </div>
                <div class="type_check" v-if="obj.subjectKey === 'T'">
                  <label :for="'selSubject' + idx + '_' + i" class="lab_check" v-for="(child, i) in obj.child" :key="i">
                    <input
                      type="checkbox"
                      v-model="selSubjectByyySubjtSeq[obj.subjectKey]"
                      :id="'selSubject' + idx + '_' + i"
                      class="inp_check"
                      :name="obj.byyySubjtSeq + obj.subjectKey"
                      :value="child.byyySubjtSeq"
                      :ref="'selSubject_' + obj.subjectKey + '_' + child.byyySubjtSeq"
                    />
                    <span class="ico_check">
                      <span class="mo_only">{{ child.subjtNm }}</span>
                    </span>
                    <span>{{ child.subjtNm }}</span>
                  </label>
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <div slot="foot">
          <button type="button" class="btn_save" @click="changeSelSubject">저장</button>
        </div>
      </Modal>
      <!-- //선택과목 설정 레이어 -->
      <ExplVurriVod expl-type="corWransr" :expl-curri-vod-url="explCurriVodUrl" v-if="showVodModalLayer" @showExplVod="showVodModalLayer = false" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TabMenu from './common/TabMenu';
import TabPannel from './common/TabPannel';
import TabTableBox from './common/TabTableBox';
import TabGraphBox from './common/TabGraphBox';
import Modal from './common/Modal';
import { USER_AUTHORITY } from '@/const/const';
import { windowOpenPopup } from '@/mixins/windowOpenPopup';
import moment from 'moment';
import ExplVurriVod from '../dailytest/taketest/ExplCurriVod';
import { PAGE_TITLE } from '../../const/const';

export default {
  name: 'regularTest',
  mixins: [windowOpenPopup],
  components: {
    ExplVurriVod,
    TabMenu,
    TabPannel,
    TabTableBox,
    TabGraphBox,
    Modal
  },
  computed: {
    ...mapState({
      unityExamTests: (state) => state.unityexam.wransrnote.unityExamTests,
      unityExamDtlDivCdList: (state) => state.unityexam.wransrnote.unityDtlDivCds,
      subjectYears: (state) => state.unityexam.wransrnote.subjectYears,
      currentSubjectMap: (state) => state.unityexam.wransrnote.currentSubjectMap,
      currentYearSubjectMap: (state) => state.unityexam.wransrnote.subjectMap,
      currentYearSubjtMap: (state) => state.unityexam.wransrnote.currentYearSubjtMap,
      lastYearSubjtMap: (state) => state.unityexam.wransrnote.lastYearSubjtMap,
      univGroup: (state) => state.unityexam.wransrnote.univGroup,
      personalScoreMap: (state) => state.unityexam.wransrnote.personalScoreMap,
      getChartData: (state) => state.unityexam.wransrnote.chartData,
      mySubjectList: (state) => state.unityexam.wransrnote.mySubjectList,
      lastTest: (state) => state.unityexam.wransrnote.lastTest,
      userInfo: (state) => state.common.userInfo,
      isMobile: (state) => state.common.isMobile
    })
  },
  watch: {
    selSubjectYear(year) {
      if (year === '') {
        return;
      }
      this.setSubjectMapByYear(year).then(() => {
        this.getSubjectMapByYear(year).then(() => {
          this.getStdSelSubjectByYear({ year, studentCd: this.userInfo.studentCd }).then(() => {
            if ((this.mySubjectList || []).length === 0 && this.userInfo?.personVo?.userAuthority !== 'PARENT') {
              this.showModalOpeningLayer = true;
            }
          });
        });
      });
    },
    mySubjectList: {
      deep: true,
      handler(list) {
        this.selSubjectByyySubjtSeq = { K: '', M: '', E: '', T: [], H: '', L: '' };
        if ((list || []).length === 0 && this.userInfo?.personVo?.userAuthority !== 'PARENT') {
          this.showModalOpeningLayer = true;
          return;
        }
        for (const obj of list) {
          for (const key in this.currentYearSubjectMap) {
            if (Object.keys(this.currentYearSubjectMap[key]).length !== 0 && this.currentYearSubjectMap[key].byyySubjtSeq === obj.highByyySubjtSeq) {
              if (key === 'T') {
                this.selSubjectByyySubjtSeq[key].push(obj.byyySubjtSeq);
              } else {
                this.selSubjectByyySubjtSeq[key] = obj.byyySubjtSeq;
              }
              break;
            }
          }
        }
      }
    },
    unityExamTests: {
      deep: true,
      handler(tests) {
        this.hasTest = Object.keys(tests).length !== 0;
      }
    },
    unityExamYear(year) {
      this.unityDtlDivCd = '0';
      const unityExamTests = this.unityExamTests || {};
      const unityDtlDivCds = unityExamTests[year] || {};
      const result = {};
      Object.keys(unityDtlDivCds).forEach((key) => {
        if (Object.keys(unityDtlDivCds[key]).length !== 0) {
          result[key] = unityDtlDivCds[key];
        }
      });
      this.unityDtlDivCds = result;
      if (Object.keys(this.lastTest).length !== 0) {
        this.unityDtlDivCd = this.lastTest.unityExamDtlDivCd;
      } else if (Object.keys(this.unityDtlDivCds).length !== 0) {
        this.unityDtlDivCd = Object.keys(this.unityDtlDivCds)[0];
      }
      // this.selSubjectYear = year;
    },
    unityDtlDivCd(unityDtlDivCd) {
      if (unityDtlDivCd === '0') {
        this.unityExamSeq = 0;
        return;
      }
      if (Object.keys(this.lastTest).length !== 0) {
        this.unityExamSeq = this.lastTest.unityExamSeq;
        this.$store.commit('SET_LAST_TEST', {});
      } else if (Object.keys(this.unityDtlDivCds[unityDtlDivCd]).length > 0) {
        this.unityExamSeq = Object.keys(this.unityDtlDivCds[unityDtlDivCd])[0];
      }
    },
    unityExamSeq(unityExamSeq) {
      if (unityExamSeq === 0) {
        return;
      }
      const unityExams = this.unityDtlDivCds[this.unityDtlDivCd][unityExamSeq].data;
      const studentCd = this.userInfo.studentCd;
      if (unityExams.length > 0) {
        this.unityDtlExamTurnOrdSeq = unityExams[0].unityDtlExamTurnOrdSeq;
        this.getSubjectMap(this.unityDtlExamTurnOrdSeq);
        const param = {
          unityDtlExamSeq: unityExams[0].unityDtlExamSeq,
          studentCd
        };
        this.getRegularTestExamScore(param).then(() => {
          const tab = this.$refs.tabMenu.$children;
          if (tab.length > 0) {
            this.getQuestionInfoList(tab[0].$props.byyySubjtSeq);
          }
        });
      }
      const year = this.unityExamYear;
      this.getRegularTestScoreOverYear({ year, studentCd });
    },
    personalScoreMap() {
      const filterOrder = ['K', 'M', 'E', 'T', 'H', 'L'];
      const seqTypeMap = {};
      const result = [];
      filterOrder.forEach((column) => {
        const checkSeqs = [];
        const subject = this.currentSubjectMap[column] || {};
        const highSubjtSeq = subject.byyySubjtSeq || 0;
        if (highSubjtSeq === 0) {
          return;
        }
        checkSeqs.push(highSubjtSeq);
        subject.child.forEach((childSubject) => {
          seqTypeMap[childSubject.byyySubjtSeq] = column;
          checkSeqs.push(childSubject.byyySubjtSeq);
        });
        checkSeqs.forEach((byyySubjtSeq) => {
          if (Object.keys(this.personalScoreMap[byyySubjtSeq] || {}).length !== 0) {
            const score = this.personalScoreMap[byyySubjtSeq];
            score.byyySubjtSeq = byyySubjtSeq;
            result.push(score);
          }
        });
      });
      this.subjtList = result;

      const tamguList = [],
        defaultCheckData = JSON.parse(JSON.stringify(this.personalScoreMap));
      if (Object.keys(defaultCheckData).length === 0) {
        (this.mySubjectList || []).forEach((selSubjt) => {
          defaultCheckData[selSubjt.byyySubjtSeq] = {};
        });
      }

      this.modalChartFilter.highSubjt = [];
      Object.keys(defaultCheckData).forEach((key) => {
        const subjtKey = seqTypeMap[key];
        if (subjtKey === 'T') {
          tamguList.push(Number(key));
          return;
        }
        if (subjtKey === undefined) {
          return;
        }
        if (this.modalChartTargetField === 'pctrk' && (subjtKey === 'E' || subjtKey === 'H')) {
          return;
        }
        this.modalChartFilter.byyySubjtSeq[subjtKey] = Number(key);
        if (this.modalChartFilter.highSubjt.indexOf(subjtKey) === -1) {
          this.modalChartFilter.highSubjt.push(subjtKey);
        }
      });
      if (tamguList.length !== 0) {
        this.modalChartFilter.byyySubjtSeq['T'] = tamguList;
        this.modalChartFilter.highSubjt.push('T');
      }
      this.modalChartAll = Object.keys(this.currentSubjectMap).length === this.modalChartFilter.highSubjt.length;
      this.chartFilter = JSON.parse(JSON.stringify(this.modalChartFilter));
    },
    modalChartTargetField(field) {
      if (field === 'pctrk') {
        ['E', 'H'].forEach((value) => {
          if (this.modalChartFilter.highSubjt.indexOf(value) !== -1) {
            this.modalChartFilter.highSubjt.splice(this.modalChartFilter.highSubjt.indexOf(value), 1);
          }
          this.modalChartFilter.byyySubjtSeq[value] = '';
        });
      }

      if (field === 'pctrk' || field === 'orgScr') {
        this.modalChartAll =
          this.modalChartFilter.highSubjt.length ===
          Object.keys(this.currentSubjectMap).filter((value) => {
            return !(field === 'pctrk' && (value === 'E' || value === 'H'));
          }).length;
      }
    },
    chartTargetField(field) {
      this.chartOptions.scales.yAxes[0].ticks.reverse = field === 'highAcumlt';
      this.changeChartData();
      this.modalChartTargetField = this.chartTargetField;
    },
    chartFilter: {
      deep: true,
      handler() {
        this.changeChartData();
      }
    },
    getChartData: {
      deep: true,
      handler() {
        this.changeChartData();
      }
    },
    modalHighAcumltFilter: {
      deep: true,
      handler(filter) {
        this.modalHighAcumltFilterALL = filter.indexOf('KMT') !== -1 && filter.indexOf('KM,MT') !== -1;
      }
    },
    selSubjectByyySubjtSeq: {
      deep: true,
      handler(seqs) {
        if (seqs['T'].length > 2) {
          const byyySeq = seqs['T'].pop();
          console.log(byyySeq);
          const input = this.$refs['selSubject_T_' + byyySeq];
          console.log(input);
          if (input.length > 0) {
            alert('탐구는 2개까지 선택 가능합니다.');
            input[0].checked = false;
          }
        }
      }
    },
    modalChartFilter: {
      deep: true,
      handler(filter) {
        this.modalChartAll =
          filter.highSubjt.length ===
          Object.keys(this.currentSubjectMap).filter((value) => {
            return !(this.modalChartTargetField === 'pctrk' && (value === 'E' || value === 'H'));
          }).length;
      }
    }
  },
  created() {
    this.setHeaderInfo({ menuName: '정규모의고사', isPrev: false });
    if (!this.userInfo.studentCd) {
      this.getUser().then(() => {
        this.init();
      });
    } else {
      this.init();
    }
    this.changeTitle();
  },
  data() {
    return {
      USER_AUTHORITY,
      popup: undefined,
      hasTest: false,
      showModalSetGraphLayer: false,
      showModalOpeningLayer: this.$route.query.showModal || false,
      showVodModalLayer: false,
      explCurriVodUrl: '',
      selSubjectYear: '',
      selSubjectByyySubjtSeq: { K: '', M: '', E: '', T: [], H: '', L: '' },
      unityExamYear: '',
      unityDtlDivCd: '',
      unityExamSeq: 0,
      unityDtlExamTurnOrdSeq: 0,
      unityDtlDivCds: {},
      subjtList: [],
      chartTargetField: 'pctrk',
      modalChartTargetField: 'pctrk',
      chartFilter: {
        unityExamDtlDivCds: ['1', '2', '3'],
        highSubjt: [],
        byyySubjtSeq: { K: '', M: '', E: '', T: [], H: '', L: '' },
        highAcumlt: ['KM', 'MT', 'KMT']
      },
      modalChartFilter: {
        unityExamDtlDivCds: ['1', '2', '3'],
        highSubjt: [],
        byyySubjtSeq: { K: '', M: '', E: '', T: [], H: '', L: '' },
        highAcumlt: ['KM', 'MT', 'KMT']
      },
      chartAll: false,
      modalChartAll: false,
      modalHighAcumltFilterALL: true,
      modalHighAcumltFilter: ['KMT', 'KM,MT'],
      chartData: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'right',
          align: 'start',
          labels: {
            usePointStyle: true,
            boxWidth: 2,
            padding: 20,
            fontColor: '#222',
            filter: (label) => {
              return label.text !== '';
            }
          }
        },
        layout: {
          padding: {
            top: 50,
            bottom: 20,
            right: 40,
            left: 40
          }
        },
        scales: {
          xAxes: [
            {
              show: true,
              offset: true,
              min: -1,
              ticks: {
                maxRotation: 0,
                autoSkip: false,
                callback(label, index, labels) {
                  if (/\s/.test(label)) {
                    return label.split(' ');
                  } else {
                    return label;
                  }
                },
                fontColor: '#222'
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                reverse: false,
                min: 0,
                max: 100,
                stepSize: 20,
                beginAtZero: true,
                fontColor: '#222'
              }
            }
          ]
        },
        annotation: {
          annotations: [
            {
              type: 'box',
              drawTime: 'beforeDatasetsDraw',
              borderColor: '',
              id: 'a-box-1',
              yMin: 0,
              yMax: 100,
              xMin: 3,
              xMax: 4,
              xScaleID: 'x-axis-0',
              yScaleID: 'y-axis-0',
              backgroundColor: 'rgba(244, 244, 244, 0.6)',
              borderWidth: 0
            }
          ]
        },
        plugins: {
          datalabels: {
            align: 'right',
            offset: 10,
            color: '#222',
            formatter(value, context) {
              let result = value;
              if (context.dataset._meta.isSat) {
                result = context.dataset._meta.subjtNm + ' ' + result;
              }
              return result;
            }
          }
        }
      }
    };
  },
  methods: {
    ...mapActions([
      'getUser',
      'getSubjectYears',
      'getSubjectMap',
      'getRegularTestList',
      'getRegularTestExamScore',
      'getQuestionInfo',
      'updateChangeCoransNoteYn',
      'getRegularTestScoreOverYear',
      'getSubjectMapByYear',
      'setSubjectMapByYear',
      'getStdSelSubjectByYear',
      'updateStdSelSubjectByYear',
      'setHeaderInfo'
    ]),
    changeTitle() {
      let title = '';
      if (this.userInfo.branchCd !== '') title = PAGE_TITLE[this.userInfo.branchCd];
      document.title = 'mypage_성적관리 - 정규모의고사 | ' + title;
    },
    init() {
      this.getSubjectYears();
      const studentCd = this.userInfo.studentCd;
      const branchCd = this.userInfo.branchCd;
      const param = {
        studentCd,
        branchCd
      };
      this.getRegularTestList(param).then(() => {
        const cdList = this.unityExamDtlDivCdList.map((value) => {
          return value.data;
        });
        this.modalChartFilter.unityExamDtlDivCds = cdList;
        this.chartFilter.unityExamDtlDivCds = cdList;
        if (Object.keys(this.lastTest).length === 0) {
          const arr = Object.keys(this.unityExamTests)
            .sort()
            .reverse();
          if (arr.length > 0) {
            this.unityExamYear = arr[0];
          }
        } else {
          this.unityExamYear = this.lastTest.unityExamYy;
        }

        const currentYear = moment().format('YYYY');
        if (this.subjectYears.indexOf(currentYear) !== -1) {
          this.selSubjectYear = currentYear;
        }
      });
    },
    getUnityDtlDivNm(unityDtlDivCd) {
      let unityDtlDivNm = '';
      this.unityExamDtlDivCdList.forEach((value) => {
        if (unityDtlDivNm === '' && value.data === unityDtlDivCd) {
          unityDtlDivNm = value.label;
        }
      });
      return unityDtlDivNm;
    },
    getQuestionInfoList(byyySubjtSeq) {
      const studentCd = this.userInfo.studentCd;
      const turnOrdSeq = this.unityDtlExamTurnOrdSeq;
      const param = {
        turnOrdSeq,
        byyySubjtSeq,
        studentCd
      };
      this.getQuestionInfo(param);
    },
    changeCoransrNoteYn(bystdntUnityExamQustnInpStusSeq) {
      const studentCd = this.userInfo.studentCd;
      const param = {
        studentCd,
        bystdntUnityExamQustnInpStusSeq
      };
      this.updateChangeCoransNoteYn(param);
    },
    openPopup(bystdntUnityExamQustnInpStusSeq) {
      const route = this.$router.resolve({
        name: 'unityExamViewQustnByExamPopup',
        query: {
          turnOrdSeq: this.unityDtlExamTurnOrdSeq,
          displaySeq: bystdntUnityExamQustnInpStusSeq
        }
      });
      this.openWindowPopup(route.href, this.$route.name, 'unityExamViewQustnByExamPopup', this.locationReload, 'width=990, height=800, scrollbars=1', this.isMobile);
    },
    closePopup() {
      this.popup.close();
      window.opener.child.close();
    },
    locationReload() {
      location.reload();
    },
    changeChartData() {
      let checkKey = [],
        label = {},
        color = {};
      const result = {};
      Object.keys(this.currentSubjectMap).forEach((key) => {
        const value = this.currentSubjectMap[key];
        value.child.forEach((child) => {
          checkKey.push(child.byyySubjtSeq);
          label[child.byyySubjtSeq] = child.subjtNm;
          color[child.byyySubjtSeq] = value.subjtColrCd || this.getRandomColor();
        });
      });
      const field = this.chartTargetField;
      if (field === 'highAcumlt') {
        checkKey = [];
        label = {};
        label['KM'] = '국수';
        label['MT'] = '수탐';
        label['KMT'] = '국수탐';
        color = {};
        color['KM'] = '#f57aa7';
        color['MT'] = '#f57aa7';
        color['KMT'] = '#f5a77a';
        checkKey.push('KM');
        checkKey.push('MT');
        checkKey.push('KMT');
      }

      const filteredData = this.getChartData.filter((item) => {
        return this.chartFilter.unityExamDtlDivCds.indexOf(item.unityExamDtlDivCd) !== -1;
      });
      const lastSatByyySubjtSeqs = [];
      for (const data of filteredData) {
        if (data.isLastSat) {
          Object.keys(data.scoreMap).forEach((key) => {
            lastSatByyySubjtSeqs.push(key);
          });
          break;
        }
      }
      checkKey.forEach((byyySubjtSeq) => {
        result[byyySubjtSeq] = [];
      });
      lastSatByyySubjtSeqs.forEach((byyySubjtSeq) => {
        result[byyySubjtSeq] = [];
      });

      for (const item of filteredData) {
        lastSatByyySubjtSeqs.forEach((byyySubjtSeq) => {
          const scoreMapElement = item.isLastSat ? item.scoreMap[byyySubjtSeq] : {};
          const scr = scoreMapElement[field] || null;
          result[byyySubjtSeq].push(scr);
        });
        checkKey.forEach((byyySubjtSeq) => {
          const scoreMapElement = item.scoreMap[byyySubjtSeq];
          if (scoreMapElement === undefined || scoreMapElement[field] === undefined) {
            result[byyySubjtSeq].push(null);
          } else {
            result[byyySubjtSeq].push(scoreMapElement[field]);
          }
        });
      }
      const resultData = [];
      Object.keys(result).forEach((key) => {
        const value = result[key];
        let isEmpty = true;
        value.forEach((el) => {
          if (el !== null) {
            isEmpty = false;
          }
        });
        if (isEmpty || !this.filterByChartFilter(key)) {
          return;
        }
        let colorElement = color[key],
          isSat = false,
          subjtNm = '';
        let labelElement = label[key];
        if (Number(key) < 0) {
          labelElement = '';
          subjtNm = this.lastYearSubjtMap[-key].subjtNm;
          colorElement = '#555';
          isSat = true;
        }
        const param = {
          label: labelElement,
          borderColor: colorElement,
          pointBorderWidth: 6,
          pointBackgroundColor: colorElement,
          data: value,
          fill: false,
          tension: 0,
          _meta: { isSat, subjtNm }
        };
        resultData.push(param);
      });

      this.chartData = {
        labels: this.chartXRowSet(),
        datasets: resultData
      };
    },
    filterByChartFilter(key) {
      if (this.chartTargetField === 'highAcumlt') {
        return this.chartFilter.highAcumlt.indexOf(key) !== -1;
      }

      for (const byyySubjtSeqKey in this.chartFilter.byyySubjtSeq) {
        if (byyySubjtSeqKey === 'T') {
          const byyySubjts = this.chartFilter.byyySubjtSeq[byyySubjtSeqKey];
          if (byyySubjts.indexOf(Number(key)) !== -1 || (key < 0 && byyySubjts.length > 0 && this.currentYearSubjtMap[byyySubjts[0]].highSubjtCd === this.lastYearSubjtMap[-Number(key)].highSubjtCd)) {
            return true;
          }
        } else {
          const chartFilterByyySubjtSeq = Number(this.chartFilter.byyySubjtSeq[byyySubjtSeqKey]);
          if (chartFilterByyySubjtSeq === Number(key) || (key < 0 && (this.currentYearSubjtMap[chartFilterByyySubjtSeq] || {}).highSubjtCd === this.lastYearSubjtMap[-Number(key)].highSubjtCd)) {
            return true;
          }
        }
      }
      return false;
    },
    chartXRowSet() {
      const result = [];
      this.getChartData.forEach((item) => {
        if (this.chartFilter.unityExamDtlDivCds.indexOf(item.unityExamDtlDivCd) === -1) {
          return;
        }
        result.push(item.unityExamNm);
      });

      const examName = this.unityDtlDivCds[this.unityDtlDivCd][this.unityExamSeq].name;
      const index = result.indexOf(examName);
      this.chartOptions.annotation.annotations[0].xMin = index - 0.5;
      this.chartOptions.annotation.annotations[0].xMax = index + 0.5;
      return result;
    },
    getRandomColor() {
      let color = Math.floor(Math.random() * 16777215).toString(16);
      while (color.length < 6) {
        color = '0' + color;
      }
      return '#' + color;
    },
    setChartFieldName(field) {
      this.chartTargetField = field;
    },
    applyGraphFilter() {
      if (this.modalChartTargetField === 'highAcumlt') {
        if (!this.modalHighAcumltFilterALL && this.modalHighAcumltFilter.length === 0) {
          alert('과목/선택과목을 선택해주세요.');
          return;
        }
        this.modalChartFilter.highAcumlt = [];
        this.modalHighAcumltFilter.forEach((value) => {
          value.split(',').forEach((type) => {
            this.modalChartFilter.highAcumlt.push(type);
          });
        });
      } else {
        if (this.modalChartFilter.highSubjt.length === 0) {
          alert('과목을 선택해 주세요.');
          return;
        }
        for (const key of this.modalChartFilter.highSubjt) {
          if (this.modalChartFilter.byyySubjtSeq[key].length === 0) {
            alert('선택과목을 선택해 주세요.');
            return;
          }
        }

        Object.keys(this.modalChartFilter.byyySubjtSeq).forEach((subjtKey) => {
          if (this.modalChartFilter.highSubjt.indexOf(subjtKey) === -1) {
            this.modalChartFilter.byyySubjtSeq[subjtKey] = subjtKey === 'T' ? [] : '';
          }
        });
      }

      this.chartFilter = JSON.parse(JSON.stringify(this.modalChartFilter));
      this.chartTargetField = this.modalChartTargetField;
      this.showModalSetGraphLayer = false;
    },
    closeModalSetGraphLayer() {
      this.showModalSetGraphLayer = false;
      this.modalChartFilter = JSON.parse(JSON.stringify(this.chartFilter));
      this.modalChartTargetField = this.chartTargetField;
    },
    lengthCheck(key, event, byyySubjtSeq, target) {
      if (key !== 'T') {
        return;
      }
      if (target === 'chartFilter' && this.modalChartFilter.byyySubjtSeq[key].length >= 2) {
        if (this.modalChartFilter.byyySubjtSeq[key].indexOf(byyySubjtSeq) === -1) {
          alert('탐구는 선택과목 2개까지 선택 가능합니다.');
          event.target.checked = false;
        }
      }
    },
    showExplVod(url) {
      this.explCurriVodUrl = url;
      this.showVodModalLayer = true;
    },
    subjectMapToList(map) {
      const filterOrder = ['K', 'M', 'E', 'T', 'H', 'L'];
      const result = [];
      filterOrder.forEach((key) => {
        const obj = map[key] || {};
        if (Object.keys(obj).length !== 0) {
          obj.subjectKey = key;
          result.push(obj);
        }
      });
      return result;
    },
    changeSelSubject() {
      const checkOrder = ['K', 'M', 'E', 'T', 'H', 'L'];
      const saveByyySubjtSeqs = [];
      for (const key of checkOrder) {
        if (Object.keys(this.currentYearSubjectMap[key] || {}).length === 0) {
          continue;
        }
        const subjtNm = this.currentYearSubjectMap[key].subjtNm;
        if (key === 'T') {
          const arr = this.selSubjectByyySubjtSeq[key] || [];
          if (arr.length === 0) {
            alert(`${subjtNm} 선택과목을 선택해 주세요`);
            return;
          } else if (arr.length === 1) {
            alert(`${subjtNm} 선택과목을 하나 더 선택해 주세요.`);
            return;
          }
          arr.forEach((seq) => {
            saveByyySubjtSeqs.push(seq);
          });
        } else {
          if (this.selSubjectByyySubjtSeq[key] === '') {
            alert(`${subjtNm} 선택과목을 선택해 주세요`);
            return;
          }
          saveByyySubjtSeqs.push(this.selSubjectByyySubjtSeq[key]);
        }
      }
      const param = {
        studentCd: this.userInfo.studentCd,
        year: this.selSubjectYear,
        seqs: saveByyySubjtSeqs
      };
      this.updateStdSelSubjectByYear(param);
      this.showModalOpeningLayer = false;
    },
    modalChartAllChange(e) {
      if (e.target.checked) {
        this.modalChartFilter.highSubjt = Object.keys(this.currentSubjectMap).filter((value) => {
          return !(this.modalChartTargetField === 'pctrk' && (value === 'E' || value === 'H'));
        });
      } else {
        this.modalChartFilter.highSubjt = [];
      }
    },
    modalHighAcumltFilterALLChange(e) {
      if (e.target.checked) {
        this.modalHighAcumltFilter = ['KMT', 'KM,MT'];
      } else {
        this.modalHighAcumltFilter = [];
      }
    }
  }
};
</script>
