import { httpClient } from '../httpclient/HttpClientFactory';

export default {
  getSubjectYears: () => httpClient.get('/api/byyy-subject/v1/year').then((res) => res.data),
  getSubjectMap: (turnOrdSeq) =>
    httpClient.get(`/api/byyy-subject/v1/by-turn-ord-seq/${turnOrdSeq}`).then((res) => res.data),
  getSubjectMapByYear: (year) => httpClient.get(`/api/byyy-subject/v1/byyy/${year}`).then((res) => res.data),
  getStdSelSubjectByYear: (year, studentCd) =>
    httpClient.get(`/api/byyy-subject/v1/select-subject/${year}/${studentCd}`).then((res) => res.data),
  updateStdSelSubjectByYear: (year, studentCd, seqs) => {
    httpClient.post(`/api/byyy-subject/v1/select-subject/${year}/${studentCd}`, seqs).then((res) => res.data);
  },
  getAllTestList: (studentCd, branchCd) =>
    httpClient
      .get(`/api/unity-exam/v1/exam/by-student?studentCds=${studentCd}&selectBranchCd=${branchCd}`)
      .then((res) => res.data),
  getRegularTestScoreOverYear: (year, studentCd) =>
    httpClient
      .get(`/api/unity-exam/v1/regular/score?studentCd=${studentCd}&year=${year}&unityExamDivCds=1,2,3`)
      .then((res) => res.data),
  getRegularTestList: (studentCd, branchCd) =>
    httpClient
      .get(`/api/unity-exam/v1/exam/by-student?unityExamDivCds=1&studentCds=${studentCd}&selectBranchCd=${branchCd}`)
      .then((res) => res.data),
  getRegularTestExamScore: (unityDtlExamSeq, studentCd) =>
    httpClient
      .get(`/api/unity-exam/v1/regular/score-by-student?unityDtlExamSeq=${unityDtlExamSeq}&studentCds=${studentCd}`)
      .then((res) => res.data),
  getAcaContentsScoreOverDtlExamSeq: (unityExamDivCd, dtlExamSeq, studentCd) =>
    httpClient
      .get(
        `/api/unity-exam/v1/aca-contents/score?studentCd=${studentCd}&unityDtlExamSeq=${dtlExamSeq}&unityExamDivCds=${unityExamDivCd}`
      )
      .then((res) => res.data),
  getAcaContentsList: (studentCd, branchCd) =>
    httpClient
      .get(`/api/unity-exam/v1/aca-contents/by-student?studentCds=${studentCd}&selectBranchCd=${branchCd}`)
      .then((res) => res.data),
  getAcaContentsScore: (unityDtlExamSeq, unityTurnOrdSeq, studentCd) =>
    httpClient
      .get(
        `/api/unity-exam/v1/aca-contents/score-by-student?unityDtlExamSeq=${unityDtlExamSeq}&unityDtlExamTurnOrdSeq=${unityTurnOrdSeq}&studentCds=${studentCd}`
      )
      .then((res) => res.data),
  getScoreByStudent: (year, studentCd) =>
    httpClient
      .get(`/api/analysis-subject/v1/analysis/subject?studentCd=${studentCd}&unityExamYy=${year}`)
      .then((res) => res.data),
  getQuestionInfo: (turnOrdSeq, byyySubjtSeq, studentCd) => {
    let url = '';
    if (studentCd) {
      url = `/api/unity-exam/v1/qadata/${turnOrdSeq}/${byyySubjtSeq}/${studentCd}`;
    } else {
      url = `/api/unity-exam/v1/exam/question/${turnOrdSeq}/${byyySubjtSeq}`;
    }
    return httpClient.get(url).then((res) => res.data);
  },
  getQuestionInfoByExamQustnSeqs: (studentCd, unityExamQustnSeqs) =>
    httpClient
      .get(
        `/api/analysis-subject/v1/analysis/question-detail-part?studentCd=${studentCd}&unityExamQustnSeqs=${unityExamQustnSeqs}`
      )
      .then((res) => res.data),
  getQuestionByStudentExam: (studentCd, turnOrdSeq) =>
    httpClient
      .get(`/api/unity-exam/v1/answers/by-student-exam?studentCd=${studentCd}&turnOrdSeq=${turnOrdSeq}`)
      .then((res) => res.data),
  getAnswerByQuestionType: (studentCd, subjtSeqs) =>
    httpClient
      .get(`/api/analysis-subject/v1/question-type/answer?studentCd=${studentCd}&byyySubjtSeqs=${subjtSeqs}`)
      .then((res) => res.data),
  getAnalysisOfAnswerCause: (studentCd, subjtSeqs) =>
    httpClient
      .get(`/api/analysis-subject/v1/analysis/cause?studentCd=${studentCd}&byyySubjtSeqs=${subjtSeqs}`)
      .then((res) => res.data),
  saveTestAnswer: (request) => httpClient.post('/api/unity-exam/v1/test-question', request).then((res) => res.data),
  updateChangeCoransNoteYn: (request) =>
    httpClient.post('/api/unity-exam/v1/question/wransrnote-yn', request).then((res) => res.data),
  saveSubjtPrint: (request) => httpClient.post('/api/unity-exam/v1/subjt-print', request).then((res) => res.data),
  getSubjtQuestionAnswerPrint: (request) => httpClient.get(`/api/unity-exam/v1/subjt-question-answer`, { params: request }).then((res) => res.data),
  getWransrnoteYearInfo: () => httpClient.get(`/api/wransrnote/year`).then((res) => res.data),
  getWransrnoteExamTabInfo: (request) => httpClient.get(`/api/wransrnote/examtab`, { params: request }).then((res) => res.data),
  getWransrnoteMobileExamInfo: (request) => httpClient.get(`/api/wransrnote/mobile-exam`, { params: request }).then((res) => res.data),
  getWransrnoteMobileDtlExamInfo: (request) => httpClient.get(`/api/wransrnote/mobile-dtlexam`, { params: request }).then((res) => res.data),
  getWransrnoteExamInfo: (request) => httpClient.get(`/api/wransrnote/examlist`, { params: request }).then((res) => res.data),
  getWransrNoteQustnInfo: (request) => httpClient.get(`/api/wransrnote/qustnlist`, { params: request }).then((res) => res.data),
  getEnterAnswerYearInfo: () => httpClient.get(`/api/enteranswer/year`).then((res) => res.data),
  getEnterAnswerExamTabInfo: (request) => httpClient.get(`/api/enteranswer/examtab`, { params: request }).then((res) => res.data),
  getEnterAnswerExamInfo: (request) => httpClient.get(`/api/enteranswer/examlist`, { params: request }).then((res) => res.data),
  getEnterAnswerDtlExamInfo: (request) => httpClient.get(`/api/enteranswer/dtlexamlist`, { params: request }).then((res) => res.data),
  getEnterAnswerPossibleExamInfo: (request) => httpClient.get(`/api/enteranswer/possibleexamlist`, { params: request }).then((res) => res.data),
  getEnterAnswerSelectExamInfo: (request) => httpClient.get(`/api/enteranswer/selectexamlist`, { params: request }).then((res) => res.data),
  getEnterAnswerExamQustnInfo: (request) => httpClient.get(`/api/enteranswer/qustnlist`, { params: request }).then((res) => res.data),
  getEnterAnswerExamQustnTamInfo: (request) => httpClient.get(`/api/enteranswer/qustnlist`, { params: request }).then((res) => res.data),
  postInpStdntQustn: (request) => httpClient.post(`/api/enteranswer/inpstdntqustn`, request).then((res) => res.data),
  postInitStdntQustn: (request) => httpClient.post(`/api/enteranswer/initstdntqustn`, request).then((res) => res.data),
  getCauseInfo: (codeBtn, causeDiv) => httpClient.get(`/api/enteranswer/causelist?codeBtn=${codeBtn}&causeDiv=${causeDiv}`).then((res) => res.data),
  postChangeRegiWransrNote: (request) => httpClient.post(`/api/wransrnote/update/register`, request).then((res) => res.data),
  postSaveCause: (request) => httpClient.post(`/api/wransrnote/update/cause`, request).then((res) => res.data)
};
