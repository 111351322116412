<template>
  <div class="ly_pop lecture" v-if="!isMobile">
    <div class="inner">
      <h5>{{ title }}</h5>
      <a class="close" @click="$emit('closePopup', '.ly_pop.lecture')">close</a>
      <div class="tbl_data flex">
        <h4>전체 강의</h4>
        <div class="scroll">
          <table>
            <colgroup>
              <col style="width:20%" span="5" />
            </colgroup>
            <thead>
              <tr>
                <th>회차</th>
                <th>강의 날짜</th>
                <th>복습시간</th>
                <th>최종 학습일</th>
                <th>강의 보기</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="turnOrdLecture in turnOrdLectures" :key="turnOrdLecture.curriVodNo" :class="{ disable: turnOrdLecture.viewYn === 'N' }">
                <td :class="{ tooltip: turnOrdLecture.userNotiCont }">
                  {{ `${turnOrdLecture.turnOrd}회차` }}
                  <div class="tool_group" v-if="turnOrdLecture.userNotiCont && turnOrdLecture.userNotiCont.trim().length > 0">
                    <i class="ico_notice"></i>
                    <div class="noti_pop" v-html="turnOrdLecture.userNotiCont"></div>
                  </div>
                </td>
                <td>{{ turnOrdLecture.curriDt }}</td>
                <td>{{ turnOrdLecture.playTmmm ? turnOrdLecture.playTmmm : '-' }}</td>
                <td>{{ turnOrdLecture.lastStdDt ? turnOrdLecture.lastStdDt : '-' }}</td>
                <td><a href="javascript:void(0);" @click="onViewClick(turnOrdLecture.mediaContsKey, turnOrdLecture.curriVodNo, turnOrdLecture.viewYn)" class="vod_btn"></a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div id="vodListLayer" class="layer_vodlist" v-else>
    <div class="layer_body">
      <div class="layer_head">
        <a href="javascript:;" class="layer_close" @click="$emit('closePopup', '#vodListLayer')"><span class="blind">레이어 닫기</span></a>
      </div>
      <div class="layer_cont">
        <h4 class="tit_lecture">{{ title }}</h4>
        <div class="wrap_table">
          <table class="tbl_vod">
            <colgroup>
              <col />
              <col style="width:24%" />
              <col style="width:18%" />
              <col style="width:24%" />
              <col style="width:13%" />
            </colgroup>
            <thead>
              <tr>
                <th>회차</th>
                <th>강의 날짜</th>
                <th>복습시간</th>
                <th>최종 학습일</th>
                <th>보기</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="turnOrdLecture in turnOrdLectures" :key="turnOrdLecture.curriVodNo" :class="{ disable: turnOrdLecture.viewYn === 'N' }">
                <td>
                  {{ `${turnOrdLecture.turnOrd}회차` }}
                  <!-- [D] 시작 : tool_group  -->
                  <div
                    v-if="turnOrdLecture.userNotiCont && turnOrdLecture.userNotiCont.trim().length > 0"
                    class="tool_group"
                    :id="`tooltip_${turnOrdLecture.curriVodNo}`"
                    @click="$emit('tooltipEvent', `#tooltip_${turnOrdLecture.curriVodNo}`);"
                  >
                    <i class="ico_pass ico_notice"></i>
                    <div class="noti_pop" v-html="turnOrdLecture.userNotiCont"></div>
                  </div>
                  <!-- [D] 끝 : tooltip -->
                </td>
                <td>{{ turnOrdLecture.curriDt }}</td>
                <td>{{ turnOrdLecture.playTmmm ? turnOrdLecture.playTmmm : '-' }}</td>
                <td>{{ turnOrdLecture.lastStdDt ? turnOrdLecture.lastStdDt : '-' }}</td>
                <td>
                  <a href="javascript:void(0);" @click="onViewClick(turnOrdLecture.mediaContsKey, turnOrdLecture.curriVodNo, turnOrdLecture.viewYn)" class="btn_vod">
                    <i class="ico_pass ico_vod"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import InfinitePassApi from '@/api/InfinitePassApi';

export default {
  name: 'lecture-popup',
  data() {
    return {
      title: ''
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      turnOrdLectures: (state) => state.infinitePass.turnOrdLectures || []
    })
  },
  methods: {
    onViewClick(mediaContsKey, curriVodNo, viewYn) {
      InfinitePassApi.playCurri(mediaContsKey, curriVodNo, viewYn);
    }
  }
};
</script>
