import MutationTypes from '@/store/MutationTypesClass';

export default {
  [MutationTypes.SET_LECTURE_SEARCH_LIST_JAE]: (state, payload) => {
    state.lecture.lectureSearchDataJae = payload;
  },
  [MutationTypes.SET_LECTURE_SEARCH_LIST_DAN]: (state, payload) => {
    state.lecture.lectureSearchDataDan = payload;
  },
  [MutationTypes.SET_TEACHER_LIST]: (state, payload) => {
    state.lecture.teacherData = payload;
  },
  [MutationTypes.SET_LECTURE_CHECK_LIST]: (state, payload) => {
    state.lecture.lectureCheckList = payload;
  },
  [MutationTypes.SET_RESULT_DATA]: (state, payload) => {
    state.lecture.resultData = payload;
  },
  [MutationTypes.SET_LECTURE_REGISTER_DATA]: (state, payload) => {
    state.lecture.lectureRegisterData = payload;
  },
  [MutationTypes.SET_LECTURE_CANCEL_DATA]: (state, payload) => {
    state.lecture.lectureCancelData = payload;
  },
  [MutationTypes.SET_IMAGE_DATA]: (state, payload) => {
    state.lecture.imageData = payload;
  },
  [MutationTypes.SET_LECTURE_TYPE_MAP]: (state, payload) => {
    payload.forEach((item) => {
      state.lecture.lectureTypeMap[item.data] = item.label;
    });
  }
};
