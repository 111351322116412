<template>
  <div class="popup_cont" style="width: 931px">
    <div class="popup_head">
      <strong class="tit_popup">시간표 출력</strong>
      <div class="select_season">
        <select v-model="currentSeason" class="selbox_comm">
          <option v-for="(item, idx) in seasonDataList" :key="idx" :value="item.seasonYear + ',' + item.seasonCd">
            {{ item.seasonNm }}
          </option>
        </select>
      </div>
      <div class="print_guide">
        <div style="margin-top: 12px;">
          <a href="javascript:;" @click="guideDisplay = true">인쇄설정</a>
          <div :style="guideDisplay ? 'display: block' : 'display: none;'">
            <a href="javascript:;" style="margin-top: 12px;" @click="guideDisplay = false" class="pop_cle">
              <img :src="AWS_S3_RESOURCE_URL + '/lms/pc/v2/images/lay_cle.gif'" alt="팝업 닫기" />
            </a>
            <img :src="AWS_S3_RESOURCE_URL + '/lms/pc/v2/images/print_guide.jpg'" class="vat" alt="" />
          </div>
        </div>
      </div>
      <div class="expander">
        <button type="button" class="btn_print" @click="print()"><span class="blind">인쇄하기</span></button>
      </div>
    </div>
    <div id="popup_target">
      <div class="popup_body print_target" style="width: 931px; margin: auto">
        <div class="box_schedule">
          <div class="info_user">
            <br />
            <strong class="txt_name">
              {{ userInfo.userName }}<span v-if="seatName">({{ seatName }})</span>
            </strong>
            <p class="txt_info">{{ this.studentGroup.groupName + '-' + 'Ⓣ' + this.studentGroup.teacherName }}</p>
          </div>
          <schedule-list v-if="scheduleVisible" :scheduleParam="scheduleParam" :popupCall="popupCall" :subjtCd="subjtCd" @checkPopup="courseCheckPopup" @scheduleRefresh="scheduleRefresh" />
          <div class="appendix">
            <p class="info_date">{{ todayFormat() }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import Vue from 'vue';
import DateUtil from '@/utils/DateUtil';
import scheduleList from '@/components/classroom/schedule/ScheduleList';
import VueHtmlToPaper from 'vue-html-to-paper';
import { AWS_S3_RESOURCE_URL } from '@build/build.config';
import { classRoomPopup } from '@/mixins/classRoomPopup';
import { PAGE_TITLE } from '../../../const/const';

export default {
  name: 'schedulePrintPopup',
  components: { scheduleList },
  mixins: [classRoomPopup],
  data() {
    return {
      branchCd: '',
      seasonData: {},
      popupCall: true,
      seasonCd: 0,
      scheduleParam: {},
      seasonYear: '',
      subjtCd: 'all',
      seasonDataList: [],
      seatName: '',
      scheduleVisible: false,
      currentSeason: '',
      guideDisplay: false,
      AWS_S3_RESOURCE_URL
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      userInfo: (state) => state.common.userInfo,
      seasonList: (state) => state.classroom.schedule.seasonList,
      studentGroup: (state) => state.classroom.schedule.studentGroup
    })
  },
  created() {
    this.branchCd = this.$route.query.branchCd;
    this.studentCd = this.$route.query.studentCd;
    this.seatName = this.$route.query.seatName;
    this.seasonCd = this.$route.query.seasonCd;
    this.seasonYear = this.$route.query.seasonYear;
    if (!this.userInfo.studentCd) {
      this.getUser().then(() => {
        this.getGroup();
      });
    } else {
      this.getGroup();
    }
    this.changeTitle();
  },
  watch: {
    currentSeason(e) {
      const year = e.split(',')[0],
        cd = e.split(',')[1];
      this.seasonCd = cd;
      this.seasonYear = year;
      this.getScheduleList();
    }
  },
  props: [],
  methods: {
    ...mapActions(['getSeason', 'getUser', 'getStudentGroup']),
    changeTitle() {
      let title = '';
      if (this.userInfo.branchCd !== '') title = PAGE_TITLE[this.userInfo.branchCd];
      document.title = 'mypage_시간표출력 | ' + title;
    },
    getSeasonList() {
      const params = {};
      params.branchCd = this.branchCd;
      params.seasonYear = this.studentGroup.groupYear;
      params.studentCd = this.studentCd;
      this.getSeason(params).finally(() => {
        const list = [];
        this.seasonList.forEach((e) => {
          if (DateUtil.dateFormatter('{yyyy}{mm}{dd}', new Date()) >= e.studyRegStartDt) {
            list.push(e);
          }
        });
        this.seasonDataList = list;
        this.currentSeason = this.seasonYear + ',' + this.seasonCd;
      });
    },
    getGroup() {
      const params = {};
      params.studentCd = this.studentCd;
      params.branchCd = this.branchCd;
      this.getStudentGroup(params).finally(() => {
        this.getSeasonList();
      });
    },
    getScheduleList() {
      const params = {};
      params.seasonCd = this.seasonCd;
      params.branchCd = this.branchCd;
      params.studentCd = this.studentCd;
      params.seasonYear = this.seasonYear;
      this.scheduleParam = params;
      if (this.scheduleVisible) {
        this.scheduleRefresh();
      } else {
        this.scheduleVisible = true;
      }
    },
    todayFormat() {
      return DateUtil.dateFormatter('{yyyy}.{mm}.{dd} {hh}:{mi}', new Date());
    },
    print() {
      Vue.use(VueHtmlToPaper, {
        name: '_blank',
        specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
        styles: [
          'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
          AWS_S3_RESOURCE_URL + '/my/pc/v2/css/mypage_comm.css?ver=2020120818',
          AWS_S3_RESOURCE_URL + '/my/pc/v2/css/common.css?ver=202011030',
          AWS_S3_RESOURCE_URL + '/my/pc/v2/css/swiper-bundle.min.css?ver=202010200903'
        ]
      });
      this.$htmlToPaper('popup_target');
    },
    courseCheckPopup(idx) {
      const route = this.$router.resolve({
        name: 'lectureCheckP',
        query: {
          branchCd: this.branchCd,
          seasonCd: this.seasonCd,
          courseCd: '1',
          studentCd: this.studentCd,
          grpIdx: idx.join(',')
        }
      });
      const size = {};
      size.width = 1200;
      size.height = 500;
      size.popupX = window.screen.width / 2 - size.width / 2;
      size.popupY = window.screen.height / 2 - size.height / 2;
      this.openClassRoomPopup(route.href, 'scheduleMain', 'lectureCheckP', this.reload, size);
    },
    reload() {
      location.reload();
    },
    scheduleRefresh() {
      this.scheduleVisible = false;
      this.$nextTick(() => {
        this.scheduleVisible = true;
      });
    }
  }
};
</script>
