<template>
  <div id="header">
    <div id="headerTop">
      <div class="inner">
        <h1 class="logo new">
          <a v-if="userInfo.branchCd === '0021'" href="https://hyper-gangnam.etoos.com"> 강남하이퍼학원 <span>본원</span> </a>
          <a v-else-if="userInfo.branchCd === '0110'" href="https://hyper-medicalgisuk.etoos.com"> 강남하이퍼기숙학원 <span>의대관</span> </a>
          <a v-else-if="userInfo.branchCd === '0310'" href="https://hyper-medical.etoos.com"> 강남하이퍼학원 <span>의대관</span> </a>
          <a v-else-if="userInfo.branchCd === '0130'" href="https://hyper-mokdong.etoos.com"> 강남하이퍼학원 <span>목동관</span> </a>
          <a v-else href="https://hyper-gisuk.etoos.com">강남하이퍼기숙학원</a>
        </h1>
        <div class="outlink">
          <ul class="afterLogin">
            <li class="arr">
              <!-- <a href="javascript:fn_logout_proc();"> -->
              <!-- 로컬용 -->
              <a v-if="userInfo.branchCd === '0021'" :href="ETOOS_MEMBER_LOGOUT_URL + '?callbackUrl=' + HOMEPAGE_URL[userInfo.branchCd]">
                <strong>{{ userInfo.userName }}</strong
                >님 로그아웃
              </a>
              <a v-else-if="userInfo.branchCd === '0110'" :href="ETOOS_MEMBER_LOGOUT_URL + '?callbackUrl=' + HOMEPAGE_URL[userInfo.branchCd]">
                <strong>{{ userInfo.userName }}</strong
                >님 로그아웃
              </a>
              <a v-else :href="ETOOS_MEMBER_LOGOUT_URL + '?callbackUrl=' + HOMEPAGE_URL[userInfo.branchCd]">
                <strong>{{ userInfo.userName }}</strong
                >님 로그아웃
              </a>
            </li>
            <li class="arr"><a href="javascript:;" @click="goMyPage('')">마이페이지</a></li>
            <li class="arr"><a href="javascript:;" @click="goMyPage('400')">장바구니</a></li>
            <li class="tel">
              <p>
                <span>교육상담</span><em>{{ BRANCH_EDU_COUNSEL_PHONE_NO[userInfo.branchCd] }}</em>
              </p>
            </li>
            <li><a href="https://www.etoos.com" target="_blank">이투스</a></li>
            <li><a href="https://www.etoos.com/book/" target="_blank">이투스북</a></li>
            <li><a @click="toggleAcaNetwork(true)">학원 네트워크</a></li>
            <li>
              <a href="https://cheongsol.etoos.com/gate/lctr/main.do" class="u" target="_blank">강사·전략담임 모집</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { OLD_MY_PAGE_URL, ETOOS_MEMBER_LOGOUT_URL, HOMEPAGE_URL } from '@build/build.config';
import { BRANCH_EDU_COUNSEL_PHONE_NO } from '@/const/const.js';
import { getBrandType } from '../../../../utils/util';
import { mapActions, mapState } from 'vuex';
export default {
  name: 'Header',
  props: ['userInfo'],
  data() {
    return {
      OLD_MY_PAGE_URL: OLD_MY_PAGE_URL[getBrandType(location.href)],
      BRANCH_EDU_COUNSEL_PHONE_NO,
      ETOOS_MEMBER_LOGOUT_URL,
      LOCATION_HREF: location.href,
      HOMEPAGE_URL
    };
  },
  computed: {
    ...mapState({
      encMemNo: (state) => state.common.memNo
    })
  },
  methods: {
    ...mapActions(['getMemNo']),
    toggleAcaNetwork(flag) {
      this.$emit('toggleAcaNetwork', flag);
    },
    goMyPage(menuCd) {
      const initDomain = {
        '0030': 'gangbuk',
        '0040': 'bucheon',
        '0050': 'bundang',
        '0080': 'ilsan',
        '0090': 'pyeongchon',
        '0130': 'a2',
        '0021': 'h1',
        '0110': 'h3',
        '0120': 'h2',
        '0060': 'etoos-gisuk',
        '0310': 'h4',
        '0500': 'n2',
        '0010': 'gangbuk'
      };
      const requestData = { etoosMemNo: this.userInfo.etoosMemNo };
      this.getMemNo(requestData).then(() => {
        window.location.href =
          this.OLD_MY_PAGE_URL +
          '/login/memNo.do?memNo=' +
          encodeURIComponent(this.encMemNo.mem_no) +
          '&returnUrl=' +
          encodeURIComponent(this.HOMEPAGE_URL[this.userInfo.branchCd]) +
          '&initDomain=' +
          initDomain[this.userInfo.branchCd] +
          '&menuCd=' +
          menuCd;
      });
    }
  }
};
</script>
