<template>
  <div class="pop_layout" style="width: 780px">
    <div class="popti">강좌 상세정보</div>
    <div class="popbody">
      <div class="fs14 ffam800 vab mgt20">
        [<span class="gw">{{ subjt }}</span
        >] <span class="teacher">{{ teacherName }} </span><br /><span class="lecture">{{ groupNm }}</span>
      </div>
      <div class="scll mgt5" id="img">
        <img :src="imgSrc" style="border-radius: 5px; width: 100%;" alt="강좌소개 이미지" />
      </div>
      <div class="txt_c mgt20">
        <a @click="close" class="combtn gbbtn dib" style="width: 110px">확인</a>
        <a @click="close" class="combtn wbtn dib mgl5" style="width: 110px">닫기</a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { PAGE_TITLE } from '../../../const/const';

export default {
  name: 'lectureDetailP',
  data() {
    return {
      subjt: '',
      teacherName: '',
      groupNm: '',
      imgSrc: '',
      PAGE_TITLE
    };
  },
  props: ['userInfo'],
  watch: {
    userInfo() {
      this.changeTitle();
    }
  },
  components: {},
  computed: {
    ...mapState({
      userInfo: (state) => state.common.userInfo,
      imageData: (state) => state.classroom.lecture.imageData
    })
  },
  created() {
    if (this.userInfo.branchCd === '') {
      this.getUser().finally(() => {
        this.changeTitle();
      });
    } else {
      this.changeTitle();
    }
    if (!this.userInfo.studentCd) {
      this.getUser().then(() => {
        this.groupNm = this.$route.query.groupName;
        this.teacherName = this.$route.query.teacherName;
        this.subjt = this.$route.query.subjt;
        this.getImageData();
      });
    } else {
      this.groupNm = this.$route.query.groupName;
      this.teacherName = this.$route.query.teacherName;
      this.subjt = this.$route.query.subjt;
      this.getImageData();
    }
  },
  methods: {
    ...mapActions(['getUser', 'getImage']),
    changeTitle() {
      let title = '';
      if (this.userInfo.branchCd !== '') title = PAGE_TITLE[this.userInfo.branchCd];
      document.title = 'mypage_강좌상세정보 | ' + title;
    },
    getImageData() {
      const params = {};
      params.branchCd = this.$route.query.branchCd;
      params.groupYear = this.$route.query.groupYear;
      params.groupidx = this.$route.query.grpIdx;

      this.getImage(params).then(() => {
        if (this.imageData.data !== null) {
          //border-radius:5px;max-width:780px;
          this.imgSrc = '/api/lecture/view?fileName=' + this.imageData.data.imageurl;
        } else {
          alert('강좌 상세정보가 없습니다');
        }
      });
    },
    close() {
      self.close();
    }
  }
};
</script>
