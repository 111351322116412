<template>
  <!-- 답안입력1 -->
  <div class="popup_cont popup_ansr" :class="{ mobile: isMobile, dailymath_pop: isMathPop, dailymath_pop_v2: isMathPop }">
    <div class="popup_head">
      <h1 class="tit_popup">{{ getMenuName() }}</h1>
      <div class="expander">
        <div class="exam_subject">
          <span class="txt_subject">{{ currentTest.unityExamNm }}</span>
          <span class="txt_subject">{{ currentTest.unityDtlExamNm }}</span>
          <span class="txt_subject">{{ currentTest.unityDtlExamTurnOrd }}회</span>
          <span class="txt_subject">{{ currentTest.subjtNm }}</span>
        </div>
        <div class="wrap_select">
          <select v-model="statusOption" @change="changeSubjt01($event)" class="select_cont" v-if="!isMathPop">
            <option v-if="enterAnswerSelectExamInfo[0].subTamYn !== 'Y'" value="">선택과목</option>
            <option v-if="enterAnswerSelectExamInfo[0].subTamYn === 'Y'" value="">선택과목1</option>
            <option v-for="(item, idx) in enterAnswerSelectExamInfo" :key="idx" :value="item.unityExamSubjtSeq">{{ item.dtlSubjtNm }}</option>
          </select>
          <select v-else v-model="statusOption" class="select_cont">
            <option v-for="(item, idx) in currentTest.child" :key="idx" :value="item.byyySubjtSeq">{{ item.subjtNm }}</option>
          </select>
        </div>
        <div v-if="enterAnswerSelectExamInfo[0].subTamYn === 'Y'" class="wrap_select">
          <select v-model="statusOptionTam" @change="changeSubjt02($event)" class="select_cont">
            <option value="">선택과목2</option>
            <option v-for="(item, idx) in enterAnswerSelectExamInfo" :key="idx" :value="item.unityExamSubjtSeq">{{ item.dtlSubjtNm }}</option>
          </select>
        </div>
      </div>
      <div class="type_check" v-show="!isMathPop">
        <label class="lab_radio" for="typeRadio1">
          <input type="radio" id="typeRadio1" class="inp_radio" name="typeRadio" v-model="InputsData" value="DataAll" />
          <span class="ico_radio"></span>일괄입력
        </label>
        <label class="lab_radio" for="typeRadio2">
          <input type="radio" id="typeRadio2" class="inp_radio" name="typeRadio" v-model="InputsData" value="DataOne" />
          <span class="ico_radio"></span>개별입력
        </label>
      </div>
    </div>
    <div class="popup_body">
      <div v-show="InputsData === 'DataAll'" class="wrap_exam">
        <template v-if="!isDisabled">
          <div class="cont_empty">
            <p>선택과목을 선택해 주세요.</p>
          </div>
        </template>
        <template v-else-if="this.statusOption != '' && enterAnswerSelectExamInfo[0].subTamYn === 'N'">
          <h2 class="tit_cont mo_only">
            {{ enterAnswerSelectExamInfo[0].unityExamNm }} > {{ enterAnswerSelectExamInfo[0].unityDtlExamNm }} > {{ enterAnswerSelectExamInfo[0].unityDtlExamTurnOrd }}회 > {{ enterAnswerSelectExamInfo[0].subjtNm }}
            <span> ({{ enterAnswerSelectExamInfo[0].dtlSubjtNm }}) </span>
          </h2>
          <div class="box_answerinput size10">
            <dl class="info_item">
              <div v-for="(item, idx) in initInput(this.enterAnswerExamQustnInfo)" :key="idx" :value="item.qustn" class="item_container">
                <dt>{{ item.qustn }}</dt>
                <dd>
                  <input
                    type="number"
                    name="inpQustn"
                    class="tf_ansr"
                    :maxlength="getMaxlength(item.qustn, enterAnswerExamQustnInfo)"
                    v-on:keydown="numberMaxLength($event.target)"
                    @change="changeNumberValidator($event.target)"
                    :value="item.ansr"
                    v-on:keyup="focusNext($event.target)"
                  />
                </dd>
              </div>
            </dl>
          </div>
        </template>
        <template v-else-if="this.statusOption != '' && enterAnswerSelectExamInfo[0].subTamYn === 'Y'">
          <div class="wrap_column">
            <div class="col_half">
              <strong class="tit_table pc_only">
                <span>
                  {{ enterAnswerSelectExamInfo.filter((item) => item.unityExamSubjtSeq == statusOption)[0].dtlSubjtNm }}
                </span>
              </strong>
              <h2 class="tit_cont mo_only">
                {{ enterAnswerSelectExamInfo[0].unityExamNm }} > {{ enterAnswerSelectExamInfo[0].unityDtlExamNm }} > {{ enterAnswerSelectExamInfo[0].unityDtlExamTurnOrd }}회 > {{ enterAnswerSelectExamInfo[0].subjtNm }}
                <span> ({{ enterAnswerSelectExamInfo[0].dtlSubjtNm }}) </span>
              </h2>
              <div class="box_answerinput size4">
                <dl class="info_item">
                  <div v-for="(item, idx) in initInput(this.enterAnswerExamQustnInfo)" :key="idx" :value="item.qustn" class="item_container">
                    <dt>{{ item.qustn }}</dt>
                    <dd>
                      <input
                        type="number"
                        name="inpQustn"
                        class="tf_ansr"
                        :maxlength="getMaxlength(item.qustn, enterAnswerExamQustnInfo)"
                        :value="item.ansr"
                        v-on:keydown="numberMaxLength($event.target)"
                        @change="changeNumberValidator($event.target)"
                        v-on:keyup="focusNext($event.target)"
                      />
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            <div class="col_half">
              <strong class="tit_table pc_only">
                <span v-if="this.statusOptionTam != ''">
                  {{ enterAnswerSelectExamInfo.filter((item) => item.unityExamSubjtSeq == statusOptionTam)[0].dtlSubjtNm }}
                </span>
                <span v-else>
                  -
                </span>
              </strong>
              <h2 class="tit_cont mo_only">
                {{ enterAnswerSelectExamInfo[1].unityExamNm }} > {{ enterAnswerSelectExamInfo[1].unityDtlExamNm }} > {{ enterAnswerSelectExamInfo[1].unityDtlExamTurnOrd }}회 > {{ enterAnswerSelectExamInfo[1].subjtNm }}
                <span> ({{ enterAnswerSelectExamInfo[1].dtlSubjtNm }}) </span>
              </h2>
              <div class="box_answerinput size4" v-if="this.statusOptionTam != ''">
                <dl class="info_item">
                  <div v-for="(item, idx) in initInput(enterAnswerExamQustnTamInfo)" :key="idx" :value="item.qustn" class="item_container">
                    <dt>{{ item.qustn }}</dt>
                    <dd>
                      <input
                        type="number"
                        name="inpQustnTam"
                        class="tf_ansr"
                        :maxlength="getMaxlength(item.qustn, enterAnswerExamQustnTamInfo)"
                        :value="item.ansr"
                        v-on:keydown="numberMaxLength($event.target)"
                        @change="changeNumberValidator($event.target)"
                        v-on:keyup="focusNext($event.target)"
                      />
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </template>
        <div class="wrap_btn">
          <button type="button" @click="saveInpStdntQustnTotal" :disabled="statusOption === ''" class="btn btn_save">
            저장
          </button>
        </div>
      </div>
      <div v-show="InputsData === 'DataOne'" class="wrap_exam_paper">
        <div class="paper_info" :class="{ active: isMobile, dimmed: openQuestionUi }">
          <div class="nav_num" v-if="isMobile && isMathPop" :class="{ open: openQuestionUi }">
            <!-- open -->
            <button type="button" class="btn_open" @click="openQuestionUi = !openQuestionUi" :disabled="questionInfoList.length < 6">
              열기/닫기
            </button>
            <ul class="list_number">
              <li
                v-for="(obj, idx) in questionInfoList"
                :class="{ on: activeIndex === idx }"
                @click="
                  activeIndex = idx;
                  openQuestionUi = false;
                "
                v-show="openQuestionUi || isShowLi(idx)"
              >
                <a class="num_order" :class="{ finish: saveAnswers[idx] }">{{ obj.qustnNo }}</a>
              </li>
            </ul>
          </div>
          <div class="bar_top_header mo_only" v-if="isMathPop" style="box-sizing: content-box">
            <div class="info_title"><strong class="tit">문항</strong></div>
            <div class="info_timer">
              <em class="tit_timer">남은시간</em>
              <p class="time_count" v-if="timer != null">
                {{
                  Math.floor(timeSeconds / 60)
                    .toFixed(0)
                    .padStart(2, '0')
                }}:{{ String(timeSeconds % 60).padStart(2, '0') }}
              </p>
              <p class="time_count" v-else>--:--</p>
            </div>
          </div>
          <div class="exam_cont">
            <div class="box_question" :class="{ on: isMobile && isMathPop }">
              <strong class="blind">지문/문항</strong>
              <div v-if="StringUtils.isNotEmpty(currentQuestion.textImgPathwy) && currentQuestion.qustnInpMethodCd === '1'">
                <img :src="awsResourceUrl + '/unity-exam/' + currentQuestion.textImgPathwy" alt="" />
              </div>
              <div v-if="StringUtils.isNotEmpty(currentQuestion.textImgPathwy) && currentQuestion.qustnInpMethodCd === '2'">
                <img :src="getTmsImg(currentQuestion.textImgPathwy)" alt="" />
              </div>
              <div v-if="StringUtils.isNotEmpty(currentQuestion.qustnImgPathwy)">
                <img v-if="currentQuestion.qustnInpMethodCd === '1'" :src="awsResourceUrl + '/unity-exam/' + currentQuestion.qustnImgPathwy" alt="" />
                <img v-if="currentQuestion.qustnInpMethodCd === '2'" :src="getTmsImg(currentQuestion.qustnImgPathwy)" alt="" />
              </div>
              <div v-else>
                <p class="blank">문항이미지가 없습니다.</p>
              </div>
            </div>
            <div class="box_explain on" v-if="!isMathPop">
              <strong class="blind">해설</strong>
              <div v-if="StringUtils.isNotEmpty((enterAnswerExamQustnInfo[activeIndex] || {}).explImgPathwy)">
                <img v-if="enterAnswerExamQustnInfo[activeIndex].qustnInpMethodCd === '1'" :src="awsResourceUrl + '/unity-exam/' + enterAnswerExamQustnInfo[activeIndex].explImgPathwy" alt="" />
                <img v-if="enterAnswerExamQustnInfo[activeIndex].qustnInpMethodCd === '2'" :src="getTmsImg(enterAnswerExamQustnInfo[activeIndex].explImgPathwy)" alt="" />
              </div>
              <div v-else>
                <p class="blank">해설이미지가 없습니다.</p>
              </div>
            </div>
            <template v-if="!isMathPop">
              <button type="button" @click="qustnPrev" class="btn_prev">이전</button>
              <button type="button" @click="qustnNext" class="btn_next">다음</button>
            </template>
            <template v-else>
              <button type="button" @click="activeIndex === 0 ? '' : activeIndex--" class="btn_prev">
                이전
              </button>
              <button type="button" @click="activeIndex === questionInfoList.length - 1 ? '' : activeIndex++" class="btn_next">
                다음
              </button>
              <div class="bar_bottom_tool mo_only">
                <ol class="list_ansr">
                  <li v-for="i in 5" :key="i">
                    <label>
                      <input type="radio" name="examAnsr" :value="i" v-model="saveAnswers[activeIndex]" />
                      <span class="num">{{ i }}</span>
                    </label>
                  </li>
                </ol>
                <button type="button" class="btn_save" @click="saveInpStdntQustn" :disabled="isSaveBtnDisabled">
                  저장
                </button>
              </div>
            </template>
          </div>
        </div>
        <template v-if="questionViewType == 0">
          <div class="exam_sheet">
            <template v-if="!isMathPop">
              <aside-list
                ref="aside_list"
                v-for="(item, index) in this.categories"
                :title="item[0].dtlSubjtNm"
                :active="index === activeLiIndex"
                :index="index"
                :key="item[0].dtlSubjtNm"
                :questionViewType="questionViewType"
                @newactive="activeLiIndex = $event"
                :Limits="item[0].unityExamSubjtSeq == statusOption ? enterAnswerExamQustnInfo : enterAnswerExamQustnTamInfo"
                @qustnPrev="qustnPrev()"
                @qustnNext="qustnNext()"
                @qustnIndex="qustnIndex"
              />
            </template>
            <aside-list
              v-else
              ref="aside_list"
              :title="aSideTitle"
              :active="true"
              :active-index="activeIndex"
              :questionViewType="questionViewType"
              @newactive="activeLiIndex = $event"
              :Limits="questionInfoList"
              :is-math-pop="isMathPop"
              @qustnPrev="qustnPrev()"
              @qustnNext="qustnNext()"
              @qustnIndex="qustnIndex"
            />
            <div class="info_timer" v-if="isMathPop">
              <em class="tit_timer">남은시간</em>
              <p class="time_count" v-if="timer != null">
                {{
                  Math.floor(timeSeconds / 60)
                    .toFixed(0)
                    .padStart(2, '0')
                }}:{{ String(timeSeconds % 60).padStart(2, '0') }}
              </p>
              <p class="time_count" v-else>
                --:--
              </p>
            </div>
            <div class="wrap_btn">
              <button type="button" @click="saveInpStdntQustn" :disabled="statusOption === '' || isSaveBtnDisabled" class="btn btn_save">
                저장
              </button>
              <button type="button" @click="openPopup('unityExamViewQustnPrintPopup', 'type1')" :disabled="statusOption === '' || isSaveBtnDisabled" class="btn btn_save" v-show="!isMobile && isMathPop">
                문제 인쇄하기
              </button>
              <button type="button" @click="openPopup('unityExamViewQustnPrintPopup', 'type3')" :disabled="statusOption === '' || isSaveBtnDisabled" class="btn btn_save" v-show="!isMobile && isMathPop">
                해설 인쇄하기
              </button>
              <button type="button" @click="openPopup('unityExamViewQustnPrintPopup', 'type2')" :disabled="statusOption === '' || isSaveBtnDisabled" class="btn btn_save" v-show="!isMobile && isMathPop">
                문제 + 해설 인쇄하기
              </button>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AsideList from '@/components/unityexam/common/popup/AsideList';
import Modal from '../Modal';
import { AWS_S3_RESOURCE_URL } from '@build/build.config';
import { windowOpenPopup } from '@/mixins/windowOpenPopup';
import { tmsImage } from '@/mixins/dailytest/tmsImage';
import StringUtils from '@/utils/StringUtils';
import moment from 'moment';
import { PAGE_TITLE } from '../../../../const/const';
export default {
  name: 'unityExamEnterAnswerPopup',
  mixins: [tmsImage, windowOpenPopup],
  components: {
    AsideList,
    Modal
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      userInfo: (state) => state.common.userInfo,
      enterAnswerSelectExamInfo: (state) => state.unityexam.enteranswer.enterAnswerSelectExamInfo,
      enterAnswerExamQustnInfo: (state) => state.unityexam.enteranswer.enterAnswerExamQustnInfo,
      enterAnswerExamQustnTamInfo: (state) => state.unityexam.enteranswer.enterAnswerExamQustnTamInfo,
      lastTest: (state) => state.unityexam.wransrnote.lastTest,
      mySubjectList: (state) => state.unityexam.wransrnote.mySubjectList,
      questionInfoList: (state) => state.unityexam.wransrnote.questionInfoList
    }),
    isDisabled() {
      return this.statusOption !== '';
    },
    currentQuestion() {
      let questions = [];
      if (!this.isMathPop) {
        questions = this.enterAnswerExamQustnInfo || [];
      } else {
        questions = this.questionInfoList || [];
      }

      if (questions.length > 0) {
        return questions[this.activeIndex];
      }
      return {};
    }
  },
  data() {
    return {
      isMathPop: false,
      InputsData: 'DataAll',
      statusOption: parseInt(this.$route.query.dtlSubjtSeq01) || '',
      statusOptionTam: parseInt(this.$route.query.dtlSubjtSeq02) || '',
      questionViewType: 0,
      options: ['1', '2'],
      activeLiIndex: 0,
      categories: [],
      activeIndex: 0,
      awsResourceUrl: AWS_S3_RESOURCE_URL,
      isSave: false,
      StringUtils,
      currentTest: {
        unityExamNm: '',
        unityDtlExamNm: '',
        unityDtlExamTurnOrd: 0
      },
      aSideTitle: '',
      inputAnswers: [],
      timeSeconds: 0,
      timer: null,
      saveAnswers: {},
      openQuestionUi: false,
      isSaveBtnDisabled: false
    };
  },
  watch: {
    statusOption() {
      if (this.isMathPop) {
        const param = {
          turnOrdSeq: this.currentTest.unityDtlExamTurnOrdSeq,
          byyySubjtSeq: this.statusOption
        };
        this.getQuestionInfo(param);
        this.currentTest.child.forEach((childSubjt) => {
          if (childSubjt.byyySubjtSeq === this.statusOption) {
            this.aSideTitle = childSubjt.subjtNm;
          }
        });
        if (Object.keys(this.$refs.aside_list.$data.answer).length !== 0) {
          alert('선택과목을 변경하면 답안이 초기화됩니다.');
          this.$refs.aside_list.clearAnswer();
        }

        this.initTimer();
        return;
      }
      this.subjectExamInfo();
    },
    statusOptionTam() {
      this.subjectExamInfo();
    },
    activeLiIndex() {
      this.activeIndex = 0;
      this.$refs.aside_list[this.activeLiIndex].isActive = this.activeIndex;
      this.$refs.aside_list[this.activeLiIndex].index = this.activeIndex;
    },
    InputsData() {
      if (this.isMathPop) {
        return;
      }
      alert('입력방법 변경 시, 저장하지 않은 답안은 초기화 됩니다.');
    },
    timeSeconds(seconds) {
      if (seconds === 0) {
        if (this.timer !== null) {
          clearInterval(this.timer);
        }
        setTimeout(() => {
          this.saveMathTest();
        }, 100);
      }
    }
  },
  created() {
    this.isMathPop = this.$route.meta.id === 'mathpop';
    if (!this.userInfo.studentCd) {
      this.getUser().then(() => {
        this.init();
      });
    } else {
      this.init();
    }
    this.changeTitle();
  },
  methods: {
    ...mapActions([
      'getUser',
      'getEnterAnswerSelectExam',
      'getEnterAnswerExamQustn',
      'getEnterAnswerExamQustnTam',
      'postInpStdntQustn',
      'postInitStdntQustn',
      'getMathDmTestSubjt',
      'getStdSelSubjectByYear',
      'getQuestionInfo',
      'saveTestAnswer',
      'saveSubjtPrint'
    ]),
    changeTitle() {
      let title = '';
      if (this.userInfo.branchCd !== '') title = PAGE_TITLE[this.userInfo.branchCd];
      document.title = 'mypage_수학DM - 시험응시 | ' + title;
    },
    getMenuName() {
      return this.isMathPop ? '시험 응시' : '답안 입력';
    },
    init() {
      const params = {};
      if (this.isMathPop) {
        this.InputsData = 'DataOne';
        const selSubjtSeq = Number(this.$route.query['selSubjtSeq']) || 0;
        this.getMathDmTestSubjt(this.$route.query['turnOrdSeq'] || 0).then(() => {
          this.currentTest = this.lastTest;
          this.getStdSelSubjectByYear({ year: moment().format('YYYY'), studentCd: this.userInfo.studentCd }).then(() => {
            if (this.currentTest.child.length === 0) {
              return;
            }
            if (selSubjtSeq !== 0) {
              for (const childSubjt of this.currentTest.child) {
                if (Number(childSubjt.unityExamSubjtSeq) === Number(selSubjtSeq)) {
                  this.statusOption = childSubjt.byyySubjtSeq;
                  return;
                }
              }
            }

            if ((this.mySubjectList || []).length > 0) {
              for (const value of this.mySubjectList) {
                if (Number(value.highByyySubjtSeq) === Number(this.currentTest.byyySubjtSeq)) {
                  for (const childSubjt of this.currentTest.child) {
                    if (Number(childSubjt.byyySubjtSeq) === Number(value.byyySubjtSeq)) {
                      this.statusOption = childSubjt.byyySubjtSeq;
                      return;
                    }
                  }
                }
              }
            }
            this.statusOption = this.currentTest.child[0].byyySubjtSeq;
          });
        });
        return;
      }
      params.examYy = this.$route.query['examYy'];
      params.highSubjtSeq = this.$route.query['highSubjtSeq'];
      params.unityExamSeq = this.$route.query['unityExamSeq'];
      params.unityDtlExamSeq = this.$route.query['unityDtlExamSeq'];
      params.unityDtlExamTurnOrdSeq = this.$route.query['unityDtlExamTurnOrdSeq'];
      params.unityExamSubjtSeq = this.$route.query['dtlSubjtSeq01'];
      params.unityExamSubjtSeqTam = this.$route.query['dtlSubjtSeq02'];
      this.initExam(params);
    },
    initExam(params) {
      this.getEnterAnswerSelectExam(params).then(() => {
        if (this.statusOption !== '') {
          if (this.enterAnswerSelectExamInfo.filter((item) => item.unityExamSubjtSeq === this.statusOption)[0].qustnInpCnt > 0) {
            this.initSubjt01(this.statusOption, 'Y');
          } else {
            this.initSubjt01(this.statusOption, 'N');
          }
          if (this.statusOptionTam !== '') {
            if (this.enterAnswerSelectExamInfo.filter((item) => item.unityExamSubjtSeq === this.statusOptionTam)[0].qustnInpCnt > 0) {
              this.initSubjt02(this.statusOptionTam, 'Y');
            } else {
              this.initSubjt02(this.statusOptionTam, 'N');
            }
          }
        } else {
          this.enterAnswerSelectExamInfo.forEach((item, idx) => {
            if (item.qustnInpCnt > 0) {
              if (this.statusOption === '') {
                this.statusOption = item.unityExamSubjtSeq;
                this.initSubjt01(this.statusOption, 'Y');
              } else if (item.subTamYn === 'Y' && this.statusOption !== '') {
                this.statusOptionTam = item.unityExamSubjtSeq;
                this.initSubjt02(this.statusOptionTam, 'Y');
              }
            }
          });
        }
        if ((this.enterAnswerSelectExamInfo || []).length > 0) {
          const infoElement = this.enterAnswerSelectExamInfo[0];
          this.currentTest = {
            unityExamNm: infoElement.unityExamNm,
            unityDtlExamNm: infoElement.unityDtlExamNm,
            unityDtlExamTurnOrd: infoElement.unityDtlExamTurnOrd,
            subjtNm: infoElement.subjtNm
          };
        }
        this.subjectExamInfo();
      });
    },
    changeSubjt01(e) {
      const params = {};
      const completeCnt = this.enterAnswerSelectExamInfo.filter((item) => item.qustnInpCnt > 0).length;
      if (e.target.value === '') {
        e.target.value = this.statusOptionTam;
        this.statusOption = e.target.value;
        const inpQustnListTam = document.querySelectorAll('input[name="inpQustnTam"]');
        const tempArray = [];
        const inpQustnList = document.querySelectorAll('input[name="inpQustn"]');
        inpQustnListTam.forEach((e, idx) => {
          tempArray.push(e.value);
        });
        inpQustnList.forEach((e, idx) => {
          e.value = tempArray[idx];
        });

        this.statusOptionTam = '';
      }
      params.unityExamSubjtSeq = this.statusOption;
      if (this.statusOption !== '' && this.enterAnswerSelectExamInfo.filter((item) => item.unityExamSubjtSeq === this.statusOption)[0].qustnInpCnt > 0) {
        params.examSubjtInpCompleteYn = 'Y';
      } else {
        if (this.statusOption !== '' && completeCnt > 0) {
          alert('선택과목을 변경하면 답안이 초기화됩니다.');
        }
      }

      params.highSubjtSeq = this.$route.query['highSubjtSeq'];
      params.examYy = this.$route.query['examYy'];
      this.getEnterAnswerExamQustn(params);
    },
    changeSubjt02(e) {
      const completeCnt = this.enterAnswerSelectExamInfo.filter((item) => item.qustnInpCnt > 0).length;

      if (this.statusOption === this.statusOptionTam) {
        alert('동일한 탐구 과목 2개는 선택할 수 없습니다.');
        this.statusOptionTam = '';
      }
      const params = {};
      params.unityExamSubjtSeq = e.target.value;
      if (this.statusOptionTam !== '' && this.enterAnswerSelectExamInfo.filter((item) => item.unityExamSubjtSeq === this.statusOptionTam)[0].qustnInpCnt > 0) {
        params.examSubjtInpCompleteYn = 'Y';
      } else {
        if (this.statusOptionTam !== '' && completeCnt > 1) {
          alert('선택과목을 변경하면 답안이 초기화됩니다.');
        }
      }
      params.highSubjtSeq = this.$route.query['highSubjtSeq'];
      params.examYy = this.$route.query['examYy'];
      this.getEnterAnswerExamQustnTam(params);
    },
    initSubjt01(seq, completeYn) {
      const params = {};
      params.unityExamSubjtSeq = seq;
      params.examSubjtInpCompleteYn = completeYn;
      params.highSubjtSeq = this.$route.query['highSubjtSeq'];
      params.examYy = this.$route.query['examYy'];
      this.getEnterAnswerExamQustn(params);
    },
    initSubjt02(seq, completeYn) {
      const params = {};
      params.unityExamSubjtSeq = seq;
      params.examSubjtInpCompleteYn = completeYn;
      params.highSubjtSeq = this.$route.query['highSubjtSeq'];
      params.examYy = this.$route.query['examYy'];
      this.getEnterAnswerExamQustnTam(params);
    },
    subjectExamInfo() {
      this.categories = [];
      if (this.statusOption !== '') {
        this.categories.push(this.enterAnswerSelectExamInfo.filter((item) => item.unityExamSubjtSeq === this.statusOption));
      }
      if (this.statusOptionTam !== '') {
        this.categories.push(this.enterAnswerSelectExamInfo.filter((item) => item.unityExamSubjtSeq === this.statusOptionTam));
      }
      return this.categories;
    },
    qustnPrev() {
      if (this.activeIndex > 0) {
        this.activeIndex--;
        this.$refs.aside_list[this.activeLiIndex].isActive = this.activeIndex;
        this.$refs.aside_list[this.activeLiIndex].index = this.activeIndex;
      }
    },
    qustnNext() {
      if (this.activeIndex < (this.activeLiIndex === 0 ? this.enterAnswerExamQustnInfo.length - 1 : this.enterAnswerExamQustnTamInfo.length - 1)) {
        this.activeIndex++;
        this.$refs.aside_list[this.activeLiIndex].isActive = this.activeIndex;
        this.$refs.aside_list[this.activeLiIndex].index = this.activeIndex;
      }
    },
    qustnIndex(index) {
      this.activeIndex = index;
    },
    initInput(qustnList) {
      const qustnInfoList = [];
      const list = [];
      const listAnsr = [];
      let cnt = 0;
      qustnList.forEach(function(item, idx) {
        if (item.qustnTpCd === '1') {
          let temp = list.pop();
          const tempAnsr = listAnsr.pop();
          if (typeof temp === 'undefined') {
            if (idx !== 0) {
              list.push('1~' + parseInt(idx));
              let str = '';
              for (let i = 0; i < idx; i++) {
                str += qustnList[i].inpAnsr;
              }
              listAnsr.push(str);
            }
            list.push(parseInt(idx + 1));
            listAnsr.push(item.inpAnsr);
          } else {
            if (typeof temp === 'string') {
              list.push(temp);
              listAnsr.push(tempAnsr);
              temp = temp.split('~');
              if (temp[1] < idx) {
                if (parseInt(parseInt(temp[1]) + 1) === parseInt(idx)) {
                  list.push(parseInt(idx));
                  listAnsr.push(qustnList[idx - 1].inpAnsr);
                } else {
                  list.push(parseInt(parseInt(temp[1]) + 1) + '~' + parseInt(idx));
                  let str = '';
                  for (let i = parseInt(temp[1]); i < idx; i++) {
                    str += qustnList[i].inpAnsr;
                  }
                  listAnsr.push(str);
                }
              }
              list.push(parseInt(idx + 1));
              listAnsr.push(item.inpAnsr);
            } else if (typeof temp === 'number') {
              if (temp === idx) {
                list.push(temp);
                listAnsr.push(tempAnsr);
                list.push(parseInt(idx + 1));
                listAnsr.push(qustnList[idx].inpAnsr);
              } else {
                list.push(temp);
                listAnsr.push(tempAnsr);
                if (parseInt(temp + 1) === parseInt(idx)) {
                  list.push(parseInt(idx));
                  listAnsr.push(qustnList[idx - 1].inpAnsr);
                  list.push(parseInt(idx + 1));
                  listAnsr.push(qustnList[idx].inpAnsr);
                } else {
                  list.push(parseInt(temp + 1) + '~' + parseInt(idx));
                  let str = '';
                  for (let i = parseInt(temp); i < idx; i++) {
                    str += qustnList[i].inpAnsr;
                  }
                  listAnsr.push(str);
                  list.push(parseInt(idx + 1));
                  listAnsr.push(qustnList[idx].inpAnsr);
                }
              }
            }
          }
          cnt = 0;
        } else if (cnt === 4 || qustnList.length === idx + 1) {
          let temp = list.pop();
          const tempAnsr = listAnsr.pop();
          if (typeof temp === 'undefined') {
            if (idx === 0) {
              list.push(parseInt(idx + 1));
              listAnsr.push(qustnList[idx].inpAnsr);
            } else if (idx < 4) {
              list.push('1~' + parseInt(idx + 1));
              let str = '';
              for (let i = 0; i <= idx; i++) {
                str += qustnList[i].inpAnsr;
              }
              listAnsr.push(str);
            } else {
              list.push(parseInt(idx - 3) + '~' + parseInt(idx + 1));
              let str = '';
              for (let i = parseInt(idx - 4); i <= idx; i++) {
                str += qustnList[i].inpAnsr;
              }
              listAnsr.push(str);
            }
          } else {
            list.push(temp);
            listAnsr.push(tempAnsr);
            if (typeof temp === 'string') {
              temp = temp.split('~');
              if (parseInt(parseInt(temp[1]) + 1) === parseInt(idx + 1)) {
                list.push(parseInt(idx + 1));
                listAnsr.push(qustnList[idx].inpAnsr);
              } else {
                list.push(parseInt(parseInt(temp[1]) + 1) + '~' + parseInt(idx + 1));
                let str = '';
                for (let i = parseInt(temp[1]); i <= idx; i++) {
                  str += qustnList[i].inpAnsr;
                }
                listAnsr.push(str);
              }
            } else if (typeof temp === 'number') {
              if (temp === idx) {
                list.push(parseInt(idx + 1));
                listAnsr.push(qustnList[idx].inpAnsr);
              } else {
                list.push(parseInt(temp + 1) + '~' + parseInt(idx + 1));
                let str = '';
                for (let i = parseInt(temp); i <= idx; i++) {
                  str += qustnList[i].inpAnsr;
                }
                listAnsr.push(str);
              }
            } else {
              list.push(parseInt(idx + 1));
              listAnsr.push(qustnList[idx].inpAnsr);
            }
          }
          cnt = 0;
        } else {
          cnt++;
        }
      });

      list.forEach((item, idx) => {
        const data = {};
        data['qustn'] = item;
        if (listAnsr[idx] !== null) {
          data['ansr'] = listAnsr[idx].length > 5 || listAnsr[idx] === 'null' ? '' : listAnsr[idx];
        }
        qustnInfoList.push(data);
      });
      return qustnInfoList;
    },
    getMaxlength(val, qustnList) {
      const cnt = this.qustnCnt(val);
      if (cnt === 1) {
        const qustnTpCd = qustnList[parseInt(val) - 1].qustnTpCd;
        return qustnTpCd === '1' ? 3 : 1;
      } else {
        return cnt;
      }
    },
    numberMaxLength(e) {
      if (e.value.length >= e.maxLength) {
        e.value = e.value.slice(0, e.maxLength - 1);
      }
    },
    changeNumberValidator(e) {
      /* if (e.value.slice(-1) == 6) {
        e.value = Number(e.value) - 1;
      } else if (e.value.slice(-1) == 0) {
        e.value = Number(e.value) + 1;
      }*/
    },
    qustnCnt(val) {
      let cnt = 0;
      if (typeof val === 'string') {
        const array = val.split('~');
        cnt = parseInt(array[1]) - parseInt(array[0]) + 1;
      } else {
        cnt = 1;
      }
      return cnt;
    },
    getInpSubjectAnswer(inpTypeName, qustnInfo) {
      const uiInpQustList = this.initInput(inpTypeName);
      const inpQustnList = document.querySelectorAll('input[name=' + qustnInfo + ']');
      let subnm = '';
      if (qustnInfo === 'inpQustn') {
        subnm = this.enterAnswerSelectExamInfo.filter((item) => item.unityExamSubjtSeq === this.statusOption)[0].dtlSubjtNm;
      } else {
        subnm = this.enterAnswerSelectExamInfo.filter((item) => item.unityExamSubjtSeq === this.statusOptionTam)[0].dtlSubjtNm;
      }
      const tempArray = [];
      for (let i = 0; i < inpQustnList.length; i++) {
        if (inpQustnList[i].value === '') {
          alert(subnm + ' 답안을 입력해 주세요');
          return false;
        } else {
          const cnt = this.qustnCnt(uiInpQustList[i].qustn);
          if (cnt === 1) {
            tempArray.push(inpQustnList[i].value);
          } else {
            if (inpQustnList[i].value.length !== cnt) {
              alert(subnm + ' 답안을 입력해 주세요');
              return false;
            } else {
              for (let j = 0; j < cnt; j++) {
                tempArray.push(inpQustnList[i].value.substring(j, j + 1));
              }
            }
          }
        }
      }

      const stdntQustnInpRequestList = [];
      const ansList = {};
      if (qustnInfo == 'inpQustn') {
        this.enterAnswerExamQustnInfo.forEach((item, idx) => {
          const data = {};
          data['unityExamQustnSeq'] = item.unityExamQustnSeq;
          data['inpAnsr'] = tempArray[idx];
          stdntQustnInpRequestList.push(data);
        });
        ansList['stdntQustnInpRequestList'] = stdntQustnInpRequestList;
        ansList['unityExamSubjtSeq'] = this.statusOption;
        ansList['highSubjtSeq'] = this.$route.query['highSubjtSeq'];
      } else {
        this.enterAnswerExamQustnTamInfo.forEach((item, idx) => {
          const data = {};
          data['unityExamQustnSeq'] = item.unityExamQustnSeq;
          data['inpAnsr'] = tempArray[idx];
          stdntQustnInpRequestList.push(data);
        });
        ansList['stdntQustnInpRequestList'] = stdntQustnInpRequestList;
        ansList['unityExamSubjtSeq'] = this.statusOptionTam;
        ansList['highSubjtSeq'] = this.$route.query['highSubjtSeq'];
      }
      return ansList;
    },
    saveInpStdntQustnTotal() {
      this.isSave = true;
      this.enterAnswerExamQustnInfo.forEach((item, idx) => {
        if (item.alot === 0) {
          this.isSave = false;
        }
      });
      if (this.isSave === false) {
        alert('답안이 저장되지 않았습니다. 관리자에 문의해주세요.');
        return false;
      }
      if (this.statusOptionTam !== '') {
        this.enterAnswerExamQustnTamInfo.forEach((item, idx) => {
          if (item.alot === 0) {
            this.isSave = false;
          }
        });
        if (this.isSave === false) {
          alert('답안이 저장되지 않았습니다. 관리자에 문의해주세요.');
          return false;
        }
      }
      let params = {};
      params.examYy = this.$route.query['examYy'];
      params.unityExamSeq = this.$route.query['unityExamSeq'];
      params.unityDtlExamSeq = this.$route.query['unityDtlExamSeq'];
      params.unityDtlExamTurnOrdSeq = this.$route.query['unityDtlExamTurnOrdSeq'];
      params.highSubjtSeq = this.$route.query['highSubjtSeq'];
      params.unityExamSubjtSeq = this.statusOption;
      params.unityExamSubjtSeqTam = this.statusOptionTam;
      this.postInitStdntQustn(params).then(() => {
        params = [];
        const list = this.getInpSubjectAnswer(this.enterAnswerExamQustnInfo, 'inpQustn');
        if (list === false) return false;
        params.push(list);
        if (this.statusOptionTam !== '') {
          const list = this.getInpSubjectAnswer(this.enterAnswerExamQustnTamInfo, 'inpQustnTam');
          if (list === false) return false;
          params.push(list);
        }
        this.postInpStdntQustn(params).then(() => {
          this.openQustnPopup('unityExamViewQustnPopup');
        });
      });
    },
    openQustnPopup(PopName) {
      const route = this.$router.resolve({
        name: PopName,
        query: {
          examYy: this.$route.query['examYy'],
          unityExamSeq: this.$route.query['unityExamSeq'],
          unityDtlExamSeq: this.$route.query['unityDtlExamSeq'],
          unityDtlExamTurnOrdSeq: this.$route.query['unityDtlExamTurnOrdSeq'],
          highSubjtSeq: this.$route.query['highSubjtSeq'],
          subjtSeq: this.statusOption
        }
      });
      this.saveAlert();
      if (!this.isMobile) {
        this.openWindowPopup(route.href, this.$route.name, PopName, this.locationReload, 'width=990, height=800, scrollbars=1');
      }
      window.opener.location.reload();
      window.focus();
      window.close();
    },
    openPopup(PopName, contentViewType) {
      let unityExamSubjtSeq = null,
        highExamSubjtSeq = null;
      for (let i = 0; i < (this.currentTest.child || []).length; i++) {
        if (this.currentTest.child[i].byyySubjtSeq === Number(this.statusOption)) {
          unityExamSubjtSeq = this.currentTest.child[i].unityExamSubjtSeq;
          highExamSubjtSeq = this.currentTest.unityExamSubjtSeq;
          break;
        }
      }

      this.subjtPrintFlag(unityExamSubjtSeq);
      const route = this.$router.resolve({
        name: PopName,
        query: {
          examYy: this.$route.query['examYy'],
          unityExamSeq: this.$route.query['unityExamSeq'],
          unityDtlExamSeq: this.$route.query['unityDtlExamSeq'],
          unityDtlExamTurnOrdSeq: this.currentTest.unityDtlExamTurnOrdSeq, //this.$route.query['unityDtlExamTurnOrdSeq'],
          dtlSubjtSeq: unityExamSubjtSeq, //this.$route.query['selSubjtSeq'],
          contentView: contentViewType
        }
      });
      this.openWindowPopup(route.href, this.$route.name, PopName, this.locationReload, 'width=990, height=800, scrollbars=1');
    },
    saveAlert() {
      const dtlSubjtNm = this.enterAnswerSelectExamInfo.filter((item) => item.unityExamSubjtSeq === this.statusOption)[0].dtlSubjtNm;
      if (this.statusOptionTam !== '') {
        const dtlSubjtTamNm = this.enterAnswerSelectExamInfo.filter((item) => item.unityExamSubjtSeq === this.statusOptionTam)[0].dtlSubjtNm;
        if (
          this.enterAnswerExamQustnInfo[0].coransrCauseDivCd === '9999' &&
          this.enterAnswerExamQustnInfo[0].wransrCauseDivCd === '9999' &&
          this.enterAnswerExamQustnTamInfo[0].coransrCauseDivCd === '9999' &&
          this.enterAnswerExamQustnTamInfo[0].wransrCauseDivCd === '9999'
        ) {
          alert('답안입력이 완료되었습니다.');
        } else {
          alert('답안입력이 완료되었습니다. ' + dtlSubjtNm + ', ' + dtlSubjtTamNm + '의 정오답원인을 입력해주세요.');
        }
      } else {
        if (this.enterAnswerExamQustnInfo[0].coransrCauseDivCd === '9999' && this.enterAnswerExamQustnInfo[0].wransrCauseDivCd === '9999') {
          alert('답안입력이 완료되었습니다.');
        } else {
          alert('답안입력이 완료되었습니다. ' + dtlSubjtNm + '의 정오답원인을 입력해주세요.');
        }
      }
    },
    saveMathTest() {
      if ((this.questionInfoList || []).length === 0) {
        return;
      }
      const currentAnswer = this.getAnswer();
      const answer = {};
      this.questionInfoList.forEach((qustionInfo) => {
        let saveAnswer = currentAnswer[qustionInfo.qustnNo];
        if (!saveAnswer) {
          saveAnswer = { answer: null, unityExamQustnSeq: qustionInfo.unityExamQustnSeq };
        }
        answer[qustionInfo.qustnNo] = saveAnswer;
      });

      let unityExamSubjtSeq = null,
        highExamSubjtSeq = null;
      for (let i = 0; i < (this.currentTest.child || []).length; i++) {
        if (this.currentTest.child[i].byyySubjtSeq === Number(this.statusOption)) {
          unityExamSubjtSeq = this.currentTest.child[i].unityExamSubjtSeq;
          highExamSubjtSeq = this.currentTest.unityExamSubjtSeq;
          break;
        }
      }

      const takexamTmssCnt = (this.lastTest.takexamTmssCnt || 0) * 60;
      const solveTime = takexamTmssCnt === 0 ? 0 : takexamTmssCnt - this.timeSeconds;
      const param = {
        answer,
        turnOrdSeq: this.currentTest.unityDtlExamTurnOrdSeq,
        byyySubjtSeq: this.statusOption,
        solveTime,
        studentCd: this.userInfo.studentCd,
        unityExamSubjtSeq,
        highExamSubjtSeq
      };
      this.saveTestAnswer(param)
        .then(() => {
          const open = opener;
          if (open) {
            open.reloadTestInfo();
          }
          this.$router.push({
            name: 'mathDailyTestResult',
            query: { turnOrdSeq: param.turnOrdSeq },
            params: { selectByyySubjtSeq: param.byyySubjtSeq }
          });
        })
        .finally(() => {
          this.isSaveBtnDisabled = false;
        });
    },
    answerEmptyCheck() {
      if (this.isMobile) {
        if (Object.keys(this.saveAnswers).length === this.questionInfoList.length) {
          return -1;
        }
        for (let i = 0; i < this.questionInfoList.length; i++) {
          if (!this.saveAnswers[i]) {
            return this.questionInfoList[i].qustnNo;
          }
        }
      } else {
        const answer = this.$refs.aside_list.$data.answer;
        if (Object.keys(answer).length === this.questionInfoList.length) {
          return -1;
        }
        for (const qustion of this.questionInfoList) {
          const qustnNo = qustion.qustnNo;
          if (!answer[qustnNo]) {
            return qustnNo;
          }
        }
      }
      return -1;
    },
    getAnswer() {
      if (this.isMobile) {
        const answer = {};
        Object.keys(this.saveAnswers).forEach((key) => {
          const qustionInfo = this.questionInfoList[key];
          const qustnNo = qustionInfo.qustnNo;
          answer[qustnNo] = { answer: this.saveAnswers[key], unityExamQustnSeq: qustionInfo.unityExamQustnSeq };
        });
        return answer;
      } else {
        return this.$refs.aside_list.$data.answer;
      }
    },
    saveInpStdntQustn() {
      if (this.isMathPop) {
        this.isSaveBtnDisabled = true;
        const number = this.answerEmptyCheck();
        if (number !== -1) {
          if (this.isMobile) {
            alert(`${number}번 답안을 입력해주세요.`);
            this.isSaveBtnDisabled = false;
          } else {
            alert(`${this.aSideTitle} 답안을 입력해주세요.`);
            this.isSaveBtnDisabled = false;
          }
          return false;
        }
        if (this.timer !== null) {
          clearInterval(this.timer);
        }
        this.saveMathTest();
        return false;
      }
      const tempArray = [];
      const inpQustnAll = document.querySelectorAll('input[name^="examAnsr"]');
      let cnt = 0;
      let textQustn = 0;
      for (let i = 0; i < inpQustnAll.length; i++) {
        if ((i - textQustn) % 5 === 0) {
          cnt = 0;
        }
        if (inpQustnAll[i].type === 'radio' && inpQustnAll[i].checked === false) {
          cnt++;
          if (cnt === 5) {
            alert(inpQustnAll[i].title + ' 답안을 입력해 주세요');
            return false;
          }
        }

        if (inpQustnAll[i].type === 'radio' && inpQustnAll[i].checked === true) {
          cnt = 0;
          tempArray.push(inpQustnAll[i].value);
        } else if (inpQustnAll[i].type === 'text') {
          if (inpQustnAll[i].value === '') {
            alert(inpQustnAll[i].title + ' 답안을 입력해 주세요');
            return false;
          }
          tempArray.push(inpQustnAll[i].value);
          textQustn++;
          cnt = 0;
        }
      }
      if (tempArray.length !== this.enterAnswerExamQustnInfo.length + this.enterAnswerExamQustnTamInfo.length) {
        return false;
      }

      this.isSave = true;
      this.enterAnswerExamQustnInfo.forEach((item, idx) => {
        if (item.alot === 0) {
          this.isSave = false;
        }
      });
      if (this.isSave === false) {
        alert('답안이 저장되지 않았습니다. 관리자에 문의해주세요.');
        return false;
      }
      if (this.statusOptionTam !== '') {
        this.enterAnswerExamQustnTamInfo.forEach((item, idx) => {
          if (item.alot === 0) {
            this.isSave = false;
          }
        });
        if (this.isSave === false) {
          alert('답안이 저장되지 않았습니다. 관리자에 문의해주세요.');
          return false;
        }
      }

      let params = {};
      params.examYy = this.$route.query['examYy'];
      params.unityExamSeq = this.$route.query['unityExamSeq'];
      params.unityDtlExamSeq = this.$route.query['unityDtlExamSeq'];
      params.unityDtlExamTurnOrdSeq = this.$route.query['unityDtlExamTurnOrdSeq'];
      params.highSubjtSeq = this.$route.query['highSubjtSeq'];
      params.unityExamSubjtSeq = this.statusOption;
      params.unityExamSubjtSeqTam = this.statusOptionTam;
      this.postInitStdntQustn(params).then(() => {
        params = [];
        let stdntQustnInpRequestList = [];
        let ansList = {};
        this.enterAnswerExamQustnInfo.forEach((item, idx) => {
          const data = {};
          data['unityExamQustnSeq'] = item.unityExamQustnSeq;
          data['inpAnsr'] = tempArray[idx];
          stdntQustnInpRequestList.push(data);
        });
        ansList['stdntQustnInpRequestList'] = stdntQustnInpRequestList;
        ansList['unityExamSubjtSeq'] = this.statusOption;
        ansList['highSubjtSeq'] = this.$route.query['highSubjtSeq'];
        params.push(ansList);

        if (this.statusOptionTam !== '') {
          stdntQustnInpRequestList = [];
          ansList = {};
          this.enterAnswerExamQustnTamInfo.forEach((item, idx) => {
            const data = {};
            data['unityExamQustnSeq'] = item.unityExamQustnSeq;
            data['inpAnsr'] = tempArray[this.enterAnswerExamQustnInfo.length + idx];
            stdntQustnInpRequestList.push(data);
          });
          ansList['stdntQustnInpRequestList'] = stdntQustnInpRequestList;
          ansList['unityExamSubjtSeq'] = this.statusOptionTam;
          ansList['highSubjtSeq'] = this.$route.query['highSubjtSeq'];
          params.push(ansList);
        }
        this.postInpStdntQustn(params).then(() => {
          this.openQustnPopup('unityExamViewQustnPopup');
        });
      });
    },
    focusNext(elem) {
      elem.value = elem.value.replace(/[^0-9]/g, '');
      const inputs = Array.from(document.querySelectorAll('input[name^="inpQustn"]'));
      const index = inputs.indexOf(elem);
      if (index < inputs.length - 1 && elem.value.length === elem.maxLength) {
        inputs[index + 1].focus();
      }
    },
    initTimer() {
      if (this.timer !== null) {
        clearInterval(this.timer);
      }
      this.timeSeconds = (this.lastTest.takexamTmssCnt || 0) * 60;
      if (this.timeSeconds === 0) {
        this.timer = null;
        return;
      }
      this.timer = setInterval(() => {
        this.timeSeconds -= 1;
      }, 1000);
    },
    isShowLi(idx) {
      const rangeStart = Math.floor(this.activeIndex / 5) * 5;
      return rangeStart <= idx && idx < rangeStart + 5;
    },
    subjtPrintFlag(unityExamSubjtSeq) {
      const studentCd = this.userInfo.studentCd;
      const param = {
        studentCd,
        unityExamSubjtSeq
      };
      this.saveSubjtPrint(param);
    }
  }
};
</script>
