<template>
  <div class="module-sidenavi">
    <template v-if="questionViewType == '0'">
      <div class="sheet_cont" :class="{ active: active }">
        <div class="box_subject">
          <button type="button" class="btn_subject" @click.stop="toggleRowActive">{{ title }}</button>
        </div>
        <div class="box_sheet">
          <table class="tbl_exam">
            <caption>
              답안지
            </caption>
            <colgroup>
              <col style="width:38px" />
              <col style="width:auto" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">답안지</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(Limit, index) in Limits"
                :key="index"
                :class="{ on: isActive === index }"
                @click="toggleActive(index)"
              >
                <template v-if="!isMathPop">
                  <th scope="row">
                    <a href="javascript:;" class="num_order">{{ index + 1 }}</a>
                  </th>
                  <td v-if="Limit.qustnTpCd === '2'">
                    <aside-radio
                      :name="`inpQustn${index}_${Limit.unityExamSubjtSeq}`"
                      :title="title"
                      :ansr="Limit.inpAnsr"
                      :subjectSeq="Limit.unityExamSubjtSeq"
                    />
                  </td>
                  <td v-else-if="Limit.qustnTpCd === '1'">
                    <input type="text" name="examAnsrinpQustn" :title="title" class="tf_ansr" :value="Limit.inpAnsr" />
                  </td>
                </template>
                <template v-else>
                  <th scope="row">
                    <a href="javascript:;" class="num_order">{{ Limit.qustnNo }}</a>
                  </th>
                  <td v-if="Limit.qustnTpCd === '2'">
                    <aside-radio
                      :name="`inpQustn${index}_${Limit.unityExamSubjtSeq}`"
                      :title="title"
                      :subjectSeq="Limit.unityExamSubjtSeq"
                      @change-answer="changeAnswer($event, Limit)"
                    />
                  </td>
                  <td v-else-if="Limit.qustnTpCd === '1'">
                    <input
                      type="text"
                      name="examAnsrinpQustn"
                      :title="title"
                      class="tf_ansr"
                      @change="changeInputAnswer($event, Limit)"
                    />
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template v-if="questionViewType == '1'">
      <div class="sheet_cont" :class="{ active: active }">
        <div class="box_subject">
          <button type="button" class="btn_subject" @click.stop="toggleRowActive">{{ subjtNm }}({{ title }}) {{ orgScr !== 0 ? orgScr + '점' : '' }}</button>
        </div>
        <div class="box_sheet">
          <table class="tbl_exam type2">
            <caption>
              답안지
            </caption>
            <colgroup>
              <col style="width:20%" />
              <col style="width:24%" />
              <col style="width:24%" />
              <col style="width:auto" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">정답</th>
                <th scope="col">내답안</th>
                <th scope="col">오답노트</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(Limit, index) in Limits"
                :key="index"
                :class="{ on: isActive === index }"
                @click="toggleActive(index)"
              >
                <th scope="row">
                  <a
                    href="javascript:;"
                    class="num_order"
                    :class="{ wransr: Limit.coransrYn === 'N', ansr: Limit.coransrYn === 'Y' }"
                    @click="openViewAnswer"
                  >
                    {{ index + 1 }}
                  </a>
                </th>
                <td>
                  <a href="javascript:;" @click="openViewAnswer" class="num_order sel_ansr">{{ Limit.coransr }}</a>
                </td>
                <td>
                  <a href="javascript:;" @click="openViewAnswer" class="num_order sel_ansr">{{ Limit.inpAnsr }}</a>
                </td>
                <td class="data_check">
                  <label class="lbl_check">
                    <input
                      type="checkbox"
                      @change="checkWransrNote(Limit, $event)"
                      :checked="Limit.coransrNotesYn === 'Y'"
                      class="inp_check"
                    />
                    <span class="ico_check"></span>
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template v-if="questionViewType == '2'">
      <table class="tbl_exam">
        <caption>
          답안지
        </caption>
        <colgroup>
          <col style="width:20%" />
          <col style="width:24%" />
          <col style="width:24%" />
          <col style="width:auto" />
        </colgroup>
        <thead>
          <tr>
            <th scope="col">No</th>
            <th scope="col">정답</th>
            <th scope="col">내답안</th>
            <th scope="col">오답노트</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(Limit, index) in Limits"
            :key="index"
            :class="{ on: isActive === index }"
            @click="toggleActive(index)"
          >
            <th scope="row">
              <a href="javascript:;" class="num_order">
                {{ index + 1 }}
              </a>
            </th>
            <td>
              <a href="javascript:;" @click="openViewAnswer" class="num_order sel_ansr">{{ Limit.coransr }}</a>
            </td>
            <td>
              <a href="javascript:;" @click="openViewAnswer" class="num_order sel_ansr">{{ Limit.myAnsr }}</a>
            </td>
            <td class="data_check">
              <label class="lbl_check">
                <input
                  type="checkbox"
                  @change="checkWransrNote(Limit, $event)"
                  :checked="Limit.coransrNotesYn === 'Y'"
                  :disabled="isParent"
                  class="inp_check"
                />
                <span class="ico_check"></span>
              </label>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <template v-if="Number(questionViewType) === 3">
      <div class="sheet_cont" :class="{ active: active }">
        <div class="box_subject">
          <button type="button" class="btn_subject" @click.stop="toggleRowActive">{{ listTitle }}</button>
        </div>
        <div class="box_sheet">
          <table class="tbl_exam type2">
            <caption>
              답안지
            </caption>
            <colgroup>
              <col style="width:20%" />
              <col style="width:20%" />
              <col style="width:20%" />
              <col style="width:20%" />
              <col style="width:auto" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">정답</th>
                <th scope="col">내답안</th>
                <th scope="col">삭제</th>
                <th scope="col">복습</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(Limit, index) in Limits"
                :key="index"
                :class="{ on: isActive === index }"
                @click="toggleActive(index)"
              >
                <th scope="row">
                  <a
                    href="javascript:;"
                    class="num_order"
                    :class="{ wransr: Limit.coransrYn === 'N', ansr: Limit.coransrYn === 'Y' }"
                    @click="openViewAnswer"
                  >
                    {{ Limit.no }}
                  </a>
                </th>
                <td>
                  <a href="javascript:;" @click="openViewAnswer" class="num_order sel_ansr">{{ Limit.coransr }}</a>
                </td>
                <td>
                  <a href="javascript:;" @click="openViewAnswer" class="num_order sel_ansr" :class="Limit.coransrYn === 'N' ? 'txt_not_yet' : ''">{{ Limit.inpAnsr }}</a>
                </td>
                <td>
                  <button v-if="(Limit.no.indexOf('-') < 0)" type="button" class="btn_delete" @click="removeRow(index,Limit.unityExamQustnSeq, Limit.bystdntUnityExamQustnInpStusSeq)">
                    <span class="blind">삭제</span>
                  </button>
                </td>
                <td class="data_check">
                  <label class="lbl_check">
                    <input
                      type="checkbox"
                      @change="checkReviEd(Limit, $event)"
                      :checked="Limit.reviEdYn === 'Y'"
                      class="inp_check"
                    />
                    <span class="ico_check"></span>
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template v-if="questionViewType === '4'">
      <div class="sheet_cont" :class="{ active: active }">
        <div class="box_subject">
          <template v-if="isMathTestResult">
            <p class="txt_info">{{ getMathTestResultTitle }}</p>
            <div class="info_timer mo_only">
              <em class="tit_timer">응시시간</em>
              <p class="time_count" v-if="analysisTest.qustnSolvSec > 0">
                {{
                  Math.floor(analysisTest.qustnSolvSec / 60)
                    .toFixed(0)
                    .padStart(2, '0')
                }}:{{ String(analysisTest.qustnSolvSec % 60).padStart(2, '0') }}
              </p>
              <p class="time_count" v-else>--:--</p>
            </div>
          </template>
          <button v-else type="button" class="btn_subject" @click.stop="toggleRowActive">
            {{ subjtNm }}({{ title }}) {{ orgScr !== 0 ? orgScr + '점' : '' }}
          </button>
        </div>
        <div class="box_sheet">
          <table class="tbl_exam type2">
            <caption>
              답안지
            </caption>
            <colgroup>
              <col style="width:20%" />
              <col style="width:24%" />
              <col style="width:24%" />
              <col style="width:auto" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">정답</th>
                <th scope="col">내답안</th>
                <th scope="col">오답노트</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(Limit, idx) in Limits"
                :key="idx"
                :class="{ on: isActive === idx }"
                @click="toggleActive(idx)"
              >
                <th scope="row">
                  <a
                    href="javascript:;"
                    class="num_order"
                    :class="Limit.coransr === Limit.myAnsr ? 'ansr' : 'wransr'"
                    @click="openViewAnswer"
                  >
                    {{ Limit.qustnNo }}
                  </a>
                </th>
                <td>
                  <a href="javascript:;" @click="openViewAnswer" class="num_order sel_ansr">{{ Limit.coransr }}</a>
                </td>
                <td>
                  <a
                    href="javascript:;"
                    @click="openViewAnswer"
                    class="num_order sel_ansr"
                    :class="{ txt_not_yet: isMathTestResult && Limit.coransr !== Limit.myAnsr }"
                  >{{ Limit.myAnsr }}</a
                  >
                </td>
                <td class="data_check">
                  <label class="lbl_check">
                    <input
                      type="checkbox"
                      @change="checkWransrNote(Limit, $event)"
                      :checked="Limit.coransrNotesYn === 'Y'"
                      class="inp_check"
                      :disabled="isParent"
                    />
                    <span class="ico_check"></span>
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="info_timer" v-if="isMathTestResult">
        <em class="tit_timer">응시시간</em>
        <p class="time_count" v-if="analysisTest.qustnSolvSec > 0">
          {{
            Math.floor(analysisTest.qustnSolvSec / 60)
              .toFixed(0)
              .padStart(2, '0')
          }}:{{ String(analysisTest.qustnSolvSec % 60).padStart(2, '0') }}
        </p>
        <p class="time_count" v-else>--:--</p>
      </div>
      <div class="wrap_btn" v-if="isMathTestResult">
        <button type="button" class="btn btn_save" @click="goTestAnalysis()">성적분석</button>
      </div>
    </template>
  </div>
</template>

<script>
import AsideRadio from './AsideRadio';
import { USER_AUTHORITY } from '@/const/const';
import { mapState } from 'vuex';
import MobileDetect from 'mobile-detect';

export default {
  props: {
    title: {
      type: String
    },
    subjtNm: {
      type: String
    },
    active: {
      required: true
    },
    activeIndex: {
      default: -1
    },
    index: {
      type: [String, Number]
    },
    questionViewType: {
      type: [String, Number],
      required: true
    },
    Limits: {
      dafault: [
        { type: 'A', isCorrect: true },
        { type: 'A', isCorrect: false },
        { type: 'B', isCorrect: true },
        { type: 'A', isCorrect: false },
        { type: 'A', isCorrect: true },
        { type: 'A', isCorrect: false },
        { type: 'A', isCorrect: true },
        { type: 'A', isCorrect: false },
        { type: 'A', isCorrect: true },
        { type: 'A', isCorrect: false },
        { type: 'A', isCorrect: true }
      ]
    },
    orgScr: {
      default: 0
    },
    isParent: {
      default: true
    },
    isMathPop: {
      default: false
    },
    isMathTestResult: {
      default: false
    },
    turnOrd: {
      default: 0
    },
    addTurnOrd: {
      default: 'N'
    }
  },
  components: {
    AsideRadio
  },
  computed: {
    ...mapState({
      analysisTest: (state) => state.dailytest.test.analysisTest
    }),
    getMathTestResultTitle() {
      let totalCnt = 0;
      let answerCnt = 0;
      (this.Limits || []).forEach((value) => {
        if (value.coransrYn === 'Y') {
          answerCnt++;
        }
        totalCnt++;
      });
      return `정답률 ${
        totalCnt === 0 ? '' : ((answerCnt / totalCnt) * 100).toFixed(1)
      }% (${answerCnt}/${totalCnt} 문항)`;
    },
    listTitle() {
      let title = '';
      if (Number(this.questionViewType) === 3) {
        title = `${this.subjtNm}(${this.title})`;
        if (this.turnOrd > 0 && this.addTurnOrd === 'Y') {
          title = `${this.turnOrd}회 > ${title}`;
        }
      }
      return title;
    }
  },
  watch: {
    activeIndex(idx) {
      this.isActive = idx;
    }
  },
  data() {
    return {
      isActive: 0,
      USER_AUTHORITY,
      answer: {}
    };
  },
  methods: {
    toggleActive(index) {
      this.isActive = index;
      this.$emit('qustnIndex', index);
    },
    toggleRowActive() {
      if ([1, 3, '4'].indexOf(this.questionViewType) !== -1 && this.active) {
        return;
      }
      this.$emit('newactive', this.index);
    },
    openViewAnswer() {
      const md = new MobileDetect(window.navigator.userAgent);
      if (md.phone()) {
        this.$emit('questionViews');
      }
    },
    removeRow(index, unityExamQustnSeq, bystdntUnityExamQustnInpStusSeq) {
      if (confirm('해당 문항을 오답노트에서 삭제하시겠습니까?')) {
        this.$emit('changeRegiWransrNote', unityExamQustnSeq, bystdntUnityExamQustnInpStusSeq);
        let cnt = 1 + this.Limits[index].smlQustnList.length;
        this.Limits.splice(index, cnt);
      }
    },
    checkWransrNote(qustn, e) {
      qustn.coransrNotesYn = e.target.checked === true ? 'Y' : 'N';
      this.$emit('changeRegiWransrNote', qustn.unityExamQustnSeq, qustn.bystdntUnityExamQustnInpStusSeq, e);
    },
    checkReviEd(qustn, e) {
      this.$emit('changeRegiReviEd', qustn, e);
    },
    changeAnswer(value, questionInfo) {
      this.answer[questionInfo.qustnNo] = {
        unityExamQustnSeq: questionInfo.unityExamQustnSeq,
        answer: value
      };
    },
    changeInputAnswer(event, questionInfo) {
      this.answer[questionInfo.qustnNo] = {
        unityExamQustnSeq: questionInfo.unityExamQustnSeq,
        answer: event.target.value
      };
    },
    clearAnswer() {
      this.answer = {};
      let elements = document.querySelectorAll('.list_ansr input[type="radio"]');
      if (elements !== null) {
        for (const element of elements) {
          element.checked = false;
        }
      }
      elements = document.querySelectorAll('input[type="text"][name="examAnsrinpQustn"]');
      if (elements !== null) {
        for (const element of elements) {
          element.value = '';
        }
      }
    },
    goTestAnalysis() {
      this.$emit('go-test-analysis');
    }
  }
};
</script>

<style></style>
