<template>
  <div id="form_search_order">
    <div class="srch_hgroup">
      <div class="sel" data-select2-id="select2-data-4-wx4s">
        <select v-model="selectedLectureTypeCd" name="srcBranCate" id="srcBranCate" required>
          <!-- 2023.06.14 전체 추가 -->
          <option value="all" disabled selected>유형</option>
          <option value="" selected>전체</option>
          <option v-for="option in lectureTypeCdOptions" :key="option.value" :value="option">
            {{ option.text }}
          </option>
        </select>
        <select v-model="selectedSubjct" name="subjctCd" id="srcBranCd">
          <option value="all" disabled>과목</option>
          <option v-for="option in subjectOptions" :key="option.value" :value="option">
            {{ option.text }}
          </option>
        </select>
        <Select2 v-model="selectedTeacher" :options="teacherOptions" class="auto_complete_box" @select="selectedTeacherCallback($event.dataCustomAttribute)" ref="selectedTeacher" />
      </div>
      <div class="lecture_name">
        <input v-model="searchText" type="text" placeholder="강사, 강좌명" @keyup.enter="search" />
      </div>
      <button @click="search" class="mbtn dark btnSearch">조회하기</button>
      <button @click="searchAll" class="mbtn dark btnSearch">전체보기</button>
      <div class="txt_box">
        <span v-for="selectedOption in selectedOptions" :key="selectedOption.value" class="search_txt"> {{ selectedOption.text }}<em @click="deleteSelectedOption(selectedOption)">X</em> </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import EventBus from '../../../utils/eventBus';
import Select2 from 'v-select2-component';

const SUBJECT = 'subject';
const TEACHER = 'teacher';

/**
 * 무한패스 검색 테이블 컴포넌트
 * @since 2023.03.20
 * @author 유경호 ykh6242@etoos.com
 */
export default {
  name: 'search-table',
  components: { Select2 },
  props: {
    /**
     * 요청할 검색 api path
     */
    apiPath: {
      type: String,
      default: ''
    },
    /**
     * 페이지당 데이터 숫자
     */
    numPerPage: {
      type: Number,
      default: 10
    }
  },
  data: () => ({
    selectedLectureTypeCd: 'all',
    lectureTypeCdOptions: [
      { value: '1', text: '정규수업' },
      { value: '21', text: '수학전문관' },
      { value: '2', text: '단과' },
      { value: '3', text: '특강' }
    ],
    selectedSubjct: 'all',
    subjectOptions: [{ text: '', value: '' }],
    teacherOptions: [{ text: '', id: '' }], // 2023.06.14 select box -> Select2 autocomplete 검색뷰로 변경. Select2에서 value 는 id로 지정
    selectedTeacher: 'all',
    selectedOptions: [],
    searchText: '',
    currPage: 1
  }),
  computed: {
    ...mapState({
      studentCd: (state) => state.common.userInfo.studentCd,
      subjectList: (state) => state.infinitePass.subjectList,
      teacherList: (state) => {
        return state.infinitePass.teacherList;
      }
    })
  },
  watch: {
    /* 2023.06.14 유형 watch 추가 : [전체] 선택시 [유형] 으로 선택될 수 있도록 설정 */
    selectedLectureTypeCd(val) {
      if (val === '') {
        this.selectedLectureTypeCd = 'all';
      }
    },
    selectedSubjct(val) {
      if (val !== 'all') {
        if (!this.selectedOptions.includes(val)) {
          this.selectedOptions.push(val);
        }
        this.selectedSubjct = 'all';
      }
    },
    subjectList(val) {
      this.subjectOptions = val.map((el) => {
        return { text: el.subjtNm, value: el.unitySubjtCd, type: SUBJECT };
      });
    },
    teacherList(val) {
      this.teacherOptions = val.map((el) => {
        return { text: el.teacherName, id: el.teacherCd, width: '100%', dataCustomAttribute: { text: el.teacherName, value: el.teacherCd, type: TEACHER } };
      });
      this.teacherOptions.unshift({ text: '강사', id: 'all', disabled: 'disabled', width: '100%', dataCustomAttribute: { text: '강사', value: 'all', type: TEACHER } });
    }
  },
  async mounted() {
    if (!this.studentCd) {
      await this.getUser();
    }
    /*
     * 학생 대표반의 무한패스 강좌에 대한 과목 리스트 초기화
     */
    if (!this.subjectList.length) {
      await this.getInfiniteSubjects({ studentCd: this.studentCd });
    }
    this.subjectOptions = this.subjectList.map((el) => {
      return { text: el.subjtNm, value: el.unitySubjtCd, type: SUBJECT };
    });
    /*
     * 학생 대표반의 무한패스 강좌에 대한 선생님 리스트 초기화
     */
    if (!this.teacherList.length) {
      await this.getInfiniteTeachers({ studentCd: this.studentCd });
    }
    this.teacherOptions = this.teacherList.map((el) => {
      return { text: el.teacherName, id: el.teacherCd, width: '100%', type: TEACHER, dataCustomAttribute: { text: el.teacherName, value: el.teacherCd, type: TEACHER } };
    });
    this.teacherOptions.unshift({ text: '강사', id: 'all', disabled: 'disabled', width: '100%', dataCustomAttribute: { text: '강사', value: 'all', type: TEACHER } });

    // autocomplete 검색결과가 없을 경우 custom 메세지 노출
    this.$refs.selectedTeacher.select2.select2.defaults.set('language--noResults', () => {
      return '결과가 없습니다.';
    });

    await this.searchAll();
    EventBus.$on('setPage', (pageNum) => {
      this.currPage = pageNum;
      this.search('setPage');
    });
  },
  methods: {
    ...mapActions(['getInfiniteSubjects', 'getInfiniteTeachers', 'getSearchData', 'getUser']),
    async search(type) {
      const params = new URLSearchParams();
      params.append('studentCd', this.studentCd);
      if (this.selectedLectureTypeCd !== 'all' && this.selectedLectureTypeCd !== '') {
        params.append('lectureTypeCds', this.selectedLectureTypeCd.value);
      }
      params.append(
        'teacherCds',
        this.selectedOptions
          ?.filter((el) => el.type === TEACHER)
          .map((el) => el.value)
          .join(',')
      );
      params.append(
        'unitySubjtCds',
        this.selectedOptions
          ?.filter((el) => el.type === SUBJECT)
          .map((el) => el.value)
          .join(',')
      );
      params.append('searchText', this.searchText);
      params.append('pageSize', String(this.numPerPage));
      params.append('pageNo', type === 'setPage' ? this.currPage : 1);

      this.$emit('loadingStart');
      const result = await this.getSearchData({ apiPath: this.apiPath, params });
      this.$emit('search', result);
    },
    async searchAll() {
      this.currPage = 1;
      const request = {
        studentCd: this.studentCd,
        pageSize: this.numPerPage,
        pageNo: this.currPage
      };

      this.$emit('loadingStart', true);
      const result = await this.getSearchData({ apiPath: this.apiPath, params: request });
      this.selectedOptions = [];
      this.searchText = '';
      this.$emit('search', result);
    },
    deleteSelectedOption(selectedOption) {
      const idx = this.selectedOptions.indexOf(selectedOption);
      this.selectedOptions.splice(idx, 1);
    },
    /* 강사 선택 후 검색 data 설정 */
    selectedTeacherCallback(selectedTeacher) {
      if (selectedTeacher.id !== 'all') {
        if (!this.selectedOptions.includes(selectedTeacher)) {
          this.selectedOptions.push(selectedTeacher);
        }
        this.selectedTeacher = 'all';
        this.$refs.selectedTeacher.setValue(this.selectedTeacher);
      }
    }
  },
  beforeDestroy() {
    EventBus.$off('setPage');
  }
};
</script>
<style>
.select2-container {
  min-width: 120px;
}

select option[value='all'][disabled] {
  display: none;
}

.select2-results__option[aria-disabled='true'] {
  display: none;
}
</style>
