<template>
  <div class="paging">
    <a @click="goFirstPage" class="first"><span class="blind">처음</span></a>
    <a @click="prevPage" class="prev"><span class="blind">이전</span></a>
    <span class="num">
      <template v-for="page in pageList">
        <a v-if="currPage !== page" :key="page" @click="setPage(page)">{{ page }}</a>
        <strong v-else :key="page">{{ page }}</strong>
      </template>
    </span>
    <a @click="nextPage" class="next"><span class="blind">다음</span></a>
    <a @click="goLastPage" class="last"><span class="blind">마지막</span></a>
  </div>
</template>

<script>
import EventBus from '../../../utils/eventBus';

export default {
  name: 'InfiniteTablePage',
  data: () => ({
    currPage: 1,
    totalCnt: 0,
    pageSize: 10
  }),
  computed: {
    totalPages() {
      let number = Math.floor((this.totalCnt + this.pageSize - 1) / this.pageSize);
      if (number < 1) {
        number = 1;
      }
      return number;
    },
    pageList() {
      let maxPage = Math.floor((this.currPage - 1) / this.pageSize) * this.pageSize + this.pageSize;
      const minPage = Math.floor((this.currPage - 1) / this.pageSize) * this.pageSize + 1,
        tempList = [];

      if (maxPage > this.totalPages) {
        maxPage = this.totalPages;
      }

      for (let i = minPage; i <= maxPage; i++) {
        tempList.push(i);
      }
      return tempList;
    }
  },
  methods: {
    setPage(currPage) {
      EventBus.$emit('setPage', currPage);
    },
    prevPage() {
      this.setPage(this.currPage - 1 === 0 ? 1 : this.currPage - 1);
    },
    nextPage() {
      this.setPage(this.currPage === this.totalPages ? this.totalPages : this.currPage + 1);
    },
    goFirstPage() {
      this.setPage(1);
    },
    goLastPage() {
      this.setPage(this.totalPages);
    },
    setPageInfo(currPage, pageSize, totalCnt) {
      this.currPage = currPage;
      this.pageSize = pageSize;
      this.totalCnt = totalCnt;
    }
  },
  created() {
    EventBus.$on('setPageInfo', this.setPageInfo);
  },
  beforeDestroy() {
    EventBus.$off('setPageInfo', this.setPageInfo);
  }
};
</script>
