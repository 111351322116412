<template>
  <div>
    <div id="lyNetworkbox" class="ly_networkbox3 ly_networkbox_2025" style="display: block;">
      <div class="tit">학원 네트워크</div>
      <ul>
        <li>
          <div class="branch_box">
            <p class="ttl">
              <a href="https://hyperacademy.etoos.com" target="_blank">의대생/서울대생이 만든 학원<span>강남하이퍼학원</span></a>
            </p>
            <ul>
              <li><a href="https://hyper-gangnam.etoos.com" target="_blank">강남하이퍼학원 본원</a></li>
              <li><a href="https://hyper-medical.etoos.com" target="_blank">강남하이퍼학원 의대관</a></li>
              <li><a href="https://hyper-mokdong.etoos.com" target="_blank">강남하이퍼학원 목동관</a></li>
              <li><a href="https://hyper-medicalgisuk.etoos.com" target="_blank">강남하이퍼기숙학원 의대관</a></li>
            </ul>
          </div>
        </li>
        <li>
          <div class="branch_box">
            <p class="ttl">
              <a href="https://cheongsol.etoos.com" target="_blank">개인별 맞춤학습<span>청솔학원</span></a>
            </p>
            <ul>
              <li><a href="https://cheongsol-gangbuk.etoos.com" target="_blank">강북청솔학원</a></li>
              <li><a href="https://cheongsol-bundang.etoos.com" target="_blank">분당청솔학원</a></li>
              <li><a href="https://cheongsol-pyeongchon.etoos.com" target="_blank">평촌청솔학원</a></li>
              <li><a href="https://cheongsol-ilsan.etoos.com/normal" target="_blank">일산청솔학원</a></li>
              <li><a href="https://cheongsol-bucheon.etoos.com" target="_blank">부천청솔학원</a></li>
            </ul>
          </div>
        </li>
        <li>
          <div class="branch_box">
            <p class="ttl">이투스가 만든 REAL ETOOS<span>이투스 기숙학원</span></p>
            <ul>
              <li><a href="https://gisuk.etoos.com" target="_blank">이투스 기숙학원 1호점</a></li>
            </ul>
          </div>
          <div class="branch_box">
            <p class="ttl">내 공부의 내공을 쌓는 학습 공간<span>청솔 네오관</span></p>
            <ul>
              <li><a href="https://cheongsol-ilsan-neo.etoos.com" target="_blank">일산청솔학원 네오관</a></li>
            </ul>
          </div>
          <div class="branch_box">
            <a href="https://247.etoos.com/index.do" target="_blank">
              <p class="ttl">담임관리형 자기주도학원<span>이투스247학원</span></p>
            </a>
          </div>
        </li>
      </ul>
      <div class="etoos_link"><a href="https://www.etoos.com" target="_blank" class="btn_etoos">이투스</a><a href="https://www.etoos.com/bookstore/" target="_blank" class="btn_etoosbook">온라인서점</a></div>
      <a href="#" class="btn_lyClose" @click="toggleAcaNetwork(false)"><span class="blind">팝업닫기</span></a>
    </div>
    <div class="bg_dimm" style=""></div>
  </div>
</template>
<script>
export default {
  name: 'AcaNetwork',
  methods: {
    toggleAcaNetwork(flag) {
      this.$emit('toggleAcaNetwork', flag);
    }
  }
};
</script>
