import MutationTypes from '../../MutationTypes';
import CommonApi from '../../../api/CommonApi';
import MobileDetect from 'mobile-detect';

export default {
  setIsMobile: async ({ commit }) => {
    // 모바일 여부
    const md = new MobileDetect(window.navigator.userAgent);
    commit(MutationTypes.SET_IS_MOBILE, md);
  },
  setLoading: async ({ commit }, flag) => {
    // 로딩 여부
    commit(MutationTypes.SET_LOADING, flag);
  },
  getUser: async ({ commit }) => {
    // 로그인 유저 정보
    try {
      const result = await CommonApi.getUserInfo();
      commit(MutationTypes.USER_INFO, result);
    } catch (e) {
      commit(MutationTypes.ERROR, e);
    }
  },
  setHeaderInfo: async ({ commit }, params) => {
    commit(MutationTypes.SET_HEADER_NAME, params.menuName);
    commit(MutationTypes.SET_HEADER_PREV, params.isPrev);
  },
  getMobileGnbMenu: async ({ commit }, params) => {
    const result = await CommonApi.getMobileGnbMenu(params);

    commit(MutationTypes.SET_MENU_HTML, result.data.menuHtml);
    commit(MutationTypes.SET_RECRUIT_JSON, result.data.recruitJson);
  },
  getMrCodeList: async ({ commit }, codeBtn) => {
    const result = await CommonApi.getMrCodeList(codeBtn);
    commit(MutationTypes.SET_CODE, result.data);
  },

  getMenuListData: async ({ commit }, request) => {
    const result = await CommonApi.getMenuListData(request);
    commit(MutationTypes.SET_MENU_LIST, result.value);
  },

  getRecruitData: async ({ commit }, request) => {
    const result = await CommonApi.getRecruitData(request);
    commit(MutationTypes.SET_RECRUIT, result.value);
  },

  getTimeTableData: async ({ commit }, request) => {
    const result = await CommonApi.getTimeTableData(request);
    commit(MutationTypes.SET_TIME_TABLE, result.value);
  },

  getMemNo: async ({ commit }, request) => {
    const result = await CommonApi.getMemNo(request);
    commit(MutationTypes.SET_MEM_NO, result.value);
  }
};
