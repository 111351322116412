<template>
  <div :class="{ pay_content: !isMobile }">
    <div class="dailymath dailymath_v2" :class="{ mobile: isMobile }">
      <h4 v-if="!isMobile"><span>수학 DM</span></h4>
      <div class="select_exam_system">
        <div class="inner">
          <div class="wrap_select_system">
            <select class="select_cont" v-model="unityExamSeq">
              <option v-for="(obj, key) in unityExamTests" :value="key" :key="key">{{ obj.name }}</option>
            </select>
          </div>
          <div class="wrap_select_system">
            <select class="select_cont" v-model="unityDtlExamSeq">
              <option v-for="(obj, key) in unityDtlExams" :value="key" :key="key"> {{ obj.name }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="daily_head">
        <div class="math_head">
          <ul>
            <li>
              <span>응시결과</span> <strong>{{ dailyTests.corAnswerCnt }}/{{ dailyTests.totalQuestionCnt }}</strong>
            </li>
            <li>
              <span>정답률</span>
              <strong>{{ parseFloat((dailyTests.corAnswerCnt / dailyTests.totalQuestionCnt) * 100 || 0).toFixed(1) }}%</strong>
            </li>
            <li>
              <span>나의취약영역</span>
              <strong class="ti">{{ dailyTests.mostWeekArea || '-' }}</strong>
            </li>
          </ul>
          <a class="mabtn exabtn cor" @click="goWransrPopup()">오답노트</a>
        </div>
      </div>
      <div class="math_list">
        <p class="tip">* 날짜를 클릭해 테스트를 응시할 수 있습니다.</p>
        <div class="list">
          <div class="list_body">
            <ul>
              <li v-for="(dailyTest, idx) in dailyTests.dailyTestResponses" :key="idx" :class="getTestListClass(dailyTest)" @click="clickLi(dailyTest.turnOrdSeq)">
                <span class="round">
                  <em class="tit_round">{{ dailyTest.turnOrd }}회</em>
                </span>
                <div v-if="dailyTest.inpAnsrCnt > 0">
                  <em>{{ getSlashDate(dailyTest.examDt) }}</em>
                  <span>{{ getSpanText(dailyTest) }}</span>
                  <div class="button">
                    <button @click="goDailyTestAnalysis(dailyTest)">성적분석</button>
                    <button @click="goTestPopup(dailyTest)">다시풀기</button>
                  </div>
                </div>
                <div v-else-if="dailyTest.examDt > today">
                  <em>{{ getSlashDate(dailyTest.examDt) }}</em>
                  <span>{{ getSpanText(dailyTest) }}</span>
                </div>
                <div v-else @click="goTestPopup(dailyTest)">
                  <em>{{ getSlashDate(dailyTest.examDt) }}</em>
                  <span>{{ getSpanText(dailyTest) }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div id="lay_sel_subj" class="layerPopup" :style="{ display: showSelSubjtPopup ? 'block' : 'none' }">
          <div class="ly_pop">
            <div class="inner">
              <p class="tip">선택과목을 선택해 주세요.</p>
              <p class="tit">수학</p>
              <div class="frame">
                <button v-for="(obj, idx) in childSubjectList" type="button" :class="{ active: selSubjtSeq === obj.unityExamSubjtSeq }" @click="selSubjtSeq = obj.unityExamSubjtSeq" :key="idx">
                  {{ obj.subjtNm }}
                </button>
              </div>
              <button type="button" class="comple" @click="goTestMobile()">선택</button>
            </div>
            <a class="close" @click="closeTestLayerPopup()"><span class="blind">레이어팝업 닫기</span></a>
          </div>
        </div>
        <div class="mgt10 list_dot">
          <ul class="clfix">
            <li class="f_r">서비스 환불 규정에 따라 수강료를 반환합니다. <a @click="showRefundGuidePopup = true">[환불 규정보기]</a></li>
          </ul>
        </div>
        <div id="laydaily" class="fex" :class="{ wrap_ly_pop: !isMobile, layerPopup: isMobile }" :style="{ display: showRefundGuidePopup ? 'block' : 'none' }">
          <template v-if="isMobile">
            <div class="title"><strong>수학 DM 환불 규정</strong></div>
            <div class="">
              <div class="inner pd20">
                <div class="tbl_lay">
                  <table>
                    <tbody>
                      <tr>
                        <th width="20%">환불</th>
                        <td class="txt_l">
                          아래의 경우에 환불이 불가능합니다.<br />1. 결제 후 1주일이 경과한 경우<br />2. 결제 후 1주일이 경과하지 않았으나 5회분 초과 응시한 경우<br />(단, 퇴원이나 코로나19 등 불가피한 사유로 서비스를 해지할 경우 미사용 회차 전액
                          환불 가능합니다.)
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <a class="close" @click="showRefundGuidePopup = false"><span class="blind">레이어팝업 닫기</span></a>
            </div>
          </template>
          <div v-else class="ly_pop">
            <div class="ly_header"><h5>수학 DM 환불 규정</h5></div>
            <div class="ly_body">
              <div class="tbl_lay">
                <table>
                  <tbody>
                    <tr>
                      <th width="20%">환불</th>
                      <td class="txt_l">
                        아래의 경우에 환불이 불가능합니다.<br />1. 결제 후 1주일이 경과한 경우<br />2. 결제 후 1주일이 경과하지 않았으나 5회분 초과 응시한 경우<br />(단, 퇴원이나 코로나19 등 불가피한 사유로 서비스를 해지할 경우 미사용 회차 전액 환불
                        가능합니다.)
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <a class="close" @click="showRefundGuidePopup = false"><span class="blind">레이어팝업 닫기</span></a>
          </div>
        </div>
      </div>
      <div v-if="!isMobile" class="mgt20">
        <img src="https://acaimg.etoos.com/cs/pc/branch/images/pay/banner_210326.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import { windowOpenPopup } from '@/mixins/windowOpenPopup';
import { PAGE_TITLE } from '../../../const/const';
import { AWS_S3_RESOURCE_URL } from '@build/build.config';

export default {
  name: 'DailyTest',
  mixins: [windowOpenPopup],
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      unityExamTests: (state) => state.unityexam.wransrnote.unityExamTests,
      dailyTests: (state) => state.dailytest.test.testList,
      lastTest: (state) => state.unityexam.wransrnote.lastTest,
      childSubjectList: (state) => state.dailytest.test.childSubjectList,
      mySubjectList: (state) => state.unityexam.wransrnote.mySubjectList,
      userInfo: (state) => state.common.userInfo
    })
  },
  created() {
    this.setHeaderInfo({ menuName: '수학 DM', isPrev: false });
    if (!this.userInfo.studentCd) {
      this.getUser().then(() => {
        this.init();
      });
    } else {
      this.init();
    }
    this.changeTitle();
    this.loadCSS(AWS_S3_RESOURCE_URL + '/math-daily/static/css/math_daily.css?ver=1');
  },
  beforeDestroy() {
    // 스타일 시트 삭제 함수 호출
    this.removeCSS('math_daily.css');
  },
  watch: {
    unityExamSeq() {
      this.unityDtlExams = {};
      Object.keys(this.unityExamTests[this.unityExamSeq]).forEach((a) => {
        if (a !== 'name') {
          this.unityDtlExams[a] = this.unityExamTests[this.unityExamSeq][a];
        }
      });
      if (Object.keys(this.lastTest || {}).length > 0) {
        this.unityDtlExamSeq = this.lastTest.unityDtlExamSeq;
        this.$store.commit('SET_LAST_TEST', {});
      } else if (Object.keys(this.unityDtlExams).length > 0) {
        this.unityDtlExamSeq = Object.keys(this.unityDtlExams)[0];
      }
    },
    unityDtlExamSeq: {
      immediate: true,
      handler(seq) {
        this.currentTest = this.unityDtlExams[seq];
        this.getMyMathDmInfo({
          studentCd: this.userInfo.studentCd,
          branchCd: this.userInfo.branchCd,
          unityDtlExamSeq: seq
        });
      }
    }
  },
  data() {
    return {
      showSelSubjtPopup: false,
      showRefundGuidePopup: false,
      today: '',
      unityExamSeq: '',
      unityDtlExamSeq: '',
      unityDtlExams: {},
      currentTest: {},
      openTest: {},
      selSubjtSeq: 0,
      selectTurnOrdSeq: 0
    };
  },
  methods: {
    ...mapActions(['getUser', 'getMathDmTest', 'setHeaderInfo', 'getMyMathDmInfo', 'getChildSubject', 'getStdSelSubjectByYear']),
    changeTitle() {
      let title = '';
      if (this.userInfo.branchCd !== '') title = PAGE_TITLE[this.userInfo.branchCd];
      document.title = 'mypage_main | ' + title;
    },
    init() {
      this.today = moment().format('YYYYMMDD');
      this.getStdSelSubjectByYear({ year: moment().format('YYYY'), studentCd: this.userInfo.studentCd });
      this.getMathDmTest({ studentCd: this.userInfo.studentCd, branchCd: this.userInfo.branchCd }).then(() => {
        if (Object.keys(this.lastTest || {}).length > 0) {
          this.unityExamSeq = this.lastTest.unityExamSeq;
        }
      });
      window.reloadTestInfo = () => {
        this.reloadTestInfo();
      };
    },
    getTestListClass(dailyTest) {
      let classname = '';
      // 응시한 경우
      if (dailyTest.inpAnsrCnt > 0) {
        classname += 'finish';
      } else if (dailyTest.examDt > this.today) {
        classname += '';
      } else if (dailyTest.examDt === this.today) {
        classname += 'today';
      } else {
        classname += ' possible';
      }

      if (dailyTest.turnOrdSeq === this.selectTurnOrdSeq) {
        classname += ' active';
      }

      return classname.trim();
    },
    getSpanText(dailyTest) {
      if (dailyTest.inpAnsrCnt > 0) {
        return `응시완료 ${dailyTest.corAnsrCnt}/${dailyTest.inpAnsrCnt}`;
      }

      return '응시가능';
    },
    getSlashDate(date) {
      return date ? moment(date).format('M/D') : '';
    },
    goDailyTestAnalysis(dailyTest) {
      const route = this.$router.resolve({ name: 'AnalysisResult', query: { selectTurnOrdSeq: dailyTest.turnOrdSeq } });
      this.openWindowPopup(route.href, this.$route.name, 'AnalysisResult', this.locationReload, 'width=990, height=800, scrollbars=1', this.isMobile);
    },
    goWransrPopup() {
      for (const argument of this.dailyTests.dailyTestResponses) {
        if (argument.coransrNotesYn) {
          const currentTurnOrdTest = this.currentTest[argument.turnOrdSeq];
          let showAll = null;
          if (this.isMobile) {
            showAll = 'Y';
          }
          const route = this.$router.resolve({
            name: 'unityExamWranNotePopup',
            query: {
              examYy: currentTurnOrdTest.unityExamYy,
              unityExamSeq: this.unityExamSeq,
              unityDtlExamSeq: currentTurnOrdTest.unityDtlExamSeq,
              unityDtlExamTurnOrdSeq: currentTurnOrdTest.unityDtlExamTurnOrdSeq,
              subjtSeq: argument.selSubjSeq,
              showAll
            }
          });
          this.openWindowPopup(route.href, this.$route.name, 'mathDailyTest', this.locationReload, 'width=990, height=800, scrollbars=1', this.isMobile);
          return;
        }
      }
      alert('저장된 오답노트가 없습니다');
    },
    goTestPopup(dailyTest) {
      if (this.isMobile) {
        this.openTest = dailyTest;
        this.getChildSubject(this.openTest.turnOrdSeq).then(() => {
          this.selSubjtSeq = 0;
          if (this.openTest.selSubjSeq) {
            this.selSubjtSeq = Number(this.openTest.selSubjSeq);
          }
          if (this.selSubjtSeq === 0) {
            for (const subject of this.mySubjectList || []) {
              for (const childSubject of this.childSubjectList || []) {
                if (Number(childSubject.byyySubjtSeq) === Number(subject.byyySubjtSeq)) {
                  this.selSubjtSeq = childSubject.unityExamSubjtSeq;
                }
                if (this.selSubjtSeq !== 0) {
                  break;
                }
              }
            }
          }
          if (this.selSubjtSeq === 0 && (this.childSubjectList || []).length === 1) {
            this.selSubjtSeq = this.childSubjectList[0].unityExamSubjtSeq;
          }
          if (this.selSubjtSeq !== 0) {
            this.goTestMobile();
            return;
          }
          this.showSelSubjtPopup = true;
        });
        return;
      }
      const currentTurnOrdTest = this.currentTest[dailyTest.turnOrdSeq];
      const route = this.$router.resolve({
        name: 'mathDailyTest',
        query: {
          examYy: currentTurnOrdTest.unityExamYy,
          unityExamSeq: this.unityExamSeq,
          unityDtlExamSeq: currentTurnOrdTest.unityDtlExamSeq,
          unityDtlExamTurnOrdSeq: currentTurnOrdTest.unityDtlExamTurnOrdSeq,
          turnOrdSeq: dailyTest.turnOrdSeq,
          selSubjtSeq: dailyTest.selSubjSeq
        }
      });
      this.openWindowPopup(route.href, this.$route.name, 'mathDailyTest', this.locationReload, 'width=990, height=800, scrollbars=1', this.isMobile);
    },
    goTestMobile() {
      const route = this.$router.resolve({
        name: 'mathDailyTest',
        query: {
          turnOrdSeq: this.openTest.turnOrdSeq,
          selSubjtSeq: this.selSubjtSeq
        }
      });
      this.openWindowPopup(route.href, this.$route.name, 'mathDailyTest', this.locationReload, 'width=990, height=800, scrollbars=1', this.isMobile);
    },
    closeTestLayerPopup() {
      this.showSelSubjtPopup = false;
      this.openTest = {};
    },
    clickLi(seq) {
      this.selectTurnOrdSeq = seq;
    },
    reloadTestInfo() {
      this.getMyMathDmInfo({
        studentCd: this.userInfo.studentCd,
        branchCd: this.userInfo.branchCd,
        unityDtlExamSeq: this.unityDtlExamSeq
      });
    },
    loadCSS(url) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = url;
      document.head.appendChild(link);
    },
    removeCSS(fileName) {
      const stylesheets = document.querySelectorAll('link[rel="stylesheet"]');
      for (const stylesheet of stylesheets) {
        if (stylesheet.href.includes(fileName)) {
          stylesheet.parentNode.removeChild(stylesheet);
        }
      }
    }
  }
};
</script>
