<template>
  <div id="footer">
    <template v-if="userInfo.branchCd === '0500'">
      <div class="inner">
        <ul class="link">
          <li><a href="https://recruit.etoos.com" target="_blank">인재채용</a></li>
          <li><a href="https://www.etoos.com/member/yakkwan_new.asp" target="_blank">이용약관</a></li>
          <li class="policy">
            <a href="https://www.etoos.com/member/individual_new.asp" target="_blank">개인정보처리방침</a>
          </li>
        </ul>
        <div class="company_info">
          <p>
            <span>(10416) 경기도 고양시 일산동구 일산로 197(마두동 736-1) 4층</span>
            <span>사업자등록번호 : 217-85-25116</span>
            <span>Tel : 031-818-4545</span>
            <span>Fax : 031-696-9945</span>
          </p>
          <p>
            <span>이투스네오일산학원 학원등록번호 : 제5837호 교습과정 : 종합-보습, 입시, 진학상담·지도</span>
            <span>이투스네오일산독서실 학원등록번호 : 제5836호 교습과정 : 독서실</span>
          </p>
          <p>
            <span>(10416) 경기도 고양시 일산동구 일산로 219(마두동 738) 5층</span>
            <span>사업자등록번호 : 711-85-01877</span>
            <span>Tel : 031-907-2345</span>
            <span>Fax : 031-696-9945</span>
          </p>
          <p>
            <span>이투스네오일산1학원 학원등록번호 : 제3786호 교습과정 : 입시</span>
          </p>
          <p>
            <span style="color:white; font-weight:bold;">이투스에듀 주식회사</span>
            <span>(06733) 서울시 서초구 남부순환로 2547 (서초동 1354-3)</span>
            <span>Tel : 02-552-1230</span>
            <span>Fax : 02-552-1325</span>
            <span>사업자등록번호 : 505-87-02372</span>
            <span>
              통신판매신고번호 : 강남-03036호
              <a href="javascript:void(0);" @click="openBizInfo('5058702372')">정보조회</a>
            </span>
          </p>
          <p>
            <span>대표이사 : 정선욱</span>
            <span>개인정보보호책임자 : 정미상</span>
          </p>
        </div>
        <p class="copy">
          copyright ⓒ ETOOS EDUCATION Co.,Ltd. All rights Reserved.
          <a href="mailto:cheongsol_web@etoos.com">cheongsol_web@etoos.com</a>
        </p>
        <p class="logo">
          <img src="https://acaimg.etoos.com/cs/pc/branch/images/common/foot_logo_ilsan_neo.gif" alt="" />
        </p>
      </div>
    </template>
    <template v-else-if="userInfo.branchCd === '0030'">
      <!-- 강북 -->
      <div class="inner">
        <ul class="link">
          <li><a href="https://recruit.etoos.com" target="_blank">인재채용</a></li>
          <li><a href="https://www.etoos.com/member/yakkwan_new.asp" target="_blank">이용약관</a></li>
          <li class="policy">
            <a href="https://www.etoos.com/member/individual_new.asp" target="_blank">개인정보처리방침</a>
          </li>
        </ul>
        <div class="company_info">
          <p>
            <span>(01734) 서울시 노원구 한글비석로 242 삼부프라자(중계동 364-19) 4층~6층, 8층</span>
            <span> 사업자등록번호 : 376-85-02348, 166-85-01908, 833-85-01731, 279-85-02009</span>
            <span>Tel : 02-934-9001</span>
            <span>Fax : 02-934-9020 </span>
          </p>
          <p>
            <span>청솔학원 학원등록번호 : 제2006-28호 교습과정 : 보습</span>
            <span>강북청솔독서실 등록번호 : 제2018-5호 교습과정 : 독서실</span>
          </p>
          <p>
            <span style="color:white; font-weight:bold;">이투스에듀 주식회사</span>
            <span>(06733) 서울시 서초구 남부순환로 2547 (서초동 1354-3)</span>
            <span>Tel : 02-552-1230</span>
            <span>Fax : 02-552-1325</span>
            <span>사업자등록번호 : 505-87-02372</span>
            <span>
              통신판매신고번호 : 강남-03036호
              <a href="javascript:void(0);" @click="openBizInfo('5058702372')">정보조회</a>
            </span>
          </p>
          <p>
            <span>대표이사 : 정선욱</span>
            <span>개인정보보호책임자 : 정미상</span>
          </p>
        </div>
        <p class="copy">
          copyright ⓒ ETOOS EDUCATION Co.,Ltd. All rights Reserved.
          <a href="mailto:cheongsol_web@etoos.com">cheongsol_web@etoos.com</a>
        </p>
        <p class="logo">
          <img src="https://img.cheongsol.co.kr/web2018/cs/branch/images/common/foot_logo_gangbuk.gif" alt="" />
        </p>
      </div>
    </template>
    <template v-else-if="userInfo.branchCd === '0011'">
      <!-- 강북 -->
      <div class="inner">
        <ul class="link">
          <li><a href="https://recruit.etoos.com" target="_blank">인재채용</a></li>
          <li><a href="https://www.etoos.com/member/yakkwan_new.asp" target="_blank">이용약관</a></li>
          <li class="policy">
            <a href="https://www.etoos.com/member/individual_new.asp" target="_blank">개인정보처리방침</a>
          </li>
        </ul>
        <div class="company_info">
          <p>
            <span>(01734) 서울시 노원구 한글비석로 242 삼부프라자(중계동 364-19) 4층~6층, 8층</span>
            <span> 사업자등록번호 : 376-85-02348, 166-85-01908, 833-85-01731, 279-85-02009</span>
            <span>Tel : 02-934-9001</span>
            <span>Fax : 02-934-9020 </span>
          </p>
          <p>
            <span>청솔학원 학원등록번호 : 제2006-28호 교습과정 : 보습</span>
            <span>강북청솔독서실 등록번호 : 제2018-5호 교습과정 : 독서실</span>
          </p>
          <p>
            <span style="color:white; font-weight:bold;">이투스에듀 주식회사</span>
            <span>(06733) 서울시 서초구 남부순환로 2547 (서초동 1354-3)</span>
            <span>Tel : 02-552-1230</span>
            <span>Fax : 02-552-1325</span>
            <span>사업자등록번호 : 505-87-02372</span>
            <span>
              통신판매신고번호 : 강남-03036호
              <a href="javascript:void(0);" @click="openBizInfo('5058702372')">정보조회</a>
            </span>
          </p>
          <p>
            <span>대표이사 : 정선욱</span>
            <span>개인정보보호책임자 : 정미상</span>
          </p>
        </div>
        <p class="copy">
          copyright ⓒ ETOOS EDUCATION Co.,Ltd. All rights Reserved.
          <a href="mailto:cheongsol_web@etoos.com">cheongsol_web@etoos.com</a>
        </p>
        <p class="logo">
          <img src="https://img.cheongsol.co.kr/web2018/cs/branch/images/common/foot_logo_gangbuk.gif" alt="" />
        </p>
      </div>
    </template>
    <template v-else-if="userInfo.branchCd === '0050'">
      <!-- 분당 -->
      <div class="inner">
        <ul class="link">
          <li><a href="https://recruit.etoos.com" target="_blank">인재채용</a></li>
          <li><a href="https://www.etoos.com/member/yakkwan_new.asp" target="_blank">이용약관</a></li>
          <li class="policy">
            <a href="https://www.etoos.com/member/individual_new.asp" target="_blank">개인정보처리방침</a>
          </li>
        </ul>
        <div class="company_info">
          <p>
            <span>(13566) 경기도 성남시 분당구 성남대로 779번길 16(이매동 105-5),</span>
            <span>사업자등록번호 : 733-85-01902</span>
            <span>Tel : 031-708-9001</span>
            <span>Fax : 031-708-0140</span>
          </p>
          <p>
            <span>분당청솔학원 학원등록번호 : 제2305호 교습과정 : 종합-입시, 진학지도</span>
            <span>분당청솔1관학원 학원등록번호 : 제6264호 교습과정 : 입시</span>
            <span>분당청솔단과학원 학원등록번호 : 제6265호 교습과정 : 보습</span>
          </p>
          <p>
            <span>(13566) 경기도 성남시 분당구 성남대로 779번길 14(이매동 105-6),</span>
            <span>사업자등록번호 : 514-85-29876</span>
            <span>Tel : 031-708-9001</span>
            <span>Fax : 031-708-0140</span>
          </p>
          <p>
            <span>분당청솔라이브러리독서실 학원등록번호 : 제5781호 교습과정 : 독서실</span>
          </p>
          <p>
            <span style="color:white; font-weight:bold;">이투스에듀 주식회사</span>
            <span>(06733) 서울시 서초구 남부순환로 2547 (서초동 1354-3)</span>
            <span>Tel : 02-552-1230</span>
            <span>Fax : 02-552-1325</span>
            <span>사업자등록번호 : 505-87-02372</span>
            <span>
              통신판매신고번호 : 강남-03036호
              <a href="javascript:void(0);" @click="openBizInfo('5058702372')">정보조회</a>
            </span>
          </p>
          <p>
            <span>대표이사 : 정선욱</span>
            <span>개인정보보호책임자 : 정미상</span>
          </p>
        </div>
        <p class="copy">
          copyright ⓒ ETOOS EDUCATION Co.,Ltd. All rights Reserved.
          <a href="mailto:cheongsol_web@etoos.com">cheongsol_web@etoos.com</a>
        </p>
        <p class="logo">
          <img src="https://img.cheongsol.co.kr/web2018/cs/branch/images/common/foot_logo_bundang.gif" alt="" />
        </p>
      </div>
    </template>
    <template v-else-if="userInfo.branchCd === '0090'">
      <!-- 평촌 -->
      <div class="inner">
        <ul class="link">
          <li><a href="https://recruit.etoos.com" target="_blank">인재채용</a></li>
          <li><a href="https://www.etoos.com/member/yakkwan_new.asp" target="_blank">이용약관</a></li>
          <li class="policy">
            <a href="https://www.etoos.com/member/individual_new.asp" target="_blank">개인정보처리방침</a>
          </li>
        </ul>
        <div class="company_info">
          <p>
            <span>(14099) 경기도 안양시 동안구 평촌대로 121 문원빌딩 (지번주소_호계동 1065-3)</span>
            <span>사업자등록번호 292-85-02036</span>
            <span>Tel : 031-383-9001</span>
            <span>Fax : 031-476-9003</span>
          </p>
          <p>
            <span>평촌청솔학원 학원등록번호 : 제2003-082호 교습과정 : 입시·논술, 실용외국어, 진학지도·상담</span>
            <span>평촌청솔2관학원 학원등록번호 : 제2020-043호 교습과정 : 입시·논술</span>
          </p>
          <p>
            <span>(14103) 경기도 안양시 동안구 평촌대로 112 정진빌딩(평촌동 922-8) 5층</span>
            <span>사업자등록번호 572-85-01914</span>
            <span>Tel : 031-383-9002</span>
            <span>Fax : 031-476-9003</span>
          </p>
          <p>
            <span>평촌청솔고등부카이로스학원 학원등록번호 : 제2017-021호 교습과정 : 보습·논술</span>
          </p>
          <p>
            <span style="color:white; font-weight:bold;">이투스에듀 주식회사</span>
            <span>(06733) 서울시 서초구 남부순환로 2547 (서초동 1354-3)</span>
            <span>Tel : 02-552-1230</span>
            <span>Fax : 02-552-1325</span>
            <span>사업자등록번호 : 505-87-02372</span>
            <span>
              통신판매신고번호 : 강남-03036호
              <a href="javascript:void(0);" @click="openBizInfo('5058702372')">정보조회</a>
            </span>
          </p>
          <p>
            <span>대표이사 : 정선욱</span>
            <span>개인정보보호책임자 : 정미상</span>
          </p>
        </div>
        <p class="copy">
          copyright ⓒ ETOOS EDUCATION Co.,Ltd. All rights Reserved.
          <a href="mailto:cheongsol_web@etoos.com">cheongsol_web@etoos.com</a>
        </p>
        <p class="logo">
          <img src="https://img.cheongsol.co.kr/web2018/cs/branch/images/common/foot_logo_pyeongchon.gif" alt="" />
        </p>
      </div>
    </template>
    <template v-else-if="userInfo.branchCd === '0080'">
      <!-- 일산 -->
      <div class="inner">
        <ul class="link">
          <li><a href="https://recruit.etoos.com" target="_blank">인재채용</a></li>
          <li><a href="https://www.etoos.com/member/yakkwan_new.asp" target="_blank">이용약관</a></li>
          <li class="policy">
            <a href="https://www.etoos.com/member/individual_new.asp" target="_blank">개인정보처리방침</a>
          </li>
        </ul>
        <div class="company_info">
          <p>
            <span>(10386) 경기도 고양시 일산서구 주화로 50(주엽동 104)</span>
            <span>사업자등록번호 : 809-85-01946</span>
            <span>Tel : 031-903-9001</span>
            <span>Fax : 031-903-9099</span>
          </p>
          <p>
            <span>일산청솔학원 학원등록번호 : 제2769호 교습과정 : 종합-입시, 진학지도</span>
            <span>일산청솔1관학원 학원등록번호 : 제5955호 교습과정 : 입시</span>
          </p>
          <p>
            <span>일산청솔2관학원 학원등록번호 : 제5956호 교습과정 : 입시</span>
          </p>
          <p>
            <span style="color:white; font-weight:bold;">이투스에듀 주식회사</span>
            <span>(06733) 서울시 서초구 남부순환로 2547 (서초동 1354-3)</span>
            <span>Tel : 02-552-1230</span>
            <span>Fax : 02-552-1325</span>
            <span>사업자등록번호 : 505-87-02372</span>
            <span>
              통신판매신고번호 : 강남-03036호
              <a href="javascript:void(0);" @click="openBizInfo('5058702372')">정보조회</a>
            </span>
          </p>
          <p>
            <span>대표이사 : 정선욱</span>
            <span>개인정보보호책임자 : 정미상</span>
          </p>
        </div>
        <p class="copy">
          copyright ⓒ ETOOS EDUCATION Co.,Ltd. All rights Reserved.
          <a href="mailto:cheongsol_web@etoos.com">cheongsol_web@etoos.com</a>
        </p>
        <p class="logo">
          <img src="https://img.cheongsol.co.kr/web2018/cs/branch/images/common/foot_logo_ilsan.gif" alt="" />
        </p>
      </div>
    </template>
    <template v-else-if="userInfo.branchCd === '0040'">
      <!-- 부천 -->
      <div class="inner">
        <ul class="link">
          <li><a href="https://recruit.etoos.com" target="_blank">인재채용</a></li>
          <li><a href="https://www.etoos.com/member/yakkwan_new.asp" target="_blank">이용약관</a></li>
          <li class="policy">
            <a href="https://www.etoos.com/member/individual_new.asp" target="_blank">개인정보처리방침</a>
          </li>
        </ul>
        <div class="company_info">
          <p>
            <span>(14623) 경기도 부천시 원미구 부일로 221번길 52 (지번주소_상동 453-1)</span>
            <span>사업자등록번호 : 683-85-02305</span>
            <span>Tel : 032-611-9001</span>
            <span>Fax : 032-328-0003</span>
          </p>
          <p>
            <span>부천청솔입시학원 학원등록번호 : 제4708호 교습과정 : 입시</span>
            <span>부천청솔1관입시학원 학원등록번호 : 제6420호 교습과정 : 입시</span>
          </p>
          <p>
            <span style="color:white; font-weight:bold;">이투스에듀 주식회사</span>
            <span>(06733) 서울시 서초구 남부순환로 2547 (서초동 1354-3)</span>
            <span>Tel : 02-552-1230</span>
            <span>Fax : 02-552-1325</span>
            <span>사업자등록번호 : 505-87-02372</span>
            <span>
              통신판매신고번호 : 강남-03036호
              <a href="javascript:void(0);" @click="openBizInfo('5058702372')">정보조회</a>
            </span>
          </p>
          <p>
            <span>대표이사 : 정선욱</span>
            <span>개인정보보호책임자 : 정미상</span>
          </p>
        </div>
        <p class="copy">
          copyright ⓒ ETOOS EDUCATION Co.,Ltd. All rights Reserved.
          <a href="mailto:cheongsol_web@etoos.com">cheongsol_web@etoos.com</a>
        </p>
        <p class="logo">
          <img src="https://img.cheongsol.co.kr/web2018/cs/branch/images/common/foot_logo_bucheon.gif" alt="" />
        </p>
      </div>
    </template>
    <template v-else>
      <!-- 강청기숙 -->
      <div class="inner">
        <div class="inner">
          <ul class="link">
            <li><a href="https://www.etoos.com/member/yakkwan_new.asp" target="_blank">이용약관</a></li>
            <li class="policy"><a href="https://www.etoos.com/member/individual_new.asp">개인정보처리방침</a></li>
          </ul>

          <!-- CompanyInfo File -->
          <div class="company_info">
            <p>
              <span>(12745) 경기도 광주시 초월읍 설월길 8(지번주소_지월리 550-2)</span>
              <span>사업자등록번호 : 123-85-74627</span>
              <span>Tel: 031-843-9001</span>
              <span>Fax: 031-843-9992</span>
            </p>
            <p>
              <span><span>이투스 기숙학원</span> 학원등록번호 : 제2150호 교습과정 : 입시</span>
            </p>
            <p>
              <span><span class="company_name">이투스에듀 주식회사</span> (06733) 서울시 서초구 남부순환로 2547 (서초동 1354-3)</span>
              <span>Tel : 02-552-1230</span>
              <span>Fax : 02-552-1325</span>
              <span>사업자등록번호 : 505-87-02372</span>
              <span>통신판매신고번호 : 강남-03036호 <a href="javascript:void(0);" onclick="openBizInfo('5058702372');">정보조회</a></span>
            </p>
            <p>
              <span>대표이사 : 정선욱</span>
              <span>개인정보보호책임자 : 정미상</span>
            </p>
          </div>
          <p class="copy">copyright ⓒ ETOOS EDUCATION Co.,Ltd. All rights Reserved. <a href="mailto:cheongsol_web@etoos.com">cheongsol_web@etoos.com</a></p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Footera',
  props: ['userInfo'],
  mounted() {},
  methods: {
    openBizInfo(obj) {
      var url = 'https://www.ftc.go.kr/info/bizinfo/communicationViewPopup.jsp?wrkr_no=' + obj;
      window.open(url, 'communicationViewPopup', 'width=750, height=700, scrollbars=yes;');
    }
  }
};
</script>
