<template>
  <div class="takemath_pop dailymath_pop_v2 popup_cont popup_ansr" :class="{ mobile: isMobile }">
    <div class="popup_head">
      <h1 class="tit_popup">성적분석</h1>
      <div class="expander" v-show="!isMobile">
        <div class="wrap_select">
          <select class="select_cont" v-model="unityExamSeq">
            <option v-for="(obj, key) in unityExamTests" :value="key" :key="key">{{ obj.name }}</option>
          </select>
        </div>
        <div class="wrap_select">
          <select class="select_cont" v-model="unityDtlExamSeq">
            <option v-for="(obj, key) in unityDtlExams" :value="key" :key="key">{{ obj.name }}</option>
          </select>
        </div>
        <div class="wrap_select">
          <select class="select_cont auto" v-model="unityExamTurnOrdSeq">
            <option v-for="(obj, key) in Object.keys(unityTurnOrdExams || {})" :value="unityTurnOrdExams[obj].unityDtlExamTurnOrdSeq" :key="key">{{ `${obj}회 (${getHypeDate((unityTurnOrdExams[obj].data || {}).unityExamDt || '')})` }}</option>
          </select>
        </div>
        <div class="wrap_select">
          <select class="select_cont">
            <option>{{ (currentTest || {}).subjtNm || '' }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="popup_body">
      <div class="mo_only">
        <div class="exam_info">
          <span class="txt_exam" style="width: 100%">{{ testTitle }}</span>
        </div>
      </div>

      <div class="math_head">
        <ul>
          <li>
            <span>응시일</span> <strong>{{ analysisTest.examDt }}</strong>
          </li>
          <li>
            <span>응시결과</span>
            <strong>{{ `${analysisTest.corAnswerCnt}/${analysisTest.inpAnswerCnt}` }}<em>(정답/문항)</em></strong>
          </li>
          <li>
            <span>정답률</span>
            <strong>{{ ((analysisTest.corAnswerCnt / analysisTest.inpAnswerCnt) * 100).toFixed(1) }}%</strong>
          </li>
          <li>
            <span>응시시간</span>
            <strong v-if="analysisTest.qustnSolvSec > 0">
              {{
                Math.floor(analysisTest.qustnSolvSec / 60)
                  .toFixed(0)
                  .padStart(2, '0')
              }}분 {{ String(analysisTest.qustnSolvSec % 60).padStart(2, '0') }}초
            </strong>
            <strong v-else>
              --분 --초
            </strong>
          </li>
        </ul>
        <div class="exabtn">
          <a class="mabtn" @click="goDailyTest()">다시풀기</a>
          <a class="mabtn" @click="goTestResult()">응시결과</a>
          <a class="mabtn cor_v2" @click="goWransrNote()">오답노트</a>
        </div>
      </div>
      <div class="math_take">
        <h3>문항별 결과 분석</h3>
        <table class="tbl_report">
          <colgroup>
            <col width="8%" />
            <col width="32%" />
            <col width="12%" />
            <col width="*" />
            <col width="12%" />
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>문항분류</th>
              <th>정오표</th>
              <th>정오답원인</th>
              <th>오답노트</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(obj, key) in analysisTest.questions" :key="key" :class="{ check: obj.coransrNotesYn === 'Y' }">
              <td>{{ obj.qustnNo }}</td>
              <td>{{ obj.qustnType }}</td>
              <td>{{ obj.corAnsrYn === 'Y' ? 'O' : 'X' }}</td>
              <td>{{ (obj.corAnsrYn === 'Y' ? obj.coransrCauseNm : obj.wransrCauseNm) || '-' }}</td>
              <td>
                <label class="checkbox">
                  <input type="checkbox" :checked="obj.coransrNotesYn === 'Y'" @change="changeRegiWransrNote(obj, $event)" />
                  <span></span>
                </label>
              </td>
            </tr>
          </tbody>
        </table>
        <h3>나의 취약 영역</h3>
        <table class="tbl_report" v-if="(analysisTest.weekAreas || []).length > 0">
          <colgroup>
            <col width="20%" />
            <col width="*" />
            <col width="20%" />
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>취약 영역</th>
              <th>오답문항</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(obj, idx) in analysisTest.weekAreas">
              <td>{{ idx + 1 }}</td>
              <td>{{ obj.weekAreaName }}</td>
              <td>{{ (obj.qustnNos || []).join(', ') }}</td>
            </tr>
          </tbody>
        </table>
        <div class="nomath_take" v-else>
          <div>
            해당 회차에 틀린 문항이 없습니다. <span><strong>문제 다시풀기</strong>로</span> 오늘 공부한 내용을 복습해 보세요.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import { PAGE_TITLE } from '../../../const/const';

export default {
  name: 'AnalysisResult',
  data() {
    return {
      unityExamSeq: 0,
      unityDtlExamSeq: 0,
      unityExamTurnOrdSeq: 0,
      unityDtlExams: {},
      unityTurnOrdExams: {},
      currentTest: {}
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      userInfo: (state) => state.common.userInfo,
      analysisTest: (state) => state.dailytest.test.analysisTest,
      lastTest: (state) => state.unityexam.wransrnote.lastTest,
      unityExamTests: (state) => state.unityexam.wransrnote.unityExamTests
    }),
    testTitle() {
      const list = [];
      if (this.currentTest.unityExamNm) {
        list.push(this.currentTest.unityExamNm);
      }
      if (this.currentTest.unityDtlExamNm) {
        list.push(this.currentTest.unityDtlExamNm);
      }

      if (this.currentTest.unityDtlExamTurnOrd) {
        let result = this.currentTest.unityDtlExamTurnOrd + '회';
        if (this.currentTest.unityExamDt) {
          result += ` (${this.getHypeDate(this.currentTest.unityExamDt)})`;
        }
        list.push(result);
      }
      if (this.currentTest.subjtNm) {
        list.push(this.currentTest.subjtNm);
      }

      return list.join(' > ');
    }
  },
  watch: {
    unityExamSeq() {
      this.unityDtlExams = {};
      Object.keys(this.unityExamTests[this.unityExamSeq]).forEach((a) => {
        if (a !== 'name') {
          this.unityDtlExams[a] = this.unityExamTests[this.unityExamSeq][a];
        }
      });
      const unityDtlExamKeys = Object.keys(this.unityDtlExams);
      if (unityDtlExamKeys.length > 0) {
        if (Object.keys(this.lastTest || {}).length > 0) {
          this.unityDtlExamSeq = this.lastTest.unityDtlExamSeq;
          return;
        }
        this.unityDtlExamSeq = unityDtlExamKeys[0];
      }
    },
    unityDtlExamSeq() {
      this.unityTurnOrdExams = {};
      Object.keys(this.unityDtlExams[this.unityDtlExamSeq]).forEach((a) => {
        if (a !== 'name') {
          this.unityTurnOrdExams[a] = this.unityDtlExams[this.unityDtlExamSeq][a];
        }
      });
      const unityTurnOrdExamKeys = Object.keys(this.unityTurnOrdExams);
      if (unityTurnOrdExamKeys.length > 0) {
        if (Object.keys(this.lastTest || {}).length > 0) {
          this.unityExamTurnOrdSeq = this.lastTest.unityDtlExamTurnOrdSeq;
          this.$store.commit('SET_LAST_TEST', {});
          return;
        }
        this.unityExamTurnOrdSeq = this.unityTurnOrdExams[unityTurnOrdExamKeys[0]].unityDtlExamTurnOrdSeq;
      }
    },
    unityExamTurnOrdSeq(seq) {
      Object.keys(this.unityTurnOrdExams).forEach((key) => {
        const exam = this.unityTurnOrdExams[key];
        if (Number(exam.unityDtlExamTurnOrdSeq) === Number(seq)) {
          this.currentTest = exam.data;
        }
      });
      this.getMathDmTargetTestInfo({ studentCd: this.userInfo.studentCd, turnOrdSeq: seq });
    }
  },
  methods: {
    ...mapActions(['getUser', 'getMathDmResolveTest', 'getMathDmTargetTestInfo', 'postChangeRegiWransrNote']),
    changeTitle() {
      let title = '';
      if (this.userInfo.branchCd !== '') title = PAGE_TITLE[this.userInfo.branchCd];
      document.title = 'mypage_수학DM - 성적분석 | ' + title;
    },
    init() {
      const selectTurnOrdSeq = this.$route.query.selectTurnOrdSeq;
      this.getMathDmResolveTest({
        studentCd: this.userInfo.studentCd,
        branchCd: this.userInfo.branchCd,
        selectTurnOrdSeq
      }).then(() => {
        const unityExamKeys = Object.keys(this.unityExamTests);
        if (unityExamKeys.length > 0) {
          if (Object.keys(this.lastTest || {}).length > 0) {
            this.unityExamSeq = this.lastTest.unityExamSeq;
            return;
          }
          this.unityExamSeq = unityExamKeys[0];
        }
      });
    },
    getHypeDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    goDailyTest() {
      this.$router.push({
        name: 'mathDailyTest',
        query: {
          turnOrdSeq: this.currentTest.unityDtlExamTurnOrdSeq,
          selSubjtSeq: this.analysisTest.unityExamSubjtSeq
        }
      });
    },
    goTestResult() {
      this.$router.push({
        name: 'mathDailyTestResult',
        query: { turnOrdSeq: this.currentTest.unityDtlExamTurnOrdSeq },
        params: { selectByyySubjtSeq: this.analysisTest.byyySubjtSeq }
      });
    },
    goWransrNote() {
      const hasCoransrNote = this.analysisTest.questions.some((question) => {
        return question.coransrNotesYn === 'Y';
      });
      if (!hasCoransrNote) {
        alert('오답노트 문항이 없습니다.');
        return;
      }
      this.$router.push({
        name: 'unityExamWranNotePopup',
        query: {
          examYy: this.currentTest.unityExamYy,
          unityExamSeq: this.unityExamSeq,
          unityDtlExamSeq: this.unityDtlExamSeq,
          unityDtlExamTurnOrdSeq: this.unityExamTurnOrdSeq,
          subjtSeq: this.analysisTest.unityExamSubjtSeq
        }
      });
    },
    changeRegiWransrNote(obj, e) {
      const checked = e.target.checked === true;
      obj.coransrNotesYn = checked ? 'Y' : 'N';
      const params = {};
      params.coransrNotesYn = checked ? 'Y' : 'N';
      params.unityExamQustnSeq = obj.unityExamQustnSeq;
      params.bystdntUnityExamQustnInpStusSeq = obj.bystdntUnityExamQustnInpStusSeq;
      this.postChangeRegiWransrNote(params);
    }
  },
  created() {
    if (!this.userInfo.studentCd) {
      this.getUser().then(() => {
        this.init();
      });
    } else {
      this.init();
    }
    this.changeTitle();
  },
  updated() {
    const element = document.querySelector('link[href*="/classroom/css"]');
    if (element !== null) {
      element.remove();
    }
  }
};
</script>
