<template>
  <div>
    <div id="wrapper">
      <div id="wrap">
        <div id="container">
          <!-- 컨텐츠 영역 -->
          <div class="mypage_comm">
            <div class="mo_only">
              <div class="box_tab">
                <div class="tab_navi size2">
                  <ul class="list_tab">
                    <li v-for="tab in tabs" :class="{ on: tab.id === activeTab }" :key="tab.id">
                      <a href="#" @click.prevent="tabToggle(tab)" class="btn_tab">
                        <span class="inner">{{ tab.name }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="tab_cont">
                  <div id="tab1" class="panel_tab" :class="{ on: activeTab === tabs[0].id }" v-if="activeTab === tabs[0].id">
                    <div class="box_filter">
                      <div class="list_selected">
                        <em class="txt_item">{{ seasonNm }}</em>
                        <em class="txt_item">{{ subjectNames }}</em>
                        <em class="txt_item">{{ teacherNames }}</em>
                        <em class="txt_item">{{ dayNames }}</em>
                        <em class="txt_item">{{ perdNames }}</em>
                      </div>
                      <button type="button" class="btn_filter" @click="filterLayers('show')">
                        <span class="blind">필터 열기</span>
                      </button>
                    </div>
                    <lecture-search-jae
                      :lectureDataJae="getLectureSearchData('tab1')"
                      :seasonList="seasonLists"
                      :pageLen="15"
                      :branchCd="branchCd"
                      :studentCd="studentCd"
                      :groupYear="groupYear"
                      :seasonCd="seasonCd"
                      :seasonPerdMap="seasonPerdMap"
                      @reload="componentReload"
                      @detailPop="detailPopup"
                      v-if="renderComponent"
                    />
                  </div>
                  <div id="tab2" class="panel_tab" :class="{ on: activeTab === tabs[1].id || activeTab === tabs[2].id }" v-else>
                    <div class="box_filter">
                      <div class="list_selected">
                        <em class="txt_item">{{ coursesSdate }}~{{ coursesEdate }}</em>
                        <em class="txt_item">{{ subjectNames }}</em>
                        <em class="txt_item">{{ teacherNames }}</em>
                        <em class="txt_item">{{ dayNames }}</em>
                        <em class="txt_item">{{ perdNames }}</em>
                      </div>
                      <button type="button" class="btn_filter" @click="filterLayers('show')">
                        <span class="blind">필터 열기</span>
                      </button>
                    </div>
                    <lecture-search-dan
                      :lectureDataDan="getLectureSearchData('tab2')"
                      :seasonList="seasonLists"
                      :pageLen="15"
                      :branchCd="branchCd"
                      :studentCd="studentCd"
                      :groupYear="groupYear"
                      :seasonCd="seasonCd"
                      :seasonPerdMap="seasonPerdMap"
                      @reload="componentReload"
                      @detailPop="detailPopup"
                      v-if="renderComponent"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- //컨텐츠 영역 -->
        </div>
        <!-- //Container -->
        <a href="#" class="btn_top">
          <span><em class="blind">TOP</em></span>
        </a>
      </div>
    </div>

    <!-- Layer -->
    <!-- 필터 -->
    <div id="filterLayer" class="layer_sidepage filter" :class="{ open: filterView === true }">
      <div class="layer_body">
        <div class="layer_head">
          <h4 class="tit_layer">필터</h4>
          <div class="expander">
            <button type="button" class="btn_refresh" @click="searchReset()">
              <span class="blind">새로고침</span>
            </button>
          </div>
          <a href="javascript:;" class="layer_close" @click="filterLayers('hide')">
            <span class="blind">레이어 닫기</span>
          </a>
        </div>
        <div class="layer_cont">
          <div class="item_filter" v-show="this.activeTab === this.tabs[1].id || this.activeTab === this.tabs[2].id">
            <button type="button" class="btn_toggle">
              <strong class="tit_cate">운영기간</strong>
              <em class="selected">{{ coursesSdate }} ~ {{ coursesEdate }}</em>
            </button>
            <div class="panel">
              <div class="datepicker_div">
                <div class="wrap_input">
                  <b-datepicker v-model="fromDate" :min-date="fromMinDate" :max-date="fromMaxDate" size="is-small" range />
                </div>
                <div class="wrap_input">
                  <b-datepicker v-model="toDate" :min-date="toMinDate" :max-date="toMaxDate" size="is-small" range />
                </div>
              </div>
            </div>
          </div>

          <div class="item_filter on" v-show="this.activeTab === 'tab1'">
            <button type="button" class="btn_toggle">
              <strong class="tit_cate">시즌</strong>
              <em class="selected">시즌{{ seasonCd }}</em>
            </button>
            <div class="panel">
              <ul class="list_item">
                <li v-for="(season, idx) in seasonLists" :key="idx">
                  <label class="lbl_item">
                    <strong class="tit_item">{{ season.seasonNm }}</strong>
                    <input type="radio" class="inp_radio" id="seasonCd" :value="season.seasonCd" v-model="seasonCd" @change="chkChange($event)" />
                    <i class="ico_comm ico_radio"></i>
                  </label>
                </li>
              </ul>
            </div>
          </div>

          <div class="item_filter">
            <button type="button" class="btn_toggle">
              <strong class="tit_cate">과목</strong>
              <em class="selected">{{ subjectNames }}</em>
            </button>
            <div class="panel">
              <ul class="list_item">
                <li>
                  <label class="lbl_item">
                    <strong class="tit_item">전체</strong>
                    <input type="checkbox" class="inp_check" id="subjects" value="" v-model="subjects" @change="chkChange($event)" />
                    <i class="ico_comm ico_check"></i>
                  </label>
                </li>
                <li v-for="(sub, idx) in getSubjects()" :key="idx">
                  <label class="lbl_item">
                    <strong class="tit_item">{{ sub.subjtNm }}</strong>
                    <input type="checkbox" class="inp_check" id="subjects" :value="sub.subjtCd" v-model="subjects" @change="chkChange($event)" />
                    <i class="ico_comm ico_check"></i>
                  </label>
                </li>
              </ul>
            </div>
          </div>

          <div class="item_filter">
            <button type="button" class="btn_toggle">
              <strong class="tit_cate">강사</strong>
              <em class="selected">{{ teacherNames }}</em>
            </button>
            <div class="panel">
              <ul class="list_item">
                <li>
                  <label class="lbl_item">
                    <strong class="tit_item">전체</strong>
                    <input type="checkbox" class="inp_check" id="teachers" value="" v-model="teachers" @change="chkChange($event)" />
                    <i class="ico_comm ico_check"></i>
                  </label>
                </li>
                <li v-for="(tc, idx) in getTeachersFilter()" :key="idx">
                  <label class="lbl_item">
                    <strong class="tit_item">{{ tc.teacherName }}</strong>
                    <input type="checkbox" class="inp_check" id="teachers" :value="tc.teacherCd" v-model="teachers" @change="chkChange($event)" />
                    <i class="ico_comm ico_check"></i>
                  </label>
                </li>
              </ul>
            </div>
          </div>

          <div class="item_filter">
            <button type="button" class="btn_toggle">
              <strong class="tit_cate">요일</strong>
              <em class="selected">{{ dayNames }}</em>
            </button>
            <div class="panel">
              <div class="daypicker">
                <div class="allday">
                  <label class="lbl_item">
                    <input type="checkbox" class="inp_check" id="days" value="" v-model="days" @change="chkChange($event)" />
                    <strong class="tit_item"><span class="inner">전체</span></strong>
                  </label>
                </div>
                <ul class="list_day">
                  <li>
                    <label class="lbl_item">
                      <input type="checkbox" class="inp_check" id="days" value="mon" v-model="days" @change="chkChange($event)" />
                      <strong class="tit_item"><span class="inner">월</span></strong>
                    </label>
                  </li>
                  <li>
                    <label class="lbl_item">
                      <input type="checkbox" class="inp_check" id="days" value="tue" v-model="days" @change="chkChange($event)" />
                      <strong class="tit_item"><span class="inner">화</span></strong>
                    </label>
                  </li>
                  <li>
                    <label class="lbl_item">
                      <input type="checkbox" class="inp_check" id="days" value="wed" v-model="days" @change="chkChange($event)" />
                      <strong class="tit_item"><span class="inner">수</span></strong>
                    </label>
                  </li>
                  <li>
                    <label class="lbl_item">
                      <input type="checkbox" class="inp_check" id="days" value="thu" v-model="days" @change="chkChange($event)" />
                      <strong class="tit_item"><span class="inner">목</span></strong>
                    </label>
                  </li>
                  <li>
                    <label class="lbl_item">
                      <input type="checkbox" class="inp_check" id="days" value="fri" v-model="days" @change="chkChange($event)" />
                      <strong class="tit_item"><span class="inner">금</span></strong>
                    </label>
                  </li>
                  <li>
                    <label class="lbl_item">
                      <input type="checkbox" class="inp_check" id="days" value="sat" v-model="days" @change="chkChange($event)" />
                      <strong class="tit_item"><span class="inner">토</span></strong>
                    </label>
                  </li>
                  <li>
                    <label class="lbl_item">
                      <input type="checkbox" class="inp_check" id="days" value="sun" v-model="days" @change="chkChange($event)" />
                      <strong class="tit_item"><span class="inner">일</span></strong>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="item_filter">
            <button type="button" class="btn_toggle">
              <strong class="tit_cate">교시</strong>
              <em class="selected">{{ perdNames }}</em>
            </button>
            <div class="panel">
              <ul class="list_item">
                <li>
                  <label class="lbl_item">
                    <strong class="tit_item">전체</strong>
                    <input type="checkbox" class="inp_check" name="perdCds" id="perdCds" value="" v-model="perdCds" @change="chkChange($event)" />
                    <i class="ico_comm ico_check"></i>
                  </label>
                </li>
                <template v-for="(item, idx) in scheduleList">
                  <li v-if="item.perdCd.indexOf('r') === -1" :key="idx">
                    <label class="lbl_item">
                      <strong class="tit_item">{{ item.exposureNm }}</strong>
                      <input type="checkbox" class="inp_check" name="perdCds" :id="'perd' + idx" :value="item.perdCd" v-model="perdCds" @change="chkChange($event)" />
                      <i class="ico_comm ico_check"></i>
                    </label>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="dimmed_layer" @click="filterLayers('hide')"></div>
    </div>
    <!-- //필터 -->
    <!-- //Layer -->
    <lecture-detail-m v-if="isMobile && isCheckPopupD" :checkParam="checkParamD" @close="isCheckPopupD = false" />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import LectureSearchJae from './LectureSearchJaeM';
import LectureSearchDan from './LectureSearchDanM';
import moment from 'moment';
import Uimypage from '@/utils/ui_mypage';
import lectureDetailM from '@/components/classroom/lecture/LectureDetailM';
import TmsCookieUtil from '@/utils/TmsCookieUtil';
import { PAGE_TITLE } from '../../../const/const';

export default {
  name: 'lectureSearch',
  data() {
    return {
      filterView: false,
      activeTab: 'tab1',
      tabs: [
        { id: 'tab1', name: '재종반 강좌', lectureTypeCd: 1 },
        { id: 'tab2', name: '단과', lectureTypeCd: 2 },
        { id: 'tab3', name: '특강', lectureTypeCd: 3 }
      ],
      branchCd: '',
      lectureTypeCd: '',
      groupYear: '',
      studentCd: '',
      seasonCd: '',
      seasonNm: '',
      subjects: [''], //과목
      teachers: [''], //선생님
      days: [''], //요일
      perdCds: [''], //교시
      perdNm: [], //교시 정보
      renderComponent: true,
      teacherNames: '전체강사',
      subjectNames: '전체과목',
      dayNames: '전체요일',
      perdNames: '전체교시',
      seasonLists: [],
      fromDate: [],
      toDate: [],
      coursesSdate: '',
      coursesEdate: '',
      toDay: '', //현재날짜
      lectureReload: '',
      fromMinDate: new Date(),
      fromMaxDate: new Date(),
      toMinDate: new Date(),
      toMaxDate: new Date(),
      defaultSeason: '',
      subjectList: [],
      teacherList: [],
      paramPerdCd: '',
      paramWeekCd: '',
      introFlag: true,
      checkParamD: {},
      isCheckPopupD: false
    };
  },
  props: ['close', 'searchPopupParam'],
  created() {
    this.setHeaderInfo({ menuName: '강좌검색', isPrev: false });
    if (!this.userInfo.studentCd) {
      this.getUser().then(() => {
        this.activeTab = 'tab1';
        this.setDefaultParams();
        this.setDefaultValue();
        this.getLectureType().then(() => {
          const tabs = [];
          for (const [key, value] of Object.entries(this.lectureTypeMap)) {
            tabs.push({ id: `tab${key}`, name: value, lectureTypeCd: key });
          }
          tabs.sort((a, b) => {
            return a.lectureTypeCd - b.lectureTypeCd;
          });
          this.tabs = tabs;
        });
      });
    } else {
      this.activeTab = 'tab1';
      this.setDefaultParams();
      this.setDefaultValue();
      this.getLectureType().then(() => {
        const tabs = [];
        for (const [key, value] of Object.entries(this.lectureTypeMap)) {
          tabs.push({ id: `tab${key}`, name: value, lectureTypeCd: key });
        }
        tabs.sort((a, b) => {
          return a.lectureTypeCd - b.lectureTypeCd;
        });
        this.tabs = tabs;
      });
    }
    this.changeTitle();
  },
  mounted() {
    Uimypage.mypageUI.init();
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      userInfo: (state) => state.common.userInfo,
      lectureSearchDataJae: (state) => state.classroom.lecture.lectureSearchDataJae,
      lectureSearchDataDan: (state) => state.classroom.lecture.lectureSearchDataDan,
      seasonList: (state) => state.classroom.schedule.seasonList,
      teacherData: (state) => state.classroom.lecture.teacherData,
      lectureTypeMap: (state) => state.classroom.lecture.lectureTypeMap,
      scheduleList: (state) => state.classroom.schedule.scheduleList,
      seasonPerdMap: (state) => state.classroom.schedule.seasonPerdMap
    })
  },
  watch: {
    lectureSearchDataJae() {
      this.forceRerender();
    },
    lectureSearchDataDan() {
      this.forceRerender();
    },
    teachers(arg1) {
      this.teacherNames = '';
      const teacher = [];
      if (arg1.length > 0) {
        arg1.forEach((cd) => {
          if (cd !== '') {
            this.getTeachersFilter().forEach((item2) => {
              if (cd === item2.teacherCd) teacher.push(item2.teacherName);
            });
          } else {
            teacher.push('전체강사');
          }
        });
      } else {
        this.teachers = [''];
        teacher.push('전체강사');
      }
      this.teacherNames = teacher.join(' ');
    },
    subjects(arg1) {
      this.subjectNames = '';
      const subject = [];
      if (arg1.length > 0) {
        arg1.forEach((cd) => {
          if (cd !== '') {
            this.getSubjects().forEach((sub) => {
              if (cd === sub.subjtCd) subject.push(sub.subjtNm);
            });
          } else {
            subject.push('전체과목');
          }
        });
      } else {
        this.subjects = [''];
        subject.push('전체과목');
      }
      this.subjectNames = subject.join(' ');
    },
    days(arg1) {
      this.dayNames = '';
      const day = [];
      if (arg1.length > 0) {
        arg1.forEach((cd) => {
          if (cd !== '') {
            this.days.forEach((d) => {
              if (cd === d) day.push(this.weekFilter(d).substring(0, 1));
            });
          } else {
            day.push('전체요일');
          }
        });
      } else {
        this.days = [''];
        day.push('전체요일');
      }

      this.dayNames = day.join(' ');
    },
    perdCds(arg1) {
      this.perdNames = '';
      const perd = [];
      if (arg1.length > 0) {
        _.chain(arg1)
          .sortBy()
          .each((cd) => {
            if (cd !== '') {
              this.perdCds.forEach((d) => {
                if (cd === d) {
                  const seasonCdMap = this.seasonPerdMap[this.groupYear] || {};
                  const perdMap = seasonCdMap[this.seasonCd] || {};
                  const name = perdMap[cd] || `${cd}교시`;
                  perd.push(name);
                }
              });
            } else if (arg1.length === 1) {
              perd.push('전체');
            }
          })
          .value();
      } else {
        this.perdCds = [''];
        perd.push('전체교시');
      }
      this.perdNames = perd.join(',');
    },
    fromDate(arg1, arg2) {
      this.coursesSdate = moment(arg1).format('YYYY.MM.DD');
      if (this.seasonCd !== '' && arg1.toString() !== '' && arg2.toString() !== '') this.getLectureSearchs(this.activeTab, false);
    },
    toDate(arg1, arg2) {
      this.coursesEdate = moment(arg1).format('YYYY.MM.DD');
      if (this.seasonCd !== '' && arg1.toString() !== '' && arg2.toString() !== '') this.getLectureSearchs(this.activeTab, false);
    }
  },
  methods: {
    ...mapActions(['getLectureSearchListJae', 'getLectureSearchListDan', 'getSeason', 'getTeacherList', 'getUser', 'getLectureType', 'getScheduleList', 'setHeaderInfo']),
    changeTitle() {
      let title = '';
      if (this.userInfo.branchCd !== '') title = PAGE_TITLE[this.userInfo.branchCd];
      document.title = 'mypage_수강신청 - 강좌검색 | ' + title;
    },
    setDefaultValue() {
      this.toDay = moment().format('YYYYMMDD');
      this.tabs.forEach((tab) => {
        if (this.activeTab === tab.id) {
          this.lectureTypeCd = tab.lectureTypeCd;
        }
      });
    },
    //검색항목 show, hide
    filterLayers(view) {
      if (view === 'show') {
        this.filterView = true;
      } else {
        this.filterView = false;
      }
    },
    //탭메뉴 전환
    tabToggle(tab) {
      this.activeTab = tab.id;
      this.subjects = [''];
      this.teachers = [''];
      //this.days = ['']      //수강신청에서 넘어오는 값으로 셋 (days, perdCds)
      //this.perdCds = ['']
      //this.searchReset();       //검색항목 리셋
      this.setDefaultValue();
      if (this.seasonCd !== '') {
        this.getLectureSearchs(tab.id, true);
        this.getLectureSearchData(tab.id);
      }
    },
    async setDefaultParams() {
      if (this.$route.params.searchPopupParam !== undefined) {
        this.branchCd = this.$route.params.searchPopupParam.branchCd;
        this.studentCd = this.$route.params.searchPopupParam.studentCd;
        this.groupYear = this.$route.params.searchPopupParam.seasonYear;

        await this.getSeasons();
        if (this.$route.params.searchPopupParam.lectureTypeCd) {
          this.activeTab = 'tab' + this.$route.params.searchPopupParam.lectureTypeCd;
        } else {
          this.activeTab = 'tab1';
        }

        if (this.$route.params.searchPopupParam.week) {
          this.referrer = 'week';
          if (this.$route.params.searchPopupParam.perdCd !== '') {
            this.paramPerdCd = this.$route.params.searchPopupParam.perdCd;
            const index = this.perdCds.indexOf('');
            if (index > -1) this.perdCds.splice(index, 1);
          }
          this.perdCds.push(this.$route.params.searchPopupParam.perdCd);
          if (this.$route.params.searchPopupParam.week !== '') {
            this.paramWeekCd = this.$route.params.searchPopupParam.week;
            const index = this.days.indexOf('');
            if (index > -1) this.days.splice(index, 1);
          }
          this.days.push(this.$route.params.searchPopupParam.week);
        }

        //시즌코드 쿠키에 저장 (페이지여서 전체 리프레시하면 정보 유실)
        let lecSeason = this.$route.params.searchPopupParam.seasonYear + ',' + this.$route.params.searchPopupParam.seasonCd;
        if (this.$route.params.searchPopupParam.week) {
          lecSeason += ',' + this.$route.params.searchPopupParam.week;
          lecSeason += ',' + this.$route.params.searchPopupParam.perdCd;
        } else {
          lecSeason += ',';
          lecSeason += ',';
        }
        TmsCookieUtil.setCookie('lecSeason', btoa(lecSeason));
      } else {
        this.branchCd = this.userInfo.branchCd;
        this.studentCd = this.userInfo.studentCd;

        if (TmsCookieUtil.getCookie('lecSeason') !== '') {
          this.getLecSeason();
        } else {
          // 쿠키 없고 직접 접속시 수강현황으로 보냄
          this.$router.push('schedulemain');
        }
      }
    },
    async getLecSeason() {
      if (TmsCookieUtil.getCookie('lecSeason') !== '') {
        const lecSeason = atob(TmsCookieUtil.getCookie('lecSeason'));

        this.groupYear = lecSeason.split(',')[0];
        await this.getSeasons();
        const cPerd = lecSeason.split(',')[3];
        const cWeek = lecSeason.split(',')[2];

        if (cWeek !== '') {
          if (cPerd !== '') {
            this.paramPerdCd = cPerd;
            const index = this.perdCds.indexOf('');
            if (index > -1) this.perdCds.splice(index, 1);
          }
          this.perdCds.push(cPerd);

          if (cWeek !== '') {
            this.paramWeekCd = cWeek;
            const index = this.days.indexOf('');
            if (index > -1) this.days.splice(index, 1);
          }
          this.days.push(cWeek);
        }
      }
    },
    //강좌내역 요청
    getLectureSearchs(tab, flag) {
      const params = {};
      params.branchCd = this.branchCd;
      params.studentCd = this.studentCd;
      params.groupYear = this.groupYear;
      params.lectureTypeCd = this.lectureTypeCd;

      if (tab === 'tab1') {
        params.seasonCd = this.seasonCd;
        if (!this.introFlag) {
          params.subjtCds = this.subjects.join(',');
          params.teacherCds = this.teachers.join(',');
          params.week = this.days.join(',');
          params.perdCds = this.perdCds.join(',');
        }
        this.getLectureSearchListJae(params).finally(() => {
          if (flag) this.subjectList = this.getLectureSubjectList();
          const nm = this.seasonLists.find((e) => (e.seasonCd === this.seasonCd ? e : ''));
          this.seasonNm = nm.seasonNm;
        });
      } else {
        params.coursesSdate = this.coursesSdate.replace(/\./gi, '');
        params.coursesEdate = this.coursesEdate.replace(/\./gi, '');

        if (!this.introFlag) {
          params.subjtCds = this.subjects.join(',');
          params.teacherCds = this.teachers.join(',');
          params.week = this.days.join(',');
          params.perdCds = this.perdCds.join(',');
        }

        this.getLectureSearchListDan(params).finally(() => {
          if (flag) this.subjectList = this.getLectureSubjectList();
        });
      }
    },
    //강좌검색 내역 sorting
    getLectureSearchData(tab) {
      let lectureData = [];
      if (tab === 'tab1') {
        const resultData = [];
        _.chain(this.lectureSearchDataJae)
          .sortBy('groupSdate')
          .sortBy('subjtCd')
          .sortBy(function(e) {
            return e.regstateCd === 1 || e.regstateCd === 6 || e.regstateCd === 7 ? 1 : 2;
          })
          .filter((e) => {
            //요일, 교시에 대한 내용 필터
            let flag = 0;
            if (this.introFlag && this.paramPerdCd !== '' && this.paramWeekCd !== '') {
              e.schedules.forEach((sch) => {
                if (this.paramPerdCd === sch.perdCd && this.paramWeekCd === sch.week) ++flag;
              });
              return flag > 0 ? true : false;
            } else {
              return true;
            }
          })
          .each(function(item) {
            resultData.push(item);
          })
          .value();
        lectureData = resultData;
      } else {
        const resultData = [];
        _.chain(this.lectureSearchDataDan)
          .sortBy('groupSdate')
          .sortBy('subjtCd')
          .sortBy(function(e) {
            return e.regstateCd === 1 || e.regstateCd === 6 || e.regstateCd === 7 ? 1 : 2;
          })
          .filter((e) => {
            //요일, 교시에 대한 내용 필터
            let flag = 0;
            if (this.introFlag && this.paramPerdCd !== '' && this.paramWeekCd !== '') {
              e.schedules.forEach((sch) => {
                if (this.paramPerdCd === sch.perdCd && this.paramWeekCd === sch.week) ++flag;
              });
              return flag > 0 ? true : false;
            } else {
              return true;
            }
          })
          .each(function(item) {
            resultData.push(item);
          })
          .value();
        lectureData = resultData;
      }

      return lectureData;
    },
    //전체 과목정보, 강사 정보를 담는다.
    getLectureSubjectList() {
      let lectureData = [];
      if (this.activeTab === 'tab1') {
        const resultData = [];
        _.chain(this.lectureSearchDataJae)
          .sortBy(function(e) {
            return e.regstateCd === '1' || e.regstateCd === '6' ? 1 : 2;
          })
          .each(function(item) {
            resultData.push(item);
          })
          .value();
        lectureData = resultData;
      } else {
        const resultData = [];
        _.chain(this.lectureSearchDataDan)
          .sortBy(function(e) {
            return e.regstateCd === '1' || e.regstateCd === '6' ? 1 : 2;
          })
          .each(function(item) {
            resultData.push(item);
          })
          .value();
        lectureData = resultData;
      }

      return lectureData;
    },
    //시즌정보 호출
    async getSeasons() {
      const params = {};
      params.branchCd = this.branchCd;
      params.seasonYear = this.groupYear;
      params.studentCd = this.studentCd;
      await this.getSeason(params);
      await this.setSeasonsInfo();
    },
    //시즌정보 셋
    async setSeasonsInfo() {
      const toDay = moment().format('YYYYMMDD');
      const arrSea = [];
      let arr = {};
      const lecSeason = atob(TmsCookieUtil.getCookie('lecSeason'));

      const paramSeasonCd = this.$route.params.searchPopupParam !== undefined ? this.$route.params.searchPopupParam.seasonCd : lecSeason.split(',')[1];

      for (const item of this.seasonList) {
        arr = {};
        if (item.seasonEndDt <= toDay) {
          item.confirm = 'Y';
        }
        if (item.seasonCd === paramSeasonCd) {
          arr.seasonCd = item.seasonCd;
          arr.seasonNm = item.seasonNm;
          arr.seasonStartDt = item.seasonStartDt;
          arr.seasonEndDt = item.seasonEndDt;
          arr.studyRegStartDt = item.studyRegStartDt;
          arr.studyRegEndDt = item.studyRegEndDt;
          arr.studyConfirmStartDt = item.studyConfirmStartDt;
          arr.studyConfirmEndDt = item.studyConfirmEndDt;
          arr.studyModStartDt = item.studyModStartDt;
          arr.studyModEndDt = item.studyModEndDt;
          arr.seasonYear = item.seasonYear;
          arr.indvdlEnrolmentYn = item.indvdlEnrolmentYn;
          arr.isEnrolment = item.isEnrolment;

          const fromDt = new Date(moment(item.seasonStartDt).format());
          const toDt = new Date(moment(item.seasonEndDt).format());

          this.seasonCd = item.seasonCd;
          this.seasonNm = item.seasonNm;
          this.fromDate = fromDt;
          this.toDate = toDt;

          //선택 가능한 날짜 제한
          this.fromMinDate = fromDt;
          this.fromMaxDate = toDt;
          this.toMinDate = fromDt;
          this.toMaxDate = toDt;

          arrSea.push(arr);
          const params = {};
          params.branchCd = this.branchCd;
          params.seasonCd = this.seasonCd;
          params.seasonYear = item.seasonYear;
          await this.getScheduleList(params);
        }
      }

      this.seasonLists = arrSea;
      this.coursesSdate = moment(this.fromDate).format('YYYY.MM.DD');
      this.coursesEdate = moment(this.toDate).format('YYYY.MM.DD');
      if (this.seasonCd !== '') this.getLectureSearchs(this.activeTab, true);
    },
    //과목정보 추출
    getSubjects() {
      //const data = this.getLectureSearchData(this.activeTab);
      const data = this.subjectList;
      const arrDt = [];
      let arr = {};
      data.forEach((item) => {
        arr = {};
        arr.subjtCd = item.subjtCd;
        arr.subjtNm = item.subjtNm;
        arrDt.push(arr);
      });
      const uniqueArray = arrDt.filter((thing, index) => {
        return (
          index ===
          arrDt.findIndex((obj) => {
            return JSON.stringify(obj) === JSON.stringify(thing);
          })
        );
      });

      return uniqueArray.sort((a, b) => (a['subjtCd'] < b['subjtCd'] ? -1 : 0));
    },
    //강사정보 추출 - 강좌내역에서 추출
    getTeachersFilter() {
      //const data = this.getLectureSearchData(this.activeTab);
      const data = this.subjectList;

      const arrDt = [];
      let arr = {};
      data.forEach((item) => {
        arr = {};
        arr.subjtCd = item.subjtCd;
        arr.teacherCd = item.teacherCd;
        arr.teacherName = item.teacherName;
        arrDt.push(arr);
      });
      const uniqueArray1 = arrDt.filter((thing, index) => {
        return (
          index ===
          arrDt.findIndex((obj) => {
            return obj.teacherCd === thing.teacherCd;
          })
        );
      });
      uniqueArray1.sort((a, b) => (a['teacherName'] < b['teacherName'] ? -1 : 0));

      const fil = this.subjects.reduce((acc, cur) => {
        arrDt.forEach((tech) => {
          if (cur === tech.subjtCd) acc.push(tech);
        });
        return acc;
      }, []);

      const uniqueArray2 = fil.filter((thing, index) => {
        return (
          index ===
          fil.findIndex((obj) => {
            return obj.teacherCd === thing.teacherCd;
          })
        );
      });
      uniqueArray2.sort((a, b) => (a['teacherName'] < b['teacherName'] ? -1 : 0));

      return this.subjects[0] === '' ? uniqueArray1 : uniqueArray2;
    },
    //강사정보 요청 : DB에서 가져옴 미사용
    getTeachers() {
      const params = {};
      params.branchCd = this.branchCd;
      this.subjects.forEach((item) => {
        params.subjectCds = item.join(',');
      });
      params.groupYear = this.groupYear;
      params.lectureTypeCd = this.lectureTypeCd;

      this.getTeacherList(params);
    },
    //검색항목 이벤트 처리
    chkChange(e) {
      this.introFlag = false;
      if (e.target.value !== '') {
        if (e.target.id === 'days') {
          const index = this.days.indexOf('');
          if (index > -1) {
            this.days.splice(index, 1);
          }
        } else if (e.target.name === 'perdCds') {
          const index = this.perdCds.indexOf('');
          if (index > -1) {
            this.perdCds.splice(index, 1);
          }
        } else if (e.target.id === 'teachers') {
          const index = this.teachers.indexOf('');
          if (index > -1) {
            this.teachers.splice(index, 1);
          }
        } else if (e.target.id === 'subjects') {
          const index = this.subjects.indexOf('');
          if (index > -1) {
            this.subjects.splice(index, 1);
          }
        }
      } else {
        if (e.target.id === 'days') {
          this.days = [''];
        } else if (e.target.name === 'perdCds') {
          this.perdCds = [''];
        } else if (e.target.id === 'teachers') {
          this.teachers = [''];
        } else if (e.target.id === 'subjects') {
          this.subjects = [''];
          if (this.teachers[0] === '') this.getSubjects();
        }
      }

      if (this.seasonCd !== '' && e.target.id === 'seasonCd') {
        this.subjects = [''];
        this.teachers = [''];
        this.getLectureSearchs(this.activeTab, true);
      } else {
        this.getLectureSearchs(this.activeTab, false);
      }
    },
    weekFilter(val) {
      switch (val) {
        case 'mon':
          return '월요일';
        case 'tue':
          return '화요일';
        case 'wed':
          return '수요일';
        case 'thu':
          return '목요일';
        case 'fri':
          return '금요일';
        case 'sat':
          return '토요일';
        case 'sun':
          return '일요일';
        default:
          '';
          break;
      }
    },
    //컴포넌트 Rerendering
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    searchReset() {
      this.subjects = [''];
      this.teachers = [''];
      this.perdCds = [''];
      this.days = [''];

      //쿠키에 값이 있는지 확인
      this.getLecSeason();

      this.getLectureSearchs(this.activeTab, false);
    },
    componentReload(e) {
      this.getLectureSearchs(e, false);
      this.forceRerender();
    },
    detailPopup(data) {
      const params = {};
      params.groupName = data.groupName;
      params.teacherName = data.teacherName;
      params.grpIdx = data.grpIdx;
      params.branchCd = this.branchCd;
      params.subjt = data.subjt;
      params.groupYear = this.groupYear;
      this.isCheckPopupD = true;
      this.checkParamD = params;
    }
  },
  components: {
    LectureSearchJae,
    LectureSearchDan,
    lectureDetailM
  }
};
</script>
