<template>
  <div class="pay_content pass">
    <!--[D]: 현장강의 무한패스의 경우 pass 클래스 추가 -->
    <h4>
      <span>오늘 업데이트 된 강의</span>
      <span class="new"> <img src="https://acaimg.etoos.com/my/pc/v2/images/mypage/badge_new.png" alt="new" /> ({{ updateDate }} 기준) </span>
    </h4>
    <!-- search box 영역 -->
    <search-table ref="searchTable" :num-per-page="numPerPage" :api-path="todaySearchApiPath" @search="onSearch" @loadingStart="isLoading = true" />
    <!-- table 영역 -->
    <infinite-pass-table-component
      :menuTitle="'오늘 업데이트된 강의'"
      :headers="tableHeaders"
      :items="todayCurriVodList"
      :rowDisable="(item) => item.viewYn === 'N'"
      :is-loading="isLoading"
    >
      <template v-slot:[`item.groupName`]="{ item }">
        <badge-box
          :unity-subjt-cd="item.unitySubjtCd"
          :subjt-nm="item.subjtNm"
          :lecture-type-cd="item.lectureTypeCd"
          :cls-middle="item.clsMiddle"
        />
        <span v-html="item.groupName"></span>
      </template>
      <template v-slot:[`item.curriTurnOrd`]="{ item }">{{ item.turnOrd }}회차</template>
      <template v-slot:[`item.viewBtn`]="{ item }">
        <a @click="onViewClick(item)">보기&gt;</a>
      </template>
    </infinite-pass-table-component>
  </div>
</template>

<script>
import SearchTable from './components/SearchTableP.vue';
import InfinitePassTableComponent from './components/InfinitePassTableP.vue';
import InfinitePassApi from '@/api/InfinitePassApi';
import EventBus from '../../utils/eventBus';
import BadgeBox from './components/BadgeBox.vue';
import { PAGE_TITLE } from '../../const/const';
import { mapState } from 'vuex';

/**
 * 오늘 업데이트된 강의 보기 화면
 * @since 2023.03.23
 * @author 유경호 ykh6242@etoos.com
 */
export default {
  name: 'today-lecture-view',
  components: {
    BadgeBox,
    InfinitePassTableComponent,
    SearchTable
  },
  data: () => ({
    /*
     * 검색 테이블 관련 properties
     */
    todaySearchApiPath: '/api/infinitePass/update/list',
    /*
     * 무한패스 테이블 컴포넌트 관련 properties
     */
    tableHeaders: [
      { text: '강좌명', value: 'groupName', txtLeft: true, sortable: true },
      { text: '강사명', value: 'teacherName', width: '9%', sortable: true },
      { text: '강의실', value: 'groupRoom', width: '9%', sortable: true },
      { text: '회차', value: 'curriTurnOrd', width: '11%' },
      { text: '강의 보기', value: 'viewBtn', width: '9%' }
    ],
    todayCurriVodList: [],
    /*
     * 검색 테이블 컴포넌트 + 무한패스 테이블 컴포넌트 공통 properties
     */
    numPerPage: 10,
    /**
     * table loading 노출관리
     */
    isLoading: false
  }),
  computed: {
    updateDate: {
      get() {
        const date = new Date();
        date.setDate(date.getDate() - 1);
        const localeString = date.toLocaleString('ko-KR', { dateStyle: 'short' });
        return `20${localeString}`;
      }
    },
    ...mapState({
      userInfo: (state) => state.common.userInfo
    })
  },
  methods: {
    changeTitle() {
      let title = '';
      if (this.userInfo.branchCd !== '') title = PAGE_TITLE[this.userInfo.branchCd];
      document.title = 'mypage_현장강의 무한패스 - 오늘 업데이트된 강의 | ' + title;
    },
    onViewClick(item) {
      InfinitePassApi.playCurri(item.mediaContsKey, item.curriVodNo, item.viewYn);
    },
    onSearch(result) {
      this.isLoading = false;
      if (result.data.list) {
        this.todayCurriVodList = result.data.list;
        EventBus.$emit('setPageInfo', result.data.pageInfo.currPage, result.data.pageInfo.numPerPage, result.data.pageInfo.totalRows);
      } else {
        EventBus.$emit('setPageInfo', 1, 10, 0);
      }
    }
  },
  created() {
    this.changeTitle();
  }
};
</script>
