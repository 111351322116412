<template>
  <header>
    <div class="inner">
      <a class="menu" @click="openMobileLeftMenu()">
        <span><em class="blind">메뉴열기</em></span>
      </a>
      <a :href="HOMEPAGE_URL[userInfo.branchCd]" class="home" v-if="!isPrev">
        <span class="homeBtnLogo"><em class="blind">홈</em></span>
      </a>
      <a href="javascript:void(0)" class="item_prev" @click="goPrev()" v-if="isPrev">이전</a>
      <h1 class="title">
        <span>{{ menuName }}</span>
      </h1>
      <a :href="HOMEPAGE_URL[userInfo.branchCd] + '/branch/member/userServiceCheck.do?returnUrl=' + HOMEPAGE_URL[userInfo.branchCd]" class="my" v-if="!isPrev"><span>My</span></a>
    </div>
  </header>
</template>

<script>
import { OLD_MY_PAGE_URL, HOMEPAGE_URL } from '@build/build.config';
import { commonEvent } from '@/mixins/commonEvent';
import { getBrandType } from '../../../../../utils/util';
export default {
  name: 'Header',
  props: ['userInfo', 'menuName', 'isPrev'],
  mixins: [commonEvent],
  data() {
    return {
      OLD_MY_PAGE_URL: OLD_MY_PAGE_URL[getBrandType(location.href)],
      HOMEPAGE_URL
    };
  },
  methods: {
    goPrev() {
      this.$router.go(-1);
    }
  }
};
</script>
