<template>
  <div id="header">
    <div id="headerTop">
      <div class="inner">
        <h1 class="logo ">
          <a v-if="userInfo.branchCd === '0130'" href="https://a2-etoosanswer.etoos.com">
            <span>ETOOS ANSWER</span>
          </a>
        </h1>
        <div class="outlink">
          <ul class="afterLogin">
            <li class="arr">
              <a v-if="userInfo.branchCd === '0130'" :href="ETOOS_MEMBER_LOGOUT_URL + '?callbackUrl=' + HOMEPAGE_URL[userInfo.branchCd] + '/logout.do'">
                <strong>{{ userInfo.userName }}</strong
                >님 로그아웃
              </a>
              <a v-else :href="ETOOS_MEMBER_LOGOUT_URL + '?callbackUrl=' + HOMEPAGE_URL['130'] + '/logout.do'">
                <strong>{{ userInfo.userName }}</strong
                >님 로그아웃
              </a>
            </li>
            <li class="arr"><a :href="HOMEPAGE_URL[userInfo.branchCd] + '/branch/member/userServiceCheck.do?returnUrl=' + HOMEPAGE_URL[userInfo.branchCd]">마이페이지</a></li>
            <li class="arr"><a :href="HOMEPAGE_URL[userInfo.branchCd] + '/branch/member/userServiceCheck.do?returnUrl=' + HOMEPAGE_URL[userInfo.branchCd] + '&menuCd=400'">장바구니</a></li>
            <li class="tel">
              <p>
                <span>교육상담</span><em>{{ BRANCH_EDU_COUNSEL_PHONE_NO[userInfo.branchCd] }}</em>
              </p>
            </li>
            <li><a href="https://www.etoos.com" target="_blank">이투스</a></li>
            <li><a href="https://www.etoos.com/book/" target="_blank">이투스북</a></li>
            <li><a @click="toggleAcaNetwork(true)">학원 네트워크</a></li>
            <li>
              <a href="https://cheongsol.etoos.com/gate/lctr/main.do" target="_blank" class="u">강사·전략담임 모집</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { OLD_MY_PAGE_URL, ETOOS_MEMBER_LOGOUT_URL, HOMEPAGE_URL } from '@build/build.config';
import { BRANCH_EDU_COUNSEL_PHONE_NO } from '@/const/const.js';
import { getBrandType } from '../../../../utils/util';
export default {
  name: 'Header',
  props: ['userInfo'],
  data() {
    return {
      OLD_MY_PAGE_URL: OLD_MY_PAGE_URL[getBrandType(location.href)],
      ETOOS_MEMBER_LOGOUT_URL,
      BRANCH_EDU_COUNSEL_PHONE_NO,
      HOMEPAGE_URL
    };
  },
  methods: {
    toggleAcaNetwork(flag) {
      this.$emit('toggleAcaNetwork', flag);
    }
  }
};
</script>
