<template>
  <div :class="!isMobile ? 'pay_content' : ''">
    <div class="mypage_comm" :style="!isMobile ? 'min-height: 1000px;' : ''">
      <div v-show="isCurrentBoxTitle" v-if="!isMobile && guidePopup && !popupCall" class="pc_only">
        <div class="box_guide open">
          <img :src="AWS_S3_RESOURCE_URL + '/my/pc/v2/images/mypage/img_guide.jpg'" />
          <label class="lbl_check">
            <input type="checkbox" class="inp_check" v-model="guidePopupChk" />
            <em class="txt_info">다시 보기 않기</em>
          </label>
          <button type="button" class="btn_close" @click="isGuidePopupChk()">
            <span class="blind">레이어 닫기</span>
          </button>
        </div>
      </div>
      <div v-if="!isMobile && !popupCall" class="pc_only">
        <div v-show="isCurrentBoxTitle" class="box_title">
          <h4 class="tit_timetable">
            <span class="wrap_select">
              <strong id="labelYear" class="lbl_season">{{ currentYear }}년</strong>
              <select id="selectYear" class="select_season" v-model="currentYear">
                <option v-for="(item, idx) in listByYear" :key="idx" :value="item.year"> {{ item.year }}년 </option>
              </select>
            </span>
            <span class="wrap_select">
              <strong id="labelGroup" class="lbl_season">{{ currentGroupName }}</strong>
              <select id="selectGroup" class="select_season" v-model="currentGrpIdx">
                <option v-for="(item, idx) in groupListByYear" :key="idx" :value="item.grpIdx">{{ item.groupName }}</option>
              </select>
            </span>
            <span class="wrap_select">
              <strong id="labelSeason" class="lbl_season">{{ this.seasonData.seasonNm + (this.seasonData.status == 'Y' ? '[확정]' : '') }}</strong>
              <select id="selectSeason" class="select_season" v-model="currentSeason">
                <option v-for="(item, idx) in seasonList" :key="idx" :disabled="disableChk(item)" :value="item.seasonYear + ',' + item.seasonCd" :style="{ color: disableChk(item) ? '#ececec' : '' }">
                  {{ item.seasonNm }}<span v-if="item.status == 'Y'">[확정]</span>
                </option>
              </select>
            </span>
            <span class="text">시간표</span>
          </h4>
        </div>
      </div>
      <div v-if="isMobile" class="mo_only">
        <div class="box_title">
          <div class="select_exam_system type3">
            <div class="inner">
              <div class="wrap_select_year">
                <select class="select_cont" v-model="currentYear">
                  <option v-for="(item, idx) in listByYear" :key="idx" :value="item.year"> {{ item.year }}년 </option>
                </select>
              </div>
              <div class="wrap_select_system">
                <select class="select_cont" v-model="currentGrpIdx">
                  <option v-for="(item, idx) in groupListByYear" :key="idx" :value="item.grpIdx">{{ item.groupName }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="wrap_select">
            <ul class="list_season">
              <li v-for="(item, idx) in seasonList" :key="idx" :class="{ on: item.seasonYear + ',' + item.seasonCd == currentSeason }" :id="item.seasonYear + ',' + item.seasonCd == currentSeason ? 'on' : ''">
                <button type="button" class="btn_select" :disabled="disableChk(item)" @click="currentSeason = item.seasonYear + ',' + item.seasonCd">
                  <span class="inner"> {{ item.seasonNm }}<span v-if="item.status == 'Y'" class="confirm">[확정]</span> </span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div v-if="!popupCall" class="pc_only">
        <div class="box_period">
          <ul class="list_step">
            <li :class="{ on: isReg }" class="step1">
              <strong class="tit_period">수강신청기간</strong>
              <p class="txt_cont">
                {{ dateTimeFilter(this.seasonData.studyRegStartDt) + '~' + dateTimeFilter(this.seasonData.studyRegEndDt) }}
              </p>
              <!--     -->
            </li>
            <li :class="{ on: isConfirm }" class="step2">
              <strong class="tit_period">수강확인기간</strong>
              <p class="txt_cont">
                {{ dateFilter(this.seasonData.studyConfirmStartDt) + '~' + dateFilter(this.seasonData.studyConfirmEndDt) }}
              </p>
            </li>
            <li :class="{ on: isMod }" class="step3">
              <strong class="tit_period">수강정정기간</strong>
              <p class="txt_cont">
                {{ dateFilter(this.seasonData.studyModStartDt) + '~' + dateFilter(this.seasonData.studyModEndDt) }}
              </p>
            </li>
          </ul>
          <div class="progress">
            <div class="step">
              <i :class="{ on: isReg }" class="stpe1"></i>
              <i :class="{ on: isConfirm }" class="stpe2"></i>
              <i :class="{ on: isMod }" class="stpe3"></i>
            </div>
          </div>
          <div :class="{ on: this.seasonData.status == 'Y' }" class="confirm">
            <div class="wrap_cont">
              <img v-if="this.seasonData.status == 'Y'" :src="AWS_S3_RESOURCE_URL + '/my/pc/v2/images/mypage/img_confirm.png?ver=20201118152'" width="94" height="19" />
              <strong class="blind">시간표 확정</strong>
              <em v-if="this.seasonData.status == 'Y'" class="date">{{ dateFilter(this.seasonData.statusDate) }}</em>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isReg || isConfirm || isMod" class="mo_only">
        <div class="box_period">
          <p class="txt_period">{{ isState() }}</p>
        </div>
      </div>
      <div v-if="!isReg && seasonData.indvdlEnrolmentYn === 'Y'" class="notice_tx">
        <span>개별 수강신청 중 입니다.</span>
      </div>
      <div class="box_schedule">
        <div v-if="!popupCall" class="filter">
          <ul class="select_subject">
            <li v-for="(item, idx) in subjtArray" :key="idx">
              <input type="radio" :id="'subject0' + idx" name="selectSubject" class="inp_radio" :value="item.value" v-model="subjtCd" />
              <label :for="'subject0' + idx" class="lbl_item"> {{ item.name }}({{ item.cnt | tmFilter() }})&nbsp; </label>
            </li>
          </ul>
        </div>
        <div v-if="!popupCall" class="utility">
          <button type="button" class="btn_type1 find" @click="courseSearchPopUp()">
            <span class="inner">강좌검색</span>
          </button>
          <button type="button" v-if="isReg || seasonData.indvdlEnrolmentYn === 'Y'" class="btn_type1 cancel" @click="cancel = true">
            <span class="inner">선택강좌 취소</span>
          </button>
          <button type="button" class="btn_type1 print" @click="printPopUp()">
            <span class="inner">시간표 출력</span>
          </button>
          <button type="button" class="btn_history" @click="showHistoryParam">
            <span class="blind">히스토리 보기</span>
          </button>
        </div>

        <div class="mo_only">
          <div class="selectday">
            <ul class="list_day">
              <li>
                <button type="button" class="btn_day"><span class="inner">월</span></button>
              </li>
              <li>
                <button type="button" class="btn_day"><span class="inner">화</span></button>
              </li>
              <li>
                <button type="button" class="btn_day"><span class="inner">수</span></button>
              </li>
              <li>
                <button type="button" class="btn_day"><span class="inner">목</span></button>
              </li>
              <li>
                <button type="button" class="btn_day"><span class="inner">금</span></button>
              </li>
              <li>
                <button type="button" class="btn_day"><span class="inner">토</span></button>
              </li>
              <li>
                <button type="button" class="btn_day"><span class="inner">일</span></button>
              </li>
            </ul>
          </div>
        </div>
        <!-- pc grid schedule class겹침 -->
        <schedule-list
          v-if="scheduleVisible"
          :scheduleParam="scheduleParam"
          :subjtCd="subjtCd"
          :cancel="cancel"
          @detailPop="detailPop"
          @cancelCheck="cancelCheck"
          @checkPopup="courseCheckPopup"
          @scheduleSearch="courseSearchPopUp"
          @subjtArray="subjtSet"
          @seasonStatusChange="seasonData.status = 'N'"
          @scheduleRefresh="scheduleRefresh"
        />

        <div class="appendix">
          <ul class="list_info">
            <li>※ 단과는 취소가 불가 합니다. 상담팀에 문의하세요.</li>
            <li>※ <em class="ico_label type1">대기강좌</em> <em class="ico_label type2">중복강좌</em> 를 나타냅니다.</li>
          </ul>
        </div>
      </div>
    </div>
    <schedule-history v-if="showHistory" :historyParam="historyParam" @close="showHistory = false" />
    <lecture-detail-m v-if="isDetailPopup && isMobile" :checkParam="detailParam" @close="isDetailPopup = false" />
    <lecture-check v-if="isMobile && isCheckPopup" :checkParam="checkParam" :seasonPerdMap="seasonPerdMap" @close="isCheckPopup = false" @refresh="scheduleRefresh()" />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { classRoomPopup } from '@/mixins/classRoomPopup';
import DateUtil from '@/utils/DateUtil';
import TmsCookieUtil from '@/utils/TmsCookieUtil';
import scheduleHistory from './ScheduleHistory';
import scheduleList from '@/components/classroom/schedule/ScheduleList';
import UiMypage from '@/utils/ui_mypage';
import { OLD_MY_PAGE_URL, AWS_S3_RESOURCE_URL, WAITING_CLIENT, APPLICATION_PHASE, HOMEPAGE_URL } from '@build/build.config';
import lectureCheck from '@/components/classroom/lecture/LectureCheckM';
import moment from 'moment';
import lectureDetailM from '@/components/classroom/lecture/LectureDetailM.vue';
import { LECTURE_TYPE_CD } from '@/const/classroom_const';
import { getBrandType } from '../../../utils/util';
import { PAGE_TITLE } from '../../../const/const';
import '../../../utils/waiting/waiting-client.js';
import '../../../utils/waiting/waiting-client.worker.js';

export default {
  name: 'schedulemain',
  components: { DateUtil, scheduleHistory, UiMypage, lectureCheck, scheduleList, lectureDetailM },
  mixins: [classRoomPopup],
  data() {
    return {
      OLD_MY_PAGE_URL: OLD_MY_PAGE_URL[getBrandType(location.href)],
      HOMEPAGE_URL,
      WAITING_CLIENT: WAITING_CLIENT[getBrandType(location.href)],
      guidePopup: false,
      seasonCd: '',
      seasonNm: '',
      seasonData: {},
      date: new Date(),
      isConfirm: false,
      isReg: false,
      isMod: false,
      subjtCd: 'all',
      subjtArray: [],
      schedule: {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: []
      },
      duplicateIdx: [],
      showHistory: false,
      guidePopupChk: false,
      branchCd: '',
      AWS_S3_RESOURCE_URL,
      deleteChk: [],
      checkParam: {},
      isCheckPopup: false,
      isPrintPopup: false,
      isDetailPopup: false,
      Array: [],
      danArray: [],
      popupCnt: 0,
      scheduleParam: {},
      historyParam: {},
      seasonYear: '',
      cancel: false,
      detailParam: {},
      scheduleVisible: false,
      currentSeason: '',
      currentYear: '',
      currentGrpIdx: 0,
      currentGroupName: '',
      isCurrentBoxTitle: false,
      groupListByYear: [],
      defaultGrpIdx: 0,
      defaultSeasonCd: '',
      LECTURE_TYPE_CD
    };
  },
  props: ['popupCall', 'popupParam'],
  filters: {
    tmFilter(val) {
      return TmsCookieUtil.getCookie('lecTM') === 'M' ? val * 60 + '분' : val + 'T';
    },
    weekFilter(val) {
      switch (val) {
        case 'mon':
          return '월요일';
        case 'tue':
          return '화요일';
        case 'wed':
          return '수요일';
        case 'thu':
          return '목요일';
        case 'fri':
          return '금요일';
        case 'sat':
          return '토요일';
        case 'sun':
          return '일요일';
        default:
          '';
          break;
      }
    }
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      userInfo: (state) => state.common.userInfo,
      seasonList: (state) => state.classroom.schedule.seasonList,
      studentGroup: (state) => state.classroom.schedule.studentGroup,
      lectureCancelData: (state) => state.classroom.lecture.lectureCancelData,
      seasonPerdMap: (state) => state.classroom.schedule.seasonPerdMap,
      listByYear: (state) => state.classroom.schedule.listByYear,
      defaultSeason: (state) => state.classroom.schedule.defaultSeason
    })
  },
  watch: {
    currentSeason(e) {
      const eArray = (e || '').split(',');
      if (eArray.length === 2) {
        const year = e.split(',')[0],
          cd = e.split(',')[1];
        this.seasonCd = cd;
        this.seasonYear = year;
        this.seasonData = _.find(this.seasonList, { seasonYear: year, seasonCd: cd });
        if (new Date(this.seasonData.studyRegStartDt) > this.date) {
          alert('다음 수강신청이 시작되면 이용하실 수 있습니다.');
          if (this.isMobile) {
            location.href = HOMEPAGE_URL[this.userInfo.branchCd] + '/branch/member/userServiceCheck.do?returnUrl=' + HOMEPAGE_URL[this.userInfo.branchCd];
          } else {
            this.$router.push('/studymain');
          }
        }
        this.currentStatus(this.seasonData);
        this.scheduleCall();
        this.scheduleRefresh();
        this.isCurrentBoxTitle = true;
      }
    },
    currentYear(e) {
      this.seasonYear = e;
      this.listByYear.find((el) => {
        if (el.year === e) {
          this.groupListByYear = el.groupList;
        }
      });
      if (this.defaultGrpIdx !== 0) {
        this.groupListByYear.find((gl) => {
          if (gl.grpIdx === this.defaultGrpIdx) {
            this.currentGroupName = gl.groupName;
            this.currentGrpIdx = gl.grpIdx;
            this.defaultGrpIdx = 0;
          }
        });
        return;
      }

      if (this.groupListByYear.length > 0) {
        this.currentGroupName = this.groupListByYear[0].groupName;
        this.currentGrpIdx = this.groupListByYear[0].grpIdx;
      }
    },
    currentGrpIdx(grpIdx) {
      this.currentSeason = '';
      this.groupListByYear.find((gl) => {
        if (gl.grpIdx === grpIdx) {
          this.currentGroupName = gl.groupName;
        }
      });
      this.getSeasonList();
    }
  },
  created() {
    if (APPLICATION_PHASE === 'REAL' || APPLICATION_PHASE === 'QA') {
      this.waitingInit();
    } else {
      this.firstCreate();
    }
  },
  mounted() {
    UiMypage.mypageUI.init();
  },
  destroyed() {},
  methods: {
    ...mapActions(['getSeason', 'getUser', 'getStudentGroup', 'lectureCancel', 'getStudentFirstGroup', 'setHeaderInfo']),
    changeTitle() {
      let title = '';
      if (this.userInfo.branchCd !== '') title = PAGE_TITLE[this.userInfo.branchCd];
      document.title = 'mypage_수강신청 | ' + title;
    },
    waitingInit() {
      let waitingClient = null;
      const settings = {
        environment: WAITING_CLIENT['server_type'],
        serviceId: 'o2o',
        waitingId: WAITING_CLIENT['client_id'],
        autoRedirect: true,
        popup: false
      };
      try {
        waitingClient = new WaitingClient.Init(settings);
        waitingClient
          .verifyToken()
          .then((response) => {
            if (response.code !== 0) {
              waitingClient.run({});
            }
            this.firstCreate();
          })
          .catch((error) => {
            console.log('waitingClient run error catch'); //run error catch
            console.log(error); //run error catch
          });
      } catch (e) {
        console.log('waitingClient init error catch');
        console.log(e); // init error catch
      }
    },
    firstCreate() {
      this.setHeaderInfo({ menuName: '수강신청', isPrev: false });
      if (TmsCookieUtil.getCookie('etsscheduleguide') === 'Y') {
        this.guidePopup = false;
      } else {
        this.guidePopup = true;
      }
      if (!this.userInfo.studentCd) {
        this.getUser().then(() => {
          this.init();
        });
      } else {
        this.init();
      }
      this.changeTitle();
    },
    init() {
      this.studentCd = this.userInfo.studentCd;
      this.branchCd = this.userInfo.branchCd;
      this.getFirstGroups();
    },
    getSeasonList() {
      const params = {};
      params.branchCd = this.branchCd;
      params.studentCd = this.studentCd;
      params.seasonYear = this.seasonYear;
      params.grpIdx = this.currentGrpIdx;
      this.getSeason(params).then(() => {
        this.seasonList.some((e) => {
          return this.seasonStatus(e);
        });
        if (this.defaultSeasonCd !== '') {
          this.currentSeason = this.seasonYear + ',' + this.defaultSeasonCd;
          this.defaultSeasonCd = '';
        } else if (this.currentSeason === '') {
          this.currentSeason = this.seasonList[this.seasonList.length - 1].seasonYear + ',' + this.seasonList[this.seasonList.length - 1].seasonCd;
        }
        if (this.isMobile) {
          this.$nextTick(() => {
            if (document.querySelector('.list_season').children.namedItem('on').offsetLeft > parseInt(document.querySelector('.wrap_select').offsetWidth / 3)) {
              document.querySelector('.wrap_select').scrollLeft = parseInt(document.querySelector('.list_season').children.namedItem('on').offsetLeft - (document.querySelector('.wrap_select').offsetWidth / 2 - 40));
            }
          });
        }
      });
    },
    getFirstGroups() {
      const params = {};
      params.studentCd = this.studentCd;
      params.branchCd = this.branchCd;
      this.getStudentFirstGroup(params).then(() => {
        if (this.defaultSeason.defaultYear) {
          this.defaultGrpIdx = this.defaultSeason.defaultGrpIdx;
          this.defaultSeasonCd = this.defaultSeason.defaultSeasonCd;
          this.currentYear = this.defaultSeason.defaultYear;
        } else if (this.listByYear.length > 0) {
          this.currentYear = this.listByYear[0].year;
        }
      });
    },
    disableChk(item) {
      if (new Date(item.studyRegStartDt) <= this.date) {
        return false;
      } else {
        return true;
      }
    },
    btnCheck(item) {
      if (item.waitingOrder > 0 && item.regstateCd == '6') {
        return 'type2';
      } else if (item.count > 0 && item.overYn === 'Y') {
        return 'type3';
      } else if (item.count > 0 && item.overYn === 'N') {
        return 'type1';
      }
    },
    courseCheckPopup(idx) {
      if (this.isMobile) {
        this.checkParam.grpIdx = idx.join(',');
        this.checkParam.studentCd = this.studentCd;
        this.checkParam.branchCd = this.branchCd;
        this.checkParam.seasonCd = this.seasonCd;
        this.checkParam.seasonYear = this.seasonData.seasonYear;
        this.isCheckPopup = true;
      } else {
        const route = this.$router.resolve({
          name: 'lectureCheckP',
          query: {
            branchCd: this.branchCd,
            courseCd: '1',
            studentCd: this.studentCd,
            grpIdx: idx.join(','),
            studyRegStartDt: this.seasonData.studyRegStartDt,
            studyRegEndDt: this.seasonData.studyRegEndDt,
            seasonCd: this.seasonCd,
            seasonYear: this.seasonYear
          }
        });
        const size = {};
        size.width = 1200;
        size.height = 500;
        size.popupX = window.screen.width / 2 - size.width / 2;
        size.popupY = window.screen.height / 2 - size.height / 2;
        this.openClassRoomPopup(route.href, 'scheduleMain', 'lectureCheckP', this.scheduleRefresh, size);
      }
    },
    isState() {
      if (this.isReg) {
        return '수강신청기간  ' + this.dateTimeFilter(this.seasonData.studyRegStartDt) + '~' + this.dateTimeFilter(this.seasonData.studyRegEndDt);
      } else if (this.isConfirm) {
        return '수강확인기간  ' + this.dateFilter(this.seasonData.studyConfirmStartDt) + '~' + this.dateFilter(this.seasonData.studyConfirmEndDt);
      } else if (this.isMod) {
        return '수강정정기간  ' + this.dateFilter(this.seasonData.studyModStartDt) + '~' + this.dateFilter(this.seasonData.studyModEndDt);
      }
    },
    isGuidePopupChk() {
      if (this.guidePopupChk) {
        const yearLater = this.date.setFullYear(this.date.getFullYear() + 1);
        TmsCookieUtil.setCookie('etsscheduleguide', 'Y', yearLater);
        this.guidePopup = false;
      } else {
        this.guidePopup = false;
      }
    },
    courseSearchPopUp(scheduleParam) {
      if (this.isMobile) {
        const searchPopupParam = {};
        if (scheduleParam) {
          if (this.seasonData.indvdlEnrolmentYn === 'N' && this.seasonData.seasonEndDt < DateUtil.dateFormatter('{yyyy}{mm}{dd}', this.date)) {
            return false;
          }
          searchPopupParam.perdCd = scheduleParam.perdCd;
          searchPopupParam.week = scheduleParam.week;
          searchPopupParam.lectureTypeCd = scheduleParam.lectureTypeCd;
        }
        searchPopupParam.branchCd = this.branchCd;
        searchPopupParam.studentCd = this.studentCd;
        searchPopupParam.seasonCd = this.seasonCd;
        searchPopupParam.groupYear = this.studentGroup.groupYear;
        searchPopupParam.seasonYear = this.seasonData.seasonYear;
        this.$router.push({ name: 'lectureSearchMainM', params: { searchPopupParam } });
      } else {
        let perdCd = '';
        let week = '';
        let lectureTypeCd = '';
        if (scheduleParam) {
          if (this.seasonData.indvdlEnrolmentYn === 'N' && this.seasonData.seasonEndDt < DateUtil.dateFormatter('{yyyy}{mm}{dd}', this.date)) {
            return false;
          }
          perdCd = scheduleParam.perdCd;
          week = scheduleParam.week;
          lectureTypeCd = scheduleParam.lectureTypeCd;
        }
        //강좌검색 버튼
        const route = this.$router.resolve({
          name: 'lectureSearchP',
          query: {
            seasonCd: this.seasonCd,
            branchCd: this.branchCd,
            groupYear: this.studentGroup.groupYear,
            seasonYear: this.seasonData.seasonYear,
            studentCd: this.studentCd,
            week,
            perdCd,
            lectureTypeCd: lectureTypeCd || String(this.LECTURE_TYPE_CD.GENERAL),
            seasonStartDt: this.seasonData.seasonStartDt,
            seasonEndDt: this.seasonData.seasonEndDt
          }
        });
        this.openClassRoomPopup(route.href, 'schedulemain', 'lecture-search-p', this.scheduleRefresh);
      }
    },
    reload() {},
    printPopUp() {
      const route = this.$router.resolve({
        name: 'schedulePrintPopup',
        query: {
          seasonCd: this.seasonCd,
          branchCd: this.branchCd,
          studentCd: this.studentCd,
          seasonYear: this.seasonData.seasonYear,
          seatName: this.studentGroup.seatName
        }
      });
      this.openClassRoomPopup(route.href, 'schedulemain', 'schedule-print-popup', this.reload);
    },
    cancelCheck(grpIdxs) {
      this.cancel = false;
      this.deleteChk = grpIdxs;
      if (this.deleteChk.length <= 0) {
        alert('선택된 강좌가 없습니다.');
      } else {
        const message = confirm('선택강좌를 취소하시겠습니까?');
        if (message) {
          let chk = true;
          const chkArray = this.danArray;
          this.deleteChk.some(function(e) {
            if (chkArray.indexOf(e.grpIdx) > -1) {
              alert('단과는 취소하실 수 없습니다.');
              chk = false;
              return true;
            }
          });
          if (!chk) return false;
          let failCnt = 0;
          this.deleteChk.forEach((item, idx) => {
            const params = {};

            params['branchCd'] = this.branchCd;
            params['studentCd'] = this.studentCd;
            params['cancelReason'] = '0';

            params['groups'] = [{ grpIdx: item.grpIdx, grpasnIdx: item.grpasnIdx }];

            this.lectureCancel(params).finally(() => {
              const result = this.lectureCancelData;
              if (result.code !== '00000') {
                failCnt += 1;
              }
              if (idx === this.deleteChk.length - 1) {
                if (failCnt > 0) {
                  alert('학원에 문의해 주세요.');
                } else {
                  alert('수강취소를 완료 했습니다');
                }
                this.$nextTick(() => {
                  this.scheduleRefresh();
                });
              }
            });
          });
        }
      }
    },
    seasonStatus(e) {
      if (e.seasonEndDt <= DateUtil.dateFormatter('{yyyy}{mm}{dd}', this.date)) {
        this.isReg = false;
        this.isConfirm = false;
        this.isMod = false;
      } else if (new Date(e.studyRegStartDt) <= this.date && new Date(e.studyRegEndDt) >= this.date) {
        this.currentSeason = e.seasonYear + ',' + e.seasonCd;
        return true;
      } else if (e.studyConfirmStartDt <= DateUtil.dateFormatter('{yyyy}{mm}{dd}', this.date) && e.studyConfirmEndDt >= DateUtil.dateFormatter('{yyyy}{mm}{dd}', this.date)) {
        this.currentSeason = e.seasonYear + ',' + e.seasonCd;
        return true;
      } else if (e.studyModStartDt <= DateUtil.dateFormatter('{yyyy}{mm}{dd}', this.date) && e.studyModEndDt >= DateUtil.dateFormatter('{yyyy}{mm}{dd}', this.date)) {
        this.currentSeason = e.seasonYear + ',' + e.seasonCd;
        return true;
      } else if (e.seasonStartDt <= DateUtil.dateFormatter('{yyyy}{mm}{dd}', this.date) && e.seasonEndDt >= DateUtil.dateFormatter('{yyyy}{mm}{dd}', this.date)) {
        this.currentSeason = e.seasonYear + ',' + e.seasonCd;
      }
    },
    currentStatus(e) {
      this.isReg = false;
      this.isConfirm = false;
      this.isMod = false;

      if (e.seasonEndDt <= DateUtil.dateFormatter('{yyyy}{mm}{dd}', this.date)) {
        this.isReg = false;
        this.isConfirm = false;
        this.isMod = false;
      } else if (new Date(e.studyRegStartDt) <= this.date && new Date(e.studyRegEndDt) >= this.date) {
        this.isReg = true;
        this.isConfirm = false;
        this.isMod = false;
      } else if (e.studyConfirmStartDt <= DateUtil.dateFormatter('{yyyy}{mm}{dd}', this.date) && e.studyConfirmEndDt >= DateUtil.dateFormatter('{yyyy}{mm}{dd}', this.date)) {
        this.isReg = false;
        this.isConfirm = true;
        this.isMod = false;
      } else if (e.studyModStartDt <= DateUtil.dateFormatter('{yyyy}{mm}{dd}', this.date) && e.studyModEndDt >= DateUtil.dateFormatter('{yyyy}{mm}{dd}', this.date)) {
        this.isReg = false;
        this.isConfirm = false;
        this.isMod = true;
      }
    },
    showHistoryParam() {
      const params = {};
      params.seasonYear = this.seasonData.seasonYear;
      params.branchCd = this.branchCd;
      params.seasonCd = this.seasonCd;
      params.studentCd = this.studentCd;

      this.historyParam = params;

      this.showHistory = true;
    },
    scheduleCall() {
      const params = {};
      params.branchCd = this.branchCd;
      params.seasonCd = this.seasonCd;
      params.seasonYear = this.seasonData.seasonYear;
      params.studentCd = this.studentCd;
      params.seasonStatus = this.seasonData.status;
      this.scheduleParam = params;
    },
    subjtSet(val) {
      this.subjtArray = val;
    },
    detailPop(val) {
      if (!this.isMobile) {
        const route = this.$router.resolve({
          name: 'lectureDetailP',
          query: {
            groupName: val.groupName,
            teacherName: val.teacherName,
            grpIdx: val.grpIdx,
            branchCd: this.branchCd,
            subjt: val.subjtNm,
            groupYear: this.currentYear
          }
        });
        this.openClassRoomPopup(route.href, 'scheduleMain', 'lectureDetailP', this.reload);
      } else {
        const params = {};
        params.groupName = val.groupName;
        params.teacherName = val.teacherName;
        params.grpIdx = val.grpIdx;
        params.branchCd = this.branchCd;
        params.subjt = val.subjtNm;
        params.groupYear = this.currentYear;

        this.detailParam = params;
        this.isDetailPopup = true;
      }
    },
    dateFilter(val) {
      return moment(val).format('YYYY.MM.DD');
    },
    dateTimeFilter(val) {
      return moment(val).format('YYYY.MM.DD HH:mm');
    },
    scheduleRefresh() {
      this.scheduleVisible = false;
      this.$nextTick(() => {
        this.scheduleVisible = true;
      });
    }
  }
};
</script>
