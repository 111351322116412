<template>
  <div id="laymove" class="fex wrap_ly_pop" style="display: block;">
    <!---->
    <div
      class="ly_pop"
      :style="this.explType === 'corWransr' ? { width: '800px' } : { width: '800px' }"
    >
      <div class="ly_header"><h5>해설강의</h5></div>
      <div class="ly_body">
        <div class="mov_lay">
          <video id="video1" width="100%" controls="controls" preload="metadata">
            <source :src="getBucketURL() + explCurriVodUrl + '#t=0.1'" type="video/mp4" />
          </video>
        </div>
      </div>
      <a @click="showExplVodUrl()" class="close"><span class="blind">레이어팝업 닫기</span></a>
    </div>
  </div>
</template>
<script>
import { AWS_S3_BUCKET_URL, AWS_S3_RESOURCE_URL } from '@build/build.config';

export default {
  name: 'ExplVurriVod',
  props: ['explCurriVodUrl', 'explType'],
  data() {
    return {
      AWS_S3_BUCKET_URL,
      AWS_S3_RESOURCE_URL
    };
  },
  methods: {
    showExplVodUrl() {
      this.$emit('showExplVod', false);
    },
    getBucketURL() {
      let url = AWS_S3_BUCKET_URL;
      if (this.explType === 'corWransr') {
        url = AWS_S3_RESOURCE_URL;
      }
      return url;
    }
  }
};
</script>
