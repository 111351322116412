<template>
  <div id="subjectList" class="layer_fullpage subjectlist open">
    <div class="layer_head">
      <h4 class="tit_layer">강좌 확인하기</h4>
      <a href="javascript:;" class="layer_close" @click="$emit('close')"><span class="blind">레이어 닫기</span></a>
    </div>
    <div class="layer_cont">
      <div class="box_list">
        <div class="wrap_list">
          <ul class="list_subject">
            <li v-for="(item, idx) in mainList" :key="idx">
              <em class="ico_type">{{ item.lectureTypeCd == '1' ? '재종반' : lectureFilter(item.groupForm) }}</em>
              <p class="txt_teacher">
                <em v-if="item.waitingOrder > 0" class="ico_notify type1">대기 {{ item.waitingOrder }}번</em>[{{ item.subject }}] {{ item.teacherName }}
              </p>
              <strong class="tit_subject" v-html="'<br />' + item.groupName"></strong>
              <div class="info_subject">
                <em class="txt_info">{{ item.weekData }}</em>
                <em class="txt_info" :class="{ caution: duplicateCheck(item.grpIdx, item.groupSdate, item.groupEdate) }">
                  {{ momentFormat(item.groupSdate) + ' ~ ' + momentFormat(item.groupEdate) }}
                </em>
              </div>
              <span class="wrap_radio">
                <label class="lbl_radio">
                  <input type="radio" class="inp_radio" name="groupRadio" :value="item.grpIdx" v-model="baisSort" @click="saveExposureLecture(item.grpIdx)" />
                  <span class="ico_radio"></span>시간표 노출
                </label>
              </span>
            </li>
          </ul>
        </div>
        <div class="appendix">
          <ul class="list_info">
            <li>운영기간이 빨간색으로 표시되고 있으면, 중복 강좌 입니다.<br />중복되지 않도록 취소해 주세요.</li>
            <li>시간표 노출 선택 시, 시간표에 선택한 강좌가 보여집니다.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import { AWS_S3_RESOURCE_URL } from '../../../../build/build.config';
import { PAGE_TITLE } from '../../../const/const';

export default {
  name: 'lectureCheck',
  data() {
    return {
      weekArray: {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: []
      },
      week: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
      mainList: [],
      baisSort: 0,
      mngArray: [],
      dateList: []
    };
  },
  props: ['close', 'checkParam', 'seasonPerdMap'],
  components: {},
  filters: {},
  created() {
    this.seasonYear = this.checkParam.seasonYear;
    this.seasonCd = this.checkParam.seasonCd;
    if (!this.userInfo.studentCd) {
      this.getUser().then(() => {
        this.LectureCheck();
      });
    } else {
      this.LectureCheck();
    }
    this.changeTitle();
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.common.userInfo,
      lectureCheckList: (state) => state.classroom.lecture.lectureCheckList,
      scheduleList: (state) => state.classroom.schedule.scheduleList
    })
  },
  watch: {
    baisSort() {}
  },
  methods: {
    ...mapActions(['getLectureCheck', 'postBaisSort', 'getUser']),
    changeTitle() {
      let title = '';
      if (this.userInfo.branchCd !== '') title = PAGE_TITLE[this.userInfo.branchCd];
      document.title = 'mypage_수강신청 | ' + title;
    },
    LectureCheck() {
      const params = {};
      params.grpIdx = this.checkParam.grpIdx;
      params.branchCd = this.checkParam.branchCd;
      params.studentCd = this.checkParam.studentCd;
      console.log(this.seasonPerdMap);
      const days = ['monYn', 'tueYn', 'wedYn', 'thuYn', 'friYn', 'satYn', 'sunYn'];
      this.getLectureCheck(params).finally(() => {
        if (this.lectureCheckList.length > 0) {
          this.lectureCheckList.forEach((e) => {
            let List = [];
            const array = [];
            const weekChk = [];
            e.forEach((ee) => {
              days.forEach((d) => {
                if (ee[d] === 'Y') {
                  //월 1교시 월 2교시
                  let week = '';
                  const val = d;
                  if (val === 'monYn') {
                    week = '월';
                  } else if (val === 'tueYn') {
                    week = '화';
                  } else if (val === 'wedYn') {
                    week = '수';
                  } else if (val === 'thuYn') {
                    week = '목';
                  } else if (val === 'friYn') {
                    week = '금';
                  } else if (val === 'satYn') {
                    week = '토';
                  } else if (val === 'sunYn') {
                    week = '일';
                  }
                  //let chk = this.weekArray[week].filter((item, idx) => {item.indexOf(ee.perdCd) > -1})
                  if (weekChk.indexOf(week) === -1) {
                    //중복아님
                    if (week === '') {
                      return;
                    }
                    const row = {};
                    const yearSeasonMap = this.seasonPerdMap[this.seasonYear] || {};
                    const perdMap = yearSeasonMap[this.seasonCd] || {};
                    row.perdCd = perdMap[ee.perdCd] || ee.perdCd + '교시';
                    row.week = week;
                    weekChk.push(week);
                    array.push(row);
                  } else {
                    //중복 교시만 합쳐서 출력
                    array.filter((item) => {
                      if (item.week === week) {
                        const yearSeasonMap = this.seasonPerdMap[this.seasonYear] || {};
                        const perdMap = yearSeasonMap[this.seasonCd] || {};
                        const name = perdMap[ee.perdCd] || ee.perdCd + '교시';
                        item.perdCd = item.perdCd + ',' + name;
                      }
                    });
                  }
                }
              });
            });
            e[0].weekData = '';
            array.forEach((item) => {
              // 다수의 요일 일때
              e[0].weekData += item.week + ' ' + item.perdCd + ' ';
            });
            List = e[0];
            if (List.baisSort === '0') {
              this.baisSort = List.grpIdx;
              this.priority = List.grpIdx;
            }
            this.dateList.push({ grpIdx: List.grpIdx, groupSdate: List.groupSdate, groupEdate: List.groupEdate });
            this.mainList.push(List);
          });
          if (this.baisSort == 0) {
            this.baisSort = this.mainList[0].grpIdx;
          }
        }
      });
    },
    lectureFilter(groupForm) {
      if (groupForm === '003') {
        return '특강';
      } else {
        return '단과';
      }
    },
    dateCheck() {
      let i = 0;
      let sdate = '';
      let edate = '';
      let result;
      this.mainList.forEach((e) => {
        if (i > 0) {
          if (e.groupSdate <= edate && sdate <= e.groupEdate) {
            //중복
            result = true;
          }
          sdate = e.groupSdate;
          edate = e.groupEdate;
        } else {
          sdate = e.groupSdate;
          edate = e.groupEdate;
        }
        i++;
      });
      return result;
    },
    duplicateCheck(idx, sdate, edate) {
      let cnt = 0;
      this.dateList.forEach((e) => {
        if (e.grpIdx !== idx) {
          if ((sdate <= e.groupSdate && edate >= e.groupEdate) || (sdate <= e.groupEdate && edate >= e.groupSdate)) {
            ++cnt;
          }
        }
      });
      return cnt > 0;
    },
    saveExposureLecture(val) {
      if (val === this.baisSort) {
        return false;
      }
      if (!confirm('시간표에 노출되는 강좌를 변경하시겠습니까?')) {
        event.preventDefault();
      } else {
        const params = {};
        params.branchCd = this.checkParam.branchCd;
        params.seasonCd = this.checkParam.seasonCd;
        params.seasonYear = this.checkParam.seasonYear;
        params.studentCd = this.checkParam.studentCd;

        const dataList = [];
        let i = 0;
        this.mainList.forEach((e) => {
          const data = {};
          if (val == e.grpIdx) {
            data['studentCd'] = e.studentCd;
            data['grpIdx'] = e.grpIdx;
            data['baisSort'] = '0';
          } else {
            data['studentCd'] = e.studentCd;
            data['grpIdx'] = e.grpIdx;
            data['baisSort'] = '1';
          }
          dataList[i] = data;
          i++;
        });
        params.requestList = dataList;
        this.postBaisSort(params).finally(() => {
          this.$emit('close');
          this.$emit('refresh');
        });
      }
    },
    momentFormat(groupDate) {
      return moment(groupDate).format('YYYY.MM.DD');
    }
  }
};
</script>
