<template>
  <span class="badge_box">
    <i class="ico_class" :class="[lectureType]">{{ lectureName }}</i>
    <template v-if="clsMiddle !== '수학전문관'">
      <i class="ico_subject_badge" :class="[subjectType]">{{ subjtNm }}</i>
    </template>
  </span>
</template>

<script>
export default {
  name: 'BadgeBox',
  props: {
    /**
     * lectureTypeCd = { 1(정규수업), 2(단과), 3(특강) }
     */
    lectureTypeCd: {
      type: String,
      default: ''
    },
    clsMiddle: {
      type: String,
      default: ''
    },
    unitySubjtCd: {
      type: String,
      default: ''
    },
    subjtNm: {
      type: String,
      default: ''
    }
  },
  computed: {
    lectureType() {
      switch (this.lectureTypeCd) {
        case '1':
          return 'type1';
        case '3':
          return 'type4';
        case '2':
          if (this.clsMiddle === '수학전문관') return 'type3';
          return 'type2';
        default:
          return 'type1';
      }
    },
    lectureName() {
      switch (this.lectureTypeCd) {
        case '1':
          return '정규수업';
        case '3':
          return '특강';
        case '2':
          if (this.clsMiddle === '수학전문관') return '수학전문관';
          return '단과';
        default:
          return '정규수업';
      }
    },
    subjectType() {
      switch (this.unitySubjtCd) {
        case '0100':
          return 'type1';
        case '0200':
          return 'type2';
        case '0300':
          return 'type3';
        case '0400':
          return 'type4';
        case '0500':
          return 'type5';
        case '0900':
          return 'type6';
        case '1000':
          return 'type7';
        default:
          return '';
      }
    }
  }
};
</script>
