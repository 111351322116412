<template>
  <!--  PC -->
  <div class="pay_content pass" v-if="!isMobile">
    <h4>
      <span>전체 강좌</span>
    </h4>
    <!-- search box 영역 -->
    <search-table-p ref="searchTable" :num-per-page="numPerPage" :api-path="lectureApi" @search="onSearchCallback" @loadingStart="isLoading = true" />
    <!-- table 영역 -->
    <infinite-pass-table-p v-model="selectedLecturesList" :title="'강좌'" :headers="tableHeaders" :items="infiniteLectures" :is-loading="isLoading" @openPopup="openPopup">
      <template v-slot:[`item.isInterest`]="{ item }">
        <i
          class="ico_star"
          :class="{ on: item.infipasIntrstLctNo }"
          @click="confirmInterest(item)"
          :id="`icon_${item.infipasLctShareNo ? item.infipasLctShareNo : item.infipasSglctDetrmnNo}`"
          style="cursor: pointer"
        > </i>
      </template>
      <template v-slot:[`item.groupName`]="{ item }">
        <badge-box
          :unity-subjt-cd="item.unitySubjtCd"
          :subjt-nm="item.subjtNm"
          :lecture-type-cd="item.lectureTypeCd"
          :cls-middle="item.clsMiddle"
        />
        <span v-html="item.groupName"></span>
      </template>
      <template v-slot:[`item.perdName`]="{ item }">
        <div v-html="getSchedule(item.schedules)"></div>
      </template>
      <template v-slot:[`item.viewBtn`]="{ item }">
        <a @click="openPopup('.ly_pop.lecture', item)">보기&gt;</a>
      </template>
    </infinite-pass-table-p>
    <!-- LayerPopup : 보기 버튼 눌렀을 때 뜨는 팝업 -->
    <lecture-popup ref="lecturePop" @closePopup="closePopup" />
    <!-- LayerPopup : 삭제팝업 -->
    <confirm-popup />
  </div>
  <!--  // PC -->
  <!--  MOBILE -->
  <div class="mypage_comm pass_mo" v-else>
    <div class="box_tab">
      <div class="tab_navi size2">
        <ul class="list_tab">
          <li class="on">
            <router-link to="/infinite-pass/all" class="btn_tab" data-href="tab1"><span class="inner">전체 강좌</span></router-link>
          </li>
          <li>
            <router-link to="/infinite-pass/ongoing" class="btn_tab" data-href="tab2"><span class="inner">수강중인 강의</span></router-link>
          </li>
        </ul>
      </div>
      <infinite-pass-table-m
        v-model="selectedLecturesList"
        :lectureInfo="lectureInfo"
        :num-per-page="numPerPage"
        :selectedFilterList="selectedFilterList"
        :is-loading="isLoading"
        @setNumPerPage="setNumPerPage"
        @openPopup="openPopup"
      />
    </div>
    <search-table-m
      v-model="selectedFilterList"
      :num-per-page="numPerPage"
      :api-path="lectureApi"
      @search="onSearchInMobile"
      @loadingStart="isLoading = true"
    />
    <lecture-popup ref="lecturePop" @closePopup="closePopup" @tooltipEvent="tooltipEvent" />
  </div>
</template>

<script>
import SearchTableP from './components/SearchTableP.vue';
import SearchTableM from './components/SearchTableM.vue';
import LecturePopup from './components/LecturePopup.vue';
import ConfirmPopup from './components/ConfirmPopup.vue';
import InfinitePassTableP from './components/InfinitePassTableP.vue';
import InfinitePassTableM from './components/InfinitePassTableM.vue';
import BadgeBox from './components/BadgeBox.vue';
import { mapActions, mapState } from 'vuex';
import EventBus from '../../utils/eventBus';
import { getSchedule } from '../../utils/util';
import { PAGE_TITLE } from '../../const/const';

export default {
  name: 'allLectureView',
  components: {
    InfinitePassTableP,
    InfinitePassTableM,
    BadgeBox,
    ConfirmPopup,
    SearchTableP,
    SearchTableM,
    LecturePopup
  },
  data: () => ({
    lectureApi: '/api/infinitePass/lecture/list',
    turnOrdLectures: [],
    infiniteLectures: [],
    lectureInfo: {
      currPage: 1,
      totalCnt: 0,
      items: null
    },
    isActiveSearch: false,
    numPerPage: 10,
    tableHeaders: [
      { text: '관심<br/>강좌', value: 'isInterest', width: '7%' },
      { text: '강좌', value: 'groupName', txtLeft: true, sortable: true },
      { text: '강사', value: 'teacherName', width: '9%', sortable: true },
      { text: '요일/교시', value: 'perdName', width: '11%' },
      { text: '강의실', value: 'groupRoom', width: '10%', sortable: true },
      { text: '강의 다시보기', value: 'viewBtn', width: '11%' }
    ],
    selectedLecturesList: [],
    selectedFilterList: {
      subjects: [],
      lectureTypeCds: []
    },
    isLoading: true
  }),
  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
      userInfo: (state) => state.common.userInfo
    })
  },
  methods: {
    getSchedule,
    ...mapActions(['getTurnOrdLectures', 'addInterestedLecture', 'setHeaderInfo']),
    changeTitle() {
      let title = '';
      if (this.userInfo.branchCd !== '') title = PAGE_TITLE[this.userInfo.branchCd];
      document.title = 'mypage_현장강의 무한패스 - 전체 강좌 | ' + title;
    },
    onSearchCallback(result) {
      this.isLoading = false;
      if (result.code !== '00000') {
        EventBus.$emit('setPageInfo', 1, 10, 0);
        return;
      }
      const data = result.data;
      this.infiniteLectures = data.list;
      EventBus.$emit('setPageInfo', data.pageInfo.currPage, data.pageInfo.numPerPage, data.pageInfo.totalRows);
    },
    onSearchInMobile(result) {
      this.isLoading = false;
      if (result.code !== '00000') {
        return;
      }
      const data = result.data;
      this.lectureInfo.currPage = data.pageInfo.currPage;
      this.lectureInfo.totalCnt = data.pageInfo.totalRows;
      this.lectureInfo.items = data.list;
    },
    async openPopup(className, obj) {
      const popup = document.querySelector(`${className}`);
      if (obj) {
        const result = await this.getTurnOrdLectures({ grpIdx: obj.grpIdx });
        if (Object.keys(result.data)?.length === 0) {
          alert('등록된 강의가 없습니다.');
          return;
        }
        this.$refs.lecturePop.title = obj.groupName;
      }
      popup.classList.add(this.isMobile ? 'open' : 'on');
    },
    closePopup(className) {
      const popup = document.querySelector(`${className}`);
      popup.classList.remove(this.isMobile ? 'open' : 'on');
    },
    confirmInterest({ infipasLctShareNo, infipasSglctDetrmnNo }) {
      const starIcon = document.querySelector('#icon_' + (infipasLctShareNo ? infipasLctShareNo.toString() : infipasSglctDetrmnNo.toString())),
        onFlag = starIcon.classList.contains('on'),
        msg = !onFlag ? '등록' : '삭제';
      if (confirm(`관심강좌를 ${msg}하시겠습니까?`)) {
        this.addInterestedLecture({ infipasLctShareNo, infipasSglctDetrmnNo, studentCd: this.userInfo.studentCd })
          .then(() => {
            alert(`${msg} 되었습니다.`);
            this.$refs.searchTable.search('setPage');
          })
          .catch(() => alert('오류가 발생되었습니다. 잠시후 다시 시도해주세요.'));
      }
    },
    tooltipEvent(id) {
      const el = document.querySelector(id),
        tooltipELs = document.querySelectorAll('.tool_group');

      if (el.classList.contains('on')) {
        return el.classList.remove('on');
      }
      tooltipELs.forEach((el) => el.classList.remove('on'));
      el.classList.add('on');
    },
    setNumPerPage(pageNum) {
      this.numPerPage = pageNum * 10;
    }
  },
  created() {
    this.setHeaderInfo({ menuName: '현장강의 무한패스', isPrev: false });
    this.changeTitle();
  }
};
</script>
